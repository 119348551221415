import React, { forwardRef } from 'react'
import ListItem from '@mui/material/ListItem'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { ListItemButton } from '@mui/material'

const RightAppMenuItemComponent = props => {
  const { className, onClick, isButton, link, children, state } = props
  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    if (isButton) {
      return (
        <ListItemButton
          className={className}
          children={children}
          onClick={onClick} />
      )
    } else {
      return (
        <ListItem
          className={className}
          children={children}
          onClick={onClick} />
      )
    }
  }

  return (
    <ListItemButton
      className={className}
      children={children}
      component={forwardRef((props, ref) => <NavLink to={{
        pathname: link,
        state: state
      }}
      exact {...props}
      innerRef={ref} />,[link])} />
  )
}

export default RightAppMenuItemComponent
