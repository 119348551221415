import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationfield.service';
import * as ServiceOrganizationCategories from '../../services/organizationcategory.service';
import * as componentActions from '../../redux/actions/organizationfields';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WithContext as ReactTags } from 'react-tag-input';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmationDeleteFieldOption from '../../components/dialog-confirmation-not-button'
import DialogTextEdit from '../../components/dialog-text-edit'
import DialogConfirmation from '../../components/dialog-confirmation'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as CalledService from '../../services/called.service';
import { PapiroConsole } from '../../utils/papiroConsole';
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import SimpleAutoCompleteMultipleCategories from "../../components/auto-complete/autocomplete-multiple-create-categories";
import HelpIcon from '@mui/icons-material/Help';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const StyledDataGrid = {
    root: {
        cell: {
            wordWrap: "break-word",

        }

    },
    fontSize: 14,
    color: '#000'
}



const delimiters = [KeyCodes.comma, KeyCodes.enter];

class OrganizationFieldEdit extends Component {

    constructor(props) {
        super(props);
        //  console.log(props);
        const { userSession, organizationfields, organizations } = this.props;

        this.fromProps = false
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        let apptargetlist = [{ id: 0, name: 'Todos' }, { id: 1, name: '2DO' }, { id: 2, name: '2MOVE' }]
        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: organizationfields.organizationfield.id,
            OrganizationId: organizationfields.organizationfield.OrganizationId,
            OrganizationText: organizationfields.organizationfield.OrganizationText,
            name: organizationfields.organizationfield.name == null ? '' : organizationfields.organizationfield.name,
            active: organizationfields.organizationfield.active,
            isrequired: organizationfields.organizationfield.isrequired,
            customeredit: organizationfields.organizationfield.customeredit,
            saveToAllOrganizationsInGroup: false,

            organizationfieldapptarget: organizationfields.organizationfield.organizationfieldapptarget,
            organizationfieldtype: organizationfields.organizationfield.organizationfieldtype,
            CategoryId: [],

        };


        var fieldoptionskeywords = []

        if (organizationfields.organizationfield.organizationfieldoptionsvalues) {
            organizationfields.organizationfield.organizationfieldoptionsvalues.map(item => {
                var obj = {
                    id: item.name,
                    text: item.name,
                    organizationfieldoptionsvaluesid: item.id
                }
                fieldoptionskeywords.push(obj)
            });
        }


        this.state = {
            organizationfield: this.initialState,
            CategoryListForDelete: "",
            CategoryListForSelect: [],
            CategoryList: [],
            positionKey: 0,
            fieldOptionIdSelectedToDelete: 0,
            fieldOptionNameSelectedToDelete: "",
            CategoryListSelect: [],
            ModalDeleteOrganizationFieldOptionValue: false,
            DialogTagEdit: false,
            tagEdit: { id: 0, text: "" },
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            fieldTypeList: [{ id: 1, name: 'Texto' }, { id: 2, name: 'Rádio' }, { id: 3, name: 'Checkbox' }, { id: 4, name: 'Seleção' }],
            tags: fieldoptionskeywords,
            apptargetlist: apptargetlist
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.categoryDeleteModal = this.categoryDeleteModal.bind(this)
        this.openCategoryInsertModal = this.openCategoryInsertModal.bind(this)
        this.saveCategoryInsertModal = this.saveCategoryInsertModal.bind(this)
        this.setCategoryDelete = this.setCategoryDelete.bind(this)
        this.getOrganizationCategories = this.getOrganizationCategories.bind(this)
        this.getOrganizationCategoriesByOrganizationFieldId = this.getOrganizationCategoriesByOrganizationFieldId.bind(this)

        this.closeModalDeleteOrganizationFieldOptionValue = this.closeModalDeleteOrganizationFieldOptionValue.bind(this);
        this.closeTagEditDialog = this.closeTagEditDialog.bind(this);
        this.openTagEditDialog = this.openTagEditDialog.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.internal === true) {
            this.getOrganizationsById(this.state.organizations.organization.id);
            this.getOrganizationCategories(this.state.organizations.organization.id)
            this.getOrganizationCategoriesByOrganizationFieldId(this.state.organizationfield.id)
        }
        else {
            this.getOrganizations();
            this.getOrganizationCategoriesByOrganizationFieldId(this.state.organizationfield.id)
            this.getOrganizationCategories(this.state.organizations.organization.id)
        }
    };

    //closeModalDeleteOrganizationFieldOptionValue = () => this.setState({ ModalDeleteOrganizationFieldOptionValue: !this.state.ModalDeleteOrganizationFieldOptionValue })

    closeModalDeleteOrganizationFieldOptionValue(i) {

        PapiroConsole.log(i)
        PapiroConsole.log("print i aqui")

        if (i != undefined && i != null && this.state.tags.length > 0 && this.state.tags[i]) {

            this.setState({
                ModalDeleteOrganizationFieldOptionValue: !this.state.ModalDeleteOrganizationFieldOptionValue,
                positionKey: i,
                fieldOptionIdSelectedToDelete: this.state.tags[i].organizationfieldoptionsvaluesid,
                fieldOptionNameSelectedToDelete: this.state.tags[i].text,
            })
        }
        else {
            this.setState({ ModalDeleteOrganizationFieldOptionValue: !this.state.ModalDeleteOrganizationFieldOptionValue })
        }



    }

    closeTagEditDialog() {
        this.setState({ DialogTagEdit: false })
    }

    openTagEditDialog(i) {
        if (i != undefined && i != null && this.state.tags.length > 0 && this.state.tags[i]) {
            const tag = this.state.tags[i]
            this.setState({ tagEdit: { id: tag.organizationfieldoptionsvaluesid, text: tag.text }, DialogTagEdit: true })
        }
    }

    handleTagEdit = (id, value) => {
        const intl = this.props.intl;

        if (value.length < 3) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "trhee.characteres.minimum" })
            })
        } else {
            const tags = this.state.tags.map(t => {
                if (id === t.organizationfieldoptionsvaluesid) {
                    return { ...t, text: value }
                } else {
                    return t
                }
            })

            if (id > 0) {
                this.setState({ loading: true })

                let data = new FormData();
                data.append("id", id);
                data.append("name", value)

                Api.put(`/organizationfieldoptionsvalues/text`, data)
                    .then(result => {
                        if (result.data.success) {

                            this.setState({
                                tags: tags,
                                loading: false,
                                openNotification: true,
                                notificationVariant: 'success',
                                notificationMessage: intl.formatMessage({ id: "edit.success" })
                            })
                            const organizationfield = {
                                ...this.state.organizationfield,
                                organizationfieldoptionsvalues: tags.map(t => ({
                                    id: t.organizationfieldoptionsvaluesid,
                                    name: t.text
                                }))
                            }
                            this.props.addCurrentOrganization(organizationfield, this.props.organizations.organization)
                        }
                        else {
                            this.setState({
                                loading: false,
                                openNotification: true,
                                notificationVariant: 'error',
                                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                            });
                            Api.kickoff(result);
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.data && err.response.data.errors) {
                            this.setState({
                                loading: false,
                                openNotification: true,
                                notificationVariant: 'error',
                                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                            });
                        }
                        else {
                            this.setState({
                                loading: false,
                                openNotification: true,
                                notificationVariant: 'error',
                                notificationMessage: intl.formatMessage({ id: "process.error" })
                            });
                        }
                        Api.kickoff(err);
                    })
            } else {
                this.setState({ tags: tags })
            }
            this.setState({ DialogTagEdit: false })
        }
    }

    async getOrganizationCategoriesByOrganizationFieldId(id) {

        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await ServiceOrganizationCategories.getOrganizationCategoriesByOrganizationFieldId(id);

        if (result.success) {

            var organizationCategories = []
            result.data.map(item => {

                item.name = item.parentname && item.parentname.length > 0 ? item.parentname : item.name;
                organizationCategories.push(item)


            })

            this.setState(prevState => ({
                loading: false, CategoryList: organizationCategories,
                organizationfield: {
                    ...prevState.organizationfield,
                    CategoryId: result.data
                }
            }));

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };
    async getOrganizations() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };
    async getOrganizationCategories(id) {

        this.setState({ loading: true });

        var result = await CalledService.getCategoriesByOrganizationSimple(id);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && id.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" }),
                            notificationLinkMove: "organizationcategories"
                        });
                    } else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" })
                        });
                    }
                }

            }
            this.setState({ loading: false, OrganizationCategoriesList: result.data, CategoryListForSelect: result.data });
        }
    };
    async getOrganizationsById(id) {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };
    /*categoryDeleteModal = async () => {
        if (this.state.CategoryListForDelete && this.state.CategoryListForDelete.length > 0) {
          const intl = this.props.intl;
          this.setState({ loading: true });

          var categoryList = this.state.CategoryList
          var categoryDeleteList = this.state.CategoryListForDelete.split(",")

          categoryDeleteList.map(itemDelete => {
            if (itemDelete != ""){
                let returnOk = categoryList.filter(el => el.id != itemDelete)
                categoryList = returnOk
            }
          })

          this.setState({
            loading: false,
            CategoryList: categoryList,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" })
          })
    
          /*let data = new FormData();
          data.append("organizationcategoriesid", categoryIds);
          data.append("organizationchecklistid", this.state.item.id);
    
          const intl = this.props.intl;
          await Api.post('/users/DeleteOrgOrLocalizationInUser', data)
            .then(result => {
    
              if (result.data.success) {
                var localizations = [];
    
                result.data.data.map(item => {
                  item.address.name = `( ${item.address.organization.name} ) ${item.address.name}`;
                  localizations.push(item.address)
                })
    
                this.setState({
                  loading: false,
                  OrganizationCategoryList: localizations,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                })
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
    
                });
                Api.kickoff(result);
              }
    
            })
            .catch(err => {
              if (err.response && err.response.data && err.response.data.errors) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: intl.formatMessage({ id: "process.error" })
                });
              }
              Api.kickoff(err);
            });
        } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: this.props.intl.formatMessage({id:"select.location"})
          })
        }
      }*/

    categoryDeleteModal = async () => {
        if (this.state.CategoryListForDelete && this.state.CategoryListForDelete.length > 0) {
            this.setState({ loading: true });

            var categoryDeleteList = this.state.CategoryListForDelete.split(",")

            let data = new FormData();
            data.append("organizationcategoriesid", categoryDeleteList);
            data.append("OrganizationFieldId", this.state.organizationfield.id);

            const intl = this.props.intl;
            await Api.post('/organizationfieldscategory/deleteinorganizationfield', data)
                .then(result => {

                    if (result.data.success) {
                        var categories = [];

                        result.data.data.map(item => {
                            if (item.organizationcategory) {
                                item.organizationcategory.name = item.organizationcategory.parentname && item.organizationcategory.parentname.length > 0 ? item.organizationcategory.parentname : item.organizationcategory.name;
                                categories.push(item.organizationcategory)
                            }


                        })
                        this.setState({
                            loading: false,
                            CategoryList: categories,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                        })
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                        });
                        Api.kickoff(result);
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                    Api.kickoff(err);
                });
        } else {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: this.props.intl.formatMessage({ id: "select.location" })
            })
        }
    }

    openCategoryInsertModal = async () => {
        this.setState({
            openCategoryInsertModal: !this.state.openCategoryInsertModal
        })
    }

    saveCategoryInsertModal = async () => {

        if (this.state.organizationfield.CategoryId != null && this.state.organizationfield.CategoryId.length > 0) {

            this.setState({
                loading: true
            })



            let data = new FormData();

            var categoryIds = this.state.organizationfield.CategoryId.map(item => {
                return item.id + ","
            })

            data.append("organizationcategoriesid", categoryIds);
            data.append("OrganizationFieldId", this.state.organizationfield.id);

            const intl = this.props.intl;
            await Api.post('/organizationfieldscategory/addinorganizationfield', data)
                .then(result => {
                    if (result.data.success) {
                        var categories = [];

                        result.data.data.map(item => {
                            if (item.organizationcategory) {
                                item.organizationcategory.name = item.organizationcategory.parentname && item.organizationcategory.parentname.length > 0 ? item.organizationcategory.parentname : item.organizationcategory.name;
                                categories.push(item.organizationcategory)
                            }


                        })



                        this.setState({
                            loading: false,
                            CategoryList: categories,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" }),
                        })

                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                        });
                        Api.kickoff(result);
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                    Api.kickoff(err);
                });

            this.setState({
                openCategoryInsertModal: !this.state.openCategoryInsertModal,
                loading: false
            })

            /* var categories = [];
             PapiroConsole.log("this.state.organizationfield.CategoryId")
             PapiroConsole.log(this.state.organizationfield.CategoryId)
             this.state.organizationfield.CategoryId.map(item => {
               item.name = item.parentname && item.parentname.length > 0 ? item.parentname : item.name;
               categories.push(item)
             })
             PapiroConsole.log("categories")
             PapiroConsole.log(categories)
            // PapiroConsole.log("categories") 
             //PapiroConsole.log(categories)
             this.setState({
               loading: false,
               CategoryList: categories,
               openNotification: true,
               notificationVariant: 'success',
               notificationMessage: intl.formatMessage({ id: "add.success" }),
               openCategoryInsertModal: !this.state.openCategoryInsertModal,
             })*/
        }
    }

    setCategoryDelete = async (org) => {
        this.setState({
            CategoryListForDelete: org + ","
        })
    }

    handleDelete(i) {

        const { tags } = this.state;
        const intl = this.props.intl;
        this.setState({
            loading: true,
        });
        if (tags.length <= 1) {

            this.setState({

                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "two.options.required" })
            });

        }
        else {

            var id = this.state.fieldOptionIdSelectedToDelete

            if (id && id != 0) {

                Api.delete(`/OrganizationFieldOptionsValues/edit/${id}`)
                    .then(result => {
                        if (result && result.data && result.data.success && result.data.success == true) {
                            const filterTags = tags.filter((tag, index) => index !== this.state.positionKey)
                            this.setState({
                                tags: filterTags,
                                loading: false,
                                openNotification: true,
                                notificationVariant: "success",
                                notificationMessage: intl.formatMessage({ id: "successfully.deleted.option" })
                            });
                            const organizationfield = {
                                ...this.state.organizationfield,
                                organizationfieldoptionsvalues: filterTags.map(t => ({
                                    id: t.organizationfieldoptionsvaluesid,
                                    name: t.text
                                }))
                            }
                            this.props.addCurrentOrganization(organizationfield, this.props.organizations.organization)
                        }
                        this.closeModalDeleteOrganizationFieldOptionValue()
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    });

            }
            else {

                this.setState({
                    tags: tags.filter((tag, index) => index !== this.state.positionKey),
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({ id: "successfully.deleted.option" })
                });
                this.closeModalDeleteOrganizationFieldOptionValue()

                /*this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "Ocorreu algum erro na operação de deletar um campo personalizado"
                    });*/
            }
        }
    }

    updateTags() {
        PapiroConsole.log("entrei aqui 2")
        const checkIfAnyElementWasAdd = this.state.tags.find(item => item.organizationfieldoptionsvaluesid == null || item.organizationfieldoptionsvaluesid == undefined || item.organizationfieldoptionsvaluesid == 0);
        PapiroConsole.log(checkIfAnyElementWasAdd)
        PapiroConsole.log("entrei aqui 3")
        if (checkIfAnyElementWasAdd) {
            PapiroConsole.log("entrei aqui 4")
            this.getFieldOptionValuesByOrganizationField()
        }
    }
    async getFieldOptionValuesByOrganizationField() {

        this.setState({ loading: true });
        var result = await Service.getFieldOptionValuesByOrganizationField(this.state.organizationfield.id);
        PapiroConsole.log(result)
        PapiroConsole.log("result")
        if (result.success) {
            let fieldoptionskeywords = [];
            let organizationfieldoptionsvalues = result.data
            if (organizationfieldoptionsvalues) {
                organizationfieldoptionsvalues.map(item => {
                    var obj = {
                        id: item.name,
                        text: item.name,
                        organizationfieldoptionsvaluesid: item.id
                    }
                    fieldoptionskeywords.push(obj)
                });
            }

            const organizationfield = {
                ...this.state.organizationfield,
                organizationfieldoptionsvalues: organizationfieldoptionsvalues
            }
            this.props.addCurrentOrganization(organizationfield, this.props.organizations.organization)
            this.setState({ loading: false, tags: fieldoptionskeywords });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    handleAddition(tag) {
        if (tag.text.length < 3) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'Mínimo de 3 caracteres'
            })
        } else {
            this.setState(state => ({ tags: [...state.tags, tag] }));
        }
    }
    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            organizationfield: {
                ...prevState.organizationfield,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();

        let idsStringCategory = "";
        if (this.state.CategoryList.length > 0) {
            this.state.CategoryList.forEach(category => idsStringCategory += `${category.id},`)
            idsStringCategory = idsStringCategory.slice(0, -1);
        }
        data.append("organizationcategoriesid", idsStringCategory);

        for (let key in this.state.organizationfield) {
            if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
                data.append(key, moment(this.state.organizationfield[key]).format('YYYY/MM/DD HH:mm:ss'));
            }


            else if (key.toString().toLowerCase().indexOf('organizationfieldapptarget') > -1) {
                data.append(key, this.state.organizationfield[key]);
            }

            else {
                data.append(key, this.state.organizationfield[key]);
            }
        }
        if (this.state.tags && this.state.tags.length > 0) {
            this.state.tags.forEach((item, index) => {
                PapiroConsole.log(item.organizationfieldoptionsvaluesid)
                PapiroConsole.log(item.text)
                data.append(`Tags[${index}].Id`, item.organizationfieldoptionsvaluesid ? item.organizationfieldoptionsvaluesid : 0);
                data.append(`Tags[${index}].Name`, item.text);
            });
        }


        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        Api.put('/organizationfields', data, config)

            .then(result => {
                const intl = this.props.intl;
                if (result.data.success) {
                    const formattedList = result.data.data;
                    //formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
                    //formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';
                    this.props.addCurrentOrganization(result.data.data, this.props.organizations.organization)
                    PapiroConsole.log(this.state.tags)
                    PapiroConsole.log("this.state.tags")
                    if (this.state.tags && this.state.tags.length > 0) {
                        PapiroConsole.log("entrei aqui")
                        this.updateTags();
                    }
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: this.props.intl.formatMessage({ id: "edit.success" }),
                        files: []
                    });
                    //this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
                    //this.componentDidMount();

                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });

                }
            })
            .catch(err => {
                const intl = this.props.intl;
                if (err.response && err.response.data && err.response.data.errors) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }) + err.response.data
                    });
                }
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {


        this.setState({ loading: true });

        if (stateName === "CategoryId") {
            /*var getOldValue = [...this.state.organizationfield.CategoryId]
            PapiroConsole.log("getOldValue 1 ")
            PapiroConsole.log(getOldValue)
            getOldValue.push(...value)
            PapiroConsole.log(getOldValue)
            PapiroConsole.log("getOldValue 2")*/

            this.setState(prevState => ({
                organizationfield: {
                    ...prevState.organizationfield,
                    [stateName]: value
                }
            }));

        }
        else {

            this.setState(prevState => ({
                organizationfield: {
                    ...prevState.organizationfield,
                    [stateName]: value
                }
            }));

            if (stateName === 'OrganizationId') {
                this.setState(prevState => ({
                    organizationfield: {
                        ...prevState.organizationfield,
                        ['OrganizationText']: text,

                    },
                    CategoryList: []
                }));
                this.getOrganizationCategories(value)
            }

        }




        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu, organizationfields } = this.props;
        const { organizationfield } = this.state;

        PapiroConsole.log(this.props.organizations.organizationfield)
        PapiroConsole.log("Props Organization")

        const intl = this.props.intl;


        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }
        let campo;
        if (this.state.organizationfield.organizationfieldtype == 2 || this.state.organizationfield.organizationfieldtype == 4) {
            campo =
                <div>
                    <Grid item xs={12} sm={12}>
                        <FormattedMessage id="registered.options" />
                    </Grid>
                    <Grid item container xs={12} sm={12} spacing={1} >
                        <Grid item xs={12} sm={11.5}>
                            <ReactTags
                                placeholder={intl.formatMessage({ id: "keywords.press.enter" })}
                                tags={this.state.tags}
                                delimiters={delimiters}
                                handleDelete={this.closeModalDeleteOrganizationFieldOptionValue}
                                handleAddition={this.handleAddition}
                                handleTagClick={this.openTagEditDialog}
                                inputFieldPosition='top'
                                inputProps={{
                                    style: { marginBottom: 10 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5} container direction='column' spacing={3}>
                            <Grid item marginTop={2.5}>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "add.option" })}</h5>} >
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>
                            {this.state.tags.length > 0 && <Grid item>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "edit.option" })}</h5>} >
                                    <HelpIcon />
                                </Tooltip>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
        }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={this.state.openCategoryInsertModal}
                            onClose={this.closeCategoryModal}
                            aria-labelledby="form-dialog-title-org-mat"
                            PaperProps={{
                                style: {
                                    overflow: 'hidden'
                                }
                            }} >
                            <DialogTitle id="form-dialog-title-org-mat">
                                {this.props.intl.formatMessage({ id: "organization.checklist.edit.new.categories" })}
                                <ClearIcon onClick={this.openCategoryInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
                            </DialogTitle>
                            <DialogContent>
                                <Grid item xs={12} sm={12} >
                                    <Grid container spacing={2} style={{ padding: "5px" }}>
                                        <Grid item xs={12} sm={12}>
                                            < SimpleAutoCompleteMultipleCategories
                                                label={this.props.intl.formatMessage({ id: "menu.categories" })}
                                                options={this.state.CategoryListForSelect}
                                                stateName="CategoryId"
                                                changeSelect={this.changeValues}
                                            //selected = {this.state.organizationfield.CategoryId}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ justifyContent: 'flex-end' }}>
                                            <Grid spacing={2} container justify="flex-end" style={{ padding: 15, justifyContent: 'flex-end' }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="button"
                                                        onClick={this.openCategoryInsertModal}
                                                        fullWidth >
                                                        {this.props.intl.formatMessage({ id: "cancel" })}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type='submit'
                                                        fullWidth
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={this.saveCategoryInsertModal}
                                                    >
                                                        {<FormattedMessage id="save" />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                        {this.state.ModalDeleteOrganizationFieldOptionValue && (
                            <DialogConfirmationDeleteFieldOption
                                msgButton={intl.formatMessage({ id: "confirm" })}
                                msg={intl.formatMessage({ id: "do.you want.to.remove.this.field.option.value" })}
                                optionToDelete={this.state.fieldOptionNameSelectedToDelete}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={e => this.handleDelete()}
                                handleClose={e => this.closeModalDeleteOrganizationFieldOptionValue()}
                                classes={classes}
                                disabled={false}
                                icon={<DeleteIcon />}
                            />
                        )}
                        <DialogTextEdit
                            msgButton={intl.formatMessage({ id: "confirm" })}
                            msg={intl.formatMessage({ id: "update.field.option.value" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            text={this.state.tagEdit.text}
                            handleConfirmation={this.handleTagEdit}
                            handleClose={e => this.closeTagEditDialog()}
                            classes={classes}
                            disabled={false}
                            open={this.state.DialogTagEdit}
                            id={this.state.tagEdit.id}
                            label="Nome"
                        />
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 4 }) : browserHistory.goBack()
                                    }
                                    }>{this.props.intl.formatMessage({ id: "back" })}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={this.props.intl.formatMessage({ id: "organization" }) + ':'} name='organizationid' options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationfield.OrganizationId} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organizationfield.name} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organization.field.type" })} options={this.state.fieldTypeList} stateName='organizationfieldtype' changeSelect={this.changeValues} selected={this.state.organizationfield.organizationfieldtype} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {campo}
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={intl.formatMessage({ id: "organizationfield.app.target" })} options={this.state.apptargetlist} stateName='organizationfieldapptarget' changeSelect={this.changeValues} selected={organizationfield.organizationfieldapptarget} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Accordion style={{ marginTop: "6px", marginBottom: "10px" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{intl.formatMessage({ id: "categories" })}*</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} xs={12} sm={12} >

                                                    <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                                        <Grid xs={12} sm={4} >

                                                        </Grid>
                                                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                            <DialogConfirmation
                                                                msgButton={"Remover categoria"}
                                                                msg={"Você tem certeza que desejar remover esta categoria deste checklist?"}
                                                                msgDisagree={this.props.intl.formatMessage({ id: "cancel" })}
                                                                msgAgree={this.props.intl.formatMessage({ id: "yes" })}
                                                                handleConfirmation={e => this.categoryDeleteModal()}
                                                                classes={classes}
                                                                icon={<DeleteIcon />}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                className={classes.button}
                                                                startIcon={<AddIcon />}
                                                                onClick={e => this.openCategoryInsertModal()} >
                                                                {this.props.intl.formatMessage({ id: "add.categories" })}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid xs={12} sm={12}
                                                        style={{
                                                            marginLeft: 5,
                                                            marginBottom: 5,
                                                            border: '3px solid #eee'
                                                        }} >
                                                        <div style={{ height: 350 }}>
                                                            <DataGrid

                                                                className="DataGridStyle"
                                                                components={{
                                                                    NoRowsOverlay: this.NoDataRows,

                                                                    Toolbar: GridToolbarFilterButton,
                                                                }}
                                                                rows={this.state.CategoryList}
                                                                rowsPerPageOptions={[5]}
                                                                checkboxSelection
                                                                style={{ fontSize: 14, color: '#000' }}
                                                                //style={StyledDataGrid}
                                                                onSelectionModelChange={itm => {
                                                                    if (itm.length > 0) {
                                                                        this.setCategoryDelete(itm);
                                                                    } else {
                                                                        this.setState({
                                                                            CategoryListForDelete: [],
                                                                        })
                                                                    }
                                                                }}
                                                                columns={[
                                                                    {
                                                                        headerName: intl.formatMessage({ id: "menu.categories" }), field: 'name', width: 500, renderCell: (params) => (
                                                                            <div>
                                                                                <Tooltip title={<h5>{params.row.name}</h5>} >
                                                                                    <span className="table-cell-trucate">{params.row.name}</span>
                                                                                </Tooltip>

                                                                            </div>)
                                                                    },
                                                                ]}
                                                                HeadersVisibility="None"
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={this.props.intl.formatMessage({ id: "required" })} name='isrequired' stateName='isrequired' changeSelect={this.changeValues} selected={organizationfield.isrequired} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={intl.formatMessage({ id: "customer.edit" })} name='customeredit' stateName='customeredit' changeSelect={this.changeValues} selected={organizationfield.customeredit} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={this.props.intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={organizationfield.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                            label={intl.formatMessage({ id: "apply.changes.to.all.organizations.in.group" })}
                                            name='saveToAllOrganizationsInGroup'
                                            stateName='saveToAllOrganizationsInGroup'
                                            changeSelect={this.changeValues}
                                            selected={organizationfield.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.submit}
                                                >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
OrganizationFieldEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    organizationfields: state.organizationfields
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationFieldEdit)));
