import Api from "./api";


export const getFile = async (hirerid,attachmentid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/attachments/file/${hirerid}/${attachmentid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
}

export const getFileDownloadLink = async (attachmentId) => {
	let data = [];
	await Api.get(`/file/downloadlink/attachment/${attachmentId}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  })
	return data
}

export const getFileBase64String = async (attachmentId) => {
	let data = [];
	await Api.get(`/file/base64/attachment/${attachmentId}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  })
	return data
}
  
export const getFileByPath = async (hirerid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/attachments/file/${hirerid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };
  export const getFileByPathWithFileRepositoryMethodByConfig = async (hirerid,filename) => {
	let data = [];
	await Api.get(`/attachments/file/path/${hirerid}?path=${filename}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };

  export const getFileById = async (id) => {
	let data = [];
	await Api.get(`/attachments/file/get/${id}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };