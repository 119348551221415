import Api from './api';

export const getAllWithFilter = async (formData) => {
  let data = []

  await Api.post(`/organizationfieldreportblockitem/getall/filter`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })

  return data
}

export const paginate = async (page, org, search, field) => { 
  let data = []

  let url = `/organizationfieldreportblockitem/paginate?page=${page}&org=${org}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginateByOrganization = async (page, orgId, search, field) => { 
  let data = []

  let url = `/organizationfieldreportblockitem/organization/paginate?page=${page}&id=${orgId}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getById = async (id) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveById = async (id) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/${id}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/${id}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationFieldReportBlockId = async (orgFieldReportBlockId) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/organizationfieldreportblock/${orgFieldReportBlockId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationFieldReportBlockId = async (orgFieldReportBlockId) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/organizationfieldreportblock/${orgFieldReportBlockId}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationFieldReportBlockIdWithoutIncludes = async (orgFieldReportBlockId) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/organizationfieldreportblock/${orgFieldReportBlockId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationFieldReportBlockIdWithoutIncludes = async (orgFieldReportBlockId) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/organizationfieldreportblock/${orgFieldReportBlockId}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationFieldReportBlockIdAndTitleWithoutIncludes = async (formData, config) => { 
  let data = []
  await Api.post(`/organizationfieldreportblockitem/exists/withoutincludes`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getByOrganizationFieldReportBlockIdAndTitleAndNotIdWithoutIncludes = async (formData, config) => { 
  let data = []
  await Api.post(`/organizationfieldreportblockitem/exists/notid/withoutincludes`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence = async (orgFieldReportBlockId) => { 
  let data = []
  await Api.get(`/organizationfieldreportblockitem/nextsequencelist/organizationfieldreportblock/${orgFieldReportBlockId}/active/withoutincludes/orderbysequence`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationFieldReportBlockIdAndSequenceAndNotIdWithoutIncludesOrderBySequence = async (formData, config) => { 
  let data = []
  await Api.post(`/organizationfieldreportblockitem/nextsequencelist/active/withoutincludes`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData, config) => { 
  let data = []
  await Api.post(`/organizationfieldreportblockitem`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const updateOrder = async (formData, config) => {
  let data = []
  await Api.put(`/organizationfieldreportblockitem/update/order`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData, config) => { 
  let data = []
  await Api.put(`/organizationfieldreportblockitem`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/organizationfieldreportblockitem/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}