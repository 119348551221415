import axios from 'axios';
import Api from './api';

import { PapiroConsole } from '../utils/papiroConsole'

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/1";
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name == "NetworkError") {
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    }else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}

let ApiLoginPost = null
let ApiStartPost = null
let getUrl = null
let sendSMSApi = null
let sendEmailApi = null

if(!saasError) {

  var baseUrl = JSON.parse(xhttp.responseText);
  //PapiroConsole.log(baseUrl)
  //PapiroConsole.log("baseUrl")
  if(baseUrl && baseUrl.data && baseUrl.data.urlapivertex) {
    const url = "https://"+baseUrl.data.urlapivertex+"/api";

    ApiLoginPost = async (dataSet, urlData, token = null) => {
      let dataReturn = [];

      await axios.post(urlData, dataSet, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',  
          'Authorization': `Bearer ${token}` 
        },
        baseURL: url
      }).then(data => {
        // PapiroConsole.log("responsePOST")
        // console.log(data)
        dataReturn = data;
      }).catch(err => {
        // console.log("responsePOST")
        // console.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    ApiStartPost = async (dataSet, urlData, token = null) => {

      let dataReturn = [];

      await axios.post(urlData, dataSet, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',  
          'Authorization': `Bearer ${token}` 
        },
        baseURL: url
      }).then(data => {
        // console.log("responsePOST")
        // console.log(data)
        dataReturn = data;
      }).catch(err => {
        // console.log("responsePOST")
        // console.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    getUrl = () => {

      return "https://"+baseUrl.data.urlfrontvertex;

    };

    sendSMSApi = async (dataSet) => {

      let dataForm = new FormData();

      dataForm.append('phone', dataSet.phone);
      dataForm.append('msg', dataSet.msg);
      dataForm.append('password', dataSet.password);

      let dataReturn = [];

      await axios.post(url+"/rooms/sendSMSExternal", dataForm, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*'
        },
        baseURL: url
      }).then(data => {
        // console.log("responsePOST")
        // console.log(data)
        dataReturn = data;
      }).catch(err => {
        // console.log("responsePOST")
        // console.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    sendEmailApi = async (dataSet) => {
      let data = [];

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      let dataForm = new FormData();

      dataForm.append('UrlCall', dataSet.urlCall);
      dataForm.append('PasswordCall', dataSet.passCall);
      dataForm.append('NameCall', dataSet.nameCall);
      dataForm.append('EmailCall', dataSet.emailCall);

      await axios.post(url+"/rooms/sendEmailExternal", dataForm, config)
        .then(result => {
          data = result;
        })
        .catch(err => {
          data = {data: (err.response && err.response.data ) ? err.response.data: "Não foi possível se conectar ao Mexx 2SEE!" };
        });

      return data;
    };
  }else {
    ApiLoginPost = null
    ApiStartPost = null
    getUrl = null
    sendSMSApi = null
    sendEmailApi = null
  }
}else {
  ApiLoginPost = null
  ApiStartPost = null
  getUrl = null
  sendSMSApi = null
  sendEmailApi = null
}

  export { ApiLoginPost, ApiStartPost, getUrl, sendSMSApi, sendEmailApi }