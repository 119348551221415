export default function timeentries(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                calledtimeentries: action.calledtimeentries,
            }
        default:
            return state;
    }
}