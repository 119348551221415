import { Button, CircularProgress, Container, CssBaseline, Grid, Switch, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from "../../../../config/ability-context.js";
import * as componentActions from '../../../../redux/actions/chatbotflows.js';
import * as chatbotComponentActions from '../../../../redux/actions/chatbots.js';
import * as ChatbotFlowService from "../../../../services/2talk/chatbot-flow.service.js";
import * as ChatbotService from "../../../../services/2talk/chatbot.service.js";
import Api from "../../../../services/api.js";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';
import { PAGE_LIST } from "./page-type.js";

const EditChatbotFlow = (props) => {
  const { classes, chatbotflows } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [item, setItem] = useState({
    id: chatbotflows?.chatbotflow?.id,
    name: chatbotflows?.chatbotflow?.name,
    flowdefault: chatbotflows?.chatbotflow?.flowdefault,
    chatbotid: chatbotflows?.chatbotflow?.chatbotid,
  });

  const [statePage, setStatePage] = useState({
    loading: false,
    openNotification: false,
    notificationVariant: "",
    notificationMessage: "",
  });

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    loadData(name, value);
  }

  const handleChangeSwitch = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    loadData(name, value);
  }

  const loadData = (name, value) => {
    if (name && value !== undefined) {
      setItem(prevState => ({
          ...prevState,
          [name]: value
      }));
    }
  }

  const fetchDataNotSuccess = (result) => {
    setStatePage({
      loading: false, 
      openNotification: true, 
      notificationVariant: "error",
      notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    })
  }

  const fetchDataThrowError = (err) => {
    setStatePage({
      loading: false, 
      openNotification: true, 
      notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    })
  }

  const closeNotification = () => {
    setStatePage(prevState => ({...prevState, openNotification: false}))
  }

  const handleSubmit = async (event) => {

    event.preventDefault();

    setStatePage(prevState => ({...prevState, loading: true}));

    let input = {
      ...item,
    }

    try {
      const output = await ChatbotFlowService.update(input);

      if (output.success) {
        setStatePage(prevState => ({
          ...prevState,
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Registro atualizado com sucesso'
        }))
      } else {
        fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      fetchDataThrowError(err);
      Api.kickoff(err);
    } finally {
      setStatePage(prevState => ({...prevState, loading: false}));
    }
  }

  useEffect(() => {
    const init = async () => {
      const output = await ChatbotService.getById(chatbotflows?.chatbotflow?.chatbotid);
      const chatbot = output?.data;
      dispatch(chatbotComponentActions.addCurrent(chatbot));
    }
    
    init();
  }, []);

  return (
    <Container component='main' maxWidth='md' style={{marginTop: '32px'}}>
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={4}>
              <Button variant='outlined' color='primary' onClick={() => props.onChangePage(PAGE_LIST)}><FormattedMessage id="back" /></Button>
          </Grid>
          <Grid item xs={12} sm={8} />
        </Grid>
        <Typography component='h1' variant='h5' mt={2}>
          {intl.formatMessage({ id: "chatbot-flow.edit" })}
        </Typography>

        <form name='myForm' className={classes.form} onSubmit={handleSubmit} style={{minWidth: '850px'}}>
          <Grid container spacing={2}>

              <Grid item xs={12} sm={12}>
                <TextField
                  inputProps={{ maxLength: 250 }} 
                  fullWidth 
                  label={intl.formatMessage({id:"name"})} 
                  autoComplete='fname' 
                  variant='outlined' 
                  name='name' 
                  onChange={(e) => handleInputChange(e)}
                  value={item.name} 
                  required 
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={item.flowdefault}
                  checked={item.flowdefault}
                  name="flowdefault"
                  onChange={(e) => handleChangeSwitch(e)}
                />
                <FormattedMessage id="flowdefault" />
              </Grid>    
              
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

          </Grid>
        </form>
      </div>


      {statePage?.loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      {
        statePage?.openNotification && (
          <CustomizedSnackbars
            variant={statePage?.notificationVariant}
            message={statePage?.notificationMessage}
            isOpen={statePage?.openNotification}
            toClose={closeNotification}
          />
        )
      }          
    </Container>
  );
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  chatbots: state.chatbots,
  chatbotflows: state.chatbotflows,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(EditChatbotFlow)));

EditChatbotFlow.contextType = AbilityContext;