import Api from './api';

export const getByCalledclassificationId = async (calledclassificationId) => { 
  let data = []
  await Api.get(`/satisfactionsurveyquestions/calledclassification/${calledclassificationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getBySatisfacionSurveyId = async (id) => { 
  let data = []
  await Api.get(`/satisfactionsurveyquestions/get-by-satisfaction-survey-id/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};