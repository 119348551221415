import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { InputLabel, MenuItem, FormControl, Select, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

const StyledOutlinedInput = styled(OutlinedInput)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border: none;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    font-size: 1em;
    padding: 8px 15px!important;
    color: #000;
    display: flex;
    align-items: center;
    background-color: rgba(82, 82, 82, .25)!important;
  }
  & .MuiOutlinedInput-root {
    background-color: #FFF;
    border: none;
    border-radius: 20px
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: none;
  }
  & .MuiInputBase-colorPrimary {
    border: none;
  }
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelectWithRoundedColor(props) {

  const { label, options, stateName, changeSelect, selected, required, disabled, fullWidth } = props;

  const classes = useStyles();
  const [option, setOption] = React.useState(selected ? selected : 0);

  const inputLabel = React.useRef(null);
  const [labelwidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    //setLabelWidth(inputLabel.current.offsetWidth);
    setOption(selected ? selected : 0);
  }, [selected]);

  const handleChange = event => {
    setOption(event.target.value);
    changeSelect(stateName, event.target.value);
  };

  return (
    <div>
      <FormControl required={required} variant="outlined" className={classes.formControl}           
        disabled={disabled} fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={option}
          onChange={handleChange}
          required
          input={<StyledOutlinedInput />}
        >
          {options && options.map(item => {
            if(item.enable == false) {
              return <MenuItem key={item.id} value={item.id} disabled={!item.enable} sx={{ display: 'flex', alignItems: 'center' }} >
                <div className='m-item-color' style={{ backgroundColor: item.colorhex, width: 10, height: 10, borderRadius: '50%', float: 'left' }}></div>
                <Typography variant='inherit' noWrap style={{ paddingLeft: 5, fontSize: '1.1em' }}>{item.title ? item.title :  item.name}</Typography>
              </MenuItem>
            } else {
                return <MenuItem key={item.id} value={item.id} sx={{ display: 'flex', alignItems: 'center' }} >
                  <div className='m-item-color' style={{ backgroundColor: item.colorhex, width: 10, height: 10, borderRadius: '50%', float: 'left' }}></div>
                  <Typography variant='inherit' noWrap style={{ paddingLeft: 5, fontSize: '1.1em' }}>{item.title ? item.title :  item.name}</Typography>
                </MenuItem>
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
}

SimpleSelectWithRoundedColor.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.number,
  disabled : PropTypes.bool
};
