import React, { useEffect } from "react";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Popover, Radio, RadioGroup } from "@mui/material";
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './style.css'
import { PapiroConsole } from "../../../utils/papiroConsole";

export default function SearchBar(props) {

  const { classes,intl, filters, addFilterEdit, clearFieldsEdit, filterName, filterValue, field, action,  callSearch, orderFields } = props;

  const [fieldValue, setFieldValue] = React.useState(field ? field : "");
	const [actionValue, setActionValue] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState(filterValue ? filterValue : "");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [actions, setActions] = React.useState([ { id: 1, name: intl.formatMessage({ id: "search" }) } ])
	const [orderActionValue, setOrderActionValue] = React.useState(1)

	const handleClickOrderPopup = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseOrderPopup = () => {
		setAnchorEl(null)
	}

	const orderFieldChange = (itemName, value) => {

	}

	const handleAddFilterEdit = (fieldValue, searchValue, actionValue) => {
		PapiroConsole.log("=== handleAddFilterEdit ===")
		PapiroConsole.log(fieldValue)
		PapiroConsole.log(searchValue)
		PapiroConsole.log(actionValue)
		PapiroConsole.log(orderActionValue)
		addFilterEdit(fieldValue, actionValue == 2 ? `${orderActionValue}`: searchValue, actionValue)
		clearFields()
	}

	const handleCallSearch = (fieldValue, searchValue, actionValue) => {
		PapiroConsole.log("=== handleCallSearch ===")
		PapiroConsole.log(fieldValue)
		PapiroConsole.log(searchValue)
		PapiroConsole.log(actionValue)
		PapiroConsole.log(orderActionValue)
		callSearch(fieldValue, actionValue == 2 ? `${orderActionValue}`: searchValue, actionValue)
		clearFields()
	}

	const handleChangeFieldValue = (value) => {
		PapiroConsole.log("=== SearchBar -> handleChangeFieldValue ===")
		PapiroConsole.log(value)
		setActionValue(1)
		setSearchValue("")
		setOrderActionValue(1)
		setFieldValue(value)
		let actionList = props.handleChangeFieldValue(value)
		PapiroConsole.log("=== SearchBar -> handleChangeFieldValue -> actionList ===")
		PapiroConsole.log(actionList)
		setActions(actionList)
	}

	const handleChangeOrderActionValue = (value) => {
		PapiroConsole.log("=== SearchBar -> handleChangeOrderActionValue ===")
		PapiroConsole.log(value)
		setOrderActionValue(value)
		setSearchValue(`${value}`)
	}

	const clearFields = () => {
		setFieldValue("")
		setActions([ { id: 1, name: intl.formatMessage({ id: "search" }) } ])
		setActionValue(1)
		setSearchValue("")
		setOrderActionValue(1)
	}

	const orderActions = [
		{
			id: 1,
			name: intl.formatMessage({ id: "order.filter.ascending" })
		},
		{
			id: 2,
			name: intl.formatMessage({ id: "order.filter.descending" })
		}
	]

	const open = Boolean(anchorEl);
	const id = open ? 'order-popup' : undefined;

  return (
	<>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={4}></Grid>
							<Grid item xs={12} sm={12} md={4} lg={3}>
								<SimpleSelect
									label={intl.formatMessage({ id: "field" })}
									options={filters}
									stateName='filter'
									changeSelectDirect={handleChangeFieldValue}
									selected={fieldValue}
									/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={2}>
								<SimpleSelect
									label={intl.formatMessage({ id: "action" })}
									customLabelSearch={intl.formatMessage({ id: "select.desired.action" })}
									options={actions}
									stateName='action'
									changeSelectDirect={setActionValue}
									selected={actionValue}
									/>
							</Grid>
							{ actionValue == 1 &&
							(<Grid item xs={12} sm={6} md={4} lg={2}>
								<TextField type="text"
									name={filterName}
									value={searchValue}
									placeholder={intl.formatMessage({ id: "search" })}
									label={intl.formatMessage({ id: "search" })}
									onChange={event => setSearchValue(event.target.value)}
									/>
							</Grid>)}
							{ actionValue == 2 &&
							(
								<Grid item xs={12} sm={6} md={4} lg={2}>
								<SimpleSelect
									label={intl.formatMessage({ id: "order" })}
									options={orderActions}
									stateName='orderActionValue'
									changeSelectDirect={handleChangeOrderActionValue}
									selected={orderActionValue}
									/>
							</Grid>
							)}
						</Grid>
					</Grid>
						<Grid item xs={12} sm={6} md={12} lg={5}></Grid>
						<Grid item xs={12} sm={6} md={4} lg={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								variant='contained'
								color='primary'
								size='large'
								onClick={() => {handleAddFilterEdit(fieldValue, searchValue, actionValue)}  }
								className={classes.button}
								style={{ width: '100%' }} >
								<ZoomInIcon /> {intl.formatMessage({id:"add"})}
							</Button>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								variant='contained'
								color='secondary'
								size='large'
								className={classes.button}
								style={{ width: '100%' }}
								onClick={() => {handleCallSearch(fieldValue, searchValue, actionValue)}  } >
								<Search /> {intl.formatMessage({id:"apply"})}
							</Button>
						</Grid>
						<Grid item xs={11} sm={5} md={3} lg={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								variant='contained'
								color='secondary'
								size='large'
								onClick={clearFieldsEdit}
								style={{ width: '100%' }}
								className={classes.button} >
								<ClearIcon /> {intl.formatMessage({id:"clear"})}
							</Button>
						</Grid>
  </>
  );
}
