import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledclosingstatuses/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerId = async (hirerId) => { 
  let data = []
  await Api.get(`/calledclosingstatuses/hirer/${hirerId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllActiveByHirerId = async (id) => { 
  let data = []
  await Api.get(`/calledclosingstatuses/active/hirer/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByHirerIdContext = async _ => { 
  let data = []
  await Api.get(`/calledclosingstatuses/active/hirer`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getCanInformCalledClosingStatusByOrgId = async (orgId) => { 
  let data = []
  await Api.get(`/organizationsettings/caninformcalledclosingstatus/${orgId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginate = async (page, search = '', field = '') => { 
  let url = `/calledclosingstatuses/paginate?page=${page}`
  if((search && search.length > 0) && (field && field.length > 0))
    url += `&search=${search}&field=${field}`

  let data = []
  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const saveCalledClosingStatus = async (formData) => {
  let data = []
  await Api.post(`/calleds/calledclosingstatus`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledclosingstatuses`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledclosingstatuses`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledclosingstatuses/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}