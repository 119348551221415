import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link, Email, Sms } from '@mui/icons-material';
import SharedEmail from './SharedCall/SharedEmail'
import SharedSms from './SharedCall/SharedSms'
import { injectIntl } from 'react-intl'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root1: {
    width: '50%',
  },
  root2: {
    width: '50%',
  },
}));

function ScrollableTabsButtonForce(props) {
  const intl = props.intl;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <div className={classes.root}> 
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={intl.formatMessage({id:"sms"})} icon={<Sms />} {...a11yProps(0)} />
          <Tab label={intl.formatMessage({id:"email"})} icon={<Email />} {...a11yProps(1)} />
        </Tabs>

      </AppBar>

      <TabPanel value={value} index={0}>
        <SharedSms propsModal={props} onLoading={props.onLoading}/>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <SharedEmail propsModal={props} onLoading={props.onLoading}/> 
      </TabPanel>
    </div>
    
    </>
  );
}
export default injectIntl(ScrollableTabsButtonForce);