export default function calledservicesettingsfileuploads(state = [], action) {
  switch (action.type) {
    case 'ADD_LIST_CALLED_SERVICE_SETINGS_FILE_UPLOAD':
      return {
        ...state,
        calledservicesettingsfileuploadslist: action.calledservicesettingsfileuploadslist,
      }
    case 'ADD_CALLED_SERVICE_SETINGS_FILE_UPLOAD':
      return {
        ...state,
        calledservicesettingsfileupload: action.calledservicesettingsfileupload
      }
    default:
      return state;
  }
}