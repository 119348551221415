import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Paper, Typography } from '@mui/material'
import { HtmlTooltip } from '../html-tooltip'
import { Styles } from '../../styles/material-styles'

function ReadOnlyField(props) {
  return (
    <div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex' }}>
      <div style={{ marginRight: 4, minWidth: 4, width: 4, height: 'inherit', borderRadius: 4, backgroundColor: '#3F51B4' }} ></div>
      <div>
        <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'inline', fontWeight: '600' }}>{props.label}</h6>
        <HtmlTooltip
          title={props.children != null ? props.children : <Typography>{props.value}</Typography>}>
            <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.value}</h5>
        </HtmlTooltip>
      </div>
    </div>
  )
}

ReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}


export default withStyles(Styles)(ReadOnlyField)