import Api from './api';

const PATH = `/integration-service-custom-field`;

const create = async (payload) => { 
  let data = {}
  await Api.post(`${PATH}`, payload)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

const deleteCustomField = async (id) => { 
  let data = {}
  await Api.delete(`${PATH}/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

const getCustomFieldsByServiceId = async (serviceId) => { 
  let data = {}
  await Api.get(`${PATH}/get-fields-by-service/${serviceId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export {
  create, deleteCustomField, getCustomFieldsByServiceId
};

