import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, Label } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import MyTransferList from '../../../components/transfer-list/transferlist';
import MyCheckList from '../../../components/checklist/checklist';
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple-create';

import SimpleAutoComplete from '../../../components/auto-complete/autocomplete-group';

import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';

import SimpleCheckBox from '../../../components/checkbox/check';
import Autocomplete from '@mui/material/Autocomplete';

import { saveAs } from 'file-saver';
import { Input } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

                    import * as Service from '../../../services/user.service';
class UserRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      Organizations:  organizationid,
      Groups: 0,
      OrganizationText: '',
      GroupText: '',
      name : '', 
      email : '', 
      active : false, 
      phone : '', 
      UserRoles : [],
      WorkHourId: 0,
      WorkHourText: '',
      OrganizationDepartamentId: 0
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      GroupList: [],
      RoleList: [],
      WorkHoursList: [],
      DepartamentList: [],
      departamentName: '',
      file: null,
      update: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true, update: false });
    //this.getOrganizations();
    await this.getRoles();
    await this.getWorkHours();
    //this.getAllGrous();
    await this.getDados();
    this.setState({ loading: false });
  
  }

  async getDados(){
    this.setState({ loading: true });
    var result = await Service.getAllTec(this.props.organizationid);
    // console.log(result);
    if(result.success == true && result.data.length > 0){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          name : result.data[0].name, 
          email : result.data[0].email, 
          active : result.data[0].active, 
          phone : this.mtel(result.data[0].phone), 
        },
      }));
      this.setState({ update: true });
    }
  }

  async getAllGrous(){

    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });

  }

  async getAllOrganizations(ids){

    if(ids.length > 0){
      this.setState({ loading: true });
      let urlids = ids.map(item => item.id);
      //this.setState({ loading: true });
      
      var orgs = await Service.getOrganizationsByGroup(urlids);
      orgs = orgs.map((org) =>{

        return {
          ...org,
          name: `( ${org.group.name} ) ${org.name}`
        }

      })
      this.setState({ loading: false, OrganizationList: orgs });
      //this.setState({ loading: false, GroupList: groups });
    }
    }

    async getAllDepartaments(ids){

      if(ids.length > 0){
        this.setState({ loading: true });
        let urlids = ids.map(item => item.id);
        //this.setState({ loading: true });
        
        var depts = await Service.getDepartaments(urlids);
        depts = depts.map((dept) =>{
  
          return {
            ...dept,
            parentname: dept.name,
            order: dept.organization.name
          }
  
        })

        this.setState({ loading: false, DepartamentList: depts });
      }
    }
  

    


  async getWorkHours(){
    this.setState({ loading: true });
    

    var result = await Service.getWorkHoursList();
      if (result.success) {
        this.setState({ loading: false, WorkHoursList: result.data });
                      
      } else {
        
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        
        Api.kickoff(result);

      }
  };

  async getDepartament(orgId){
    this.setState({ loading: true });
    var result = await Service.getDepartamentList(orgId);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
                    
    } else {
      
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      
      Api.kickoff(result);

    }
  };

  async getOrganizations(){
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
      if (result.success) {
        this.setState({ loading: false, OrganizationList: result.data });
                      
      } else {
        
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        
        Api.kickoff(result);

      }
};

async getRoles(){
  this.setState({ loading: true });
  var result = await Service.getRoles();
    if (result.success) {
      // console.log(result.data);
      let resultDate = [];
      result.data = result.data.map((item) =>{
        if(item.id == 798){
          resultDate.push(item);
        }
      });
      this.setState({ loading: false, RoleList: resultDate });
      let resultId = [];
      resultId.push(resultDate[0]);
      //resultId.push(resultDate[0].id);
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['UserRoles']: resultId
        }
      }));
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
};

handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    
    if(e.target.name === 'phone'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['phone']: this.mtel(value),
        }
      }));
    }

  };

  handleSubmit = async e => {

    e.preventDefault();
    this.setState({ loading: true });
    
    if(this.state.update == true){
      this.props.next();
    }

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }
      


      //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.item['email'])) { 
      //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
      //  return;
      //}
      if(this.state.item.UserRoles == null || this.state.item.UserRoles == ''){

        const intl = this.props.intl;
        this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:intl.formatMessage({id:"selected.group"})});
        return;
      }
      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        }
        else if (key.toString().toLowerCase().indexOf('userroles') > -1) {
          //console.log(this.state.item[key]); 
          /*
          var UserRoles = [];
          this.state.item[key].map(item => {

            var Role = {
              Id : item
             };
             var UserRole = {
              Role : Role
             };
             UserRoles.push(UserRole);
            });
            var datapost = {
              UserRoles
            };
            */ 
            data.append(key, this.state.item[key]);
          }else if (key.toString().toLowerCase().indexOf('phone') > -1){
            data.append(key, this.state.item[key].replace(/\D/gim, ''));
          } else {
            if(key === 'Organizations'){
              data.append(key,this.state.item['Organizations']);
            } else {
              data.append(key, this.state.item[key]);
            }
          
        }
      }
      //Descomentar em dev
      //this.props.next();
      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'dataType': 'json'
        }
      };
        Api.post('/users', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
            });
            this.props.next();

          }
          else{

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }          
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response
            });
          }
        });
  };
  
  mtel(v){
    v=v.replace(/\D/g,"");
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2");
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");
    return v;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        

    // console.log(stateName);
    // console.log(value);
    // console.log(text);

    if(stateName === 'OrganizationId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationText']: text,
          Organizations:value,
          OrganizationDepartamentId: 0
        },
        departamentName: ''
      }));
      this.setState({ DepartamentList:[] });
      await this.getAllDepartaments(value);
      
      //this.getDepartament(value);
    }

    if(stateName === 'GroupId'){
      this.setState({ DepartamentList:[] });
      this.setState({ OrganizationList:[] });
      await this.getAllOrganizations(value);
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['GroupText']: text,
          Groups:value,
          Organizations:[],
          OrganizationId:[],
          OrganizationDepartamentId: 0
        },
        departamentName: ''
      }));
    }

    if(stateName === 'WorkHourId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['WorkHourText']: text,
        }
    }));
    }

    this.setState({ loading: false });
 

  };
  getRolesIds(){
        const idList = this.state.RoleList.map(item => {
          return item.id
      });
      return idList;
     
  }
  getRolesNames(){
    const idList = this.state.RoleList.map(item => {
      return item.name
  });
  return idList;
 
  }  
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    
  render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

     return (
      <div className={classes.root}>

          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  
                  <Grid container spacing={4} style={{display: 'none'}}>
                      <Grid item xs={12} sm={12} >
                        <SimpleSelect label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='Organizations' changeSelect={this.changeValues} selected={item.Organizations} />
                      </Grid>
                  </Grid>

                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"email"})} inputProps={{ maxLength: 255 }} autoComplete='fname' type='email' variant='outlined' name='email' onChange={this.handleInputChange} value={item.email} required />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField inputProps={{ maxLength: 15 }} fullWidth label={intl.formatMessage({id:"cellphone"})} autoComplete='fname' variant='outlined' name='phone' onChange={this.handleInputChange} value={item.phone} required/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                    <SimpleSelect  label={intl.formatMessage({id:"menu.workhours"})} options={this.state.WorkHoursList} stateName='WorkHourId' changeSelect={this.changeValues} selected={item.WorkHoursId} />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Typography component="h5" variant="h5">
                      {<FormattedMessage id="user.profile" />}
                    </Typography>

                    <Grid item xs={12} sm={12}>
                      <MyCheckList label={intl.formatMessage({id:"menu.groups"})} name='UserRoles'  optionsText={this.getRolesNames()}  options={this.getRolesIds()}  stateName='UserRoles' changeSelect={this.changeValues} selected={item.UserRoles} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4} /> 
                  <Grid  xs={4} sm={4} style={{textAlign: 'center', marginTop: '5px'}}>
                    <Button
                      type='submit'
                      color='primary'
                      onClick={back}
                    >
                      {this.props.intl.formatMessage({id:"back"})}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                    >
                      {intl.formatMessage({id:"next"})}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} /> 
                </Grid>
                
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>

      </div>
    );
  }
}
UserRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(UserRegistration))); 
