import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import Steps from '@mui/icons-material/Money';
import { FormattedMessage, injectIntl } from 'react-intl';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PostAddIcon from '@mui/icons-material/PostAdd';

import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

import HomeIcon from '@mui/icons-material/Home';


import TwoWheelerRounded from '@mui/icons-material/TwoWheelerRounded';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "#8884d8",
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceIconSucess: {
    color: 'green'
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const Budget = props => {
  const { className, total, comparation, positiveMonth, titleBudget } = props;

  const classes = useStyles();

  const intl = props.intl

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent >
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography 
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {<FormattedMessage id={titleBudget} />}
            </Typography>
            <Typography variant="h3">{total} </Typography>
            
          </Grid>
          <Grid item >
            <Avatar className={classes.avatar}>

            {titleBudget == "quantity.of.inloco.calleds"  &&
              
              
              <HomeIcon className={classes.icon} />

                
            }

            

            {titleBudget == "displacement.hours"  &&
              
              
              <TwoWheelerRounded className={classes.icon} />

                
            }
             {titleBudget == "inloco.attendance"  &&
              
              
              <ScheduleRoundedIcon className={classes.icon} />

                
            }
            
             {titleBudget == "total.hours"  &&
              
              
              <ScheduleRoundedIcon className={classes.icon} />

                
            }

          {titleBudget == "total.displacement.value"  &&
              
              
              <MoneyIcon className={classes.icon} />

                
            }
             {titleBudget == "total.value"  &&
              
              
              <MoneyIcon className={classes.icon} />

                
            }

            {titleBudget == "total.value.complete"  &&
              
              
              <MoneyIcon className={classes.icon} />

                
            }
            
             
            </Avatar>
          </Grid>
          
        </Grid>
        
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  comparation: PropTypes.number,
  positiveMonth: PropTypes.bool
};

export default injectIntl(Budget);
