export function addCurrent(additionalcalledcostitemreason) {
  return {
      type: 'ADD_CURRENT',
      additionalcalledcostitemreason,
  }
}

export function addCurrentOrganization(additionalcalledcostitemreason,organization) {
  return {
      type: 'ADD_CURRENT_ORGANIZATION',
      additionalcalledcostitemreason,
      organization,
      }
}