import React from 'react'
import Path from '../../../images/attachment.png'
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel'
import ChatMessageVideoDialog from './message-type-dialog/chat-message-video-dialog'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'

function ChatMessageVideo({ content, title, id, setLoading }) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [url, setUrl] = React.useState("")

  React.useEffect(() => {
    return () => {
      setUrl('');
      setOpenDialog(false)
    }
  }, [])

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const download = async e => {
    e.preventDefault()

    if (e.target.classList.contains('omnichannel-chat-message-video') || e.target.classList.contains('omnichannel-chat-message-video-icon') || e.target.classList.contains('omnichannel-chat-message-video-name')) {
      if (openDialog) return

      setLoading(true)
      let result = await GetImageMetaService(id)
      if (result && result.success && result.data && result.data.url) {
        setUrl(result.data.url)
        setTimeout(() => {
          setOpenDialog(true)
          setLoading(false)
        }, 1000)
      } else 
        setLoading(false)
    }
  }

  return (<div
      className="omnichannel-chat-message-video"
      onClick={download} >
    <div className="omnichannel-chat-message-video-icon" >
      <PlayCircleOutlinedIcon style={{ color: '#545454', fontSize: '3em' }} />
    </div>
    <div className="omnichannel-chat-message-video-name" >
      {content}.mp4
    </div>
    { (url && url.length > 0) && <ChatMessageVideoDialog
      url={url}
      title={title}
      isOpen={openDialog}
      handleCloseDialog={handleCloseDialog} />}
  </div>)
};

export default ChatMessageVideo
