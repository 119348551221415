import React from 'react'
import Path from '../../../images/image-coming-soon.jpg'
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel'
import './styles.css'
import { PapiroConsole } from '../../../../../utils/papiroConsole';
import { getContactByMessageContent } from '../../../../../utils/contactUtils';
import PersonIcon from '@mui/icons-material/Person';
import { FormattedMessage } from 'react-intl';

function ChatMessageContact({ content }) {
  PapiroConsole.log("=== ChatMessageContact ===")
  const [contact, setContact] = React.useState({ name: '', phones: [] })

  React.useEffect(() => {
    PapiroConsole.log("ChatMessageContact -> React.useEffect")
    let contactData = getContactByMessageContent(content)
    PapiroConsole.log(contactData)
    if (contactData && contactData.name)
      setContact(contactData)
  }, [content])

  return (<div
      className="omnichannel-chat-message-contact" >
    <div className="omnichannel-chat-message-contact-icon" >
      <PersonIcon style={{ color: '#545454', fontSize: '5em' }} />
    </div>
    <div className="omnichannel-chat-message-contact-name" >
      <h4>{contact.name}</h4>
      {contact.phones.map((phone, idx) => {
        return (<p><FormattedMessage id="phone" /> {(idx+1)}: {phone}</p>)
      })}
    </div>
  </div>)
}

export default React.memo(ChatMessageContact)
