import React, { Component } from 'react';
                import { connect } from 'react-redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { AbilityContext } from '../../config/ability-context'
                    import Base from '../../components/layout-base/base';
                    import OrganizationSettingsContent from './index.content';

class OrganizationSettings extends Component {

                    constructor(props) {
                        super(props);
                        //console.log(props);

                    }

                    componentWillReceiveProps(nextProps){
                    }    

                    componentDidMount() {
                    }
                     render() {
                        

                        return (

                            <Base props={this.props}>
                                   <OrganizationSettingsContent props={this.props} />

                            </Base>        
                        );
                    }
                }

                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });

            export default connect(mapStateToProps)(withStyles(Styles)(OrganizationSettings));

            OrganizationSettings.contextType = AbilityContext;                                    