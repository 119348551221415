import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Typography, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles-chat';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/rulesattendance';
import Api2Talk from "../../services/api2talk";
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import SimpleSelect from "../../components/select/simple";
import PersonIcon from '@mui/icons-material/Person';
import ReplyIcon from '@mui/icons-material/Reply';
import StopIcon from '@mui/icons-material/Stop';
import Chat from "./chat/index"
import Title from '../../components/title-name';
import { QueuePlayNext } from '@mui/icons-material';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import PhoneInput from 'react-phone-input-2'

class RulesAttendance extends Component {

    constructor(props) {
        super(props);

        const intl = this.props.intl;

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            search: '',
            item: {
                statusAttendance: 1,
                phoneNumber: "",
                phoneSender: ""
            },
            statusAttendance: [{id: 1, name: 'Em andamento'}],
            phoneNumberList: [],
            chatMessages: [],
        };
    }

    async componentDidMount () {
        if (Api2Talk == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/calleds`
        }
           
        this.getPhoneNumberList();
    }

    getPhoneNumberList = () => {

        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        this.setState({ loading: true });

        Api2Talk.get("/registrationphonenumber/getallphoneactiveable")
            .then(result => {
                if (result.data.success) {

                    var formatlist = result.data.data.map(item => {
                        return {
                            id: item.id,
                            name: item.phonenumber
                        }
                    })

                    this.setState({ 
                        loading: false,
                        phoneNumberList: formatlist
                    });

                } else {
                    this.setState({ 
                        loading: false
                    });

                   /* const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);*/
                }
            })
            .catch(err => {
                this.setState({ 
                    loading: false
                });

               /* const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);*/
            });
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
      
        this.setState({
            [name]: value
        });
    }

    changeValues = async (stateName, value, text = "") => {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            [stateName]: value,
          },
        }));
    }

    setChatMessages = (messages) => {
        const messagesList = this.state.chatMessages;
        messagesList.push(messages);
        this.setState({
            chatMessages: messagesList,
        });
    }

    handleChangePhone = (e) => {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            'phoneSender': e
          }
        }));
    }
    

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        const conversations = [
            {
              id: 1,
              avatar: 'A',
              name: 'Alice',
              lastMessage: 'Olá, como você está?',
              time: '10:00 AM',
              notification: '10'
            },
            {
              id: 2,
              avatar: 'B',
              name: 'Bob',
              lastMessage: 'Vamos nos encontrar hoje à noite?',
              time: '11:00 AM',
              notification: '6'
            },
            {
              id: 3,
              avatar: 'C',
              name: 'Charlie',
              lastMessage: 'Estou animado para o evento de amanhã! Estou animado para o evento de amanhã!',
              time: '12:00 PM',
              notification: '1'
            }
          ];

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />

                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                Atendimentos <Title />
                            </Typography>

                            <Grid container alignItems="center" >
                                <Grid xs={3} style={{marginBottom: '10px'}} >
                                    <SimpleAutoComplete
                                        label="Telefone de atendimento"
                                        options={this.state.phoneNumberList}
                                        stateName='phoneNumber'
                                        changeSelect={this.changeValues}
                                        size="small"
                                    />
                                </Grid>
                                <Grid xs={3} style={{marginLeft: '10px', marginBottom: '10px'}} >
                                    <PhoneInput
                                        inputStyle={{
                                            padding: '23.5px 14px 23.5px 58px',
                                            width: '100%'
                                        }}
                                        style={{
                                            marginTop: 4,
                                            marginBottom: 8
                                        }}
                                        id="phone"
                                        name="phone"
                                        country={'br'}
                                        value={this.state.item.phoneSender}
                                        onChange={this.handleChangePhone}
                                        InputLabel="Telefone cliente teste"
                                        specialLabel="Telefone cliente teste"
                                        inputProps={{
                                            name: "Telefone cliente teste"
                                        }} 
                                    />
                                </Grid>
                            </Grid>

                            {this.state.item.phoneNumber != "" && (
                                <Grid container alignItems="center" >
                                    <Grid item xs={3} >
                                        <div style={{backgroundColor: "#3F51B4", height: "180px", padding: "20px"}}>
                                            <Grid container spacing={2} alignItems="center" >
                                                <Grid style={{color: 'white'}} item xs={2}>
                                                    <div 
                                                        style={{
                                                            backgroundColor: 'rgb(92 106 183)',
                                                            top: '10px',
                                                            left: '6px',
                                                            width: '36px',
                                                            height: '24px',
                                                            textAlign: 'center',
                                                            borderRadius: '4px'
                                                        }} 
                                                    >
                                                        10
                                                    </div>
                                                </Grid>
                                                <Grid style={{color: 'white', fontSize: '12px', fontWeight: 'bold' }} item xs={5}> 
                                                    Atendimentos
                                                </Grid>
                                                <Grid style={{color: 'white', fontSize: '10px', textAlign: 'right' }} item xs={4}> 
                                                    Disponível
                                                </Grid>
                                                
                                                <Grid xs={10} style={{marginTop: '20px', marginLeft: '15px'}}>
                                                    <TextField
                                                        name="search"
                                                        label=""
                                                        size="small"
                                                        fullWidth
                                                        style={{backgroundColor: "white", borderRadius: "12px"}}
                                                        value={this.state.search}
                                                        onChange={(e) => this.handleInputChange(e)}
                                                    />
                                                </Grid>
                                                <Grid xs={1} style={{marginTop: '20px', marginLeft: '8px', color: "white"}}>
                                                    <IconButton onClick={() => {""}}>
                                                        <SearchIcon sx={{ color: 'white' }} />
                                                    </IconButton>
                                                </Grid>

                                                <Grid xs={12} style={{marginTop: '8px', marginLeft: '15px',  borderRadius: "12px", backgroundColor: "white"}}>
                                                    <SimpleSelect
                                                        options={this.state.statusAttendance}
                                                        stateName='statusAttendance'
                                                        changeSelect={this.changeValues} 
                                                        selected={this.state.item.statusAttendance}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Grid>
                                            
                                    </div>
                                    <div className={classes.scrollable}>
                                            <Grid container spacing={2} >
                                                <div>
                                                    {conversations.map((conversation) => (
                                                        <div key={conversation.id} className={classes.conversation}>
                                                            <div className={classes.avatar}>
                                                                <PersonIcon />
                                                            </div>
                                                            <div className={classes.details}>
                                                                <div className={classes.name}>{conversation.name}</div>
                                                                <div className={`${classes.lastMessage} ${conversation.lastMessage.length > 25 ? 'truncate' : ''}`}>
                                                                    {conversation.lastMessage.length > 25 ? conversation.lastMessage.slice(0, 25) : conversation.lastMessage}
                                                                </div>
                                                                <div className={classes.time}>{conversation.time}</div>
                                                            </div>
                                                            <div className={classes.countNotification}>{conversation.notification}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>

                                    <Grid item xs={9} style={{ width: "100%" }}>
                                        <div className={classes.headerChat}>
                                            <div className={classes.avatarChat}>
                                                <PersonIcon fontSize='large' />
                                            </div>
                                            <div className={classes.text}> 
                                                Nome do usuário
                                            </div>
                                        </div>
                                        <div className={classes.lineAttendance}>
                                            <div className={classes.status} />
                                            <div className={classes.statusSelect}>
                                                <SimpleSelect
                                                    options={this.state.statusAttendance}
                                                    stateName='statusAttendance'
                                                    changeSelect={this.changeValues} 
                                                    selected={this.state.item.statusAttendance}
                                                    size="small"
                                                />
                                            </div>
                                            <div className={classes.statusButtonLine}>
                                                <span className={classes.statusButton}>
                                                    <ReplyIcon color='success' style={{verticalAlign: "bottom"}} />
                                                    Transferir
                                                </span>
                                                <span className={classes.statusButton}>
                                                    <StopIcon color='error' style={{verticalAlign: "bottom"}} />
                                                    Encerrar
                                                </span>
                                            </div>
                                        </div>
                                        <div className={classes.chat}>
                                            <Chat
                                                messages={this.state.chatMessages}
                                                setMessages={this.setChatMessages}
                                                phoneNumber={this.state.item.phoneNumber}
                                                phoneSender={this.state.item.phoneSender}
                                            />
                                        </div>
                                    </Grid>

                                </Grid>
                            )}
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
RulesAttendance.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RulesAttendance)));
RulesAttendance.contextType = AbilityContext;
