import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useChatHubContext } from '../../contexts/chatHubContext';
import Badge from '@mui/material/Badge';
import {Grid} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';
import ChatListOptionsInProgress from '../chat-list-options-inprogress';
import ChatListOptionsCloseds from '../chat-list-options-closeds';

export default function ChatListOptions(intl) {
  const userSession = useSelector((state) => state.userSession);
  const {
    openChatList,
    chatHub,
    currentTechnicianChat,
    currentClientChat
  } = useChatHubContext();


  const [chatIndex, setChatIndex] = useState(0);

 

  return (
    <Grid item xs={12} sm={12} >
      <Tabs selectedIndex={chatIndex} onSelect={index => setChatIndex(index)} style={{padding : '10px', borderBottom: 'none' }}>
        <TabList style={{ borderBottom: 'none' }}>
          <Tab style={{  fontSize: "12px",  borderBottom :'none', color:'gray' }}> {<FormattedMessage id="Em andamento" />} </Tab>
          <Tab style={{  fontSize: "12px",  borderBottom :'none', color:'gray' }}> {<FormattedMessage id="Histórico" />} </Tab>

        </TabList>

        <TabPanel>
          <ChatListOptionsInProgress/>

        </TabPanel>
        
        <TabPanel>  
          <ChatListOptionsCloseds/>
        </TabPanel> 

       


      </Tabs>
    </Grid>





  );
}

