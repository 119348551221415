export function addCurrent(additionalcalledcostitem) {
  return {
      type: 'ADD_CURRENT',
      additionalcalledcostitem,
  }
}

export function addCurrentOrganization(additionalcalledcostitem,organization) {
  return {
      type: 'ADD_CURRENT_ORGANIZATION',
      additionalcalledcostitem,
      organization,
      }
}