import * as ChatbotFlowService from '../../../../../../services/2talk/chatbot-flow.service';

export const getItemsSelect = async (id, type) => {
  var result = await ChatbotFlowService.getItemsSelect(id, type);

  if (result.success && result?.data?.length > 0) {
    return result?.data;         
  } 

  return [];
};