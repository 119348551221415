import React from 'react';
import Path from '../../images/video.png';
import { GetImageMeta as GetImageMetaService
  } from '../../../../services/2talk/omnichannel';




  async function download(props){
    props.setLoading(true);
    let result = await GetImageMetaService(props.id)
    if (result && result.success) {
        //alert(JSON.stringify(result.data));
        if(result.data && result.data.url){
            window.open(result.data.url);
        }
    } else {
    }
    props.setLoading(false);
}

const Icon = props => (
    <div>
        <img src={props.src && props.src.length > 0 ? props.src : Path}  
        style={props.style != null ? props.style : {  }}
        title={props.title != null ? props.title : "Clique para visualizar"} onClick={() => {
            download(props);
          }}/>
    </div>
);

export default Icon;
