import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, Switch, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import * as componentActions from '../../redux/actions/calledflows';
import * as ChatbotService from "../../services/2talk/chatbot.service.js";
import Api from "../../services/api";
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';


class ChatbotRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      createduser: userSession.user.id,
      name: '',
      active: false
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      optionsWebchatTypes: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
  });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let input = {
      ...this.state.item,
    }

    try {
      const output = await ChatbotService.create(input);

      if (output.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          item: {
            ...this.itemInitialState,
          },
          notificationMessage: 'Registro incluído com sucesso'
        });
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <SmartToyIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "chatbot.registration" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='name' 
                        onChange={this.handleInputChange} 
                        value={item.name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Switch
                        value={this.state.item.active}
                        checked={this.state.item.active}
                        name="active"
                        onChange={(e) => this.handleChangeSwitch(e)}
                      />
                      <FormattedMessage id="active" />
                    </Grid>    
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatbotRegistration)));

ChatbotRegistration.contextType = AbilityContext;