import Api from './api';

export const getAll = async (configId) => { 
  let data = []
  await Api.get(`/satisfactionsurveysettingsdetails/${configId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const create = async (item) => {
  let data = []
  await Api.post('/satisfactionsurveysettingsdetails', item)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
    return data
}

export const update = async (item) => {
  let data = []
  await Api.put('/satisfactionsurveysettingsdetails', item)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
    return data
}

export const deleteById = async (id) => {
  let data = []
  await Api.delete(`/satisfactionsurveysettingsdetails/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
    return data
}