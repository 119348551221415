import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import * as userSessionActions from '../../redux/actions/user-session';
import { removeUserSession } from '../../redux/actions/user-session';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'

function UserMenu(props) {

  let history = useHistory();
  //console.log(props);
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleClose();
    removeUserSession();

    let language = window.location.pathname.split('/')[1]
    if (language != "pt" && language != "es" && language != "en")
      language = "pt"

    history.push(`/${language}/admin`);
  };

  //{ resetSession },

  return (
  
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Person style={{ color: '#FFF' }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/admin/forgot-password` }}>
          <CompareArrows /> 
          <span style={{ marginLeft: '10px' }}><FormattedMessage id="change.password"/></span>
        </MenuItem>

        <MenuItem onClick={logOut}>
          <ExitToApp /> 
          <span style={{ marginLeft: '10px' }}>{this.props.intl.formatMessage({id:"user.menu.logout"})}</span>
        </MenuItem>

      </Menu>
    </div>
  );
}

const mapStateToProps = state => ({
  userSession: state.userSession,
});

//const mapDispatchToProps = dispatch => ({
//  resetSession: () => dispatch(userSessionActions.removeUserSession())
//});

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);

//export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserMenu))))
