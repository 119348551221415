import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { PapiroConsole } from "../../utils/papiroConsole";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleCheckBox(props) {
  const { name, label, stateName, changeSelect, selected, required, disabled, icon, readOnly } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState(selected == true ? true : false);
  const [option, setOption] = React.useState(selected == true ? true : false);
  //console.log("selected: "+selected);
  React.useEffect(() => {
    setOption(selected == true ? true : false);
    setChecked(selected == true ? true : false);
  }, [selected]);

  const handleChange = (event) => {
    var value = false;
    if (event.target.checked) {
      value = true;
    }
    setChecked(event.target.checked);

    setOption(value);
    //console.log("event.target.value: "+value);
    changeSelect(stateName, value);
  };

  return (
    <div>
      {icon && (
        <FormControlLabel
          control={
            <Checkbox
              labelStyle={{color: 'black'}}
              label={label}
              name={name}
              value={option}
              variant="outlined"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={disabled}
              readOnly={readOnly}
              icon={<CircleUnchecked />}
              required={required}
              checkedIcon={<CircleCheckedFilled  />}
            />
          }
          label={label}
        />
      )}

      {!icon && (
        <FormControlLabel
          control={
            <Checkbox
              labelStyle={{color: 'black'}}
              label={label}
              name={name}
              value={option}
              variant="outlined"
              checked={checked}
              onChange={handleChange}
              required={required}
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={disabled}
            />
          }
          label={label}
        />
      )}
      
    </div>
  );
}

SimpleCheckBox.propTypes = {
  label: PropTypes.string,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.bool,
};
