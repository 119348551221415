import Api from './api';


export const get = async () => {
    let data = [];
    await Api.get("/")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
};

export const getCategories = async () => {
    let data = [];
    await Api.get("/categories/childrens")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
};

export const getAllCategories = async () => {
    let data = [];
    await Api.get("/categories")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
};