const drawerWidth = 240;

export const Styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardheadercolor:{
    color:'white'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  dropzoneArea: {
    width: "100%",
    marginTop: "20px",
    padding: "10px 15px",
    backgroundColor: "#e3e2e2",
    border: "2px dashed #b7b7b7",
    borderRadius: "5px",
    fontSize: "16px",
    color: "#7d7c7b",
    cursor: "pointer",
  },
  dropzoneItem: {
    width: "fit-content",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    border: "1px solid #CCC",
    borderRadius: "20px",
    marginBottom: "5px",
  },
  deleteItem: {
    height: "10px",
    fill: "#d35555",
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mb20: {
    marginBottom: "20px"
  },
  mb50: {
    marginBottom: "50px"
  },
  actionBox: {
    marginTop: "15px",
    backgroundColor: "#f0f0f0",
    boxShadow: "0 2px 4px rgba(27,31,35,.15)",
    "& div:first-child": {
      
      "& p": {
        fontWeight: "bold",
        margin: 0,
        padding: "6px 10px",
        textAlign: "left",
        "& span.type": {
          fontSize: "11px",
          fontWeight: "normal",
          fontStyle: "italic",
          marginLeft: "10px"
        },
        "& span.date": {
          fontSize: "10px",
          fontWeight: "normal",
          marginLeft: "10px"
        }
      }
    },
    "& div.privacyAction": {
      borderBottom: "1px solid #f50057",
    },
    "& div.publicAction": {
      borderBottom: "1px solid #3f51b5",
    },
    "& p": {
      margin: 0,
      padding: "10px",
      textAlign: "left !important"
    }
  },
  actionItems: {
    marginTop: "40px",
    width: "100%",
  },
  clientView: {
    width: "70%",
    margin: "0 auto",
    "& h2": {
      width: "100%",
      textAlign: "center"
    },
    "& p": {
      width: "100%",
      margin: 0,
      textAlign: "center"
    }
  },
  ImageList: {
      width: "100%",
      height: 250,
  },
  attachmentsBox: {
    padding: "10px",
    border: "1px solid #CCC",
    borderRadius: "4px",
    marginBottom: "10px",
    color: "#919090",
    "& ul": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      marginTop: "10px",
      "& li": {
        marginTop: "5px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "& svg": {
          fontSize: "18px",
        },
        "& a": {
          cursor: "pointer",
        }
      }
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  conversationsList: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  header: {
    padding: "12px",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "1px solid #ccc",
  },
  scrollable: {
    height: "55vh",
    overflowY: "scroll",
    padding: "10px",
    backgroundColor: "white",
    padding: "15px",
  },
  conversation: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "10px",
    margin: "8px",
    width: "100%",
    borderRadius: "8px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    "&:hover": {
      background: "rgb(170 176 207) 0% 0% no-repeat padding-box",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "15px",
      width: "120%",
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
    },
  },
  details: {
    flex: 1,
    marginRight: "12px",
  },
  name: {
    fontWeight: "bold",
    color: "#802A8E",
  },
  time: {
    fontSize: "12px",
    color: "#999",
  },
  avatar: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    marginRight: "12px",
    backgroundColor: "#3F51B4",
    color: "white",
    textAlign: "center",
    lineHeight: "45px",
  },
  lastMessage: {
    fontSize: "14px",
    color: "#777",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "&::after": {
      content: '""',
      display: "inline-block",
      width: 0,
      overflow: "hidden",
    },
    "&.truncate::after": {
      content: "'..'",
    },
  },
  countNotification: {
    fontSize: "12px",
    color: "#ffffff",
    background: "#3852cf 0% 0% no-repeat padding-box",
    borderRadius: "8px",
    padding: "5px",
    height: "25px",
    lineHeight: "15px",
  },
  headerChat: {
    background: "#E4E4E4 0% 0% no-repeat padding-box",
    height: "75px",
    display: "flex",
  },
  avatarChat: {
    width: "53px",
    height: "53px",
    borderRadius: "50%",
    marginLeft: "18px",
    marginTop: "10px",
    backgroundColor: "#545454",
    color: "white",
    textAlign: "center",
    lineHeight: "68px",
  },
  text: {
    fontSize: "18px",
    marginLeft: "12px",
    letterSpacing: "0px",
    color: "#545454",
    marginTop: "27px",
  },
  lineAttendance: {
    height: "50px",
    padding: "4px",
    display: "flex"
  },
  status: {
      backgroundColor: '#0fff06',
      width: '6px',
      height: '30px',
      marginLeft: '15px',
      marginTop: '4px',
      borderRadius: '4px',
  },
  statusSelect: {
      width: '200px',
      marginLeft: '5px',
  },
  statusButtonLine: {
      justifyContent: 'right',
      width: '100%',
      display: 'flex',
  },
  statusButton: {
      padding: '10px',
      alignSelf: 'center',
  },
  iconLineStatus: {
      transform: 'rotateX(180deg)',
      marginTop: '10px',
  },
  chat: {
      backgroundColor: '#e4e4e4',
      height: '61vh',
  },
});
