import React, { useEffect, useState } from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import './styles.css'
import { Button, Grid, IconButton } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import StopIcon from '@mui/icons-material/Stop'
import ReplyIcon from '@mui/icons-material/Reply'
import SimpleAutoCompleteSearch from '../../../../components/auto-complete/autocomplete-search-api';
import Api from "../../../../services/api";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleSelectWithRoundedColor from './simple-with-rounded-color'

function ChatHeaderUserActionbar(props) {
  const {intl} =  props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599.9);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 599.9);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChatUserDetailsSidebar = () => {
    props.handleChatUserDetailsSidebarToggle(!props.chats.issidebaropen)
  }

  return (<div style={{ paddingRight: 10 }}>
    <Grid container spacing={0} >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="omnichannel-chat-header-user-action-bar-info-button-container" >
        <Button
          style={{
            border: '1px solid rgba(0, 0, 0, .4)',
            backgroundColor: props.chats.issidebaropen ? 'rgba(82, 82, 82, .25)' : 'transparent',
            color: 'black',
            textTransform: 'none'
          }}
          variant="outlined"
          onClick={handleChatUserDetailsSidebar}
          startIcon={<InfoOutlinedIcon sx={{ color: '#000' }} />} >
          Informações
        </Button>
      </Grid>
    </Grid>
  </div>)
}

ChatHeaderUserActionbar.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatHeaderUserActionbar)))