import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import AdditionalCalledCostItemsContent from './components/additional-called-cost-item/list';
import AdditionalCalledCostTypesContent from './components/additional-called-cost-type/list';
import AdditionalCalledCostReasonsContent from './components/additional-called-cost-reason/list';
import * as componentActions from '../../redux/actions/calledlistdatas';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { PapiroConsole } from '../../utils/papiroConsole';

class AdditionalCalledCostItemsTabContent extends Component {

  constructor(props) {
    super(props);

    PapiroConsole.log("=== this.props.location ===")
    PapiroConsole.log(this.props.location)

    PapiroConsole.log("=== propServiceTab ===")
    let propTabIndex = 0
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.propServiceTab && this.props.location.state.propServiceTab > 0) {
      propTabIndex = this.props.location.state.propServiceTab
      PapiroConsole.log(this.props.location.state.propServiceTab)
    }
    PapiroConsole.log(propTabIndex)
    
    let fromProps = ""
    if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
      fromProps = this.props.from

    this.state = {
      tabServiceIndex: propTabIndex,
      from: fromProps
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() { }

  render() {
    return (
      <div>
        <Grid container spacing={6} style={{ marginTop: 5, marginBottom: 15 }}>
          <Grid item xs={12} sm={12}>
            <Card className="background-title-search">
              <CardContent>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} md={4}>
                    <Typography variant="h5">
                      {<FormattedMessage id="additional.called.cost.config" />}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}></Grid>
                  <Grid item xs={12} md={2}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Tabs selectedIndex={this.state.tabServiceIndex} onSelect={index => this.setState({ tabServiceIndex: index })}>
          <TabList style={{borderColor:'#BB8FCE'}}>
            <Tab  style={{fontSize: "14px"}}><FormattedMessage id="additional.called.cost.items" /></Tab>
            <Tab  style={{fontSize: "14px"}}><FormattedMessage id="additional.called.cost.types" /></Tab>
            <Tab  style={{fontSize: "14px"}}><FormattedMessage id="additional.called.cost.reasons" /></Tab>
          </TabList>
          <TabPanel>
            <AdditionalCalledCostItemsContent from={this.state.from} props={this.props} />
          </TabPanel>
          <TabPanel>
            <AdditionalCalledCostTypesContent from={this.state.from} props={this.props} />
          </TabPanel>
          <TabPanel>
            <AdditionalCalledCostReasonsContent from={this.state.from} props={this.props} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

AdditionalCalledCostItemsTabContent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AdditionalCalledCostItemsTabContent))))

AdditionalCalledCostItemsTabContent.contextType = AbilityContext