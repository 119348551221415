import React, { Component } from 'react';
import { Tooltip, IconButton, Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.white,
  },
}));

class InfoTooltip extends Component {

  renderTypography(condition, label, value, variant = "subtitle1", gutterBottom = true) {
    return condition && (
      <Typography variant={variant} gutterBottom={gutterBottom}>
        <strong>{label}:</strong><br /> {value}
      </Typography>
    );
  }
  render() {

    const messageProviderId = this.props.messageProviderId;
    const phoneNumber = this.props.phoneNumber;
    const phoneNumberId = this.props.phoneNumberId;
    const accountId = this.props.accountId;
    const registrationNumberRequestMessageProviderConfigAccountSid = this.props.registrationNumberRequestMessageProviderConfigAccountSid;




    const webchatTitle = this.props.webchatTitle;
    const webchatHash = this.props.webchatHash;
    const webchatAccountId = this.props.webchatAccountId;
    const webchatActive = this.props.webchatActive == true ? 'Sim' : 'Não';
    const webchatIsDefault = this.props.webchatIsDefault == true ? 'Sim' : 'Não';
    return (

      <CustomTooltip
        style={{ padding: 0, margin: 0 }}
        title={
          <Box sx={{ p: 2 }}>
            <>
              {this.renderTypography(phoneNumber, "Número de Telefone", phoneNumber)}
              {this.renderTypography(accountId, "Número da Conta", accountId)}
              {this.renderTypography(phoneNumberId, "Número identificador do telefone", phoneNumberId)}
              {this.renderTypography(registrationNumberRequestMessageProviderConfigAccountSid, "Número da conta associado ao número registrado", registrationNumberRequestMessageProviderConfigAccountSid)}

              {/* Propriedades do webchat */}
              {messageProviderId == 6 && (
                <>
                  {this.renderTypography(webchatTitle, "Webchat", webchatTitle)}
                  {this.renderTypography(webchatHash, "Hash", webchatHash)}
                  {this.renderTypography(webchatAccountId, "Identificador da conta", webchatAccountId)}
                  {this.renderTypography(webchatActive, "Ativo", webchatActive, "subtitle1", false)}
                  {this.renderTypography(webchatIsDefault, "Padrão?", webchatIsDefault, "subtitle1", false)}
                </>
              )}
            </>



          </Box>
        }
        arrow
        placement="right"
      >

        <InfoIcon sx={{ fontSize: 25, color: '#1976d2', cursor: 'pointer', padding: 0, margin: 0 }} />

      </CustomTooltip>

    );
  }
}

export default InfoTooltip;
