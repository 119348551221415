import React, { useState, useEffect, memo } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';

const useStyles = color => {
  return {
    backgroundColor: `rgb(${color},0.4)`,
    "& .MuiLinearProgress-bar": {
      backgroundColor: `rgb(${color})`
    }
  }
}

export const ColorfyLinearProgress = memo(function ColorfyLinearProgress(props) {
  
  const [color, setColor] = useState('slagreen')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (props.progress <= 50) {
      setProgress(props.progress)
      setColor('slagreen')
    } else if (props.progress > 50 && props.progress < 100) {
      setProgress(props.progress)
      setColor('slaorange')
    } else if (props.progress >= 100) {
      setProgress(100)
      setColor('slared')
    }
  }, [props.progress])

  return (
    <LinearProgress
      variant='determinate'
      value={progress}
      className={ color === 'slagreen' ? 'slaGreen' : color === 'slaorange' ? 'slaOrange' : 'slaRed' }
    />
  )

})