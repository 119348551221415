import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch, IconButton } from '@mui/material'
import Api from '../../../../services/api'
import * as Service from '../../../../services/calledservicesettingsfileupload.service'
import * as AttachmentsService from '../../../../services/calledservicesettingsfileuploadattachment.service'
import { FormattedMessage, injectIntl } from 'react-intl'
import SimpleHelp from "../../../../components/help"
import SimpleCheckBox from '../../../../components/checkbox/check'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingsfileuploads'
import { DataGrid, GridActionsCellItem, GridToolbarFilterButton  } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import DialogConfirmation from '../../../../components/dialog-confirmation'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit';
import AddCalledServiceSettingsFileUploadItemDialog from './addItemDialog'
import UpdateCalledServiceSettingsFileUploadItemDialog from './updateItemDialog'

function CalledServiceSettingsFileUploads(props) {

  const { classes, intl, userId, hirerId, calledServiceSettingsId } = props

  const [calledServiceSettingsFileUploadList, setCalledServiceSettingsFileUploadList] = useState([])
  const [calledServiceSettingsFileUploadListForDelete, setCalledServiceSettingsFileUploadListForDelete] = useState([])
  const [calledServiceSettingsFileUploadInsertDialogOpen, setCalledServiceSettingsFileUploadInsertDialogOpen] = useState(false)
  const [calledServiceSettingsFileUploadUpdateDialogOpen, setCalledServiceSettingsFileUploadUpdateDialogOpen] = useState(false)

  const [itemEditData, setItemEditData] = useState(null)

  useEffect(() => {
    if (props.calledservicesettingsfileuploads && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.length > 0)
      setCalledServiceSettingsFileUploadList(props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist)
  }, [props.calledservicesettingsfileuploads])

  const NoDataRows = _ => {
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
    )
  }

  const setCalledServiceSettingsFileUploadDelete = calledServiceSettingsFileUpload => setCalledServiceSettingsFileUploadListForDelete(calledServiceSettingsFileUpload)

  const handleOpenCalledServiceSettingsFileUploadInsertModal = isOpen => setCalledServiceSettingsFileUploadInsertDialogOpen(isOpen)

  const handleOpenCalledServiceSettingsFileUploadUpdateModal = isOpen => {
    setCalledServiceSettingsFileUploadUpdateDialogOpen(isOpen)
    if (!isOpen)
      setItemEditData(null)
  }

  const calledServiceSettingsFileUploadDeleteModal = async _ => {
    let idList = ""
    calledServiceSettingsFileUploadListForDelete.map(id => {
      idList += `${id},`
    })

    if (idList.length > 0) {
      props.setLoading(true)
      let response = await Service.deleteItems(idList)
      if (response && response.success) {
        let notIdList = props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.filter(item => !calledServiceSettingsFileUploadListForDelete.includes(item.id))
        if (notIdList != null) {
          let loopOrder = 0
          let notIdListOrdened = notIdList.sort((a, b) => a.id - b.id)
          let notIdListFinal = notIdListOrdened.map(item => {
            return {
              ...item,
              order: ++loopOrder
            }
          })
          props.addCalledServiceSettingsFileUploadsList(notIdListFinal)
        } else
          props.addCalledServiceSettingsFileUploadsList([])
        setCalledServiceSettingsFileUploadListForDelete([])
        props.setLoading(false)
        props.setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
      } else {
        props.setLoading(false)
        props.setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  const editItem = (params) => {
    PapiroConsole.log("=== editItem ===")
    PapiroConsole.log(params)
    props.addCalledServiceSettingsFileUpload(params.row)
    setItemEditData(params.row)
    handleOpenCalledServiceSettingsFileUploadUpdateModal(true)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={12} style={{ padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
        <Grid xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
          { calledServiceSettingsFileUploadListForDelete.length > 0 && <DialogConfirmation
            msgButton="Remover campo de upload de arquivos"
            msg="Você tem certeza que deseja remover este campo de upload de arquivos?"
            msgDisagree={intl.formatMessage({id:"cancel"})}
            msgAgree={intl.formatMessage({id:"yes"})}
            handleConfirmation={e => calledServiceSettingsFileUploadDeleteModal()}
            classes={classes}
            icon={<DeleteIcon />} />}
        </Grid>
        <Grid xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={e => handleOpenCalledServiceSettingsFileUploadInsertModal(true)} >
            Adicionar campo de upload de arquivos
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12}
        style={{
          border: '3px solid #eee'
        }} >
        <div style={{ height: 350 }}>
          <DataGrid
            style={{ fontSize: 14, color: '#000' }}
            className="DataGridStyle"
            components={{
              NoRowsOverlay: NoDataRows,
              Toolbar: GridToolbarFilterButton,
            }}
            rows={calledServiceSettingsFileUploadList}
            rowsPerPageOptions={[10, 25, 50]}
            checkboxSelection
            onSelectionModelChange={itm => {
              if (itm.length > 0) {
                PapiroConsole.log("=== onSelectionModelChange ===")
                PapiroConsole.log(itm)
                setCalledServiceSettingsFileUploadDelete(itm);
              } else {
                setCalledServiceSettingsFileUploadListForDelete([])
              }
            }}
            columns={[
              {
                headerName: intl.formatMessage({id:"edit"}),
                field: "actions",
                renderCell: (params) => (
                  <IconButton
                    arya-label="edit"
                    onClick={() => editItem(params)} >
                    <EditIcon />
                  </IconButton>
                )
              },
              { headerName: intl.formatMessage({ id: "organization.field" }), field: 'label', width: 600 },
              { headerName: intl.formatMessage({ id: "item.order" }), field: 'order', width: 100 }
            ]}
          />
        </div>
      </Grid>
      <AddCalledServiceSettingsFileUploadItemDialog
        open={calledServiceSettingsFileUploadInsertDialogOpen}
        handleOpen={handleOpenCalledServiceSettingsFileUploadInsertModal}
        itemsCount={calledServiceSettingsFileUploadList?.length}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsId={calledServiceSettingsId} />
      <UpdateCalledServiceSettingsFileUploadItemDialog
        open={calledServiceSettingsFileUploadUpdateDialogOpen}
        handleOpen={handleOpenCalledServiceSettingsFileUploadUpdateModal}
        itemsCount={calledServiceSettingsFileUploadList?.length}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsId={calledServiceSettingsId}
        itemData={itemEditData} />
    </Grid>
  )
}

CalledServiceSettingsFileUploads.propTypes = {
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsfileuploads: state.calledservicesettingsfileuploads,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CalledServiceSettingsFileUploads))