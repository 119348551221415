import React from 'react'
import { FormControlLabel, FormGroup } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-root': {
      padding: '5px',
      borderRadius: '8px'
    }
  },
  error: {
    '& .MuiFormControlLabel-label': {
      border: '1px solid rgb(95, 33, 32)',
      color: 'rgb(95, 33, 32)',
      backgroundColor: 'rgb(253, 237, 237)'
    }
  },
  warning: {
    '& .MuiFormControlLabel-label': {
      border: '1px solid rgb(102, 60, 0)',
      color: 'rgb(102, 60, 0)',
      backgroundColor: 'rgb(255, 244, 229)'
    }
  },
  info: {
    '& .MuiFormControlLabel-label': {
      border: '1px solid rgb(1, 67, 97)',
      color: 'rgb(1, 67, 97)',
      backgroundColor: 'rgb(229, 246, 253)'
    }
  },
  success: {
    '& .MuiFormControlLabel-label': {
      border: '1px solid rgb(30, 70, 32)',
      color: 'rgb(30, 70, 32)',
      backgroundColor: 'rgb(237, 247, 237)'
    }
  }
}));

function ChatLabel(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(props.checked);

  const handleChange = (event) => {
    setChecked(event.target.checked)
    //props.handleChange(event.target.checked)
  }

  return (<div>
    <FormGroup>
      <FormControlLabel
      className={clsx(classes.root, { [classes.error]: props.variant == 'error' }, { [classes.warning]: props.variant == 'warning' }, { [classes.info]: props.variant == 'info' }, { [classes.success]: props.variant == 'success' })}
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={props.label} />
    </FormGroup>
  </div>)
}

export default ChatLabel