import { PapiroConsole } from "../../utils/papiroConsole"

export function addCurrent(phoneregistrationrequests) {
    return {
        type: 'ADD_CURRENT',
        phoneregistrationrequests,
    }
}


export function updateMessageProviderConfig(messageproviderconfig) {
    
    return {
        type: 'UPDATE_MESSAGE_PROVIDER_CONFIG',
        messageproviderconfig,
    }
}
