import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/defaultaction/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerId = async (hirerId) => { 
  let data = []
  await Api.get(`/defaultaction/hirer/${hirerId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllActiveByHirerId = async (id) => { 
  let data = []
  await Api.get(`/defaultaction/active/hirer/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginate = async (page, search = '', field = '') => { 
  let url = `/defaultaction/paginate?page=${page}`
  if((search && search.length > 0) && (field && field.length > 0))
    url += `&search=${search}&field=${field}`

  let data = []
  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/defaultaction`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/defaultaction`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/defaultaction/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}