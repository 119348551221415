import React, { Component } from 'react';
import { Grid } from "@mui/material";
import MELikeIcon from "../../../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../../../components/me-feedback-images/unlike-icon";
import { PapiroConsole } from '../../../../utils/papiroConsole';

class RatingScale extends Component {
  constructor(props) {
    super(props);

    // Inicialize o estado do componente
    
    this.state = {
       starRating: this.props.indcsatisfiedserviceindex ? this.props.starRating :  null,
      colors : this.props.colors
    };
    
  }

  updateChildState = (newState) => {
    PapiroConsole.log("ENTREI AQUI 2")
    this.setState({ starRating: null })
  }


  render() {

    PapiroConsole.log(this.state.starRating)
    PapiroConsole.log("this.state.starRating aqui")

    
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.disabledCallRejectionFlag ? this.props.index+1 : this.props.index+2}.</strong> {this.props.title}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
          <div className="vote-gradient">
            {Array.from({ length: this.props.end - this.props.initial + 1 }, (_, index) => (
              <div
                className={"vote-number" + (this.state.starRating == index + this.props.initial  ? ' vote-number-selected' : '')}
                style={{ marginRight: 2, background: this.state.colors[index]}}
                onClick={() => {
                  this.props.changeValuesSurveyQuestions(this.props.index,index + this.props.initial, this.props.indcsatisfiedserviceindex )
                  this.setState({ starRating: index + this.props.initial })
                }} >{index + this.props.initial}</div>
            ))}
          </div>


        </Grid>


        {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt ={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.index+2}.</strong> {this.props.title}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  mt ={3} >
          <div className="vote-gradient">
            <div
              className={"vote-number" + (this.state.starRating == 0 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#EC475C' }}
              onClick={() => {
                this.setState({ starRating: 0 })
              }} >0</div>
            <div
              className={"vote-number" + (this.state.starRating == 1 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#EC475C' }}
              onClick={() => {
                this.setState({ starRating: 1 })
              }} >1</div>
            <div
              className={"vote-number" + (this.state.starRating == 2 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, background: 'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)' }}
              onClick={() => {
                this.setState({ starRating: 2 })
              }} >2</div>
            <div
              className={"vote-number" + (this.state.starRating == 3 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#FB8A31' }}
              onClick={() => {
                this.setState({ starRating: 3 })
              }} >3</div>
            <div
              className={"vote-number" + (this.state.starRating == 4 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#FB8A31' }}
              onClick={() => {
                this.setState({ starRating: 4 })
              }} >4</div>
            <div
              className={"vote-number" + (this.state.starRating == 5 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, background: 'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)' }}
              onClick={() => {
                this.setState({ starRating: 5 })
              }} >5</div>
            <div
              className={"vote-number" + (this.state.starRating == 6 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
              onClick={() => {
                this.setState({ starRating: 6 })
              }} >6</div>
            <div
              className={"vote-number" + (this.state.starRating == 7 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
              onClick={() => {
                this.setState({ starRating: 7 })
              }} >7</div>
            <div
              className={"vote-number" + (this.state.starRating == 8 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, background: 'linear-gradient(90deg, #FFE44B 0%, #10A9FD 100%)' }}
              onClick={() => {
                this.setState({ starRating: 8 })
              }} >8</div>
            <div
              className={"vote-number" + (this.state.starRating == 9 ? ' vote-number-selected' : '')}
              style={{ marginRight: 2, backgroundColor: '#10A9FD' }}
              onClick={() => {
                this.setState({ starRating: 9 })
              }} >9</div>
            <div
              className={"vote-number" + (this.state.starRating == 10 ? ' vote-number-selected' : '')}
              style={{ backgroundColor: '#10A9FD' }}
              onClick={() => {
                this.setState({ starRating: 10 })
              }} >10</div>
          </div>
            </Grid>*/}
      </>
    );
  }
}

export default RatingScale;