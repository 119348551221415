import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table/index-report';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import { FormattedMessage,injectIntl } from 'react-intl';
                import {addCurrentCalled, addAccompanimentsList, addCalled, addAccompanimentsListInCalled } from '../../redux/actions/calleds';
                import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';

                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import ClearIcon from '@mui/icons-material/Clear';

                
                    import * as componentActions from '../../redux/actions/patrimonygroups';
                    class ChildCalleds extends Component {

                    constructor(props) {
                        super(props); 

                        this.typingTimeout = null;

                        const { userSession } = this.props;
                        this.state = {
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],
                            called: {},

                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: ''
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.getCalled = this.getCalled.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        this.getItens(page);
                    }

                
                
                    callSearch(){
                        if(this.state.searchValue != ''){
                            this.getItens(this.state.page);
                        }                        
                    }


                    deleteItem(data, resolve) {
                        let abl = this.context.can('Delete', 'PatrimonyGroup');
                        if (abl == false){
                            const intl = this.props.intl;
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }

                    Api.delete(`/calleds/parent/${data.id}`)
    
                            .then(result => {
                                if (result.data.success) {
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                resolve();
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    

                    };
    
                    componentWillReceiveProps(nextProps){
                           if(nextProps.location && nextProps.location.state === 'reload'){
                              this.componentDidMount();
                          }

                    }
                    async componentDidMount() {
                        await this.getItens();
                    }

                    getCalled(id){
                        this.setState({loading:true});
                        Api.get(`/calleds/calledInfoParent/${id}`)
                        .then(result => {
                          if (result.data.success) {
                            const intl = this.props.intl;
                            // console.log("esse é o item")
                            let item = result.data.data;

                            let times = "";
                        if(item.organizationcategory.techinicalteamorganizationcategories != null){
                            item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                                times = times + item2.techinicalteam.name + ","
                            });
                            times = times.substring(0, times.length - 1);
                        }
                        
                        let rowData =  {
                            ...item,
                            typeName: item.type.name,
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory.parentname,
                            Organization: item.organization.name, 
                            slaAtendimento: getSlaAtendimento(item, this.props.userSession)+"%",
                            slaSolucao: getSlaSolucao(item, this.props.userSession)+"%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            time: times
                        }

                        // let abl = this.context.can('Answer', 'Called');
                        // let abl2 = this.context.can('Edit', 'Called');
                        // if(abl == false && abl2 == false ){
                        //     this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                        //     return false;
                        // }

                        // if(rowData.assigneduser != null){
                        //     if(rowData.assigneduser.id != this.props.userSession.user.id){
                        //         if(rowData.organization.organizationsettings.canviewcalledfromothertechnical == false && this.isClient == false){
                        //             this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: 'Usuário sem permissão de acesso.'});
                        //             return false;
                        //         }
                        //     }
                        // }
                        //console.log(rowData);
                        this.setState({loading:false});
                        this.props.addCurrentCalled(rowData);
                        this.props.addAccompanimentsList(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)));

                        this.props.addCalled(rowData);
                        this.props.addAccompanimentsListInCalled(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)), rowData.id);
                        //this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment`);
                        window.location.reload();
                            
                    
                    
                          }
                          
                        })
                        .catch(err => {
                          if(err.response && err.response.data && err.response.data.errors){
                            const intl = this.props.intl;
                            this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "error",
                              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                            });
                          }
                          else{
                            const intl = this.props.intl;
                            this.setState({
                              loading: false,
                              openNotification: true,
                              notificationVariant: "error",
                              notificationMessage: intl.formatMessage({id:"process.error"})
                            });
                          }
                        });
                      }

                    getItens(page = 1) {

                        let url = `/calleds/byparent?called=${this.props.relationshipcalled}`;


                        this.setState({ loading: true });
                                            Api.get(url)
                            
                                    .then(result => {
                                        if (result.data.success) {

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        return (


                                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0, marginBottom: 50 }}>
                                        <CssBaseline />
                                        <Grid container className={classes.mb20}  spacing={0}>
                                        </Grid>
                                        <Grid container spacing={6}> 
                                            <Grid item xs={12} sm={12}>

                                                
                                            <Card className="background-title-search">
                                                <CardContent>
                                                    
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={12} md={8}>
                                                            <Typography variant="h5">
                                                            {<FormattedMessage id="children.called" />}
                                                            </Typography>
                                                                </Grid>
                                                    </Grid>
                                                </CardContent>
                                                </Card>
                                            </Grid>
                                                            
                                        </Grid>
                                        <Can I='List' a='Called'>      
                                        <MyMaterialTable
                                                         title=""
                                            columns={[
                                                        { title: "ID", field: 'id' },
                                                       { title: intl.formatMessage({id:"title"}), field: 'title' },


                                            ]}
                                            rowClick={(event, called) => {
                                                this.getCalled(called.id);

                                            }}
                                            deleteItem={this.deleteItem} 
                                            data={this.state.resultList}
                                        />
                                        </Can>
                                            
                                        {this.state.loading && (
                                            <Overlay>
                                                <CircularProgress color='secondary' />
                                            </Overlay>
                                        )}
                      

                                        <CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />
                                    </Container>
                        );
                    }
                }
ChildCalleds.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    relationshipcalled: state.relationshipcalled.relationshipcalled
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators({ addCurrentCalled, addCalled, addAccompanimentsList, addAccompanimentsListInCalled }, dispatch);
            export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChildCalleds)));ChildCalleds.contextType = AbilityContext;
