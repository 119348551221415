import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, ContactPhoneOutlined } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import * as Service from '../../../services/address.service';

class Organization extends Component {

  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
     OrganizationId:  this.props.orgId ? this.props.orgId: 0 ,
     OrganizationText: '',

     };

     if(this.props.orgId){
      this.props.save(this.props.orgId);
     }

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      UserList: [],

    };
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.submit = this.submit.bind(this);
    
  }   

                    componentDidMount() {
        this.getOrganizations();
     };
     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     


  closeNotification() {
    this.setState({ openNotification: false });
  }

  submit(){
    const intl = this.props.intl;
    if(this.state.item.OrganizationId !== 0){
      this.props.next();
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
      });
    }
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
    
    this.props.save(value);
 
    this.setState({ loading: false });

  };

    render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
     return (
      <div className={classes.root}>
        <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
                <br />
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                    <SimpleSelect label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} />
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{marginTop:8}}>
                  <Grid item xs={4} sm={4} /> 
                  <Grid  xs={4} sm={4} style={{textAlign: 'center'}}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={this.submit}
                    >
                      {intl.formatMessage({id:"next"})}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} /> 
                </Grid>
                <br />

            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>

      </div>
    );
  }
}
Organization.propTypes = {
                    classes: PropTypes.object.isRequired,
                    save: PropTypes.func,
                    orgId: PropTypes.string
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Organization)));
