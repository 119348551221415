import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import moment from 'moment';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MaterialTable from 'material-table';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import { FormattedMessage,injectIntl } from 'react-intl';
                import { addCalled } from '../../redux/actions/relationshipcalled';
                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import ClearIcon from '@mui/icons-material/Clear';
                import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
                import * as Service from '../../services/relationshipcalled.service';
                import {addCurrentCalled, addCalled as addCall, addAccompanimentsList, addAccompanimentsListInCalled } from '../../redux/actions/calleds';
                import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';

                
                    import * as componentActions from '../../redux/actions/patrimonygroups';
import { PapiroConsole } from '../../utils/papiroConsole';
                    class RelationshipCalleds extends Component {

                    constructor(props) {
                        super(props); 

                        this.typingTimeout = null;

                        const { userSession, calleds } = this.props;

                        this.initialState = {
                            createdUser: userSession.user.id,
                            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
                            CalledCreatetId : 0,
                            CalledTargetId : 0,
                            CalledTargetText:''
                      
                           };
                          
                         PapiroConsole.log(this.props)
                         PapiroConsole.log("props do relacionamento de chamados")
                        this.state = {
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],
                            calledList: [],
                            item: this.initialState,
                            archived : calleds.called && calleds.called.archived ? calleds.called.archived : false,
                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: ''
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);
                        this.changeValues = this.changeValues.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                        this.clearFields = this.clearFields.bind(this);
                        this.getCalled = this.getCalled.bind(this);
                        this.addItem = this.addItem.bind(this);
                        this.getCalleds = this.getCalleds.bind(this)
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        this.getItens(page);
                    }
                
                    setPagination(count,page){
                                            
                                            let totalPage = Math.ceil (count/10);
                                            this.setState({ totalPages:totalPage })
                                            if(count > 0){
                                                if(page == 1){
                                                    if(count < 10){
                                                        this.setState({pageStart:1,pageEnd:count});
                                                    } else{
                                                        this.setState({pageStart:1,pageEnd:10});
                                                    }
                                                } else{
                                                    let pageStart = ((page - 1) * 10) + 1;
                                                    if(count >= page * 10){
                                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                                    } else{
                                                        this.setState({pageStart:pageStart,pageEnd:count});
                                                    }
                                                }
                                            } else {
                                                this.setState({totalPages:0,page:1,count:0});
                                            }
                                            
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });
                
                    }

                    getCalled(id){
                      this.setState({loading:true});
                      Api.get(`/calleds/calledInfoParent/${id}`)
                      .then(result => {
                        if (result.data.success) {
                          const intl = this.props.intl;
                          let item = result.data.data;

                          let times = "";
                      if(item.organizationcategory.techinicalteamorganizationcategories != null){
                          item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                              times = times + item2.techinicalteam.name + ","
                          });
                          times = times.substring(0, times.length - 1);
                      }
                      
                      let rowData =  {
                          ...item,
                          typeName: item.type.name,
                          originRequestName: item.originrequest.name,
                          statusName: item.status.name,
                          OrganizationCategory: item.organizationcategory.parentname,
                          Organization: item.organization.name, 
                          slaAtendimento: getSlaAtendimento(item, this.props.userSession)+"%",
                          slaSolucao: getSlaSolucao(item, this.props.userSession)+"%",
                          assignedUser: item.assigneduser ? item.assigneduser.name : "",
                          requestingUser: item.requestinguser ? item.requestinguser.name : "",
                          time: times
                      }

                      // let abl = this.context.can('Answer', 'Called');
                      // let abl2 = this.context.can('Edit', 'Called');
                      // if(abl == false && abl2 == false ){
                      //     this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                      //     return false;
                      // }

                      // if(rowData.assigneduser != null){
                      //     if(rowData.assigneduser.id != this.props.userSession.user.id){
                      //         if(rowData.organization.organizationsettings.canviewcalledfromothertechnical == false && this.isClient == false){
                      //             this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: 'Usuário sem permissão de acesso.'});
                      //             return false;
                      //         }
                      //     }
                      // }
                      //console.log(rowData);
                      this.setState({loading:false});
                      this.props.addCurrentCalled(rowData);
                      this.props.addAccompanimentsList(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)));

                      this.props.addCalled(rowData);
                      this.props.addAccompanimentsListInCalled(rowData.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)), rowData.id);

                      this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${rowData.id}`);
                      window.location.reload();  
                  
                  
                        }
                        
                      })
                      .catch(err => {
                        console.log("=== ERROR ===")
                        console.log(err)
                        console.log(this.props)
                        if(err.response && err.response.data && err.response.data.errors){
                          const intl = this.props.intl;
                          this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                          });
                        }
                        else{
                          const intl = this.props.intl;
                          this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({id:"process.error"})
                          });
                        }
                      });
                    }

                    async getCalleds(){
                        this.setState({ loading: true });
                        var result = await Service.getCalleds();
                          if (result.success) {
                            let calledId = 0;
                            let formattedList = result.data.map((item,index) => {
                                if(item.id === this.props.relationshipcalled){
                                  calledId = index;
                                }
                                return {
                                  ...item,
                                  parentname: `${item.id}: ${item.title}`,
                                  name: `${item.id}: ${item.title}`,
                    
                              }
                    
                            });
                          formattedList.splice (calledId, 1);
                            this.setState({ loading: false, calledList: formattedList });
                                          
                          } else {
                            this.setState({
                              loading: false, openNotification: true, notificationVariant: 'error',
                              notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                            });
                            Api.kickoff(result);  
                          }
                    };
                
                    callSearch(){
                        if(this.state.searchValue != ''){
                            this.getItens(this.state.page);
                        }                        
                    }
                
                    async clearFields(){
                        await   this.setState({searchValue: '', page:1})
                        this.getItens(1);
                    }

                    changeValues(stateName, value, text = '') {

                        this.setState({ loading: true });
                    
                        this.setState(prevState => ({
                          item: {
                            ...prevState.item,
                            [stateName]: value
                          }
                        }));
                        
                        if(stateName === 'CalledTargetId'){
                              this.setState(prevState => ({
                                item: {
                                  ...prevState.item,
                                  ['CalledTargetText']: text,
                                }
                              }));
                            }
                     
                        this.setState({ loading: false });
                    
                      };

                    addItem(data, resolve) {

                        this.setState({ loading: true });

    if(this.state.item.CalledTargetId == 0){
      const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"empty.field"})
            });
    } else{

      let data = new FormData();



      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          if(key == "CalledTargetId"){
            data.append("CalledTargetId",this.state.item.CalledTargetId.id)
          } else {
            data.append(key, this.state.item[key]);
          }
          
        }
      }

      data.append("CalledCreateId",this.props.relationshipcalled)
      

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/calleds/relation', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;

            this.getItens();

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });


          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });



          this.setState(prevState => ({
            item: {
              ...prevState.item,
              CalledTargetId: 0,
              ['CalledTargetText']: '',
            }
          }));

    }

                };

                    deleteItem(data, resolve) {
                        let abl = this.context.can('Delete', 'PatrimonyGroup');
                        if (abl == false){
                            const intl = this.props.intl;
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }
                    

                    Api.delete(`/calleds/relation/${data.idTarget}`)
    
                            .then(result => {
                                if (result.data.success) {
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                resolve();
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    
 
                    };
    
                    componentWillReceiveProps(nextProps){
                           if(nextProps.location && nextProps.location.state === 'reload'){
                              this.componentDidMount();
                          }

                    }
                    async componentDidMount() {
                        this.getItens();
                        //this.getCalleds();
                    }

                    getItens(page = 1) {

                        let url = `/calleds/relation/paginate?page=${page}&called=${this.props.relationshipcalled}`;
        
                        if(this.state.searchValue != ''){
                            url += `&search=${this.state.searchValue}`
                        }

                        this.setState({ loading: true });
                                            Api.get(url)
                            
                                    .then(result => {
                                        if (result.data.success) {

                                            let count = result.data.count;
                                            this.setState({count:count});
                                            this.setPagination(count,page);

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,
                                                    title: item.calledtarget.title,
                                                    idTarget: item.calledtarget.id

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });

                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        return (


                                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                                        <CssBaseline />
                                        {!this.state.archived &&  (
                                           <Grid container className={classes.mb20}  spacing={0}>
                                             
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    disabled={!this.context.can('View', 'Called')}
                                                    startIcon={<AddIcon />}
                                                    component={Link} {...{ to: `/${window.location.pathname.split("/")[1]}/relationshipcalled-registration` }}
                                                >
                                                    {<FormattedMessage id="add" />}
                                                </Button>
                                          </Grid>
                                        )
                                          
                                        }
                                       

                                        <Card className="background-title-search">
                                                <CardContent>
                                                    
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={12} md={8}>
                                                            <Typography variant="h5">
                                                            {<FormattedMessage id="called.rel" />}
                                                            </Typography>
                                                                </Grid>
                                                    </Grid>
                                                </CardContent>
                                                </Card>
                                        
                                        <Can I='List' a='Called'>      
                                        <MyMaterialTable
                                                         title=""
                                            columns={[

                                                       { title: "ID", field: 'idTarget' },
                                                       { title: intl.formatMessage({id:"title"}), field: 'title' },

                                            ]}
                                            rowClick={(event, called) => {
                                              this.getCalled(called.idTarget);

                                          }}
                                            data={this.state.resultList}
                                            deleteItem={this.deleteItem} 
                                        />


                                        </Can>

                                        

                                        <Can I='List' a='PatrimonyGroup'>
                                            
                                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                                <Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                                            </Grid>

                                            </Can> 
                                            
                                        {this.state.loading && (
                                            <Overlay>
                                                <CircularProgress color='secondary' />
                                            </Overlay>
                                        )}
                      

                                        <CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />
                                    </Container>

                        );
                    }
                }
RelationshipCalleds.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    relationshipcalled: state.relationshipcalled.relationshipcalled,
                    organizationid: state.calleds.called.organization.id,
                    calleds: state.calleds,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators({addCalled,addCurrentCalled, addCall, addAccompanimentsList, addAccompanimentsListInCalled }, dispatch);
            export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RelationshipCalleds)));RelationshipCalleds.contextType = AbilityContext;
