export default function integrationUserVariables(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      return {
        ...state,
        integrationUserVariable: action.integrationUserVariable,
      }
    default:
      return state;
  }
}