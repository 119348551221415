import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import MyMaterialTable from '../../../../components/table';
import { Link } from 'react-router-dom';
import { PapiroConsole } from "src/utils/papiroConsole";

const ConfigMailBoxList = props => {

    const { intl, classes, configMailBoxList, lang } = props

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Button
                    variant='contained'
                    color='secondary'
                    size='large'
                    className={classes.button}
                    startIcon={<Add />}
                component={Link} {...{ to: `/${lang}/configmailbox-registration` }}
                >
                    {intl.formatMessage({ id: "add" })}
                </Button>
            </Grid>
            <Grid item sm={12} xs={12}>
                <MyMaterialTable
                    title={intl.formatMessage({ id: 'mail.box.config.list' })}
                    rowClick={(event, rowData) => {
                        props.handleItemListClick(rowData)
                    }}
                    columns={[
                        { title: intl.formatMessage({ id: "id" }), field: 'id' },
                        { title: intl.formatMessage({ id: "from.mail.receive" }), field: 'frommailreceive' },
                    ]}
                    data={configMailBoxList}
                    deleteItem={props.deleteItem}
                />
            </Grid>
        </Grid>
    )
}

export default ConfigMailBoxList