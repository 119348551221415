import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { withStyles } from "@mui/styles"
import { Card, CardContent, Grid, Typography, Avatar, Link, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import Steps from '@mui/icons-material/Money';
import { FormattedMessage, injectIntl } from 'react-intl';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CloseIcon from '@mui/icons-material/Close'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import TimerIcon from '@mui/icons-material/Timer';



const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "#8884d8",
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceIconSucess: {
    color: 'green'
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  secondaryColor: {
    color: theme.palette.secondary.main
  },
  secondaryColorFill: {
    fill: theme.palette.secondary.main
  }
}));

const Budget = props => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false)
  const { className, total, comparation, positiveMonth, titleBudget, detailsLink, detailsLinkFunction } = props;

  const classes = useStyles();

  const intl = props.intl

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        {!detailsLink ? (
        <Grid
          container
          justify="space-between">
          <Grid item xs={9} sm={10} md={10} lg={10}>
            <Typography 
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {<FormattedMessage id={titleBudget} />}
            </Typography>
            <Typography variant="h3">{total} </Typography>
            { detailsLink && (<Typography onClick={() => setTooltipIsOpen(!tooltipIsOpen)} className={classes.secondaryColor} style={{ textDecoration: 'underline', cursor: 'pointer' }} variant="caption">Detalhes</Typography>) }
            { detailsLinkFunction && (
              <HtmlTooltip
                interactive
                placement="bottom-start"
                open={tooltipIsOpen}
                title={
                  <React.Fragment>
                    {detailsLinkFunction}
                    <Grid container spacing={4} justify="flex-end" style={{ marginTop: 10 }}>
                      <Grid item xs={2} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          type='button'
                          variant='contained'
                          color='primary'
                          onClick={() => setTooltipIsOpen(false)}
                          fullWidth >
                          {intl.formatMessage({id:"close"})}
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </HtmlTooltip>
            )}
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={2}>
            <Avatar className={classes.avatar}>
              {titleBudget == "total.km.covered"  &&
                <PersonPinCircleIcon className={classes.icon} />
              }
              {titleBudget == "displacement.hours"  &&
                <TimerIcon className={classes.icon} />
              }
              {titleBudget == "inloco.attendance"  &&
                <HomeIcon className={classes.icon} />
              }
              {titleBudget == "call.solution.hours"  &&
                <WatchLaterIcon className={classes.icon} />
              }
              {titleBudget == "total.hours"  &&
                <PostAddIcon className={classes.icon} />
              }
              {titleBudget == "total.value"  &&
                <MoneyIcon className={classes.icon} />
              }
            </Avatar>
          </Grid>
        </Grid>):
        (<Grid
          container
          justify="space-between">
          <Grid item xs={9} sm={10} md={10} lg={10}>
            <Grid container direction="column">
              <Grid item>
                <Typography 
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2" >
                  {<FormattedMessage id={titleBudget} />}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center">
                  <Grid item>
                    <Typography variant="h3">{total} </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    { detailsLink && (<Typography onClick={() => setTooltipIsOpen(!tooltipIsOpen)} className={classes.secondaryColor} style={{ textDecoration: 'underline', cursor: 'pointer' }} variant="caption">Detalhes</Typography>) }
                    { detailsLinkFunction && (
                      <HtmlTooltip
                        interactive
                        placement="bottom-start"
                        open={tooltipIsOpen}
                        title={
                          <React.Fragment>
                            {detailsLinkFunction}
                            <Grid container spacing={4} justify="flex-end" style={{ marginTop: 10 }}>
                              <Grid item xs={2} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                  type='button'
                                  variant='contained'
                                  color='primary'
                                  onClick={() => setTooltipIsOpen(false)}
                                  fullWidth >
                                  {intl.formatMessage({id:"close"})}
                                </Button>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        }>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </HtmlTooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={2}>
            <Avatar className={classes.avatar}>
              {titleBudget == "total.km.covered"  &&
                <PersonPinCircleIcon className={classes.icon} />
              }
              {titleBudget == "displacement.hours"  &&
                <TimerIcon className={classes.icon} />
              }
              {titleBudget == "inloco.attendance"  &&
                <HomeIcon className={classes.icon} />
              }
              {titleBudget == "call.solution.hours"  &&
                <WatchLaterIcon className={classes.icon} />
              }
              {titleBudget == "total.hours"  &&
                <PostAddIcon className={classes.icon} />
              }
              {titleBudget == "total.value"  &&
                <MoneyIcon className={classes.icon} />
              }
            </Avatar>
          </Grid>
        </Grid>)}
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  comparation: PropTypes.number,
  positiveMonth: PropTypes.bool
};

export default injectIntl(Budget);
