import React from 'react';
import { Button, Menu, MenuItem, Typography, IconButton, Grid, CircularProgress } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import SimpleHelp from '../help';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
import { Overlay } from '../../styles/global';
import { PapiroConsole } from '../../utils/papiroConsole';


function ItemMenu(props) {

  const intl = props.intl;
  
  PapiroConsole.log("printe as props aqui")
  PapiroConsole.log(props)
  const MyButton = styled(Button)({

    // Outros estilos aqui, se necessário
    '&:hover': {
      // Evite alterar a cor do texto ao passar o mouse
      color: 'white',
      backgroundColor: '#3F51B4', // Define a cor de fundo para transparente ao passar o mouse
    },
  });


  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  var required = false;
  var bgcolor = props.item.required ? "rgba(181, 63, 77, 0.5)"  : "rgba(63, 81, 180, 0.4)"
  var color = props.item.required ? "#B5453F"  : "#3F51B4"
  return (
    <div style={{ marginBottom: '5px', width: '400px', textAlign: 'right' }} >
      
          <Grid container style={{ marginTop: '5px', marginBottom: '15px' }}>
            <Grid item xs={12}>
              <SnackbarContent
                style={{
                  border: '2px dashed #aaa',
                  borderRadius: '6px',
                  background: bgcolor,
                  color: color,
                  //fontWeight:"bolder"

                }}
                message={<div>
                  <Typography variant="h6" component="div" style={{ textAlign: 'left', fontWeight: 'bolder' }}>
                    Nova atividade

                    <Typography variant="body2" component="span" >
                      - {moment(props.item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" component="div" style={{ textAlign: 'left', color: '#6E6E6E', }}>
                    Você foi envolvido na atividade "<strong>{props.item.information}</strong>".
                  </Typography>
                  <Grid container mt={2}  >

                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
                      <MyButton
                        variant="contained" size="small"
                        onClick={() => props.redirectNotification(props.item)}
                        /*component={Link} {...{ to: `/pt/${props.item.redirection}` }}*/>
                        {intl.formatMessage({ id: "verify" })}
                      </MyButton>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right", fontWeight: 'bolder' }} mt={1} >

                      <Button style={{ color: color }} variant="text" onClick={() => props.readNotification(props.item)}><CheckIcon style={{ fontSize: '18px', fontWeight: 'bold' }} /> Marcar como lida</Button>

                    </Grid>
                  </Grid>

                </div>}
              //action={btn(item.redirection, "#3F51B4")}
              >
              </SnackbarContent>
            </Grid>
          </Grid>
        
      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </div>

  );
}

ItemMenu.propTypes = {
  listNotification: PropTypes.array.isRequired,
  notificationName: PropTypes.string.isRequired,
  calledIdOrOrgId: PropTypes.string.isRequired,

};


export default ItemMenu;
