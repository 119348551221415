import React from 'react';
import Path from '../../images/delivered.png';
import Typography from '@mui/material/Typography'
import moment from "moment";

const Icon = props => (
    <div>
        <Typography variant="caption" color="textSecondary">
        {props.date ? moment(props.date).format("HH:mm") : null}
        </Typography>
        <img src={props.src && props.src.length > 0 ? props.src : Path}  
        style={props.style != null ? props.style : {  }}
        title={props.title != null ? props.title : "Delivered"}/>
    </div>
);

export default Icon;
