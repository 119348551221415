export default function calledservicesettings(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
        return {
            ...state,
            calledservicesettings: action.calledservicesettings,
        }
      default:
          return state;
  }
}