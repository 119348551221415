import { Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress, Paper, TableBody, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { StyledIconButton, StyledTable, StyledTableBodyCell, StyledTableCell, StyledTableContainer, StyledTextField } from './styles';

import { useIntl } from 'react-intl';
import CustomizedSnackbars from 'src/components/material-snackbars';
import { Overlay } from 'src/styles/global';
import * as IntegrationServiceCustomFieldService from "../../../services/integration-service-custom-field.service";


const EditableTableCustomFields = ({customFields, serviceId}) => { 
  const intl = useIntl();

  const [rows, setRows] = useState(customFields);
  const [loading, setLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState('error');
  const [notificationMessage, setNotificationMessage] = useState('');

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  
  const handleAddCustomField = async (rowId) => {
    let currentRow = rows.find(row => row.id === rowId);

    if (!currentRow?.name) {
      setMessage(true, 'error', "Nome é obrigatório");
      return;
    }

    if (currentRow) {
      try {
        setLoading(true);

        const payload = {
          serviceId,
          name: currentRow?.fieldname,
          sourceFieldName: currentRow?.name
        }

        const response = await IntegrationServiceCustomFieldService.create(payload);

        if (!response?.success) {
          setMessage(true, 'error', response?.data?.errors[0] ?? intl.formatMessage({ id: "process.error" }));
        } else {
          const newId = response?.data?.customfield?.id;

          const newRows = [...rows];
          const index = newRows?.findIndex(row => row?.id === currentRow?.id);

          if (index !== -1) {
            newRows[index] = {...newRows[index], id: newId, isEditable: false};
            setRows(newRows);
          }

          setMessage(true, 'success', intl.formatMessage({ id: "Adicionado com sucesso." }));
        }

      } catch (err) {
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }));
      } finally {
        setLoading(false);
      }
    }
  }

  const handleRemoveCustomField = async (rowId) => {
    let currentRow = rows.find(row => row.id === rowId);

    if (currentRow) {
      try {
        setLoading(true);

        const response = await IntegrationServiceCustomFieldService.deleteCustomField(rowId);

        if (!response?.success) {
          setMessage(true, 'error', response?.data?.errors[0] ?? intl.formatMessage({ id: "process.error" }));
        } else {
          const newId = response?.data?.customfield?.id;

          const newRows = [...rows];
          const index = newRows?.findIndex(row => row?.id === rowId);

          if (index !== -1) {
            newRows[index] = {...newRows[index], id: newId, isEditable: true, name: ''};
            setRows(newRows);
          }
        }
      } catch (err) {
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }));
      } finally {
        setLoading(false);
      }
    }
  }

  const handleChangeFieldName = (e, rowId) => {
    let name = e.target.value;
    let newRowList = [...rows];
    let index = newRowList.findIndex(row => row.id === rowId);

    if (index !== -1) {
      newRowList[index] = {...newRowList[index], name};
      setRows(newRowList);
    }
  }
  
  return (
      <StyledTableContainer component={Paper}>
          <StyledTable>
              <TableHead>
                  <TableRow>
                      <StyledTableCell>Campo</StyledTableCell>
                      <StyledTableCell>Nome</StyledTableCell>
                      <StyledTableCell>Ação</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {rows.map((row, index) => (
                      <TableRow key={index}>
                          <StyledTableBodyCell>
                            {row.fieldname}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell>
                              {row.isEditable ? (
                                  <StyledTextField
                                    value={row.name}
                                    onChange={(e) => handleChangeFieldName(e, row.id)}
                                  />
                              ) : (
                                row.name
                              )}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell>
                              {row.isEditable ? (
                                  <StyledIconButton onClick={() => handleAddCustomField(row.id)}>
                                      <SaveIcon />
                                  </StyledIconButton>
                              ) : (
                                  <StyledIconButton delete onClick={() => handleRemoveCustomField(row.id)}>
                                      <Delete />
                                  </StyledIconButton>
                              )}
                          </StyledTableBodyCell>
                      </TableRow>
                  ))}
              </TableBody>
          </StyledTable>

          {loading && (
            <Overlay>
                <CircularProgress color='secondary' />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={notificationVariant}
            message={notificationMessage}
            isOpen={openNotification}
            toClose={closeNotification} 
          />
      </StyledTableContainer>
  );
}

export default EditableTableCustomFields;