import React, { } from 'react';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactQuill, { Quill } from 'react-quill'; // ES6
import Dropzone from 'react-dropzone';
import { DeleteForever } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';







function DialogWithRichTextAndAttachment(props) {

    const intl = props.intl;
    var reactQuillModules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],	
        ],
    }

    var reactQuillFormats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color', 'font'
    ]



    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.dialogSubTitle}
                    </DialogContentText>

                    <Grid item xs={12} sm={12} mt={2} mb={2}>
                        
                        <ReactQuill name={props.nameDescription} /*value={props.value}*/ style={{ height: 200 }} modules={reactQuillModules} formats={reactQuillFormats}
                            fullWidth
                            required
                            variant="outlined"
                            id="outlined-multiline-static"
                            onChange={(content, delta, source, editor) => props.handleChangeEditor(content, delta, source, editor, props.nameDescription)} />

                    </Grid>
                    <Grid item xs={12} sm={12} mt={7} mb={4}>
                        <section style={{ width: '100%' }}>
                            <div className={props.classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                                <Dropzone onDrop={acceptedFiles => props.validateAtachments([...acceptedFiles, props.files])}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <AttachFileIcon />
                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                <FormattedMessage id="drag.and.drop.files" />
                                            </Typography>
                                        </div>
                                    )}
                                </Dropzone>
                                {props.files.map(file => {
                                    return (
                                        <div className={props.classes.dropzoneItem} key={file.name}>
                                            {file.name}
                                            <DeleteForever className={props.classes.deleteItem} onClick={() => { props.removeItem(file) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.openOrCloseModalFunction()} color="primary" >
                        {intl.formatMessage({ id: "cancel" })}
                    </Button>
                    <Button onClick={() => { props.handleConfirmation() }} variant="contained" color="primary" autoFocus>
                        {intl.formatMessage({ id: "confirm" })}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default injectIntl(DialogWithRichTextAndAttachment);