export function addCurrent(organizationsla) {
    return {
        type: 'ADD_CURRENT',
        organizationsla,
    }
}

export function addCurrentOrganization(organizationsla,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
            organizationsla,
            organization,
    }
}