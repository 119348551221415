import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../redux/actions/chats'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base'
import ToTalkChatAttendanceContent from './index.content'
import { injectIntl } from 'react-intl';

class ToTalkChatAttendance extends Component {

  constructor(props) {
    super(props)
    const { userSession } = this.props;

	   const intl = this.props.intl;

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            userSession: userSession,
            
            
        };
      
      }

  componentWillReceiveProps(nextProps){
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }
                    
  componentDidMount() {
		
	
  }
 
  

  render() {      
    return (           
      <Base props={this.props} customStyle={{ maxWidth: '100%', width: '100%', paddingBottom: 0 }}>
        <ToTalkChatAttendanceContent props={this.props}/>
      </Base>        
    )
  }
}

ToTalkChatAttendance.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ToTalkChatAttendance)));
ToTalkChatAttendance.contextType = AbilityContext;