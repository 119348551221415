import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Fab from '@mui/material/Fab';
import { PapiroConsole } from '../../utils/papiroConsole';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2)
  },

}));

export default function MenuListComposition(props) {

  PapiroConsole.log("=== MenuListComposition ===")
  PapiroConsole.log(props.items)

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} style={props.style} >
      <div>
        {/*<IconButton>*/}
        {(props.tooltip != null && props.tooltip) ? <Tooltip title={props.tooltipTitle} aria-label="menu-list-grow">
          <Fab
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            variant="extended"
            size="medium"
            color="secondary"
            aria-label="add" >
            { (props.labelLeft && props.labelLeft.length > 0) && props.labelLeft }
            <ArrowDropDownIcon />
            { (props.labelRight && props.labelRight.length > 0) && props.labelRight }
          </Fab>
        </Tooltip>
        :
          <Fab
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            variant="extended"
            size="medium"
            color="secondary"
            aria-label="add" >
            { (props.labelLeft && props.labelLeft.length > 0) && props.labelLeft }
            <ArrowDropDownIcon />
            { (props.labelRight && props.labelRight.length > 0) && props.labelRight }
          </Fab>}
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: "999"}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.items.map((item, index) => ( <MenuItem key={index} onClick={handleClose}> {item} </MenuItem> ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
          </Popper>
      </div>
    </div>
  );
}