import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { withRouter } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../../styles/material-styles'
import { AbilityContext } from '../../../../../src/config/ability-context';
import Avatar from '@mui/material/Avatar';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as componentActions from '../../../../../src/redux/actions/organizationcategoriesportal'
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActionArea } from '@mui/material';
import moment from 'moment';

import Api from '../../../../../src/services/api';

import { FormattedMessage, injectIntl } from 'react-intl';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import Cardinformations from '../../../cardinformations';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const CardCategories = props => {

  const { element,actualElement, properties, maxLength } = props;
  

  
  

  const intl = props.intl;
  const [expanded, setExpanded] = React.useState(false);
  const [urlImage, setUrlImage] = React.useState("");
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  React.useEffect(async () => {

    if (element.attachmentid) {

      let url = await getImage(element.attachmentid)
      setUrlImage(url)


    }

  }, []
  );






  const moveToAnotherPage = async (nextElement,actualElement) => {

    
    await props.addCurrent(nextElement);
    props.history.push({
      pathname: `/${props.match.params.lang}/portal-category/${nextElement.id}`,
      state: {
        action: "cardReload",
        parentId: actualElement
      }

    })

  }

  const getImage = async (AttachmentId) => {

    if (AttachmentId != null && AttachmentId != undefined) {
      var url = ""
      await Api.post(`/organizationcategories/attachments`, { AttachmentId })
        .then((result) => {




          if (result.data) {
            if (result.data.data && result.data.data.length != undefined && result.data.data.length != 0) {


              url = result.data.data


              return result

            }

          }

        })
        .catch((err) => {
          PapiroConsole.log(err);
          /*this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });*/

          // PapiroConsole.log("entrei aqui");
        });

      return url

    }

  }






  return (



    <Grid item xs={12} sm={6} lg={4} xl={3}
    >

      <CardActionArea style={{ height: "100%" }} >
        <Card  onClick={() => moveToAnotherPage(element,actualElement)} style={{padding:"10px", height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >

          <CardHeader
           titleTypographyProps={{
            fontSize: 20,
          }}
            style={{ margin: '0', padding: '0'}}
            avatar={
              <Avatar sx={{ bgcolor: "#3f51b4", width: 40, height: 40 }} aria-label="recipe">
                {element.name.charAt(0).toUpperCase()}
              </Avatar>
            }
            /* action={
               <IconButton aria-label="settings">
                 <MoreVertIcon />
               </IconButton>
             }*/
            title={element.name}
            

          />
          {/* <CardMedia
            component="img"
            height="100"
            style={{ margin:'0',padding:'0'}}

            //src={`${getImage(element.attachmentid)}`}
            //urlImage
            src={`${urlImage}`/*`${Api.apiurlbase()}${this.state.calledreportconfig.image.path}`}
            //image="https://mui.com/static/images/cards/paella.jpg"//{getImage(element.attachmentid)}
            sx={{
               objectFit: "contain",
            }}

          />*/}

          <CardMedia  style={{ display:'flex', justifyContent:'center' }}>
            {element.attachmentid != null && element.attachmentid != undefined 
              
              ? <img src={`${urlImage}`} height="120px" width="120" />
              
              : null
            
            }
            
          </CardMedia>
          <CardContent >
            {/*<Typography variant="body2" color="text.secondary">

              {maxLength ?
                <div dangerouslySetInnerHTML={{ __html: element.description ? element.description.substring(0, maxLength) + '...' : '' }} />
                :
                <div dangerouslySetInnerHTML={{ __html: element.description }} />

              }



            </Typography>*/}
          </CardContent>







        </Card>

      </CardActionArea>


    </Grid>



  );


};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
  organizationcategoryportal: state.organizationcategoryportal
});




//export default injectIntl(CardCategories);

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CardCategories))));
CardCategories.contextType = AbilityContext;
