import React, { Component } from 'react';
import { Grid } from "@mui/material";
import MELikeIcon from "../../../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../../../components/me-feedback-images/unlike-icon";
import Rating from '@mui/material/Rating';
import { PapiroConsole } from '../../../../utils/papiroConsole';
const styles = {

  regularFont: {
    color: "black",
    fontWeight: "700",
    margin: "0px",
    padding: "0px",
  },

  grayFont: {
    margin: "0px",
    padding: "0px",
    display: 'flex',
    color: "#909497 ",
    fontSize: "15px",
    fontWeight: "500",
  },

  alignMiddle: {

    verticalAlign: "middle"
  },

}

class RatingScaleOldLayout extends Component {
  constructor(props) {
    super(props);

    // Inicialize o estado do componente
    this.state = {
      starRating: this.props.indcsatisfiedserviceindex ? this.props.starRating :  0,

    };

    PapiroConsole.log(this.props.indcsatisfiedserviceindex)
    PapiroConsole.log("this.props.indcsatisfiedserviceindex")

    PapiroConsole.log(this.props.starRating)
    PapiroConsole.log("this.props.starRating old layout ")

  }

  



  render() {

    return (
      <>
        {/*<div style={{marginTop: "15px"}}>
            <div>
              <h4 style={styles.regularFont} > {this.props.title} </h4>
            </div>
           
    </div>*/}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.disabledCallRejectionFlag ? this.props.index + 1 : this.props.index + 2}.</strong> {this.props.title}</p>
        </Grid>
        <Grid item mt={1} xs={12} sm={12} container alignItems="center" justifyContent="center" direction="row"  >
          <Rating
            style={{ fontSize: "45px" }}
            name="rating"
            required
            value={this.state.starRating}
            max={this.props.end}
            onChange={(event, newValue) => {
              this.setState({
                starRating: newValue
              });
              this.props.changeValuesSurveyQuestions(this.props.index, newValue, this.props.indcsatisfiedserviceindex)
            }}
          />
        </Grid>



      </>
    );
  }
}

export default RatingScaleOldLayout;