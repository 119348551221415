import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithOrganizationFieldOptionsValuesIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/${id}/withorganizationfieldoptionvaluesincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsOrganizationFieldId = async (calledServiceSettingsOrganizationFieldId) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/all/calledservicesettingsorganizationfield/${calledServiceSettingsOrganizationFieldId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsOrganizationFieldIdWithoutIncludes = async (calledServiceSettingsOrganizationFieldId) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/all/calledservicesettingsorganizationfield/${calledServiceSettingsOrganizationFieldId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsOrganizationFieldIdWithOrganizationFieldOptionsValuesIncludes = async (calledServiceSettingsOrganizationFieldId) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldvalidation/all/calledservicesettingsorganizationfield/${calledServiceSettingsOrganizationFieldId}/withorganizationfieldoptionvaluesincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginate = async (page, search, field, size = 10) => { 
  let data = []

  let url = `/calledservicesettingsorganizationfieldvalidation/paginate?page=${page}&size=${size}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledservicesettingsorganizationfieldvalidation`, formData, { headers: { "Content-Type": "multipart/form-data" } })
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledservicesettingsorganizationfieldvalidation`, formData, { headers: { "Content-Type": "multipart/form-data" } })
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettingsorganizationfieldvalidation/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}