import Api from './api';
import ApiReports from "./apireport";

export const getLastFeedbacks = async (dateStart,dateEnd, organizationId = 0, techinicalTeamId = 0, defaultDate = null ) => { 
  let data = [];
  await Api.get(`/dashboard/lastFeedback/dapper?dateStart=${dateStart}&dateEnd=${dateEnd}&organizationid=${organizationId}&techinicalTeamId=${techinicalTeamId}&defaultDate=${defaultDate}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};


export const exportDashboardOperation = async (formData) => { 
  let data = []
  await ApiReports.post(`/dashboard/operation/excell`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}


