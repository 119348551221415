import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch } from '@mui/material'
import Api from '../../../services/api'
import * as Service from '../../../services/configpasswordpolicy.service'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux';

class ConfigPasswordPolicy extends Component {

  constructor(props) {
    super(props)
    
    const { configpasswordpolicy, userId } = this.props
    
    let itemData = {}
    if (configpasswordpolicy && configpasswordpolicy.configpasswordpolicy && configpasswordpolicy.configpasswordpolicy.id)
      itemData = configpasswordpolicy.configpasswordpolicy
  
    this.state = {
      configpasswordpolicy: {
        id: itemData && itemData.id ? itemData.id: 0,
        createduser: userId,
        updateduser: userId,
        hirerid: itemData && itemData.hirerid ? itemData.hirerid: null,
        configid: itemData && itemData.configid ? itemData.configid: null,
        minimumamountcharacters: itemData && itemData.minimumamountcharacters ? itemData.minimumamountcharacters: null,
        passwordexpirationtime: itemData && itemData.passwordexpirationtime ? itemData.passwordexpirationtime: null,
        notallowrepeatnumber: itemData && itemData.notallowrepeatnumber ? itemData.notallowrepeatnumber: false,
        notallowsequentialnumber: itemData && itemData.notallowsequentialnumber ? itemData.notallowsequentialnumber: false,
        notallowcaracterlinkeduser: itemData && itemData.notallowcaracterlinkeduser ? itemData.notallowcaracterlinkeduser: false,
        atleastonespecialcaracter: itemData && itemData.atleastonespecialcaracter ? itemData.atleastonespecialcaracter: false,
        atleastonenumber: itemData && itemData.atleastonenumber ? itemData.atleastonenumber: false,
        atleastoneletteruppercase: itemData && itemData.atleastoneletteruppercase ? itemData.atleastoneletteruppercase: false,
        atleastoneletter: itemData && itemData.atleastoneletter ? itemData.atleastoneletter: false,
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
  }

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    this.setState(prevState => ({
      configpasswordpolicy: {
        ...prevState.configpasswordpolicy,
        [name]: checked
      }
    }))
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    let { configpasswordpolicy } = this.state
    
    let data = new FormData();

    for (let key in configpasswordpolicy) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1)
        data.append(key, moment(configpasswordpolicy[key]).format('YYYY/MM/DD HH:mm:ss'))
      else
        data.append(key, configpasswordpolicy[key])
    }
    
    let isEdit = configpasswordpolicy.id === 0 ? false : true

    let response = await Service.mergeItem(data);
    if (response && response.success) {
      setLoading(false)
      setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "save.success" }))
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      configpasswordpolicy: {
        ...prevState.configpasswordpolicy,
        [name]: value
      }
    }));
  };

  render() {
    const { classes, intl } = this.props
    const { configpasswordpolicy } = this.state

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography component='h1' variant='h3'>
              <FormattedMessage id="passwordpolicy.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" variant="standard">

              <TextField 
                label={intl.formatMessage({ id:"passwordpolicy.passwordexpirationtime" })}
                type='number' 
                variant='filled' 
                InputLabelProps={{shrink: true}} 
                name='passwordexpirationtime' 
                onChange={this.handleInputChange} 
                value={configpasswordpolicy.passwordexpirationtime}  
                style={{padding: 5}}
              />

              <TextField 
                label={intl.formatMessage({ id:"passwordpolicy.minimumamountcharacterstitle" })}
                type='number' 
                variant='filled' 
                InputLabelProps={{shrink: true}} 
                name='minimumamountcharacters' 
                onChange={this.handleInputChange} 
                value={configpasswordpolicy.minimumamountcharacters}  
                style={{padding: 5}}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.atleastoneletteruppercase}
                      onChange={this.handleChangeSwitch}
                      name="atleastoneletteruppercase" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.atleastoneletteruppercase" })} />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.atleastonespecialcaracter}
                      onChange={this.handleChangeSwitch}
                      name="atleastonespecialcaracter" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.atleastonespecialcaracter" })} />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.atleastoneletter}
                      onChange={this.handleChangeSwitch}
                      name="atleastoneletter" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.atleastoneletter" })} />
              </FormGroup>

              <FormGroup> 
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.atleastonenumber}
                      onChange={this.handleChangeSwitch}
                      name="atleastonenumber" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.atleastonenumber" })} />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.notallowsequentialnumber}
                      onChange={this.handleChangeSwitch}
                      name="notallowsequentialnumber" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.notallowsequentialnumber" })} />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.notallowrepeatnumber}
                      onChange={this.handleChangeSwitch}
                      name="notallowrepeatnumber" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.notallowrepeatnumber" })} />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={configpasswordpolicy.notallowcaracterlinkeduser}
                      onChange={this.handleChangeSwitch}
                      name="notallowcaracterlinkeduser" />
                  }
                  label={intl.formatMessage({ id:"passwordpolicy.notallowcaracterlinkeduser" })} />
              </FormGroup>

            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={12} sm={4}    >
                <Button 
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit} >
                  {<FormattedMessage id="save" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ConfigPasswordPolicy.propTypes = {
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  configpasswordpolicy: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired
}

export default connect(null, null)(ConfigPasswordPolicy)