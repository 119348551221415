import React, { Component } from 'react';
import { Grid } from "@mui/material";
import MELikeIcon from "../../../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../../../components/me-feedback-images/unlike-icon";
import '../../../feedback/styles.css'
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
const styles = {

  regularFont: {
    color: "black",
    fontWeight: "700",
    margin: "0px",
    padding: "0px",
  },

  grayFont: {
    margin: "0px",
    padding: "0px",
    display: 'flex',
    color: "#909497 ",
    fontSize: "15px",
    fontWeight: "500",
  },

  alignMiddle: {

    verticalAlign: "middle"
  },

}
class ThumbsUpOrDownOldLayout extends Component {
  constructor(props) {
    super(props);

    // Inicialize o estado do componente
    this.state = {
      trueOption: null,
    };
  }



  render() {
    return (
      <>
        {/*<div mt ={3}>

          <h4 style={styles.regularFont} > {this.props.title} </h4>
    </div >*/}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.disabledCallRejectionFlag ? this.props.index + 1 : this.props.index + 2}.</strong> {this.props.title}</p>
        </Grid>
        <Grid container spacing={2}  >
          <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>

            <ThumbUp
              className={"thumbs-up" + (this.state.trueOption == 1 ? ' thumbs-up-selected' : '')}
              style={{ fontSize: '32px' }}
              onClick={() => {
                this.props.changeValuesSurveyQuestions(this.props.index, true)
                this.setState({ trueOption: 1 })
              }} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <ThumbDown style={{ fontSize: '32px' }}
              className={"thumbs-up" + (this.state.trueOption == 0 ? ' thumbs-up-selected-red' : '')}
              onClick={() => {
                this.props.changeValuesSurveyQuestions(this.props.index, false)
                this.setState({ trueOption: 0 })
              }} />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ThumbsUpOrDownOldLayout;