export default function chatbotflows(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      return {
        ...state,
        chatbotflow: action.chatbotflow,
      }
    default:
      return state;
  }
}