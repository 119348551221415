import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import Grid from '@mui/material/Grid'
import * as Service from '../../../services/apiCallCenter';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import { FormattedMessage,injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/,/\d/,/\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function SharedSms(props) {

    const classes = useStyles();

    // const url = window.location.href + props.propsModal.propsModal.cod;
    // const pass = props.propsModal.propsModal.password;
    const url = window.location.href + props.propsModal.cod;
    const pass = props.propsModal.password;
    const intl = props.intl;

    const [values, setValues] = React.useState({
        loading: false,
        sms_name: '',
        phone_sms: props.propsModal.requestUser.phone
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            ['phone_sms']: event,
        });
    };

    const submitHandler = async e => {
        e.preventDefault()

        if(props.propsModal.hirerid != 0 && props.propsModal.hirerid != null && props.propsModal.hirerid != ""){
            props.onLoading();

            setValues({ ...values, loading:true });
            
            let phone = props.propsModal.requestUser ? props.propsModal.requestUser.phone : null;

            if(phone != null){
                
                props.onLoading();

                setValues({ ...values, loading:true });
                
                const contentSMS = this.props.intl.formatMessage({id:"sharedSms.virtual.attendance.link"}) + url + this.props.intl.formatMessage({id:"password"}) + ": " + pass;
                let phone = "";
                phone += values.phone_sms.replace("(", "").replace(")","").replace("-","").replace(" ", "");

                let dataset = {
                    phone: phone,
                    msg: url,
                    password: pass
                }

                //await Service.sendSMSApi(dataset);
                //props.propsModal.eventInit(phone);
                props.propsModal.eventInit(phone, false, 'sms', dataset);
    
            }
        }

    }

    return (
        <div>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} xl={3} lg={3}></Grid> 
                    <Grid item xs={12} sm={6} xl={6} lg={6}>
                        <FormControl fullWidth className={clsx(classes.margin)} variant="outlined">
                            {/*<InputLabel htmlFor="phone_sms">Telefone</InputLabel>
                            <OutlinedInput
                                id="phone_sms"
                                name="phone_sms"
                                labelwidth={60}
                                value={props.propsModal.requestUser.phone}
                                onChange={handleChange}
                                //inputComponent={TextMaskCustom}
                            />*/}
                            <PhoneInput
                                inputStyle={{
                                    padding: '23.5px 14px 23.5px 58px',
                                    width: '100%'
                                }}
                                style={{
                                    marginTop: 16,
                                    marginBottom: 8
                                }}
                                id="phone_sms"
                                name="phone_sms"
                                country={'br'}
                                value={values.phone_sms}
                                onChange={handleChange}
                                InputLabel={intl.formatMessage({id:"phone"})}
                                specialLabel={intl.formatMessage({id:"phone"})}
                                inputProps={{
                                    name: intl.formatMessage({id:"phone"})
                                }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3} lg={3}></Grid> 
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={submitHandler} color="primary" type="submit" style={{float: 'right'}}>
                            {intl.formatMessage({id:"send"})}
                    </Button>
                    </Grid>
                </Grid>

            </form>

        </div>
    )
}
export default injectIntl(SharedSms);