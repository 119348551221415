export function addCalledServiceSettingsValidationsList(calledservicesettingsvalidationslist) {
  return {
      type: 'ADD_LIST_CALLED_SERVICE_SETINGS_VALIDATION',
      calledservicesettingsvalidationslist,
  }
}

export function addCalledServiceSettingsValidation(calledservicesettingsvalidation) {
  return {
      type: 'ADD_CALLED_SERVICE_SETINGS_VALIDATION',
      calledservicesettingsvalidation,
  }
}