import Api from './api';

export const paginate = async (page, org, search, field) => { 
  let data = []

  let url = `/additionalcalledcostitemtypes/paginate?page=${page}&org=${org}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginateByOrganization = async (page, orgId, search, field) => { 
  let data = []

  let url = `/additionalcalledcostitemtypes/organization/paginate?page=${page}&id=${orgId}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAll = async () => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/all`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllActive = async () => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/all/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getById = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveById = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/${id}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/${id}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationId = async (additionalCalledCostItemId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/organization/${additionalCalledCostItemId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationId = async (additionalCalledCostItemId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/organization/${additionalCalledCostItemId}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationIdWithoutIncludes = async (additionalCalledCostItemId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/organization/${additionalCalledCostItemId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationIdWithoutIncludes = async (additionalCalledCostItemId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitemtypes/organization/${additionalCalledCostItemId}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData, config) => { 
  let data = []
  await Api.post(`/additionalcalledcostitemtypes`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData, config) => { 
  let data = []
  await Api.put(`/additionalcalledcostitemtypes`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/additionalcalledcostitemtypes/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}