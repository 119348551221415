import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import Select from '../Input/Select/Select';
import { useBaseInspector } from './useBaseInspector';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Dialog, DialogTitle } from '@mui/material';
import ReactQuill from 'react-quill';
import RequestIntegrationService from 'src/pages/integration-service-edit/components/request-integration-service';
import * as IntegrationService from "../../../../../../../../services/integrationservice.service";
import HelperCustomField from '../../HelperCustomField/HelperCustomField';

interface Props {
    cell: shapes.app.Service;
    chatbotFlowId: string;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    serviceId: ['attrs', 'serviceId', 'id'],
    isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
};

const ServiceInspector = (props: Props): ReactElement => {

    const { cell, chatbotFlowId } = props;

    const [servicesOptions, setServicesOptions] = useState([]);

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [serviceId, setServiceId] = useState(null);
    const [isTextRich, setIsTextRich] = useState<boolean>(false);

    const [openModal, setOpenModal] = useState(false);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setServiceId(cell.prop(cellProps.serviceId));
        setIsTextRich(cell.prop(cellProps.isTextRich));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    const fetchServices = async () => {
        const services = await IntegrationService.getList();
        setServicesOptions(services ?? []);
    }

    useEffect(() => {
        fetchServices();
    }, [])

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <div 
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    marginTop: '8px',
                    marginBottom: '8px'
                }}
            >
                <input 
                    type="checkbox" 
                    style={{
                        height: '16px',
                        width: '16px',
                        margin: '0',
                        padding: '0'
                    }} 
                    checked={isTextRich}
                    onChange={(e) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}
                />
                <label
                    style={{
                        marginTop: '4px'
                    }}
                >
                    É um texto rico ?
                </label>
            </div>

            {
                isTextRich
                ? (
                    <label>Descrição
                        <ReactQuill
                            name="description"
                            style={{ height: 150, marginBottom: '82px' }}
                            fullWidth
                            variant="outlined"
                            id="descriprion"
                            value={description}
                            onChange={(value: any) => changeCellProp(cellProps.description, value)}
                        />
                    </label>
                )
                : (
                    <label>Descrição
                        <Input 
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                        />
                    </label>
                )
            }

            <HelperCustomField
                isRichText={isTextRich}
                description={description}
                param1={cellProps.description}
                onChangeDescription={changeCellProp}
                chatbotFlowId={chatbotFlowId}
            />

            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <label>Serviço
                    <Select
                        value={serviceId}
                        onChange={(e) => changeCellProp(cellProps.serviceId, e.target.value)}
                        options={servicesOptions}
                    />
                </label>

                {serviceId && (
                    <button className='button-save' onClick={() => setOpenModal(true)} style={{marginBottom: '16px'}}>
                        Testar serviço
                    </button>
                )}
            </div>

            <>
                <Dialog 
                    open={openModal} 
                    onClose={() => setOpenModal(false)} 
                    aria-labelledby="form-dialog-title"  
                    fullWidth
                    maxWidth="lg" 
                    PaperProps={{
                        style: { width: "100%", maxWidth: "900px", minHeight: '400px' } 
                    }}
                >
                    <DialogTitle id="form-dialog-title">
                        Testar serviço
                        <ClearIcon 
                            onClick={() => setOpenModal(false)} 
                            style={{ float: 'right', cursor : 'pointer'}}
                        /> 
                    </DialogTitle>
                    <Box>
                        <div style={{width: '100%', padding: '24px'}}>
                            <RequestIntegrationService
                                token={null}
                                integrationServiceId={serviceId}
                                isOpenFromJoint={true}
                            />
                        </div>
                    </Box>     
                </Dialog>
            </>

        </>
    );
};

export default ServiceInspector;
