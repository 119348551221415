import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingsorganizationfields'
import Api from '../../../../services/api'
import { addItem } from '../../../../services/calledservicesettingsorganizationfield.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import { getAllActiveByOrganizationIdWithoutIncludes, getAllActiveByOrganizationIdAndCalledServiceSettingIdWithoutIncludes } from '../../../../services/organizationfield.service'
import AttachFileIcon from '@mui/icons-material/AttachFile';
function AddCalledServiceSettingsOrganizationFieldItemDialog(props) {

  const { intl } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [organizationFieldId, setOrganizationFieldId] = useState(null)
  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const [requiredFieldMessage, setRequiredFieldMessage] = useState('')
  const [files, setFiles] = useState([])

  const [orderList, setOrderList] = useState([])
  const [organizationFieldList, setOrganizationFieldList] = useState([])

  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)

    if (props.open) {
      setFiles([])
      getAllOrganizationFieldsByOrganization(props.organizationId)
    }
  }, [props.open])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount + 1
      setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })
      PapiroConsole.log("=== props.itemsCount ===")
      PapiroConsole.log(totalOrderItems)
      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        PapiroConsole.log(orderItem)
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([{ id: 1, name: '1' }])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const setInitialState = () => {
    setOrganizationFieldId(null)
    setLabel('')
    setOrder(null)
    setIsRequired(false)
    setRequiredFieldMessage('')
    setFiles([])
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
    if (stateName === 'organizationField')
      setOrganizationFieldId(value)
  }

  const validateImagesAtachments = (filesList) => {
    if (typeof filesList === 'object') {
      let count = 0
      let fileList = []
      Object.values(filesList).forEach(val => {
        ++count
        if (isInvalidFileType(val.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
        } else
          fileList.push(val)
      })
      setFiles(fileList)
      if (files.length > 2) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    } else {
      let fileList = []
      filesList.foreach((item, idx) => {
        if (!isInvalidFileType(item.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
          return
        } else
          fileList.push(item)
      })
      setFiles(fileList)
      if (files.length > 2) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    }
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (organizationFieldId == null || (organizationFieldId != null && organizationFieldId === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Campo personalizado' é obrigatório")
      return false
    } else if (order == null || (order != null && order === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    }

    return true
  }

  const createItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Label", label)
      data.append("Order", order.id)
      data.append("IsRequired", isRequired)
      data.append("RequiredFieldMessage", requiredFieldMessage)
      if (organizationFieldId) {
        data.append("OrganizationFieldId", organizationFieldId.id)
      }
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await addItem(data)
      PapiroConsole.log(response)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "add.success" }))
        if (response.data != null) {
          PapiroConsole.log("=== createItem ===")
          PapiroConsole.log(props.calledservicesettingsorganizationfields)
          let oldItemsList = props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.length > 0 ? [...props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist] : []
          PapiroConsole.log(oldItemsList)
          let orderFilter = oldItemsList.filter(item => item.order >= order.id)
          PapiroConsole.log(orderFilter)
          if (orderFilter && orderFilter.length > 0) {
            let newOrderFilterList = orderFilter.map(item => {
              return {
                ...item,
                order: (item.order + 1)
              }
            })
            let newOrderFilterListIds = orderFilter.map(item => item.id)
            PapiroConsole.log(newOrderFilterList)

            let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
            PapiroConsole.log(notOrderFilterList)
            oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
            PapiroConsole.log(oldItemsList)

          }
          let itemList = [...oldItemsList, response.data]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsOrganizationFieldsList(itemList)
        }
        setInitialState()
        close()
      } else {
        const error = response.data.errors && response.data.errors[0] ? response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        setLoading(false)
        setMessage(true, 'error', error)
        Api.kickoff(response)
      }
    }
  }

  const getAllOrganizationFieldsByOrganization = async (organizationId) => {
    setLoading(true)

    let response = await getAllActiveByOrganizationIdAndCalledServiceSettingIdWithoutIncludes(organizationId, props.calledServiceSettingsId)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setOrganizationFieldList(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-add-called-service-setting-organization-field-dialog-title" >
        <DialogTitle id="form-add-called-service-setting-organization-field-dialog-title">
          {intl.formatMessage({ id: "add.organization.field" })}
          <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={8} >
                <TextField
                  name="label"
                  variant="outlined"
                  fullWidth
                  id="label"
                  label={intl.formatMessage({ id: "name" })}
                  value={label}
                  required
                  onChange={(e) => setLabel(e.target.value)} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={8} >
                <SimpleAutoComplete
                  label="Campo personalizado"
                  options={organizationFieldList}
                  stateName='organizationField'
                  changeSelect={changeValues}
                  required
                  selected={organizationFieldId} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Campo personalizado que aparecerá no formulário</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={4} >
                <SimpleAutoComplete
                  label={intl.formatMessage({ id: "item.order" })}
                  options={orderList}
                  stateName='order'
                  changeSelect={changeValues}
                  required
                  selected={order} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Ordem de exibição do campo personalizado</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
            {isRequired && <Grid item xs={12} sm={12} md={12} lg={8} >
              <TextField
                name="requiredFieldMessage"
                variant="outlined"
                multiline
                rows="4"
                fullWidth
                id="requiredFieldMessage"
                label="Mensagem de erro"
                value={requiredFieldMessage}
                required
                onChange={(e) => setRequiredFieldMessage(e.target.value)} />
            </Grid>}
            {isRequired && <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Mensagem de erro, caso o campo seja obrigatório, e o usuário tente enviar o formulário sem preencher</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagen informativas (Máximo de 2 arquivos)</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                  {<Dropzone
                    maxFiles={2}
                    onDrop={acceptedFiles => validateImagesAtachments([...acceptedFiles, ...files])} >
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        <AttachFileIcon />
                        <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage id="drag.and.drop" />
                        </Typography>
                      </div>
                    )}
                  </Dropzone>}
                  {files?.map(file => {
                    return (
                      <div className={props.classes.dropzoneItem} key={file.name}>
                        {file.name}
                        <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                      </div>
                    )
                  })}
                </div>
              </section>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={createItem} >
            {intl.formatMessage({ id: "save" })}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

AddCalledServiceSettingsOrganizationFieldItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsorganizationfields: state.calledservicesettingsorganizationfields,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AddCalledServiceSettingsOrganizationFieldItemDialog)))