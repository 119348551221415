import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import { browserHistory } from '../../helpers/history';
import * as Service from '../../services/holiday.service';
import * as componentActions from '../../redux/actions/holidays';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleSelect from '../../components/select/simple';
import Title from '../../components/title-name';

class HolidayEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,holidays } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!holidays || (holidays && !holidays.holiday)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: holidays.holiday.id,
      endtime : moment(holidays.holiday.endtime).format('HH:mm'),  
      starttime : moment(holidays.holiday.starttime).format('HH:mm'), 
      eventdate : holidays.holiday.eventdate, 
      active : holidays.holiday.active, 
      recurrenteveryear : holidays.holiday.recurrenteveryear, 
      observation : holidays.holiday.observation == null ? '' : holidays.holiday.observation , 
      name : holidays.holiday.name == null ? '' : holidays.holiday.name , 
     };         
    this.state = {
      holiday: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentDidMount() {
     };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      holiday: {
        ...prevState.holiday,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          holiday: {
            ...prevState.holiday,
          }
        }));
      } else {
        this.setState(prevState => ({
          holiday: {
            ...prevState.holiday,
          }
        }));
      }


      for (let key in this.state.holiday) {
        if (key.toString().toLowerCase().indexOf('eventdate') > -1 || key.toString().toLowerCase().indexOf('_date') > -1) {
          data.append(key, moment(this.state.holiday[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.holiday[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/holidays', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      holiday: {
        ...prevState.holiday,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, holidays } = this.props;
    const { holiday } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />} <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
             
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} >
                      <SimpleDatePicker label={intl.formatMessage({id:"date"})} name='eventdate' stateName='eventdate' changeDate={this.changeValues} currentDate={holiday.eventdate}  required/>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <TextField
                        id="time"
                        label={intl.formatMessage({id:"calendar.holiday.start.time"})}
                        type="time"
                        defaultValue='00:00'
                        className={classes.textField}
                        onChange={this.handleInputChange}
                        name='starttime'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <TextField
                          id="time"
                          label={intl.formatMessage({id:"calendar.holiday.end.time"})}
                          type="time"
                          defaultValue='23:59'
                          className={classes.textField}
                          onChange={this.handleInputChange}
                          name='endtime'
                          required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={holiday.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"observation"})} multiline rows={6} autoComplete='fname' variant='outlined' name='observation' onChange={this.handleInputChange} value={holiday.observation} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"calendar.holiday.happen.every.year"})} name='recurrenteveryear' stateName='recurrenteveryear' changeSelect={this.changeValues} selected={holiday.recurrenteveryear} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={holiday.active} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                  </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
HolidayEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    holidays: state.holidays
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(HolidayEdit)));
