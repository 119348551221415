
import React, { Component } from 'react'
import { Overlay } from "../../styles/global"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Container, Button } from '@mui/material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as componentActions from "../../redux/actions/users"
import Logo from '../../components/logo/logo';
import { FormattedMessage,injectIntl } from 'react-intl';
//import Api from '../../services/api'
import './style.css'

class Unavailable extends Component {

    constructor(props) {
      super(props);

      if (window.performance) {
        if (performance.navigation.type == 1) {
          var l = this.props.match.params.lang;
          document.location.href=`/${l}/`
        }
      }
    }
	componentDidMount(){
		const intl = this.props.intl;
		document.title = intl.formatMessage({id:"unavailable.system.temporarily"})+" "+intl.formatMessage({id:"unavailable.system.unavailable"});
	}
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement|null}
     */
    render() {
      const intl = this.props.intl;
        return (
            <div
                style={{
                    background: "white",
                    height: '100%'
                }}>
              <Container component="main" style={{ paddingTop: 150 }}>
                <div
                    style={{
                        float: 'left',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    { /*<Logo key="groups-fieldservice-awrar6aw15" />*/}
                </div>
                <div style={{ float: 'right', width: '50%', paddingLeft: 30 }}>
                    <h3 style={{ fontSize: '2em', color: 'black', fontWeight: 500 }}>{intl.formatMessage({id:"unavailable.system.temporarily"})}<br/>{intl.formatMessage({id:"unavailable.system.unavailable"})}</h3>
                    <h4 style={{ fontSize: '1.5em', color: '#37363D', fontWeight: 500 }}>{intl.formatMessage({id:"unavailable.please.return.later"})}<br/>{intl.formatMessage({id:"unavailable.or.contact"})}</h4>
                    <h5 style={{ color: '#37363D', fontWeight: 100 }}>{intl.formatMessage({id:"unavailable.contact"})}</h5>
                    <Button color='primary' variant='contained' onClick={e => {
                      var l = this.props.match.params.lang;
                      document.location.href=`/${l}/`
                    }}>{intl.formatMessage({id:"update"})}</Button>
                </div>
              </Container>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(Unavailable);connect(null, mapDispatchToProps)(withStyles(Styles)(Unavailable));