import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import SimpleCheckBox from "src/components/checkbox/check";
import { PapiroConsole } from "src/utils/papiroConsole";

const EmailSendingConfig = props => {

    const { config, classes, intl } = props

    return (
        <div>
            <form name='myForm' className={classes.form} onSubmit={props.checkProviderDeletion} encType='multipart/form-data'>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="frommail"
                            variant="outlined"
                            type="email"
                            fullWidth
                            id="frommail"
                            label={intl.formatMessage({ id: "email" })}
                            value={config.frommail}
                            onChange={props.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="hostmail"
                            variant="outlined"
                            fullWidth
                            id="hostmail"
                            label={intl.formatMessage({ id: "hostmail" })}
                            value={config.hostmail}
                            onChange={props.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="portmail"
                            variant="outlined"
                            type="number"
                            fullWidth
                            id="portmail"
                            label={intl.formatMessage({ id: "port" })}
                            value={config.portmail}
                            onChange={props.handleInputChange} />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="usermail"
                            variant="outlined"
                            fullWidth
                            id="usermail"
                            label={intl.formatMessage({ id: "user.access" })}
                            value={config.usermail}
                            onChange={props.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="passwordmail"
                            variant="outlined"
                            fullWidth
                            id="passwordmail"
                            label={intl.formatMessage({ id: "access.password" })}
                            type='password'
                            placeholder={intl.formatMessage({ id: "leave.empty" })}
                            value={config.passwordmail}
                            onChange={props.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <SimpleCheckBox
                            label={intl.formatMessage({ id: "config.edit.enable.ssl" })}
                            name='enablessl'
                            stateName='enablessl'
                            changeSelect={props.changeValues}
                            selected={config.enablessl} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="mailtest"
                            variant="outlined"
                            fullWidth
                            id="mailtest"
                            label={intl.formatMessage({ id: "config.edit.label.send.test.email" })}
                            value={config.mailtest}
                            onChange={props.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Grid container justify="flex-start" spacing={2} style={{ justifyContent: 'flex-start' }}>
                            <Grid item xs={12} sm={6} align-items-xs-center={'true'}>
                                <Button
                                    onClick={props.handleClick}
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit} >
                                    {intl.formatMessage({ id: "config.edit.test.sending.email" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default EmailSendingConfig