import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import moment from 'moment';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/timeentries';
import Footer from '../../components/footer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

class CalledTimeEntries extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;
        const { userSession, calleds } = this.props;
        this.isAdm = false
        this.isTec = true

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype == 0) {
                this.isAdm = true;
            }
            if (item.role.roletype != 2) {
                this.isTec = false;
            }
        });

        this.state = {
            calledId: calleds.called.id,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            archived : calleds.called.archived ? calleds.called.archived : false ,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: ''
        };

        this.callSearch = this.callSearch.bind(this);

        this.onFieldChange = this.onFieldChange.bind(this);


        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.searchValue != '') {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ searchValue: '', page: 1 })
        this.getItens(1);
    }


    deleteItem(data, resolve) {


        Api.delete(`/calledtimeentries/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === 'reload') {
            this.componentDidMount();
        }

    }

    componentDidMount() {
        this.getItens(1);
    }

    convertTohoursFormat = (minutos) => {
        let calledHourLoc = minutos ? minutos : "00:00"
        if(calledHourLoc && calledHourLoc > 0) {
            var splitCalledHoutLoc = [
                (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                calledHourLoc % 1
            ]
            let nDate = new Date(0,0)
            nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
            if(nDate.getSeconds() >= 58 ) {
                nDate.setSeconds(0)
                nDate.setMinutes(nDate.getMinutes() + 1)
            }
            let calledHourLocResult = nDate.toTimeString().slice(2, 5)
          
            calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
            calledHourLoc = calledHourLocResult

            if(calledHourLoc == '0')
                calledHourLoc = "00:00"
        } else if(calledHourLoc == '0')
            calledHourLoc = "00:00"

        return calledHourLoc
    }

    getItens(page = 1) {
        this.setState({ loading: true });
        
        let url = `/calledtimeentries/paginate?page=${page}`;
        if (this.state.searchValue != '') {
            url += `&search=${this.state.searchValue}`
        }

        url += `&calledId=${this.state.calledId}`
        this.setState({ loading: true });
        Api.get(url)

            .then(result => {
                if (result.data.success) {
                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);
                    const formattedList = result.data.data.map(item => {
                        let total = item.hours.toString().split(".");
                        let hour = total[0].toString().length == 1 ? "0"+total[0] : total[0];
                        let minute = total[1] ? (total[1]*6).toString() : "00";
                        minute = Math.round((minute + Number.EPSILON) * 100) / 100
                        if(minute.length > 2){
                          minute = minute.substr(0, 2)
                        }

                        

                        return {
                            ...item,
                            timeentrydateConverted: moment(item.timeentrydate).format('DD/MM/YYYY'),
                            Hours: this.convertTohoursFormat(item.hours)

                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
              
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
               
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
       
        const { classes, headerMenu } = this.props;
       

        
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (




            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                <CssBaseline />
                {!this.state.archived && (
                    <Grid container className={classes.mb20} spacing={0}>

                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            className={classes.button}
                            startIcon={<AddIcon />}
                            component={Link} {...{ to: `/${window.location.pathname.split("/")[1]}/called-time-entries-registration` }}
                        >
                            {<FormattedMessage id="add" />}
                        </Button>
                    </Grid>
                )}
                
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12}>

                        <Card className="background-title-search">
                            <CardContent>

                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h5">
                                            {<FormattedMessage id="appointment.hours" />}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>

                <MyMaterialTable
                    title=""
                    rowClick={(event, rowData) => {
                        const { userSession } = this.props;
                        if (this.isAdm == false) {
                            if (rowData.user.id != userSession.user.id) {

                                this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "user.not.allowed" }) });
                                return false;



                            }
                        }

                        if (this.context.can('Add', 'CalledTimeEntries')) {
                            this.props.addCurrent(rowData);
                            this.props.history.push(`/${this.props.match.params.lang}/called-time-entries-edit`);
                        } else {
                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
                            return false;
                        }


                    }}
                    columns={[
                        { title: intl.formatMessage({ id: "activity" }), field: 'activity.name' },
                        { title: intl.formatMessage({ id: "hours" }), field: 'Hours' },
                        { title: intl.formatMessage({ id: "date" }), field: 'timeentrydateConverted' },
                        { title: intl.formatMessage({ id: "user" }), field: 'user.name' },
                    ]}
                    data={this.state.resultList}
                    deleteItem={this.deleteItem}
                />





                <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                    <Grid item xs={12} md={3}>
                        <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                        {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                    </Grid>
                </Grid>


                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}


                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />
            </Container>


        );
    }
}
CalledTimeEntries.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
    timeentries: state.timeentries

});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledTimeEntries)));

CalledTimeEntries.contextType = AbilityContext;