import { IconButton, TextField, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from "styled-components";
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import { Styles } from '../../../../styles/material-styles';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import Delivered from '../icons/delivered';
import Failed from '../icons/failed';
import Read from '../icons/read';
import Request from '../icons/request';
import Sent from '../icons/sent';
import ChatMessageHandler from './message-type/chat-message-handler';
import './styles.css';


const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border: none;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    padding: 7.5px 10px;
    color: #FFF;
  }
  & .MuiOutlinedInput-root {
    background-color: #3F51B4;
    border: none;
    border-radius: 20px;
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: none;
  }
  & .MuiInputBase-colorPrimary {
    border: none;
  }
`;

function HistoryChatMessage({ userSession, message, setLoading, reSendMessage }) {
  PapiroConsole.log("=== HistoryChatMessage ===")
  PapiroConsole.log(message)
  

  
  
  PapiroConsole.log(message)
  PapiroConsole.log("message aqui 12")
  /*if (  (message.senderuserid === userSession.user.id || message.messageorigin === 4)
        || (message.messageorigin+'' == '1' )) {*/

  const MessageOriginTypeMessenger = 1;
  const MessageOriginTypeAutomatic = 4;

  if ( (message.messageorigin === MessageOriginTypeAutomatic) || (message.messageorigin === MessageOriginTypeMessenger )) {

    let color = "#3F51B4";
    //randomColor();
    return (<div key={message.id} className="history-message history-sender">
      <div className="history-sender-content">
        <span style={{fontWeight:'bold', color: color}}>{message.sendername}</span><br />
        {/*message.content*/}
        <ChatMessageHandler
         content={message.content}
         id={message.id}
         setLoading={setLoading}
         title={message.content}
         messagetype={message.messagetype} />

         
        { ( message.request && ! message.sent  && ! message.delivered && ! message.read && message.chatmessagestatus != 5) && ( <Request key={message.id} date={message.createdat} title={"Sending..."} style={{ width: 15 }} />)}

        { ( message.request && message.sent  && ! message.delivered && ! message.read && message.chatmessagestatus != 5) && ( <Sent key={message.id} date={message.createdat} style={{ width: 15 }} />)}

        { ( message.request && message.sent && message.delivered && ! message.read  && message.chatmessagestatus != 5) && ( <Delivered key={message.id} date={message.createdat} style={{ width: 15 }} />)}

        { ( message.request && message.sent && message.delivered && message.read  && message.chatmessagestatus != 5) && ( <Read key={message.id}  date={message.createdat} style={{ width: 15 }} />)}
        
        { ( message.request &&  message.chatmessagestatus === 5) && ( 
          <Tooltip title={"Code: "+message.errorcode+" | Message: "+message.errormessage}>
            <IconButton key={message.id} onClick={() => {
              //alert('RESEND');
             
            }}>
              <Failed key={message.id} style={{ width: 15 }} title={"Reenviar"} /> 
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div key={"arrow"+message.id}></div>
    </div>)
  } else {
    let color = "#3F51B4";//randomColor();
    return (<div  key={"receiver"+message.id} className="history-message history-receiver">

      <p>
        <span style={{fontWeight:'bold', color: color}}>{message.sendername}</span><br />
        <ChatMessageHandler
         content={message.content}
         id={message.id}
         setLoading={setLoading}
         title={message.content}
         messagetype={message.messagetype} 
         timestamp={message.createdat}
         />
         <div>
          <Typography variant="caption" color="textSecondary">
            {message.createdat ? moment(message.createdat).format("HH:mm") : null}
            </Typography>
         </div>
        
      </p>
    </div>)
  }
}

HistoryChatMessage.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HistoryChatMessage)))