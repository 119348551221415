import React, { useCallback, useState, useEffect, useMemo, render } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './styles.css'

const center = [-8.06224030, -34.87254230]
const zoom = 18

function DisplayPosition({ map }) {
  const [position, setPosition] = useState(map.getCenter())

  const onClick = useCallback(() => {
    map.setView(center, zoom)
  }, [map])

  const onMove = useCallback(() => {
    setPosition(map.getCenter())
  }, [map])

  useEffect(() => {
    map.on('move', onMove)
    return () => {
      map.off('move', onMove)
    }
  }, [map, onMove])

  return (
    <p>
      latitude: {position.lat.toFixed(4)}, longitude: {position.lng.toFixed(4)}{' '}
      <button onClick={onClick}>reset</button>
    </p>
  )
}

function ExternalStateMiniMap(props) {
  const [map, setMap] = useState(null)

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[props.lat, props.long]}
        zoom={zoom}
        scrollWheelZoom={false}
        whenCreated={setMap}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[props.lat, props.long]}>
          <Popup>
            {props.address}
          </Popup>
        </Marker>
      </MapContainer>
    ),
    [],
  )

  return (
    <div>
      {displayMap}
    </div>
  )
}

export default props => {
  return (<ExternalStateMiniMap
            lat={props.lat}
            long={props.long}
            address={props.address}
          />)
}