import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as Service from "../../services/organizationsetting.service";
import * as componentActions from "../../redux/actions/organizationsettings";
import SimpleCheckBox from "../../components/checkbox/check";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Footer from "../../components/footer";
import { FormattedMessage, injectIntl } from "react-intl";

class OrganizationSettingEdit extends Component {
  constructor(props) {
    super(props);

    const { userSession, organizationsettings, organizations } = this.props;
    // console.log(this.props);

    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if(!organizationsettings || (organizationsettings && !organizationsettings.organizationsetting)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    //console.log(organizationsettings.organizationsetting);
    this.initialState = {
      updatedUser: userSession.user.id,
      id: organizationsettings.organizationsetting.id,
      clientvisualization: organizationsettings.organizationsetting.clientvisualization,
      requiredratingcalleds: organizationsettings.organizationsetting.requiredratingcalleds,
      OrganizationId: this.props.org,
      OrganizationText:
        organizationsettings.organizationsetting.OrganizationText,
      TypeId: organizationsettings.organizationsetting.TypeId,
      TypeText: organizationsettings.organizationsetting.TypeText,
      OriginRequestId: organizationsettings.organizationsetting.OriginRequestId,
      OriginRequestText:
        organizationsettings.organizationsetting.OriginRequestText,
      StatusId: organizationsettings.organizationsetting.StatusId,
      StatusText: organizationsettings.organizationsetting.StatusText,
      TechinicalTeamId: organizationsettings.organizationsetting.TechinicalTeamId,
      TechinicalTeamText: organizationsettings.organizationsetting.TechinicalTeamText,
      roleclientcanselectcategory:
        organizationsettings.organizationsetting.roleclientcanselectcategory ==
        1
          ? true
          : false,
      canassingusercalled:
        organizationsettings.organizationsetting.canassingusercalled == 1
          ? true
          : false,
      estimatedanwserhours:
        organizationsettings.organizationsetting.estimatedanwserhours,
      canviewcalledfromothertechnical:
        organizationsettings.organizationsetting
          .canviewcalledfromothertechnical == 1
          ? true
          : false,
      canselectpatrimonyincalled:
        organizationsettings.organizationsetting.canselectpatrimonyincalled == 1
          ? true
          : false,
      canselectdepartamentincalled: organizationsettings.organizationsetting.canselectdepartamentincalled != null ? organizationsettings.organizationsetting.canselectdepartamentincalled : false,
      canselectparentcalledincalled: organizationsettings.organizationsetting.canselectparentcalledincalled != null ? organizationsettings.organizationsetting.canselectparentcalledincalled : false,
      canselectdisplacementincalled: organizationsettings.organizationsetting.canselectdisplacementincalled != null ? organizationsettings.organizationsetting.canselectdisplacementincalled : false,
      canselecthourcostorcalledcostincalled: organizationsettings.organizationsetting.canselecthourcostorcalledcostincalled != null ? organizationsettings.organizationsetting.canselecthourcostorcalledcostincalled : false,
      canclientselecttypeincalled:
        organizationsettings.organizationsetting.canclientselecttypeincalled == 1
          ? true
          : false,
      canclientviewslametricsincalled:
        organizationsettings.organizationsetting.canclientviewslametricsincalled == 1
          ? true
          : false,
      canclientselectpatrimonyincalled:
        organizationsettings.organizationsetting.canclientselectpatrimonyincalled == 1
          ? true
          : false,
          caninformcalledclosingstatus:
        organizationsettings.organizationsetting.caninformcalledclosingstatus == 1
          ? true
          : false,
      canselectorganizationcategoriesbylevels:
        organizationsettings.organizationsetting.canselectorganizationcategoriesbylevels == 1
          ? true
          : false,
      caninformzeroincalledtechnicalcost:
        organizationsettings.organizationsetting.caninformzeroincalledtechnicalcost == 1
          ? true
          : false,
      canfinalchecklistisrequired:
        organizationsettings.organizationsetting.canfinalchecklistisrequired == 1
          ? true
          : false,
      caninitialchecklistisrequired:
        organizationsettings.organizationsetting.caninitialchecklistisrequired == 1
          ? true
          : false,
      caninforminitialchecklist:
        organizationsettings.organizationsetting.caninforminitialchecklist == 1
          ? true
          : false,
      automaticallycreatecalledwhenreadingqr:
        organizationsettings.organizationsetting
          .automaticallycreatecalledwhenreadingqr == 1
          ? true
          : false,
      
      allowscreateanonymouscalledsbyemail:
        organizationsettings.organizationsetting
          .allowscreateanonymouscalledsbyemail == 1
          ? true
          : false,
      canstartcallcenter: organizationsettings.organizationsetting.canstartcallcenter,
      allowattendanceviachat: organizationsettings.organizationsetting.allowattendanceviachat ? organizationsettings.organizationsetting.allowattendanceviachat : false,
      requireattachedimage: organizationsettings.organizationsetting.requireattachedimage ? organizationsettings.organizationsetting.requireattachedimage : false,
      finalizationexternalstatus : organizationsettings.organizationsetting.finalizationexternalstatus ? organizationsettings.organizationsetting.finalizationexternalstatus : false,
      canclientfinishcalled: organizationsettings.organizationsetting.canclientfinishcalled ? organizationsettings.organizationsetting.canclientfinishcalled : false,
      canclientreopencalled: organizationsettings.organizationsetting.canclientreopencalled ? organizationsettings.organizationsetting.canclientreopencalled : false,
      canclientautomaticchangestatusaftersendaccompaniment: organizationsettings.organizationsetting.canclientautomaticchangestatusaftersendaccompaniment ? organizationsettings.organizationsetting.canclientautomaticchangestatusaftersendaccompaniment : false,
      canarchivecalleds :  organizationsettings.organizationsetting.canarchivecalleds ? organizationsettings.organizationsetting.canarchivecalleds : true,
      disablecallrejectionbyrequester :  organizationsettings.organizationsetting.disablecallrejectionbyrequester ? organizationsettings.organizationsetting.disablecallrejectionbyrequester : false,
      allowclientinteractiononclosedcalleds : organizationsettings.organizationsetting.allowclientinteractiononclosedcalleds ? organizationsettings.organizationsetting.allowclientinteractiononclosedcalleds : false,
      caneditaccompanimentinactivecalled: organizationsettings.organizationsetting.caneditaccompanimentinactivecalled ? organizationsettings.organizationsetting.caneditaccompanimentinactivecalled : false,
      actionrequiredwhenstatusorteamorassignedchanges : organizationsettings.organizationsetting.actionrequiredwhenstatusorteamorassignedchanges ? organizationsettings.organizationsetting.actionrequiredwhenstatusorteamorassignedchanges : false,
      enablesendingsatisfactionsurveythroughmessagingproviders : organizationsettings.organizationsetting.enablesendingsatisfactionsurveythroughmessagingproviders
    };

    this.state = {
      organizationsetting: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      CategoryList: [],
      OrganizationList: [],
      SlaList: [],
      TypeList: [],
      OriginRequestList: [],
      StatusList: [],
      TechinicalTeamList: []
    };

    // console.log(this.state.organizationsetting);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTechinicalTeams = this.getTechinicalTeams.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getOrganizations();
    await this.getTypes();
    await this.getOriginRequests();
    await this.getStatuses();
    await this.getTechinicalTeams();
  }

  async getTechinicalTeams() {
    this.setState({ loading: true });
    var result = await Service.getTechinicalTeamsByOrganizationId(this.state.organizationsetting.OrganizationId);
    if (result.success) {
      this.setState({ loading: false, TechinicalTeamList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getTypes() {
    this.setState({ loading: true });
    var result = await Service.getTypes();
    if (result.success) {
      this.setState({ loading: false, TypeList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getOriginRequests() {
    this.setState({ loading: true });
    var result = await Service.getOriginRequests();
    if (result.success) {
      this.setState({ loading: false, OriginRequestList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getStatuses() {
    this.setState({ loading: true });
    var result = await Service.getStatuses();
    if (result.success) {
      this.setState({ loading: false, StatusList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      organizationsetting: {
        ...prevState.organizationsetting,
        [name]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const defaultvalue = { id: 0, name: "" };
    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
        },
      }));
    } else {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
        },
      }));
    }

    // console.log(this.state.organizationsetting)

    for (let key in this.state.organizationsetting) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.organizationsetting[key]).format(
            "YYYY/MM/DD HH:mm:ss"
          )
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("roleclientcanselectcategory") > -1
      ) {
        if (
          this.state.organizationsetting[key] == true ||
          this.state.organizationsetting[key] === true
        ) {
          // console.log('entrei aqui')
          data.append(key, 1);
        } else {
          data.append(key, 0);
        }
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("canviewcalledfromothertechnical") > -1
      ) {
        if (
          this.state.organizationsetting[key] == true ||
          this.state.organizationsetting[key] === true
        ) {
          // console.log('entrei aqui')
          data.append(key, true);
        } else {
          data.append(key, false);
        }
      }else if (key.toString().toLowerCase().indexOf("canstartccallcenter") > -1) {
        // console.log(this.state.organizationsetting);
      }
      
       else {
        data.append(key, this.state.organizationsetting[key]);
      }
    }

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // console.log(data)
    Api.put("/organizationsettings", data, config)
    

      .then((result) => {
        if (result.data.success) {

          // console.log(result.data)
          
          const formattedList = result.data.data;
          formattedList.OrganizationId = formattedList.organization
            ? formattedList.organization.id
            : 0;
          formattedList.OrganizationText = formattedList.organization
            ? formattedList.organization.name
            : "";
          formattedList.TypeId = formattedList.type ? formattedList.type.id : 0;
          formattedList.TypeText = formattedList.type
            ? formattedList.type.name
            : "";
          formattedList.OriginRequestId = formattedList.originrequest
            ? formattedList.originrequest.id
            : 0;
          formattedList.OriginRequestText = formattedList.originrequest
            ? formattedList.originrequest.name
            : "";
          formattedList.StatusId = formattedList.status
            ? formattedList.status.id
            : 0;
          formattedList.StatusText = formattedList.status
            ? formattedList.status.name
            : "";
          formattedList.TechinicalTeamId = formattedList.techinicalteam
          ? formattedList.techinicalteam.id : 0
          formattedList.TechinicalTeamText = formattedList.techinicalteam
          ? formattedList.techinicalteam.name : ''
          formattedList.roleclientcanselectcategory =
            formattedList.roleclientcanselectcategory == 1 ? true : false;
          formattedList.canassingusercalled =
            formattedList.canassingusercalled == 1 ? true : false;
          formattedList.canviewcalledfromothertechnical =
            formattedList.canviewcalledfromothertechnical == 1 ? true : false;
          formattedList.clientvisualization =
            formattedList.clientvisualization == 1 ? true : false;
          formattedList.requiredratingcalleds =
            formattedList.requiredratingcalleds == 1 ? true : false;

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: [],
          });
          this.props.addCurrentOrganization(
            formattedList,
            this.state.organizations.organization
          );
          this.componentDidMount();
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;
          // console.log(err);
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    

    // console.log(stateName,value, text)
    this.setState({ loading: true });

    this.setState((prevState) => ({
      organizationsetting: {
        ...prevState.organizationsetting,
        [stateName]: value,
      },
    }));

    // console.log(this.state.organizationsetting)
    if (stateName === "OrganizationId") {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
          ["OrganizationText"]: text,
        },
      }));
    }
    if (stateName === "TypeId") {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
          ["TypeText"]: text,
        },
      }));
    }
    if (stateName === "OriginRequestId") {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
          ["OriginRequestText"]: text,
        },
      }));
    }
    if (stateName === "StatusId") {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
          ["StatusText"]: text,
        },
      }));
    }
    if (stateName === "TechinicalTeamId") {
      this.setState((prevState) => ({
        organizationsetting: {
          ...prevState.organizationsetting,
          ["TechinicalTeamText"]: text,
        },
      }));
    }
    this.setState({ loading: false });
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, organizationsettings } = this.props;
    const { organizationsetting } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    // console.log(this.state.organizationsetting)
    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>

              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />} <Title />
              </Typography>

              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({
                        id: "organization.settings.type.call",
                      })}
                      options={this.state.TypeList}
                      stateName="TypeId"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.TypeId}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({
                        id: "organization.settings.called.origin",
                      })}
                      options={this.state.OriginRequestList}
                      stateName="OriginRequestId"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.OriginRequestId}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "default.type" })}
                      options={this.state.StatusList}
                      stateName="StatusId"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.StatusId}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "attendance.team" })}
                      options={this.state.TechinicalTeamList}
                      stateName="TechinicalTeamId"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.TechinicalTeamId}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="filled-number"
                      label={intl.formatMessage({
                        id: "organization.settings.expire",
                      })}
                      type="number"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="estimatedanwserhours"
                      onChange={this.handleInputChange}
                      value={organizationsetting.estimatedanwserhours}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={6}>
                    <SimpleCheckBox 
                      label={intl.formatMessage({id:"organization.settings.videocall"})} 
                      name='canstartcallcenter' 
                      stateName='canstartcallcenter' 
                      changeSelect={this.changeValues} 
                      selected={organizationsetting.canstartcallcenter} />
                  </Grid>
                  <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.video.call"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={6}>
                    <SimpleCheckBox
                      label={intl.formatMessage({
                        id: "organization.settings.cliente.categoria",
                      })}
                      name="roleclientcanselectcategory"
                      stateName="roleclientcanselectcategory"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.roleclientcanselectcategory}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={6}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.client.category"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={7}>
                    <SimpleCheckBox
                      label={intl.formatMessage({
                        id: "organization.settings.responsavel.chamado",
                      })}
                      name="canassingusercalled"
                      stateName="canassingusercalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canassingusercalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={5}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.responsible.pick.called"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={intl.formatMessage({
                        id: "organization.settings.allow.call",
                      })}
                      name="canviewcalledfromothertechnical"
                      stateName="canviewcalledfromothertechnical"
                      changeSelect={this.changeValues}
                      selected={
                        organizationsetting.canviewcalledfromothertechnical
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.responsible.view.calleds"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={intl.formatMessage({
                        id: "organization.settings.allow.call.email",
                      })}
                      name="allowscreateanonymouscalledsbyemail"
                      stateName="allowscreateanonymouscalledsbyemail"
                      changeSelect={this.changeValues}
                      selected={
                        organizationsetting.allowscreateanonymouscalledsbyemail
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.from.email"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})}
                      name="canselectpatrimonyincalled"
                      stateName="canselectpatrimonyincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselectpatrimonyincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.patrimony"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.can.attach.departament.to.called"})}
                      name="canselectdepartamentincalled"
                      stateName="canselectdepartamentincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselectdepartamentincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.departament"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.parent.called.in.called"})}
                      name="canselectparentcalledincalled"
                      stateName="canselectparentcalledincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselectparentcalledincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.parent.called.in.called.info"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.displacement.in.called"})}
                      name="canselectdisplacementincalled"
                      stateName="canselectdisplacementincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselectdisplacementincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.displacement.in.called.info"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.hour.cost.or.called.cost.in.called"})}
                      name="canselecthourcostorcalledcostincalled"
                      stateName="canselecthourcostorcalledcostincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselecthourcostorcalledcostincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.can.select.hour.cost.or.called.cost.in.called.info"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.client.adds.patrimonies.to.called"})}
                      name="canclientselectpatrimonyincalled"
                      stateName="canclientselectpatrimonyincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canclientselectpatrimonyincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.client.create.called.add.patrimony"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organizations.settings.canclientselecttypeincalled"})}
                      name="canclientselecttypeincalled"
                      stateName="canclientselecttypeincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canclientselecttypeincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.canclientselecttypeincalled.info"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.canclientviewslametricsincalled' }) }
                      name="canclientviewslametricsincalled"
                      stateName="canclientviewslametricsincalled"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canclientviewslametricsincalled}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.canclientviewslametricsincalled.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.caninformcalledclosingstatus' }) }
                      name="caninformcalledclosingstatus"
                      stateName="caninformcalledclosingstatus"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.caninformcalledclosingstatus}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.caninformcalledclosingstatus.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.canselectorganizationcategoriesbylevels' }) }
                      name="canselectorganizationcategoriesbylevels"
                      stateName="canselectorganizationcategoriesbylevels"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canselectorganizationcategoriesbylevels}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.canselectorganizationcategoriesbylevels.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.caninformzeroincalledtechnicalcost' }) }
                      name="caninformzeroincalledtechnicalcost"
                      stateName="caninformzeroincalledtechnicalcost"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.caninformzeroincalledtechnicalcost}
                    />
                  </Grid>
                  <Grid item xs={2} sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }} >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.caninformzeroincalledtechnicalcost.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.canfinalchecklistisrequired' }) }
                      name="canfinalchecklistisrequired"
                      stateName="canfinalchecklistisrequired"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.canfinalchecklistisrequired}
                    />
                  </Grid>
                  <Grid item xs={2} sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }} >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.canfinalchecklistisrequired.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.caninforminitialchecklist' }) }
                      name="caninforminitialchecklist"
                      stateName="caninforminitialchecklist"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.caninforminitialchecklist}
                    />
                  </Grid>
                  <Grid item xs={2} sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }} >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.caninforminitialchecklist.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                {organizationsetting.caninforminitialchecklist && (<Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={ intl.formatMessage({ id: 'organizations.settings.caninitialchecklistisrequired' }) }
                      name="caninitialchecklistisrequired"
                      stateName="caninitialchecklistisrequired"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.caninitialchecklistisrequired}
                    />
                  </Grid>
                  <Grid item xs={2} sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }} >
                    <Tooltip title={ intl.formatMessage({ id: 'organizations.settings.caninitialchecklistisrequired.info' }) }>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>)}
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.client.only.views.own.calls"})}
                      name="clientvisualization"
                      stateName="clientvisualization"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.clientvisualization}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.enable.client.view.only.own.called"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation"})}
                      name="requiredratingcalleds"
                      stateName="requiredratingcalleds"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.requiredratingcalleds}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation.title"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                {this.state.organizationsetting.canselectpatrimonyincalled && (
                  <Grid container spacing={4}>
                    <Grid item xs={10} sm={6}>
                      <SimpleCheckBox
                        label={this.props.intl.formatMessage({id:"organization.setting.edit.create.called.read.qrcode"})}
                        name="automaticallycreatecalledwhenreadingqr"
                        stateName="automaticallycreatecalledwhenreadingqr"
                        changeSelect={this.changeValues}
                        selected={
                          organizationsetting.automaticallycreatecalledwhenreadingqr
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={6}
                      style={{ textAlign: "right", alignSelf: "stretch" }}
                    >
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.add.patrimony.to.called.create.called.read.qrcode"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.allow.chat.attendance"})}
                      name="allowattendanceviachat"
                      stateName="allowattendanceviachat"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.allowattendanceviachat}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.allow.technician.chat.attendance.for.clients"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={10} sm={5}>
                    <SimpleCheckBox
                      label={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.image.attachments"})}
                      name="requireattachedimage"
                      stateName="requireattachedimage"
                      changeSelect={this.changeValues}
                      selected={organizationsetting.requireattachedimage}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={7}
                    style={{ textAlign: "right", alignSelf: "stretch" }}
                  >
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.allow.technician.chat.attendance.for.clients"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.allow.technician.finish.attendance"})} name='finalizationexternalstatus' stateName='finalizationexternalstatus' changeSelect={this.changeValues} selected={organizationsetting.finalizationexternalstatus} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.show.finished.attendance.status"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called"})} name='canclientfinishcalled' stateName='canclientfinishcalled' changeSelect={this.changeValues} selected={organizationsetting.canclientfinishcalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called"})} name='canclientreopencalled' stateName='canclientreopencalled' changeSelect={this.changeValues} selected={organizationsetting.canclientreopencalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment"})} name='canclientautomaticchangestatusaftersendaccompaniment' stateName='canclientautomaticchangestatusaftersendaccompaniment' changeSelect={this.changeValues} selected={organizationsetting.canclientautomaticchangestatusaftersendaccompaniment} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"can.organization.archive.closed.calleds"})} name='canarchivecalleds' stateName='canarchivecalleds' changeSelect={this.changeValues} selected={organizationsetting.canarchivecalleds} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.organization.archive.closed.calleds"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester"})} name='disablecallrejectionbyrequester' stateName='disablecallrejectionbyrequester' changeSelect={this.changeValues} selected={organizationsetting.disablecallrejectionbyrequester} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds"})} name='allowclientinteractiononclosedcalleds' stateName='allowclientinteractiononclosedcalleds' changeSelect={this.changeValues} selected={organizationsetting.allowclientinteractiononclosedcalleds} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled"})} name='caneditaccompanimentinactivecalled' stateName='caneditaccompanimentinactivecalled' changeSelect={this.changeValues} selected={organizationsetting.caneditaccompanimentinactivecalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges"})} name='actionrequiredwhenstatusorteamorassignedchanges' stateName='actionrequiredwhenstatusorteamorassignedchanges' changeSelect={this.changeValues} selected={organizationsetting.actionrequiredwhenstatusorteamorassignedchanges} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"enable.sending.satisfaction.survey.through.messaging.providers"})} name='enablesendingsatisfactionsurveythroughmessagingproviders' stateName='enablesendingsatisfactionsurveythroughmessagingproviders' changeSelect={this.changeValues} selected={organizationsetting.enablesendingsatisfactionsurveythroughmessagingproviders} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"this.feature.enables.sending.satisfaction.survey.at.the.end.of.a.ticket.via.messaging.providers"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                </Grid>
                    

                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={1}
                    sm={4}
                    align-items-xs-center={"true"}
                    justify-xs-flex-end={"true"}
                  >2
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
OrganizationSettingEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
  organizationsettings: state.organizationsettings,
  org: state.organization.organization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(OrganizationSettingEdit))
);

//{"organization":{"id":763,"name":"Jornal do Comercio","group":{"id":3,"name":"GRUPO JCPM"},"groupid":3,"segment":{"id":2,"name":"Comunicação"},"segmentid":2,"GroupId":3,"GroupText":"GRUPO JCPM","SegmentId":2,"SegmentText":"Comunicação","tableData":{"id":1}}}, //state.organizations,
