import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import * as Service from '../../services/relationshipcalled.service';
class RelationshipCalledRegistration extends Component {

  constructor(props) {
    super(props);

    //alert(this.props.organizationid);
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      CalledCreatetId : 0,
      CalledTargetId : 0,
      CalledTargetText:''

     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      calledList: []

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getCalleds = this.getCalleds.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    //await this.getCalleds(); // CRIME!!!
     };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if(name === 'name')
    {
      if(value.length <= 255){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }

   
  };

  async getCalleds(){
    this.setState({ loading: true });
    var result = await Service.getCalledsForRelation();
      if (result.success) {
        let calledId = 0;
        let formattedList = result.data.map((item,index) => {
            if(item.id === this.props.relationshipcalled){
              calledId = index;
            }
            return {
              ...item,
              parentname: `${item.id}: ${item.title}`,
              name: `${item.id}: ${item.title}`,

          }

        });
      formattedList.splice (calledId, 1);
        this.setState({ loading: false, calledList: formattedList });
                      
      } else {
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    if(this.state.item.CalledTargetId == 0){
      const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"empty.field"})
            });
    } else{

      let data = new FormData();



      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          if(key == "CalledTargetId"){
            //let text = "";
            //this.state.item.CalledTargetId.forEach(element => {
            //text += element.id + ","
              
            //});
            //let textFinal = text.slice(0, -1)
			let textFinal = this.state.item[key];
			//alert(JSON.stringify(textFinal));
            data.append("CalledTargetId",textFinal.id)
          } else {
            data.append(key, this.state.item[key]);
          }
          
        }
      }

      data.append("CalledCreateId",this.props.relationshipcalled)
      
	  //alert(this.props.relationshipcalled);
      //return;
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/calleds/relation', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });


          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });



          this.setState(prevState => ({
            item: {
              ...prevState.item,
              CalledTargetId: 0,
              ['CalledTargetText']: '',
            }
          }));

    }


      


    
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    
    if(stateName === 'CalledTargetId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['CalledTargetText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  handleBack = () => {
    if (this.props.calleds.called) {
      const id = this.props.calleds.called.id
      this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${id}`, { propTab: 12 })
    } else {
      browserHistory.goBack()
    }
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={this.handleBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />} {<FormattedMessage id="called.rel" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                    {/*<SimpleAutoCompleteMultiple label={intl.formatMessage({id:"called.header"})} options={this.state.calledList} stateName='CalledTargetId' changeSelect={this.changeValues} selected={item.CalledTargetId} />*/}
					<SimpleAutoCompleteSearch numberdigits={4} label={intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "called.header" })} options={this.state.calledList} stateName='CalledTargetId' changeSelect={this.changeValues} loadListFunction={this.getParentCalleds} selected={item.CalledTargetId} urlapi={Api.apiurlbase()+'api/calleds/search/'+this.props.organizationid}/>

                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                        >
                            {<FormattedMessage id="save" />}
                        </Button>
                    </Grid>
                    </Grid>
                    </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
RelationshipCalledRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    relationshipcalled: state.relationshipcalled.relationshipcalled,
                    organizationid: state.calleds.called.organization.id,
                    calleds: state.calleds,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(RelationshipCalledRegistration)));
