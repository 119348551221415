import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, IconButton, Switch, TextField, Typography, DialogContentText } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';
import * as componentActions from '../../../../redux/actions/calledservicesettingsvalidations'
import Api from '../../../../services/api'
import { editItem, deleteAttachmentById } from '../../../../services/calledservicesettingsvalidation.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType, validateFyleTypeByTypeList } from '../../../../utils/fileUtils'
import * as CalledServiceSettingsFileUploadAttachmentService from '../../../../services/calledservicesettingsfileuploadattachment.service'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import * as FileService from '../../../../services/attachment.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';
function UpdateCalledServiceSettingsValidationItemDialog(props) {

  const { classes, intl, userId, hirerId } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [title, setTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [active, setActive] = useState(true)
  const [files, setFiles] = useState([])

  const [attachment, setAttachment] = useState(null)

  useEffect(() => {
    PapiroConsole.log("=== props.calledservicesettingsvalidations ===")
    PapiroConsole.log(props.calledservicesettingsvalidations)
    if (props.calledservicesettingsvalidations && props.calledservicesettingsvalidations.calledservicesettingsvalidation != null) {
      setId(props.calledservicesettingsvalidations.calledservicesettingsvalidation.id)
      setTitle(props.calledservicesettingsvalidations.calledservicesettingsvalidation.title)
      setErrorMessage(props.calledservicesettingsvalidations.calledservicesettingsvalidation.errormessage)
      setActive(props.calledservicesettingsvalidations.calledservicesettingsvalidation.active)
      setAttachment(props.calledservicesettingsvalidations.calledservicesettingsvalidation.attachment)
    }
  }, [props.calledservicesettingsvalidations])

  useEffect(() => {
    PapiroConsole.log("=== props.open ===")
    PapiroConsole.log(props.open)
    setOpen(props.open)
    props.handleOpen(props.open)

    if (props.open) {
      setFiles([])
    }
  }, [props.open])

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const classes2 = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      }
    });
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const openLinkFile = async attachment => {
    setLoading(true)
		
		let url = await FileService.getFileDownloadLink(attachment.id)
		if(url &&  url.data && url.data.data && url.data.success){
      setLoading(false)
			window.open(`${url.data.data}`, '_blank')
		} else{
      setMessage(true, 'error', intl.formatMessage({ id: "file.not.found" }))
      setLoading(false)
    }
  }

  const validateForm = _ => {
    if (title == null || (title != null && title.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Título' é obrigatório")
      return false
    } else if (errorMessage == null || (errorMessage != null && errorMessage.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Mensagem de erro' é obrigatório")
      return false
    } else if ((files == null || (files != null && files.length === 0)) && (attachment == null)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Arquivo de validação' é obrigatório")
      return false
    }

    return true
  }

  const updateItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("Title", title)
      data.append("ErrorMessage", errorMessage)
      data.append("Active", active)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("UpdatedUser", userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await editItem(data)
      if (response && response.success) {
        if (response.data != null && response.data.attachment != null)
          setAttachment(response.data.attachment)
        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
        setFiles([])
        setLoading(false)
        if (response.data != null) {
          let oldItemsList = props.calledservicesettingsvalidations && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist.length > 0 ? [...props.calledservicesettingsvalidations.calledservicesettingsvalidationslist] : []
          var itemList = oldItemsList.filter(item => item.id != response.data.id)
          var newItemList = [...itemList, response.data]
          newItemList = newItemList.sort((a, b) => a.title - b.title)
          props.addCalledServiceSettingsValidationsList(newItemList)
        }
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  const deleteCalledServiceSettingsValidationAttachmentItem = async itemId => {
    setLoading(true)
		
		let result = await deleteAttachmentById(itemId)
		if(result && result.data && result.success) {
      setAttachment(null)

      // recupera itens do Redux
      let oldItemsList = props.calledservicesettingsvalidations && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist.length > 0 ? [...props.calledservicesettingsvalidations.calledservicesettingsvalidationslist] : []

      // recupera os itens, exceto o que está sendo editado
      let itemList = oldItemsList.filter(item => item.id != itemId)

      // recupera o iten que está sendo editado
      let oldItem = oldItemsList.find(item => item.id == itemId)
      if (oldItem != null) {
        oldItem.attachment = null
        oldItem.attachmentid = null
      }

      // Adiciona a lista atualizada no Redux
      let newItemList = [...itemList, oldItem]
      newItemList = newItemList.sort((a, b) => a.title - b.title)
      props.addCalledServiceSettingsValidationsList(newItemList)

      setLoading(false)
		} else{
      setMessage(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result)
    }
  }

  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-edit-called-service-setting-file-upload-dialog-title" >
        <DialogTitle id="form-edit-called-service-setting-file-upload-dialog-title">
          Editar campo de upload de arquivos
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          { id > 0 ? (<Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="title"
                variant="outlined"
                fullWidth
                id="title"
                label={`${intl.formatMessage({ id: "title" })}`}
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={12} sm={12} md={12} lg={8} style={{ marginBottom: 50 }}>
              <Typography>
                Mensagem
              </Typography>
              <ReactQuill
                id="errorMessage"
                name="errorMessage"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                value={errorMessage}
                style={{ height: 200 }}
                fullWidth
                required
                InputLabelProps={{ shrink: errorMessage && errorMessage.length > 0 ? true : false }}
                variant="outlined"
                onChange={(value) => setErrorMessage(value)} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      name="active" />
                  }
                  label={intl.formatMessage({ id:"active" })} />
              </FormGroup>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Arquivo de validação</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            { attachment == null && (<Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                  <Dropzone maxFiles={2} onDrop={acceptedFiles => {
                    let attach = null
                    if (acceptedFiles != null && acceptedFiles.type != null)
                      attach = acceptedFiles
                    else if (acceptedFiles != null && Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
                      attach = acceptedFiles[0]
                    }
                    if (attach != null) {
                      let validateAttachment = validateFyleTypeByTypeList(attach.type, ["csv", ".csv", "text/csv", "application/vnd.ms-excel", "application/csv", "text/x-csv", "application/x-csv", "text/comma-separated-values", "text/x-comma-separated-values"])
                      if (validateAttachment)
                        setFiles([attach])
                      else
                        setMessage(true, 'error', 'Formato do arquivo inválido')
                    }
                  }}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
              
                      <AttachFileIcon  />
                      <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage  id="drag.and.drop" />
                      </Typography>
                    </div>
                    )}
                  </Dropzone>
                  {files.map(file => {
                  return (
                    <div className={props.classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                    </div>
                    )
                  })}
                </div>
              </section>
            </Grid>)}
            { attachment == null && <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch', display: 'flex', alignItems: 'center' }}>
              <Tooltip title={<h5>Os dados utilizados para validação, são E-mail/Documento do usuário, na solicitação do formulário externo. Insira um arquivo .csv, com um E-mail ou documento (CPF) por linha, na primeira coluna do arquivo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            {attachment != null && <Grid item xs={11} sm={11} md={11} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={props.classes.attachmentsBox}>
                  <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                    <ul>
                      <li key={attachment.path}>
                        <AttachFile /> <Button onClick={() => openLinkFile(attachment)}>{attachment.description}</Button>
                        <DeleteForever onClick={() => deleteCalledServiceSettingsValidationAttachmentItem(id)} style={{ cursor: "pointer" }} />
                      </li>
                    </ul>
                  </ImageList>
                </div>
              </Grid>
            </Grid>}
            {attachment != null && <Grid item xs={1} sm={1} md={1} lg={4} />}
          </Grid>) :
          (
            <Grid container spacing={2} style={{ padding: 10 }} >
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }} >
                <CircularProgress color='secondary' />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          {id > 0 && <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={updateItem} >
            {intl.formatMessage({id:"save"})}
          </Button>}
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

UpdateCalledServiceSettingsValidationItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  itemData: PropTypes.object
}

const mapStateToProps = state => ({
  calledservicesettingsvalidations: state.calledservicesettingsvalidations
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

//props.addCalledServiceSettingsFileUploadValidation(itemToEdit)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UpdateCalledServiceSettingsValidationItemDialog)))