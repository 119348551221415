import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Rating,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../components/footer";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import { AbilityContext } from "../../config/ability-context";
import * as componentActions from "../../redux/actions/calledclassifications";
import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";


import { QueuePlayNext } from "@mui/icons-material";
import ReactQuill from "react-quill";
import { browserHistory } from "../../helpers/history";
import ChatClassificationService from "../../services/2talk/chatclassification.service";

class ChatClassificationsEdit extends Component {
  constructor(props) {
    super(props);

    this.typingTimeout = null;

    const { userSession, calledclassifications } = this.props;

    var attr = "Atribuído para";
    var bool = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;
    this.isClient = true;
    const intl = this.props.intl;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
        attr = intl.formatMessage({ id: "client" });
        bool = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true;
      }
    });

    this.state = {
      item: {
        id: calledclassifications?.calledclassification?.classificationId,
        assignedUser: calledclassifications?.calledclassification?.assignedUser,
        noteAssigned: null,
        observationAssigned: ""
      },
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userId: userSession.user.id,
      userRoleName: attr,
      userRoleBool: bool,
    };

    this.closeNotification = this.closeNotification.bind(this);

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
        item: {
            ...prevState.item,
            [name]: value || ""
        }
    }));
  }

  handleChangeInput(name, value) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  async handlerSubmit(event) {
    event.preventDefault();

    try {

      const input = {
        id: this.state.item.id,
        noteassigned: this.state.item.noteAssigned,
        observationAssigned: this.state.item.observationAssigned,
      }

      const service = new ChatClassificationService();
      const output = await service.update(input);

      if (output.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Registro salvo com sucesso'
        });
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  async fetchData() {
    this.setState({ loading: true });

    try {

      const service = new ChatClassificationService();
      const output = await service.getById(this.state.item.id);

      if (output.success) {
        this.setState({
          item: {
            ...this.state.item,
            noteAssigned: output?.data?.noteassigned ?? null,
            observationAssigned: output?.data?.observationassigned ?? ""
          },
        });
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container
            component="main"
            maxWidth={false} // Desabilitar o maxWidth padrão do MUI
            style={{ paddingLeft: 0, marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
          >
            <CssBaseline />

            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>

              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>

              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="Classificação de conversa" />}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handlerSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>
                    <TextField 
                      inputProps={{ maxLength: 250 }} 
                      fullWidth 
                      label={intl.formatMessage({id:"Atendente"})} 
                      variant='outlined' 
                      value={this.state.item.assignedUser} 
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      <FormattedMessage id="Avaliação" />
                    </Typography>

                    <Rating
                      name="simple-controlled"
                      value={this.state.item.noteAssigned}
                      onChange={(event, newValue) => {
                        this.handleChangeInput('noteAssigned', (newValue ?? 0));
                      }}
                      disabled={this.globalAdm}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="Observação" />
                    </Typography>

                    {
                      this.globalAdm 
                      ? (
                        <p>{ this.state.item.observationAssigned ? this.state.item.observationAssigned : "N/A" }</p>
                      )
                      : (
                        <ReactQuill
                          name="observationRequest" 
                          value={this.state.item.observationAssigned}
                          style={{ height: 150, marginBottom: 32 }} 
                          modules={this.reactQuillModules} 
                          formats={this.reactQuillFormats}
                          fullWidth
                          variant="outlined"
                          id="observationAssigned"
                          onChange={(value) => this.handleChangeEditor(value, "observationAssigned")}
                        />	
                      )
                    }

                    				  
                  </Grid>
                  
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!this.globalAdm && (
                      <Button 
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        {<FormattedMessage id="save" />}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>


        </main>
        <Footer />
      </div>
    );
  }
}

ChatClassificationsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calledclassifications: state.calledclassifications
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(ChatClassificationsEdit))
);

ChatClassificationsEdit.contextType = AbilityContext;