import React from 'react'
import Path from '../../../images/image-coming-soon.jpg'
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel'
import './styles.css'
import ChatMessageLocationDialog from './message-type-dialog/chat-message-location-dialog';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { PapiroConsole } from '../../../../../utils/papiroConsole';

function ChatMessageLocation({ content, id, setLoading, initialLatitude, initialLongitude, title }) {
  PapiroConsole.log("=== ChatMessageLocation ===")

  const [openDialog, setOpenDialog] = React.useState(false)
  const [address, setAddress] = React.useState("")
  const [latitude, setLatitude] = React.useState(initialLatitude || '');
  const [longitude, setLongitude] = React.useState(initialLongitude || '');

  React.useEffect(() => {
    return () => {
      setOpenDialog(false);
    };
  }, []);

  React.useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true)
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`,
          {
            method: 'GET',
          }
        );

        if (response.ok) {
          const data = await response.json();
          const formattedAddress = `${data.address.road}, ${data.address.house_number}, ${data.address.road}, ${data.address.suburb}, ${data.address.city}, ${data.address.state} - ${data.address.country}`;
          setAddress(formattedAddress);
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        } else {
          PapiroConsole.log('Failed to fetch address')
          setLoading(false)
        }
      } catch (error) {
        PapiroConsole.log('Error fetching address:')
        PapiroConsole.log(error)
        setLoading(false)
      }
    };

    if (latitude && longitude) {
      fetchAddress();
    }
  }, [latitude, longitude]);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = async (e) => {
    e.preventDefault()

    if (e.target.classList.contains('omnichannel-chat-message-location') || e.target.classList.contains('omnichannel-chat-message-location-icon') || e.target.classList.contains('omnichannel-chat-message-location-name')) {
      setOpenDialog(true)
    }
  }

  return (<div onClick={handleOpenDialog}
      className="omnichannel-chat-message-location" >
    <div className="omnichannel-chat-message-location-icon" >
      <LocationOnIcon style={{ color: '#545454', fontSize: '3em' }} />
    </div>
    <div className="omnichannel-chat-message-location-name" >
      {(address.length > 0) ? address : content}
    </div>
    { (latitude && latitude.length > 0 && longitude && longitude.length > 0 && address && address.length > 0) && <ChatMessageLocationDialog
      latitude={latitude}
      longitude={longitude}
      title={address}
      isOpen={openDialog}
      handleCloseDialog={handleCloseDialog} />}
  </div>)
}

export default React.memo(ChatMessageLocation)
