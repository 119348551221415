import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingscalledfields'
import Api from '../../../../services/api'
import { addItem } from '../../../../services/calledservicesettingscalledfield.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'

function AddCalledServiceSettingsCalledFieldItemDialog(props) {

  const { intl } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const [disableIsRequired, setDisableIsRequired] = useState(false)
  const [requiredFieldMessage, setRequiredFieldMessage] = useState('')
  const [files, setFiles] = useState([])

  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)

    if (props.open) {
      setFiles()
    }
  }, [props.open])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount + 1
      setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })
      PapiroConsole.log("=== props.itemsCount ===")
      PapiroConsole.log(totalOrderItems)
      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        PapiroConsole.log(orderItem)
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([ { id: 1, name: '1' } ])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const setInitialState = () => {
    setLabel('')
    setOrder(null)
    setIsRequired(false)
    setDisableIsRequired(false)
    setRequiredFieldMessage('')
    setFiles([])
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
    if (stateName === 'fieldType') {
      if (value != null && value.id != null && value.id > 0) {
        if (value.id == 1 || value.id == 2 || value.id == 3 || value.id == 4 || value.id == 5) {
          setIsRequired(true)
          setDisableIsRequired(true)
        } else
          setDisableIsRequired(false)
      } else
        setDisableIsRequired(false)
      setFieldType(value)
    }
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (order == null || (order != null && order === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    } else if (fieldType == null || (fieldType != null && fieldType.id == null) || (fieldType != null && fieldType.id != null && fieldType.id == 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Tipo do campo' é obrigatório")
    }

    return true
  }

  const createItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Label", label)
      data.append("Order", order.id)
      data.append("IsRequired", isRequired)
      data.append("RequiredFieldMessage", requiredFieldMessage)
      data.append("FieldType", fieldType.id)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      let response = await addItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "add.success" }))
        if (response.data != null) {
          PapiroConsole.log("=== createItem ===")
          PapiroConsole.log(props.calledservicesettingscalledfields)
          let oldItemsList = props.calledservicesettingscalledfields && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.length > 0 ? [...props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist] : []
          PapiroConsole.log(oldItemsList)
          let orderFilter = oldItemsList.filter(item => item.order >= order.id)
          PapiroConsole.log(orderFilter)
          if (orderFilter && orderFilter.length > 0) {
            let newOrderFilterList = orderFilter.map(item => {
              return {
                ...item,
                order: (item.order + 1)
              }
            })
            let newOrderFilterListIds = orderFilter.map(item => item.id)
            PapiroConsole.log(newOrderFilterList)

            let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
            PapiroConsole.log(notOrderFilterList)
            oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
            PapiroConsole.log(oldItemsList)

          }

          let formattedResponse = response.data
          let fieldTypeName = ''
          switch(formattedResponse.fieldtype) {
            case 1:
              fieldTypeName = 'Título do chamado'
              break
            case 2:
              fieldTypeName = 'Descrição do chamado'
              break
            case 3:
              fieldTypeName = 'Categoria do chamado'
              break
            case 4:
              fieldTypeName = 'Nome do usuário'
              break
            case 5:
              fieldTypeName = 'Email do usuário'
              break
            case 6:
              fieldTypeName = 'Telefone do usuário'
              break
            case 7:
              fieldTypeName = 'Documento do usuário'
              break
            default:
              fieldTypeName = ''
              break
          }
          formattedResponse.fieldTypeName = fieldTypeName

          let itemList = [...oldItemsList, formattedResponse]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsCalledFieldsList(itemList)
        }
        setInitialState()
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-add-called-service-setting-called-field-dialog-title" >
        <DialogTitle id="form-add-called-service-setting-called-field-dialog-title">
          { intl.formatMessage({ id: "add.field" }) }
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="label"
                variant="outlined"
                fullWidth
                id="label"
                label={intl.formatMessage({ id: "name" })}
                value={label}
                required
                onChange={(e) => setLabel(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label={`${intl.formatMessage({ id: "item.order" })}*`}
                options={orderList}
                stateName='order'
                changeSelect={changeValues}
                selected={order} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Ordem de exibição do campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label="Tipo do campo*"
                options={props.fieldTypeList}
                stateName='fieldType'
                changeSelect={changeValues}
                selected={fieldType} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Tipo do do campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  disabled={disableIsRequired}
                  control={
                    <Switch 
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={createItem} >
            {intl.formatMessage({id:"save"})}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

AddCalledServiceSettingsCalledFieldItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  organizationId:  PropTypes.number.isRequired,
  fieldTypeList: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingscalledfields: state.calledservicesettingscalledfields,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AddCalledServiceSettingsCalledFieldItemDialog)))