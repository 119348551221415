import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../../helpers/history';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple';
import * as Service from '../../../services/organizationmaterial.service';
import SimpleCheckBox from '../../../components/checkbox/check';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../../components/title-name';
import SimpleDatePicker from '../../../components/date-pickers/inline-no-today';
import NumberFormat from 'react-number-format';
import SimpleHelp from '../../../components/help';

class OrganizationMaterialRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.arrayLenght = 0;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      id:0,
      updatedUser : 0,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
     OrganizationId:  organizationid,
     OrganizationText: '',
     MaterialId:  0,
     MaterialText: '',
     name : '', 
     garanty : 0, 
     value : '', 
     invoicedate : moment().format('YYYY/MM/DD HH:mm:ss'),
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      MaterialList: [],
      template: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentDidMount() {
        this.getOrganizations();
        this.getMaterials();
        this.getDados();
     };
     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getMaterials(){
          this.setState({ loading: true });
          var result = await Service.getMaterials();
            if (result.success) {
              this.setState({ loading: false, MaterialList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    
    if(name == 'value'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['value']: value
        }
      }));
      
    }else{
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }
    
  };

  mtel(num){
    num=num.toString().replace(/\D/g,"");
    //
    if(num.toString().length > 5 && num.toString().length < 7){
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }else if (num.toString().length >= 7) {
      num=num.replace(/(\d{1,2})$/, ',$1');  
      num=num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');  
      num=num.replace(/^0+/, '');

    }else if (num.toString().length == 4){
        num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    }else{
        num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { intl } = this.props;

    if (!this.state.item.OrganizationId) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.required.message" })
      })
      return
    } 

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {

        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1 || key.toString().toLowerCase().indexOf('invoicedate') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('material') > -1) {
          let list = this.state.item[key];
          
          if(list != null && list != ""){
            let result = "";

            for (let value in this.state.item[key]) {
              result = this.state.item[key][value].id+","+result
            }
            
            data.append("MaterialLot", result);
            data.append("name", "Materiais em Lote");
          }

        } else if (key.toString().toLowerCase().indexOf('value') > -1) {
          let oldVSplit = this.state.item[key].split(',')
          let oldV = oldVSplit[0]
          if(oldV.length > 0) {
            oldV = oldV.replaceAll('.', ',')
            if('1' in oldVSplit)
              oldV += `.${oldVSplit[1]}`
          }

          data.append("value", oldV);
        }else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if(this.arrayLenght > 0)
      {
        Api.put('/organizationmaterials', data, config)
        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });

              this.props.next();

          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
      }
      else
      {
        Api.post('/organizationmaterials', data, config)

        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });
        this.getOrganizations();
        this.getMaterials();
        this.props.handleComplete();
        this.props.handleNextBarProgress();


          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
      }
        
  };

  async getDados(){
    this.setState({ loading: true });
    var result = await Service.getMaterialsOrganizations(this.props.organizationid);
    this.arrayLenght = result.data.length;
      if (result.success) {
        if(result.data.length > 0){
          
          this.setState({
            template: false
          });
          let resultData = result.data[0];

          console.log(resultData)

          var value = resultData.value;

          if(resultData.value) {
            let oldVSplit = value.toString().split('.')
            let oldV = oldVSplit[0]
            if(oldV.length > 0) {
              oldV = oldV.replaceAll(',', '.')
              if('1' in oldVSplit)
                oldV += `.${oldVSplit[1]}`
            }
            value = oldV
          }
          console.log("aqui")
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['name']: resultData.name,
              ['ParentOrganizationDepartamentId']:resultData.parentorganizationdepartament,
              ['id']: resultData.id,
              ['updatedUser'] : resultData.id,
              ['value']: value,
              ['invoicedate'] : resultData.invoicedate,
              ['garanty'] : resultData.garanty,

              
          
            }
          }));
          /*
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['id']: resultData.id,
              ['OrganizationSlaId']: resultData.organizationslaid,
              ['CalledFlowId']:  resultData.calledflowid,
              ['SlaCountTypeId']:  resultData.slacounttypeid,
              ['CategoryId']:  resultData.categoryid,
              ['ParentOrganizationCategoryId']:  resultData.parentorganizationcategoryid,
              ['name'] : resultData.name, 
              ['cancreatecalled'] : resultData.cancreatecalled, 
              ['imageicon'] : resultData.imageicon, 
              ['parentname'] : resultData.parentname, 
              ['servicetime'] : resultData.servicetime, 
              ['solutiontime'] : resultData.solutiontime, 
              ['useslatime'] : resultData.useslatime, 
              ['serviceslanotification'] : resultData.serviceslanotification, 
              ['isdefaultcategory'] : resultData.isdefaultcategory,
            },
            update: true
          }));*/
        }
        
        this.setState({ loading: false });
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'MaterialId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['MaterialText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

    render() {
      console.log(this.state)
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    console.log(this.state)
     return (
      <div className={classes.root}>
       
      
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />} 
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect disabled label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required={true} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.material.inform.material"})}  name='template' stateName='template' 
                      changeSelect={(event, newValue) => {
                        this.setState({
                          template: newValue
                        });
                      }}/>
                    </Grid>
                  {this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"menu.organizationmaterials"})} options={this.state.MaterialList} stateName='MaterialId' changeSelect={this.changeValues} selected={item.MaterialId} />
                    </Grid>
                  )}
                  {!this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})}  
                      inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                  )}
                  <Grid item xs={3} sm={3}>
                   <TextField
                      label={intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={item.value}
                      onChange={this.handleInputChange}
                      name="value"
                      id="formatted-numberformat-input"
                      //disabled = {this.state.template}
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete='fname'
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                    </Grid>
                    {/*<Grid item xs={2} sm={2} style={{marginLeft: "-10px"}}>
                      <SimpleHelp message={"Usar . como separador decimal"}/>
                    </Grid>*/}
                  
                  
                {!this.state.template && (
                    <Grid item xs={3} sm={3}>
                      <SimpleDatePicker label={intl.formatMessage({id:"material.registration.note.date"})} name='invoicedate' stateName='invoicedate' changeDate={this.changeValues} currentDate={item.invoicedate}  required/>
                    </Grid>)}
                {!this.state.template && (
                    <Grid item xs={3} sm={3}></Grid>
                )}
                {!this.state.template && (
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"garanty"})} type='number' variant='filled' InputLabelProps={{shrink: true}} name='garanty' onChange={this.handleInputChange} value={item.garanty} required />
                    </Grid>
                )}
                </Grid>

<Grid container spacing={0}  style={{marginBottom: '60px', display: 'flex', marginTop:'20px'}}>
                
                <Grid item xs={12} sm={2} >
                      {this.props.backButton}


                  </Grid>

                  <Grid item xs={12} sm={2} >
                  {this.props.nextButton} 


                  </Grid>
                  <Grid item container xs={12} sm={6} style={{marginLeft:'auto'}} spacing={2}>
                    <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                          {this.props.finishButton}
                    </Grid>
                  </Grid>
              
            
             </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        
      </div>
    );
  }
}
OrganizationMaterialRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationMaterialRegistration)));
