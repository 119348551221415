export function addCurrent(address) {
    return {
        type: 'ADD_CURRENT',
        address,
        }
}
export function addCurrentOrganization(address,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        address,
        organization,
        }
}
