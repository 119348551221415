import React from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //width:'600px',
        //height:'600px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #ffffff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        zIndex: 1301
    },
}));

export default function ChartModal(props) {
    const classes = useStyles();
    const { children, isopen, handleOpen, handleClose, expand } = props;
    const [open, setOpen] = React.useState(false);


    React.useEffect(() => {
        setOpen(isopen);
    }, [isopen]);

    //const handleOpen = () => {
    //  setOpen(true);
    //};

    //const handleClose = () => {
    //  setOpen(false);
    //};


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Grid container xs={11} sm={11} className={classes.paper} justifyContent='end'>
                        <Grid item xs={0.5} sm={0.5} paddingRight={0} >
                            <Button onClick={handleClose}>X</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            {children}
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}