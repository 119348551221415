import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch, IconButton } from '@mui/material'
import Api from '../../../../services/api'
import * as Service from '../../../../services/calledservicesettingsorganizationfield.service'
import * as ValidationsService from '../../../../services/calledservicesettingsorganizationfieldvalidation.service'
import * as AttachmentsService from '../../../../services/calledservicesettingsorganizationfieldattachment.service'
import { FormattedMessage } from 'react-intl'
import SimpleHelp from "../../../../components/help"
import SimpleCheckBox from '../../../../components/checkbox/check'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingsorganizationfields'
import { DataGrid, GridActionsCellItem, GridToolbarFilterButton  } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import DialogConfirmation from '../../../../components/dialog-confirmation'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit';
import AddCalledServiceSettingsOrganizationFieldItemDialog from './addItemDialog'
import UpdateCalledServiceSettingsOrganizationFieldItemDialog from './updateItemDialog'

function CalledServiceSettingsOrganizationFields(props) {

  const { classes, intl, userId, hirerId, calledServiceSettingsId, organizationId } = props

  const [calledServiceSettingsOrganizationFieldList, setCalledServiceSettingsOrganizationFieldList] = useState([])
  const [calledServiceSettingsOrganizationFieldListForDelete, setCalledServiceSettingsOrganizationFieldListForDelete] = useState([])
  const [calledServiceSettingsOrganizationFieldInsertDialogOpen, setCalledServiceSettingsOrganizationFieldInsertDialogOpen] = useState(false)
  const [calledServiceSettingsOrganizationFieldUpdateDialogOpen, setCalledServiceSettingsOrganizationFieldUpdateDialogOpen] = useState(false)

  const [itemEditData, setItemEditData] = useState(null)

  useEffect(() => {
    if (props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.length > 0)
      setCalledServiceSettingsOrganizationFieldList(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist)
  }, [props.calledservicesettingsorganizationfields])

  const NoDataRows = _ => {
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
    )
  }

  const setCalledServiceSettingsOrganizationFieldDelete = calledServiceSettingsOrganizationField => setCalledServiceSettingsOrganizationFieldListForDelete(calledServiceSettingsOrganizationField)

  const handleOpenCalledServiceSettingsOrganizationFielInsertModal = isOpen => setCalledServiceSettingsOrganizationFieldInsertDialogOpen(isOpen)

  const handleOpenCalledServiceSettingsOrganizationFielUpdateModal = isOpen => {
    setCalledServiceSettingsOrganizationFieldUpdateDialogOpen(isOpen)
    if (!isOpen)
      setItemEditData(null)
  }

  const calledServiceSettingsOrganizationFieldDeleteModal = async _ => {
    let idList = ""
    calledServiceSettingsOrganizationFieldListForDelete.map(id => {
      idList += `${id},`
    })

    if (idList.length > 0) {
      props.setLoading(true)
      let response = await Service.deleteItems(idList)
      if (response && response.success) {
        let notIdList = props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.filter(item => !calledServiceSettingsOrganizationFieldListForDelete.includes(item.id))
        if (notIdList != null) {
          let loopOrder = 0
          let notIdListOrdened = notIdList.sort((a, b) => a.id - b.id)
          let notIdListFinal = notIdListOrdened.map(item => {
            return {
              ...item,
              order: ++loopOrder
            }
          })
          props.addCalledServiceSettingsOrganizationFieldsList(notIdListFinal)
        } else
          props.addCalledServiceSettingsOrganizationFieldsList([])
        setCalledServiceSettingsOrganizationFieldListForDelete([])
        props.setLoading(false)
        props.setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
      } else {
        props.setLoading(false)
        props.setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  const editItem = async (params) => {
    PapiroConsole.log("=== editItem ===")
    PapiroConsole.log(params)
    await props.addCalledServiceSettingsOrganizationField(params.row)
    setItemEditData(params.row)
    handleOpenCalledServiceSettingsOrganizationFielUpdateModal(true)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={12} style={{ padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
        <Grid xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
          { calledServiceSettingsOrganizationFieldListForDelete.length > 0 && <DialogConfirmation
            msgButton={intl.formatMessage({id: "delete.organization.field" })}
            msg={intl.formatMessage({id: "delete.organization.field.confirmation.message" })}
            msgDisagree={intl.formatMessage({id:"cancel"})}
            msgAgree={intl.formatMessage({id:"yes"})}
            handleConfirmation={e => calledServiceSettingsOrganizationFieldDeleteModal()}
            classes={classes}
            icon={<DeleteIcon />} />}
        </Grid>
        <Grid xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
          { props.organizationId > 0 && <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={e => handleOpenCalledServiceSettingsOrganizationFielInsertModal(true)} >
            {intl.formatMessage({id:"add.organization.field"})}
          </Button>}
        </Grid>
      </Grid>
      <Grid xs={12} sm={12}
        style={{
          border: '3px solid #eee'
        }} >
        <div style={{ height: 350 }}>
          <DataGrid
            style={{ fontSize: 14, color: '#000' }}
            className="DataGridStyle"
            components={{
              NoRowsOverlay: NoDataRows,
              Toolbar: GridToolbarFilterButton,
            }}
            rows={calledServiceSettingsOrganizationFieldList}
            rowsPerPageOptions={[10, 25, 50]}
            checkboxSelection
            onSelectionModelChange={itm => {
              if (itm.length > 0) {
                PapiroConsole.log("=== onSelectionModelChange ===")
                PapiroConsole.log(itm)
                setCalledServiceSettingsOrganizationFieldDelete(itm);
              } else {
                setCalledServiceSettingsOrganizationFieldListForDelete([])
              }
            }}
            columns={[
              {
                headerName: intl.formatMessage({id:"edit"}),
                field: "actions",
                renderCell: (params) => (
                  <IconButton
                    arya-label="edit"
                    onClick={() => editItem(params)} >
                    <EditIcon />
                  </IconButton>
                )
              },
              { headerName: intl.formatMessage({ id: "organization.field" }), field: 'label', width: 600 },
              { headerName: intl.formatMessage({ id: "item.order" }), field: 'order', width: 100 }
            ]}
          />
        </div>
      </Grid>
      <AddCalledServiceSettingsOrganizationFieldItemDialog
        open={calledServiceSettingsOrganizationFieldInsertDialogOpen}
        handleOpen={handleOpenCalledServiceSettingsOrganizationFielInsertModal}
        itemsCount={calledServiceSettingsOrganizationFieldList?.length}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsId={calledServiceSettingsId}
        organizationId={organizationId} />
      <UpdateCalledServiceSettingsOrganizationFieldItemDialog
        open={calledServiceSettingsOrganizationFieldUpdateDialogOpen}
        handleOpen={handleOpenCalledServiceSettingsOrganizationFielUpdateModal}
        itemsCount={calledServiceSettingsOrganizationFieldList?.length}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsId={calledServiceSettingsId}
        organizationId={organizationId}
        itemData={itemEditData} />
    </Grid>
  )
}

CalledServiceSettingsOrganizationFields.propTypes = {
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsorganizationfields: state.calledservicesettingsorganizationfields,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CalledServiceSettingsOrganizationFields)