import React, { Component, useEffect  } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from "@mui/material/Link";
import moment from "moment";
import Grid from '@mui/material/Grid';
import SimpleCheckBox from '../checkbox/check';
import Api from "../../services/api";
import { Button, IconButton, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReactHtmlParser from 'react-html-parser';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import CheckIcon from '@mui/icons-material/Check';
import { PapiroConsole } from "../../utils/papiroConsole";
import './styles.css'
import { FormattedMessage,injectIntl } from 'react-intl';
import parse from "html-react-parser";
import EditIcon from '@mui/icons-material/Edit';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { update } from "../../services/accompaniment.service";
import DOMPurify from "dompurify";

class AccompanimentAction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editAccompaniment: false,
            description: this.props.action.description 
        }

        PapiroConsole.log(this.props.action)
        PapiroConsole.log("this.props.action")

        this.reactQuillModules = {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline','strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
            ],
        }
        
        this.reactQuillFormats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ]

        this.handleChangeEditor = this.handleChangeEditor.bind(this)
        this.handleShowEditAccompaniment = this.handleShowEditAccompaniment.bind(this)
        this.handleHideEditAccompaniment = this.handleHideEditAccompaniment.bind(this)
        this.updateAccompaniment = this.updateAccompaniment.bind(this)
    }

    handleChangeEditor = (value) => this.setState({ description: value || "" })

    handleShowEditAccompaniment = _ => this.setState({ editAccompaniment: true })

    handleHideEditAccompaniment = _ => this.setState({ editAccompaniment: false, description: this.props.action.description })

    updateAccompaniment = async _ => {
        const { intl, setLoading, setMessage, convertHtmlOption, refreshAccompaniments, action, userId, calledId } = this.props
        

        let sanitizedText = convertHtmlOption(this.state.description)
        if (sanitizedText != null && sanitizedText.length > 0) {
            setLoading(true)
            let data = new FormData()
            data.append("Id", action.id)
            data.append("CalledId", calledId)
            data.append("Description", this.state.description)
            data.append("UserAction", userId)

            let response = await update(data)
            if (response && response.success) {
                setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
                this.setState({ editAccompaniment: false })
                refreshAccompaniments()
            } else {
                setLoading(false)
                setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
                Api.kickoff(response)
            }
        } else {
            setMessage(true, 'error', intl.formatMessage({ id: "erro.action.rejection" }))
        }
    }

    render() {
        const intl = this.props.intl;

        const { classes, action, showPrivacy, attachments, getFile, id, finishAccompanimentId, addAccompanimentToKnowledgeBase, deleteKnowledgeBase, knowledgebage, originId, openAccompanimentLocationModal, client, checkIfCalledIsArchived, displayassignedinfomode, techinicalteamname } = this.props;

        //this.action = renderToStaticMarkup(<p> {action.description} </p>);
        this.action =action.description;
        PapiroConsole.log("ação aqui")
        PapiroConsole.log(this.props)

        if(action.description.trim().startsWith("<p> &lt;h") || action.description.trim().startsWith("<p>&lt;div")) {
            PapiroConsole.log("=== AccompanimentAction if 1 ===")
            this.action = renderToStaticMarkup(action.description)
            PapiroConsole.log(this.action)
        } else if (action.description.trim().startsWith("<div style=\"") || action.description.trim().startsWith("<div dir=") || action.description.trim().startsWith("<p>") || action.description.trim().startsWith("<h1>") || action.description.trim().startsWith("<h2>") || action.description.trim().startsWith("<h3>")) {
            this.action = action.description
            PapiroConsole.log("=== AccompanimentAction else if ===")
            PapiroConsole.log(this.action)
        }

        this.action = DOMPurify.sanitize(this.action)

        const handleFile = (fileName, id,filerepositorymethod) => {
            getFile(fileName, id,filerepositorymethod);
        }
        const handleKnowledgeBaseState = async (statename, value) => {
            //? Adiciona à base ou deleta dependendo do estado do checkbox (value)
            value ? addAccompanimentToKnowledgeBase() : deleteKnowledgeBase(); 
        }
        const handleOpenAccompanimentLocationModal = () => {
            openAccompanimentLocationModal()
        }

        const isUserActionOnlyClient = (action.useraction.userroles.length == 1 && action.useraction.userroles[0].role && action.useraction.userroles[0].role.roletype == 3)
        PapiroConsole.log("AÇão")
        return (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                    <div className={classes.actionBox}>
                        <Grid item xs={12} sm={12}>
                            <div className={action.privacy ? 'privacyAction' : 'publicAction'}>
                                <p>
                                {isUserActionOnlyClient &&
                                    <span className="user-action-name">{action.useraction.name}</span>
                                }

                                {(displayassignedinfomode == 1 || (!client && displayassignedinfomode == 2)) && !isUserActionOnlyClient &&
                                    <span className="user-action-name">{action.useraction.name}</span>
                                }

                                {((client && displayassignedinfomode == 2) || displayassignedinfomode == 4) && !isUserActionOnlyClient &&
                                    <span className="user-action-name">{action.calledtechinicalteam ? action.calledtechinicalteam.name : null}</span>
                                }

                                    <span className="date">{moment(action.actiontime).format("DD/MM/YYYY HH:mm")}</span>
                                    {showPrivacy && <span className="type">{action.privacy ? this.props.intl.formatMessage({id:"private.action"}) : this.props.intl.formatMessage({id:"public.action"})}</span>}
                                    { (originId && (originId == 6 || originId == 11)) && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={handleOpenAccompanimentLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}
                                    { action.calledclosed && (<Tooltip title={<h5>{this.props.intl.formatMessage({id:"accompaniment.actions.title.recently.added.action"})}</h5>}><IconButton style={{ padding: 0, marginLeft: 10 }} aria-label="CheckIcon"><CheckIcon style={{ fontColor: "#43a047", fill: "#43a047" }} /></IconButton></Tooltip>) }
                                    {this.props.action.edited && <span className="date">(Editado)</span> }
                                    <div style={{ float: "right", display: "flex" }}>
                                        <span style={{ border: "none", marginTop: "-10px"}}>
                                        {(!action.privacy && !client && !checkIfCalledIsArchived) && 
                                            <SimpleCheckBox 
                                                label={this.props.intl.formatMessage({id:"add.to.knowledgebase"})}
                                                name='knowledgebage' 
                                                stateName='knowledgebage' 
                                                changeSelect={handleKnowledgeBaseState} 
                                                selected={knowledgebage} />
                                        }
                                        </span>
                                        {!this.state.editAccompaniment && <span style={{ border: "none"}}>
                                            { ((this.props.caneditaccompanimentinactivecalled && this.props.clientStatus && this.props.clientStatus.statustypeid && this.props.clientStatus.statustypeid == 2) && (this.props.globalAdm || (this.props.userId == action.useraction.id))) && (<Tooltip title={this.props.intl.formatMessage({id:"edit.accompaniment"})}><IconButton style={{ padding: 0 }} aria-label="LocationIcon"><EditIcon onClick={this.handleShowEditAccompaniment} style={{ fontColor: "rgb(39, 122, 254)", fill: "rgb(39, 122, 254)" }} /></IconButton></Tooltip>)}
                                        </span>}
                                    </div>
                                </p>
                            </div>
                        </Grid>
						{/*<p><div className="action-text-content" style={{ fontWeight: 'normal' }} dangerouslySetInnerHTML={{__html: this.action}} /></p>*/}
						{/*<div className="action-text-content" style={{ fontWeight: 'normal' }}> { ReactHtmlParser (this.action) } </div>*/}
						{!this.state.editAccompaniment && 
                            <p style={{ wordBreak: 'break-word'}}><div className="action-text-content" style={{ fontWeight: 'normal' }}> { parse (this.action) } </div></p>
                        }
                        {this.state.editAccompaniment && (<Grid container spacing={2}>
                            <Grid item xs={1} sm={1} md={1} lg={1} />
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                <ReactQuill
                                    name="description"
                                    value={this.state.description || ''}
                                    style={{ minHeight: 200 }}
                                    fullWidth
                                    modules={this.reactQuillModules}
                                    formats={this.reactQuillFormats}
                                    required
                                    InputLabelProps={{ shrink: this.state.description && this.state.description.length > 0 ? true : false }}
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    onChange={this.handleChangeEditor} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}/>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Button variant="contained" fullWidth onClick={this.handleHideEditAccompaniment} color="secondary">
                                        {intl.formatMessage({ id: "cancel" })}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Button variant="contained" fullWidth onClick={this.updateAccompaniment} color="primary">
                                            {intl.formatMessage({ id: "save" })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>)}
                        <div className={classes.attachmentsBox} style={{border: 0}}>
                            <ul>
                                {attachments.map(item => {
                                    if(item.attachment.type.indexOf("image/png") > -1 || item.attachment.type.indexOf("image/jpg") > -1 || item.attachment.type.indexOf("image/jpeg") > -1){
                                        return (<li key={item.attachment.path}>
                                            <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment.path, id, item.attachment.filerepositorymethod)}> { item.attachment.path.split('/').pop()} { /*<img style={{maxHeight: '250px', maxWidth: '250px'}} src={Api.apiurlbase()+item.attachment.path}></img>*/ }</Link>
                                        </li>)
                                    }else{
                                        return (<li key={item.attachment.path}>
                                            <AttachFileIcon /> <Link onClick={() => handleFile(item.attachment.path, id, item.attachment.filerepositorymethod)}>{item.attachment.description}</Link>
                                        </li>)
                                    }
                                    
                                })}
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

AccompanimentAction.propTypes = {
    classes: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    showPrivacy: PropTypes.bool.isRequired,
    attachments: PropTypes.array,
    getFile: PropTypes.func,
    id: PropTypes.number
};

export default injectIntl(withStyles(Styles)(AccompanimentAction));