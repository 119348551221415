import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material'
import { AccessTime, Check, ExpandMore, History } from '@mui/icons-material'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import moment from 'moment'

function CalledHistory(props) {

  const [expanded, setExpanded] = useState(true)

  const handleChangeExpand = _ => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChangeExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="called-costs-content"
        id="called-costs-header">
        <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}><History />&nbsp;Atendimento</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0}>
          {props.calledData != null && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
              { props.calledData.solutiondate != null && <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: '#fff', borderColor: '#43a047', width: '20px', height: '20px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Check sx={{ color: '#43a047', fill: '#43a047', fontSize: '1em' }} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5 style={{ margin: 0 }}>Solucionado</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0}}>{moment(props.calledData.solutiondate).format("DD/MM/YYYY HH:mm")}</h6>
                    </Grid>
                  </Grid>
                </TimelineContent>
              </TimelineItem>}
              { props.calledData.servicestartdate != null && <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: '#fff', borderColor: '#43a047', width: '20px', height: '20px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Check sx={{ color: '#43a047', fill: '#43a047', fontSize: '1em' }} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5 style={{ margin: 0 }}>Atendimento iniciado</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0}}>{moment(props.calledData.servicestartdate).format("DD/MM/YYYY HH:mm")}</h6>
                    </Grid>
                  </Grid>
                </TimelineContent>
              </TimelineItem>}
              { props.calledData.openingdate != null && <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: '#fff', borderColor: '#43a047', width: '20px', height: '20px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Check sx={{ color: '#43a047', fill: '#43a047', fontSize: '1em' }} />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h5 style={{ margin: 0 }}>Criado</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0}}>{moment(props.calledData.openingdate).format("DD/MM/YYYY HH:mm")}</h6>
                    </Grid>
                  </Grid>
                </TimelineContent>
              </TimelineItem>}
            </Timeline>
          </Grid>}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

CalledHistory.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledHistory)))