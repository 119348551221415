import Api from './api';
export const getOrganizations = async (id = 0) => {

  let data = [];
  if(id != 0){
    await Api.get(`/addresses/organizations/${id}`)
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
  }else{
   
    await Api.get('/organizations')
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
});
  }
  return data;
};

export const getAllByOrganizationIdSelectionModel = async (organizationId) => {

  let data = [];
  await Api.get(`/addresses/organization/${organizationId}/selectionmodel`)
    .then(result => {
      data = result.data
    })
     .catch(err => {
      data = err;
    });
  return data;
}

export const getUsers = async () => { 
  let data = [];
  await Api.get('/users')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getFirstByUserId = async (userId) => { 
  let data = [];
  await Api.get(`/addresses/first/user/${userId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const addItem = async (formData, config) => { 
  let data = []
  await Api.post(`/addresses`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData, config) => { 
  let data = []
  await Api.put(`/addresses`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/addresses/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}