import Api from './api';
export const getTechinicalTeams = async () => { 
  let data = [];
  await Api.get('/techinicalteams')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllOrganizationsSelectionModel = async () => { 
  let data = [];
  await Api.get('/organizations/selectionmodel')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllNotInTechinicalTeamSelectionModel = async (techinicalTeamId) => { 
  let data = [];
  await Api.get(`/organizations/notin/techinicalteam/${techinicalTeamId}/selectionmodel`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getAllByTechnicalTeamId = async (id) => {
  let data = []
  await Api.get(`/techinicalteamorganizations/technicalteam/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllByTechnicalTeamIdSelectionModel = async (id) => {
  let data = []
  await Api.get(`/techinicalteamorganizations/technicalteam/${id}/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const associateTechinicalTeams = async (formData) => {
  let data = []
  await Api.post("/techinicalteamorganizations/associate", formData)
    .then((result) => {
      data = result.data
    })
    .catch((err) => {
      data = err
    })
  return data
}