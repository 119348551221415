import React, { Component } from "react";
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { makeStyles } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';
import Api from "../../services/api";
import Budget from '../dashboard/components/budget';
import TutorialProgress from '../dashboard/components/tutorialProgress';
import { Overlay } from "../../styles/global";
import TotalUsers from '../dashboard/components/totalusers';
import TasksProgress from '../dashboard/components/tasksprogress';
import ComparationCalledForMonth from '../dashboard/components/latestsales';
import * as TableCustomFilterService from '../../services/tablecustomfilter.service'
import CustomizedSnackbars from "../../components/material-snackbars"
import CalledForStatus from '../dashboard/components/usersbydevice';
import LastFeedback from '../dashboard/components/latestproducts';
import LastFeedbackPdf from '../dashboard/components/latestproductspdf';
import Pizza from '../dashboard/components/pizza';
import Footer from '../../components/footer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
import Pdf from "react-to-pdf";
//import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
//import ReactPDF from '@react-pdf/renderer';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleSelect from '../../components/select/simple';
import Chip from '@mui/material/Chip';
import {
  CardHeader
} from '@mui/material';
import * as ExcelJS from 'exceljs'
import { bindActionCreators } from 'redux';
import * as calledsActions from '../../redux/actions/calleds';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import CardContent from '@mui/material/CardContent';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import moment from 'moment';
import Divider from '@mui/material/Divider'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from '@mui/material';
import LogoMarca from '../../images/new-logo-cmtech.png';
import * as CalledService from '../../services/called.service';
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import * as Service from "../../services/user.service";
import FilterListIcon from '@mui/icons-material/FilterList';
import { getAttendanceTimeDashboardData, getSolutionTimeDashboardData, getAttendanceAndSolutionTimeDashboardData } from '../../services/calledstatus.service'
import MontlySolutionTimeAverage from './components/montly-solution-time-average'
import MontlyServiceTimeAverage from './components/montly-service-time-average'
import TechnicalRank from './components/technical-rank'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import PizzaNps from "../dashboard/components/pizzaNps";
import { PapiroConsole } from "../../utils/papiroConsole";
import { saveAs } from 'file-saver';
import { getAllSelectionModel } from '../../services/techinicalteam.service'
import { getAllSelectionModelByTechnicalTeam } from '../../services/organization.service'
import { getAllUsersSelecionModelByRoleTypeAndOrganizationIdAndTechnicalTeamIdAndUserId } from '../../services/user.service'
import { evaluateSession } from '../../utils/sessionUtils'
import SimpleAutoCompleteSearch from "../../components/auto-complete/autocomplete-search-api";

const data = [
  {
    name: 'Page A', uv: 4000, chamados: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, chamados: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, chamados: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, chamados: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, chamados: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, chamados: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, chamados: 4300, amt: 2100,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

class ProductivityDashboard extends Component {

  constructor(props) {
    super(props);
    this.Reload = this.Reload.bind(this);
    this.Reload();


    const { userSession } = this.props;

    evaluateSession(userSession)

    this.isClient = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.onlyAdmOrganization = false;
    this.percentSlasisCoordenator = false;
    this.isTechinical = false;

    // this.organizationId=null;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true;
      }
    });
    if (this.AdmOrganization == true && this.globalAdm == false) {
      this.onlyAdmOrganization = true;
    }

    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    const listMonthts = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      totalCalled: 0,
      comparation: 0,
      positiveMonth: true,
      totalClassification: 0,
      comparationClassification: 0,
      positiveClassificationMonth: true,
      progressCalled: 0,
      grid: 4,
      grid12: 12,
      WindowSize: window.innerWidth * 0.9,
      maxWidth: '100%',
      width: '100%',
      calledStatus: [],
      calledStatusChart: [],
      percentSlas: [],
      listMonthts: listMonthts,
      dataStatus: [],
      classificationNoteList: [],
      NpsNoteList:[],
      dataStatusName: [],
      dataStatusColor: [],
      dataLastFeedback: [],
      dataCalledForMonth: [],
      loading: true,
      organization: 0,
      isTutorial: false,
      showChart: false,
      showButtonPdf: false,
      loadingSLA: false,
      organizationsList: [],
      organization: 0,
      technicalTeamList: [],
      technicalTeam: 0,
      userList: [],
      user: 0,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      favoriteFilters: [],
      attendanceTimeData: null,
      solutionTimeData: null,
      technicalRank: []
    };

    this.handleResize = this.handleResize.bind(this);
    this.getById = this.getById.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.search =  this.search.bind(this);
    this.getCalledForMonthTotal = this.getCalledForMonthTotal.bind(this);
    this.getTechnicalAttendanceRank = this.getTechnicalAttendanceRank.bind(this)
    this.getCalleds = this.getCalleds.bind(this)
    this.getOrganizations = this.getOrganizations.bind(this)
    this.getCalledTotal = this.getCalledTotal.bind(this)
    this.getProgressTotal = this.getProgressTotal.bind(this)
    this.getCalledStatusTotal = this.getCalledStatusTotal.bind(this)
    this.getFavoritesAndCountByUserIdAndTableName = this.getFavoritesAndCountByUserIdAndTableName.bind(this)
    this.getPdf = this.getPdf.bind(this)
    this.getExcel = this.getExcel.bind(this)
    this.handleResize = this.handleResize.bind(this);
    this.getAllSelectionModel = this.getAllSelectionModel.bind(this)
    this.getUsers = this.getUsers.bind(this)
  }

  async getAttendanceAndSolutionTimeDashboardData(organizationId = '', technicalTeamId = '', userId = '') {
    let startDate = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(this.state.dateEnd).format('YYYY-MM-DD 00:00:00')

    PapiroConsole.log("=== getAttendanceAndSolutionTimeDashboardData ===")
    PapiroConsole.log(organizationId)
    PapiroConsole.log(technicalTeamId)
PapiroConsole.log(userId)

    let result = await getAttendanceAndSolutionTimeDashboardData(startDate, endDate, (organizationId == null || organizationId == '') ? 0 : organizationId, (technicalTeamId == null || technicalTeamId == '') ? 0 : technicalTeamId, (userId == null || userId == '') ? 0 : userId)
    if (result && result.success) {
      if (result.data != null)
	    //alert(JSON.stringify(result.data));
        this.setState({ attendanceTimeData: result.data.calledattendancedashboarddatalist, solutionTimeData:result.data.calledsolutiondashboarddatalist , showButtonPdf: true})
	} else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }

  async getAttendanceTimeDashboardData(organizationId = '', technicalTeamId = '', userId = '') {
    let startDate = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(this.state.dateEnd).format('YYYY-MM-DD 00:00:00')

    PapiroConsole.log("=== getAttendanceTimeDashboardData ===")
    PapiroConsole.log(organizationId)
    PapiroConsole.log(technicalTeamId)
    PapiroConsole.log(userId)

    let result = await getAttendanceTimeDashboardData(startDate, endDate, (organizationId == null || organizationId == '') ? 0 : organizationId, (technicalTeamId == null || technicalTeamId == '') ? 0 : technicalTeamId, (userId == null || userId == '') ? 0 : userId)
    if (result && result.success) {
      if (result.data != null)
        this.setState({ attendanceTimeData: result.data })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }
  
  async getSolutionTimeDashboardData(organizationId = '', technicalTeamId = '', userId = '') {
    let startDate = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(this.state.dateEnd).format('YYYY-MM-DD 00:00:00')

    PapiroConsole.log("=== getSolutionTimeDashboardData ===")
    PapiroConsole.log(organizationId)
    PapiroConsole.log(technicalTeamId)
    PapiroConsole.log(userId)

    let result = await getSolutionTimeDashboardData(startDate, endDate, (organizationId == null || organizationId == '') ? 0 : organizationId, (technicalTeamId == null || technicalTeamId == '') ? 0 : technicalTeamId, (userId == null || userId == '') ? 0 : userId)
    if (result && result.success) {
      if (result.data != null)
        this.setState({ solutionTimeData: result.data, showButtonPdf: true })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }

  async getTechnicalAttendanceRank(organizationId = '', technicalTeamId = '', userId = '') {
    let startDate = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(this.state.dateEnd).format('YYYY-MM-DD 00:00:00')

    PapiroConsole.log("=== getTechnicalAttendanceRank ===")
    PapiroConsole.log(organizationId)
    PapiroConsole.log(technicalTeamId)
    PapiroConsole.log(userId)

    let result = await CalledService.getTechnicalAttendanceRank(startDate, endDate, (organizationId == null || organizationId == '') ? 0 : organizationId, (technicalTeamId == null || technicalTeamId == '') ? 0 : technicalTeamId, (userId == null || userId == '') ? 0 : userId)

    if (result && result.success) {
      if (result.data != null)
        this.setState({ technicalRank: result.data })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }


  getTechnicalRank() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  async getCalledForMonthTotal(organizationId = '') {

    let url = `/dashboard/monthCalled`;

    if(organizationId != null || organizationId != '' || organizationId != 0){
      url += "/" + organizationId;
    }

    // console.log("a url usada aqui é essa :::: " + url);

    Api.get(url)
      .then(result => {
        if (result.data.success) {

          let list = result.data.data.map((item) => {
            return {
              ...item,
              name: item.month,
              'Chamados': item.count

            }
          })
          //list.sort((a, b) => b.monthnumber < a.monthnumber ? -1 : b.monthnumber > a.monthnumber ? 1 : 0)
          this.setState({
            dataCalledForMonth: list,
            // loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getClassificationPie(organizationId = '') {

    // this.setState({loading: true})
    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }
    
    let url = `/calledclassifications/chart/query?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          this.setState({
            classificationNoteList: result.data.data,
            //loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getNpsClassification(organizationId = '') {

    // this.setState({loading: true})
    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }
    
    let url = `/nps/chart/query?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&organizationId=${organizationId}`;


    console.log(url)
    console.log("url aqui")
    Api.get(url)
      .then(result => {
        console.log(result)
        console.log("result quero")
        if (result.data.success) {
          this.setState({
            NpsNoteList: result.data.data,
            //loading: false,
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getCalledTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/all/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          this.setState({
            totalCalled: result.data.data.totalmonth,
            comparation: result.data.data.totalmonthpass,
            positiveMonth: result.data.data.positivemonth
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });


    // this.setState({ loading:false })
  }

  async getLastClassification(organizationId = '') {
    
    let url = `/dashboard/lastFeedback`;

    if(organizationId != null || organizationId != '' || organizationId != 0){
      url += "/" + organizationId;
    }

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          // console.log("feedback abaixo")
          // console.log(result.data.data)
          this.setState({
            dataLastFeedback: result.data.data,
            // loading: false
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })

  }

  async getProgressTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/progress/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {

          if (result.data.data.totalinprogress > 0) {
            this.setState({
              progressCalled: result.data.data.totalinprogress,
              // loading: false
            });
          } else {
            this.setState({
              progressCalled: 0,
              // loading: false
            });
          }
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getExcel() {
    const intl = this.props.intl;

    let startDate = moment(this.state.dateStart).format('_YYYY-MM-DD')
    let endDate = moment(this.state.dateEnd).format('_YYYY-MM-DD')

    let wb = new ExcelJS.Workbook()

    let montlyServiceTime = wb.addWorksheet(intl.formatMessage({ id: "productivity.dashboard.montly.service.time.in.hours" }))
    montlyServiceTime.columns = [
      { header: intl.formatMessage({ id: "month" }), key: 'monthyear', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.service.time.average.in.hours" }), key: 'attendancetimeaveragedata', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.larger.service.time.in.hours" }), key: 'longerattendancetimedata', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.shorter.service.time.in.hours" }), key: 'shorterattendancetimedata', width: 25 }
    ]

    montlyServiceTime.getCell('A1').style = { font: { bold: true } }
    montlyServiceTime.getCell('B1').style = { font: { bold: true } }
    montlyServiceTime.getCell('C1').style = { font: { bold: true } }
    montlyServiceTime.getCell('D1').style = { font: { bold: true } }

    montlyServiceTime.addRows(this.state.attendanceTimeData);

    let montlySolutionTime = wb.addWorksheet(intl.formatMessage({ id: "productivity.dashboard.montly.solution.time.in.hours" }))
    montlySolutionTime.columns = [
      { header: intl.formatMessage({ id: "month" }), key: 'monthyear', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.solution.time.average.in.hours" }), key: 'solutiontimeaveragedata', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.larger.solution.time.in.hours" }), key: 'longersolutiontimedata', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.shorter.solution.time.in.hours" }), key: 'shortersolutiontimedata', width: 25 }
    ]

    montlySolutionTime.getCell('A1').style = { font: { bold: true } }
    montlySolutionTime.getCell('B1').style = { font: { bold: true } }
    montlySolutionTime.getCell('C1').style = { font: { bold: true } }
    montlySolutionTime.getCell('D1').style = { font: { bold: true } }

    montlySolutionTime.addRows(this.state.solutionTimeData);

    let technicalRank = wb.addWorksheet(intl.formatMessage({ id: "productivity.dashboard.technical.rank" }))
    technicalRank.columns = [
      { header: intl.formatMessage({ id: "name" }), key: 'name', width: 25 },
      { header: intl.formatMessage({ id: "email" }), key: 'email', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.technical.rank.called.count" }), key: 'calledcount', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.technical.rank.service.time.average" }), key: 'servicetimeaveragedata', width: 25 },
      { header: intl.formatMessage({ id: "productivity.dashboard.technical.rank.solution.time.average" }), key: 'solutiontimeaveragedata', width: 25 }
    ]

    technicalRank.getCell('A1').style = { font: { bold: true } }
    technicalRank.getCell('B1').style = { font: { bold: true } }
    technicalRank.getCell('C1').style = { font: { bold: true } }
    technicalRank.getCell('D1').style = { font: { bold: true } }
    technicalRank.getCell('E1').style = { font: { bold: true } }

    technicalRank.addRows(this.state.technicalRank);

    let buf = await wb.xlsx.writeBuffer()

    saveAs(new Blob([buf]), `${intl.formatMessage({ id: "dashboards.productivity.file" })}_${startDate}_${endDate}.xlsx`)
  }

  timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  async getPdf() {

    await this.setState({ loading: true })

    let windowSize = this.state.WindowSize

    await this.setState({ maxWidth: '500px', grid: 12, width: '500px', showChart: true })

    const header = document.getElementById('header-pdf');
    let headerCanvas = await html2canvas(header);
    const headerImg = headerCanvas.toDataURL('image/png');

    let imgData = null
    let canvas = null
    const page1 = document.getElementById('div-id-to-print');
    if(page1 != null)
      canvas = await html2canvas(page1);
    if(canvas != null)
      imgData = canvas.toDataURL('image/png');

    const chart = document.getElementById('chart-called');
    let canvasChart = await html2canvas(chart);
    const imgChart = canvasChart.toDataURL('image/png');

    const page2 = document.getElementById('page2');
    let canvasPage2 = await html2canvas(page2);
    const imgData2 = canvasPage2.toDataURL('image/png');






    const page31 = document.getElementById('page3-1');
    let canvaspage31 = await html2canvas(page31);
    const imgData31 = canvaspage31.toDataURL('image/png');

    const pdf = new jsPDF();
    pdf.addImage(headerImg, 'PNG', 0, 0);
    if(imgData != null)
      pdf.addImage(imgData, 'PNG', 0, 25);
    pdf.addPage();
    pdf.addImage(imgData2, 'PNG', 0, 0);
    pdf.addImage(imgChart, 'PNG', 0, 150)
    pdf.addPage();

    if (this.onlyAdmOrganization || this.isCoordenator || this.AdmOrganization || this.globalAdm) {
      const page3 = document.getElementById('page3');
      let canvaspage3 = await html2canvas(page3);
      const imgData3 = canvaspage3.toDataURL('image/png');
      pdf.addImage(imgData3, 'PNG', 0, 0);
    }

    pdf.addImage(imgData31, 'PNG', 0, 150);
    if (this.onlyAdmOrganization || this.AdmOrganization || this.globalAdm) {
      const pizza = document.getElementById('pizza');
      if(pizza != null){

        let canvaspizza = await html2canvas(pizza);
        const imgPizza = canvaspizza.toDataURL('image/png');
        pdf.addPage();
        pdf.addImage(imgPizza, 'PNG', 0, 0);

      }
      

    }

    pdf.save("download.pdf");
    //   html2canvas(input)
    // .then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save("download.pdf");
    // });

    await this.setState({ maxWidth: '100%', width: '100%', grid: 4, showChart: false, loading: false })

  }

  async getAllSelectionModel() {
    const result = await getAllSelectionModel();
    if (result.success) {
      this.setState({ technicalTeamList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizations(technicalTeam = '') {
    PapiroConsole.log("=== getOrganizations ===")
    PapiroConsole.log(technicalTeam)
    const result = await getAllSelectionModelByTechnicalTeam((technicalTeam == null || technicalTeam == '') ? 0 : technicalTeam);
    if (result.success) {
      this.setState({ organizationsList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getUsers(technicalTeam = '', organizationId = '') {
    PapiroConsole.log("=== getUsers ===")
    PapiroConsole.log(technicalTeam)
    PapiroConsole.log(organizationId)
    const result = await getAllUsersSelecionModelByRoleTypeAndOrganizationIdAndTechnicalTeamIdAndUserId(2, (technicalTeam == null || technicalTeam == '') ? 0 : technicalTeam, (organizationId == null || organizationId == '') ? 0 : organizationId, 0);
    if (result.success) {
      this.setState({ userList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getCalleds(organizationId = '') {

    let percentList = [];
    if (this.onlyAdmOrganization || this.isCoordenator || this.AdmOrganization || this.globalAdm) {

      const startState = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
      const endState = moment(this.state.dateEnd).format('YYYY-MM-DD 00:00:00')
      const startMonth = parseInt(moment(this.state.dateStart).format('MM')); 
      const endMonth = parseInt(moment(this.state.dateEnd).format('MM'));
      const timeInterval =  startMonth <= endMonth ? (endMonth - startMonth + 1) : (13 - startMonth + endMonth) 

      this.setState({ loadingSLA: true });
      for (let index = 0; index < timeInterval-1; index++) { /// qual o sentido disso????

        let start = "";
        let end = "";
        let month = 0;
        if (index === 0) {
          
			start = moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00')
			month = moment(this.state.dateStart).format('MM');
			end = moment(start).add(1, 'M').format('YYYY-MM-01 00:00:00');
			// Caso só seja um mês ou a data acima seja inválida, pois n pode conter
			// uma data maior que a selecionada
			if (timeInterval === 1 || end > endState) {
				end = endState;
			}
        }
		else {
          month = moment(this.state.dateStart).add(index, 'months').format('MM');
          start = moment(this.state.dateStart).add(index, 'months').format('YYYY-MM-01 00:00:00');
          end = moment(this.state.dateStart).add(index + 1, 'months').format('YYYY-MM-01 00:00:00');

          if (end > endState) {
            end = endState;
          }
        }

        if(organizationId == null || organizationId == ''){
          organizationId = 0;
        }
        await Api.get(`/calleds/dash?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`)
          .then(result => {
            if (result.data.success) {
              
              if (result.data.data.length > 0) {
                let total = 0;
                let doneService = 0;
                let doneSolution = 0;

                let percentService = 0;
                let percentSolution = 0;

                result.data.data.forEach((item) => {

                  if(item.calledcosts && item.calledcosts[0]){
                    console.log(item.calledcosts[0])
                    
                    total++;
                    if(!item.calledcosts[0].slaserviceoverflow){
                      doneService += 1
                    }
  
                    if(!item.calledcosts[0].slasolutionoverflow){
                      doneSolution += 1
                    }
                  }
                  
                  /*let service = getSlaAtendimento(item, this.props.userSession);

                  if (service > 0 && service < 100) {
                    doneService += 1
                  }
                  let solution = getSlaSolucao(item, this.props.userSession);

                  if (solution > 0 && solution < 100) {
                    doneSolution += 1
                  }*/
                })

                percentService = (doneService / total) * 100
                percentSolution = (doneSolution / total) * 100

                percentList.push({ percentService, percentSolution, doneService, doneSolution, total, month })
              } else {

                percentList.push({ percentService: 0, percentSolution: 0, doneService: 0, doneSolution: 0, total: 0, month: month })
              }
            }
            else {
              const intl = this.props.intl;
              percentList.push({ percentService: 0, percentSolution: 0, doneService: 0, doneSolution: 0, total: 0, month })
              this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
            }
          })
          .catch(err => {
            const intl = this.props.intl;
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          });
        this.setState({ percentSlas: percentList, showButtonPdf: true })
		//break; // adicionado pois se isso realmente é necessário, a lógica tem que ser mudada; Não se pode ter um FOR realizando requisições de relatório que podem ser bastante pesadas;
      }

      this.setState({ loadingSLA: false });

    }


    this.setState({ showButtonPdf: true })

  }

  async getCalledStatusTotal(organizationId = '') {

    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    let start = moment(this.state.dateStart).format('YYYY-MM-DD');
    let end = moment(this.state.dateEnd).format('YYYY-MM-DD');

    let url = `/dashboard/calledStatus/query?dateStart=${start}&dateEnd=${end}&organizationId=${organizationId}`;

    Api.get(url)
      .then(result => {
        if (result.data.success) {
          var listValues = [];
          var listNames = [];
          var listChart = [];
          var listColors = [];

          result.data.data.statuspercent.map(status => (
            listValues.push(status.total),
            listNames.push(status.statusname),
            listColors.push(status.statuscolorhex),
            listChart.push(status.chart)
          ))
          this.setState({
            calledStatusChart: listChart,
            calledStatus: result.data.data.statuspercent,
            dataStatus: listValues,
            dataStatusName: listNames,
            dataStatusColor: listColors
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    // this.setState({ loading:false })
  }

  async getClassificationTotal(organizationId = '') {

    let url = `/dashboard/classification`;

    if (organizationId != '' || organizationId != 0) {
      url += '/' + organizationId;
    }

    Api.get(url)
      .then(result => {

        if (result.data.success) {
          if (result.data.data.totalgeral === null) {
            result.data.data.totalgeral = 0;
          }
          this.setState({
            totalClassification: parseFloat(result.data.data.totalgeral.toFixed(2)),
            comparationClassification: result.data.data.totalmonthpass,
            positiveClassificationMonth: result.data.data.positivemonth
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });

    //this.setState({ loading: false })

  }

  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth * 0.9 })

  }

  async changeValues(stateName, value, text = '') {
    if (stateName === "organization") {
      this.setState({ organization: value, user: 0 });
      //await this.getUsers(this.state.technicalTeam && this.state.technicalTeam.id, value && value.id)
    } else if (stateName === "technicalTeam") {
      PapiroConsole.log("=== changeValues ===")
      PapiroConsole.log(stateName)
      PapiroConsole.log(value)
      this.setState({ technicalTeam: value, organization: 0, user: 0 });
      await this.getOrganizations(value && value.id)
      //await this.getUsers(value && value.id, this.state.organization && this.state.organization.id)
    } else if (stateName === "user") {
      this.setState({ user: value });
    } else {
      this.setState({ [stateName]: value });
    }


    if (stateName === "dateEnd") {
      // this.organizationId=value;
      console.log(value)
      console.log("value aqui")
      console.log(this.state.dateStart)
      if(value < this.state.dateStart){
        console.log("não é possivel ter uma data final menor que a data inicial")
      }
      // console.log("Organization id >>>" + value)

      //  await this.loadItems(value);
      // this.updateOrganization(this.organizationId);

    }
  };

  async loadItems(technicalTeam = '', organizationId = '') {
    this.setState({ loading: true })

    await this.getAllSelectionModel()
    await this.getOrganizations(technicalTeam); // **
    //await this.getUsers(technicalTeam, organizationId)
    //await this.getAttendanceTimeDashboardData(organizationId, technicalTeam, this.state.user && this.state.user.id)
    //await this.getSolutionTimeDashboardData(organizationId, technicalTeam, this.state.user && this.state.user.id)
    this.getTechnicalAttendanceRank(organizationId, technicalTeam, this.state.user && this.state.user.id)
	await this.getAttendanceAndSolutionTimeDashboardData(organizationId, technicalTeam, this.state.user && this.state.user.id)
    this.setState({ loading: false })
  }

  async search() {

    PapiroConsole.log("=== search ===")
    PapiroConsole.log(this.state.technicalTeam)
    PapiroConsole.log(this.state.organization)
    PapiroConsole.log(this.state.user)

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    console.log(dateStart)
    console.log(dateEnd)
    if(dateStart > dateEnd){
        
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:"Data inicial não pode ser maior que data final",
                loading :false
                });
   
    }
    else{
      console.log("entrei no else")
      await this.loadItems(this.state.technicalTeam && this.state.technicalTeam.id, this.state.organization && this.state.organization.id)
    }

  }

  getFavoritesAndCountByUserIdAndTableName = async _ => {

    const { userSession } = this.props
    let userId = userSession && userSession.user && userSession.user.id

    let result = await TableCustomFilterService.getFavoritesAndCountCalledByUserIdAndTableName(userId, "calleds")
    if(result && result.success) {
        if(result.data != null) {
            this.setState({
              favoriteFilters: result.data.favorites
            })
        }
    } else {
        const intl = this.props.intl
        this.setState({
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  goToCalledWithFilter = (tableCustomFilter) => {
    if(tableCustomFilter && tableCustomFilter.tablecustomfilterfields) {
      let field = ''
      let search = ''

      tableCustomFilter.tablecustomfilterfields.map(item => {
        field += `${item.field}@`
        search += `${item.value}@`
      })

      localStorage.setItem('newFilter', field);
      localStorage.setItem('newSearch', search);
      this.props.history.push(`/${this.props.match.params.lang}/calleds`);
    }
  }


  async getById(id) {
    
    let data = {}
    this.setState({ loading: true });

    Api.get(`/calleds/calledInfoPaginate/${id}`)
      .then(async result => {
        if (result.data.success) {

          let item = result.data.data

          let times = "";
            if (item.organizationcategory.techinicalteamorganizationcategories != null) {
              item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                  times = times + item2.techinicalteam.name + ","
              });
              times = times.substring(0, times.length - 1);
            }
            data = {
              ...item,
              statusName: item.status.name,
              OrganizationCategory: item.organizationcategory.parentname,
              Organization: item.organization.name,
              slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
              slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
              assignedUser: item.assigneduser ? item.assigneduser.name : "",
              requestingUser: item.requestinguser ? item.requestinguser.name : "",
              time: times,
              addressname: item.address.name,
              opening: moment(item.openingdate).format("DD/MM/YYYY"),
              classification: item.calledclassification
            }

            await this.props.addCurrentCalled(data);
            await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

            await this.props.addCalled(data);
            await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id);

            const urlRedirect = "/" + this.props.match.params.lang + `/called-accompaniment/${data.id}`;
            // window.open(urlRedirect, "_blank");
            // this.setState({ loading: false })
            await setTimeout(() => { 
              window.open(urlRedirect, "_blank");
              this.setState({ loading: false })
            }, 3000);

          } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : 'Erro ao processar a solicitação.'
            });
            Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
        });
        Api.kickoff(err);

      });
  };
  async componentDidMount() {
    await this.loadItems();
  }

  Reload = () => {
    const reload = localStorage.getItem('reload');
    if (reload == "true") {
      localStorage.setItem('reload', false);

      setTimeout(function () { document.location.reload(true); }, 500);
    }
    return 0;
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, userSession } = this.props;
    const intl = this.props.intl;
    const ref = React.createRef();

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const save = (organization) => {
      this.setState({ organization: organization });
    }

    const options = {
      orientation: 'landscape',
      unit: 'in',
      format: [4, 2]
    };

    const reset = () => {
    }

    const printDiv = (divName) => {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;

    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} style={{ minWidth: '300px' }} />
          <Grid container spacing={2}>
            <Grid id="header-pdf" item lg={12} sm={12} xl={12} xs={12} style={{ padding: '2px' }}>
              <Card>
                <CardContent>
                  {this.state.showChart && <img src={LogoMarca} style={{ width: 70 }} /> }
                  <span style={{ marginLeft: 25, fontSize: 18 }}>{ this.state.showChart && intl.formatMessage({ id: "productivity.dashboard.report.of" }) } <FormattedMessage id="productivity.dashboard.title" /></span>
                  { this.state.showChart && <span style={{ marginLeft: 25, fontSize: 18 }}><FormattedMessage id="from.capital" /> {moment(this.state.dateStart).format('DD/MM/YYYY')} <FormattedMessage id="to" /> {moment(this.state.dateEnd).format('DD/MM/YYYY')}</span>}
                  <Divider />
                </CardContent>
              </Card>
            </Grid>
            {(this.AdmOrganization || this.globalAdm) &&
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  {this.state.showButtonPdf && <Button variant='contained'
                    color='secondary'
                    style={{ marginBottom: "24px" }}
                    size='large' onClick={this.getExcel}>{<FormattedMessage id="export" />}</Button>}
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}></Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })}
                      options={this.state.technicalTeamList}
                      stateName="technicalTeam"
                      changeSelect={this.changeValues}
                       />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "productivity.dashboard.organizations" })}
                      options={this.state.organizationsList}
                      stateName="organization"
                      changeSelect={this.changeValues}
                       />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <SimpleAutoCompleteSearch
                      label={intl.formatMessage({ id: "productivity.dashboard.users" })}
                      options={this.state.userList}
                      stateName="user"
                      changeSelect={this.changeValues}
                      selected={this.state.user}
                      urlapi={Api.apiurlbase()+'api/users/selectionmodel/search/2/' + this.state.technicalTeam.id +'/' + this.state.organization.id}
                      disabled={!(this.state.technicalTeam && this.state.technicalTeam.id > 0 && this.state.organization && this.state.organization.id > 0)}
                       />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SimpleDatePicker
                      label={intl.formatMessage({ id:"start.date" })}
                      name='dateStart'
                      stateName='dateStart'
                      changeDate={this.changeValues}
                      currentDate={this.state.dateStart}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <SimpleDatePicker
                      label={intl.formatMessage({id:"end.date"})}
                      name='dateEnd'
                      stateName='dateEnd'
                      changeDate={this.changeValues}
                      currentDate={this.state.dateEnd}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <Button variant='contained'
                      color='secondary'
                      fullWidth
                      onClick={this.search}>
                      {<FormattedMessage id="search" />}
                    </Button>
                  </Grid>
              </Grid>
            </Grid>}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MontlyServiceTimeAverage data={this.state.attendanceTimeData} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MontlySolutionTimeAverage data={this.state.solutionTimeData} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TechnicalRank data={this.state.technicalRank} />
            </Grid>
          </Grid>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
           <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />

        </main>
        <Footer />

      </div>

    );
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chatHubConnection: state.chatHubConnection,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ProductivityDashboard)));
