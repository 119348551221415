import React, { Component } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import { Redirect } from 'react-router';
import SimpleSelect from '../../../components/select/simple';
import { QueuePlayNext } from "@mui/icons-material";
import * as Service from "../../../services/apiWebhook";
import MaterialTable from 'material-table';

class WebhookList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      redirect: false,
      EventList: [],
      item: [],
      itemData: [],
      status: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    
  }

  async componentDidMount() {
    await this.getEvents();
  };

  async getEvents() {
    this.setState({ loading: true });

    var result = await Service.getEvents();
    
    this.setState({ loading: false });

    if(result != null){
      if (result.data.success) {
        this.setState({ EventList: result.data.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
      }
    }
  }


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    var result = await Service.ApiGetWebhook("/webhooksubscriptions/byUserLicence/"+this.state.item['email']+"/"+this.state.item['token']);
    
    this.setState({ loading: false });

    if(result != null){
      if(result.data.success == true && result.data.success != null){
        this.setState({
          itemData: result.data.data.webhooksubscriptions,
          status: true
        });
      }else{
        const intl = this.props.intl;
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"subscription.not.found.email.token"}),
          status: false
        });
      }
    }

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    })); 
    this.setState({ loading: false });

  };
  
  deleteItem = async (oldData) => {
    
    this.setState({ loading: true });

    let data = new FormData();

    data.append("urlsubscription", this.state.item['url']);
    data.append("token", this.state.item['token']);
    data.append("email", this.state.item['email']);
    data.append("id", oldData.id);
    
    await Service.ApiPostWebhook(data, "/webhooksubscriptions/delete");
    
    var result = await Service.ApiGetWebhook("/webhooksubscriptions/byUserLicence/"+this.state.item['email']+"/"+this.state.item['token']);
    
    this.setState({ loading: false });

    if(result != null){
      if(result.data.success == true && result.data.success != null){
        this.setState({
          itemData: result.data.data.webhooksubscriptions,
          status: true
        });
      }else{
        const intl = this.props.intl;
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"subscription.not.found.email.token"}),
          status: false
        });
      }
    }

    this.setState({ loading: false });

  };

  render() {
    const intl = this.props.intl;
    const { classes } = this.props;

     return (
      <Container component='main' maxWidth='sm'>
        <div style={{textAlign: "center"}}>
          <Avatar style={{marginLeft: "48%"}} className={classes.avatar}>
            <QueuePlayNext />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {intl.formatMessage({id:"list.subscription"})}
          </Typography>
        </div>
        <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
          
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label={this.props.intl.formatMessage({id:"email"})} autoComplete='fname' variant='outlined' name='email' onChange={this.handleInputChange} required />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="Token" autoComplete='fname' variant='outlined' name='token' onChange={this.handleInputChange} required />
            </Grid>
          </Grid>
          

          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                  {intl.formatMessage({id:"search"})}
              </Button>
              </Grid>
          </Grid>
        </form>

        {this.state.status && (
          <Grid container spacing={0} >
            <Grid item xs={12} sm={12} style={{ display: 'table', tableLayout: 'fixed', width: '100%', minWidth: '210px', paddingLeft: 0, paddingRight: 0 }}>
              <MaterialTable
                title={intl.formatMessage({id:"webhook.subscriptions.list"})}
                columns={[
                  { title: intl.formatMessage({id:"url"}), field: 'urlsubscription' },
                  { title: intl.formatMessage({id:"event"}), field: 'webhookevent.name' },
                ]}
                data={this.state.itemData}
                options={{
                    pageSize:10
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: intl.formatMessage({id:"no.data.to.display"}),
                        deleteText: intl.formatMessage({id:"confirm.delete.line"}),
                        emptyDataSourceMessage: intl.formatMessage({id:"no.data.to.display"}),
                        addTooltip: intl.formatMessage({id:"new"}),
                        deleteTooltip: intl.formatMessage({id:"webhook.delete"}),
                        editTooltip: intl.formatMessage({id:"edit"}),
                        filterRow: {
                            filterPlaceHolder: intl.formatMessage({id:"filter"}),
                            filterTooltip: intl.formatMessage({id:"filter"}),
                        },
                        editRow: {
                            deleteText: intl.formatMessage({id:"confirm.delete"}),
                            cancelTooltip: this.props.intl.formatMessage({id:"cancel"}),
                            saveTooltip: this.props.intl.formatMessage({id:"save"}),
                        },
                    },
                    grouping: {
                        placeholder: intl.formatMessage({id:"drag.title.to.group.lines"}),
                        groupedBy: intl.formatMessage({id:"group.by"}),
                    },
                    header: {
                        actions: intl.formatMessage({id:"actions"}),
                    },
                    pagination: {
                        labelDisplayedRows: intl.formatMessage({id:"count.from.to"}),
                        labelRowsSelect: intl.formatMessage({id:"lines"}),
                        labelRowsPerPage: intl.formatMessage({id:"lines.per.page"}),
                        firstAriaLabel: intl.formatMessage({id:"first.page"}),
                        firstTooltip: intl.formatMessage({id:"first.page"}),
                        previousAriaLabel: intl.formatMessage({id:"previous.page"}),
                        previousTooltip: intl.formatMessage({id:"previous.page"}),
                        nextAriaLabel: intl.formatMessage({id:"next.page"}),
                        nextTooltip: intl.formatMessage({id:"next.page"}),
                        lastAriaLabel: intl.formatMessage({id:"last.page"}),
                        lastTooltip: intl.formatMessage({id:"last.page"}),
                    },
                    toolbar: {
                        addRemoveColumns: intl.formatMessage({id:"add.remove"}),
                        nRowsSelected: intl.formatMessage({id:"n.lines.selected"}),
                        showColumnsTitle: intl.formatMessage({id:"show.columns"}),
                        showColumnsAriaLabel: intl.formatMessage({id:"show.columns"}),
                        exportTitle: intl.formatMessage({id:"export"}),
                        exportAriaLabel: intl.formatMessage({id:"export"}),
                        exportName: intl.formatMessage({id:"export.csv"}),
                        searchTooltip: intl.formatMessage({id:"search"}),
                        searchPlaceholder: intl.formatMessage({id:"search.here"}),
                    },
                }}
                editable={{
                    onRowDelete: oldData => this.deleteItem(oldData)
                }}
              />
            </Grid>
          </Grid>
        )}
        

        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />

      </Container>
    );
  }
}

export default withStyles(Styles)(WebhookList);
