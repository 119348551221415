import React, { Component } from 'react';
import { Typography, Box, Divider, Grid } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import { CircularProgress } from '@mui/material';
import { Overlay } from '../../../../styles/global';

class ChatIndicators extends Component {




  render() {
    const { loadingIndicators, countNewChats, countInAttendanceChats, countCanceledChats, countFinishChats, countChatsNotResolvedInTheFirstService, countChatsResolvedInTheFirstService } = this.props;

    PapiroConsole.log("loadingIndicators")
    PapiroConsole.log(loadingIndicators)
    PapiroConsole.log(this.props)
    PapiroConsole.log("this.props indicadores")
    return (
      <div style={{ padding: '20px' }}>
        <Box>
          <Typography variant="h5" color="primary" style={{ marginBottom: '10px' }}>
            Principais Indicadores
          </Typography>
          <Divider />
        </Box>
        {loadingIndicators == false ?
          <>

            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <MarkChatReadIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats finalizados
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que foram concluidos até o momento.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countFinishChats}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <MarkUnreadChatAltIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats aguardando atendimento
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que aguardam atendimento.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countNewChats}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <ChatIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats em atendimento
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que estão em atendimento.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countInAttendanceChats}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <SpeakerNotesOffIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats cancelados
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que foram cancelados.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countCanceledChats}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <QuickreplyIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats resolvidos em primeiro atendimento
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que foram resolvidos em primeiro atendimento.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countChatsResolvedInTheFirstService}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2} alignItems="center">
              <Grid item>
                <FeedbackIcon color="primary" style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Chats não resolvidos em primeiro atendimento
                </Typography>
                <Typography variant="body1" style={{ color: '#7f8c8d' }}>
                  Quantidade total de chats que não foram resolvidos em primeiro atendimento.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {countChatsNotResolvedInTheFirstService}
                </Typography>
              </Grid>
            </Grid>
          </>
          :

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{  marginTop: '25px' }}

          >
            <CircularProgress />
          </Box>
        }

      </div>
    );
  }
}

export default ChatIndicators;
