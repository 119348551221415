import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/units/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/units/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}


export const getAll = async () => { 
  let data = []
  await Api.get(`/units/all`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllWithoutIncludes = async () => { 
  let data = []

  await Api.get(`/units/all/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })

  return data
}

export const getAllWithoutIncludesAnonymous = async () => { 
  let data = []

  await Api.get(`/units/all/withoutincludes/anonymous`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
    
  return data
}

export const paginate = async (page, search, field) => { 
  let data = []

  let url = `/units/paginate?page=${page}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/units`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/units`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/units/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}