export default function calledreportconfigs(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
        return {
            ...state,
            calledreportconfig: action.calledreportconfig,
        }
      default:
          return state;
  }
}