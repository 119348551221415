import React, { Component, Fragment } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { FormattedMessage, injectIntl } from 'react-intl';
import PasswordPolicy from '../../components/passwordPolicy/index';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      disabledButtons: true,
      loading: false,
      emailExpired: null,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      disabledResendButton: false,
      userLogged: false,
      configPasswordPolicy: null
    };

    this.ButtonExpired = null

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    console.log(this.props.match.params.token)
    console.log("entrei aqui no param")
    console.log(this.props.location.search)
    console.log("this.props.location.search")
    if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    } else if (this.props.userSession && this.props.userSession.user) {
      const email = this.props.userSession.user.email;
      this.setState(prevState => ({
        userLogged: true,
        disabledButtons: false,
        passwordResetForm: {
          ...prevState.passwordResetForm,
          email
        }
      }));
    }
  }

  getUser(token) {

    const intl = this.props.intl;
    this.setState({ loading: true });

    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };

    if (this.props.location.search && this.props.location.search.length > 20) {
      config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
    }


    Api.get(`/users/password-recovery/${token}`, config)
      .then(result => {
        if (result.data.success) {
          const { email, configpasswordpolicy } = result.data.data;
          
          this.setState(prevState => ({
            loading: false,
            disabledButtons: false,
            passwordResetForm: {
              ...prevState.passwordResetForm,
              email
            },
            configPasswordPolicy: configpasswordpolicy
          }));
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: this.props.intl.formatMessage({ id: "password.reset.invalid.token" })
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });

        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {

          if (err.response.data.errors[0] == this.props.intl.formatMessage({ id: "password.reset.expired.link.resend" })) {

            this.addButtonExpiredToken()
          }
        }
      });
  };

  resendEmail() {
    const intl = this.props.intl;

    console.log(this.state)

    this.setState({ loading: true });
    let data = new FormData();
    data.append("email", this.state.emailExpired);


    Api.post("/users/resendExpiredEmailPassword", data)
      .then(result => {
        if (result.data.success) {
          this.setState((prevState) => ({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: this.props.intl.formatMessage({ id: "email.sent" }),
            disabledResendButton: true

          }));
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          disabledResendButton: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      });

  }


  addButtonExpiredToken() {

    const intl = this.props.intl;

    this.setState({ loading: true });
    Api.get(`/users/get-user-by-token/${this.props.match.params.token}`)
      .then((result) => {
        console.log(result)
        if (result.data.success) {

          this.setState({
            emailExpired: result.data.data.email,
            passwordResetForm: {
              email: result.data.data.email,
            },
          });

        }
      })
      .catch((err) => {

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({ id: "process.error" }),
        });

      });

    const { classes } = this.props;
    this.ButtonExpired =
      <div className={classes.form} style={{ marginTop: '0px' }}  >
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.resendEmail}

        >
          {intl.formatMessage({ id: "resend.new.link" })}
        </Button>
      </div>

    this.setState({ buttonRender: true, loading: false });



  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {

    const intl = this.props.intl;

    e.preventDefault();

    console.log("entrei aqui")
    if (this.validatePassword()) {
      this.setState({ loading: true });
      const { email, password } = this.state.passwordResetForm;
      const ConfirmationGuid = this.props.match.params.token;
      if (this.state.userLogged == true && (this.props.match.params.token == undefined || this.props.match.params.token == null)) {

        Api.post("/users/change-password-auth", { email, password, ConfirmationGuid })
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: this.props.intl.formatMessage({ id: "password.reset.password.registered" })
              });
              setTimeout(() => {
                this.props.history.push("/");
              }, 3000);
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
          });

      }
      else {

        let config = {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        };

        config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;

        Api.post("/users/change-password", { email, password, ConfirmationGuid }, config)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: this.props.intl.formatMessage({ id: "password.reset.password.registered" })
              });
              setTimeout(() => {
                this.props.history.push("/");
              }, 3000);
            } else {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: this.props.intl.formatMessage({ id: "password.reset.invalid.token" })
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
          });

      }



    }
  };

  validatePassword() {
    const { passwordResetForm } = this.state;
    if (passwordResetForm.password !== passwordResetForm.confirmPassword) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({ id: "password.reset.passwords.dont.match" })
      });
      return false;
    } else if (this.state.configPasswordPolicy != null && document.querySelector('[data-testid="CloseIcon"]')) {
      
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: this.props.intl.formatMessage({id:"passwordpolicy.errorPolicy"}),
      });
      return false;
      
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { passwordResetForm, userLogged } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const containerBody = () => {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}> <CompareArrowsIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Redefina sua senha </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={this.props.intl.formatMessage({ id: "email" })}
                    name="email"
                    autoComplete="email"
                    value={passwordResetForm.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    disabled={this.state.disabledButtons}
                    required
                    fullWidth
                    name="password"
                    label={this.props.intl.formatMessage({ id: "password" })}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={passwordResetForm.password}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={this.props.intl.formatMessage({ id: "confirm.password" })}
                    type="password"
                    id="confirmPassword"
                    disabled={this.state.disabledButtons}
                    autoComplete="current-confirmPassword"
                    value={passwordResetForm.confirmPassword}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordPolicy
                    password={passwordResetForm.password}
                    configPasswordPolicy={this.state.configPasswordPolicy}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={12} >
                      <Button
                        style={{ marginBottom: '8px' }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={this.state.disabledButtons} >
                        {this.props.intl.formatMessage({ id: "data.register" })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>





            </form>
            {!this.state.disabledResendButton && (

              <>
                {this.ButtonExpired}
              </>

            )}

            {!userLogged && (
              <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: '2px' }}>
                <Grid item>
                  <Link href="/" variant="body2"> {this.props.intl.formatMessage({ id: "password.reset.has.account.login" })} </Link>
                </Grid>
              </Grid>
            )}
          </div>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      );
    };

    if (userLogged) {
      return (
        <div className={classes.root}>
          <HeaderMenu />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: headerMenu.open,
            }, classes.actionsContent, {
              [classes.actionscontentShift]: actionsHeaderMenu.open,
            })}
          >
            <div className={classes.drawerHeader} />

            {containerBody()}
          </main>
        </div>
      );
    } else {
      return (
        <Fragment>
          {containerBody()}
        </Fragment>
      );
    }
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(PasswordReset)));
