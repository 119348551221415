import Api2Talk from "../api2talk";

const PATH = '/quickresponses';

const removeAttachment = async (attachmentId) => { 
  try {
    const response = await Api2Talk.delete(`${PATH}/remove-attachment/${attachmentId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getQuickResponseHighlight = async () => { 
  try {
    const response = await Api2Talk.get(`${PATH}/get-quick-response-highlight`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllQuickResponse = async () => { 
  try {
    const response = await Api2Talk.get(`${PATH}/get-all-quick-response`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export {
  getAllQuickResponse, getQuickResponseHighlight, removeAttachment
};

