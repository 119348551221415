export default function types(state = [], action) {
    switch (action._type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                type: action.type,
            }
        default:
            return state;
    }
}
