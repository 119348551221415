import React from 'react';
import InternetIcon from '../../images/me-feedback/site_icon.png';

const MEInternetIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : InternetIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackInternetIcon"
            className='App-Internet-icon' />
    </div>
);

export default MEInternetIcon;
