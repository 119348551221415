export default function organizationchecklists(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              organizationchecklist: action.organizationchecklist,
          }
      case 'ADD_CURRENT_ORGANIZATION':
          return {
              ...state,
              organizationchecklist: action.organizationchecklist,
              organization: action.organization,

          }

      default:
          return state;
  }
}