export default function npssearchs(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                npssearch: action.npssearch,
            }
        default:
            return state;
    }
}
