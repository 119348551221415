export function addCurrent(organizationpatrimony) {
    return {
        type: 'ADD_CURRENT',
        organizationpatrimony,
    }
}

export function addCurrentOrganization(organizationpatrimony,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationpatrimony,
        organization,
    }
}