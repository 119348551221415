export default function calledservicesettingsorganizationfieldvalidations(state = [], action) {
  switch (action.type) {
    case 'ADD_CALLED_SERVICE_SETINGS_ORGANIZATION_FIELD_VALIDATION':
      return {
        ...state,
        calledservicesettingsorganizationfieldvalidation: action.calledservicesettingsorganizationfieldvalidation
      }
    default:
      return state;
  }
}