import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import './styles.css'
import { Badge, Button, Grid, IconButton } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import StopIcon from '@mui/icons-material/Stop'
import ReplyIcon from '@mui/icons-material/Reply'
import SimpleAutoCompleteSearch from '../../../../components/auto-complete/autocomplete-search-api';
import Api from "../../../../services/api";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleSelectWithRoundedColor from './simple-with-rounded-color'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { env } from '../../../../env'
import { getProviderList as getProviderListService } from '../../../../services/2talk/omnichannel'
import { PapiroConsole } from '../../../../utils/papiroConsole'

const CustomTabs = withStyles((theme) => ({
  root: {
    borderBottom: 'none',
  },
  indicator: {
    display: 'none',
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const CustomTab = withStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: 'rgba(86, 103, 196, .3)',
      color: theme.palette.common.white,
    },
    marginRight: '5px!important',
    marginLeft: '5px!important'
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabWithBadge = ({ label, badgeContent, ...rest }) => (
  <CustomTab label={
    <Box display="flex" alignItems="center">
      <span style={{ marginRight: badgeContent > 0 ? 15 : 0 }}>{label}</span>
      {badgeContent > 0 && (
        <Badge badgeContent={badgeContent} color="secondary">
          
        </Badge>
      )}
    </Box>
  } {...rest} />
);

function a11yProps(index) {
  PapiroConsole.log("=== a11yProps ===")
  PapiroConsole.log(index)
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ChatChannelsTabs(props) {
  const messageProviders = env.REACT_APP_2TALK_MESSAGE_PROVIDER_FILTERS;
  const messageProviderIDs = messageProviders ? messageProviders.split(',').map(id => parseInt(id, 10)) : []

  const [value, setValue] = React.useState(0);
  const [allProviderList, setAllProviderList] = React.useState([])
  const [providerList, setProviderList] = React.useState([])

  React.useEffect(() => {
    const getProviderList = async _ => {
      let result = await getProviderListService()
      if (result && result.success) {
        if (result.data) {
          PapiroConsole.log("=== ProviderList ===")
          PapiroConsole.log(result.data)
          setAllProviderList(result.data)
          filterProviderList(result.data)
        }
      } else
        Api.kickoff(result)
    }
    getProviderList()
  }, [])

  const filterProviderList = (providerList) => {
    const messageProviderList = providerList.filter(objeto => messageProviderIDs.includes(objeto.id))
    if (messageProviderList)
      setProviderList(messageProviderList)
  }

  const handleChange = (event, newValue) => {
    PapiroConsole.log("=== handleChange ===")
    PapiroConsole.log(newValue)
    props.filterChatsByMessageProvider(newValue)
    setValue(newValue);
  }

  const {intl} =  props;
  return (<div className="omnichannel-chat-channels-tabs" style={{width:'100%'}}>
    <Box>
      <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <TabWithBadge label="Todas" key={0} {...a11yProps(0)} value={0} badgeContent={0} />
        {providerList.map(provider => {
          let providerId = provider.id
          let content = props.chats.omnichannelchats && props.chats.omnichannelchats.length > 0 ? props.chats.omnichannelchats.filter(chat => chat.messageproviderid == provider.id) : []
          let totalContent = content.length
          return (<TabWithBadge key={providerId} label={provider.name} {...a11yProps(providerId)} value={providerId} badgeContent={totalContent} />)
        })}
      </CustomTabs>
    </Box>
  </div>)
}

ChatChannelsTabs.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatChannelsTabs)))