import Api from './api';
export const getUsers = async () => { 
  let data = [];
  await Api.get('/users')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getCalleds = async () => { 
  let data = [];
  await Api.get('/calleds')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getByCalled = async (calledId) => { 
  let data = [];
  await Api.get('/calledvideocalls/called/'+calledId)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getByUser = async (userId) => { 
  let data = [];
  await Api.get('/calledvideocalls/user/'+userId)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};


