import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, FormControl, FormLabel, FormControlLabel, FormGroup, Switch, Tooltip, IconButton, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions, Box  } from '@mui/material'
import { Add, Clear, Delete, Edit, QueuePlayNext } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import * as Service from '../../services/calledservicesettings.service'
import * as CalledServiceSettingsOrganizationFieldService from '../../services/calledservicesettingsorganizationfield.service'
import * as CalledServiceSettingsCalledFieldService from '../../services/calledservicesettingscalledfield.service'
import * as CalledServiceSettingsFileUploadService from '../../services/calledservicesettingsfileupload.service'
import * as CalledServiceSettingsValidationService from '../../services/calledservicesettingsvalidation.service'
import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import { getAllSelectionModel } from '../../services/organization.service'
import { getAllSelectionModelCanCreateCalledByOrganizationId } from '../../services/organizationcategory.service'
import { getAllSelectionModelByOrganizationIdAndActiveAndtarget } from '../../services/organizationfield.service'
import { getAllSelectionModel as getAllTypesSelectionModel } from '../../services/type.service'
import { getAllSelectionModel as getAllOriginRequestsSelectionModel } from '../../services/originrequest.service'
import { getAllSelectionModel as getAllStatusSelectionModel } from '../../services/status.service'
import { getAllSelectionModel as getAllPrioritySelectionModel } from '../../services/priority.service'
import HelpIcon from '@mui/icons-material/Help'
import 'react-quill/dist/quill.snow.css' // ES6
import ReactQuill from 'react-quill' // ES6
import { PapiroConsole } from '../../utils/papiroConsole'
import { bindActionCreators } from 'redux'
import * as calledServiceSettingsActions from '../../redux/actions/calledservicesettings'
import * as calledServiceSettingsOrganizationFieldsActions from '../../redux/actions/calledservicesettingsorganizationfields'
import * as CalledServiceSettingsCalledFieldsActions from '../../redux/actions/calledservicesettingscalledfields'
import * as calledServiceSettingsFileUploadsActions from '../../redux/actions/calledservicesettingsfileuploads'
import * as calledServiceSettingsValidationsActions from '../../redux/actions/calledservicesettingsvalidations'
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs'
import CalledservicesettingsOrganizationfield from './components/calledservicesettings-organizationfield'
import CalledservicesettingsValidation from './components/calledservicesettings-validation'
import CalledServiceSettingsFileUploads from './components/calledservicesettings-fileupload'
import { DeleteForever } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { validateFyleTypeByTypeList } from '../../utils/fileUtils'
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid'
import DialogConfirmation from '../../components/dialog-confirmation'
import AddCalledServiceSettingsCalledFieldItemDialog from './components/calledservicesettings-calledfield/addItemDialog'
import UpdateCalledServiceSettingsCalledFieldItemDialog from './components/calledservicesettings-calledfield/updateItemDialog'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LanguageIcon from '@mui/icons-material/Language';

const colors = [
  '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
  '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
  '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
  '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
  '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
]

const reactQuillModules = {
  toolbar: [
      [{ 'header': [1, 2, false] }],
      [{ 'size': ['small', false, 'large']  }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': colors }, { 'background': colors }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
  ],
}

const reactQuillFormats = [
  'header',
  'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'color', 'background',
  'list', 'bullet', 'indent',
  'link', 'image'
]

class CalledServiceSettings extends Component {

  constructor(props) {
    super(props)
    const { userSession, calledservicesettings } = this.props
    let frontCalledServiceUrl = Api.frontCalledServiceUrl()
    let l = this.props.match.params.lang

    PapiroConsole.log("=== CalledServiceSettings ===")
    PapiroConsole.log(this.props)

    this.globalAdm = false

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }

    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.calledFieldTitleItem = { id: 1, name: "Título do chamado" }
    this.calledFieldDescriptionItem = { id: 2, name: "Descrição do chamado" }
    this.calledFieldCategoryItem = { id: 3, name: "Categoria do chamado" }
    this.calledFieldUserNameItem = { id: 4, name: "Nome do usuário" }
    this.calledFieldUserEmailItem = { id: 5, name: "Email do usuário" }
    this.calledFieldUserPhoneItem = { id: 6, name: "Telefone do usuário" }
    this.calledFieldUserDocumentItem = { id: 7, name: "Documento do usuário" }

    this.fieldValueByFormCalledTitle = false
    this.fieldValueByFormCalledDescription = false
    this.fieldValueByFormOrganizationCategory = false

    this.calledfieldsList = []

    if (calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.calledservicesettingscalledfields && calledservicesettings.calledservicesettings.calledservicesettingscalledfields.length > 0) {
      let issetFieldTitle = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 1)
      if (issetFieldTitle == null || typeof issetFieldTitle === 'undefined') {
        this.calledfieldsList.push(this.calledFieldTitleItem)
      } else {
        this.fieldValueByFormCalledTitle = true
      }
      let issetFieldDescription = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 2)
      if (issetFieldDescription == null || typeof issetFieldDescription === 'undefined') {
        this.calledfieldsList.push(this.calledFieldDescriptionItem)
      } else {
        this.fieldValueByFormCalledDescription = true
      }
      let issetFieldCategory = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 3)
        if (issetFieldCategory == null || typeof issetFieldCategory === 'undefined') {
          this.calledfieldsList.push(this.calledFieldCategoryItem)
        } else {
          this.fieldValueByFormOrganizationCategory = true
        }
      let issetFieldUserName = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 4)
        if (issetFieldUserName == null || typeof issetFieldUserName === 'undefined')
          this.calledfieldsList.push(this.calledFieldUserNameItem)
      let issetFieldUserEmail = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 5)
        if (issetFieldUserEmail == null || typeof issetFieldUserEmail === 'undefined')
          this.calledfieldsList.push(this.calledFieldUserEmailItem)
      let issetFieldUserPhone = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 6)
        if (issetFieldUserPhone == null || typeof issetFieldUserPhone === 'undefined')
          this.calledfieldsList.push(this.calledFieldUserPhoneItem)
      let issetFieldUserDocument = calledservicesettings.calledservicesettings.calledservicesettingscalledfields.find(element => element.fieldtype == 7)
        if (issetFieldUserDocument == null || typeof issetFieldUserDocument === 'undefined')
          this.calledfieldsList.push(this.calledFieldUserDocumentItem)
    } else {
      this.calledfieldsList = [
        this.calledFieldTitleItem,
        this.calledFieldDescriptionItem,
        this.calledFieldCategoryItem,
        this.calledFieldUserNameItem,
        this.calledFieldUserEmailItem,
        this.calledFieldUserPhoneItem,
        this.calledFieldUserDocumentItem
      ]
    }

    let itemId = calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.id ? calledservicesettings.calledservicesettings.id : 0

    this.initialState = {
      id: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.id ? calledservicesettings.calledservicesettings.id : 0,
      calledTitle: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.calledtitle ? calledservicesettings.calledservicesettings.calledtitle : '',
      backButtonUrl: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.backbuttonurl ? calledservicesettings.calledservicesettings.backbuttonurl : '',
      routeText: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.RouteText && calledservicesettings.calledservicesettings.RouteText.length > 0 ? calledservicesettings.calledservicesettings.RouteText : itemId > 0 ? `${frontCalledServiceUrl}${l}/form/${itemId}` : '',
      title: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.title ? calledservicesettings.calledservicesettings.title : '',
      updatedUser: userSession.user.id,
      hirerid: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.hirerid ? calledservicesettings.calledservicesettings.hirerid : userSession.user.hirerid,
      updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.organizationid ? calledservicesettings.calledservicesettings.organizationid : 0,
      organizationCategoryId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.organizationcategoryid ? calledservicesettings.calledservicesettings.organizationcategoryid : 0,
      typeId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.typeid ? calledservicesettings.calledservicesettings.typeid : 0,
      originRequestId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.originrequestid ? calledservicesettings.calledservicesettings.originrequestid : 0,
      statusId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.statusid ? calledservicesettings.calledservicesettings.statusid : 0,
      priorityId: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.priorityid ? calledservicesettings.calledservicesettings.priorityid : 0,
      enablecaptcha: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.enablecaptcha ? calledservicesettings.calledservicesettings.enablecaptcha != null : false,
      turnonfacelivenessvalidation: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.turnonfacelivenessvalidation ? calledservicesettings.calledservicesettings.turnonfacelivenessvalidation != null : false,
      validationofexistingcalleds:  calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.validationofexistingcalleds ? calledservicesettings.calledservicesettings.validationofexistingcalleds != null : false,
      emailvalidationofexistingcalled: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.emailvalidationofexistingcalled != null ? calledservicesettings.calledservicesettings.emailvalidationofexistingcalled : true,
      documentvalidationofexistingcalled: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.documentvalidationofexistingcalled != null ? calledservicesettings.calledservicesettings.documentvalidationofexistingcalled : false,
      validationofexistingcalledmessage: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.validationofexistingcalledmessage ? calledservicesettings.calledservicesettings.validationofexistingcalledmessage : '',
      system: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.system != null ? calledservicesettings.calledservicesettings.system : false,
      istitlefieldprovidedbyuser: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.istitlefieldprovidedbyuser != null ? calledservicesettings.calledservicesettings.istitlefieldprovidedbyuser : false,
      iscategoryfieldprovidedbyuser: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.iscategoryfieldprovidedbyuser != null ? calledservicesettings.calledservicesettings.iscategoryfieldprovidedbyuser : false,
      fieldValueByFormCalledTitle: this.fieldValueByFormCalledTitle,
      fieldValueByFormCalledDescription: this.fieldValueByFormCalledDescription,
      fieldValueByFormOrganizationCategory: this.fieldValueByFormOrganizationCategory,
      footermessage: calledservicesettings && calledservicesettings.calledservicesettings && calledservicesettings.calledservicesettings.footermessage ? calledservicesettings.calledservicesettings.footermessage : "",
    }

    

    this.state = {
      item: this.initialState,
      rTabIndex: 0,
      OrganizationList: [],
      OrganizationCategoryList: [],
      SubjectList: [],
      ReasonList: [],
      ServiceList: [],
      TypeList: [],
      OriginRequestList: [],
      StatusList: [],
      PriorityList: [],
      pepListFile: null,
      blackListFile: null,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      calledfieldsList: this.calledfieldsList,
      calledServiceSettingsCalledFieldList: [],
      calledServiceSettingsCalledFieldListForDelete: [],
      calledServiceSettingsCalledFieldInsertDialogOpen: false,
      calledServiceSettingsCalledFieldUpdateDialogOpen: false,
      createCalledServiceSettingsItemDialogOpen: false,
      itemEditData: null,
    }

    this.setLoading = this.setLoading.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.setRTabIndex = this.setRTabIndex.bind(this)
    this.getAllOrganizations = this.getAllOrganizations.bind(this)
    this.getAllTypes = this.getAllTypes.bind(this)
    this.getAllOriginRequests = this.getAllOriginRequests.bind(this)
    this.getAllStatus = this.getAllStatus.bind(this)
    this.getAllPriority = this.getAllPriority.bind(this)
    this.getAllOrganizationCategoriesByOrganizationId = this.getAllOrganizationCategoriesByOrganizationId.bind(this)
    this.getAllOrganizationFieldByOrganizationId = this.getAllOrganizationFieldByOrganizationId.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.getAllCalledServiceSettingsOrganizationFields = this.getAllCalledServiceSettingsOrganizationFields.bind(this)
    this.setCalledServiceSettingsCalledFieldDelete = this.setCalledServiceSettingsCalledFieldDelete.bind(this)
    this.handleOpenCalledServiceSettingsCalledFieldInsertModal = this.handleOpenCalledServiceSettingsCalledFieldInsertModal.bind(this)
    this.handleOpenCalledServiceSettingsCalledFieldUpdateModal = this.handleOpenCalledServiceSettingsCalledFieldUpdateModal.bind(this)
    this.calledServiceSettingsCalledFieldDeleteModal = this.calledServiceSettingsCalledFieldDeleteModal.bind(this)
    this.handleOpenCreateCalledServiceSettingsItemModal = this.handleOpenCreateCalledServiceSettingsItemModal.bind(this)
    this.handleCloseCreateCalledServiceSettingsItemModal = this.handleCloseCreateCalledServiceSettingsItemModal.bind(this)
    this.handleSetFieldTypeListOptions = this.handleSetFieldTypeListOptions.bind(this)
    this.editItem = this.editItem.bind(this)
    this.handleRedirectLink = this.handleRedirectLink.bind(this)
    this.handleCopyLink = this.handleCopyLink.bind(this)
  }

  async componentDidMount() {
    await this.getAllCalledServiceSettingsOrganizationFields(this.state.item.id)
    await this.getAllCalledServiceSettingsCalledFields(this.state.item.id)
    await this.getAllCalledServiceSettingsFileUploads(this.state.item.id)
    await this.getAllCalledServiceSettingsValidations(this.state.item.id)
    await this.getAllTypes()
    await this.getAllOriginRequests()
    await this.getAllStatus()
    await this.getAllPriority()
    await this.getAllOrganizations()
  }

  componentWillReceiveProps(nextProps) {
    PapiroConsole.log("=== componentWillReceiveProps ===")
    PapiroConsole.log(nextProps)
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    } else if (nextProps.calledservicesettingscalledfields != null && this.props.calledservicesettingscalledfields) {
      if (nextProps.calledservicesettingscalledfields && nextProps.calledservicesettingscalledfields.calledservicesettingscalledfieldslist && nextProps.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.length > 0) {
        this.setState({ calledServiceSettingsCalledFieldList: nextProps.calledservicesettingscalledfields.calledservicesettingscalledfieldslist})
        this.handleSetFieldTypeListOptions(nextProps.calledservicesettingscalledfields.calledservicesettingscalledfieldslist)
      }
    }
  }

  setCalledServiceSettingsCalledFieldDelete = calledServiceSettingsCalledField => this.setState({ calledServiceSettingsCalledFieldListForDelete: calledServiceSettingsCalledField })

  handleOpenCalledServiceSettingsCalledFieldInsertModal = isOpen => {
    if (this.state.item.id == 0 && isOpen) {
      this.setState({ createCalledServiceSettingsItemDialogOpen: true })
    } else
      this.setState({ calledServiceSettingsCalledFieldInsertDialogOpen: isOpen })
  }

  handleOpenCalledServiceSettingsCalledFieldUpdateModal = isOpen => {
    this.setState({ calledServiceSettingsCalledFieldUpdateDialogOpen: isOpen })
    if (!isOpen)
      this.setState({ itemEditData: null })
  }

  handleOpenCreateCalledServiceSettingsItemModal = isOpen => this.setState({ createCalledServiceSettingsItemDialogOpen: isOpen })

  handleCloseCreateCalledServiceSettingsItemModal = _ => this.setState({ createCalledServiceSettingsItemDialogOpen: false })

  calledServiceSettingsCalledFieldDeleteModal = async _ => {
    const { intl } = this.props
    let idList = ""
    this.state.calledServiceSettingsCalledFieldListForDelete.map(id => {
      idList += `${id},`
    })

    if (idList.length > 0) {
      this.setLoading(true)
      let response = await CalledServiceSettingsCalledFieldService.deleteItems(idList)
      if (response && response.success) {
        let notIdList = this.props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.filter(item => !this.state.calledServiceSettingsCalledFieldListForDelete.includes(item.id))
        if (notIdList != null) {
          let loopOrder = 0
          let notIdListOrdened = notIdList.sort((a, b) => a.id - b.id)
          let notIdListFinal = notIdListOrdened.map(item => {
            return {
              ...item,
              order: ++loopOrder
            }
          })
          this.props.addCalledServiceSettingsCalledFieldsList(notIdListFinal)
          this.setState({ calledServiceSettingsCalledFieldList: notIdListFinal })
        } else {
          this.props.addCalledServiceSettingsCalledFieldsList([])
          this.setState({ calledServiceSettingsCalledFieldList: [] })
        }
        this.setState({ calledServiceSettingsCalledFieldListForDelete: [] })
        this.setLoading(false)
        this.setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
      } else {
        this.setLoading(false)
        this.setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  setRTabIndex = idx => this.setState({ rTabIndex: idx })

  setLoading = value => this.setState({ loading: value })

  setMessage = (open, variant, message) => this.setState({ openNotification: open, notificationVariant: variant, notificationMessage: message })

  getAllCalledServiceSettingsOrganizationFields = async calledServiceSettingsId => {
    this.setState({ loading: true })
    let result = await CalledServiceSettingsOrganizationFieldService.getAllByCalledServiceSettingsIdWithoutIncludes(calledServiceSettingsId)
    if(result && result.success) {
      if (result.data != null) {
        this.props.addCalledServiceSettingsOrganizationFieldsList(result.data)
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllCalledServiceSettingsCalledFields = async (calledServiceSettingsId, exitLoading = false) => {
    this.setState({ loading: true })
    let result = await CalledServiceSettingsCalledFieldService.getAllByCalledServiceSettingsIdWithoutIncludes(calledServiceSettingsId)
    if(result && result.success) {
      if (result.data != null) {
        let formattedMessage = result.data.map(element => {
          let fieldTypeName = ''
          switch(element.fieldtype) {
            case 1:
              fieldTypeName = 'Título do chamado'
              break
            case 2:
              fieldTypeName = 'Descrição do chamado'
              break
            case 3:
              fieldTypeName = 'Categoria do chamado'
              break
            case 4:
              fieldTypeName = 'Nome do usuário'
              break
            case 5:
              fieldTypeName = 'Email do usuário'
              break
            case 6:
              fieldTypeName = 'Telefone do usuário'
              break
            case 7:
              fieldTypeName = 'Documento do usuário'
              break
            default:
              fieldTypeName = ''
              break
          }

          return {
            ...element,
            fieldTypeName: fieldTypeName
          }
        })
        
        this.props.addCalledServiceSettingsCalledFieldsList(formattedMessage)
        this.setState({ calledServiceSettingsCalledFieldList: formattedMessage })
        if (exitLoading) this.setState({ loading: false })
        this.handleSetFieldTypeListOptions(formattedMessage)
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  handleSetFieldTypeListOptions = list => {
    PapiroConsole.log("=== handleSetFieldTypeListOptions ===")
    PapiroConsole.log(list)
    if (list != null && list.length > 0) {
      let calledfieldsList = []
      let fieldValueByFormCalledTitle = false
      let fieldValueByFormCalledDescription = false
      let fieldValueByFormOrganizationCategory = false
        let issetFieldTitle = list.find(element => element.fieldtype == 1)
        PapiroConsole.log(issetFieldTitle)
        if (issetFieldTitle == null || typeof issetFieldTitle === 'undefined') {
          calledfieldsList.push(this.calledFieldTitleItem)
        } else {
          fieldValueByFormCalledTitle = true
        }
        let issetFieldDescription = list.find(element => element.fieldtype == 2)
        PapiroConsole.log(issetFieldDescription)
        if (issetFieldDescription == null || typeof issetFieldDescription === 'undefined') {
          calledfieldsList.push(this.calledFieldDescriptionItem)
        } else {
          fieldValueByFormCalledDescription = true
        }
        let issetFieldCategory = list.find(element => element.fieldtype == 3)
        PapiroConsole.log(issetFieldCategory)
          if (issetFieldCategory == null || typeof issetFieldCategory === 'undefined') {
            calledfieldsList.push(this.calledFieldCategoryItem)
          } else {
            fieldValueByFormOrganizationCategory = true
          }
        let issetFieldUserName = list.find(element => element.fieldtype == 4)
        PapiroConsole.log(issetFieldUserName)
          if (issetFieldUserName == null || typeof issetFieldUserName === 'undefined')
            calledfieldsList.push(this.calledFieldUserNameItem)
        let issetFieldUserEmail = list.find(element => element.fieldtype == 5)
        PapiroConsole.log(issetFieldUserEmail)
          if (issetFieldUserEmail == null || typeof issetFieldUserEmail === 'undefined')
            calledfieldsList.push(this.calledFieldUserEmailItem)
        let issetFieldUserPhone = list.find(element => element.fieldtype == 6)
        PapiroConsole.log(issetFieldUserPhone)
          if (issetFieldUserPhone == null || typeof issetFieldUserPhone === 'undefined')
            calledfieldsList.push(this.calledFieldUserPhoneItem)
        let issetFieldUserDocument = list.find(element => element.fieldtype == 7)
        PapiroConsole.log(issetFieldUserDocument)
          if (issetFieldUserDocument == null || typeof issetFieldUserDocument === 'undefined')
            calledfieldsList.push(this.calledFieldUserDocumentItem)
      PapiroConsole.log(calledfieldsList)
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          istitlefieldprovidedbyuser: fieldValueByFormCalledTitle,
          fieldValueByFormCalledDescription: fieldValueByFormCalledDescription,
          iscategoryfieldprovidedbyuser: fieldValueByFormOrganizationCategory,
        }
      }))
      this.setState({ calledfieldsList: calledfieldsList })
    } else {
      this.setState({ calledfieldsList: [
        this.calledFieldTitleItem,
        this.calledFieldDescriptionItem,
        this.calledFieldCategoryItem,
        this.calledFieldUserNameItem,
        this.calledFieldUserEmailItem,
        this.calledFieldUserPhoneItem,
        this.calledFieldUserDocumentItem
      ] })
    }

    return list
  }

  editItem = async (params) => {
    PapiroConsole.log("=== editItem ===")
    PapiroConsole.log(params)
    await this.props.addCalledServiceSettingsCalledField(params.row)
    this.setState({ itemEditData: params.row })
    this.handleOpenCalledServiceSettingsCalledFieldUpdateModal(true)
  }

  getAllCalledServiceSettingsFileUploads = async calledServiceSettingsId => {
    this.setState({ loading: true })
    let result = await CalledServiceSettingsFileUploadService.getAllByCalledServiceSettingsIdWithoutIncludes(calledServiceSettingsId)
    if(result && result.success) {
      if (result.data != null) {
        this.props.addCalledServiceSettingsFileUploadsList(result.data)
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllCalledServiceSettingsValidations = async calledServiceSettingsId => {
    this.setState({ loading: true })
    const { intl } = this.props
    let result = await CalledServiceSettingsValidationService.getAllByCalledServiceSettingsIdWithAttachmentIncludes(calledServiceSettingsId)
    if(result && result.success) {
      if (result.data != null) {
        let formmatedData = result.data.map(item => {
          return {
            ...item,
            IsActive: item.active != null && item.active === true ? intl.formatMessage({ id: "yes" })  : intl.formatMessage({ id: "no" })
          }
        })

        this.props.addCalledServiceSettingsValidationsList(formmatedData)
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllOrganizations = async _ => {
    let result = await getAllSelectionModel()
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ OrganizationList: result.data })
        if (this.state.item.organizationId && !isNaN(this.state.item.organizationId) && this.state.item.organizationId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.organizationId)
          PapiroConsole.log(filter)
          if (filter != null) {
            this.setState(prevState => ({ item: { ...prevState.item, organizationId: filter } }))
            this.getAllOrganizationCategoriesByOrganizationId(filter.id)
          } else
            this.setState({ loading: false })
        } else
          this.setState({ loading: false })
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllTypes = async _ => {
    let result = await getAllTypesSelectionModel()
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ TypeList: result.data })
        PapiroConsole.log("=== getAllTypes ===")
        PapiroConsole.log(this.state.item.typeId)
        PapiroConsole.log(result.data)
        if (this.state.item.typeId && !isNaN(this.state.item.typeId) && this.state.item.typeId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.typeId)
          PapiroConsole.log(filter)
          if (filter != null)
            this.setState(prevState => ({ item: { ...prevState.item, typeId: filter } }))
        }
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllOriginRequests = async _ => {
    let result = await getAllOriginRequestsSelectionModel()
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ OriginRequestList: result.data })
        if (this.state.item.originRequestId && !isNaN(this.state.item.originRequestId) && this.state.item.originRequestId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.originRequestId)
          PapiroConsole.log(filter)
          if (filter != null)
            this.setState(prevState => ({ item: { ...prevState.item, originRequestId: filter } }))
        }
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllStatus = async _ => {
    let result = await getAllStatusSelectionModel()
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ StatusList: result.data })
        if (this.state.item.statusId && !isNaN(this.state.item.statusId) && this.state.item.statusId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.statusId)
          PapiroConsole.log(filter)
          if (filter != null)
            this.setState(prevState => ({ item: { ...prevState.item, statusId: filter } }))
        }
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllPriority = async _ => {
    let result = await getAllPrioritySelectionModel()
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ PriorityList: result.data })
        if (this.state.item.priorityId && !isNaN(this.state.item.priorityId) && this.state.item.priorityId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.priorityId)
          PapiroConsole.log(filter)
          if (filter != null)
            this.setState(prevState => ({ item: { ...prevState.item, priorityId: filter } }))
        }
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllOrganizationCategoriesByOrganizationId = async (orgId) => {
    this.setState({ loading: true })

    let result = await getAllSelectionModelCanCreateCalledByOrganizationId(orgId)
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ OrganizationCategoryList: result.data })
        if (this.state.item.organizationCategoryId && !isNaN(this.state.item.organizationCategoryId) && this.state.item.organizationCategoryId > 0) {
          let filter = result.data.find(i => i.id == this.state.item.organizationCategoryId)
          PapiroConsole.log(filter)
          if (filter != null)
            this.setState(prevState => ({ item: { ...prevState.item, organizationCategoryId: filter } }))
        }
      }
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getAllOrganizationFieldByOrganizationId = async (orgId) => {

    let result = await getAllSelectionModelByOrganizationIdAndActiveAndtarget(orgId)
    if(result && result.success) {
      if (result.data != null) {
        this.setState({ SubjectList: result.data, ReasonList: result.data, ServiceList: result.data })
      }
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  handleChangeEditorAction = value => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        validationofexistingcalledmessage: value
      }
    }))
  }

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    if (name === 'emailvalidationofexistingcalled') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          documentvalidationofexistingcalled: checked ? false : true
        }
      }))
    } else if (name === 'documentvalidationofexistingcalled') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          emailvalidationofexistingcalled: checked ? false : true
        }
      }))
    } else if (name === 'istitlefieldprovidedbyuser') {
      if (checked != null && checked == true) {
        let found = this.state.calledfieldsList.find(element => element.id == this.calledFieldTitleItem.id)
        if (found != null && typeof found !== 'undefined') {
          let newCalledFieldsList = this.state.calledfieldsList.map(element => element.id != this.calledFieldTitleItem.id)
          this.setState({ calledfieldsList: newCalledFieldsList })
        }
      } else {
        let found = this.state.calledfieldsList.find(element => element.id == this.calledFieldTitleItem.id)
        if (found == null || typeof found === 'undefined') {
          let newCalledFieldsList = [this.calledFieldTitleItem.id, ...this.state.calledfieldsList]
          this.setState({ calledfieldsList: newCalledFieldsList })
        }
      }
    } else if (name === 'iscategoryfieldprovidedbyuser') {
      if (checked != null && checked == true) {
        let found = this.state.calledfieldsList.find(element => element.id == this.calledFieldCategoryItem.id)
        if (found != null && typeof found !== 'undefined') {
          let newCalledFieldsList = this.state.calledfieldsList.map(element => element.id != this.calledFieldCategoryItem.id)
          this.setState({ calledfieldsList: newCalledFieldsList })
        }
      } else {
        let found = this.state.calledfieldsList.find(element => element.id == this.calledFieldCategoryItem.id)
        if (found == null || typeof found === 'undefined') {
          let newCalledFieldsList = [this.calledFieldCategoryItem.id, ...this.state.calledfieldsList]
          newCalledFieldsList = newCalledFieldsList.sort((a, b) => a.id - b.id)
          this.setState({ calledfieldsList: newCalledFieldsList })
        }
      }
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: checked
      }
    }))
  }

  handleSubmit = async e => {
      e.preventDefault()
      let { item } = this.state

      this.setState({ loading: true, createCalledServiceSettingsItemDialogOpen: false })

      let data = new FormData()
      
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }))

        for (let key in item) {
          if(key.toString().toLowerCase().indexOf('updateduser') > -1) {
            if (item.id > 0)
              data.append(key, item[key])
            else
              data.append('createduser', item[key])
          } else if(key.toString().toLowerCase().indexOf('updatedat') > -1) {
            if (item.id > 0)
              data.append(key, moment(item[key]).format('YYYY/MM/DD HH:mm:ss'))
            else
              data.append('createddat', moment(item[key]).format('YYYY/MM/DD HH:mm:ss'))
          } else if (key.toString().endsWith('Id')) {
            if (item[key] && item[key].id && item[key].id > 0)
              data.append(key, item[key].id)
            else if (item[key] && item[key] > 0)
              data.append(key, item[key])
          } else {
            data.append(key, item[key])
          }
        }

        data.append("automaticcategoryfield", item.id > 0 ? false : this.state.item.fieldValueByFormCalledTitle)
        data.append("automatictitlefield", item.id > 0 ? false : this.state.item.fieldValueByFormOrganizationCategory)

        let result = item.id > 0 ? await Service.editItem(data) : await Service.addItem(data)
        if(result && result.success) {
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: item.id > 0 ? intl.formatMessage({ id: "edit.success" }) : intl.formatMessage({ id: "save.success" })
          })
          if (result.data != null) {
            if (!(item.id > 0)) {
              let frontCalledServiceUrl = Api.frontCalledServiceUrl()
              let l = this.props.match.params.lang

              this.setState(prevState => ({
                item: {
                  ...prevState.item,
                  id: result.data.id,
                  routeText: `${frontCalledServiceUrl}${l}/${result.data.route}`
                }
              }))
              this.getAllCalledServiceSettingsCalledFields(result.data.id, true)
            }
            this.props.addCurrent(result.data)
          }
        } else {
          PapiroConsole.log("=== handleSubmit errors ===")
          PapiroConsole.log(result)
          const intl = this.props.intl
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result && result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  changeValues(stateName, value, text = '') {

    if (stateName === 'organizationId' && value && value.id && value.id > 0) {
      this.getAllOrganizationCategoriesByOrganizationId(value.id)
      this.getAllOrganizationFieldByOrganizationId(value.id)
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  NoDataRows = _ => {
    const { intl } = this.props
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
    )
  }

  handleCopyLink = () => {
    var link = this.state.item.routeText;
    PapiroConsole.log(this.state.item.routeText)
    PapiroConsole.log("this.state.item.routeText")
    navigator.clipboard.writeText(link)
      
  };

  handleRedirectLink = () => {
    var link = this.state.item.routeText;
    PapiroConsole.log(this.state.item.routeText)
    PapiroConsole.log("this.state.item.routeText")
    window.open(link, '_blank');
  };

  render() {
    const { classes, headerMenu, actionsHeaderMenu, intl } = this.props
    const { item } = this.state

    if(Api == null) {
      var l = this.props.match.params.lang
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
        <div className={classes.drawerHeader} />
        <Container component='main' maxWidth='md'>
          <CssBaseline />
          <AddCalledServiceSettingsCalledFieldItemDialog
            open={this.state.calledServiceSettingsCalledFieldInsertDialogOpen}
            handleOpen={this.handleOpenCalledServiceSettingsCalledFieldInsertModal}
            itemsCount={this.props?.calledservicesettingscalledfields?.calledservicesettingscalledfieldslist?.length}
            userId={this.state.item.updatedUser}
            hirerId={this.state.item.hirerid}
            calledServiceSettingsId={this.state.item.id}
            organizationId={this.state.item.organizationId?.id}
            fieldTypeList={this.state.calledfieldsList} />
          <UpdateCalledServiceSettingsCalledFieldItemDialog
            open={this.state.calledServiceSettingsCalledFieldUpdateDialogOpen}
            handleOpen={this.handleOpenCalledServiceSettingsCalledFieldUpdateModal}
            itemsCount={this.state.calledServiceSettingsCalledFieldList?.length}
            userId={this.state.item.updatedUser}
            hirerId={this.state.item.hirerid}
            calledServiceSettingsId={this.state.item.id}
            organizationId={this.state.item.organizationId?.id}
            itemData={this.state.itemEditData}
            fieldTypeList={this.state.calledfieldsList} />
          <Dialog
            open={this.state.createCalledServiceSettingsItemDialogOpen}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleCloseCreateCalledServiceSettingsItemModal}
            scroll="body"
            aria-labelledby="form-add-called-service-setting-dialog-title" >
            <DialogTitle id="form-add-called-service-setting-dialog-title">
              { intl.formatMessage({ id: "add.field" }) }
              <Clear onClick={this.handleCloseCreateCalledServiceSettingsItemModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Para adicionar os campos do formulário, é necessário salvar a configuração do formulário externo antes. Deseja salvar o formulário externo atual?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                  variant='contained'
                  type="button"
                  onClick={this.handleCloseCreateCalledServiceSettingsItemModal} >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button 
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={this.handleSubmit} >
                  {intl.formatMessage({id:"save"})}
                </Button>
              </DialogActions>
          </Dialog>
          <div className={classes.paper} style={{ marginTop: 0 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
              </Grid>
              <Grid item xs={12} sm={8} />
              {this.state.item.system && <Grid item xs={12} sm={12}>
                <Typography component='h1' variant='h3'>
                  {<FormattedMessage id="name" />}: {this.state.item.title}
                </Typography>
              </Grid>}
              <Grid item xs={12} sm={12}>
                <Box display="flex" alignItems="center">
                  <Typography component='h1' variant='h5' style={{marginTop:'5px', marginRight:'8px'}}>
                      Rota: {this.state.item.routeText} 
                    </Typography>
                    <Tooltip title="Copiar link">
                          <IconButton onClick={this.handleCopyLink}>
                            <ContentCopyIcon style={{ color: '#277afe' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Ir para o link">
                          <IconButton onClick={this.handleRedirectLink}>
                            <LanguageIcon style={{ color: '#277afe' }} />
                          </IconButton>
                        </Tooltip>
                </Box>
                
              </Grid>
            
            <Grid item xs={12} sm={12}>
                  <RTabs selectedIndex={this.state.rTabIndex} onSelect={ index => this.setRTabIndex(index) } >
                    <RTabList style={{ borderColor: '#BB8FCE' }}>
                      <RTab>{intl.formatMessage({id:"called.service.settings"})}</RTab>
                      <RTab>{intl.formatMessage({id:"called.service.settings.organization.main.fields"})}</RTab>
                      {this.state.item.id > 0 && (<RTab>{intl.formatMessage({id:"called.service.settings.organization.fields"})}</RTab>)}
                      {this.state.item.id > 0 && (<RTab>{intl.formatMessage({id:"called.service.settings.file.upload.fields"})}</RTab>)}
                      {this.state.item.id > 0 && (<RTab>{intl.formatMessage({id:"called.service.settings.organization.validations"})}</RTab>)}
                    </RTabList>
                    <RTabPanel style={{ padding: 10 }}>
                      <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                          {!this.state.item.system && <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <TextField
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                                label={intl.formatMessage({ id: "name" })}
                                autoComplete='fname'
                                variant='outlined'
                                name='title'
                                onChange={this.handleInputChange}
                                required
                                value={item.title} />
                            </Grid>
                          </Grid>}
                          <Grid item xs={9} sm={6}>
                            <TextField
                              inputProps={{ maxLength: 255 }}
                              fullWidth
                              label="Título do chamado"
                              autoComplete='fname'
                              variant='outlined'
                              name='calledTitle'
                              onChange={this.handleInputChange}
                              value={item.calledTitle}
                              disabled={item.istitlefieldprovidedbyuser} />
                          </Grid>
                          <Grid item xs={3} sm={1} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 16, display: 'flex', alignItems: 'center' }}>
                            <Tooltip title={<h5>Nome que será utilizado como o título do chamado</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.istitlefieldprovidedbyuser}
                                    onChange={this.handleChangeSwitch}
                                    name="istitlefieldprovidedbyuser" />
                                }
                                label={"Valor do campo será fornecido pelo usuário no formulário?"} />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <SimpleAutoComplete
                                label={intl.formatMessage({ id: "organization" })}
                                options={this.state.OrganizationList}
                                stateName='organizationId'
                                changeSelect={this.changeValues}
                                selected={item.organizationId}
                                required
                                />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <SimpleAutoComplete
                              label={intl.formatMessage({ id: "category" })}
                              options={this.state.OrganizationCategoryList}
                              stateName='organizationCategoryId'
                              changeSelect={this.changeValues}
                              selected={item.organizationCategoryId}
                              required={!item.iscategoryfieldprovidedbyuser}
                              disabled={item.iscategoryfieldprovidedbyuser} />
                          </Grid>
                          <Grid item xs={12} sm={1}/>
                          <Grid item xs={12} sm={5}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.iscategoryfieldprovidedbyuser}
                                    onChange={this.handleChangeSwitch}
                                    name="iscategoryfieldprovidedbyuser" />
                                }
                                label={"Valor do campo será fornecido pelo usuário no formulário?"} />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <SimpleAutoComplete
                                label={intl.formatMessage({ id: "type" })}
                                options={this.state.TypeList}
                                stateName='typeId'
                                changeSelect={this.changeValues}
                                selected={item.typeId}
                                required
                                />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <SimpleAutoComplete
                                label={intl.formatMessage({ id: "origin.request" })}
                                options={this.state.OriginRequestList}
                                stateName='originRequestId'
                                changeSelect={this.changeValues}
                                selected={item.originRequestId}
                                required
                                />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <SimpleAutoComplete
                                label={intl.formatMessage({ id: "menu.statuses" })}
                                options={this.state.StatusList}
                                stateName='statusId'
                                changeSelect={this.changeValues}
                                selected={item.statusId}
                                required
                                />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={11} sm={11}>
                              <SimpleAutoComplete
                                label={intl.formatMessage({ id: "priority" })}
                                options={this.state.PriorityList}
                                stateName='priorityId'
                                changeSelect={this.changeValues}
                                selected={item.priorityId}
                                required
                                />
                            </Grid>
                          </Grid>
                          { item.id == 2 && <Grid item xs={11} sm={11}>
                            <TextField
                              inputProps={{ maxLength: 255 }}
                              fullWidth
                              label="URL do botão de voltar"
                              autoComplete='fname'
                              variant='outlined'
                              name='backButtonUrl'
                              onChange={this.handleInputChange}
                              value={item.backButtonUrl} />
                          </Grid>}
                          {item.id == 2 && <Grid item xs={1} sm={1} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 16, display: 'flex', alignItems: 'center' }}>
                            <Tooltip title={<h5>Caso o formulário seja invalidado, será exibido um botão de nome "voltar", que redirecionará para o a URL informada</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          <Grid item xs={10} sm={6} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.enablecaptcha}
                                    onChange={this.handleChangeSwitch}
                                    name="enablecaptcha" />
                                }
                                label={intl.formatMessage({ id: "called.service.settings.enable.captcha" })} />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
                            <Tooltip title={<h5>{intl.formatMessage({ id: "called.service.settings.enable.captcha.helper" })}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>
                          {item.system && <Grid item xs={10} sm={6} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.turnonfacelivenessvalidation}
                                    onChange={this.handleChangeSwitch}
                                    name="turnonfacelivenessvalidation" />
                                }
                                label={"Habilitar verificação de vida?"} />
                            </FormGroup>
                          </Grid>}
                          {item.system && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
                            <Tooltip title={<h5>Habilita captura de selfie do usuário, para verificar se é uma pessoa viva que está fazendo a solicitação</h5>}>
                              <HelpIcon />
                              </Tooltip>
                          </Grid>}
                          <Grid item xs={12} sm={12} paddingBottom={5}>
                            <Grid item xs={11} sm={11}>
                              <FormattedMessage id="whitelabelconfig.footer.text" />
                              <ReactQuill
                                value={item.footermessage || ""}
                                name="footertext" style={{ height: 200 }} modules={reactQuillModules} formats={reactQuillFormats}
                                fullWidth
                                required
                                variant="outlined"
                                id="footertext"
                                onChange={value => this.changeValues("footermessage", value)}
                            />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset" variant="standard">
                              <FormLabel component="legend">
                                <FormattedMessage id="called.service.settings.validation" />
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch 
                                      checked={item.validationofexistingcalleds}
                                      onChange={this.handleChangeSwitch}
                                      name="validationofexistingcalleds" />
                                  }
                                  label={intl.formatMessage({ id:"called.service.settings.existing.called.validation" })} />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          {item.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {item.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.emailvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="emailvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.mail.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {item.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.mail.existing.called.validation.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {item.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {item.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={item.documentvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="documentvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.document.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {item.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.document.existing.called.validation.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {item.validationofexistingcalleds == true && <Grid item xs={11} sm={11} style={{ marginBottom: 30 }}>
                            <Typography>
                              <FormattedMessage id="called.service.settings.existing.called.validation.message" />
                            </Typography>
                            <ReactQuill
                              id="validationofexistingcalledmessage"
                              name="validationofexistingcalledmessage"
                              modules={this.reactQuillModules}
                              formats={this.reactQuillFormats}
                              value={item.validationofexistingcalledmessage}
                              style={{ height: 200 }}
                              fullWidth
                              required
                              InputLabelProps={{ shrink: item.validationofexistingcalledmessage && item.validationofexistingcalledmessage.length > 0 ? true : false }}
                              variant="outlined"
                              onChange={this.handleChangeEditorAction} />
                          </Grid>}
                          {item.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.existing.called.validation.message.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          <Grid item xs={12} sm={11} style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                              <Grid item xs={12} sm={4}    >
                                <Button 
                                  type='submit'
                                  fullWidth
                                  variant='contained'
                                  color='primary'
                                  className={classes.submit} >
                                  {<FormattedMessage id="save" />}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <Grid container spacing={2}>
                      { ((this.state.item.organizationId != null && this.state.item.organizationId.id > 0) || this.state.calledServiceSettingsCalledFieldListForDelete.length > 0) && <Grid item xs={12} sm={12} style={{ padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid item xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              { this.state.calledServiceSettingsCalledFieldListForDelete.length > 0 && <DialogConfirmation
                                msgButton={intl.formatMessage({id: "delete.field" })}
                                msg={intl.formatMessage({id: "delete.field.confirmation.message" })}
                                msgDisagree={intl.formatMessage({id:"cancel"})}
                                msgAgree={intl.formatMessage({id:"yes"})}
                                handleConfirmation={e => this.calledServiceSettingsCalledFieldDeleteModal()}
                                classes={classes}
                                icon={<Delete />} />}
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              { (this.state.item.organizationId != null && this.state.item.organizationId.id > 0) && <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.button}
                                startIcon={<Add />}
                                onClick={e => this.handleOpenCalledServiceSettingsCalledFieldInsertModal(true)} >
                                {intl.formatMessage({id:"add.field"})}
                              </Button>}
                            </Grid>
                          </Grid>}
                          <Grid item xs={12} sm={12}
                              style={{
                                border: '3px solid #eee'
                              }} >
                              <div style={{ height: 350 }}>
                                <DataGrid
                                  style={{ fontSize: 14, color: '#000' }}
                                  className="DataGridStyle"
                                  components={{
                                    NoRowsOverlay: this.NoDataRows,
                                    Toolbar: GridToolbarFilterButton,
                                  }}
                                  rows={this.state.calledServiceSettingsCalledFieldList}
                                  rowsPerPageOptions={[10, 25, 50]}
                                  checkboxSelection
                                  onSelectionModelChange={itm => {
                                    if (itm.length > 0) {
                                      PapiroConsole.log("=== onSelectionModelChange ===")
                                      PapiroConsole.log(itm)

                                      this.setCalledServiceSettingsCalledFieldDelete(itm);
                                    } else {
                                      this.setCalledServiceSettingsCalledFieldDelete([])
                                    }
                                  }}
                                  columns={[
                                    {
                                      headerName: intl.formatMessage({id:"edit"}),
                                      field: "actions",
                                      renderCell: (params) => (
                                        <IconButton
                                          arya-label="edit"
                                          onClick={() => this.editItem(params)} >
                                          <Edit />
                                        </IconButton>
                                      )
                                    },
                                    { headerName: intl.formatMessage({ id: "field" }), field: 'label', width: 300 },
                                    { headerName: 'Tipo do campo', field: 'fieldTypeName', width: 200 },
                                    { headerName: intl.formatMessage({ id: "item.order" }), field: 'order', width: 100 }
                                  ]}
                                />
                              </div>
                        </Grid>
                      </Grid>
                    </RTabPanel>
                    {this.state.item.id > 0 && (<RTabPanel style={{ padding: 10 }}>
                      <CalledservicesettingsOrganizationfield
                        userId={this.state.item.updatedUser}
                        hirerId={this.state.item.hirerid}
                        classes={classes}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                        calledServiceSettingsId={this.state.item.id}
                        organizationId={this.state.item.organizationId && this.state.item.organizationId.id ? this.state.item.organizationId.id : 0} />
                    </RTabPanel>)}
                    {this.state.item.id > 0 && (<RTabPanel style={{ padding: 10 }}>
                      <CalledServiceSettingsFileUploads
                        userId={this.state.item.updatedUser}
                        hirerId={this.state.item.hirerid}
                        classes={classes}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                        calledServiceSettingsId={this.state.item.id} />
                    </RTabPanel>)}
                    {this.state.item.id > 0 && (<RTabPanel style={{ padding: 10 }}>
                      <CalledservicesettingsValidation
                        userId={this.state.item.updatedUser}
                        hirerId={this.state.item.hirerid}
                        classes={classes}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                        calledServiceSettingsId={this.state.item.id}
                        organizationId={this.state.item.organizationId && this.state.item.organizationId.id ? this.state.item.organizationId.id : 0} />
                    </RTabPanel>)}
                  </RTabs>
              </Grid>
            </Grid>
          </div>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}
          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

CalledServiceSettings.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calledservicesettings: state.calledservicesettings,
  calledservicesettingscalledfields: state.calledservicesettingscalledfields
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, calledServiceSettingsActions, calledServiceSettingsOrganizationFieldsActions, CalledServiceSettingsCalledFieldsActions, calledServiceSettingsFileUploadsActions, calledServiceSettingsValidationsActions), dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledServiceSettings)))