import Api from './api';




export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/Integrationauthheaders`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



