import React, { Component } from 'react';

import { QRCode } from "react-qr-svg";
export default class Qr extends React.Component {
    render() {
      return (
      <QRCode value={this.props.valor}  bgColor="#FFFFFF"
      fgColor="#000000"
      level="Q"
      style={{ width: 256,marginTop:60, marginLeft: 25,marginBottom:60}}  />);
    }
  }
   