export default function units(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
        return {
          ...state,
          unit: action.unit,
        }
      default:
          return state;
  }
}