import Api from './api';

export const deleteAttachmentItem = async (id) => { 
  let data = []
  await Api.delete(`/additionalcalledcosts/attach/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}