export default function shippingservicesettings(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT_SHIPPING_SERVICE_SETTINGS':
          return {
              ...state,
              shippingservicesettings: action.shippingservicesettings,
          }
      default:
          return state;
  }
}