import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../../styles/material-styles";
import { Grid, Button, Dialog, TextField } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AbilityContext } from '../../../../config/ability-context';
import { injectIntl } from 'react-intl';
import Api2Talk from "../../../../services/api2talk";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CachedIcon from '@mui/icons-material/Cached';
import PropTypes from 'prop-types';
import { PapiroConsole } from "../../../../utils/papiroConsole";
import * as componentActions from '../../../../redux/actions/phoneregistrationrequests';

class RegistrationData extends Component {

    constructor(props) {
        super(props);




        const { phoneregistrationrequests } = this.props;

        PapiroConsole.log("props aqui")
        PapiroConsole.log(props)



        this.state = {
            requestnumber: phoneregistrationrequests.phoneregistrationrequests,
            openCancelRegisterNumber: false,

        };


        this.changeValuesText = this.changeValuesText.bind(this);


    }





    componentDidMount() {
    }

    changeValues = (stateName, value) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

    };



    changeValuesText(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            requestnumber: {
                ...prevState.requestnumber,
                [name]: value
            }
        }));

        PapiroConsole.log(this.state)
        PapiroConsole.log("this.state")
    }

    handleSubmit = async (event) => {

        const { getNotification, setLoadingState } = this.props;
        if (Api2Talk == null) {


            getNotification(false, true, 'error', "Api de mensageria não configurada.")
            /*this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });*/
            return;
        }

        event.preventDefault();
        //this.setState({ loading: true });
        setLoadingState(true)
        this.openCancelRegister();

        const requestNumber = this.state.requestnumber;
        requestNumber.requeststatusid = 2;

        Api2Talk.put("/registrationphonenumber/" + requestNumber.id)
            .then((result) => {
                if (result.data.success) {

                    requestNumber.StatusName = result.data.data?.requeststatus?.name ?? "Cancelado";
                    var newRequest = requestNumber.requeststatus;
                    newRequest.id = 2;

                    requestNumber.requeststatus = newRequest;

                    getNotification(false, true, 'success', "Solicitação cancelado com sucesso")
                    this.setState({ requestnumber: requestNumber })
                    /*
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: "Solicitação cancelado com sucesso",
                        requestnumber: requestNumber
                    });*/
                } else {
                    const intl = this.props.intl;

                    getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    /*this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            result.data.errors && result.data.errors[0]
                                ? result.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.errors) {
                    const intl = this.props.intl;
                    getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    /*
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                } else {
                    const intl = this.props.intl;
                    getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

                    /*this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                }
            });
    }

    openCancelRegister = () => {
        this.setState({ openCancelRegisterNumber: !this.state.openCancelRegisterNumber });

    }

    handleReload = () => {

        const { getNotification, setLoadingState } = this.props;
        if (Api2Talk == null) {
            /*this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });*/
            getNotification(false, true, 'error', "Api de mensageria não configurada.");
            return;
        }

        //this.setState({ loading: true });
        setLoadingState(true)

        if (Api2Talk == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/calleds`
        }

        Api2Talk.get("/registrationphonenumber/verifystatus/" + this.state.requestnumber.id)
            .then((result) => {
                if (result.data.success) {

                    const formattedList = this.state.requestnumber;
                    formattedList.StatusName = result.data.data?.requeststatus?.name;
                    formattedList.requeststatus = result.data.data?.requeststatus ?? formattedList.requeststatus;
                    formattedList.accountid = result.data.data?.accountid ?? formattedList.accountid;
                    formattedList.phonenumberid = result.data.data?.phonenumberid ?? formattedList.phonenumberid;
                    formattedList.messageproviderconfig = result.data.data?.messageproviderconfig ?? formattedList.messageproviderconfig;

                    
                    /*
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: "Solicitação atualizada com sucesso",
                        requestnumber: formattedList
                    });*/
                    getNotification(false, true, 'success', "Solicitação atualizada com sucesso.");
                    this.setState({ requestnumber: formattedList });
                    this.props.addCurrent(formattedList)
                    

                } else {
                    const intl = this.props.intl;
                    getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    /*this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            result.data.errors && result.data.errors[0]
                                ? result.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.errors) {
                    const intl = this.props.intl;
                    /*this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                    getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                } else {
                    const intl = this.props.intl;

                    /*this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });*/
                    getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                }
            });
    }

    validations() {

        const { getNotification } = this.props;

        PapiroConsole.log(this.state.requestnumber)
        PapiroConsole.log("this.state.requestnumber")
        if (!this.state.requestnumber.accountid) {
            getNotification(false, true, 'error', "É necessário preencher o campo AccountId");
            return false
        }
        if (!this.state.requestnumber.phonenumberid) {

            getNotification(false, true, 'error', "É necessário preencher o campo PhoneNumberId");
            return false

        }

        return true
    }

    handleActiveUse = () => {

        const { getNotification, setLoadingState } = this.props;
        if (Api2Talk == null) {
            /*this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });*/
            getNotification(false, true, 'error', "Api de mensageria não configurada.");
            return;
        }

        //this.setState({ loading: true });

        let validations = this.validations()

        if (validations) {
            let data = {
                id: this.state.requestnumber.id,
                accountid: this.state.requestnumber.accountid,
                phonenumberid: this.state.requestnumber.phonenumberid
            }

            setLoadingState(true)
            Api2Talk.put("/registrationphonenumber/activeuse", data)
                .then((result) => {
                    if (result.data.success) {
                        const formattedList = this.state.requestnumber;

                        formattedList.StatusName = "Ativo";
                        var newRequest = formattedList.requeststatus;
                        newRequest.id = 2;
                        formattedList.requeststatus = result.data.data?.requeststatus ?? newRequest;

                        getNotification(false, true, 'success', "Número ativado com sucesso");
                        /* this.setState({
                             loading: false,
                             openNotification: true,
                             notificationVariant: "success",
                             notificationMessage: "Número ativado com sucesso",
                             requestnumber: formattedList
                         });*/
                        this.setState({ requestnumber: formattedList });
                    } else {
                        const intl = this.props.intl;
                        /*this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage:
                                result.data.errors && result.data.errors[0]
                                    ? result.data.errors[0]
                                    : intl.formatMessage({ id: "process.error" }),
                        });*/
                        getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    }
                })
                .catch((err) => {
                    if (err && err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        /*this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage:
                                err.response && err.response.data && err.response.data.errors
                                    ? err.response.data.errors[0]
                                    : intl.formatMessage({ id: "process.error" }),
                        });*/
                        getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

                    } else {
                        const intl = this.props.intl;

                        /*this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage:
                                err.response && err.response.data.errors
                                    ? err.response.data.errors[0]
                                    : intl.formatMessage({ id: "process.error" }),
                        });*/
                        getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    }
                });
        }
        


    }




    render() {

        const { classes } = this.props;
        const { requestnumber } = this.state;

        PapiroConsole.log(requestnumber)
        PapiroConsole.log("requestnumber listado")

        return (

            <div>
                <Dialog fullWidth maxWidth="sm" open={this.state.openCancelRegisterNumber} onClose={this.openCancelRegister} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        Atenção!
                    </DialogTitle>
                    <DialogContent style={{ minWidth: "400px" }}>
                        Essa solicitação será cancelada, você tem certeza?
                    </DialogContent>
                    <DialogActions>
                        <Grid item xs={12} sm={4}>
                            <Button
                                onClick={this.openCancelRegister}
                                fullWidth
                                color="secondary"
                            >
                                Fechar
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Button
                                onClick={this.handleSubmit}
                                fullWidth
                                color="primary"
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <form name='myForm' className={classes.form} encType='multipart/form-data'>
                    <Grid container spacing={2} style={{ marginTop: 15 }}>

                        <Grid item xs={10} sm={10}>
                            <TextField
                                fullWidth
                                label="Status da Solicitação"
                                variant="outlined"
                                value={requestnumber.StatusName}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={1} sm={1} style={{ marginTop: '8px' }}>
                            {requestnumber.requeststatusid == 3 || requestnumber.requeststatusid == 4 ?
                                <CheckCircleIcon fontSize='large' color='success' />
                                : ""
                            }
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <Tooltip title="Verificar se houve atualizações de status." aria-label="add">
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={this.handleReload}
                                >
                                    <CachedIcon fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Data da Solicitação"
                                variant="outlined"
                                value={requestnumber.RequestDate}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Nome da organização"
                                variant="outlined"
                                value={requestnumber.OrganizationName}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Número de telefone"
                                variant="outlined"
                                value={requestnumber.PhoneNumber}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Usuário Requerente"
                                variant="outlined"
                                value={requestnumber.requestuser.name}
                                disabled
                            />
                        </Grid>



                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Provedor de mensageria"
                                variant="outlined"
                                value={requestnumber.messageprovider?.name}
                                onChange={this.changeValuesText}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                name="accountid"
                                label="Número da conta"
                                variant="outlined"
                                value={requestnumber.accountid ? requestnumber.accountid : ''}
                                InputProps={{
                               
                                    autoComplete: 'off',
                                  }}
                                //onChange={this.changeValuesText}
                                disabled

                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                name="phonenumberid"
                                label="Número identificador do telefone"
                                variant="outlined"
                                InputProps={{
                                    autoComplete: 'off',
                                  }}
                                value={requestnumber.phonenumberid ? requestnumber.phonenumberid : '' }
                                disabled
                                //onChange={this.changeValuesText}
                                

                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Grid container spacing={2} >
                                <Grid item sm={4} />
                                <Grid item sm={4}>
                                    <Button
                                        onClick={this.openCancelRegister}
                                        fullWidth
                                        variant='contained'
                                        color='error'
                                        disabled={this.state.requestnumber.requeststatus.id != 1}
                                    >
                                        Cancelar Solicitação
                                    </Button>
                                </Grid>
                                <Grid item sm={4} >
                                    <Button
                                        onClick={this.handleActiveUse}
                                        fullWidth
                                        variant='contained'
                                        color='success'
                                        disabled={!(this.state.requestnumber.requeststatus.id == 3)}
                                    >
                                        Ativar uso
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </form>
            </div>


        );
    }
}


RegistrationData.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    phoneregistrationrequests: state.phoneregistrationrequests
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

//export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(RegistrationData))));
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RegistrationData)));
RegistrationData.contextType = AbilityContext;