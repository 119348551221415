import React, { Component } from "react";
import "./ModalPatrimonio.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from '../../services/api';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SnackbarsModal from "../snackbarsModal";
import ScrollTabs from '../scrolltabs-list/ScrollableTabsButtonForce'
import * as ApiCallCenter from '../../services/apiCallCenter';
import * as Service from '../../services/organization.service';
import * as ServiceCallCenter from '../../services/apiCallCenter';
import * as Service2 from '../../services/organizationsetting.service';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import * as CalledService from '../../services/called.service';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

class ModalVideoCall extends Component {
  constructor(props) {
    super(props);
    const { userSession } = this.props;

    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      title: ""
      
    };
    
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let possiblePass = "0123456789";
    let text = "";
    let password = "";

    for (var i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      if (i < 4) {
          password += possiblePass.charAt(Math.floor(Math.random() * possiblePass.length));
      }
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      passwordRoom: password,
      linkRoom: uuidv4(),
      requestUser: "",
      orgSettings: "",
      organizationList: [],
      clientList: [],
      categoryList: [],
      localizationList: [],
      called: 0
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onLoading = this.onLoading.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getAllOrgs = this.getAllOrgs.bind(this);
    this.getAllUserByOrgs = this.getAllUserByOrgs.bind(this);
    this.getConfigOrg = this.getConfigOrg.bind(this);
    this.getLocationOrg = this.getLocationOrg.bind(this);
    this.getCategoryOrg = this.getCategoryOrg.bind(this);
    this.createRoom = this.createRoom.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  async componentDidMount() {
    await this.getAllOrgs();

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "value") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    /*let x = document.querySelector(".classeteste");
    console.log(x);*/

    this.setState({ loading: true });

    
  };

  async getLocationOrg(orgId){
    this.setState({ loading: true});

    const result = await CalledService.getOrganizationAddresses(orgId);


    if (result.success) {
      this.setState({ loading: false, localizationList: result.data }); 
    } else {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getCategoryOrg(orgId){
    this.setState({ loading: true});

    const result = await CalledService.getCategoriesByOrganization(orgId);

    if (result.success) {
      this.setState({ loading: false, categoryList: result.data }); 
    } else {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getConfigOrg(orgId){
    this.setState({ loading: true});

    const result = await Service2.getOrganizations(orgId);

    if (result.success) {
      this.setState({ loading: false, orgSettings: result.data[0] }); 
    } else {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getAllUserByOrgs(orgId){
    this.setState({ loading: true});

    const result = await CalledService.getRequestingUsers(orgId);

    if (result.success) {
      this.setState({ loading: false, clientList: result.data }); 
    } else {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getAllOrgs(){
    this.setState({ loading: true});

    var result = await Service.getOrgCallCenter();

    if (result.success) {
      this.setState({ loading: false, organizationList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  onLoading(){
    this.setState({ loading: true });
  }


  async onFormSubmit(emailorphoneclient, error, type, datasetScrollTable) {
    const intl = this.props.intl;
    //event.preventDefault();
    if(error == true) {
      if(type == 'email') {
          this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"modal.video.call.email.error"}),
          });
      } else {
          this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"modal.video.call.sms.error"}),
          });
      }
    }else{
      this.setState({ loading: true });

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      let data = new FormData();

      const { userSession } = this.props;

      data.append("duedate", moment().format("YYYY/MM/DD HH:mm:ss"));
      data.append("title", this.state.item['title']);
      data.append("addressid", this.state.item['localization'].id);
      data.append("organizationcategory", this.state.item['category'].id);
      data.append("organization", this.state.item['organization'].id);
      data.append("requestinguser", this.state.item['client'].id);
      data.append("createduser", userSession.user.id);
      data.append("type", 10);

      await Api.post("/calleds/createForm", data, config)
        .then(result => {
          if (result && result.data && result.data.success) {
            this.setState({ calledresponse: JSON.stringify(result.data.data), called: result.data.data.id, userSession: userSession.user.id});
            this.createRoom(emailorphoneclient, datasetScrollTable, type);
          }else{
            const intl = this.props.intl;
            this.setState({
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"process.error"})
            });
          }
        });
    }
    
  }

  async createRoom(data, datasetScrollTable, type){

    const { userSession } = this.props;
    const intl = this.props.intl;
    
    await Api.post("/hirer/vertexLogin", data).then(result => {
      if(result && result.data && result.data.data ){
        let dataForm2 = new FormData();
    
        dataForm2.append('cod', this.state.linkRoom);
        dataForm2.append('password', this.state.passwordRoom);
        dataForm2.append('clientId', data);
        dataForm2.append('organizationkey', this.state.item.organization.organizationkey);
        dataForm2.append('hirerid', this.state.item.organization.hirer ? this.state.item.organization.hirer.id : 0);
        dataForm2.append('called', this.state.calledresponse ? this.state.calledresponse : null);
        dataForm2.append('useremailtec', userSession.user.email);
        
        ApiCallCenter.ApiStartPost(dataForm2, '/rooms/AddWithCalledCreated', result.data.data.accesstoken)
          .then(resultA => {
            if(resultA && resultA.data && resultA.data.success == true &&  resultA.data.data != []){
              this.props.calledStart();
              this.props.onClose();

              //window.open(ApiCallCenter.getUrl() + "/"+this.state.linkRoom+"?token="+result.data.data.accesstoken, "_blank");
              
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "success",
                  notificationMessage: "Sala criada com sucesso!"
              });

              let dataIntegrationKey = new FormData();
              dataIntegrationKey.append('roomid', resultA.data.data.id);
              dataIntegrationKey.append('calledid', this.state.called);

              Api.post("/calleds/saveLogIntegrationKey", dataIntegrationKey).then(result => {

              });

              var url = ApiCallCenter.getUrl() + "/"+this.state.linkRoom+"?token="+result.data.data.accesstoken;
                
              if(type == 'email'){
                window.open(url+"&email=true&value="+datasetScrollTable.emailCall+"&password="+datasetScrollTable.passCall, "_blank");
                //ServiceCallCenter.sendEmailApi(datasetScrollTable);
              }else{
                window.open(url+"&email=false&value="+datasetScrollTable.phone+"&password="+datasetScrollTable.password, "_blank");
                //ServiceCallCenter.sendSMSApi(datasetScrollTable);
              }
            }else{
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: resultA.data && resultA.data.data && resultA.data.data.errors ? resultA.data.data.errors[0] : this.props.intl.formatMessage({id:"modal.video.call.room.error"})
              });
            }
          }).catch(resultA => {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: resultA.response && resultA.response.data && resultA.response.data.errors ? resultA.response.data.errors[0] : this.props.intl.formatMessage({id:"api.2see.connect.error"})
            });
          });
        
        
      }else{
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"api.2see.connect.error"})
        });
      }

    }).catch(err => {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"api.2see.connect.error"})
      });
    });
    
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.OrganizationId = this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    if(stateName == 'organization'){
      
      if(value.id == 0){
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            ['category']: "",
            ['localization']: "",
            ['client']: "",
          },
        }));
      }else{
        this.getAllUserByOrgs(value.id);
        this.getConfigOrg(value.id);
        this.getCategoryOrg(value.id);
        this.getLocationOrg(value.id);
      }
    }

    if(stateName == 'client'){
      this.setState({ requestUser: value });
    }

    this.setState({ loading: false });
  }
  
  render() {
    const { classes, headerMenu,userSession } = this.props;
    const { item } = this.state;

    const intl = this.props.intl;

    return (
      <Container component="main" maxWidth="md">
      <div className="modalPatrimonio">
        <div className="containerModal" style={{zIndex: "999"}}>
          <button className="closeButton" onClick={this.props.onClose}>
            <HighlightOffIcon
              className="closeModal"
              style={{ marginBottom: 0 }}
            />
          </button>
          <div className="containerForm">
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: false,
              })}
              style={{
                marginTop: '5%',
                maxWidth: '100%',
                display: 'flex'
              }}
            >
              {/*<div className={classes.drawerHeader} />*/}
              
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: "-10px" }}>
                <Avatar className={classes.avatar}>
                  <QueuePlayNext />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {<FormattedMessage id="called.new" />}
                </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: "20px"}}>
                      <SimpleAutoComplete 
                        label={this.props.intl.formatMessage({id:"menu.organization"})} 
                        options={this.state.organizationList}
                        stateName='organization'
                        changeSelect={this.changeValues}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: "20px"}}>
                      <SimpleAutoComplete 
                        label={intl.formatMessage({id:"category"})} 
                        options={this.state.categoryList}
                        stateName='category'
                        changeSelect={this.changeValues}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: "20px"}}>
                      <SimpleAutoComplete 
                        label={"Localizações"} 
                        options={this.state.localizationList}
                        stateName='localization'
                        changeSelect={this.changeValues}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: "20px"}}>
                      <SimpleAutoComplete 
                        label={intl.formatMessage({id:"user"})} 
                        options={this.state.clientList}
                        stateName='client'
                        changeSelect={this.changeValues}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: "20px"}}>
                     <TextField
                        autoComplete="fname"
                        value={item.title}
                        name="title"
                        variant="outlined"
                        fullWidth
                        id="title"
                        label={intl.formatMessage({id:"title"})}
                        onChange={this.handleInputChange}
                        inputProps={{ maxLength: 250 }}
                        required
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ScrollTabs
                        cod={this.state.linkRoom}
                        password={this.state.passwordRoom}
                        eventInit={this.onFormSubmit}
                        onLoading={this.onLoading}
                        hirerid={userSession.user.hirerid}
                        requestUser={this.state.requestUser}
                      />
                    </Grid>
                  </Grid>
                </div>

                {this.state.loading && (
                  <Overlay>
                    <CircularProgress color="secondary" />
                  </Overlay>
                )}

                <SnackbarsModal
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
            </main>
          </div>
        </div>
      </div>
      </Container>
    );
  }
}

ModalVideoCall.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ModalVideoCall))
);
