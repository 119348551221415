import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button, Paper } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import Api from '../../services/api'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import 'react-phone-input-2/lib/material.css'
import { PapiroConsole } from 'src/utils/papiroConsole'

class InvalidAuthenticadeUser extends Component {
    constructor(props) {
        super(props)

        console.log(this.props)

        const email = this.props.location.state.email ? this.props.location.state.email : null
        this.state = {
            loading: false,
            statesList: [],
            notificationVariant: 'success',
            notificationMessage: '',
            openNotification: false,
            logo: Api != null ? Api.getLoginImage() : null,
            email : email
        }
    }

    render() {

        return (
            <Grid container spacing={0}>
                <Grid container spacing={0} minHeight='calc(100vh - 30px)' style={{ backgroundColor: '#F8F8F8' }}>
                    <Grid container spacing={0} alignContent='start'>
                        <Grid item sm={12} md={12} lg={2} />
                        <Grid item sm={12} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Logo src={this.state.logo} key="groups-fieldservice-awrar6aw15" />
                        </Grid>
                        <Grid item sm={12} md={12} lg={2} />
                        <Grid item sm={12} md={1} lg={2} />
                        <Grid item sm={12} md={10} lg={8} style={{ width: '100%', boxSizing: 'border-box' }} >
                            <Paper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item sm={8} md={8} lg={8} justifyContent='center' style={{ marginBottom: 30, display: 'flex'}}>
                                        <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4' }}>
                                            Email inválido
                                        </Typography>
                                    </Grid>
                                    <Grid item container sm={12} md={12} lg={12} justifyContent="center" style={{ width: '100%', boxSizing: 'border-box' }}>
                                        <Grid item>
                                            <Typography variant='h5'>E-mail não encontrado{this.state.email ? (':' + this.state.email) : " " }<a href='external-user-registration'>Clique aqui para se cadastrar</a></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item sm={12} md={1} lg={2} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

InvalidAuthenticadeUser.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    email: state.email,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(InvalidAuthenticadeUser)));