import React, { } from 'react';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { injectIntl } from 'react-intl';
import SimpleCheckBox from '../checkbox/check';
import SimpleAutoComplete from '../auto-complete/autocomplete';




function DialogGeneralWithAutoComplete(props) {

  const intl = props.intl;


  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogSubTitle}
          </DialogContentText>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={12}>
              <SimpleAutoComplete
                label= {props.autocompleteLabel}
                options={props.options}
                name={props.autocompleteStateName}
                stateName={props.autocompleteStateName}
                changeSelect={props.changeValues}
                
              />
            </Grid>
            
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openOrCloseModalFunction()} color="primary" >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={() => { props.handleConfirmation() }} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default injectIntl(DialogGeneralWithAutoComplete);