import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SharedEvents } from '../../../../joint-plus/controller';
import { actionCreator } from '../../../../redux/helpers/actionCreator';

import './index.scss';

interface Props {
    className?: string;
    placeholder?: string;
    value?: string | number;
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
    options: { id: number, name: string }[];
}

const BATCH_NAME = 'inspector-input';

const Select = (props: Props): ReactElement => {

    const dispatch = useDispatch();
    const [filterInput, setFilterInput] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(props.options);
    const [showDropdown, setShowDropdown] = useState(false);

    const onFocus = (): void => {
        dispatch(actionCreator(SharedEvents.GRAPH_START_BATCH, BATCH_NAME));
    };

    const onBlur = (): void => {
        dispatch(actionCreator(SharedEvents.GRAPH_STOP_BATCH, BATCH_NAME));
    };

    // Lógica de filtragem
    const filterOptions = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setFilterInput(input);
        const filtered = props.options.filter(option =>
            option.name.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredOptions(filtered);
        props.onChange(event as any);
    };

    useEffect(() => {
        return () => {
            onBlur();
        };
    }, []);

    useEffect(() => {
        setFilterInput(props?.options?.find(item => item.id == props?.value)?.name);
    }, [props?.options])

    return (
        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
            <input
                type="text"
                id="filter-input"
                placeholder={props?.placeholder ?? "Digite para filtrar..."}
                value={filterInput}
                onChange={filterOptions}
                onFocus={() => {
                    setShowDropdown(true)
                    onFocus()
                }}
                onBlur={() => {
                    setTimeout(() => setShowDropdown(false), 100)
                    onBlur()
                }}
                className='input'
            />

            {showDropdown && filteredOptions.length >= 0 && (
                <ul className='ul-dropdown'>
                    {
                        filteredOptions.length > 0 
                        ? filteredOptions.map(option => (
                                <li
                                    key={option.id}
                                    onMouseDown={() => {
                                        setFilterInput(option.name);
                                        props.onChange({
                                            target: { value: option.id } as any
                                        } as any);
                                        setShowDropdown(false);
                                    }}
                                    className='li-item-dropdown'
                                >
                                    {option.name}
                                </li>
                            ))
                        : 
                            !filterInput && props?.options?.slice(0, 5).map((option) => (
                                <li
                                    key={option.id}
                                    onMouseDown={() => {
                                        setFilterInput(option.name);
                                        props.onChange({
                                            target: { value: option.id } as any
                                        } as any);
                                        setShowDropdown(false);
                                    }}
                                    className='li-item-dropdown'
                                >
                                    {option.name}
                                </li>
                            ))
                    }
                </ul>
            )}
        </div>
    );
};

export default Select;