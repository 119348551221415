import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import ClearIcon from '@mui/icons-material/Clear';
import * as componentActions from '../../redux/actions/calledflows';
import Api2Talk from "../../services/api2talk";
import { PapiroConsole } from '../../utils/papiroConsole';

class EnableAgents extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;

        let filters = [
            { name: intl.formatMessage({ id: "name" }), id: "name" },
        ];

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            userSelected: null,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            user: null,
            userList: [],
            organizationList: []
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {
        this.setState({ searchValue: event.target.value, page: 1 });
    }

    callSearch() {
        if (this.state.searchValue != ''){
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ searchValue: '', page: 1 })
        this.getItens(1);
    }

    changeValues = (stateName, value, text = '') => {
        
        this.setState({
            user: value
        });
    }

    deleteItem = (data, resolve) => {
        const intl = this.props.intl;
        /*let abl = this.context.can('Delete', 'CalledFlow');
        if (abl == false) {
            const intl = this.props.intl;
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }*/
        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        Api2Talk.delete(`/enableusers/${data.userid}`)
            .then(result => {
                if (result.data.success) {
                    
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "delete.sucess" })
                    });
                    resolve();
                    this.componentDidMount();
                }
                else {
                    this.setState({
                        loading: false, 
                        openNotification: true, 
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };

    componentDidMount() {
        this.getItens(1);
    }

    enableAgents = () => {

        const intl = this.props.intl;
        
        if(this.state.user == null || this.state.user?.id == 0) { 
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.time.entries.registration.user.field" }),
            });
            return;
        }
        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        this.setState({ loading: true });

        PapiroConsole.log(this.state.user?.id)
        PapiroConsole.log("this.state.user?.id")

        let data = {
             userid: this.state.user?.id 
        }
        Api2Talk.post('/enableusers', data )
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "save.success" }),
                        userSelected: ""
                    });
                    this.componentDidMount();
                } else {
                    this.setState({
                        loading: false, 
                        openNotification: true, 
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    getItens(page = 1) {

        let url = `/enableusers/paginate?page=${page}`;

        if (this.state.searchValue != '') {

            url += `&search=${this.state.searchValue}`
        }
        if(Api2Talk == null){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        this.setState({ loading: true });
        
        Api2Talk.get(url)
            .then(result => {
                if (result.data.success) {
                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            username: item.user?.name,
                            useremail: item.user?.email,
                            agentstatus : item.userstatus && item.userstatus.colorhex ?  <div className='m-item-color' style={{ backgroundColor: item.userstatus.colorhex, width: 10, height: 10, borderRadius: '50%', marginLeft:'12px' }}></div> : null
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='false'>
                        <CssBaseline />
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Card className="background-title-search">
                                    <CardContent>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid style={{marginBottom: '15px'}} item xs={8} md={7}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="menu.2talk.enableagents" />}
                                                </Typography>
                                            </Grid>
                                            <Grid style={{marginTop: '15px'}} item xs={4}  md={5}>
                                                <TextField 
                                                    type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({id:"search"})}
                                                    onChange={this.onFieldChange}
                                                />
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight:4, marginLeft:8, marginTop: 5 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>
                                                <Button
                                                    style={{ marginTop: 5 }}
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}
                                        
                                                >
                                                    <ClearIcon />
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid container style={{marginTop: '10px'}} className={classes.mb20} spacing={0}>
                            <Grid container spacing={2} >
                                <Grid item xs={4} sm={4}></Grid>
                                <Grid item xs={6} sm={6}>
                                    <SimpleAutoCompleteSearch 
                                        label={ intl.formatMessage({ id: "menu.users" })} 
                                        options={this.state.userList} 
                                        stateName="user" 
                                        sendfrom2talk={true}
                                        changeSelect={this.changeValues}
                                        loading={this.state.loadingRequestingUsers}
                                        selected={this.state.userSelected}
                                        urlapi={`${Api2Talk.apiurlbase()}/enableusers/search/technician`}
                                        required 
                                    />
                                </Grid>
                                <Grid style={{display: "flex", alignSelf: "center"}} item xs={2} sm={2}>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        size='large'
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        onClick={this.enableAgents}
                                    >
                                        {<FormattedMessage id="add" />}
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </Grid>

                        <Can I='List' a='CalledFlow'>
                            <MyMaterialTable
                                columns={[
                                    { title: intl.formatMessage({ id: "name" }), field: 'username' },
                                    { title: intl.formatMessage({ id: "email" }), field: 'useremail' },
                                    { title: intl.formatMessage({ id: "menu.statuses" }), field: 'agentstatus' },
                                ]}
                                data={this.state.resultList}
                                deleteItem={this.deleteItem}
                            />
                        </Can>

                        <Can I='List' a='CalledFlow'>

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>

                        </Can>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
EnableAgents.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(EnableAgents)));
EnableAgents.contextType = AbilityContext;
