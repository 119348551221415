import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calledorganizationchecklists';
import { bindActionCreators } from 'redux';
import moment from "moment";
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CalledReportImagesDialog from "../../components/called-report-images-dialog";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import * as UserService from '../../services/user.service';
import * as CalledService from '../../services/called.service';
import CardContent from '@mui/material/CardContent';
import MiniMap from '../../components/minimap/fixed'
import { PapiroConsole } from '../../utils/papiroConsole';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { getReportData } from '../../services/called.service'
import { renderToStaticMarkup } from "react-dom/server"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoSmall2 from "../../components/logo/logo-small-2";
import { get, getByOrganizationId } from '../../services/calledreportconfig.service'
import * as FileService from '../../services/attachment.service';
import * as DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser'; 
import parse from "html-react-parser";

const flexStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(197, 198, 208, 0.3)',
    color: '#546e7a',
    fontSize: 14,
    fontWeight: 600,
  },
  body: {
    fontSize: 12,
    color: '#546e7a'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class TabCalledReportInformation extends Component {
  constructor(props) {
    super(props);
    
    const { calleds} = this.props;

    this.displayAssignedInfoMode = 0

    /*if(calleds.called && calleds.called.organization &&  calleds.called.organization.organizationsettings && calleds.called.organization.organizationsettings.displayassignedinfomode ){
      this.displayAssignedInfoMode = calleds.called.organization.organizationsettings.displayassignedinfomode
    }*/

    PapiroConsole.log(calleds)
    PapiroConsole.log("calleds")
    this.state = {
      
      calledReport: {
        token : "",
        calledId : null,
        email : ""
      },
      displayAssignedInfoMode: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization && calleds[`called-${this.urlId}`].organization.organizationsettings && calleds[`called-${this.urlId}`].organization.organizationsettings.displayassignedinfomode ? calleds[`called-${this.urlId}`].organization.organizationsettings.displayassignedinfomode : 0 ,
      techinicalTeamName: calleds.called && calleds.called.techinicalteam? calleds.called.techinicalteam.name : '',  
      openImagesDialog: false,
      calledId: null, 
      title : "",
      organization: "",
      openingDate : "", 
      dueDate : "",
      solutionDate: "",
      user : "",
      userData: {
        document: "",
        email: "",
        phone: ""
      },
      technical : "",
      description : "",
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
      base64image: "",
      category: "",
      status: "",
      signatureName: "",
      observations: '',
      lat: "",
      long: "",
      address: "",
      calledreportconfig: {
        title: '',
        declaration: '',
        displaymaterialsused: false,
        displaychecklist: false,
        displayinitialchecklist: false,
        displayorganizationfields: false,
        displayadditionalcosts: false,
        displaydisplacement: false,
        image: {},
      },
	  logofromconfig:"",
      calledorganizationmaterials: [],
      calledorganizationfields: [],
      calledorganizationfieldsconfig: [],
      calledorganizationchecklists: [],
      initialcalledorganizationchecklists: [],
      additionalcosts: [],
      baduse: false
    };

    const { history } = this.props;
    this.history = history
    this.addresses = null
    this.listItems = null
    this.teste = null

    this.getData = this.getData.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.openImagesDialog = this.openImagesDialog.bind(this);
    this.closeImagesDialog = this.closeImagesDialog.bind(this);
    this.getAccompainmentFile = this.getAccompainmentFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {

    const { calleds } = this.props;
    this.setState({
      calledId: calleds.called.id,
    });
    await this.get()
    await this.getData(calleds)
    await this.getUserData()
    await this.getCalledDescriptionById()
  }

  getUserData = async _ => {
    const { calleds } = this.props;
	
	//console.error(calleds.called.requestinguser?.id);
    let result = await UserService.getById(calleds?.called?.requestinguser?.id)
    if (result && result.success) {
      if (result.data) {
        this.setState(prevState => ({
          userData: {
            document: result.data.document,
            email: result.data.email,
            phone: result.data.phone
          }
        }))
      }
    } else {
      const { intl } = this.props
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  get = async _ => {
    const { calleds } = this.props;
    let result = await getByOrganizationId(calleds.called.organizationid)
    PapiroConsole.log(result)
    PapiroConsole.log("resultado aqui 1")
    if (result && result.success) {
      if (result.data && result.data.id && result.data.id > 0) {

        await this.getCalledAdditionalData({
          calledid: calleds.called.id,
          displaymaterialsused: result.data.displaymaterialsused,
          displaychecklist: result.data.displaychecklist,
          displayinitialchecklist: result.data.displayinitialchecklist,
          displayorganizationfields: result.data.displayorganizationfields,
          displayadditionalcosts: result.data.displayadditionalcosts,
          displaydisplacement: result.data.displaydisplacement
        })

        this.setState({
          calledreportconfig: {
            ...result.data
          }
        });
        PapiroConsole.log(result)
        PapiroConsole.log("resultado aqui")
        if(result.data.image != null){
            let file = await FileService.getFile(result.data.image.hirerid, result.data.image.id, result.data.image.path, result.data.image.filerepositorymethod);
            if(file && file.data && file.data.data) {
              
              this.setState({logofromconfig : file.data.data});
            }
        }

        
      }
    } else {
      const { intl } = this.props
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getCalledAdditionalData = async (data) => {

    let formData = new FormData()

    formData.append("calledid", data.calledid)
    formData.append("displaymaterialsused", data.displaymaterialsused)
    formData.append("displaychecklist", data.displaychecklist)
    formData.append("displayinitialchecklist", data.displayinitialchecklist)
    formData.append("displayorganizationfields", data.displayorganizationfields)
    formData.append("displayadditionalcosts", data.displayadditionalcosts)
    formData.append("displaydisplacement", data.displaydisplacement)

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    let result = await getReportData(formData, config)
    if (result && result.success) {
      if (result.data && result.data.calledorganizationmaterial && result.data.calledorganizationmaterial.length > 0)
        this.setState({ calledorganizationmaterials: result.data.calledorganizationmaterial })
      if (result.data && result.data.calledorganizationfields && result.data.calledorganizationfields.length > 0)
        this.setState({ calledorganizationfields: result.data.calledorganizationfields })
      if (result.data && result.data.calledorganizationfieldsconfig && result.data.calledorganizationfieldsconfig.length > 0)
        this.setState({ calledorganizationfieldsconfig: result.data.calledorganizationfieldsconfig })
      if (result.data && result.data.calledorganizationchecklists && result.data.calledorganizationchecklists.length > 0)
        this.setState({ calledorganizationchecklists: result.data.calledorganizationchecklists })
      if (result.data && result.data.initialcalledorganizationchecklists && result.data.initialcalledorganizationchecklists.length > 0)
        this.setState({ initialcalledorganizationchecklists: result.data.initialcalledorganizationchecklists })
      if (result.data && result.data.additionalcalledcosts && result.data.additionalcalledcosts.length > 0)
        this.setState({ additionalcosts: result.data.additionalcalledcosts })
      if (result.data && result.data.baduse != null)
        this.setState({ baduse: result.data.baduse })
    } else {
      const { intl } = this.props
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  async getAccompainmentFile(fileName, id) {
    //window.open(`${Api.apiurlbase()}/${fileName}`, '_blank');
	const intl = this.props.intl;
	this.setState({ loading: true });
	
	
	let url = await FileService.getFileById(id); 
	this.setState({ loading: false });
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}	



  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      calledReport: {
        ...prevState.calledReport,
        [name]: value,
      },
    }));
  }

  pdfSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    Api.post("calleds/pdfReport", { id: this.state.calledId })
      .then((result) => {
        if(result && result.data && result.data.data){
			
          if(result.data.data[0] && result.data.data[0].url != null){
            window.open(`${result.data.data[0].url}`, '_blank');
          }
          
          this.setState({
            loading: false,
          });
        }
        else
        {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
            result && result.data && result.data.errors
                ? result.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        }

      })
      .catch((err) => {
        PapiroConsole.log(err)
        
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
      });

    
  };
  

  handleSubmit = async (e) => {
    e.preventDefault();
    const intl = this.props.intl;

    this.setState({ loading: true });

    const { calledId, token, email } = this.state.calledReport;
    var Id = parseInt(calledId)
    
    var id = '{"id" :  5}'
    Api.post("calleds/reportCalled", { Id, token, email })
        .then((result) => {
          if (result.data.success) {
            
            this.props.history.push({
              pathname: `/pt/calleds`,
              state: {
                  result: intl.formatMessage({id:"tab.called.report.anything"}),

              }
          })

           this.setState({
              loading: false,
            });
           
          }
        })
        .catch((err) => {
          PapiroConsole.log(err)
          
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });
  };

  async getData(props){

    const { calleds } = this.props;

    

    this.setState({ loading: true });
        const mystyle = {
          subtitleFont : {
            marginTop:'4px',
            fontSize :"14px",
            
          },
      }

        var calledId = calleds.called?.id
        var email = calleds.called?.requestinguser?.email
        var tabReport = true

        /*await Api.post(`/calleds/attachments`,  { calledId, email, tabReport })
        .then((result) => {
          if (result.data) {
            if(result.data.data && result.data.data.length!= undefined && result.data.data.length !=0 ){
            // document.getElementById("attachment").src= `data:image/jpeg;base64,${result.data.data}`
              var base = result.data.data;

              if(base.substring(0,4).toLowerCase() != "data"){
                base = "data:image/png;base64,"+base;
              }
              this.setState({  base64image: base });
            }
          } else {
            PapiroConsole.log(result);
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });*/

        if(calleds.called && calleds.called?.id){
          await Api.get(`/addresses/called/${calleds.called.id}`)
            .then((result) => {
              if (result.data && result.data.data && result.data.data.name && result.data.data.name.length > 0) {
                this.addresses = (
                  <div>
                    <h4 style={{ fontSize :"16px", color:"black" }}>{result.data.data.name} - {result.data.data.street}, {result.data.data.number > 0 ? "Nº"+result.data.data.number : "S/N"}, {result.data.data.complement}. {result.data.data.neighborhood}, {result.data.data.city} - {result.data.data.country}</h4>
                  </div>
                );
              } else {
                PapiroConsole.log(result);
              }
            })
            .catch((err) => {
              PapiroConsole.log(err);
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : this.props.intl.formatMessage({id:"process.error"}),
              });
            });
        }

        await Api.get(`/calledhistorycheck/signature/${calleds.called.id}`)
            .then((result) => {
              if(result && result.data && result.data.data) {
                this.setState({
                  lat: result.data.data.latitude ? result.data.data.latitude.replaceAll("\"", "") : "",
                  long: result.data.data.longitude ? result.data.data.longitude.replaceAll("\"", "") : "",
                  address: result.data.data.addresscomplete ? result.data.data.addresscomplete.replaceAll("\"", "") : "",
                  base64image: result.data.data.attachment != null && result.data.data.attachment.url != null && result.data.data.attachment.url.length > 0 ? `data:image/png;base64,${result.data.data.attachment.url}` : ''
                });
              }else {
                PapiroConsole.log(result);
              }
            })
            .catch((err) => {
              PapiroConsole.log(err);
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : this.props.intl.formatMessage({id:"process.error"}),
              });
            });
        
        await Api.get(`/calleds/signatureinfo/${calleds.called.id}`)
          .then((result) => {
            if(result && result.data && result.data.data) {

              PapiroConsole.log(result.data)
              PapiroConsole.log("result.data")
              this.setState({
                signatureName: result.data.data.namesignature,
                observations: result.data.data.observations,
                displayAssignedInfoMode: result.data.data.organization && result.data.data.organization.organizationsettings ? result.data.data.organization.organizationsettings.displayassignedinfomode : this.state.displayAssignedInfoMode


              });
            }else {
              PapiroConsole.log(result);
            }
          })
          .catch((err) => {
            PapiroConsole.log(err);
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : this.props.intl.formatMessage({id:"process.error"}),
            });
          });

      PapiroConsole.log("=== Props calleds ===")
      PapiroConsole.log(calleds)
      PapiroConsole.log(calleds.called.solutiondate)
      PapiroConsole.log(calleds.called != null && calleds.called.solutiondate != null)
      this.setState({
        calledId: calleds.called?.id,
        title : calleds.called?.title,
        openingDate : moment(calleds.called.openingDate).endOf('day').format("DD/MM/YYYY"),
        dueDate : moment(calleds.called.duedate).endOf('day').format("DD/MM/YYYY h:mm  "),
        solutionDate: calleds.called != null && calleds.called.solutiondate != null ? moment(calleds.called.solutiondate).format("DD/MM/YYYY HH:mm") : "",
        user : calleds.called?.requestinguser?.name,
        technical : calleds.called?.assigneduser?.name,
        description : '',
        organization: calleds.called?.organization?.name,
        category: calleds.called?.organizationcategory.name,
        status: calleds.called?.status.name,
      });

  }

  getCalledDescriptionById = async () => {
    const { calleds, intl } = this.props;

    var result = await CalledService.getCalledDescriptionById(calleds.called.id)
    if(result && result.data && result.data.data) {
      //let desc = renderToStaticMarkup(<p> {result.data.data} </p>)
	  let desc = result.data.data;
      if(result.data.data.trim().startsWith("<p> &lt;h") || result.data.data.trim().startsWith("<p>&lt;div")) {
        desc = renderToStaticMarkup(result.data.data)
      } else if (result.data.data.trim().startsWith("<div style=\"") || result.data.data.trim().startsWith("<div dir=") || result.data.data.trim().startsWith("<p>") || result.data.data.trim().startsWith("<h1>") || result.data.data.trim().startsWith("<h2>") || result.data.data.trim().startsWith("<h3>")) {
        desc = result.data.data
      }
      this.setState({ description: desc, loading: false })
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  validatePassword() {
    
  }

  validatePhone() {
   
  }

  openImagesDialog() {
    this.setState({ openImagesDialog: true });
  }

  closeImagesDialog() {
    this.setState({ openImagesDialog: false });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }
  htmlDecode(content) {
	let e = document.createElement('div');
	e.innerHTML = content;
	
	return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  render() {
    const { classes, userSession, calleds, intl } = this.props;

    var openingDate = moment(calleds.called.openingDate).endOf('day').format("DD/MM/YYYY")
    var dueData = moment(calleds.called.dueData).endOf('day').format("DD/MM/YYYY")
    PapiroConsole.log(calleds)
    PapiroConsole.log(this.state.displayAssignedInfoMode)
    PapiroConsole.log("this.state.displayAssignedInfoMode")
 
    const { calledReport, userData } = this.state;
  
    const mystyle = {

        containerBorder : {
         fontSize :"20px",
         minHeight: '100px', 
         borderRight:'1px solid #e6e6e6'
         
       },
       minContainerBorder : {
        fontSize :"20px",
        minHeight: '30px', 
        borderRight:'1px solid #e6e6e6'
        
      },
   
       subtitleFont : {
         marginTop:'4px',
        fontSize :"14px",
       
        
       },

       dataFont : {
        fontSize :"16px",
        color:"black",
        
        
        
       },
       menuRoot: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: '8px',
      },
      titleMenu: {
        flexGrow: 1,
      },
   
       
  
     }

     if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    return (
    
          
          
          <Container component="main" maxWidth="xl"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                border: "1px solid rgba(0, 0, 0, .3)"
              }}>
            <CalledReportImagesDialog
              intl={this.props.intl} 
              open={this.state.openImagesDialog} 
              handleClose={this.closeImagesDialog}
              accompaniments={this.props.calleds.accompaniments}
              getFile={this.getAccompainmentFile}
              isClient={this.props.isClient}
              classes={classes}
              displayAssignedInfoMode={this.state.displayAssignedInfoMode}
              techinicalTeamName = {this.state.techinicalTeamName}
              />
            <div className={mystyle.menuRoot} style={{ marginBottom: "15px" }}>
              
              <AppBar position="static" style={{
                backgroundColor:"rgba(197, 198, 208, .3)",
                backgroundImage: "linear-gradient(to right, #fff, rgba(197, 198, 208, .5))"
              }}>
                <Toolbar style={{marginTop:'5px', marginBottom:'5px'}}>
                  
                    { (this.state.calledreportconfig && this.state.calledreportconfig.image && this.state.calledreportconfig.image.path && this.state.calledreportconfig.image.path.length > 0
					&& this.state.logofromconfig.length > 0) ? 
					<img src={`${this.state.logofromconfig}`/*`${Api.apiurlbase()}${this.state.calledreportconfig.image.path}`*/} 
					title={intl.formatMessage({id:"tab.called.report.called.report.logo"})} style={{ maxWidth: 165 }} /> : <LogoSmall2/>}

                    <Typography variant="h6" className={classes.title}>
                     
                    </Typography>

                    <div>
                        <div> <p style={{color:'#594D5B' }}> {intl.formatMessage({id:"tab.called.report.called.number"})} </p> </div>
                        <div> <p style={{color:'black' }}>  {this.state.calledId} </p> </div>


                        {(this.props.isClient && this.state.displayAssignedInfoMode == 2) || this.state.displayAssignedInfoMode == 4 ? 
                          <div>
                              <div><p style={{color:'#594D5B' }}> {intl.formatMessage({id:"team"})}</p> </div>
                              <div >
                                <p style={{color:'black'  }}> 
                                
                                    <span>{this.state.techinicalTeamName}</span>
                                </p>
                              </div>
                          </div>
                     
                     : 
                        <div>
                          <div><p style={{color:'#594D5B' }}> {intl.formatMessage({id:"tab.called.report.technician.name"})}</p> </div>
                          <div >
                            <p style={{color:'black'  }}> 
                            
                                <span>{this.state.technical}</span>
                            </p>
                          </div>
                        </div>
                 }
                      

                      

                        
                        
                       
                 
                    </div>
                </Toolbar>
              </AppBar>
            </div>
          <CssBaseline />
          <div className={mystyle.menuRoot}
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                marginBottom: "15px"
              }}>
          <Grid container xs justify="flex-end" spacing={3} style={{marginTop:'25px'}}>
            <Grid item>
              <Button 
                variant="contained"
                color='primary' 
                onClick={this.pdfSubmit}
              >
                {intl.formatMessage({id:"download"})}
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained"
                color='primary' 
                onClick={this.openImagesDialog}
              >
                {intl.formatMessage({id:"photos.and.documents"})}
              </Button>
            </Grid>
          </Grid>
          
          <div className={classes.paper} style={{marginTop:'25px'}} >
            <Avatar className={classes.avatar}>
              {" "}
              <AssignmentIcon />{" "}
            </Avatar>
            <Typography component="h1" variant="h5">
              {" "}
              {(this.state.calledreportconfig && this.state.calledreportconfig.title && this.state.calledreportconfig.title.length > 0) ? this.state.calledreportconfig.title : intl.formatMessage({id:"call report"}) }{" "}
            </Typography>
            <Typography component="h4">
              {" "}
              {intl.formatMessage({id:"representative"})} {(calleds && calleds.called && calleds.called.organization && calleds.called.organization.name && calleds.called.organization.name.length > 0) ? calleds.called.organization.name : "" }{" "}
            </Typography>
          </div>
        {/* <div style={{border: '1px solid red'}}>
              <h4>{intl.formatMessage({id:"general.data"})}</h4>
              <div style={{display:'flex'}}>
                  <div>
                      nome do segurado
                  </div>
                  <div>
                      endereço da ocorrência
                  </div>
                  <div>
                      endereço da ocorrência
                  </div>
              </div>
      </div>*/}
                <div style={{marginTop :'30px', marginBottom: '40px'}}>
                  <h4>{intl.formatMessage({id:"client.data"})}</h4>
                  <Divider />
                  <Grid container spacing={3}  style={{marginTop: '20px', border : '1px solid #e6e6e6', borderRadius:'10px'}}>
                      <Grid item xs={3} style={mystyle.containerBorder} >
                        <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"tab.called.report.username"})}</h4>
                        <h4 style={mystyle.dataFont}>{this.state.user}</h4>
                      </Grid>
                      {(userData.document && userData.document.length > 0) && (<Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({ id: "document" })}</h4>
                          <h4 style={mystyle.dataFont}>{userData.document}</h4>
                      </Grid>)}
                      {(userData.email && userData.email.length > 0) && (<Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({ id: "email" })}</h4>
                          <h4 style={mystyle.dataFont}>{userData.email}</h4>
                      </Grid>)}
                      {(userData.phone && userData.phone.length > 0) && (<Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({ id: "phone" })}</h4>
                          <h4 style={mystyle.dataFont}>{userData.phone}</h4>
                      </Grid>)}
                  </Grid>

              </div>
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                  <h4>{intl.formatMessage({id:"general.data"})}</h4>
                  <Divider />
                  <Grid container spacing={3}  style={{marginTop: '20px', border : '1px solid #e6e6e6', borderRadius:'10px'}}>
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"address"})}</h4>
                          {this.addresses}
                      </Grid>
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"categories"})}</h4>
                          <h4 style={mystyle.dataFont}>{this.state.category}</h4>
                      </Grid>
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"status.of.called"})}</h4>
                          <h4 style={mystyle.dataFont}>{this.state.status}</h4>
                      </Grid>
                  </Grid>
              </div>
              <div style={{marginBottom: '50px'}}>
                  <h4 >{intl.formatMessage({id:"called.list.data.edit.called.title"})}</h4>
                  <Divider />
                  <Grid container spacing={3}  style={{marginTop: '20px', border : '1px solid #e6e6e6', borderRadius:'10px'}}>

                      <Grid item xs={12}  >
                        <h4 style={mystyle.dataFont}>{this.state.title}</h4>
                      </Grid>
                    
                  </Grid>

              </div>
              <div style={{marginBottom: '50px'}}>
                <h4 >{intl.formatMessage({id:"description"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px'}}>
                  <Grid item xs={12}>
						{/*<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.description) }}></div>*/}
						{/*<div> { ReactHtmlParser (this.state.description) } </div>*/}
						{<p><div> { parse (this.state.description) } </div></p>}
						</Grid>
                </Grid>
              </div>

              { (this.state.calledorganizationfields && this.state.calledorganizationfields.length > 0) && (<div style={{marginBottom: '50px', marginTop: '80px'}}>
                <h4>{intl.formatMessage({id:"organization.custom.fields"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px'}}>
                {this.state.calledorganizationfields.map((item, i) => {
                  if (item && item.value && item.value.length > 0) {
                    return <Grid item xs={3} style={mystyle.containerBorder} >
                      <h4 style={mystyle.subtitleFont}>{(item && item.organizationfield && item.organizationfield.name && item.organizationfield.name.length > 0) ? item.organizationfield.name : ""}</h4>
                      <h4 style={mystyle.dataFont}>{(item && item.value && item.value.length > 0) ? item.value : ""}</h4>
                    </Grid>
                  }
                })}
                </Grid>
              </div>)}
              {this.state.calledorganizationfieldsconfig.map((item, i) => {
                if (item && item.organizationfieldreportblockitems && item.organizationfieldreportblockitems.length > 0) {
                  return <div style={{marginBottom: '50px', marginTop: '80px'}}>
                    <h4>{item.title}</h4>
                    <Divider />
                    <Grid container spacing={3}  style={{marginTop: '20px'}}>
                      {item.organizationfieldreportblockitems.map((blockItem, i) => {
                        if (blockItem && blockItem.organizationfield && blockItem.organizationfield.calledorganizationfields && blockItem.organizationfield.calledorganizationfields.length > 0) {
                          return <Grid item key={blockItem.id} xs={6} style={mystyle.containerBorder} >
                            <h4 style={mystyle.subtitleFont}>{(blockItem && blockItem.organizationfield && blockItem.organizationfield.name && blockItem.organizationfield.name.length > 0) ? blockItem.organizationfield.name : ""}</h4>
                            <h4 style={mystyle.dataFont}>{blockItem?.organizationfield?.calledorganizationfields[0]?.value}</h4>
                          </Grid>
                        }
                      })}
                    </Grid>
                  </div>
                }
              })}

              { (this.state.additionalcosts && this.state.additionalcosts.length > 0) && (<div style={{marginBottom: '50px', marginTop: '80px'}}>
                <h4>{intl.formatMessage({id:"services"})}</h4>
                <Divider />
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label={intl.formatMessage({id:"additional.services.table"})}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">{intl.formatMessage({id:"item"})}</StyledTableCell>
                        <StyledTableCell align="left">{intl.formatMessage({id:"type"})}</StyledTableCell>
                        <StyledTableCell align="left">{intl.formatMessage({id:"reason"})}</StyledTableCell>
                        <StyledTableCell align="left">{intl.formatMessage({id:"observation"})}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.additionalcosts.map((item, i) => {
                        return <TableRow>
                          <StyledTableCell align="left">{(item && item.additionalcalledcostitem) ? item.additionalcalledcostitem.name : ""}</StyledTableCell>
                          <StyledTableCell align="left">{(item && item.additionalcalledcostitemtype) ? item.additionalcalledcostitemtype.name : ""}</StyledTableCell>
                          <StyledTableCell align="left">{(item && item.additionalcalledcostitemreason) ? item.additionalcalledcostitemreason.name : ""}</StyledTableCell>
                          <StyledTableCell align="left">{(item && item.observation) ? item.observation : ""}</StyledTableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>)}

              {(this.state.calledorganizationmaterials && this.state.calledorganizationmaterials.length > 0) && (
                <div style={{marginBottom: '50px', marginTop: '80px'}} >
                  <h4>{intl.formatMessage({id:"installed.materials"})}</h4>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label={intl.formatMessage({id:"additional.services.table"})}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">{intl.formatMessage({id:"product"})}</StyledTableCell>
                          <StyledTableCell align="left">{intl.formatMessage({id:"amount"})}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.calledorganizationmaterials.map((item, i) => {
                          return <TableRow>
                            <StyledTableCell align="left">{(item && item.organizationmaterial) ? item.organizationmaterial.name : ""}</StyledTableCell>
                            <StyledTableCell align="left">{(item && item.quantity) ? item.quantity : 0}</StyledTableCell>
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider style={{ marginTop: 10 }} />
                  <h4 style={mystyle.dataFont}>{intl.formatMessage({id:"tab.called.report.bad.material.usage"})}</h4>
                  <h4 style={mystyle.subtitleFont}>{this.state.baduse ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"})}</h4>
                </div>
              )}

              {(this.state.initialcalledorganizationchecklists && this.state.initialcalledorganizationchecklists.length > 0) && (
                <div style={{marginBottom: '50px', marginTop: '80px'}} >
                  <h4>{intl.formatMessage({id:"tab.called.report.pre.service.check"})} &nbsp;&nbsp;&nbsp;(<CheckIcon />) OK &nbsp;&nbsp;&nbsp;(<ClearIcon />) {intl.formatMessage({id:"not.working"})}</h4>
                  <Divider />
                  <Grid container alignItems="center" spacing={3}  style={{marginTop: '20px'}}>
                    {this.state.initialcalledorganizationchecklists.map((item, i) => {
                      return <Grid item key={item.id} xs={3} style={mystyle.minContainerBorder} >
                        <Grid container spacing={0} >
                          <Grid item xs={2}>
                            { ((item && item.evidencetext && item.evidencetext.length > 0) || (item && item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0)) ? <CheckIcon /> : <ClearIcon /> }
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component="h4" style={{ marginTop: 0 }}>{item?.organizationchecklist?.title}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    })}
                  </Grid>
                </div>
              )}
              
              {(this.state.calledorganizationchecklists && this.state.calledorganizationchecklists.length > 0) && (
                <div style={{marginBottom: '50px', marginTop: '80px'}} >
                  <h4>{intl.formatMessage({id:"tab.called.report.post.service.check"})} &nbsp;&nbsp;&nbsp;(<CheckIcon />) OK &nbsp;&nbsp;&nbsp;(<ClearIcon />) {intl.formatMessage({id:"not.working"})}</h4>
                  <Divider />
                  <Grid container alignItems="center" spacing={3}  style={{marginTop: '20px'}}>
                    {this.state.calledorganizationchecklists.map((item, i) => {
                      return <Grid item key={item.id} xs={3} style={mystyle.minContainerBorder} >
                        <Grid container spacing={0} >
                          <Grid item xs={2}>
                            { ((item && item.evidencetext && item.evidencetext.length > 0) || (item && item.calledorganizationchecklistattachments && item.calledorganizationchecklistattachments.length > 0)) ? <CheckIcon /> : <ClearIcon /> }
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component="h4" style={{ marginTop: 0 }}>{item?.organizationchecklist?.title}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    })}
                  </Grid>
                </div>
              )}

              { this.state.base64image.length > 0 && (
              <div style={{marginBottom:'40px'}}>
                  <h4>{intl.formatMessage({id:"user.signature"})}</h4>
               
                  
                  <Grid container spacing={3}  style={{marginTop: '20px'}}>
        

                     
                     <Grid item xs={12} style={mystyle.containerBorder} >
                        <img id ="attachment" width="170px" src={this.state.base64image}/>
                     </Grid>
                     
                     
                     { this.state.lat.length > 0 && (
                      <Grid item xs={3}  >
                        <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"tab.called.report.signature.location"})}</h4>
                        {
                          this.state.lat.length > 0 && this.state.long.length > 0 && (
                            <h4 style={mystyle.dataFont}>({this.state.lat}, {this.state.long}) {this.state.address}</h4>
                          )
                        }
                     </Grid>
                     )}
                     { this.state.lat.length > 0 && (
                      <Grid item xs={12} >
                       <Card style={{
                         width: 200, minWidth: 200, maxWidth: 200,
                         height:200, minHeight: 200, maxHeight: 200
                       }}>
                         <CardContent>
                           <MiniMap
                             style={{
                               width: 200, minWidth: 200, maxWidth: 200,
                               height:200, minHeight: 200, maxHeight: 200
                             }}
                             lat={this.state.lat}
                             long={this.state.long}
                             address={this.state.address} />
                         </CardContent>
                       </Card>
                      </Grid>
                    )}
                     

                    <Grid item xs={12} style={mystyle.containernoBorder} >
                      <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"tab.called.report.signer.name"})}</h4>
                      <h4 style={mystyle.dataFont}>{ this.state.signatureName && this.state.signatureName.length > 0 ? this.state.signatureName : this.state.user}</h4>
                    </Grid>
                    { (this.state.observations && this.state.observations.length > 0) && (<Grid item xs={12} style={mystyle.containernoBorder} >
                      <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"observations"})}</h4>
                      <h4 style={mystyle.dataFont}>{ this.state.observations}</h4>
                    </Grid>) }
                  </Grid>
              </div>
              )}
              <div style={{marginBottom:'40px'}}>
                  <h4 >{intl.formatMessage({id:"called.report.config.declaration"})}</h4>
                  <Grid container spacing={3}  style={{marginTop: '20px', marginLeft:'10px'}}>
                  {(this.state.calledreportconfig && this.state.calledreportconfig.declaration && this.state.calledreportconfig.declaration.length > 0) ? this.state.calledreportconfig.declaration : intl.formatMessage({id:"tab.called.report.term.agree"}) } &nbsp;&nbsp;<a href="https://dpo.privacytools.com.br/policy-view/Pk6G3wvxj/3/policy/pt_BR?s=1640698153824" target="_blank">{intl.formatMessage({id:"useterm"})}</a>
                  </Grid>
              </div>
              <div style={{marginBottom:'40px'}}>
                  <h4 >{intl.formatMessage({id:"tab.called.report.due.date"})} </h4>
                  <Grid container spacing={3}  style={{marginTop: '20px', marginLeft:'10px'}}>
                  {this.state.solutionDate}
                  </Grid>
              </div>
            </div>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      

      
      
    );
  }
}

TabCalledReportInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);;

//export default connect(mapStateToProps)(withStyles(Styles)(CalledReportInformation));
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(TabCalledReportInformation))));