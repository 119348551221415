export default function organizationdepartaments(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organizationdepartament: action.organizationdepartament,
            }
        case 'ADD_CURRENT_ORGANIZATION':
          return {
              ...state,
              organizationdepartament: action.organizationdepartament,
              organization: action.organization,
          }
        default:
            return state;
    }
}
