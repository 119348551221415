import { shapes } from '@joint/plus';
import React, { ReactElement, useCallback, useState } from 'react';

import Input from '../Input/Input';
import { useBaseInspector } from './useBaseInspector';

interface Props {
  cell: shapes.app.TalkAttendant;
}

const cellProps = {
  label: ['attrs', 'label', 'text'],
};

const TalkAttendantInspector = (props: Props): ReactElement => {

    const { cell } = props;

    const [label, setLabel] = useState<string>(cell.prop(cellProps.label));

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>
        </>
    );
};

export default TalkAttendantInspector;
