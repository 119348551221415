import { PapiroConsole } from "../../utils/papiroConsole"

export default function calledservicesettingsorganizationfields(state = [], action) {
  PapiroConsole.log("=== calledservicesettingsorganizationfield reducer ===")
  PapiroConsole.log(action)

  switch (action.type) {
    case 'ADD_CALLED_SERVICE_SETINGS_ORGANIZATION_FIELD_LIST':
      return {
        ...state,
        calledservicesettingsorganizationfieldslist: action.calledservicesettingsorganizationfieldslist,
      }
    case 'ADD_CALLED_SERVICE_SETINGS_ORGANIZATION_FIELD':
      return {
        ...state,
        calledservicesettingsorganizationfield: action.calledservicesettingsorganizationfield
      }
    default:
      return state;
  }
}