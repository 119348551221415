import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ClearIcon from '@mui/icons-material/Clear'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'
import Search from '@mui/icons-material/Search'
import { withStyles } from '@mui/styles'
import DialogConfirmation from '../../components/dialog-confirmation'
import { AbilityContext } from '../../config/ability-context'
import AddIcon from '@mui/icons-material/Add'
import Api from '../../services/api'
import { Can } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/calledservicesettings'
import CustomizedSnackbars from '../../components/material-snackbars'
import { evaluateSession } from '../../utils/sessionUtils'
import Footer from '../../components/footer'
import { FormattedMessage, injectIntl } from 'react-intl'
import HeaderMenu from '../../components/header-menu'
import MyMaterialTable from '../../components/table'
import { Overlay } from '../../styles/global'
import { PapiroConsole } from '../../utils/papiroConsole'
import { paginate, paginateByOrganization, deleteItem } from '../../services/calledservicesettings.service'
import { Styles } from '../../styles/material-styles'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { Delete } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'


class CalledServiceSettingsList extends Component {
  constructor(props) {
    super(props)

    const { userSession } = this.props
    evaluateSession(userSession)

    let fromProps = ""
    if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
      fromProps = this.props.from

    let filters = [
      { name: "Palavras-Chaves", id: "keyword" },
    ];

    this.state = {
      count:0,
      loading: false,
      notificationMessage: '',
      notificationVariant: 'error',
      openNotification: false,
      page:1,
      pageEnd:0,
      pageStart:0,
      itemsPerPage: 10,
      resultList: [],
      searchValue: '',
      filterSelected: 0,
      filters: filters,
      totalPages:0,
      from: fromProps,
      organizationList: [],
      deleteItemData: null,
      deleteItemConfirmationDialogOpen: false,
    }
  }

  handleOpenDeleteItemConfirmationDialog = _ => this.setState({ deleteItemConfirmationDialogOpen: true })

  handleCloseDeleteItemConfirmationDialog = _ => this.setState({ deleteItemConfirmationDialogOpen: false, deleteItemData: null })

  handleClickCreateNewItem = async _ => {
    await this.props.addCurrent(null)
    this.props.history.push(`/${this.props.match.params.lang}/calledservicesettings-edit`);
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({filterSelected : value})
  }

  callSearch = _ => {
    if(this.state.searchValue !== '')
      this.getItens(this.state.page)
  }

  clearFields = async _ => {
    await this.setState({ searchValue: '', page: 1, itemsPerPage: 10 })
    this.getItens(1)
  }

  closeNotification = () => {
    this.setState({ openNotification: false })
  }

  async componentDidMount() {
    await this.props.addCurrent(null)
    await this.getItens(1)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload")
      this.componentDidMount()
  }

  onChangePaginate = (e, page) => {
    this.getItens(page)
    this.setState({ page: page })
  }

  onChangeField = (event) => {  
  this.setState({
    [event.target.name]: event.target.value,
    page: 1
  })
 }

 deleteItemConfirmation = (item) => {
  if (item != null) {
    if (item.system != null && item.system == true) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'Você não pode excluir um formulário de sistema!'
      })
    } else {
      this.setState({ deleteItemData: item })
      this.handleOpenDeleteItemConfirmationDialog()
    }
  }
 }

 deleteItem = async _ => {
  if (this.state.deleteItemData != null) {
    this.setState({ loading: true })

    let result = await deleteItem(this.state.deleteItemData.id)
    if (result && result.success) {
      let resultList = this.state.resultList
      resultList = resultList.filter(element => element.id != this.state.deleteItemData.id)
      this.setState({
        deleteItemData: null,
        resultList: resultList,
        loading: false,
        deleteItemConfirmationDialogOpen: false,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: this.props.intl.formatMessage({ id: "delete.sucess" }),
      })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }
 }

  setPagination = (count, page) => {                                      
    let totalPage = Math.ceil (count/10);
    this.setState({ totalPages: totalPage })
    if(count > 0){
      if(page === 1) {
        if(count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if(count >= page * 10)
          this.setState({pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }

  getItens = async (page = 1) => {
    this.setState({ loading: true })

    let result = await paginate(page, this.state.itemsPerPage)
    if (result && result.success) {
      let frontCalledServiceUrl = Api.frontCalledServiceUrl()
      let l = this.props.match.params.lang

      let count = result.count;
      this.setState({ count: count });
      this.setPagination(count, page);
      if(result.data.length === 0)
        this.emptyArray = true
      else
        this.emptyArray = false

      const formattedList = result.data.map(item => {
        let routeName = item.route
        if (routeName == null || (routeName != null && routeName.length == 0))
          routeName = `form/${item.id}`

        return {
          ...item,
          OrganizationText: item.organization && item.organization.name ? item.organization.name : '',
          RouteText: `${frontCalledServiceUrl}${l}/${routeName}`
        }
      })
      this.setState({ loading: false, resultList: formattedList })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  render () {
    const { classes, headerMenu, actionsHeaderMenu, intl } = this.props

    if (Api == null) {
      var l = this.props.match.params.lang
      document.location.href = `/${l}/unavailable`
    }
    
    return (
      <div className={classes.root}
        style={{
          paddingLeft: 0,
          marginLeft: 0
        }}
      >
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Container
            component='main'
            maxWidth='xl'
            style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}
          >
            <CssBaseline />
            <Dialog
              open={this.state.deleteItemConfirmationDialogOpen}
              onClose={this.handleCloseDeleteItemConfirmationDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Excluir formulário externo</DialogTitle>
              <DialogContent>
                <DialogContentText>Você tem certeza que deseja excluir este formulário externo?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDeleteItemConfirmationDialog} color="primary">
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button onClick={this.deleteItem} color="primary" autoFocus>
                  {intl.formatMessage({id:"yes"})}
                </Button>
              </DialogActions>
            </Dialog>
            <Grid
              container
              spacing={6}
              sx={{ marginBottom: '15px' }} >
              <Grid item xs={6} sm={6} md={6} lg={6} >
                <Can I="Add" a="CalledServiceSettings">
								  <Button
									  variant="contained"
									  color="secondary"
									  size="large"
									  className={classes.button}
									  startIcon={<AddIcon />}
                    onClick={this.handleClickCreateNewItem} >
									  Novo formulário externo
								  </Button>
								</Can>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} />
            </Grid>
            <Grid
              container
              spacing={6}>
              <Grid
                item
                sm={12}
                xs={12}
              >
                <Card className="background-title-search">
                  <CardContent>
                    <Grid
                      alignItems="flex-end"
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <Typography variant="h5">
                          <FormattedMessage id="called.service.settings" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Can
              I="List"
              a="CalledServiceSettings"
            >
              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {
                  let abl = this.context.can('Edit', 'CalledServiceSettings')
                  if (abl === false) {
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: 'error',
                      notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
                    })
                    return false
                  }
                  this.props.addCurrent(rowData)
                  this.props.history.push({
                    pathname: `/${this.props.match.params.lang}/calledservicesettings-edit`
                  })
                }}
                action={[
                  {
                    icon: 'delete',
                    tooltip: 'Excluir formulário externo',
                    onClick: (event, rowData) => this.deleteItemConfirmation(rowData)
                  }
                ]}
                columns={[
                  { title: 'Id', field: 'id', width: 40 },
                  { title: intl.formatMessage({id:"title"}), field: 'title' },
                  { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationText' },
                  { title: 'Rota', field: 'RouteText' },
                ]}
                data={this.state.resultList}
              />
            </Can>
            <Can
              I="List"
              a="CalledServiceSettings"
            >
              <Grid
                container
                style={{
                  marginBottom: 12,
                  marginTop: 8
                }}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <Pagination
                    count={this.state.totalPages}
                    onChange={this.onChangePaginate}
                    page={this.state.page}
                  />
                </Grid>
                <Grid
                  item
                  style={{ marginTop: 6 }}
                  md={3}
                  xs={12}
                >
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color="primary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              isOpen={this.state.openNotification}
              message={this.state.notificationMessage}
              toClose={this.closeNotification}
              variant={this.state.notificationVariant}
            />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

CalledServiceSettingsList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledServiceSettingsList)))

CalledServiceSettingsList.contextType = AbilityContext