import React from 'react'
import ReactQuill from 'react-quill'; // ES6
import PropTypes from "prop-types";
import { useState } from 'react';
const { forwardRef, useRef, useImperativeHandle } = React;

//function RichTextArea(props) {
export const RichTextArea = forwardRef((props, ref) => {

  const quill = useRef();
  const [description, setDescription] = useState("")

  const handleChange = (value) => {
    value && value.length > 0 ? setDescription(value) : setDescription("")
    props.saveChanges(description)
  }

  useImperativeHandle(ref, () => ({

    setValue(value) {
	  //quill.current.editor.insertText(0, value+" ");
	  const delta = quill.current.editor.clipboard.convert(value)

	  quill.current.editor.updateContents(delta, 'silent')
    }

  }));  

  function saveChanges(){

  }
  
  return (
    <ReactQuill
      id={props.id}
	  ref={quill}
      name={props.name}
      modules={props.modules}
      formats={props.formats}
      value={description}
      style={ props.style ? props.style : { minHeight: 200 }}
      readOnly={props.readOnly}
      InputLabelProps={{ shrink: description && description.length > 0 ? true : false }}
      fullWidth
      required
      variant="outlined"
      onChange={handleChange}
      onBlur={saveChanges} />
  )
});

RichTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  modules: PropTypes.object.isRequired,
  formats: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func.isRequired,
  style: PropTypes.object
}

//export default RichTextArea;