import Api from './api';


  export const getDataByConfigId = async (id) => { 
    let data = [];
    await Api.get('/configtwofactorauthentication/by-config/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
 
