import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import Rating from '@mui/material/Rating';
import CustomizedSnackbars from "../../components/material-snackbars";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { FormattedMessage, injectIntl } from 'react-intl'

class NpsEmailRating extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null
    var rating = 0
    var token = ""
    var titleNps = ""
    var descriptioNps =""
    if (this.props.match && this.props.match.params &&  this.props.match.params.type  ) {
        rating = this.props.match.params.type
        if(this.props.match.params.token){
          token = this.props.match.params.token
        }
        if(this.props.match.params.title){
          titleNps = this.props.match.params.title
        }
        if(this.props.match.params.description){
          descriptioNps = this.props.match.params.description
        }
        
    }
    
    this.state = {
      
      rating : rating,
      description : "",
      token : token, 
      disabledButtons : true,
      titleNps : titleNps,
      descriptioNps : descriptioNps,
      colors: {
        bgColor0: "#B32227",
        bgColor1: "#D42127",
        bgColor2: "#F25123",
        bgColor3 : "#F66E24",
        bgColor4 : "#FAA723",
        bgColor5 : "#FECA28",
        bgColor6 : "#ECDB12",
        bgColor7: "#E8E73D",
        bgColor8: "#C6D928",
        bgColor9: "#AFD037",
        bgColor10: "#65B64D",
        lastColorChanged: "",
        lastpropertyChanged : ""
      },
    
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.boxClick = this.boxClick.bind(this);
    

    this.resendEmail = this.resendEmail.bind(this);

  }

  

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    if (this.props.match.params.token && this.props.match.params.type  ) {

      console.log("parametros aqui")
      console.log(this.props)

      console.log(this.state.rating)
      var propertyColor = "bgColor"+this.state.rating
      this.setState(prevState => ({
        colors: {

          ...prevState.colors,
          lastColorChanged : this.state.colors[propertyColor],
          lastpropertyChanged : propertyColor,
          [propertyColor]: "#1B98E0",
          
        }
        }));
 

      this.getNps(this.props.match.params.token);
    }

    console.log(this.props)
    console.log("props aqui")

  
  }

  getNps(token) {
    const intl = this.props.intl;

    this.setState({ loading: true });
    Api.get(`/nps/email/${token}`)
      .then(result => {
        if (result.data.success) {
          console.log(result)
          console.log("aqui")
          
          var title = ""
          var description = ""
          if(result.data.data && result.data.data.nps ){
            
            title = result.data.data.nps.npstitle
            description = result.data.data.nps.npsdescription
          }

          this.setState({
            disabledButtons : false,
            loading: false,
            titleNps : title,
            descriptioNps : description
            
          }); 
        
          
        }
        else{
          this.setState({
            disabledButtons : true,
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          }); 
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true,disabledButtons : true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });

        if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
        {
          
            if(err.response.data.errors[0] == this.props.intl.formatMessage({id:"nps.email.rating.link.expired.feedback"})){
            
              this.addButtonExpiredToken()
            }
        }
       // document.getElementById('submit').classList.add("Mui-disabled");
      });
    this.setState({ loading: false });
  };

  componentDidUpdate(_, prevState) { }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
  
    this.setState({ [name]: value }); 
    
  }

  addButtonExpiredToken(){
    const intl = this.props.intl;
    
  const { classes } = this.props;
  this.ButtonExpired =   
        <Button
      
        variant="contained"
        color="primary"
        className={classes.submit}
        
        onClick={this.resendEmail}
        style={{width: "300px",marginRight:'16px'}}
 
      >
        {intl.formatMessage({id:"resend.feedback.link"})}
      </Button>

  this.setState({ buttonRender: true, loading: false}, () => {
    var l = this.props.match.params.lang;
    document.location.href=`/${l}/`
  });
  
 

}

async resendEmail(){
  const intl = this.props.intl;

  this.setState({ loading: true});

  console.log("entrei aqui")

Api.put(`feedback/resendFeedback/${this.props.match.params.token}`)
  .then(result => {
    if (result.data.success) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "success",
        notificationMessage: this.props.intl.formatMessage({id:"nps.email.rating.feedback.link.sent"}),
        disabledResendButton : true
      });

      
    }
  })
  .catch(err => {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: "error",
      disabledResendButton : true,
      notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      
    });
  })

  
  

}

checkValidations() {
 const intl = this.props.intl;

  if(this.state.description =="" ){

    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: 'error',
      notificationMessage: this.props.intl.formatMessage({id:"nps.email.rating.missing.comment"})

    });

    return false
    
  }
 
  return true

  
}


  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });
    
    const intl = this.props.intl;
    const { rating, description, token} = this.state;

    var returnReponse = this.checkValidations();

    if(returnReponse == true){

      if (rating === 0 || rating == undefined || rating == null)  {
      
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: this.props.intl.formatMessage({id:"nps.email.rating.missing.nps.note"}),
        });
  
      } else {
       
        const { history } = this.props;
  
  
          Api.put("/nps/email-nps", { Description: description, AccessToken: token, Rating: rating })
            .then(result => {
              if (result.data.success) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "success",
                  notificationMessage: this.props.intl.formatMessage({id:"nps.email.rating.nps.search.success"})
                });
                setTimeout(function () { history.push("/"); }, 3000);
              }
            })
            .catch(err => {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              if(err.response && err.response.data && err.response.data.errors &&  err.response.data.errors[0])
              {
                
                  if(err.response.data.errors[0] == this.props.intl.formatMessage({id:"nps.email.rating.link.expired.nps"})){
                  
                    this.addButtonExpiredToken()
                  }
              }
            })
        
      }

    }

   

  };
  closeNotification() {
    this.setState({ openNotification: false });
  }

  boxClick(e,id, color) {
    console.log(e)
    console.log(id)
    const name = e.currentTarget.dataset.id ;
    console.log(name)
    console.log("nome aqui")

    
    this.setState(prevState => ({

      rating: id,
      colors: {

        ...prevState.colors,
        [name]: "#1B98E0",
        [this.state.colors.lastpropertyChanged] : this.state.colors.lastColorChanged,
        lastColorChanged : color,
        lastpropertyChanged : name,
       
        
      }
      }));
  }
  render() {
    const intl = this.props.intl;
    console.log(this.state)

    const styles = {

      regularFont : {
       color: "black",
       fontWeight: "700",
       margin: "0px",
       padding: "0px",
     },
 
     grayFont : {
       margin: "0px",
       padding: "0px",
       
       color: "#909497 ",
       fontSize: "15px",
       fontWeight: "500",
     },
 
      alignMiddle : {
     
       verticalAlign : "middle"
     },

   }
    const { classes } = this.props;
   

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="md">

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}
         
        <h4 style={{fontSize:'20px'}}> {this.state.titleNps} </h4> 

        <h6 style={{color:"rgb(204, 204, 204)", marginTop:"18px", marginBottom:'36px'}}> {this.state.descriptioNps}</h6>

        <form className={classes.form} onSubmit={this.handleSubmit}>
          <Grid container spacing={2} direction="column"
            alignItems="center"
            justifyContent="center" >

           {/* <Grid item xs={12} sm={12} container alignItems="flex-start" justify="left" direction="row" >
              <Rating 
                max={10}
                style={{fontSize: "45px"}}
                name="rating"
                required

                value={this.state.rating}
                onChange={(event, newValue) => {
                  this.setState({
                    rating: newValue
                  });
                }}
              />
              </Grid>*/}
             
                  <ul style={{listStyleType:"none", marginBottom:'18px'}}>
                    
                    <li data-id="bgColor0" onClick={(e) => { this.boxClick(e, 0,"#B32227");}} style={{ cursor : "pointer",borderColor:this.state.colors.bgColor0,  marginRight:"5px", width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#B32227"}}  ><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>0</p></li>
                    <li data-id="bgColor1" onClick={(e) => { this.boxClick(e, 1, "#D42127");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor1,  marginRight:"5px", width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#D42127"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>1</p></li>
                    <li data-id="bgColor2" onClick={(e) => { this.boxClick(e, 2, "#F25123");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor2, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#F25123"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>2</p></li>
                    <li data-id="bgColor3" onClick={(e) => { this.boxClick(e, 3, "#F66E24");}}  style={{cursor : "pointer", borderColor:this.state.colors.bgColor3, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#F66E24"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>3</p></li>
                    <li data-id="bgColor4" onClick={(e) => { this.boxClick(e, 4,"#FAA723");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor4,marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#FAA723"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>4</p></li>
                    <li data-id="bgColor5" onClick={(e) => { this.boxClick(e, 5,"#FECA28");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor5, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#FECA28"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>5</p></li>
                    <li data-id="bgColor6" onClick={(e) => { this.boxClick(e, 6,"#ECDB12");}}  style={{cursor : "pointer", borderColor:this.state.colors.bgColor6, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#ECDB12"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>6</p></li>
                    <li data-id="bgColor7" onClick={(e) => { this.boxClick(e, 7,"#E8E73D");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor7,  marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#E8E73D"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>7</p></li>
                    <li data-id="bgColor8" onClick={(e) => { this.boxClick(e, 8,"#C6D928");}}  style={{cursor : "pointer", borderColor:this.state.colors.bgColor8,  marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#C6D928"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>8</p></li>
                    <li data-id="bgColor9" onClick={(e) => { this.boxClick(e, 9,"#AFD037");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor9, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#AFD037"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>9</p></li>
                    <li data-id="bgColor10" onClick={(e) => { this.boxClick(e, 10,"#65B64D");}} style={{cursor : "pointer", borderColor:this.state.colors.bgColor10, marginRight:"5px",width:"44px", height:"44px", display:"inline-block", borderRadius:"6px", borderWidth:"4px", borderStyle:"solid", backgroundColor:"#65B64D"}}><p style={{color:"black", textAlign:"center", marginTop:"6px"}}>10</p></li>

                    
                </ul>
            </Grid>

            <Grid container spacing={2}>
              
              <Grid item xs={12}>
              <h6 >{this.props.intl.formatMessage({id:"nps.email.rating.grade.justification"})}</h6>
                <TextField
                  variant="outlined"
                  id="outlined-multiline-static"
                  label={this.props.intl.formatMessage({id:"coment"})}
                  name="description"
                  disabled ={this.state.disabledButtons}
                  multiline
                  rows="8"
                  fullWidth
                  variant="outlined"
                  onChange={this.handleInputChange}
                />
              </Grid>

            </Grid>
            
            
          
          <div style={{textAlign: "right"}}>

         
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              id="submit"
              style={{width: "200px"}}
              disabled={this.state.disabledButtons}
              
            >
              {intl.formatMessage({id:"send"})}
            </Button>
          </div>
            
          </form>
        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

NpsEmailRating.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(Styles)(NpsEmailRating));
