import Api from './api';
export const getOrganizationAccountEmailConfigs = async () => { 
  let data = [];
  await Api.get('/organizationaccountemailconfigs')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getAccountSends = async () => { 
  let data = [];
  await Api.get('/organizationaccountemailconfigs')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getOrg = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getDados = async (id, type) => { 
  let data = [];
  await Api.get(`/organizationaccountemailconfigs/type?id=${id}&type=${type}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getAccountReceiveds = async () => { 
  let data = [];
  await Api.get('/organizationaccountemailconfigs')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
