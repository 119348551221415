import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import Editor  from '../../../components/editor/editor';

const getButtonClass = (icon, enabled) => classnames(`btn-action fa ${icon}`, { disable: !enabled });

function EditorWindow({ status, close, image, calledId, userId, loadingOpen, loadingClose }) {

    const [imageSrc, setImageSrc] = useState(image != null ? image : '');

    React.useEffect(() => { 
        setImageSrc(image != null ? image : '');
    },[image]);



  const closeHandler  = (e) => {
    //console.log('close2');
    if (typeof close === 'function') {
        close();
    }
  }

  const loadingOpenHandler  = (e) => {
    // console.log('close');
    if (typeof loadingOpen === 'function') {
      loadingOpen();
    }
  } 

  const loadingCloseHandler  = (e) => {
    // console.log('close2');
    if (typeof loadingClose === 'function') {
      loadingClose();
    }
  } 

  return (
    <div className={classnames('editor-window', status)}>
      <div className="editor-control">
          <Editor status={status}
            close={closeHandler}
            image={imageSrc}
            calledId={calledId}
            userId={userId}
            loadingOpen={loadingOpenHandler}
            loadingClose={loadingCloseHandler}
                    />
      </div>
    </div>
    
  );
};

EditorWindow.propTypes = {
  status: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  loadingOpen: PropTypes.func.isRequired,
  loadingClose: PropTypes.func.isRequired
};

export default EditorWindow;
