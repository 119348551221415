import React, { Component, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DraftsIcon from '@mui/icons-material/Drafts';
import { ExitToApp, Assignment } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import * as userSessionActions from '../../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import If from './../conditionals/if'
import Api from '../../services/api';
import BookIcon from '@mui/icons-material/Book';
import { Overlay } from "../../styles/global";
import { CircularProgress } from "@mui/material";
import { getOrganizations } from "../../services/called.service";
import { AvailabilityConfigChat } from '../../components/availability-chat';
import SettingsIcon from '@mui/icons-material/Settings';
import { ChatListItem } from "../chatListItem";
import { ClientChat } from '../../components/client-chat';
import TechnicianChat from '../../components/technician-chat';
import CustomizedSnackbars from "../../components/material-snackbars";
import { PapiroConsole } from "../../utils/papiroConsole";


const UserRoles = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [orgList, setOrgList] = React.useState([]);
  const [showChat, setShowChat] = React.useState(false);
  const [showChatConfig, setShowChatConfig] = React.useState(false);
  const [showClientChat, setshowClientChat] = React.useState(false);
  const [showTechnicianChat, setShowTechnicianChat] = React.useState(false);

  const [openAvailabilityConfigChat, setOpenAvailabilityConfigChat] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [notificationVariant, setNotificationVariant] = React.useState("error",);
  const [notificationMessage, setNotificationMessage] = React.useState("");



  var isClient = true
  if (props.user) {
    props.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        isClient = false;
      }
    }
    );
  }




  const intl = props.intl;
  let history = useHistory();
  //console.log(props.user.userrole);
  //console.log(props.class);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAvailabilityConfigChatModal = () => {
    setOpenAvailabilityConfigChat(true)
  }

  const closeAvailabilityConfigChatModal = () => {
    setOpenAvailabilityConfigChat(false)
  }

  const closeClientChat = (notify = false) => {
    const intl = props.intl;
    //  this.setState({ showClientChat: false })
    setshowClientChat(false)
    if (notify == true) {

      setOpenNotification(true);
      setNotificationVariant("success");
      setNotificationMessage(intl.formatMessage({ id: "footer.chat.attendance.finished" }))

    }
  }

 const closeTechnicianChat = () => {
    //this.setState({ showTechnicianChat: false })
    PapiroConsole.log("ENTREI AQUI 2")
    setShowTechnicianChat(false)
  }
  const toggleClientChat = () => {
    //this.setState({ showClientChat: !this.state.showClientChat })
    setshowClientChat(!showClientChat)
  }

  const toggleTechnicianChat = () => {
    //this.setState({ showTechnicianChat: !this.state.showTechnicianChat })
    PapiroConsole.log("entrei aqui 1")
    PapiroConsole.log(showTechnicianChat)
    setShowTechnicianChat(!showTechnicianChat)
  }

  const showChatConfigMount = async () => {
    try {
      let orgData = []
      const { user } = props;


      if (user && user.userorganizations && user.userorganizations.length > 0) {
        orgData = user.userorganizations
      }

      if (orgData == null || (orgData && orgData.length == 0)) {
        let response = await getOrganizations();
        if (response && response.data)
          orgData = response.data
      }
      orgData = orgData.filter(c => c.active == true);

      console.log(orgData)
      console.log("orgData aqui")

      setOrgList(orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData)

      for (let item of orgData) {
        if (item.organizationsettings) {
          if (item.organizationsettings.allowattendanceviachat) {

            setShowChat(true)
            setShowChatConfig(isClient ? false : true)
            return;
          }
        }
      }




      // this.setState({ showChatConfig: false });
      // this.setState({ showChatConfig: false });
    } catch (err) {
      console.log(err);
      // this.setState({ showChatConfig: false });
      // this.setState({ showChatConfig: false });
    }
  }

  let sortedList = props.user.userrole.map(
    (item, index) => <MenuItem key={index} onClick={handleClose}>{item.role.name}</MenuItem>);

  const logOut = () => {
    setLoading(true)
    handleClose();
    userSessionActions.removeUserSession();

    let language = window.location.pathname.split('/')[1]
    if (language != "pt" && language != "es" && language != "en")
      language = "pt"

    Api.get("/users/removeClaims")
      .then(result => {
        if (Api && Api.resetToken) { Api.resetToken(); }
        history.push(`/${language}/`)
      })
      .catch(err => {
        if (Api && Api.resetToken) { Api.resetToken(); }
        history.push(`/${language}/`)
      });
  };

  const handleOpenWiki = () => {
    window.open(`https://wiki.mexx.com.br/en/1-In%C3%ADcio/home`, '_blank');
  };

  const closeNotification = () => {
    //this.setState({ openNotification: false });
    setOpenNotification(false);
  }

  const [displayMyPlan, setDisplayMyPlan] = React.useState(false);
  useEffect(() => {
    //console.log(props)
    //console.log("props aqui informada")
    const { user } = props;
    props.user.userrole.map(item => {
      if (item.role.roletype == 0)
        setDisplayMyPlan(true)
    })

    showChatConfigMount();




  }, [])

  return (
    <div>
      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification}
      />
      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
      {isClient && showClientChat &&
        <ClientChat closeChat={closeClientChat} userId={props.user.id} userSession={props} intl={intl} />
      }
      {!isClient && showTechnicianChat &&
        <TechnicianChat closeChat={closeTechnicianChat} userId={props.user.id} />
      }
      <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} inline="true">
        <Typography variant="h6" className={props.class}>
          <div className={props.class} style={{ color: '#ffffff' }}>
            {props.user.name}
          </div>
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key={-1} onClick={handleClose}>
          <ListItemIcon>
            <DraftsIcon fontSize="default" />
          </ListItemIcon>
          {props.user.email}
        </MenuItem>
        <MenuItem key={0} onClick={handleClose}>
          <Typography variant="inherit">
            {intl.formatMessage({ id: "profile.text" })} {props.user.userrole.map(item => `${item.role.name}`).join(', ')}
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleOpenWiki} >
          <BookIcon />
          <span style={{ marginLeft: '10px' }}>{intl.formatMessage({ id: "help" })}</span>
        </MenuItem>

        <If test={displayMyPlan}>
          <MenuItem key={1} onClick={handleClose} component={Link} {...{ to: `/${props.match.params.lang}/my-plan` }}>
            <Assignment /> <span style={{ marginLeft: '10px' }}>{intl.formatMessage({ id: "my.plan" })}</span>
          </MenuItem>
        </If>

        <If test={showChatConfig && !isClient}>
          <MenuItem key={1} onClick={handleClose} >
            <SettingsIcon /> <span style={{ marginLeft: '10px' }} onClick={openAvailabilityConfigChatModal} >Configurações do chat</span>
          </MenuItem>
        </If>
        <AvailabilityConfigChat
          onClose={closeAvailabilityConfigChatModal}
          open={openAvailabilityConfigChat}
          userSession={props.user}
          intl={props.intl}
        />
        <If test={showChat}>
         
            
              <ChatListItem
                clientChatIsOpen={showClientChat}
                handleOnClick={isClient ? toggleClientChat : toggleTechnicianChat}
                handleClose={handleClose}
                
              //disabled={!this.context.can('Add', 'RealtimeChat') && !this.context.can('View', 'RealtimeChat')}
              />

            
         
        </If>
        <MenuItem onClick={logOut}><ExitToApp /> <span style={{ marginLeft: '10px' }}>Logout</span></MenuItem>

      </Menu>
    </div>
  );

}


class UserInfo extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

  }
  constructor(props) {
    super(props);

    this.state = {
      isNotClient: this.props.userSession != null && this.props.userSession.user != null ? this.props.userSession.user.userrole.some((item) => item.role.roletype != 3) : false
    }
  }
  render() {
    const { classes, userSession, history, location, match } = this.props;
    return (
      <div style={this.state.isNotClient ? { paddingRight: 30 } : {}} >
        <UserRoles
          intl={this.props.intl}
          user={userSession.user}
          class={classes.title}
          history={history}
          location={location}
          match={match} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userSession: state.userSession
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserInfo))));
