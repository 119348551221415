import { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import * as componentActions from '../../redux/actions/calledflows';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context';
import HeaderMenu from "../../components/header-menu";
import Api from "../../services/api";
import clsx from 'clsx';
import { Button, CircularProgress, Container, CssBaseline, Grid, Avatar, TextField, Typography } from "@mui/material";
import { browserHistory } from '../../helpers/history';
import Api2Talk from "../../services/api2talk";
import { Overlay } from "../../styles/global.js";
import CustomizedSnackbars from "../../components/material-snackbars/index.js"; 
import PeopleIcon from '@mui/icons-material/People';
import { isValidCnpj } from "../../utils/validations/cnpj.validation.js";
import { cnpjMask } from "../../utils/mask/cnpj.mask.js";
import PhoneInput from "react-phone-input-2";
import Dropzone from "react-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DeleteForever } from "@mui/icons-material";


class CompanyRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.formDataInitialState = {
      createduser: userSession.user.id,
      name: '',
      identificationnumber: '',
      address: '',
      website: '',
      mainactivity: '',
      phone: ''
    }; 

    this.state = {
      itemError: {
        identificationnumber: '',
      },
      formData: this.formDataInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      phone: {
        country: 'br'
      },
      files: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));

    if (name === 'identificationnumber') {
      let valor = 0;
    
      valor = cnpjMask(value);
      
      if ((value.length !== 14 && value.length > 0) || !isValidCnpj(value)) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, identificationnumber: 'CNPJ inválido' } }));
      } else {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, identificationnumber: '' } }));
      }
      
      // Atualiza o estado do usuário com o valor formatado
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: valor
        }
      }));
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    let data = new FormData();
    let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }; 

    const formData = this.state.formData;

    this.setState({ loading: true });

    if(!isValidCnpj(formData.identificationnumber)){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo CNPJ não foi validado" 
      });
      return;
    }

    for (let key in this.state.formData) {
      data.append(key, this.state.formData[key])
    }
    this.state.files.forEach(file => data.append('FilesUpload', file));

    Api2Talk.post(`/company`, data, config)
      .then(result => {
          if (result.data.success) {
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  formData: this.formDataInitialState,
                  files: [],
                  notificationMessage: 'Registro incluído com sucesso'
              });
          } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        'phone': e
      }
    }));
  }

  validateImagesAtachments(files) {
    const intl = this.props.intl;
    if (files.length > 1) {
        this.setState({
            evAttachFile: {},
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "checklist.modal.select.image.error" }),
        })
        return
    }
    else {
        this.setState({ files: files })
    }
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { formData, itemError } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <PeopleIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "company.registration" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name.company"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='name' 
                        onChange={this.handleInputChange} 
                        value={formData.name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "identificationnumber" })}
                        autoComplete="fidentificationnumber"
                        inputProps={{ maxLength: 255 }}
                        name="identificationnumber"
                        onChange={this.handleInputChange}
                        value={formData.identificationnumber}
                        variant='outlined'
                        error={itemError.identificationnumber?.length > 0}
                        helperText={itemError.identificationnumber} 
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"address"})} 
                        autoComplete='faddress' 
                        variant='outlined' 
                        name='address' 
                        onChange={this.handleInputChange} 
                        value={formData.address} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"website"})} 
                        autoComplete='fwebsite' 
                        variant='outlined' 
                        name='website' 
                        onChange={this.handleInputChange} 
                        value={formData.website} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"mainactivity"})} 
                        autoComplete='fmainactivity' 
                        variant='outlined' 
                        name='mainactivity' 
                        onChange={this.handleInputChange} 
                        value={formData.mainactivity} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <PhoneInput
                        required
                        inputStyle={{
                          padding: '23.5px 14px 23.5px 58px',
                          width: '100%'
                        }}
                        id="phone"
                        name="Phone"
                        country={this.state.phone.country}
                        InputLabel={intl.formatMessage({ id: "phone" })}
                        specialLabel={intl.formatMessage({ id: "phone" })}
                        inputProps={{
                          name: intl.formatMessage({ id: "phone" })
                        }} 
                        onChange={this.handleChangePhone} 
                        value={formData.phone} 
                      />
                    </Grid>

                    <Grid item xs={12} >
                        {/*<TextField label="Logo" autoComplete='fname' variant='outlined' name='logo' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.logo} required fullWidth />*/}
                        <Grid item xs={12} sm={12}>
                            <section style={{ width: '100%' }}>
                                <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                                    <Dropzone onDrop={acceptedFiles => this.validateImagesAtachments([...acceptedFiles, ...this.state.files])}>
                                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <AttachFileIcon />
                                                <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                    <FormattedMessage id="drag.and.drop.logo" />
                                                </Typography>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {this.state.files.map(file => {
                                        return (
                                            <div className={classes.dropzoneItem} key={file.name}>
                                                {file.name}
                                                <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

CompanyRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CompanyRegistration)));

CompanyRegistration.contextType = AbilityContext;