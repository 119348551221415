import Api from './api';

export const getAllSelectionModel = async () => { 
  let data = []
  await Api.get(`/statuses/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllSelectionModelWithColorHex = async () => { 
  let data = []
  await Api.get(`/statuses/selectionmodel/withcolorhex`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}