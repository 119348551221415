import React, { Component } from 'react';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { AbilityContext } from '../../config/ability-context'
                import Base from '../../components/layout-base/base';
                import OrganizationAccountEmail from './index.content';

 
                    import * as componentActions from '../../redux/actions/organizationcalendars';
                    class OrganizationCalendars extends Component {

                    constructor(props) {
                        super(props);

                      
                    }

                    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                    }

                 
                     render() {
                       

                        return (
                            <Base props={this.props}>
                                   <OrganizationAccountEmail props={this.props} />

                            </Base>                                
                        );
                    }
                }
OrganizationCalendars.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationCalendars));
OrganizationCalendars.contextType = AbilityContext;
