import React from 'react';
import LogoMarca from '../../images/new-logo-cmtech.png';

const LogoSmall2 = props => (
   <div>
        {/*<img src={props.src && props.src.length > 0 ? props.src : LogoMarca} title="Logomarca" style={{width: "100px"}}  />*/}
    </div>
);

export default LogoSmall2;
