import { PapiroConsole } from "../../utils/papiroConsole";

let initialState = {
  omnichannelchats: [], // Lista geral de chats
  chats: [], // Lista de chats filtrados
  chatsv2: {}, // Lista de chats V2 - Chaveados
  chatscount: 0, // Total de chats
  chat: null, // Chat selecionado
  messages: [], // Mensagens do chat selecionado
  userstatus: 1, // Status do usuário
  issidebaropen: false, // Barra de detalhes do chat de usuário aberta?
  customerdetails: null, // Editar dados do cliente na barra de informações do chat
  chatdetails: null, // Editar dados do chat na barra de informações
  refreshdashboard : false,
  events: null
}

export default function chats(state = initialState, action) {
  switch (action.type) {
    case 'REFRESH_DASHBOARD':
      return {
        ...state,
        refreshdashboard: action.refreshdashboard
      }

      case 'UPDATE_EVENT':
        return {
          ...state,
          event: action.event
        }

    case 'HANDLE_CHAT_USER_DETAILS_SIDEBAR_TOGGLE':
      return {
        ...state,
        issidebaropen: action.issidebaropen,
        ishistorysidebaryopen: action.issidebaropen ? !action.issidebaropen : state.ishistorysidebaryopen
      }
      case 'HANDLE_CHAT_HISTORY_SIDEBAR_TOGGLE':
      return {
        ...state,
        ishistorysidebaryopen: action.ishistorysidebaryopen,
        issidebaropen: action.ishistorysidebaryopen ? !action.ishistorysidebaryopen : state.issidebaropen
      }
    case 'ADD_CURRENT_CHAT_MESSAGE':
        let itens = {};
        if(action.chat && action.chat.chatmessages && action.chat.id){
              if(state.chatsv2){
                if( !state.chatsv2[action.chat.id] 
                  || !state.chatsv2[action.chat.id].messages 
                  || !state.chatsv2[action.chat.id].messages.itens){
                    action.chat.chatmessages.map((item) => {
                            itens = { ...itens, [`message-${item.guid}`] :  item} ;
                      }
                    );
                }
            }

            return {
              ...state, // copy state
              chat: action.chat,
              chatsv2: {
                ...state.chatsv2, // copy chats
                [action.chat.id]: {  // update one specific chat 
                  ...(state.chatsv2 ? state.chatsv2[action.chat.id] : {}),  // copy that specific chats's properties
                  itens,
                }
              },

          }  

        }
        else{
          return {
            ...state,
            chat: action.chat,
            chatsv2: {},
            messages : [],
          }  
        }
    case 'UPDATE_LAST_CHAT_MESSAGE_V2':
      let chatmessages = []
      PapiroConsole.log("state")
      PapiroConsole.log(state)
      PapiroConsole.log("checar action")
      PapiroConsole.log(action)
      let c = state.chats ? state.chats : []
      if (c.length > 0) {
          let chatItem = c.find(item => item.id == action.chatid);
          if(chatItem != null){
            chatItem.chatmessages.push(action.lastmessage);
            if(action.unreadMessages != null){
              chatItem.unreadmessages = action.unreadMessages;
            }
            
          }
      }
      return {
        ...state, // copy state
        chatsv2: {
          ...state.chatsv2, // copy chats
          [action.chatid]: {  // uupdate one specific chat 
            ...state.chatsv2[action.chatid],  // copy that specific chats's properties
              itens : {
                ...(state.chatsv2[action.chatid] ? state.chatsv2[action.chatid].itens : {}),  // copy that specific chats's ITENS properties
                [`message-${action.lastmessage.guid}`]:  action.lastmessage  // update ONLY element 
              }
            
          }
        }
      }

      case 'RESET_CHAT_NUMBER_OF_NOTIFICATIONS':
        PapiroConsole.log("enntrei aqui 2")
        PapiroConsole.log(action.chatid)
      let cn = state.chats ? state.chats : []
      if (cn.length > 0) {
          let chatItem = cn.find(item => item.id == action.chatid);
          if(chatItem != null){
            
              chatItem.unreadmessages = 0
            
          }
      }
      return {
        ...state, // copy state
       
      }

    case 'SET_CURRENT_CHAT_BY_CUSTOMER_ID':
      let chatByCustomerId = state.omnichannelchats.find(chat => chat.customerid == action.customerid)
      if (chatByCustomerId) {
        return {
          ...state,
          chat: chatByCustomerId,
          messages: chatByCustomerId && chatByCustomerId.chatmessages ? chatByCustomerId.chatmessages : [],
        }
      } else
        return state
    case 'ADD_CURRENT_CHAT_MESSAGES':
      return {
        ...state,
        messages: action.messages ? action.messages : [],
      }
    case 'SET_USER_CHAT_STATUS':
      return {
        ...state,
        userstatus: action.userstatus
      }
    case 'ADD_CURRENT_OMNICHANNEL_CHAT_LIST':
      return {
        ...state,
        omnichannelchats: action.omnichannelchats,
        chats: action.omnichannelchats,
      }
    case 'ADD_CURRENT_CHAT_LIST':
      return {
        ...state,
        chats: action.chats,
      }
    case 'ADD_CURRENT_CHATS_COUNT':
      PapiroConsole.log(action)
      PapiroConsole.log("action aqui 22")
      return {
        ...state,
        chatscount: action.chatscount,
      }
    case 'FILTER_CHAT_BY_MESSAGE_PROVIDER':
      let chatList = state.omnichannelchats ? state.omnichannelchats : []

      if (action.messageproviderid && action.messageproviderid > 0)
        chatList = chatList.filter(chat => chat.messageproviderid == action.messageproviderid)

      let currentChat = state.chat ? state.chat : null
      if (state.chat && state.chat.id && chatList.some(chat => chat.id == state.chat.id)) {
        PapiroConsole.log("somechat")
        currentChat = state.chat
      } else {
        currentChat = null
      }

      return {
        ...state,
        chats: chatList,
        chat: currentChat,
        messages: currentChat ? state.messages : []
      }
    case  'UPDATE_CURRENT_CHAT_CUSTOMER_DATA':
      let updatedChatCustomerData = updateCurrentChatCustomerData(state, action)
      return {
        ...state,
        omnichannelchats: updatedChatCustomerData.omnichannelchats,
        chats: updatedChatCustomerData.chats,
        chat: updatedChatCustomerData.chat
      }
    case  'UPDATE_CURRENT_CHAT_DETAILS_DATA':
      let updatedChatDetailsData = updateCurrentChatDetailsData(state, action)
      return {
        ...state,
        omnichannelchats: updatedChatDetailsData.omnichannelchats,
        chats: updatedChatDetailsData.chats,
        chat: updatedChatDetailsData.chat
      }

    case 'UPDATE_LAST_CHAT_MESSAGE':
      let messages = []
      let message = null
      if (state.messages && state.messages.some(message => message.chatid == action.chatid)) {
        messages = state.messages
        message = messages.find(item => ((item.id == action.lastmessage.id || (item.id == null || typeof item.id === 'undefined')) && item.content == action.lastmessage.content))
        if (message && action.lastmessage)
          message = enrichMessage(message, action.lastmessage)
      }

      if (state.messages && state.messages.some(message => message.chatid == action.chatid)) {
        if (message) {
          messages = messages.map(item => {
            if(((item.id == action.lastmessage.id || (item.id == null || typeof item.id === 'undefined')) && item.content == action.lastmessage.content)) {
              return message
            } else {
              return item
            }
          })
        } else if (action.lastmessage)
          messages.push(action.lastmessage)
      }

      let chats = state.chats ? state.chats : []
      if (chats.length > 0) {
        let chatItem = chats.find(item => item.id == action.chatid);
        if (chatItem) {
          chatItem.lastchatmessage = message ? message : action.lastmessage
          if (messages && messages.some(message => message.chatid == action.chatid))
            chatItem.chatmessages = messages
          else if ((!messages || (messages && !messages.some(message => message.chatid == action.chatid))) && action.lastmessage) {
            let chatMessages = chatItem.chatmessages ? chatItem.chatmessages : []
            chatMessages.push(action.lastmessage)
            chatItem.chatmessages = chatMessages
          }
        }
      }
      let omnichannelchats = state.omnichannelchats ? state.omnichannelchats : []
      if (omnichannelchats.length > 0) {
        let omnichannelChatItem = omnichannelchats.find(item => item.id == action.chatid);
        if (omnichannelChatItem) {
          omnichannelChatItem.lastchatmessage = message ? message : action.lastmessage
          if (messages && messages.some(message => message.chatid == action.chatid))
            omnichannelChatItem.chatmessages = messages
          else if ((!messages || (messages && !messages.some(message => message.chatid == action.chatid))) && action.lastmessage) {
            let chatMessages = omnichannelChatItem.chatmessages ? omnichannelChatItem.chatmessages : []
            chatMessages.push(action.lastmessage)
            omnichannelChatItem.chatmessages = chatMessages
          }
        }
      }

      return {
        ...state,
        chats: chats,
        omnichannelchats: omnichannelchats,
        messages: messages
      }

    case 'UPDATE_CHAT_STATUS_ID':
      PapiroConsole.log("entrei aqui no UPDATE_CHAT_STATUS_ID ")
      PapiroConsole.log(state)
      PapiroConsole.log(action)

      let updatedChats = updateChatListStatus(state.chats, action)
     

      PapiroConsole.log(updatedChats)
      PapiroConsole.log("updatedChats")
      return {
        ...state,
        chats : updatedChats,
        chat: {
          ...state.chat,
          chatstatusid: action.chatstatus.id,
          chatstatusname : action.chatstatus.name
        }
      }

      case 'UPDATE_CHAT_NOTES':
        PapiroConsole.log("entrei aqui no UPDATE_CHAT_STATUS_ID ")
        PapiroConsole.log(state)
        PapiroConsole.log(action)
  
        let updatedChatsList = updatedChatNotesOfChatsList(state.chat, state.chats, action)

        return {
          ...state,
          chats : updatedChatsList,
        }

      case 'UPDATE_USER_DETAILS_INFORMATIONS':
        PapiroConsole.log("entrei aqui no UPDATE_USER_INFORMATIONS details ")
        PapiroConsole.log(state)
        PapiroConsole.log(action)
  
        let updateUserDetailsInfos = updateUserDetailsInformations(state.chats, state.chat, action)
       
  
        PapiroConsole.log(updateUserDetailsInfos)
        PapiroConsole.log("updateUserDetailsInfos")
        return {
          ...state,
          chats : updateUserDetailsInfos,
          chat: {
            ...state.chat,

          }
         
        }
        

        case 'UPDATE_USER_INFORMATIONS':
        PapiroConsole.log("entrei aqui no UPDATE_USER_INFORMATIONS ")
        PapiroConsole.log(state)
        PapiroConsole.log(action)
  
        let updateUserInfos = updateUserInformations(state.chats, action)
       
  
        PapiroConsole.log(updateUserInfos)
        PapiroConsole.log("updateUserInfos")
        return {
          ...state,
          chats : updateUserInfos,
          chat: {
            ...state.chat,
            customer : action.userInfos
          }
        }
    default:
      return state;
  }
}

function enrichMessage(message, updatedMessage) {
  let id = (message.id == null || typeof message.id === 'undefined') ? updatedMessage.id : message.id

  PapiroConsole.log("entrei aqui")
  PapiroConsole.log("message")
  PapiroConsole.log(message)
  return {
    ...message,
    id: id,
    chatmessagestatus: updatedMessage.chatmessagestatus,
    delivered: updatedMessage.delivered,
    read: updatedMessage.read,
    request: updatedMessage.request,
    sent: updatedMessage.sent,
    sendername: updatedMessage.sendername
  }
}

function updateCurrentChatCustomerData(state, action) {
  let omnichannelchats = state.omnichannelchats ? state.omnichannelchats : []
  let chats = state.chats ? state.chats : []
  let chat = state.chat ? state.chat : state.omnichannelchats.find(chat => chat.id == action.chatid)
  if (action.customerdetails) {
    if (chat && chat.customer) {
      chat.customer.name = action.customerdetails.name ? action.customerdetails.name : ""
      chat.customer.email = action.customerdetails.email ? action.customerdetails.email : ""
      chat.customer.phone = action.customerdetails.phone ? action.customerdetails.phone : ""
    }

    if (chats.length > 0) {
      let chatItem = chats.find(item => item.id == action.chatid);
      if (chatItem) {
        chatItem.customer = chat.customer
      }
    }

    if (omnichannelchats.length > 0) {
      let omnichannelChatItem = omnichannelchats.find(item => item.id == action.chatid);
      if (omnichannelChatItem) {
        omnichannelChatItem.customer = chat.customer
      }
    }
  }

  return {
    omnichannelchats,
    chats,
    chat
  }
}

function updateCurrentChatDetailsData(state, action) {
  let omnichannelchats = state.omnichannelchats ? state.omnichannelchats : []
  let chats = state.chats ? state.chats : []
  let chat = state.chat ? state.chat : state.omnichannelchats.find(chat => chat.id == action.chatid)

  if (action.chatdetails) {
    if (chat) {
      chat.title = action.chatdetails.title ? action.chatdetails.title : ""
      chat.assigneduserid = action.chatdetails.assigneduser && action.chatdetails.assigneduser.id ? action.chatdetails.assigneduser.id : null
      chat.assignedusername = action.chatdetails.assigneduser && action.chatdetails.assigneduser.name ? action.chatdetails.assigneduser.name : null
      chat.priority = action.chatdetails.priority ? action.chatdetails.priority : null
      chat.priorityid = action.chatdetails.priority && action.chatdetails.priority.id ? action.chatdetails.priority.id : null
      chat.organizationcategory = action.chatdetails.category ? action.chatdetails.category : null
      chat.organizationcategoryid = action.chatdetails.category && action.chatdetails.category.id ? action.chatdetails.category.id : null
      chat.organizationdepartament = action.chatdetails.departament ? action.chatdetails.departament : null
      chat.organizationdepartamentid = action.chatdetails.departament && action.chatdetails.departament.id ? action.chatdetails.departament.id : null
    }

    if (chats.length > 0) {
      let chatItem = chats.find(item => item.id == action.chatid);
      if (chatItem) {
        chatItem = chat
      }
    }

    if (omnichannelchats.length > 0) {
      let omnichannelChatItem = omnichannelchats.find(item => item.id == action.chatid);
      if (omnichannelChatItem) {
        omnichannelChatItem = chat
      }
    }
  }

  return {
    omnichannelchats,
    chats,
    chat
  }
}


function updateChatListStatus(chat, action) {

  const updatedChats = chat.map(item => {
    // Verifique se é o chat que queremos atualizar
    if(item.id == action.chatid){
        item.chatstatusid = action.chatstatus.id
        item.chatstatusname = action.chatstatus.name
    }
    
    // Se não, retorne o chat original
    return item;
  });

  PapiroConsole.log("updatedChats 1 ")
  PapiroConsole.log(updatedChats)
  return updatedChats;
}

function updatedChatNotesOfChatsList(currentChat, chats, action) {
  
  
  
  const updatedChats = chats.map(item => {
    // Verifique se é o chat que queremos atualizar
    if(item.id == action.chatid){
       if(item.chatnotes && item.chatnotes.length > 0){
        PapiroConsole.log(action.chatnotes)
        PapiroConsole.log("action.chatnotes")
      
        item.chatnotes.push(action.chatnotes)
       }
       else{
        item.chatnotes = [];
        item.chatnotes.push(action.chatnotes)
       }
        
    }
    // Se não, retorne o chat original
    return item;
  });

 

  // const updateCurrentChat = currentChat
  // if(updateCurrentChat.chatnotes != null && updateCurrentChat.chatnotes.length > 0 ){
  //   updateCurrentChat.chatnotes.forEach(element => {
  //         if(element.id == action.chatnotes.id){
  //           checkElement = true;
  //           PapiroConsole.log("entrei na checagem de elemento")
  //         }
  //   });
  //   if(checkElement == false){
  //     PapiroConsole.log("entrei no false")
  //     updateCurrentChat.chatnotes.push(action.chatnotes)
  //   }
  // }

 
    return updatedChats;
  


 
}



function updateUserInformations(chat, action) {

  const updatedChats = chat.map(item => {
    // Verifique se é o chat que queremos atualizar
    if(item.id == action.chatid){
        if(item.customer){
          item.customer.id = action.userInfos.id
          item.customer.name = action.userInfos.name
          item.customer.email = action.userInfos.email
          
        }
        
      
    }
    
    // Se não, retorne o chat original
    return item;
  });

  PapiroConsole.log("updatedChats 1 ")
  PapiroConsole.log(updatedChats)
  return updatedChats;

}


function updateUserDetailsInformations(chats,currentChat, action) {

  const updatedChats = chats.map(item => {
    // Verifique se é o chat que queremos atualizar
    if(item.id == action.chatid){
      PapiroConsole.log(action.userInfos.organization)
      PapiroConsole.log(action.userInfos.organizationdepartament)
      PapiroConsole.log(action.userInfos.organizationcategory)
      PapiroConsole.log(action.userInfos.priority)
      PapiroConsole.log(action.userInfos.title)
      PapiroConsole.log("variaveis aqui")
      item.organization = action.userInfos.organization ? action.userInfos.organization : null
      item.organizationdepartament = action.userInfos.organizationdepartament ? action.userInfos.organizationdepartament : null
      item.organizationcategory = action.userInfos.organizationcategory ? action.userInfos.organizationcategory : null
      item.priority = action.userInfos.priority ? action.userInfos.priority : null
      item.title =  action.userInfos.title ?  action.userInfos.title : ''
    }

    PapiroConsole.log("passei aqui")
    
    // Se não, retorne o chat original
    return item;
  });



  currentChat.organization = action.userInfos.organization ? action.userInfos.organization : null
  currentChat.organizationdepartament = action.userInfos.organizationdepartament ? action.userInfos.organizationdepartament : null
  currentChat.organizationcategory = action.userInfos.organizationcategory ? action.userInfos.organizationcategory : null
  currentChat.priority = action.userInfos.priority ? action.userInfos.priority : null
  currentChat.title =  action.userInfos.title ?  action.userInfos.title : ''



  PapiroConsole.log("updatedChats 1 ")
  PapiroConsole.log(updatedChats)
  return updatedChats;

}



