import React, { Component } from 'react';
import { render } from 'react-dom';
import _ from 'lodash';
import PeerConnection from './PeerConnection';
import MainWindow from './MainWindow';
import CallWindow from './CallWindow';
import CallModal from './CallModal';
import { bindActionCreators } from 'redux';
import * as calledsActions from '../../../redux/actions/calleds';
import { Overlay } from "../../../styles/global";
import { CircularProgress} from "@mui/material";
import PropTypes from "prop-types";
import { browserHistory } from '../../../helpers/history';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';
import CustomizedSnackbars from "../../../components/material-snackbars";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import { AbilityContext } from '../../../config/ability-context'
import { connect } from 'react-redux';
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { Styles } from "../../../styles/material-styles";
import '../css/app.scss';
import Api from "../../../services/api";
import * as CalledVideoCallService from '../../../services/calledvideocall.service';
import socket from './socket';
import EditorWindow  from './EditorWindow';


const socketConfig = require('../../config.json');
 
//const CERTIFICATE_ROOT = path.resolve(__dirname, '../../', 'sslcert','iis-dev.pfx');
//console.log(CERTIFICATE_ROOT);


class VideoCall extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    
    // console.log(props);
    //const { user, called, socket } = this.props;
    const { user, called } = this.props;

    this.timer = null;

    this.state = {
      clientId: '',
      friendID: '',
      callWindow: '',
      editorWindow:'',
      image:'',
      callModal: '',
      callFrom: '',
      localSrc: null,
      peerSrc: null,
      called:called,
      calledvideocallList : [],
      user:user.user,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
    };
    this.pc = {};
    this.config = null;
    this.startCallHandler = this.startCall.bind(this);
    this.endCallHandler = this.endCall.bind(this);
    this.rejectCallHandler = this.rejectCall.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.closeNotification = this.closeNotification.bind(this);

    this.closeEditorWindowHandler = this.closeEditorWindow.bind(this);

    this.loadingOpen = this.loadingOpen.bind(this);
    this.loadingClose = this.loadingClose.bind(this);
    
    this.showEditorHandler = this.showEditor.bind(this);

    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;
    this.isClient = true;
    this.RoleType = -1;
    //console.log(called);
    //console.log(user);
    user.user.userrole.map((item, key) => {
        if(item.role.roletype !=3){
            this.isClient = false;
        }
        if(item.role.roletype == 0){
            this.globalAdm =true;
            this.RoleType = item.role.roletype;
        }
        if(item.role.roletype == 1){
            this.AdmOrganization =true;
            this.RoleType = item.role.roletype;
        }
        if(item.role.roletype ==2){
          this.isTechinical =true;
          this.RoleType = item.role.roletype;
        }
        if(item.role.roletype ==3){
              this.RoleType = item.role.roletype;
        }
        if(item.role.roletype == 4){
              this.isCoordenator =true;
              this.RoleType = item.role.roletype;
        }
    });

  }

  loadingOpen() {
    this.setState({ 
      loading: true
   });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  loadingClose() {
    const intl = this.props.intl;
    Api.get(`/accompaniments/getAllByCalled/${this.state.called.id}`)                          
    .then(result => {
    if (result.data.success) {
      // console.log(result.data);
      this.props.addAccompanimentsList(result.data.data);
      
      this.setState({ 
        loading: false,
        openNotification: true,
        notificationVariant: "success",
        notificationMessage: intl.formatMessage({id:"edit.success"})
      });

    }else{
      this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
      }
    })
    .catch(err => {
      this.setState({
      loading: false, openNotification: true, notificationVariant: 'error',
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(err);

    });

  };

  async getCalledVideoCall(calledId) {
    if(calledId == null){
      calledId=this.state.called.id;
    }
    this.setState({ loading: true });
     
    var result = await CalledVideoCallService.getByCalled(calledId);

    if (result.success) {
      this.setState({ loading: false, calledvideocallList: result.data });
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
    }
  };
  async submitCalledVideoCall(key) {
    let data = new FormData();

    //for (let key in this.state.called) {
    //        data.append(key, this.state.called[key]);
    //}
    data.append("RoleType",this.RoleType);
    data.append("Key",key);
    data.append("Active",true);
    data.append("CalledId",this.state.called.id);
    data.append("UserId",this.state.user.id);
    //console.log(data);
    Api.post("/calledvideocalls", data)
        .then(result => {
            if (result.data.success) {
                //console.log(result.data.data);
                this.setState({
                    loading: false,
                    openNotification: false,
                });
            }
        })
        .catch(err => {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
        });
  }
  async cleanCalledVideoCall() {
    this.setState({
      loading: false,
      openNotification: false,
    });
    /*let data = new FormData();

    data.append("Key","#");
    data.append("Active",false);
    data.append("CalledId",this.state.called.id);
    data.append("UserId",this.state.user.id);
    Api.put("/calledvideocalls/updateall", data)
        .then(result => {
            if (result.data.success) {
                //console.log(result.data.data);
                this.setState({
                    loading: false,
                    openNotification: false,
                });
            }
        })
        .catch(err => {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
        });*/
  }


  async componentWillUnmount(){
    //await socket.emit('disconnect', this.state.clientId);
    //console.log('will unmount');
    //socket.disconnect();
    //socket.disconnect(true);
    
    socket.emit('finish',this.state.clientId);

    setTimeout(
      function() {
          //console.log('removeAllListeners');
          socket.removeAllListeners();
      }
      .bind(this),
      1000
    );    

    //clearInterval(this.timer);
    //await this.cleanCalledVideoCall();
    //console.log('disconnect2');

  }
  
  componentDidUpdate() {
    //console.log('updated');
  }
async initSocket(){

    /*
    this.timer = setInterval(
      function() {
          //console.log('Hello, World!');
          this.getCalledVideoCall(this.state.called.id);
      }
      .bind(this),
      15000
    );
    */
   
    
    socket
    .on('init', ({ id: clientId }) => {
      //console.log('init');
      //document.title = `${clientId} - VideoCall`;
      this.setState({ clientId });
      setTimeout(
        async function() {
          await this.submitCalledVideoCall(clientId);
        }
        .bind(this),
        500);        


      
      setTimeout(
        async function() {
            await this.getCalledVideoCall(this.state.called.id);
        }
        .bind(this),
        1000);        
      
      localStorage.setItem('clientId', clientId);
      socket.emit('connectedUser', clientId);


      })
      .on('request', ({ from: callFrom }) => {
        //console.log('request');
        this.setState({ callModal: 'active', callFrom });
      })
      .on('call', (data) => {
        //console.log('call');
        if (data.sdp) {
          this.pc.setRemoteDescription(data.sdp);
          if (data.sdp.type === 'offer') this.pc.createAnswer();
        } else this.pc.addIceCandidate(data.candidate);

        

      })
      .on('end', () => {
          //console.log('end');

          this.endCall.bind(this, false);
      })
      .on('connectedUser', (users) =>{
          socket.name = users;
          setTimeout(
            async function() {
              this.getCalledVideoCall(this.state.called.id);
            }
            .bind(this),
            1000);        
  
          // console.log(users + ' has joined the chat.1');
      })
      .on('disconnect', (user) =>{
        // Do stuff (probably some jQuery)
        //console.log("disconnect2");

      })
      .on('finish', (users) =>{
        socket.name = users;
        // Do stuff (probably some jQuery)
        setTimeout(
          async function() {
            await this.getCalledVideoCall(this.state.called.id);
          }
          .bind(this),
          500);        
  
  
        // console.log("finished to "+users);
        this.setState({friendID:'' });
        this.refreshChild();


      })
      .emit('init');

  }
  async componentDidMount() {
    //console.log('aqui: '+JSON.stringify(this.state.called));
    //console.log('mounted');
    //this.initSocket(); 
    await this.getCalledVideoCall(this.state.called.id);


  }
  showEditor(show, image = '') {
    //console.log(image);
      if (show) {
        const newState = { editorWindow: 'active', image: image };
        this.setState(newState);
      }
      else{
        const newState = { editorWindow: '', image: null };
        this.setState(newState);
      }

  }

  closeEditorWindow() {
    //console.log('close3');

    this.showEditor(false);
  }


  closeModal(){
     this.props.handleClose();
  }
  
  startCall(isCaller, friendID, config) {
    this.config = config;
    this.pc = new PeerConnection(friendID)
      .on('localStream', (src) => {
        const newState = { callWindow: 'active', localSrc: src };
        if (!isCaller) newState.callModal = '';
        this.setState(newState);
      })
      .on('peerStream', src => this.setState({ peerSrc: src }))
      .start(isCaller, config);
  }

  rejectCall() {
    const { callFrom } = this.state;
    socket.emit('end', { to: callFrom });
    this.setState({ callModal: '' });
  }

  endCall(isStarter) {
    if (_.isFunction(this.pc.stop)) {
      this.pc.stop(isStarter);
    }
    this.pc = {};
    this.config = null;
    this.setState({
      callWindow: '',
      callModal: '',
      localSrc: null,
      peerSrc: null
    });
  }

  render() {
    const { clientId, friendID, callFrom, callModal, callWindow, localSrc, peerSrc, editorWindow, image } = this.state;
    return (
      <div>
        <MainWindow ref={this.child} 
          clientId={this.state.clientId}
          friend={this.state.friendID}
          startCall={this.startCallHandler}
          onlinelist={this.state.calledvideocallList}
          setRefresh={refresh => this.refreshChild = refresh}
          refreshUser={this.getCalledVideoCall.bind(this)}
          calledId={this.state.called.id}
          showEditor={this.showEditorHandler}
          handleClose={this.closeModal}
          
        />
        {!_.isEmpty(this.config) && (
          <CallWindow
            status={callWindow}
            localSrc={localSrc}
            peerSrc={peerSrc}
            config={this.config}
            mediaDevice={this.pc.mediaDevice}
            endCall={this.endCallHandler}
            showEditor={this.showEditorHandler}
            />
        ) }
        <CallModal
          status={callModal}
          startCall={this.startCallHandler}
          rejectCall={this.rejectCallHandler}
          callFrom={callFrom}
        />
        <EditorWindow
          status={editorWindow}
          close={this.closeEditorWindowHandler}
          image={image}
          calledId={this.state.called.id}
          userId={this.state.user.id}
          loadingOpen={this.loadingOpen}
          loadingClose={this.loadingClose}
        />

        {this.state.loading && (
            <Overlay>
                <CircularProgress color="secondary" />
            </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />

      </div>
    );
  }
}


VideoCall.propTypes = {
  classes: PropTypes.object.isRequired,
};



const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(VideoCall))));
VideoCall.contextType = AbilityContext;
