import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fade from '@mui/material/Fade'
import ClearIcon from '@mui/icons-material/Clear'
import MessageAttachment from './message-attachment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />
})

function ChatMessageVideoDialog({ url, title, isOpen, handleCloseDialog }) {
  return (<Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="chat-message-video-dialog" >
      <DialogTitle>
        {title}.mp4
        <ClearIcon onClick={handleCloseDialog}  style={{ float: 'right', cursor : 'pointer'}}/> 
      </DialogTitle>
      <DialogContent className="chat-message-video-dialog">
        <MessageAttachment
          src={url}
          alt={`${title}.mp4`} />
      </DialogContent>
  </Dialog>)
}

export default React.memo(ChatMessageVideoDialog)