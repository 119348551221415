import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Document, Page } from 'react-pdf';


function PdfAttachment({ url, intl }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
      {intl.formatMessage({ id: "page" })} {pageNumber} {intl.formatMessage({ id: "of" })} {numPages}
      </p>
    </div>
  );
}

export default injectIntl(PdfAttachment)