import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Grid, CircularProgress } from '@mui/material';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import palette from '../../../../styles/palette';

import { data, options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, dataCalled } = props;

  var listValues = [];
  var listNames = [];

  dataCalled.map(dataCalled => (
    listValues.push(dataCalled.count),
    listNames.push(dataCalled.month)
  ))
  const intl = props.intl;
  const dataPush = {
    labels: listNames,
    datasets: [
      {
        label: intl.formatMessage({id:"amount"}),
        backgroundColor: palette.primary.main,
        data: listValues
      }
    ]
  };
  
  const classes = useStyles();

  return (
    <Grid container>

    <Grid item xs={12}>
    <Card
      className={clsx(classes.root, className)}
    >
      <Grid item xs={12} md={12}>
      <CardHeader
        title={intl.formatMessage({id:"amount.calss"})}
      />
      </Grid>
      <Grid item xs={11} md={12}>
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={dataPush}
            options={options}
          />
        </div>
      </CardContent>
      </Grid>
    </Card>
    </Grid>
    </Grid>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
  dataCalled: PropTypes.array
};

export default injectIntl(LatestSales);
