import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../components/footer";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import MyMaterialTable from "../../components/table";
import { AbilityContext, Can } from "../../config/ability-context";

import * as componentActions from "../../redux/actions/calledclassifications";

import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";

import ClearIcon from "@mui/icons-material/Clear";
import Search from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import RatingElement from "../../components/rating-element";
import SimpleSelect from "../../components/select/simple-search";
import ChatClassificationService from "../../services/2talk/chatclassification.service";

import moment from "moment";

class ChatClassifications extends Component {
  constructor(props) {
    super(props);

    this.typingTimeout = null;

    const { userSession } = this.props;
    var attr = "Atribuído para";
    var bool = true;
  
    const intl = this.props.intl;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;
    this.isClient = true;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
        attr = intl.formatMessage({ id: "client" });
        bool = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true;
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true;
      }
    });

    let filters = [
      { name: "Id", id: "id" },
      { name: "Provedor", id: "provider" },
      { name: "Atendente", id: "assignedUser" },
      { name: "Cliente", id: "requestUser" },
    ];

    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      resultList: [],
      userId: userSession.user.id,
      userRoleName: attr,
      userRoleBool: bool,

      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: "",
      filterSelected: 0,
      filters: filters,
    };

    this.closeNotification = this.closeNotification.bind(this);
    this.getItens = this.getItens.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.callSearch = this.callSearch.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.getItens();
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page });
    this.getItens(page);
  };

  setPagination(count, page) {
    let totalPage = Math.ceil(count / 10);
    this.setState({ totalPages: totalPage });
    if (count > 0) {
      if (page == 1) {
        if (count < 10) {
          this.setState({ pageStart: 1, pageEnd: count });
        } else {
          this.setState({ pageStart: 1, pageEnd: 10 });
        }
      } else {
        let pageStart = (page - 1) * 10 + 1;

        if (count >= page * 10) {
          this.setState({ pageStart: pageStart, pageEnd: page * 10 });
        } else {
          this.setState({ pageStart: pageStart, pageEnd: count });
        }
      }
    } else {
      this.setState({ totalPages: 0, page: 1, count: 0 });
    }
  }

  onFieldChange(event) {
    this.setState({ [event.target.name]: event.target.value, page: 1 });
  }

  callSearch() {
    if (this.state.filterSelected != 0) {
      this.getItens(this.state.page);
    }
  }

  async clearFields() {
    await this.setState({ filterSelected: 0, searchValue: "", page: 1 });
    this.getItens(1);
  }

  async changeValues(stateName, value, text = "") {
    this.setState({ filterSelected: value });
  }

  async getItens(page = 1) {
    this.setState({ loading: true });
    const intl = this.props.intl;

    try {

      let paramSearch = "";

      if (this.state.searchValue != "" && this.state.filterSelected != 0) {
        paramSearch += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`;
      }

      const service = new ChatClassificationService();
      const output = await service.listAllPaginate(page, paramSearch);

      if (output?.success) {
        let count = output?.count;
        this.setState({ count: count });
        this.setPagination(count, page);

        const formattedList = output?.data.map((item) => {

          const rating = <RatingElement
            type={6}
            value={item.noteassigned ? item.noteassigned : null}
          />

          return {
            classificationId: item.id,
            chatId: item?.chat?.id,
            classificationDate: item?.createdat ? moment(item?.createdat).format("DD/MM/YYYY HH:mm") : "" ,
            assignedUser: item?.assigneduser?.name,
            noteAssigned: item?.noteassigned ? rating : 'N/A',
            requestUser: item?.requestuser?.name,
            provider: item?.messageprovider?.name
          };
        });

        this.setState({ resultList: formattedList });
        return;
      } else {
        const messageError = (output?.errors) ? output?.errors[0] : intl.formatMessage({ id: "process.error" });
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: messageError
        });
      }
    } catch (err) {
      const messageError = intl.formatMessage({ id: "process.error" });
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: messageError
      });
      Api.kickoff(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container
            component="main"
            maxWidth="100%"
            style={{ paddingLeft: 0, marginLeft: 0, marginRight: 0 }}
          >
            <CssBaseline />

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card className="background-title-search">
                  <CardContent>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Typography variant="h5">
                          {
                            <FormattedMessage id="menu.2talk.chatclassification" />
                          }
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <SimpleSelect
                          label={intl.formatMessage({ id: "field" })}
                          options={this.state.filters}
                          stateName="filter"
                          changeSelect={this.changeValues}
                          selected={this.state.filterSelected}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={3}>
                        <TextField
                          type="text"
                          name="searchValue"
                          value={this.state.searchValue}
                          placeholder={intl.formatMessage({ id: "search" })}
                          onChange={this.onFieldChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.button}
                          style={{ marginRight: 4, marginLeft: 8 }}
                          onClick={this.callSearch}
                        >
                          <Search />
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} lg={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={this.clearFields}
                          className={classes.button}
                        >
                          <ClearIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            
            <MyMaterialTable
              title=""
              rowClick={(event, rowData) => {
                this.props.addCurrent(rowData);
                if (rowData.classificationId == 0 || (!this.isClient && !this.globalAdm)) {
                  this.props.history.push(
                    `/${this.props.match.params.lang}/chatclassifications`
                  );
                } else {
                  this.props.history.push(
                    `/${this.props.match.params.lang}/chatclassifications-edit`
                  );
                }
              }}
              columns={
              [
                {
                  title: "ID",
                  field: "classificationId",
                },
                {
                  title: "Chat",
                  field: "chatId",
                },
                {
                  title: "Data da classificação",
                  field: "classificationDate",
                },
                {
                  title: "Atendente",
                  field: "assignedUser",
                },
                {
                  title: intl.formatMessage({ id: "csat.requester.to.assigned" }),
                  field: "noteAssigned"
                },
                {
                  title: intl.formatMessage({ id: "client" }),
                  field: "requestUser"
                },
                {
                  title: "Provedor",
                  field: "provider"
                },
              ]}
              data={this.state.resultList}
            />
           
            <Can I="List" a="CalledClassification">
              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination
                    count={this.state.totalPages}
                    page={this.state.page}
                    onChange={this.onChangePaginate}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />}{" "}
                  {this.state.pageEnd} {<FormattedMessage id="of" />}{" "}
                  {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>

        </main>
        <Footer />
      </div>
    );
  }
}

ChatClassifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(ChatClassifications))
);

ChatClassifications.contextType = AbilityContext;