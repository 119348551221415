import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

const styles = theme => ({
  indicator: {
    backgroundColor: 'white',
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box variant="fullWidth" p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function Panels(props) {
    //console.log(props.components);  

    return (
        props.components.map((component, i) => { 
        return (<TabPanel key={i} value={props.value} index={i}>{component}</TabPanel>) 
         })        
    );
    

  }

  function LinkTabs(props) {
    //console.log(props.components);
    //return (<span></span>);
    
    var rows = [];
    var numrows = props.components.length;
    for (var i = 0; i < numrows; i++) {
        if(props.components[i].props.displayName){
          var name = props.components[i].props.displayName;
          //console.log("aqui");
        }  
        else if(props.components[i].type.Naked){
          var name = props.components[i].type.Naked.displayName;
        }
        else{
          var name = props.components[i].type.displayName.replace("withRouter(Connect(WithStyles(","").replace(")","").replace(")","").replace(")","");
        }
        if(props.icons[i]){
            rows.push((<LinkTab  icon={props.icons[i]} key={i} label={name} href={"/"+i} {...a11yProps(i)} />));
        }
        else{
        rows.push((<LinkTab  icon={<NotListedLocationIcon/>} key={i} label={name} href={"/"+i} {...a11yProps(i)} />));
        }
    }
    return  (
        <Tabs
          variant="fullWidth"
          value={props.value}
          onChange={props.handleChange}
          aria-label={props.label}
          TabIndicatorProps={{style: {background:'#ff4081'}}}
            
        >
           {rows} 
        </Tabs>
    );
    
  }

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function NavTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const { label, components, icons, changeSelect } = props;

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
    
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
          <LinkTabs handleChange={handleChange} label={label} value={value} components={components} icons={icons}/>  
      </AppBar>
      <Panels value={value} components={components}/>

    </div>
  );
}
NavTabs.propTypes = {
    label: PropTypes.string,
    components: PropTypes.any.isRequired,
  };
  