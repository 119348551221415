export default function priorities(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              priority: action.priority,
          }
      default:
          return state;
  }
}