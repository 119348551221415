
import Api from './api';

const PATH = '/integration-user-variables';

const create = async (input) => { 
  try {
    const response = await Api.post(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (input) => { 
  try {
    const response = await Api.put(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPaginate = async (page) => {
  try {
    const response = await Api.get(`${PATH}/paginate?page=${page}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getById = async (id) => {
  try {
    const response = await Api.get(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const deleteById = async (id) => {
  try {
    const response = await Api.delete(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  create, deleteById, getAllPaginate, getById,
  update
};
