import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';

import Chatbot from './components/Chatbot/Chatbot';

import './index.scss';

const JointPlusChatbot = ({id, chatbotId} : {id:string, chatbotId: string}) => {
  return (
    <Provider store={store}>
      <div className='container-chatbot-flow'>
        <Chatbot id={id} idChatbot={chatbotId} />
      </div>
    </Provider>
  )
}

export default JointPlusChatbot;
