import React, { Component } from "react";
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { connect } from 'react-redux';
import HeaderMenu from "../../components/header-menu";
import { makeStyles } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';
import Api from "../../services/api";
import TutorialProgress from './components/tutorialProgress';
import { Overlay } from "../../styles/global";
import Footer from '../../components/footer';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.Reload = this.Reload.bind(this);
    this.Reload();

    const { userSession } = this.props;
    this.isClient = true;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.onlyAdmOrganization = false;
    this.isCoordenator = false;
    this.isTechinical = false;

    userSession.user.userrole.map((item, key) => {
        if(item.role.roletype !=3){
            this.isClient = false;
        }
        if(item.role.roletype ==0){
            this.globalAdm =true;
        }
        if(item.role.roletype ==1){
            this.AdmOrganization =true;
        }
        if(item.role.roletype ==4){
            this.isCoordenator =true;
        }
        if(item.role.roletype ==2){
            this.isTechinical =true;
        }
    });
    if(this.AdmOrganization == true && this.globalAdm == false){
      this.onlyAdmOrganization = true;
    }

    if(userSession.user.userrole == null){
      Api.logoff();
    }
    this.state = {
      totalCalled: 0,
      comparation: 0,
      positiveMonth: true,
      totalClassification: 0,
      comparationClassification: 0,
      positiveClassificationMonth: true,
      progressCalled: 0,
      calledStatus: [],
      dataStatus: [],
      dataStatusName: [],
      dataLastFeedback: [],
      dataCalledForMonth: [],
      loading: false,
      organization: 0,
      name: '',
      attNotification: false,
      listNotification: []
    };

    this.getTutorial = this.getTutorial.bind(this);
   
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.location.state === "reload"){
          this.componentDidMount();
      }
  } 

  async verifyOrganizationPendenc(){


    //endpoint antigo que pegava as notificações pro usuário, provavelmente vai ser descontinuado

   /* this.setState({ loading:true })

    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          var alert = false;
          result.data.data.map((item, key) => {
            item.notification.map((itemDois, key) => {
              if(itemDois.required == true && itemDois.finish == false){
                alert = true;
              }
            });
          });
          //console.log(alert);
          if(alert == true){
            this.setState({isTutorial: true});
          }
        }
    });
  
    this.setState({ loading:false });*/

}

  getTutorial(){
    //endpoint antigo que pegava as notificações pro usuário, provavelmente vai ser descontinuado
    /*Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          this.setState({listNotification: result.data.data});
          // console.log(this.state.listNotification);
        }
    });*/
    
  }

  
  componentDidMount() {
  }

  Reload = () => {
    const reload = localStorage.getItem('reload');
    if(reload == "true" ){
        localStorage.setItem('reload', false);
        
        setTimeout(function(){     document.location.reload(true); }, 500);
    }
    return 0;
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, userSession } = this.props;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    var organizationProps = this.props.history.location.state ? this.props.history.location.state.orgId : this.props.userSession.user.orgid
    
    const save = (organization) => {
      this.setState({ organization: organization });
      
      Api.get(`/organizations/${organization}`)
      .then(result => {
        if (result.data.success) {
          this.setState({ name: result.data.data.name });
        }
      });
    }

    const reset = () => {
      return <Redirect push component={Link} to={'/pt/calleds'} />;
    }
    
    const attNotification = () => {
      this.setState({ attNotification: true });
    }

    return (
      <div className={classes.root}>
        
        <HeaderMenu attNotification={this.state.listNotification}/>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} style={{minWidth:'300px'}}/>

            <Grid container>
              <Grid lg={12} sm={12} xl={12} xs={12} style={{padding: '10px'}} >
                <TutorialProgress attNotification={this.getTutorial} name={this.state.name} save={save} organization={this.state.organization} reset={reset} orgId={organizationProps} />
              </Grid>
            </Grid>
            
            {this.state.loading && (
              <Overlay>
                  <CircularProgress color="secondary" />
              </Overlay>
            )}
              
        </main>
        <Footer />                    

      </div>

    );
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Dashboard)));
