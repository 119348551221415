import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import * as Service from '../../../services/apiCallCenter';
import Grid from '@mui/material/Grid'

// import NodeMailer from 'nodemailer'



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

export default function SharedEmail(props) {
    const intl = props.intl;
    const classes = useStyles();

    // const url = window.location.href + props.propsModal.propsModal.cod;
    // const pass = props.propsModal.propsModal.password;

    const url = props.propsModal.cod;
    const pass = props.propsModal.password;

    const [values, setValues] = React.useState({
        email_name: '',
        email: props.propsModal.requestUser.email
    });

    const [loading, setLoading] = React.useState(0);

    const [fruta, setFruta] = React.useState('banana');


    const setLoadingCall = () => {
        setLoading(loading + 1);
    }
    

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const callLoading = (event) => {
        setLoadingCall();
        
    };

    const submitHandler = async (e) => {
        e.preventDefault()

        if(props.propsModal.hirerid != 0 && props.propsModal.hirerid != null && props.propsModal.hirerid != ""){
            props.onLoading();

            //setLoadingCall();
    
    
            let dataSet = {
                urlCall: url,
                passCall: pass,
                nameCall: '',
                emailCall: values.email,
            };
    
            //var result = await Service.sendEmailApi(dataSet);
    
            //window.location = url;
            //props.propsModal.eventInit(values.email);
            props.propsModal.eventInit(values.email, false, 'email', dataSet);
        }
    }

    return (
        <div>
            <form>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={3} xl={3} lg={3}></Grid>
                <Grid item xs={12} sm={6} xl={6} lg={6}>
                    <FormControl fullWidth className={clsx(classes.margin)} variant="outlined">
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            name="email"
                            value={values.email}
                            labelwidth={40}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} xl={3} lg={3}></Grid>
            </Grid>

            
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={submitHandler} type="submit" style={{float: 'right'}}>
                        {this.props.intl.formatMessage({id:"send"})}
                    </Button>
                </Grid>
            </Grid> 
            </form>


        </div>
    )
}