import { PapiroConsole } from "../../utils/papiroConsole"

export default function calleds(state = [], action) {

    PapiroConsole.log("=== called reducer ===")
    PapiroConsole.log(action)

    switch (action.type) {
        case 'ADD_CURRENT_CALLED':
            return {
                ...state,
                called: action.called,
            }
        case 'ADD_ACTIONS_LIST':
            return {
                ...state,
                accompaniments: action.accompaniments,
            }
        case 'ADD_CALLED':
            return {
                ...state,
                [`called-${action.called.id}`]: action.called
            }
        case 'ADD_ACTIONS_LIST_IN_CALLED':
            return {
                ...state,
                [`accompaniments-${action.calledId}`]: action.accompaniments
            }
        case 'ADD_COMPLEMENTAR_INFO_CALLED':
            return {
                ...state,
                hasdisplacement: action.hasdisplacement,
                defaulttechnicalemployeehourvalue: action.defaulttechnicalemployeehourvalue,
                defaulttechnicalvalueforcalled: action.defaulttechnicalvalueforcalled,
                defaulttechnicalkmvaluerotated: action.defaulttechnicalkmvaluerotated,
                costparameter: action.costparameter,
                configdisplacement: action.configdisplacement,
                displacement: action.displacement,
                organizationaddresseslist: action.organizationaddresseslist,
                organizationpatrimonylist: action.organizationpatrimonylist,
                parentcalledlist: action.parentcalledlist,
                organizationcategorylist: action.organizationcategorylist,
                userstechnicallist: action.userstechnicallist,
                typeslist: action.typeslist,
                organizationdepartamentlist: action.organizationdepartamentlist,
                statuslist: action.statuslist,
                finishaccompanimentid: action.finishaccompanimentid
            }
        default:
            return state;
    }
}
