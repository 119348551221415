import { PapiroConsole } from "../../utils/papiroConsole"

export default function accompaniments(state = [], action) {
  PapiroConsole.log("=== accompaniment reducer ===")
  PapiroConsole.log(action)

  switch (action.type) {
    case 'ACCOMPANIMENTS_ADD_ACTIONS_LIST_IN_CALLED':
      return {
        ...state,
        [`accompaniments-${action.calledId}`]: action.accompaniments
    }

    default:
      return state;
  }
}
