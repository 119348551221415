export function addCalledServiceSettingsFileUploadsList(calledservicesettingsfileuploadslist) {
  return {
      type: 'ADD_LIST_CALLED_SERVICE_SETINGS_FILE_UPLOAD',
      calledservicesettingsfileuploadslist,
  }
}

export function addCalledServiceSettingsFileUpload(calledservicesettingsfileupload) {
  return {
      type: 'ADD_CALLED_SERVICE_SETINGS_FILE_UPLOAD',
      calledservicesettingsfileupload,
  }
}