import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dialog, Tooltip, CssBaseline, CircularProgress, Container, Button, Grid, Avatar, Typography, TextField, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/phoneregistrationrequests';
import Api2Talk from "../../services/api2talk";
import Api from '../../services/api';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import { QueuePlayNext } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CachedIcon from '@mui/icons-material/Cached';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { Tabs, Tab, Paper } from '@mui/material';
import RegistrationData from './components/registration-data';
import RegistrySettings from './components/provider-settings';
import ChannelAssociation from './components/channel-association';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { PapiroConsole } from '../../utils/papiroConsole';




const MyPaper = styled(Paper)({
    backgroundColor: (theme) => theme.palette.background.paper,
});

const MyTabs = styled(Tabs)({
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
});

class PhoneRegistrationRequestsEdit extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;
        const { phoneregistrationrequests } = this.props;

        PapiroConsole.log(this.props)
        PapiroConsole.log("this.props aqui")

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            requestnumber: phoneregistrationrequests.phoneregistrationrequests,
            openCancelRegisterNumber: false,
            tabSelected: 0,
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.getNotification = this.getNotification.bind(this);
        this.setLoadingState = this.setLoadingState.bind(this);
    }

    componentDidMount() {
    }

    handleChange = (event, newValue) => {
        this.setState({ tabSelected: newValue });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }


    getNotification(loading, openNotification, notificationVariant, notificationMessage){

        this.setState({ loading: loading, openNotification : openNotification, notificationVariant: notificationVariant, notificationMessage : notificationMessage });
    }

    setLoadingState(loading){

        this.setState({ loading: loading });
    }



    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        const { requestnumber } = this.state;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />

                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={browserHistory.goBack}
                                    >
                                        {<FormattedMessage id="back" />}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                                <AddIcCallIcon />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                Solicitações de registros de telefone <Title />
                            </Typography>

                            <div style={{ marginTop:'20px'}}>
                                <MyPaper square>
                                    <MyTabs
                                        value={this.state.tabSelected}
                                        onChange={this.handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        centered
                                    >
                                        <Tab label="Dados do Registro" />
                                        <Tab label="Configurações do provedor" />
                                        <Tab label="Associação dos Canais" />
                                    </MyTabs>
                                </MyPaper>
                                {this.state.tabSelected === 0 && <RegistrationData getNotification={this.getNotification} setLoadingState={this.setLoadingState} />}
                                {this.state.tabSelected === 1 && <RegistrySettings getNotification={this.getNotification} setLoadingState={this.setLoadingState} />}
                                {this.state.tabSelected === 2 && < ChannelAssociation  getNotification={this.getNotification} setLoadingState={this.setLoadingState}/>}

                            </div>


                        </div>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
PhoneRegistrationRequestsEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    phoneregistrationrequests: state.phoneregistrationrequests
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(PhoneRegistrationRequestsEdit)));
PhoneRegistrationRequestsEdit.contextType = AbilityContext;
