import { Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Styles } from '../../../styles/material-styles';
import { PapiroConsole } from '../../../utils/papiroConsole';

const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  margin: 20px auto;
`;

const StyledTable = styled(Table)`
  min-width: 650px;
  background-color: #ffffff;
`;

const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTableBodyCell = styled(TableCell)`
  && {
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${props => props.delete ? '#f44336' : '#687EFC'};
    &:hover {
      color: ${props => props.delete ? '#f44336' : '#3f51b4'};
    }
  }
`;


class EditableTableService extends Component {
    


    handleInputChange = (e, id, field) => {
        let value;

        if (field === "isPathParam") {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        PapiroConsole.log("entrei aqui1")
        PapiroConsole.log(e)
        PapiroConsole.log(id)
        PapiroConsole.log(field)
        PapiroConsole.log(value)

        this.props.handleInputChangeTable(this.props.type, field, value, id)

    };

    handleAutocompleteChange = (e, value, id, field) => {
        PapiroConsole.log("entrei aqui4")
        PapiroConsole.log(e)
        PapiroConsole.log(value)
        PapiroConsole.log(id)
        //PapiroConsole.log(field)
       
        this.props.handleChangeAutoCompleteTable(this.props.type, value, id, field)

    };

    handleAddRow = (id) => {
        PapiroConsole.log("entrei no handleRow 1")
        PapiroConsole.log(this.props.type)
        PapiroConsole.log(id)
        this.props.handleAddRow(this.props.type, id)
    };

    handleDeleteRow = row => {
        this.props.handleDeleteRow(this.props.type, row);
    };

    render() {
        const { rows,  associationList } = this.props;
        
        return (
            <StyledTableContainer component={Paper}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nome</StyledTableCell>
                            <StyledTableCell>Valor padrão</StyledTableCell>
                            <StyledTableCell>Associação</StyledTableCell>
                            {this.props.type === "paramRows" && <StyledTableCell>É um parâmetro de url ?</StyledTableCell>}
                            <StyledTableCell>Ação</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <StyledTableBodyCell>
                                    {row.isEditable ? (
                                        <StyledTextField
                                            value={row.name}
                                            onChange={(e) => this.handleInputChange(e, row.id, 'name')}
                                        />
                                    ) : (
                                        row.name
                                    )}
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                    {row.isEditable ? (
                                        <StyledTextField
                                            value={row.value}
                                            onChange={(e) => this.handleInputChange(e, row.id, 'value')}
                                        />
                                    ) : (
                                        row.value
                                    )}
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                   
                                        <Autocomplete
                                            options={associationList != null ?  associationList : []}
                                            groupBy={(option) => option.type == 'variable' ? 'Variáveis' : 'Parâmetros'}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Escolha uma opção" variant="outlined" />}
                                            style={{ width: 300 }}
                                            disabled ={!row.isEditable}
                                           // onChange={this.handleChange(e, row.id, )}
                                           value={ this.props.type == "paramRows" ? row && row.integrationserviceparamsrelation ? row.integrationserviceparamsrelation : null : row && row.integrationservicerequestbodyrelation ? row.integrationservicerequestbodyrelation : null  }
                                            onChange={(event, newValue) => this.handleAutocompleteChange(event, newValue, row.id, this.props.type == "paramRows" ? 'integrationserviceparamsrelation' : 'integrationservicerequestbodyrelation'  )}
                                        />
                                    
                                </StyledTableBodyCell>
                                {
                                this.props.type === "paramRows" 
                                && <StyledTableBodyCell>
                                    {row.isEditable ? (
                                        <Switch
                                            value={row.isPathParam}
                                            checked={row.isPathParam}
                                            onChange={(e) => this.handleInputChange(e, row.id, 'isPathParam')}
                                        />
                                    ) : (
                                        row.isPathParam ? "Sim" : "Não"
                                    )}
                                    </StyledTableBodyCell>
                                }
                                <StyledTableBodyCell>
                                    {row.isEditable ? (
                                        <StyledIconButton onClick={() => this.handleAddRow(row.id)}>
                                            <SaveIcon />
                                        </StyledIconButton>
                                    ) : (
                                        <StyledIconButton delete onClick={() => this.handleDeleteRow(row)}>
                                            <Delete />
                                        </StyledIconButton>
                                    )}
                                </StyledTableBodyCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>
        );
    }
}

export default injectIntl(withStyles(Styles)(EditableTableService))