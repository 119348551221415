import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../styles/material-styles";
import { Overlay } from "../../../styles/global";
import Api from "../../../services/api";
import CustomizedSnackbars from "../../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../../components/header-menu";
import { connect } from "react-redux";
import { browserHistory } from "../../../helpers/history";
import { Link } from "react-router-dom";
import SimpleSelect from "../../../components/select/simple";
import SimpleAutoComplete from "../../../components/auto-complete/autocomplete";
import * as Service from "../../../services/organizationpatrimony.service";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../../components/title-name";
import Footer from "../../../components/footer";
import SimpleDatePicker from "../../../components/date-pickers/inline-no-today";
import NumberFormat from "react-number-format";
import SimpleHelp from "../../../components/help";
import SimpleCheckBox from "../../../components/checkbox/check";

class OrganizationPatrimonyRegistration extends Component {
  constructor(props) {
    super(props);
    const { userSession, organizationid } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    this.arrayLenght = 0;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    const defaultvalue = { id: 0, name: "" };

    this.initialState = {
      id:0,
      updatedUser : 0,
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      DepartamentId: 0,
      DepartamentText: "",
      PatrimonySubgroupId: 0,
      PatrimonySubgroupText: "",
      OrganizationId: organizationid ,
      OrganizationText: "",
      PatrimonyGroupId: 0,
      PatrimonyGroupText: "",
      PatrimonyMaterialId: 0,
      keeppatrimony: true,
      PatrimonyMaterialText: "",
      name: "",
      tipping: "",
      qrcode: "",
      garanty: 0,
      value: "",
      serialnumber: "",
      brand: "",
      model: "",
      invoicedate: moment().format("YYYY/MM/DD HH:mm:ss"),
    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      DepartamentList: [],
      PatrimonySubgroupList: [],
      OrganizationList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentDidMount() {
    //this.getDepartaments();
    this.getPatrimonyGroups();
    this.getDepartaments(this.state.item.OrganizationId);
    //this.getPatrimonySubgroups();
    this.getOrganizations();
    this.getDados();
    //this.getPatrimonyMaterials();
  }

  async getDados(){
    this.setState({ loading: true });
    var result = await Service.getPatrimonyOrganizations(this.props.organizationid);
      if (result.success) {
        this.arrayLenght = result.data.length;
        if(result.data.length > 0){
          
          this.setState({
            template: false
          });
          let resultData = result.data[0];
          /*
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['id']: resultData.id,
              ['OrganizationSlaId']: resultData.organizationslaid,
              ['CalledFlowId']:  resultData.calledflowid,
              ['SlaCountTypeId']:  resultData.slacounttypeid,
              ['CategoryId']:  resultData.categoryid,
              ['ParentOrganizationCategoryId']:  resultData.parentorganizationcategoryid,
              ['name'] : resultData.name, 
              ['cancreatecalled'] : resultData.cancreatecalled, 
              ['imageicon'] : resultData.imageicon, 
              ['parentname'] : resultData.parentname, 
              ['servicetime'] : resultData.servicetime, 
              ['solutiontime'] : resultData.solutiontime, 
              ['useslatime'] : resultData.useslatime, 
              ['serviceslanotification'] : resultData.serviceslanotification, 
              ['isdefaultcategory'] : resultData.isdefaultcategory,
            },
            update: true
          }));*/
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['id']: resultData.id,
              ['updatedUser'] : resultData.id,
              ['DepartamentId']: resultData.departament,
              ['PatrimonyGroupId']:resultData.patrimonygroup,
              ['PatrimonySubgroupId']: resultData.patrimonysubgroup,
              ['PatrimonyMaterialId']: resultData.patrimonymaterial,
              ['name'] : resultData.name,
              ['tipping']: resultData.tipping,
              ['serialnumber']: resultData.serialnumber,
              ['brand']: resultData.brand,
              ['model']: result.data.model,
              ['value']: resultData.value,
              ['invoicedate'] : resultData.invoicedate,
              ['garanty'] : resultData.garanty,
              
              
          
            }
          }));
        }
        
        this.setState({ loading: false });
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
};
  async getDepartaments(orgid) {
    this.setState({ loading: true });
    var result = await Service.getDepartaments(orgid);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonySubgroups(patrimonyGroupId) {
    this.setState({ loading: true });
    var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
    if (result.success) {
      this.setState({ loading: false, PatrimonySubgroupList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
      Api.kickoff(result);
    }
  }
  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonyGroups() {
    this.setState({ loading: true });
    var result = await Service.getPatrimonyGroups();
    if (result.success) {
      this.setState({ loading: false, PatrimonyGroupList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonyMaterials(subgroupid) {
    this.setState({ loading: true });
    var result = await Service.getPatrimonyMaterials(subgroupid);
    if (result.success) {
      this.setState({ loading: false, PatrimonyMaterialList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
      Api.kickoff(result);
    }
  }

  mtel(num) {
    num = num.toString().replace(/\D/g, "");
    //
    if (num.toString().length > 5 && num.toString().length < 7) {
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    } else if (num.toString().length >= 7) {
      num = num.replace(/(\d{1,2})$/, ",$1");
      num = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      num = num.replace(/^0+/, "");
    } else if (num.toString().length == 4) {
      num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    } else {
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "value") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { intl } = this.props;

    if(!this.state.item.OrganizationId) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "organization.required.message" })
      })
      return
    }

    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    }

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("departamentid") > -1 &&
        this.state.item[key] != ""
      ) {
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonygroupid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonymaterialid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonysubgroupid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("invoicedate") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else {
        data.append(key, this.state.item[key]);
      }
    }

    if(this.state.item['DepartamentId']){
      data.append('DepartamentId', this.state.item['DepartamentId'].id);
    }

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if(this.arrayLenght > 0)
    {
      Api.put('/organizationpatrimonies', data, config)
      .then(result => {
        const intl = this.props.intl;
        if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });

            this.props.next();

        }
        else{

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
                          notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
        }
        else{
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
                          notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

          });
        }
        Api.kickoff(err);

      });
    }
    else
    {
      Api.post("/organizationpatrimonies", data, config)

      .then((result) => {
        if (result.data.success) {
          if (!this.state.item.keeppatrimony) {
            this.setState({
              item: this.initialState,
              files: [],
            });
          }
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"add.success"}),
          });
          this.getDepartaments();
          this.getPatrimonySubgroups();
          this.getOrganizations();
          this.getPatrimonyGroups();
          this.getPatrimonyMaterials();
          this.props.handleComplete();
          this.props.handleNextBarProgress();
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
          });
        }
        Api.kickoff(err);
      });
    }
   
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

  

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
    if (stateName === "DepartamentId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["DepartamentText"]: text,
        },
      }));
    }
    if (stateName === "PatrimonySubgroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonySubgroupText"]: text,
          ["PatrimonyMaterialId"]: "",
        },
      }));

      if (value.id != 0) {
        this.getPatrimonyMaterials(value.id);
      }
    }
    if (stateName === "OrganizationId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["OrganizationText"]: text,
          ["DepartamentId"]: "",
        },
      }));

      this.getDepartaments(value);
    }
    if (stateName === "PatrimonyGroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyGroupText"]: text,
          ["PatrimonySubgroupId"]: "",
          ["PatrimonyMaterialId"]: "",
        },
      }));
      if (value.id != 0) {
        this.getPatrimonySubgroups(value.id);
      }
    }
    if (stateName === "PatrimonyMaterialId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyMaterialText"]: text,
        },
      }));
    }

    this.setState({ loading: false });
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }
  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    
    return (
      <div className={classes.root}>
        
        
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
         
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                    disabled
                      label={intl.formatMessage({ id: "organization.header" })}
                      options={this.state.OrganizationList}
                      stateName="OrganizationId"
                      changeSelect={this.changeValues}
                      selected={item.OrganizationId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "departament" })}
                      options={this.state.DepartamentList}
                      stateName="DepartamentId"
                      changeSelect={this.changeValues}
                      selected={item.DepartamentId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "menu.patrimonygroups" })}
                      options={this.state.PatrimonyGroupList}
                      stateName="PatrimonyGroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyGroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({
                        id: "menu.patrimonysubgroups",
                      })}
                      options={this.state.PatrimonySubgroupList}
                      stateName="PatrimonySubgroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonySubgroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({
                        id: "menu.patrimonymaterials",
                      })}
                      options={this.state.PatrimonyMaterialList}
                      stateName="PatrimonyMaterialId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyMaterialId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 250 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "tipping" })}
                      inputProps={{ maxLength: 250 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="tipping"
                      onChange={this.handleInputChange}
                      value={item.tipping}
                      required
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label={intl.formatMessage({id:"value"})}
                      variant="outlined"
                      value={item.value}
                      onChange={this.handleInputChange}
                      name="value"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required
                    />
                  </Grid>
                  {/*<Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                    <SimpleHelp message={"Usar . como separador decimal"} />
                  </Grid>*/}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "serial.number" })}
                      inputProps={{ maxLength: 250 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="serialnumber"
                      onChange={this.handleInputChange}
                      value={item.serialnumber}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "brand" })}
                      inputProps={{ maxLength: 250 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="brand"
                      onChange={this.handleInputChange}
                      value={item.brand}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "model" })}
                      inputProps={{ maxLength: 250 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="model"
                      onChange={this.handleInputChange}
                      value={item.model}
                      required
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <SimpleDatePicker
                      label="Data da Nota"
                      name="invoicedate"
                      stateName="invoicedate"
                      changeDate={this.changeValues}
                      currentDate={item.invoicedate}
                      required
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}></Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="filled-number"
                      label={intl.formatMessage({ id: "garanty" })}
                      type="number"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      name="garanty"
                      onChange={this.handleInputChange}
                      value={item.garanty}
                      required
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} style={{ textAlign: "left" }}>
                    <SimpleCheckBox
                      selected={true}
                      label={intl.formatMessage({ id: "keep.called" })}
                      name="keeppatrimony"
                      stateName="keeppatrimony"
                      changeSelect={this.changeValues}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"organization.patrimony.registration"})} />
                  </Grid>
                </Grid>

                <Grid container spacing={0}  style={{marginBottom: '60px', display: 'flex', marginTop:'20px'}}>
                
                  <Grid item xs={12} sm={2} >
                        {this.props.backButton}


                    </Grid>

                    <Grid item xs={12} sm={2} >
                    {this.props.nextButton} 


                    </Grid>
                    <Grid item container xs={12} sm={6} style={{marginLeft:'auto'}} spacing={2}>
                    <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                          {this.props.finishButton}
                    </Grid>
                  </Grid>
              
             </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
      
        <Footer />
      </div>
    );
  }
}
OrganizationPatrimonyRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(
    withStyles(Styles)(OrganizationPatrimonyRegistration)
  )
);
