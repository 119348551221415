import { useState } from "react";
import AddChatbotFlow from "./add-chatbot-flow.js";
import EditChatbotFlow from "./edit-chatbot-flow.js";
import ListChatbotFlow from "./list-chatbot-flow.js";
import { PAGE_ADD, PAGE_EDIT, PAGE_LIST } from "./page-type.js";


const ChatbotFlows = () => {

  const [pageType, setPageType] = useState(PAGE_LIST);

  const handleSaveJsonFlow = () => {
    console.log("@log >> chatbot json", localStorage.getItem("@mexx-flows-chatbot"));

    //const json = getJson(chatbotId);
    //console.log("@log >> save json >> ", json, chatbotId)
  }

  const getJson = (id) => {
    /*const storedValue = JSON.parse(localStorage.getItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY) || '[]');
    const chatbot = storedValue.find(item => item.id == id);

    console.log("@log >> chatbot json", localStorage.getItem("@mexx-flows-chatbot"));

    return chatbot ? chatbot.json : null;*/
  }

  const updateJson = (id, json) => {
    /*const storedValue = JSON.parse(localStorage.getItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY));
    const index = storedValue.findIndex(item => item.id == id);

    if (index !== -1) {
      storedValue[index].json = json;
    } else {
      storedValue.push({ id, json });
    }

    localStorage.setItem(MEXX_FLOWS_CHATBOT_LOCALSTORAGE_KEY, JSON.stringify(storedValue));*/
  }


  return (
    <>
      {pageType === PAGE_LIST && <ListChatbotFlow onChangePage={setPageType} />}
      
      {pageType === PAGE_ADD && <AddChatbotFlow onChangePage={setPageType}/>}
      
      {pageType === PAGE_EDIT && <EditChatbotFlow onChangePage={setPageType}/>}
    </>
  )
}

export default ChatbotFlows;