import Api from './api';



export const getOrganizationsByKnowledgeBaseId = async (id) => { 
  let data = [];
  await Api.get('/knowledgebaseorganizations/getOrganizationsByKnowledgeBaseId/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

