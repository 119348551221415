import React from 'react'
import Path from '../../../images/image-coming-soon.jpg'
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel'
import './styles.css'
import ChatMessageStickerDialog from './message-type-dialog/chat-message-sticker-dialog';
import { PapiroConsole } from '../../../../../utils/papiroConsole';
import PhotoIcon from '@mui/icons-material/Photo';
import StickerIcon from '@mui/icons-material/Collections';
function ChatMessageSticker({ content, id, setLoading, src, title }) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [url, setUrl] = React.useState("")

  React.useEffect(() => {
    return () => {
      setUrl('');
      setOpenDialog(false);
    };
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const download = async e => {
    e.preventDefault()

    if (e.target.classList.contains('omnichannel-chat-message-image') || e.target.classList.contains('omnichannel-chat-message-image-icon') || e.target.classList.contains('omnichannel-chat-message-image-name')) {
      if (openDialog) return

      setLoading(true)
      let result = await GetImageMetaService(id)
      if (result && result.success && result.data && result.data.url) {
        setUrl(result.data.url)
        setTimeout(() => {
          setOpenDialog(true)
          setLoading(false)
        }, 1000)
      } else 
        setLoading(false)
    }
  }

  return (<div
      className="omnichannel-chat-message-image"
      onClick={download} >
    <div className="omnichannel-chat-message-image-icon" >
      <StickerIcon style={{ color: '#545454', fontSize: '3em' }} />
    </div>
    <div className="omnichannel-chat-message-image-name" >
      {content}.sticker
    </div>
    { (url && url.length > 0) && <ChatMessageStickerDialog
      url={url}
      title={title}
      isOpen={openDialog}
      handleCloseDialog={handleCloseDialog} />}
  </div>)
}

export default React.memo(ChatMessageSticker)
