import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#CDDFE7' }}>
      <ReactPlayer
        url={videoUrl}
        controls={true}
        width="500px"
        height="500px"
        style={{ outline: 'none' }}
      />
    </div>
  );
};

export default VideoPlayer;