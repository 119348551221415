export default function techinicalteamorganizationcategories(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                techinicalteamorganizationcategory: action.techinicalteamorganizationcategory,
            }
        default:
            return state;
    }
}
