import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import Select from '../Input/Select/Select';
import { useBaseInspector } from './useBaseInspector';

import ReactQuill from 'react-quill';

import { getCategoriesByOrganization, getOrganizations } from '../../../services/organization.service';
import HelperCustomField from '../../HelperCustomField/HelperCustomField';

interface Props {
    cell: shapes.app.Service;
    chatbotFlowId: string;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
    organizationId: ['attrs', 'organizationId', 'id'],
    categoryId: ['attrs', 'categoryId', 'id'],
};

const CreateTicketInspector = (props: Props): ReactElement => {

  const { cell, chatbotFlowId } = props;

  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [organizationCategoryOptions, setOrganizationCategoryOptions] = useState([]);

  const [label, setLabel] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isTextRich, setIsTextRich] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const assignFormFields = useCallback(async (): Promise<void> => {
      setLabel(cell.prop(cellProps.label));
      setDescription(cell.prop(cellProps.description));
      setIsTextRich(cell.prop(cellProps.isTextRich));
      setOrganizationId(cell.prop(cellProps.organizationId));
      setCategoryId(cell.prop(cellProps.categoryId));

      if (cell.prop(cellProps.organizationId)) {
        await fetchOrganizationCategory(cell.prop(cellProps.organizationId));
      }
  }, [cell]);

  const changeCellProp = useBaseInspector({ cell, assignFormFields });

  const fetchOrganization = async () => {
    const organizations = await getOrganizations();
    setOrganizationOptions(organizations);
  }

  const fetchOrganizationCategory = async (orgId: number) => {
    const categories = await getCategoriesByOrganization(orgId);
    setOrganizationCategoryOptions(categories);
  }

  const changeOrganization = async (orgId: any) => {
    setCategoryId(null);
    changeCellProp(cellProps.organizationId, orgId);
    await fetchOrganizationCategory(orgId);
  }

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
      <>
          <h1>Componente selecionado</h1>

          <label>Label
              <Input type="text"
                      placeholder="Enter label"
                      value={label}
                      disabled={true}
              />
          </label>

          <div 
              style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  marginTop: '8px',
                  marginBottom: '8px'
              }}
          >
              <input 
                  type="checkbox" 
                  style={{
                      height: '16px',
                      width: '16px',
                      margin: '0',
                      padding: '0'
                  }} 
                  checked={isTextRich}
                  onChange={(e) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}
              />
              <label
                  style={{
                      marginTop: '4px'
                  }}
              >
                  É um texto rico ?
              </label>
          </div>

          {
              isTextRich
              ? (
                  <label>Descrição
                      <ReactQuill
                          name="description"
                          style={{ height: 150, marginBottom: '82px' }}
                          fullWidth
                          variant="outlined"
                          id="descriprion"
                          value={description}
                          onChange={(value: any) => changeCellProp(cellProps.description, value)}
                      />
                  </label>
              )
              : (
                  <label>Descrição
                      <Input 
                          type="text"
                          placeholder="Enter description"
                          value={description}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                      />
                  </label>
              )
          }

            <HelperCustomField
                isRichText={isTextRich}
                description={description}
                param1={cellProps.description}
                onChangeDescription={changeCellProp}
                chatbotFlowId={chatbotFlowId}
            />

          <label>Organização
            <Select
              value={organizationId}
              onChange={(e) => changeOrganization(e.target.value)}
              options={organizationOptions}
            />
          </label>

          <label>Categoria
            <Select
              value={categoryId}
              onChange={(e) => changeCellProp(cellProps.categoryId, e.target.value)}
              options={organizationCategoryOptions}
            />
          </label>
      </>
  );
};

export default CreateTicketInspector;
