import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";

import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as componentActions from "../../redux/actions/calledclassifications";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import React, { Component } from "react";
import "./first-element.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Rating from "@mui/material/Rating";
import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import SnackbarsModal from "../snackbarsModal";
import * as CalledService from "../../services/called.service";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CallEndIcon from '@mui/icons-material/CallEnd';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';

//import { getSlaAtendimento, getSlaSolucao } from '../../pages/called.functions';
import { getSlaAtendimento, getSlaSolucao } from '../../pages/called-list/called.functions';
import * as calledsActions from '../../redux/actions/calleds';

//import { addCurrentCalled, addAccompanimentsList } from '../../redux/actions/calleds';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";

class FirstElement extends Component {
  constructor(props) {
    super(props);

 
      this.FirstEvent = null
      const { classes, headerMenu } = this.props;

    this.state = {
      loading: false,
      
    };
   
  }

 

  async componentDidMount() {
    await this.createElements(this.props)
    
  };

  createElements(props)
  {
    
    this.setState({ loading: true });
    var icon = null

   /* if(this.props.icone == "QueuePlayNextIcon")
    {
      icon = (
        <div >
            <QueuePlayNextIcon style={{fontSize: "45px"}} />
        </div>
      )
    }*/
     
          this.FirstEvent = (
              <div style={{textAlign: "center"}}>
                  {this.props.icon}
                  <h3 className ="regularFont">{this.props.title}</h3>
                  <h4 className="grayFont" style={{marginBottom: "20px"}}>{this.props.subtitle}</h4>
                  {this.props.buttonText}
                  {/*
                  <Button
                      variant="contained"
                      color="secondary"
                      style={{fontSize: "14px"}}
                      
                      startIcon={<AddIcon />}
                      component={Link} {...{ to: this.props.link }}
                       >
                    {this.props.buttonText}
                  </Button>*/}
                    
               
        
              </div>
      );

      this.setState({ loading: false });

  }

 

  render() {  
  
   

    
    return (
      <div className="FirstElement">
        {this.FirstEvent}

        {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

      </div>
    );
  }
}

FirstElement.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
  relationshipcalled: state.relationshipcalled.relationshipcalled
});


const mapDispatchToProps = dispatch =>
  //bindActionCreators({ addCurrentCalled, addAccompanimentsList }, dispatch);
  bindActionCreators(calledsActions, dispatch);


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(FirstElement)));