import axios from 'axios';

const UrlApiSaaS = process.env.REACT_APP_API

const ApiSaas = axios.create({
  //baseURL: "https://192.168.10.103:5008/api" // teste
  baseURL: UrlApiSaaS // teste
});

ApiSaas.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
ApiSaas.defaults.headers.post['Accept']='application/json';
ApiSaas.defaults.headers.post['Content-Type'] ='application/json';

let tokensaas = localStorage.getItem('accesstokensaas');

ApiSaas.defaults.headers.common['Authorization']='Bearer '+tokensaas;

export default ApiSaas;
