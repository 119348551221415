import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Grid } from '@mui/material'
import moment from 'moment'

function HistoryCalledOrganizations(props) {

  if (props != null && props.calledData != null && props.calledData.calledorganizations != null && props.calledData.calledorganizations.length > 0) {
    return (
      <Grid container spacing={0}>
        {props.calledData.calledorganizations.map((item, idx) => {
          if (idx == props.calledData.calledorganizations.length - 1) {
            return <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex' }}>
                  <div style={{ marginRight: 4, minWidth: 4, width: 4, height: 'inherit', borderRadius: 4, backgroundColor: '#43a047' }} ></div>
                  <div>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="organization" />: {item && item.nextorganization && item.nextorganization.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="category" />: {item && item.nextorganizationcategory && item.nextorganizationcategory.parentname ? item.nextorganizationcategory.parentname : item.nextorganizationcategory.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="address" />: {item && item.nextaddress && item.nextaddress.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="team" />: {item && item.nexttechinicalteam && item.nexttechinicalteam.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="assigned" />: {item && item.nextassigneduser && item.nextassigneduser.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="departament" />: {item && item.nextorganizationdepartament && item.nextorganizationdepartament.name}</h5>
                    <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="date" />: {item && item.createdat && moment(item.createdat).format("DD/MM/YYYY HH:mm")}</h6>
                    <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="user" />: {item && item.createduserdata && item.createduserdata.name}</h6>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex' }}>
                  <div style={{ marginRight: 4, minWidth: 4, width: 4, height: 'inherit', borderRadius: 4, backgroundColor: '#43a047' }} ></div>
                  <div>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="organization" />: {item && item.previousorganization && item.previousorganization.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="category" />: {item && item.previousorganizationcategory && item.previousorganizationcategory.parentname ? item.previousorganizationcategory.parentname : item.previousorganizationcategory.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="address" />: {item && item.previousaddress && item.previousaddress.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="team" />: {item && item.previoustechinicalteam && item.previoustechinicalteam.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="assigned" />: {item && item.previousassigneduser && item.previousassigneduser.name}</h5>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="departament" />: {item && item.previousorganizationdepartament && item.previousorganizationdepartament.name}</h5>
                    <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="date" />: {moment(props.calledData.openingdate).format("DD/MM/YYYY HH:mm")}</h6>
                  </div>
                </div>
              </Grid>
            </>
          } else {
            return <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex' }}>
                <div style={{ marginRight: 4, minWidth: 4, width: 4, height: 'inherit', borderRadius: 4, backgroundColor: '#43a047' }} ></div>
                <div>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="organization" />: {item && item.nextorganization && item.nextorganization.name}</h5>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="category" />: {item && item.nextorganizationcategory && item.nextorganizationcategory.parentname ? item.nextorganizationcategory.parentname : item.nextorganizationcategory.name}</h5>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="address" />: {item && item.nextaddress && item.nextaddress.name}</h5>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="team" />: {item && item.nexttechinicalteam && item.nexttechinicalteam.name}</h5>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="assigned" />: {item && item.nextassigneduser && item.nextassigneduser.name}</h5>
                  <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, fontWeight: '600' }}><FormattedMessage id="departament" />: {item && item.nextorganizationdepartament && item.nextorganizationdepartament.name}</h5>
                  <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="date" />: {item && item.createdat && moment(item.createdat).format("DD/MM/YYYY HH:mm")}</h6>
                  <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, paddingTop: 5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="user" />: {item && item.createduserdata && item.createduserdata.name}</h6>
                </div>
              </div>
            </Grid>
          }
      })}
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
          <FormattedMessage id="no.data" />
        </Grid>
      </Grid>
    )
  }
}

HistoryCalledOrganizations.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(HistoryCalledOrganizations)))