import Api from './api';
export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};