export default function calledservicesettingsfileuploadvalidations(state = [], action) {
  switch (action.type) {
    case 'ADD_CALLED_SERVICE_SETINGS_FILE_UPLOAD_VALIDATION':
      return {
        ...state,
        calledservicesettingsfileuploadvalidation: action.calledservicesettingsfileuploadvalidation
      }
    default:
      return state;
  }
}