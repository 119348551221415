import * as React from 'react'
import Pdf from '../../../../../../components/pdf/pdf'
import VideoPlayer from '../../../../../../components/video-player'
import { getFileType } from '../../../../../../utils/fileUtils'
import { getAttachmentExtension, getAttachmentNameByURL } from '../../../../../../utils/regexUtils'

function MessageAttachment({ src, title, type }) {
  const [link, setLink] = React.useState("")
  const [attachmentType, setAttachmentType] = React.useState("")
  const [typeAttach, setTypeAttach] = React.useState("")

  React.useEffect(() => {
    if (src && src.length > 0 && src != link) {
      let attachment = getAttachmentNameByURL(src)
      let attachmentExtension = getAttachmentExtension(attachment)
      let attachmentType = getFileType(attachmentExtension)

      setAttachmentType(attachmentType)
      setLink(src)
      setTypeAttach(type)
    }
  }, [src,type])

  const renderAttachment = (link, attachmentType, title) => {
    if (!link || link.length === 0) return null;
  
    const attachmentComponents = {
      image: <img src={link} alt={title} />,
      video: <VideoPlayer videoUrl={link} />,
      pdf: <Pdf url={link} />,
      presentation: <></>,
      spreadsheet: <></>,
      compressed: <></>,
      text: <></>,
      audio: <></>,
      code: <></>,
    };
  
    return attachmentComponents[attachmentType] || null;
  };

  if (typeAttach === "sticker") {
    return <img src={link} alt={title} />;
  } else {
    return renderAttachment(link, attachmentType, title);
  }
}

export default React.memo(MessageAttachment)