import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import * as Service from '../../services/organizationsetting.service';
import Footer from '../../components/footer';

class OrganizationSettingRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff(); 
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    this.initialState = {
    createdUser: userSession.user.id,
    createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    OrganizationId:  this.props.org,
    OrganizationText: '',
    TypeId:  0,
    TypeText: '',
    TechinicalTeamId: 0,
    TechinicalTeamText: '',
    OriginRequestId:  0,
    OriginRequestText: '',
    StatusId:  0,
    StatusText: '',
    canviewcalledfromothertechnical : false, 
    roleclientcanselectcategory : false, 
    canassingusercalled : false,
    estimatedanwserhours: 24,
    canselectpatrimonyincalled: false,
    canselectdepartamentincalled: false,
    canclientselecttypeincalled: false,
    canclientviewslametricsincalled: false,
    canclientselectpatrimonyincalled: false,
    caninformcalledclosingstatus: false,
    canselectorganizationcategoriesbylevels: false,
    caninformzeroincalledtechnicalcost: false,
    canfinalchecklistisrequired: false,
    caninitialchecklistisrequired: false,
    caninforminitialchecklist: false,
    automaticallycreatecalledwhenreadingqr: false,
    allowscreateanonymouscalledsbyemail: false,
    allowattendanceviachat: false,
    requireattachedimage: false,
    restrictviewcalleddepartament: false,
    defaultaccompaniment: false,
    automaticchangestatusaftermobilefinish: false,
    cantechnicalopencustomerreviewlinkonmobile: false,
    caninformutilizedmaterialsincalledfinish: false,
    canselectoriginrequestincalledcreation: false,
    isrequiredselectteamincalledfinish: false,
    finalizationexternalstatus: false,
    canclientfinishcalled: false,
    actionrequiredwhenstatusorteamorassignedchanges : false,
    enablesendingsatisfactionsurveythroughmessagingproviders : false,
    canclientreopencalled: false,
    canclientautomaticchangestatusaftersendaccompaniment: false,
    canarchivecalleds : true,
    allowclientinteractiononclosedcalleds : false,
    disablecallrejectionbyrequester : false,
    caneditaccompanimentinactivecalled: false
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      TypeList: [],
      OriginRequestList: [],
      StatusList: [],
      TechinicalTeamList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTechinicalTeams = this.getTechinicalTeams.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload"){
      this.componentDidMount();
    }
  }    

  async componentDidMount() {
    await this.getOrganizations();
    await this.getTypes();
    await this.getOriginRequests();
    await this.getStatuses();
    await this.getTechinicalTeams();
  };

  async getTechinicalTeams() {
    this.setState({ loading: true });
    var result = await Service.getTechinicalTeamsByOrganizationId(this.state.item.OrganizationId);
    if (result.success) {
      this.setState({ loading: false, TechinicalTeamList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

    async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({  OrganizationList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  }; 
  async getTypes(){
          this.setState({ loading: true });
          var result = await Service.getTypes();
            if (result.success) {
              this.setState({  TypeList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getOriginRequests(){
          this.setState({ loading: true });
          var result = await Service.getOriginRequests();
            if (result.success) {
              this.setState({  OriginRequestList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getStatuses(){
          this.setState({ loading: true });
          var result = await Service.getStatuses();
            if (result.success) {
              this.setState({ loading: false, StatusList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      if(!this.state.item.canselectpatrimonyincalled){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            automaticallycreatecalledwhenreadingqr: false,
            canclientselectpatrimonyincalled: false
          }
        }));
      }
      if(!this.state.item.caninforminitialchecklist){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            caninitialchecklistisrequired: false
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
        else if (key.toString().toLowerCase().indexOf('roleclientcanselectcategory') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, 1);
          }
          else{
            data.append(key, 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canassingusercalled') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canviewcalledfromothertechnical') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else {
          data.append(key, this.state.item[key]);
        }
      }
      // console.log(this.state.item);
      //this.setState({ loading: false });
      //return false;
      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/organizationsettings', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });
              this.getOrganizations();
              this.getTypes();
              this.getOriginRequests();
              this.getStatuses();


          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'TypeId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['TypeText']: text,
            }
          }));
        }
        if(stateName === 'OriginRequestId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OriginRequestText']: text,
            }
          }));
        }
        if(stateName === 'StatusId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['StatusText']: text,
            }
          }));
        }
        if (stateName === "TechinicalTeamId") {
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              ["TechinicalTeamText"]: text,
            },
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    console.log(this.state.item);
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />}
              <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.type.call"})} options={this.state.TypeList} stateName='TypeId' changeSelect={this.changeValues} selected={item.TypeId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.called.origin"})} options={this.state.OriginRequestList} stateName='OriginRequestId' changeSelect={this.changeValues} selected={item.OriginRequestId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"default.type"})} options={this.state.StatusList} stateName='StatusId' changeSelect={this.changeValues} selected={item.StatusId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"attendance.team"})} options={this.state.TechinicalTeamList} stateName='TechinicalTeamId' changeSelect={this.changeValues} selected={item.TechinicalTeamId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"organization.settings.expire"})} type='number' variant='filled' InputLabelProps={{shrink: true}} fullWidth name='estimatedanwserhours' onChange={this.handleInputChange} value={item.estimatedanwserhours} required />
                    </Grid>
                  <Grid item xs={10} sm={6}>
                    <SimpleCheckBox 
                      label={intl.formatMessage({id:"organization.settings.videocall"})} 
                      name='canstartcallcenter' 
                      stateName='canstartcallcenter' 
                      changeSelect={this.changeValues} 
                      selected={item.canstartcallcenter} />
                  </Grid>
                  <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                    <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.video.call"})}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                    <Grid item xs={10} sm={6}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.cliente.categoria"})} name='roleclientcanselectcategory' stateName='roleclientcanselectcategory' changeSelect={this.changeValues} selected={item.roleclientcanselectcategory} />
                    </Grid>
                    <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.client.category"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={7}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.responsavel.chamado"})} name='canassingusercalled' stateName='canassingusercalled' changeSelect={this.changeValues} selected={item.canassingusercalled} />
                    </Grid>
                    <Grid item xs={2} sm={5} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.responsible.pick.called"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call"})} name='canviewcalledfromothertechnical' stateName='canviewcalledfromothertechnical' changeSelect={this.changeValues} selected={item.canviewcalledfromothertechnical} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.responsible.view.calleds"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call.email"})} name='allowscreateanonymouscalledsbyemail' stateName='allowscreateanonymouscalledsbyemail' changeSelect={this.changeValues} selected={item.allowscreateanonymouscalledsbyemail} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.from.email"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})} name='canselectpatrimonyincalled' stateName='canselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canselectpatrimonyincalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.patrimony"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.can.attach.departament.to.called"})} name='canselectdepartamentincalled' stateName='canselectdepartamentincalled' changeSelect={this.changeValues} selected={item.canselectdepartamentincalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.departament"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                {this.state.item.canselectpatrimonyincalled && (
                    <Grid item xs={10} sm={6}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.create.called.read.qrcode"})} name='automaticallycreatecalledwhenreadingqr' stateName='automaticallycreatecalledwhenreadingqr' changeSelect={this.changeValues} selected={item.automaticallycreatecalledwhenreadingqr} />
                    </Grid>)}
                {this.state.item.canselectpatrimonyincalled && (
                    <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.add.patrimony.to.called.create.called.read.qrcode"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>)}
                {this.state.item.canselectpatrimonyincalled && (
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientselectpatrimonyincalled" })} name='canclientselectpatrimonyincalled' stateName='canclientselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canclientselectpatrimonyincalled} />
                    </Grid>)}
                {this.state.item.canselectpatrimonyincalled && (
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientselectpatrimonyincalled.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>)}
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientselecttypeincalled" })} name='canclientselecttypeincalled' stateName='canclientselecttypeincalled' changeSelect={this.changeValues} selected={item.canclientselecttypeincalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientselecttypeincalled.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientviewslametricsincalled" })} name='canclientviewslametricsincalled' stateName='canclientviewslametricsincalled' changeSelect={this.changeValues} selected={item.canclientviewslametricsincalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientviewslametricsincalled.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninformcalledclosingstatus" })} name='caninformcalledclosingstatus' stateName='caninformcalledclosingstatus' changeSelect={this.changeValues} selected={item.caninformcalledclosingstatus} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninformcalledclosingstatus.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canselectorganizationcategoriesbylevels" })} name='canselectorganizationcategoriesbylevels' stateName='canselectorganizationcategoriesbylevels' changeSelect={this.changeValues} selected={item.canselectorganizationcategoriesbylevels} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.canselectorganizationcategoriesbylevels.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninformzeroincalledtechnicalcost" })} name='caninformzeroincalledtechnicalcost' stateName='caninformzeroincalledtechnicalcost' changeSelect={this.changeValues} selected={item.caninformzeroincalledtechnicalcost} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninformzeroincalledtechnicalcost.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canfinalchecklistisrequired" })} name='canfinalchecklistisrequired' stateName='canfinalchecklistisrequired' changeSelect={this.changeValues} selected={item.canfinalchecklistisrequired} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.canfinalchecklistisrequired.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninforminitialchecklist" })} name='caninforminitialchecklist' stateName='caninforminitialchecklist' changeSelect={this.changeValues} selected={item.caninforminitialchecklist} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninforminitialchecklist.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                {item.caninforminitialchecklist && (
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninitialchecklistisrequired" })} name='caninitialchecklistisrequired' stateName='caninitialchecklistisrequired' changeSelect={this.changeValues} selected={item.caninitialchecklistisrequired} />
                    </Grid>)}
                {item.caninforminitialchecklist && (
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninitialchecklistisrequired.info" })}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>)}
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.client.only.views.own.calls"})} name='clientvisualization' stateName='clientvisualization' changeSelect={this.changeValues} selected={item.clientvisualization} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.functionality.enable.client.view.only.own.called"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation"})} name='requiredratingcalleds' stateName='requiredratingcalleds' changeSelect={this.changeValues} selected={item.clientvisualization} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation.title"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.allow.chat.attendance"})} name='allowattendanceviachat' stateName='allowattendanceviachat' changeSelect={this.changeValues} selected={item.allowattendanceviachat} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.allow.technician.chat.attendance.for.clients"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.obligatory.image.attachments"})} name='requireattachedimage' stateName='requireattachedimage' changeSelect={this.changeValues} selected={item.requireattachedimage} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.registration.obligatory.accompaniment.image.attachment"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"restrict.view.called.departament"})} name='restrictviewcalleddepartament' stateName='restrictviewcalleddepartament' changeSelect={this.changeValues} selected={item.restrictviewcalleddepartament} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.registration.user.views.only.calleds.from.own.departament"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"display.default.accompaniments"})} name='defaultaccompaniment' stateName='defaultaccompaniment' changeSelect={this.changeValues} selected={item.defaultaccompaniment} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.registration.user.can.select.standard.actions"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.automaticchangestatusaftermobilefinish"})} name='automaticchangestatusaftermobilefinish' stateName='automaticchangestatusaftermobilefinish' changeSelect={this.changeValues} selected={item.automaticchangestatusaftermobilefinish} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({id:"organizations.settings.automaticchangestatusaftermobilefinish.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.cantechnicalopencustomerreviewlinkonmobile"})} name='cantechnicalopencustomerreviewlinkonmobile' stateName='cantechnicalopencustomerreviewlinkonmobile' changeSelect={this.changeValues} selected={item.cantechnicalopencustomerreviewlinkonmobile} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({id:"organizations.settings.cantechnicalopencustomerreviewlinkonmobile.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.caninformutilizedmaterialsincalledfinish"})} name='caninformutilizedmaterialsincalledfinish' stateName='caninformutilizedmaterialsincalledfinish' changeSelect={this.changeValues} selected={item.caninformutilizedmaterialsincalledfinish} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({id:"organizations.settings.caninformutilizedmaterialsincalledfinish.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.canselectoriginrequestincalledcreation"})} name='canselectoriginrequestincalledcreation' stateName='canselectoriginrequestincalledcreation' changeSelect={this.changeValues} selected={item.canselectoriginrequestincalledcreation} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({id:"organizations.settings.canselectoriginrequestincalledcreation.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.isrequiredselectteamincalledfinish"})} name='isrequiredselectteamincalledfinish' stateName='isrequiredselectteamincalledfinish' changeSelect={this.changeValues} selected={item.isrequiredselectteamincalledfinish} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={intl.formatMessage({id:"organizations.settings.isrequiredselectteamincalledfinish.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.edit.allow.technician.finish.attendance"})} name='finalizationexternalstatus' stateName='finalizationexternalstatus' changeSelect={this.changeValues} selected={item.finalizationexternalstatus} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.edit.show.finished.attendance.status"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called"})} name='canclientfinishcalled' stateName='canclientfinishcalled' changeSelect={this.changeValues} selected={item.canclientfinishcalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called"})} name='canclientreopencalled' stateName='canclientreopencalled' changeSelect={this.changeValues} selected={item.canclientreopencalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment"})} name='canclientautomaticchangestatusaftersendaccompaniment' stateName='canclientautomaticchangestatusaftersendaccompaniment' changeSelect={this.changeValues} selected={item.canclientautomaticchangestatusaftersendaccompaniment} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"can.organization.archive.closed.calleds"})} name='canarchivecalleds' stateName='canarchivecalleds' changeSelect={this.changeValues} selected={item.canarchivecalleds} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.organization.archive.closed.calleds"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                   
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester"})} name='disablecallrejectionbyrequester' stateName='disablecallrejectionbyrequester' changeSelect={this.changeValues} selected={item.disablecallrejectionbyrequester} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds"})} name='allowclientinteractiononclosedcalleds' stateName='allowclientinteractiononclosedcalleds' changeSelect={this.changeValues} selected={item.allowclientinteractiononclosedcalleds} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
              
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled"})} name='caneditaccompanimentinactivecalled' stateName='caneditaccompanimentinactivecalled' changeSelect={this.changeValues} selected={item.caneditaccompanimentinactivecalled} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>

                   
                    <Grid item xs={10} sm={5}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges"})} name='actionrequiredwhenstatusorteamorassignedchanges' stateName='actionrequiredwhenstatusorteamorassignedchanges' changeSelect={this.changeValues} selected={item.actionrequiredwhenstatusorteamorassignedchanges} />
                    </Grid>
                    <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                      <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges.info"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>

                    <Grid container spacing={4}>
                      <Grid item xs={10} sm={5}>
                        <SimpleCheckBox label={this.props.intl.formatMessage({id:"enable.sending.satisfaction.survey.through.messaging.providers"})} name='enablesendingsatisfactionsurveythroughmessagingproviders' stateName='enablesendingsatisfactionsurveythroughmessagingproviders' changeSelect={this.changeValues} selected={item.enablesendingsatisfactionsurveythroughmessagingproviders} />
                      </Grid>
                      <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                        <Tooltip title={this.props.intl.formatMessage({id:"this.feature.enables.sending.satisfaction.survey.at.the.end.of.a.ticket.via.messaging.providers"})}>
                          <HelpIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>

              
                
                    
              
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit} >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
OrganizationSettingRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    org: state.organization.organization
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationSettingRegistration)));
