import React from 'react';
import LogoMarcaCMTECHSmall from '../../images/Logo-cmtech-sm-600px.svg';

const LogoCMTechSmall = props => (
    <div>
        <img width={props.width} src={LogoMarcaCMTECHSmall} title="Logomarca CMTECH" />
    </div>
);

export default LogoCMTechSmall;
