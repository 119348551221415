export function addCurrentCalled(called) {
    return {
        type: 'ADD_CURRENT_CALLED',
        called,
    }
}

export function addCalled(called) {
    return {
        type: 'ADD_CALLED',
        called,
    }
}

export function addAccompanimentsList(accompaniments) {
    return {
        type: 'ADD_ACTIONS_LIST',
        accompaniments,
    }
}

export function addAccompanimentsListInCalled(accompaniments, calledId) {
    return {
        type: 'ADD_ACTIONS_LIST_IN_CALLED',
        accompaniments,
        calledId
    }
}

export function addCalledComplementarInformation(complementar) {
    return {
        type: 'ADD_COMPLEMENTAR_INFO_CALLED',
        hasdisplacement: complementar.hasdisplacement,
        defaulttechnicalemployeehourvalue: complementar.defaulttechnicalemployeehourvalue,
        defaulttechnicalvalueforcalled: complementar.defaulttechnicalvalueforcalled,
        defaulttechnicalkmvaluerotated: complementar.defaulttechnicalkmvaluerotated,
        costparameter: complementar.costparameter,
        configdisplacement: complementar.configdisplacement,
        displacement: complementar.displacement,
        organizationaddresseslist: complementar.organizationaddresseslist,
        organizationpatrimonylist: complementar.organizationpatrimonylist,
        parentcalledlist: complementar.parentcalledlist,
        organizationcategorylist: complementar.organizationcategorylist,
        userstechnicallist: complementar.userstechnicallist,
        typeslist: complementar.typeslist,
        organizationdepartamentlist: complementar.organizationdepartamentlist,
        statuslist: complementar.statuslist,
        finishaccompanimentid: complementar.finishaccompanimentid
    }
}