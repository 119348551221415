import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import AddIcon from '@mui/icons-material/Add';
import { Link, withRouter } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import { getAllByCalledIdWithRequestingUserAndStatusInclude } from '../../services/calledrequestuser.service';
import { PapiroConsole } from '../../utils/papiroConsole';
class CalledRequestingUser extends Component {

    constructor(props) {
        super(props);

        PapiroConsole.log("=== CalledRequestingUser ===")
        PapiroConsole.log(this.props)

        const { userSession, calleds } = this.props;
        const { id } = this.props.match.params
        this.urlId = id

        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            
        });
      
        this.state = {
            calledId: id ? id : calleds.called.id,
            calledOrganizationId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.id,
            techinicalTeamName : calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].techinicalteam ? calleds[`called-${this.urlId}`].techinicalteam.name : '', 
            loading: false,
            displayAssignedInfoMode: 0 ,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            columns : [],
        };

        PapiroConsole.log(this.state.calledOrganizationId)
        PapiroConsole.log("this.state.calledOrganizationId 1")

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        await this.getOrganizationSettings();
        await this.getItensNew();
    }

    async getOrganizationSettings() {

        
        const intl = this.props.intl;

        this.setState({ loading: true });

        await Api.get(`/organizationsettings/organization/${this.state.calledOrganizationId}/withoutincludes`)

            .then(result => {
                if (result.data.success) {

                   
                    
                    if(result.data.data.displayassignedinfomode){
                        var  columns= [
                            { title: intl.formatMessage({id:"previous.requestuser"}), field: 'PreviousRequestingUserName' },
                            { title: intl.formatMessage({id:"next.requestuser"}), field: 'RequestingUserName' },
                            { title: intl.formatMessage({id:"menu.statuses"}), field: 'StatusName' },
                            { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                           
                               
                        ]
                        if(result.data.data.displayassignedinfomode == 1 || (this.isClient == false && result.data.data.displayassignedinfomode != 4)){
                            columns.push( { title: intl.formatMessage({ id: "user" }), field: 'CreatedUserName' })  
                        } 
                    }
                   

                    this.setState({ loading: false, columns : columns});
                }
                else {
                  

                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
               
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });

    }

    getItens = async () => {
        this.setState({ loading: true });
        
      let response = await getAllByCalledIdWithRequestingUserAndStatusInclude(this.state.calledId)
      if (response && response.success) {
        if (response.data != null) {
          const formattedList = response.data.map(item => {
            return {
                PreviousRequestingUserName: item.previousrequestinguser ? item.previousrequestinguser.name : '',
                RequestingUserName: item.requestinguser ? item.requestinguser.name : '',
                StatusName: item.status ? item.status.name : "",
                DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm:ss"),
                CreatedUserName: item.createduserdata ? item.createduserdata.name : ""
            }
          });
          this.setState({ loading: false, resultList: formattedList });
        }
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(response)
      }
    };

    async getItensNew() {
        this.setState({ loading: true });

        PapiroConsole.log(this.state.calledOrganizationId)
        PapiroConsole.log("this.state.calledOrganizationId")
        await Api.get(`/calledrequestuser/new/list/called/withtincludes/requestinguser/status?calledId=${this.state.calledId}&techinicalteamname=${this.state.techinicalTeamName}&organizationid=${this.state.calledOrganizationId}`)

            .then(result => {
                if (result.data.success) {
                    // console.log(result.data.data);
                    PapiroConsole.log(result.data.data);
                    PapiroConsole.log("result.data.data");
                    const formattedList = result.data.data.map(item => {
                        return {
                            PreviousRequestingUserName: item.previousrequestinguser ? item.previousrequestinguser.name : '',
                            RequestingUserName: item.requestinguser ? item.requestinguser.name : '',
                            StatusName: item.status ? item.status.name : "",
                            DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm:ss"),
                            CreatedUserName: item.createduserdata ? item.createduserdata.name : ""
                        }
                    });

                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    PapiroConsole.log("entrei aqui")
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                PapiroConsole.log("entrei aqui 2 ") 
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <MyMaterialTable
                    title={intl.formatMessage({ id: "calledrequestinguser.history.header" })}
                    columns={ this.state.columns?.length > 0 ? this.state.columns :[
                        { title: intl.formatMessage({id:"previous.requestuser"}), field: 'PreviousRequestingUserName' },
                        { title: intl.formatMessage({id:"next.requestuser"}), field: 'RequestingUserName' },
                        { title: intl.formatMessage({id:"menu.statuses"}), field: 'StatusName' },
                        { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                        { title: intl.formatMessage({ id: "user" }), field: 'CreatedUserName' }
                    ]}
                    data={this.state.resultList}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
CalledRequestingUser.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    calleds: state.calleds,
});

export default injectIntl(withRouter(connect(mapStateToProps, null)(withStyles(Styles)(CalledRequestingUser))));

CalledRequestingUser.contextType = AbilityContext;