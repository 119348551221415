import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { PapiroConsole } from '../utils/papiroConsole'
import CustomizedSnackbars from "../components/material-snackbars";

import Sound from "../assets/audio.wav"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as componentActions from '../redux/actions/header-menu';

export const ChatHubContext = createContext();

//PapiroConsole.log("=== ChatHubContext===")
//PapiroConsole.log(ChatHubContext)

const isClient = (userSession) => {
  let isClient = true;
  PapiroConsole.log("entrei aqui userSession ")
  PapiroConsole.log(userSession)
  if (userSession != null) {
    userSession.user.userrole.map(item => {
      if (item.role.roletype != 3) {
        isClient = false
      }
    })

  }


  return isClient
};

//function MessageHub2TalkProdiver(props) {

function ChatHubProdiver( props ) {
  const userSession = useSelector((state) => state.userSession);

  const [chatHub, setChatHub] = useState({});
  const [currentTechnicianChat, setCurrentTechnicianChat] = useState();
  const [currentClientChat, setCurrentClientChat] = useState();
  const [openChatList, setOpenChatList] = useState([]);
  const [closedChatList, setClosedChatList] = useState([]);

  const [notifications, setNotifications] = useState([]);

  const [currentMessages, setCurrentMessages] = useState(null);
  const [removeChat, setRemoveChat] = useState(null);
  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const closeAlertMessage = () => setAlertNotification({ ...alertNotification, isOpen: false });
  const openAlertMessage = (variant, message) => setAlertNotification({ variant, message, isOpen: true });
  const [error, setError] = useState(false);


  //? Serve para quando algum chat tem o número de mensagens não vistas alterado
  //? dessa forma, podemos alterar a badge de mensagens não lidas de uma chat na lista
  const [chatUpdated, setChatUpdated] = useState(null);

  const [newChat, setNewChat] = useState(null);
  const [newMessage, setNewMessage] = useState(null);


  

  const setChatHubConnection = connection => setChatHub(connection);
  const setErrorMessage = ErrorMessage => setErrorMessage(ErrorMessage);



  

  const setNewPrivateMessage = (message, selectedChat, senderId) => {
    PapiroConsole.log("teste de envio de mensagem 1")
    if (message && selectedChat) {
      setNewMessage(message)
      setChatUpdated(selectedChat)
      //x = x+1 
      //setUpdateState(x)
    }
  };

  const setNotificationSound = () => {
    PapiroConsole.log("teste de envio de mensagem 2")
    playNotificationSound()
  };

  const playNotificationSound = () => {
    PapiroConsole.log("teste de envio de mensagem 3")
    //const absolutePath = 'C:\\Users\\CMTech\\Desktop\\audio\\audio.mp3'; // Caminho absoluto no seu sistema de arquivos
  const audio = new Audio(Sound);
  audio.play();
  };

  const newCalledCreated = (chat) => {
    
    PapiroConsole.log("entrei aqui no newCalledCreated ")
    PapiroConsole.log("chat do chamado aqui ")
    PapiroConsole.log(chat)
    if (chat) {
      setChatUpdated(chat)
      setCurrentTechnicianChat(chat)
    }
  };
  const ShowError = (message, selectedChat, senderId) => {
    PapiroConsole.log(message)
    PapiroConsole.log(selectedChat)
    PapiroConsole.log(senderId)
    PapiroConsole.log("erro de mensagens aqui")
    if (message && selectedChat) {
      PapiroConsole.log("SHOW ERROR -> " + message);
      //setErrorMessage(message);
      setAlertNotification({ variant: "error", message: message.toString(), isOpen: true });
      setError(true);
    }
  };
  const Ok = (message, selectedChat, senderId) => {
    if (message) {
      PapiroConsole.log("OK -> " + message);
      setError(false);
    }
  };

  const UpdateChatInTheList = (chat) => {
    PapiroConsole.log("teste de envio de mensagem 4")

    PapiroConsole.log("chat aqui UpdateChatInTheList ")
    PapiroConsole.log(chat)
    if (chat) {

      setChatUpdated(chat)
    }
  };



  const setOpenChatListInitialValue = (chats) => {
    PapiroConsole.log("teste de envio de mensagem 5")
    PapiroConsole.log(chats)
    PapiroConsole.log("chats aqui")
    setOpenChatList(chats);
  };

  const setClosedChatListInitialValue = (chats) => {
    PapiroConsole.log("teste de envio de mensagem 6")
    PapiroConsole.log(chats)
    PapiroConsole.log("chats aqui 2")
    setClosedChatList(chats);
  };

  const setAllNotifications = (notifications) => {
    PapiroConsole.log("teste de envio de mensagem 7")

    
    PapiroConsole.log("notifications aqui")
    PapiroConsole.log(notifications)  
    PapiroConsole.log("peguei notificações iniciais")

    PapiroConsole.log("entrei no NOTIFICATION_MENU_REFRESHED 0  ")


    localStorage.setItem('notificationMenuRefreshed_'+userSession.user.id, true);

    //props.notificationMenuRefreshed(true);
    
    setNotifications(notifications);
  };

  const setNewNotification = (notification) => {
    PapiroConsole.log("teste de envio de mensagem 8")

    PapiroConsole.log("nova notificação aqui")
    PapiroConsole.log(notification)
  };

  const loadChatMessages = (messages, chat) => {
    PapiroConsole.log("teste de envio de mensagem 9")
    PapiroConsole.log("chat aqui")
    PapiroConsole.log(chat)
    PapiroConsole.log(messages)
    PapiroConsole.log("messages aqui no chathub")

    if (messages && chat) {
      setCurrentMessages(messages);
      if (isClient(userSession)) {
        setCurrentClientChat(chat)
      }
      else {
        setCurrentTechnicianChat(chat)
      }
    }


  };

  const handleNewChat = (chat, username, organization) => {

    setNewChat(chat);

    /*if (isClient(userSession)) {
      
      setNewChat(chat);
      
    } else {
      //* Colocar alguma notificação para o tecnico saber que há um novo chat
      setAlertNotification({variant: "info", message: "Uma nova mensagem no chat chegou para você!", isOpen: true});
      setNewChat(chat);
    }*/
  };

  const closeChat = (chatId, userId, chat) => {


    setRemoveChat({ chatId, userId, chat });

    /*if (isClient(userSession)) {
      setCurrentClientChat(chat)
    }
    else {
      setCurrentTechnicianChat(chat)
    }
    //setRemoveChat({ chatId, userId });*/
  }
  useEffect(() => {

  }, [error]);

  const sendAccompanimentNotification = (messages, chat) => {
    PapiroConsole.log("enviei uma nova notificação")
    


  };

  useEffect(() => {
    if (newMessage) {
      if (isClient(userSession)) {
        if (currentClientChat && currentClientChat.id == newMessage.chatId) {

          if (currentMessages && currentMessages.length > 0) {
            setCurrentMessages([...currentMessages, newMessage]);
          } else {
            setCurrentMessages([newMessage]);
          }

        }

      } else if (currentTechnicianChat && currentTechnicianChat.id === newMessage.chatId) {
        if (currentMessages && currentMessages.length > 0) {
          setCurrentMessages([...currentMessages, newMessage]);
        } else {
          setCurrentMessages([newMessage]);
        }
      }
    }
    return () => setNewMessage(null);
  }, [newMessage])

  useEffect(() => {
    if (newChat) {
      PapiroConsole.log("new chat aqui")
      PapiroConsole.log(newChat)
      // setOpenChatList([...openChatList, newChat])
      setOpenChatList([newChat, ...openChatList])
    }
    return () => setNewChat(null);
  }, [newChat])

  useEffect(() => {
    //? Validações para evitar que um chat errado seja excluído equivocadamente
    if (removeChat) {
      if (isClient(userSession)) {
        if (userSession.user.id === removeChat.userId) {
          setOpenChatList(openChatList.filter(chat => chat.id !== removeChat.chatId))
          setClosedChatList([removeChat.chat, ...closedChatList])
        }
        if (currentClientChat && currentClientChat.id === removeChat.chatId) {
          setCurrentClientChat(null);
          setCurrentMessages([]);
        }
      } else {
        if (userSession.user.id === removeChat.userId) {
          setOpenChatList(openChatList.filter(chat => chat.id !== removeChat.chatId))
          setClosedChatList([removeChat.chat, ...closedChatList])
        }
        if (currentTechnicianChat && currentTechnicianChat.id === removeChat.chatId) {
          setCurrentTechnicianChat(null);
          setCurrentMessages([]);
        }
      }
    }
    return () => setRemoveChat(null);
  }, [removeChat])

  useEffect(() => {

    PapiroConsole.log("effect do chat update")
    var openChats = false;
    if (chatUpdated) {
      if (openChatList && !isClient(userSession)) {
        PapiroConsole.log("effect do chat update 1 ")

        PapiroConsole.log(openChatList)
        const updatedChatList = openChatList.map(chat => {

          if (chat.id === chatUpdated.id) {
            openChats = true
            PapiroConsole.log("effect do chat update  2")
            return chatUpdated
          }
          return chat
        });
        setOpenChatList(updatedChatList)

      } else if (isClient(userSession)) {
        const updatedChatList = openChatList.map(chat => {
          if (chat.id === chatUpdated.id) {
            openChats = true;
            return chatUpdated
          }
          return chat
        });
        setOpenChatList(updatedChatList)
      }

      if (closedChatList && openChats == false) {
        const updatedChatList = closedChatList.map(chat => {
          if (chat.id === chatUpdated.id) {

            return chatUpdated
          }
          return chat
        });
        setClosedChatList(updatedChatList)
      }
    }
    return () => setChatUpdated(null);
  }, [chatUpdated])

  





  return (
    <ChatHubContext.Provider value={{
      chatHub,
      setChatHubConnection,
      openChatList,
      closedChatList,
      setOpenChatListInitialValue,
      setClosedChatListInitialValue,
      setAllNotifications,
      setNewNotification,
      setCurrentMessages,
      currentMessages,
      notifications,
      setNotifications,
      currentClientChat,
      setCurrentClientChat,
      setNewPrivateMessage,
      setNotificationSound,
      newCalledCreated,
      handleNewChat,
      loadChatMessages,
      UpdateChatInTheList,
      currentTechnicianChat,
      closeChat,
      sendAccompanimentNotification,
      ShowError,
      Ok,
      setErrorMessage,
      error, setError,

    }}>
      {props.children}

      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />

    </ChatHubContext.Provider>
  )
}


const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});


const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export function useChatHubContext() {
  const context = useContext(ChatHubContext);



  return context;
}

export default connect(mapStateToProps, mapDispatchToProps)((ChatHubProdiver));