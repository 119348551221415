import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Menu, QueuePlayNext, ChevronLeft, Home } from '@mui/icons-material';
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
//import '../../global-admin.css';
import UserMenu from '../user-menu-admin';
import UserInfo from '../user-info-admin';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as headerMenuActions from '../../redux/actions/header-menu';

import { AbilityContext } from '../../config/ability-context'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import Api from '../../services/api';
import LogoSmall from '../logo/logo-small';
import LogoSmall2 from '../logo/logo-small-2';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import AppMenu from '../appmenu/appmenu'
import * as Service from "../../services/organizationsetting.service";
import { Overlay } from "../../styles/global";
import { CircularProgress } from "@mui/material";
import CustomizedSnackbars from "../material-snackbars";

function ListItemLink(props) {
  const { icon, primary, to, state } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={{
      pathname: to,
      state: state
    }} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}


ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  state: PropTypes.string,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }),
)
const drawerWidth = 240;

class HeaderMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      subsopen: [false, false, false, false],
      matches: window.matchMedia("(max-width: 660px)").matches,
      style: { backgroundColor: "#3c4043", paddingLeft: '30px', color: '#ffffff', fontSize: '20px' },
      styleMobile: { paddingLeft: '15px', color: '#ffffff', fontSize: '15px' },
      modalopen: false,
      modalgroup: false,
      WindowSize: window.innerWidth,
      marginLeft: window.innerWidth > 540 ? ((window.innerWidth - 540) * 0.8) : ((window.innerWidth - 310) * 0.8),
      GroupList: [],
      variantDot: '',
      listNotification: [],
      qtdNotification: 0,
      openSteps: false,
      orgId: 0,
      openCheckEmail: false,
      OrganizationList: [],
      organizationCheckEmail: 0,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
    };
    // console.log(props);
    this.handleResize = this.handleResize.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.QRCode = this.QRCode.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalGroup = this.openModalGroup.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
  }

  QRCode() {
    this.openModal();
  }

  calculate() {
    if (this.state.WindowSize > 540) {
      this.setState({ marginLeft: ((this.state.WindowSize - 540) * 0.8) });
    } else {

      this.setState({ marginLeft: ((this.state.WindowSize - 310) * 0.8) });
    }


  }

  closeNotification = () => {
    this.setState({ openNotification: false });
  }

  changeValuesCheckEmail = async (stateName, value, text = '') => {
    console.log(value)
    console.log("changeValuesCheckEmail")
    await this.setState({ organizationCheckEmail: value });
  }

  getOrganizations = async () => {

    var result = await Service.getOrganizations(0);
    if (result.success) {
      this.setState({ OrganizationList: result.data });
    } else {
      // console.log("erro get organizations")
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  openModal() {
    this.setState({ modalopen: true });

  }
  async closeModal() {
    this.setState({ modalopen: false });

  }

  openModalGroup() {
    this.setState({ modalgroup: true });

  }
  async closeModalGroup() {
    this.setState({ modalgroup: false });

  }

  async logoff() {
    document.location.href=`/pt/admin`
  }

  handleClick = (i) => {

  }
  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth })

    this.calculate();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const handler = (e) => {
      this.setState({ matches: e.matches })
    };
    window.matchMedia("(max-width: 660px)").addListener(handler);

  }

  openCheckEmail = async () => {
    this.setState({ openCheckEmail: true, loading: true });
    await this.getOrganizations();
    this.setState({ openCheckEmail: true, loading: false });
  }

  closeCheckEmail = () => {
    this.setState({ openCheckEmail: false });
  }

  componentWillUnmount() {
    window.addEventListener("resize", null);
  }

  handleDrawerOpen = () => {
    this.props.location.state = "";
    this.props.toggle(true);

  };
  handleReload = () => {

    const { router, params, match, location, history, routes } = this.props

    const current = location.pathname;
    this.props.location.state = "reload";
    this.props.reload(true);

  }
  handleDrawerClose = () => {
    this.props.location.state = "";
    this.props.toggle(false);
  };

  checkEmail = async () => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    console.log(this.state)
    console.log("checkEmail")
    await Api.get('/emails/organization/' + this.state.organizationCheckEmail).then(result => {

      if (result.data.success) {
        if (result.data.data > 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({id:"called.list.notification.was.created"}) + result.data.data + this.props.intl.formatMessage({id:"called.list.notification.called.with.success"}),
            resultList: []
          });
          if(window.location.pathname.split('/')[2] == "calleds"){
            this.props.location.state = "reload";
            this.props.reload(true);
          }
          
        } else if (result.data.data == 1) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({id:"called.list.notification.was.created.plural"}) + result.data.data + this.props.intl.formatMessage({id:"called.list.notification.called.with.success.plural"}),
            resultList: []
          });

          if(window.location.pathname.split('/')[2] == "calleds"){
            this.props.location.state = "reload";
            this.props.reload(true);
          }
        } else {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: this.props.intl.formatMessage({id:"called.list.notification.empty.mailbox"}),
            resultList: []
          });
          Api.kickoff(result);
        }

        this.componentDidMount();
      }
    }).catch(err => {
      // console.log(err);
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(err);

    });;

    this.setState({ loading: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, userSession, history, location } = this.props;
    const theme = createTheme({
      overrides: {
        MuiIconButton: {
          root: {
            '&:hover': {
              backgroundColor: "$labelcolor"
              /*padding : "3px",
              borderRadius: "10px"*/
            }
          },

        }

      }
    })

    const divStyle = {
      day: {
        backgroundColor: "inherit !important",

        "&:focus": {
          outline: "none"
        },
        ".MuiIconButtonRoot": {

          padding: "0px"

        }
      }
    };
    const appMenuItems = [];

    /*
        GlobalAdministrator = 0,
        Administrator = 1,
        Technical = 2,
        Client = 3,
        Coordenator = 4,    
    */
    //console.log(userSession);
    this.isAdmGlobal = true;

    appMenuItems.push(
    {
      name: this.props.intl.formatMessage({ id: "hirer.title" }),
      link: `/${this.props.match.params.lang}/admin/hirer`,
      Icon: Home,
    });


    if (this.state.redirect) {
      return <Redirect push component={Link} to={location.pathname} />;
    }
    if (this.state.openSteps) {
      if (this.state.orgId != 0) {
        this.setState({ openSteps: false, orgId: 0 })
        return <Redirect push component={Link} to={{ pathname: "/pt/dashboard", state: { orgId: this.state.orgid } }} />
      }
    }

    const intl = this.props.intl;
    var organizationName = "";
    var GroupList = [];

    organizationName = <FormattedMessage id="Administração" />;

    /*
  const handleSteps = (id) => (
    //this.setState({ openSteps: true, orgId: id })
    
  )*/

    return (
      <div>
        <AppBar
          position="fixed"
          style={{backgroundColor: "#3c4043"}}
          //color="#3c4043"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: headerMenu.open,
          }, classes.appBarAction, {
            [classes.appBarActionShift]: actionsHeaderMenu.open
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, headerMenu.open && classes.hide)}

            >
              <Menu />
            </IconButton>
            <LogoSmall2 />
            {!this.state.matches && <Typography variant="h6" className={classes.title}>
              <div className={classes.title} style={this.state.style}>

                {GroupList.length <= 4 && <div>
                  {this.isAdmGlobal && (organizationName)}

                </div>}

              </div>
            </Typography>}

            {!this.state.matches && <UserMenu />}
            {!this.state.matches && <UserInfo name={this.props.userSession.user.name} /> }
          </Toolbar>
        </AppBar>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={headerMenu.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          //color="#3c4043"
        >
          <div className={classes.drawerHeader}>
            <ThemeProvider theme={theme}>

              <IconButton onClick={this.handleDrawerClose} style={divStyle.day}>
                <LogoSmall /><ChevronLeft />
              </IconButton>

            </ThemeProvider>
          </div>
          <Divider />

          <AppMenu appMenuItems={appMenuItems} />

        </Drawer>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attNotification: PropTypes.array
};



const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(headerMenuActions, dispatch);



export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HeaderMenu))));
HeaderMenu.contextType = AbilityContext;