import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import PositionedSnackbar from '../../components/material-snackbars/snackbar';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/group.service';
import * as componentActions from '../../redux/actions/groups';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl'; 
import Title from '../../components/title-name';

class GroupEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,groups } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if(!groups || (groups && !groups.group)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      updatedUser: userSession.user.id,
       id: groups.group.id,
     name : groups.group.name, 

     };         
    this.state = {
      group: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
     };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      group: {
        ...prevState.group,
        [name]: value
      }
    }));
  };
  getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  getCsrfToken = () => {
    return Api.get('antiforgery/token')
        .then(data => data)
        .catch(error => console.error('Error fetching CSRF token:', error));
  };


  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          group: {
            ...prevState.group,
          }
        }));
      } else {
        this.setState(prevState => ({
          group: {
            ...prevState.group,
          }
        }));
      }

      let xsrfToken = this.getCookie('X-XSRF-TOKEN');   //*get AntiforgeryToken from Cookies
      //alert(JSON.stringify(xsrfToken));

      await this.getCsrfToken().then(csrfToken => {
        //alert(JSON.stringify(csrfToken));

        xsrfToken= csrfToken.data;
      });
      

      for (let key in this.state.group) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.group[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.group[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };
      if(xsrfToken != null){
        //alert(xsrfToken);

        config.headers['X-XSRF-TOKEN']=xsrfToken;
        data.append("X-XSRF-TOKEN",xsrfToken);

      }
        Api.put('/groups', data, config)
 
        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      group: {
        ...prevState.group,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, groups } = this.props;
    const { group } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />} <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                      <TextField inputProps={{ maxLength: 250 }} fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={group.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
GroupEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    groups: state.groups
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(GroupEdit)));
