import React, { Component } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import HeaderMenu from '../../components/header-menu'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import Api from '../../services/api'
import MyMaterialTable from '../../components/table'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import { FormattedMessage, injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import * as Service from '../../services/defaultaction.service'
import * as componentActions from '../../redux/actions/defaultactions'
import { PapiroConsole } from '../../utils/papiroConsole'
import SimpleSelect from '../../components/select/simple-search';
import fileDownload from 'js-file-download'
import ApiReports from '../../services/apireport'

class DefaultActions extends Component {

  constructor(props) {
    super(props);

    this.typingTimeout = null;

    const intl = this.props.intl;

    let filters = [
      { name: intl.formatMessage({ id: "title" }), id: "title" },
      { name: intl.formatMessage({ id: "action" }), id: "text" },
      { name: intl.formatMessage({ id: "author" }), id: "createduser" },
      { name: intl.formatMessage({ id: "updated.user" }), id: 'updateduser' },
      { name: intl.formatMessage({ id: "active" }), id: "active" }
    ];

    const { userSession } = this.props;
    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      resultList: [],
      TimeZoneList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      filterSelected: '',
      filters: filters
    }

    this.changeValues = this.changeValues.bind(this);
    this.callSearch = this.callSearch.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.clearFields = this.clearFields.bind(this)
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getItens(page)
  }

  setPagination(count, page) {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10) {
          this.setState({ pageStart: 1, pageEnd: count })
        } else {
          this.setState({ pageStart: 1, pageEnd: 10 })
        }
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10) {
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        } else {
          this.setState({ pageStart: pageStart, pageEnd: count })
        }
      }
    } else {
      this.setState({ totalPages: 0, page: 1, count: 0 })
    }
  }

  onFieldChange(event) {
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch() {
    if (this.state.searchValue != '') {
      this.getItens(this.state.page)
    }
  }

  async clearFields() {
    await this.setState({ filterSelected: '', searchValue: '', page: 1 })
    this.getItens(1)
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ filterSelected: value })
  }

  async deleteItem(data, resolve) {
    let abl = this.context.can('Delete', 'DefaultAccompaniment')
    if (abl == false) {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
      })
      resolve()
      return false
    }

    var result = await Service.deleteItem(data.id)
    if (result && result.success) {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
        resultList: []
      })
      this.getItens()
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
    resolve()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  async componentDidMount() {
    this.getItens(1)
  }

  async getItens(page = 1) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.paginate(page, this.state.searchValue, this.state.filterSelected);
    if (result.success) {
      console.log(result)
      console.log("result aqui")
      let count = result.count
      this.setState({ count: count })
      this.setPagination(count, page)

      const formattedList = result.data.map(item => {

        let body = item.text
        let temp = document.createElement("div")
        temp.innerHTML = body
        let sanitized = temp.textContent || temp.innerText

        const createdAt = item.createdat ? new Date(item.createdat) : null
        const updatedAt = item.updatedat ? new Date(item.updatedat) : null
        return {
          ...item,
          name: sanitized,
          CreatedAt: createdAt ? createdAt.toLocaleString() : "",
          createdusername: item.createduser ? item.createduser.name : "",
          updatedusername: item.updateduser ? item.updateduser.name : "",
          UpdatedAt: updatedAt ? updatedAt.toLocaleString() : "",
          Active: item.active ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })
        }
      })

      this.setState({ loading: false, resultList: formattedList })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  getExcel = () => {
    this.setState({ loading: true });
    const intl = this.props.intl;
    let newFilter = this.state.filterSelected
    let newSearch = this.state.searchValue
    newFilter = newFilter.split('@')
    newSearch = newSearch.split('@')
    var url = '/report/defaultaccompaniment/getexcel';

    let title = ""
    let text = ""
    let createduser = ""
    let updateduser = ""
    let active = ""
    newFilter.forEach((filter, i) => {
      switch (filter) {
        case "title":
          title += title == "" ? newSearch[i] : "," + newSearch[i]
          break;
        case "text":
          text += text == "" ? newSearch[i] : "," + newSearch[i]
          break;
        case "createduser":
          createduser += createduser == "" ? newSearch[i] : "," + newSearch[i]
          break;
        case "updateduser":
          updateduser += updateduser == "" ? newSearch[i] : "," + newSearch[i]
          break;
        case "active":
          active += active == "" ? newSearch[i] : "," + newSearch[i]
          break;
        default:
          break;
      }
    });

    url += `?title=${title}&text=${text}&createduser=${createduser}&updateduser=${updateduser}&active=${active}`;

    ApiReports.get(url, {
      responseType: 'blob'
    })
      .then((result) => {
        if (result)
        fileDownload(result.data, `export_defaultaccompaniment.zip`);

        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: error.data && error.data.response && error.data.response.data && error.data.response.data.errors && error.data.response.data.errors[0] ? error.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
      });

  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    if (Api == null) {
      var l = this.props.match.params.lang
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main className={clsx(classes.content, {
          [classes.contentShift]: headerMenu.open,
        }, classes.actionsContent, {
          [classes.actionscontentShift]: actionsHeaderMenu.open,
        })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />
            <Grid container className={classes.mb20} spacing={2}>
              <Grid item>
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  disabled={!this.context.can('Add', 'DefaultAccompaniment')}
                  className={classes.button}
                  startIcon={<AddIcon />}
                  component={Link} {...{ to: `/${this.props.match.params.lang}/default-actions-registration` }} >{<FormattedMessage id="add" />}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!this.context.can('Add', 'KnowledgeBase')}
                  size='large'
                  className={classes.button}
                  onClick={this.getExcel}
                >
                  {intl.formatMessage({ id: "export.plan" })}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={8} />
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card className="background-title-search">
                  <CardContent>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                          {<FormattedMessage id="default.actions" />}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <SimpleSelect
                          label={intl.formatMessage({ id: "field" })}
                          options={this.state.filters}
                          stateName='filter'
                          changeSelect={this.changeValues}
                          selected={this.state.filterSelected} />
                      </Grid>
                      <Grid item>
                        <Search />
                      </Grid>
                      <Grid item>
                        <TextField type="text"
                          name="searchValue"
                          value={this.state.searchValue}
                          placeholder={intl.formatMessage({ id: "search" })}
                          onChange={this.onFieldChange} />
                        <Button
                          variant='contained'
                          color='secondary'
                          size='large'
                          className={classes.button}
                          style={{ marginRight: 4, marginLeft: 8 }}
                          onClick={this.callSearch} >
                          <Search />
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          size='large'
                          onClick={this.clearFields}
                          className={classes.button} >
                          <ClearIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Can I='List' a='DefaultAccompaniment'>
              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {
                  let abl = this.context.can('Edit', 'DefaultAccompaniment')
                  if (abl == false) {
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: 'error',
                      notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
                    })
                    return false
                  }
                  this.props.addCurrent(rowData)
                  this.props.history.push(`/${this.props.match.params.lang}/default-actions-edit`)
                }}
                columns={[
                  { title: intl.formatMessage({ id: "title" }), field: 'title' },
                  { title: intl.formatMessage({ id: "action" }), field: 'name' },
                  { title: intl.formatMessage({ id: "author" }), field: 'createdusername' },
                  { title: intl.formatMessage({ id: "created.at" }), field: 'CreatedAt' },
                  { title: intl.formatMessage({ id: "updated.user" }), field: 'updatedusername' },
                  { title: intl.formatMessage({ id: "updated.at" }), field: 'UpdatedAt' },
                  { title: intl.formatMessage({ id: "active" }), field: 'Active' },
                ]}
                data={this.state.resultList}
                deleteItem={this.deleteItem} />
            </Can>
            <Can I='List' a='DefaultAccompaniment'>
              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination
                    count={this.state.totalPages}
                    page={this.state.page}
                    onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

DefaultActions.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(DefaultActions)))
DefaultActions.contextType = AbilityContext