import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage,injectIntl } from 'react-intl';

function AlertDialog(props) {

  const intl = props.intl;

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.user.logged.user.logged"})}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({id:"dialog.user.logged.active.session"})}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openDialogUserLoggedFunc()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={() => { props.handleConfirmation() }} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({id:"confirm"})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(AlertDialog);