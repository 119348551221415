import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from "@mui/styles"
import { injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',

  },
}))(Tooltip);

function SimpleSelect(props) {

  const { label, options, stateName, changeSelect, selected, required, disabled, hasMenuIcon = false, name="select", size="medium", isRequired=false } = props;

  const classes = useStyles();
  const [option, setOption] = React.useState(selected ? selected : '');

  const inputLabel = React.useRef(null);
  const [labelwidth, setLabelWidth] = React.useState(0);

  const intl = props.intl;

  React.useEffect(() => {
    //setLabelWidth(inputLabel.current.offsetWidth);
    setOption(selected ? selected : '');
  }, [selected]);

  const handleChange = event => {
    setOption(event.target.value);
    changeSelect(stateName, event.target.value);
  };

  return (
    <div>
      <FormControl required={required} variant="outlined" className={classes.formControl}           
        disabled={disabled} fullWidth error={props.hasError}>
        <InputLabel id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          name={name}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={option}
          onChange={handleChange}
          require={required}
          size={size}
          input={<OutlinedInput />}
        >
          {!isRequired && (
            <MenuItem value={0}>
              <em> &nbsp; </em>
            </MenuItem>
          )}

          {options && options.map(item => {
            if (props.hasLocationIcon) {
              return <MenuItem
                key={item.id}
                value={item.id}>
                  <ListItemIcon>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography>{intl.formatMessage({id:"name"})} {item && item.name ? item.name : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"cep"})} {item && item.zipcode ? item.zipcode : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"street"})} {item && item.street ? item.street : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"number"})} {item && item.number ? item.number : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"complement"})} {item && item.complement ? item.complement : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"neighborhood"})} {item && item.neighborhood ? item.neighborhood : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"city"})} {item && item.city ? item.city : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"state"})} {item && item.state ? item.state : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"country"})} {item && item.country ? item.country : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"latitude"})} {item && item.longitude ? item.longitude : '---'}</Typography>
                          <Typography>{intl.formatMessage({id:"longitude"})} {item && item.latitude ? item.latitude : '---'}</Typography>
                        </React.Fragment>
                      }>
                      <LocationOnIcon  fontSize="small" />
                    </HtmlTooltip>
                  </ListItemIcon>
                  <Typography variant="inherit">{item.title ? item.title :  item.name}</Typography>
                </MenuItem>
            } else {
              if(item.enable == false){
                return <MenuItem
                  key={item.id}
                  value={item.id}
                  disabled={!item.enable}>
                    {item.title ? item.title :  item.name}
                  </MenuItem>
              }
              else{
                  return <MenuItem key={item.id} value={item.id}>{item.title ? item.title :  item.name}</MenuItem>
              }
            }
          })}
        </Select>
        {props.hasError && <FormHelperText>{props.errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}

SimpleSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.number,
  disabled : PropTypes.bool
};

export default injectIntl(SimpleSelect);