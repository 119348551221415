import React from 'react';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';

import { useChatHubContext } from '../../contexts/chatHubContext';
import { PapiroConsole } from '../../utils/papiroConsole';
import MenuItem from '@mui/material/MenuItem';

export function ChatListItem({ handleOnClick, buttonStyle, clientChatIsOpen, disabled, handleClose }) {
    const { chatHub, openChatList, currentClientChat } = useChatHubContext();
    const userSession = useSelector((state) => state.userSession);

    const resetNotSeenMessages = async () => {
        if (currentClientChat) {
            await chatHub.invoke("ResetNotSeenMessages", currentClientChat.id, userSession.user.id);
        }
    }

    const onClick = () => {
        handleOnClick()
        handleClose()
        resetNotSeenMessages()
    };

    const numberOfUnseenMessages = () => {
        
        if (!openChatList || openChatList.length < 1) return 0;
        let total = 0;
        openChatList.forEach(chat => {

            if (userSession.user.id == chat.assignedUserId) {
                total += chat.notSeenByAssignedUser;
            }
            if (userSession.user.id == chat.requestingUserId) {
                total += chat.notSeenByRequestingUser;
            }

        });
        return total
    };

    return (

        <MenuItem  onClick={onClick}    >
            <Badge  badgeContent={numberOfUnseenMessages()} color="error">
                <QuestionAnswerIcon /> 
            </Badge>
            <span style={{ marginLeft: '10px' }} >Abrir chat</span>
        </MenuItem>
    )
}