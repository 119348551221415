import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import InputIcon from '@mui/icons-material/Input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FirstElement from "../../components/first-element";
import If, { Else } from '../../components/conditionals/if'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DeleteForever, AttachFile } from '@mui/icons-material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageList from '@mui/material/ImageList'
import SimpleCheckBox from '../../components/checkbox/check';
import * as componentActions from '../../redux/actions/calledorganizationchecklists';

import Dropzone from 'react-dropzone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import TextFieldCheckIcon from '../../custom/icons/TextfieldCheckIcon'
import AttachFileCheckIcon from '../../custom/icons/AttachFileCheckIcon'
import * as FileService from '../../services/attachment.service';
import { PapiroConsole } from 'src/utils/papiroConsole';

class CalledOrganizationChecklistContent extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;
        this.emptyArray = null;

        let filters = [
            { name: intl.formatMessage({ id: "title" }), id: "title" },
            { name: intl.formatMessage({ id: "hasdone" }), id: "value" }
        ];

        const { userSession, calleds } = this.props;
        this.state = {
            calleds: calleds,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],

            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            checklistTextEvidenceModalOpen: false,
            checklistImageEvidenceModalOpen: false,
            evAttachTitle: '',
            evTextTitle: '',
            evTextInput: '',
            evTextId: null,
            evAttachFile: {},
            evAttachFileData: [],
            evAttachId: null
        };

        this.changeValues = this.changeValues.bind(this);
        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);

        this.openChecklistTextEvidenceModal = this.openChecklistTextEvidenceModal.bind(this)
        this.openChecklistImageEvidenceModal = this.openChecklistImageEvidenceModal.bind(this)
        this.closeTextEvidenceModal = this.closeTextEvidenceModal.bind(this)
        this.closeImageEvidenceModal = this.closeImageEvidenceModal.bind(this)

        this.useStyles = this.useStyles.bind(this)
    }

    useStyles() {
        return withStyles({
            ImageList: {
                width: "100%",
                height: 100,
            },
        });
    }

    closeTextEvidenceModal = () => this.setState({ checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen, evTextInput: '', evTextTitle: '', evTextId: null })

    closeImageEvidenceModal = () => this.setState({ checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen, evAttachTitle: '', evAttachId: null, evAttachFile: {}, evAttachFileData: [] })

    async openChecklistTextEvidenceModal(id, name, evidencetext) {
        const intl = this.props.intl;
        this.setState({ loading: true })
        await Api.get(`/calledorganizationchecklists/${id}`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    this.setState({
                        evTextTitle: name,
                        evTextInput: result.data.data.evidencetext,
                        evTextId: id,
                        loading: false,
                        checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
                    })
                } else {
                    this.setState({
                        evTextTitle: name,
                        evTextInput: evidencetext,
                        evTextId: id,
                        loading: false,
                        checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    async openChecklistImageEvidenceModal(id, name, evidenceattachmentid) {
        const intl = this.props.intl;
        PapiroConsole.log("entrei aqui no attachment 3")
        this.setState({ loading: true })
        await Api.get(`/calledorganizationchecklists/${id}`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    if (result.data.data.calledorganizationchecklistattachments && result.data.data.calledorganizationchecklistattachments.length > 0) {
                        this.setState({
                            evAttachTitle: name,
                            evAttachId: id,
                            evAttachFileData: result.data.data.calledorganizationchecklistattachments,
                            loading: false,
                            checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                        })
                    } else {
                        this.setState({
                            evAttachTitle: name,
                            evAttachId: id,
                            evAttachFileData: [],
                            loading: false,
                            checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                        })
                    }
                } else {
                    this.setState({
                        evAttachTitle: name,
                        evAttachId: id,
                        loading: false,
                        checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    async getCalledFile(attachment,fileName) {
        /*
		let fName = fileName
        if(fName.startsWith('/wwwroot/', 0))
          fName = fName.substring(9)
        
        let apiUrl = Api.apiurlbase()
        if (apiUrl.endsWith("/"))
          apiUrl = apiUrl.slice(0, -1)
    
        window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
		*/


		const intl = this.props.intl;
		this.setState({ loading: true });
		
		
		let url = await FileService.getFile(attachment.hirerid,attachment.id,attachment.path,attachment.filerepositorymethod); 
		//alert(JSON.stringify(url.data));
		this.setState({ loading: false });
		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});
	
		}	
	


    };

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                // console.log(count)
                // console.log('pagina')
                // console.log(page * 10)
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.filterSelected != 0) {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
        this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {

        this.setState({ filterSelected: value });
    }


    deleteItem(data, resolve) {
        let abl = this.context.can('Delete', 'CalledOrganizationChecklist');
        if (abl == false) {
            const intl = this.props.intl;
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }

        Api.delete(`/calledorganizationchecklists/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.getItens();
    }

    setCalledChecklist = async () => {
        const { userSession } = this.props;

        this.setState({ loading: true });

        let calledId = this.state.calleds.called.id;
        let orgId = this.state.calleds.called.organization.id;
        let orgCategoryId = this.state.calleds.called.organizationcategory.id;

        let dataSet = new FormData();
        dataSet.append('CalledId', calledId);
        dataSet.append('OrganizationId', orgId);
        dataSet.append('CategoryId', orgCategoryId);
        dataSet.append('CreatedUser', userSession.user.id);

        await Api.post(`/calledorganizationchecklists/called`, dataSet)
            .then(result => {

            })
            .catch(err => {
            });
    }

    getItens = async (page = 1) => {
        const intl = this.props.intl;
        await this.setCalledChecklist();

        this.setState({ loading: true });
        var url = `/calledorganizationchecklists/called/${this.state.calleds.called.id}`;
        url = `/calledorganizationchecklists/called/paginate?page=${page}&id=${this.state.calleds.called.id}`;

        if (this.state.searchValue != '' && this.state.filterSelected != 0) {

            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`

        }

        if (this.props.location && this.props.location.pathname === "/calledorganizationchecklists") {
            url = `/calledorganizationchecklists`;

            url = `/calledorganizationchecklists/paginate?page=${page}`;

            if (this.state.searchValue != '' && this.state.filterSelected != 0) {

                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`

            }
        }
        Api.get(url)
            .then(result => {
                if (result.data.success) {


                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);
                    if (result.data.data.length === 0) {
                        this.emptyArray = true
                    }
                    else {
                        this.emptyArray = false
                    }

                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            OrganizationChecklistId: item.organizationchecklist ? item.organizationchecklist.id : 0,
                            OrganizationChecklistTitle: item.organizationchecklist ? item.organizationchecklist.title : '',
                            OrganizationText: item.organizationchecklist.organization ? item.organizationchecklist.organization.name : '',
                            ValueText: item.value == true ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                            Active: item.active == true ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                            EvidenceText: item.evidencetext ? <Tooltip title="Texto evidência">
                                <IconButton aria-label="textevidence"
                                    onClick={e => this.openChecklistTextEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidencetext)}>
                                    <TextFieldsIcon />
                                </IconButton>
                            </Tooltip> : '',
                            EvidenceAttachment: item.attachments && item.attachments.length > 0 ? <Tooltip title={intl.formatMessage({id:"evidence.file"})}>
                                <IconButton aria-label="attachevidence"
                                    onClick={e => this.openChecklistImageEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.attachments[0].attachment ? item.attachments[0].attachment : null)}>
                                    <AttachFileIcon />
                                </IconButton>
                            </Tooltip> : ''
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    newchangeValues = async (stateName, value) => {
        const intl = this.props.intl;
        if (stateName.includes("chk-")) {
            this.setState({
                [stateName]: value
            })
            let splitChk = stateName.split('-')
            let checkId = splitChk[1]

            this.setState({ loading: true })
            const { userSession } = this.props
            let data = new FormData()
            data.append('id', checkId)
            data.append('value', value)
            data.append('updateduser', userSession.user.id)

            Api.put("/calledorganizationchecklists/value", data)
                .then(result => {
                    if (result && result.data && result.data.success && result.data.success == true) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({id:"checklist.modal.updated.checklist"})
                        });
                    }
                })
                .catch(err => {
                    console.error(err)
                    console.error("errerr")
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                });

            this.setState({ loading: false })
        }
        else {
            this.setState({
                [stateName]: value
            });
        }

        //this.submitAction();
    }

    openChecklistTextEvidenceModal = async (id, name, evidencetext) => {
        const intl = this.props.intl;
        this.setState({ loading: true })
        await Api.get(`/calledorganizationchecklists/${id}`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    this.setState({
                        evTextTitle: name,
                        evTextInput: result.data.data.evidencetext,
                        evTextId: id,
                        loading: false,
                        checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
                    })
                } else {
                    this.setState({
                        evTextTitle: name,
                        evTextInput: evidencetext,
                        evTextId: id,
                        loading: false,
                        checklistTextEvidenceModalOpen: !this.state.checklistTextEvidenceModalOpen
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    async openChecklistImageEvidenceModal(id, name, evidenceattachmentid) {
        PapiroConsole.log("entrei aqui no attachment 5")
        const intl = this.props.intl;
        this.setState({ loading: true })
        await Api.get(`/calledorganizationchecklists/${id}`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    if (result.data.data.calledorganizationchecklistattachments && result.data.data.calledorganizationchecklistattachments.length > 0) {
                        this.setState({
                            evAttachTitle: name,
                            evAttachId: id,
                            evAttachFileData: result.data.data.calledorganizationchecklistattachments,
                            loading: false,
                            checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                        })
                    } else {
                        this.setState({
                            evAttachTitle: name,
                            evAttachId: id,
                            evAttachFileData: [],
                            loading: false,
                            checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                        })
                    }
                } else {
                    this.setState({
                        evAttachTitle: name,
                        evAttachId: id,
                        loading: false,
                        checklistImageEvidenceModalOpen: !this.state.checklistImageEvidenceModalOpen
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

     sendTextEvidence = async () => {
        const intl = this.props.intl;
        this.setState({ loading: true })
        console.log("sendTextEvidence")
        console.log(this.state.loading)

        const { userSession } = this.props
        let data = new FormData()
        data.append('id', this.state.evTextId)
        data.append('value', false)
        data.append('evidencetext', this.state.evTextInput)
        data.append('updateduser', userSession.user.id)

        Api.put("/calledorganizationchecklists/ev/text", data)
            .then(result => {
                if (result && result.data && result.data.success && result.data.success == true) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"checklist.modal.evidence.text.updated"}),
                        ['chk-' + this.state.evTextId]: true
                    });
                    this.createCheckState(this.state.evTextId)
                    this.closeTextEvidenceModal()
                }
                else{
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                console.error(err)
                console.error("errerr")
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
            });

       
    }

    sendImageEvidence = async () => {
        const intl = this.props.intl
        if (this.state.evAttachFile && this.state.evAttachFile.name) {
            this.setState({ loading: true })
            const { userSession } = this.props
            let data = new FormData()
            data.append('id', this.state.evAttachId)
            data.append('value', false)
            data.append('filesUpload', this.state.evAttachFile)
            data.append('updateduser', userSession.user.id)

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            };

             Api.put("/calledorganizationchecklists/ev/attach", data, config)
                .then(result => {
                    if (result && result.data && result.data.success && result.data.success == true) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({id:"checklist.modal.evidence.file.updated"}),
                            ['chk-' + this.state.evAttachId]: true
                        });
                        this.createCheckState(this.state.evAttachFile)
                        this.closeImageEvidenceModal()
                    }
                    else{
                        this.setState({ loading: false })
                    }
                })
                .catch(err => {
                    console.error(err)
                    console.error("errerr")
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                });

            //this.setState({ loading: false })
        } else {
            this.closeImageEvidenceModal()
        }
    }

    createCheckState = (itemId) => {
        let item = this.state.resultList.filter(checklist => (checklist.id == itemId))
        PapiroConsole.log("item aqui createCheckState")
        PapiroConsole.log(item)
        if (item.size > 0) {
            item[0].value = true
            var stateNameVar = `chk-${itemId}`
            this.setState({ [stateNameVar]: true })

            let items = this.state.resultList.filter(checklist => (checklist.id != itemId))
            items.push(item)

            this.setState({ resultList: items })
        }
    }

    validateImagesAtachments(files) {
        PapiroConsole.log("entrei na função de validação")
        const intl = this.props.intl;
        if (files.length > 1) {
            this.setState({
                evAttachFile: {},
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"checklist.modal.select.image.error"}),
            })
            return
        }
        for (const file of files) {
            if (!file.type.includes("image")) {
                PapiroConsole.log("entrei aqui na validação")
                this.setState({
                    evAttachFile: {},
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: intl.formatMessage({id:"checklist.modal.image.field.error"}),
                })
                return
            }
        }

        const image = new Image();
        image.addEventListener('load', () => {
            this.setState({ evAttachFile: files[0] })
        });
        image.src = URL.createObjectURL(files[0])
    }

    removeFileFromDropzone() {
        this.setState({ evAttachFile: {} })
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name == "formCallOrgMatQuantity") {
            if (value.match('.') || value.match(',')) {
                this.setState({
                    [name]: parseInt(value.replaceAll(",", ""))
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    };

    deleteDocument = async (id) => {
        const intl = this.props.intl
        this.setState({ loading: true });
        
        Api.delete(`/calledorganizationchecklists/ev/attach/${id}`)
          .then(result => {
            if (result && result.data && result.data.success && result.data.success) {
              var attachListUpdated = this.state.evAttachFileData.filter(c=> c.id != id);
              this.setState({
                evAttachFileData: attachListUpdated,
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({id:"checklist.evidence.file.deleted"})
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          });
      }

    render() {
        const classes2 = this.useStyles();
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        PapiroConsole.log(this.state)
        PapiroConsole.log("this.state de checklist aqui")
        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                {!this.emptyArray ? (
                    <div>
                        <Dialog open={this.state.checklistTextEvidenceModalOpen} onClose={this.closeTextEvidenceModal} aria-labelledby="form-dialog-title">
                        {this.state.loading && (
                                <Overlay>
                                    <CircularProgress color='secondary' />
                                </Overlay>
                            )}
                            <DialogTitle id="form-dialog-title">
                                {intl.formatMessage({id:"menu.organization.checklists"})} - {intl.formatMessage({ id: "evidence.text" })} - {this.state.evTextTitle}
                                <ClearIcon onClick={this.closeTextEvidenceModal} style={{ float: 'right', cursor: 'pointer' }} />
                            </DialogTitle>
                            <DialogContent>
                                <TextField
                                    name="evTextInput"
                                    autoComplete="fname"
                                    label={intl.formatMessage({ id: "evidence.text" })}
                                    multiline
                                    rows="4"
                                    fullWidth
                                    value={this.state.evTextInput}
                                    variant="outlined"
                                    onChange={(e) => this.handleInputChange(e)}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </DialogContent>
                            <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 25, paddingBottom: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        className={classes.submit}
                                        onClick={this.sendTextEvidence}
                                    >
                                        {<FormattedMessage id="save" />}
                                    </Button>
                                </Grid>
                            </Grid>
                           
                        </Dialog>
                        <Dialog open={this.state.checklistImageEvidenceModalOpen} onClose={this.closeImageEvidenceModal} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">
                                {intl.formatMessage({id:"menu.organization.checklists"})} - {intl.formatMessage({ id: "evidence.attachment" })} - {this.state.evAttachTitle}
                                <ClearIcon onClick={this.closeImageEvidenceModal} style={{ float: 'right', cursor: 'pointer' }} />
                            </DialogTitle>
                            <DialogContent>
                                <DialogContent>
                                    <Grid container spacing={0} style={{ marginBottom: '12px' }}>
                                        <section style={{ width: '100%', marginTop: '0', marginBottom: '10px' }}>
                                            <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                                                <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <AttachFileIcon  />
                                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                            <FormattedMessage  id="drag.image.or.click.to.select" />
                                                            </Typography>
                                                            
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {this.state.evAttachFile && this.state.evAttachFile.name && (
                                                    <div className={classes.dropzoneItem} key={this.state.evAttachFile.name}>
                                                        {this.state.evAttachFile.name}
                                                        <DeleteForever className={classes.deleteItem} onClick={() => { this.removeFileFromDropzone() }} />
                                                    </div>
                                                )}
                                            </div>
                                        </section>
                                    </Grid>
                                    {this.state.evAttachFileData && this.state.evAttachFileData.length > 0 && (

                                        <Grid container spacing={0} style={{ marginTop: "5px" }}>
                                            <Grid item xs={12}>
                                                <div className={classes.attachmentsBox}>
                                                    <label>{intl.formatMessage({id:"attachments"})}</label>
                                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                        <ul>
                                                            {this.state.evAttachFileData.map(item => {
                                                                return (<li key={item.attachment.path}>
                                                                <AttachFile /> <Button onClick={() => this.getCalledFile(item.attachment)}>{item.attachment.description}</Button>
                                                                <DeleteForeverIcon onClick={() => this.deleteDocument(item.id)} style={{ cursor: "pointer" }} />
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    </ImageList>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                </DialogContent>
                            </DialogContent>
                            <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', paddingRight: 25, paddingBottom: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        className={classes.submit}
                                        onClick={this.sendImageEvidence}
                                    >
                                        {<FormattedMessage id="save" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Dialog>
                        <CssBaseline />
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={8} />
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>


                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="organization.custom.checklist" />}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            <Grid item >
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />

                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Grid>
                        </Grid>
                        <Can I='List' a='OrganizationChecklist'>
                            <>
                                <Grid item xs={12} sm={12}
                                    style={{color: 'black', fontSize: '12px', padding: '20px 5px 20px 20px', backgroundColor: '#F9F9F9', borderBottom: '1px solid #b3b3b3' }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} sm={6}>
                                            {intl.formatMessage({id:"name"})}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            {intl.formatMessage({id:"type"})}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            {intl.formatMessage({id:"description"})}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            {intl.formatMessage({id:"attachments"})}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {this.state.resultList && this.state.resultList.map(item => {
                                    return (
                                        <Grid item xs={12} sm={12}
                                            style={{ padding: '15px 15px 0 15px' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={6}>
                                                    <SimpleCheckBox changeSelect={this.newchangeValues} stateName={'chk-' + item.id} fullWidth autoComplete='fname' name={'chk-' + item.id} selected={this.state['chk-'+item.id] != null && this.state['chk-'+item.id] != undefined  ? this.state['chk-'+item.id]  : item.value} variant='outlined' label={item.organizationchecklist ? item.organizationchecklist.title : ''} disabled={true} />
                                                </Grid>
                                                <Grid item xs={2} sm={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                    { item.type ? item.type == 1 ? 'Inicial' : 'Final' : '' }
                                                </Grid>
                                                <Grid item xs={2} sm={2}>
                                                    <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.text"})}>
                                                        <IconButton aria-label="textevidence"
                                                            onClick={e => this.openChecklistTextEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidencetext)}>
                                                            {item.evidencetext && item.evidencetext.length > 0 ? (<TextFieldCheckIcon />) : (<TextFieldsIcon />)}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={2} sm={2}>
                                                    <Tooltip title={intl.formatMessage({id:"checklist.modal.add.evidence.file"})}>
                                                        <IconButton aria-label="attachevidence"
                                                            onClick={e => this.openChecklistImageEvidenceModal(item.id, item.organizationchecklist ? item.organizationchecklist.title : '', item.evidenceattachmentid)}>
                                                            { item.attachments && item.attachments.length > 0 ? (<AttachFileCheckIcon />) : (<AttachFileIcon />)}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </>
                        </Can>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </div>)
                    : (
                        <>
                            {this.props.calledEdit && this.props.calledEdit == true ? (
                                <div>
                                    <FirstElement
                                        title={intl.formatMessage({id:"called.organization.checklist.list.called.checklist"})}
                                        subtitle={intl.formatMessage({id:"called.organization.checklist.list.checklist.not.found"})}
                                        icon={<CheckBoxIcon style={{ fontSize: "45px" }} />}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <FirstElement
                                        title={intl.formatMessage({id:"called.organization.checklist.list.called.checklist"})}
                                        subtitle={intl.formatMessage({id:"called.organization.checklist.list.called.checklist.subtitle"})}
                                        //buttonText = "Novo Chamado"
                                        buttonText=
                                        {
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                style={{ marginBottom: '20px' }}
                                                className={classes.button}
                                            >
                                                {<FormattedMessage id="add" />}
                                            </Button>
                                        }

                                        icon={<CheckBoxIcon style={{ fontSize: "45px" }} />}

                                    />
                                </div>
                            )}
                        </>
                    )}

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}


                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />


            </Container>

        );
    }
}
CalledOrganizationChecklistContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    calleds: state.calleds
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledOrganizationChecklistContent))));
CalledOrganizationChecklistContent.contextType = AbilityContext;