export const isInvalidFileType = (type, archiveModel = "all") => {
  if (archiveModel === "all") {
    if (type === 'image/bmp' || type === 'image/gif' || type === 'image/jpeg' || type === 'image/png' || type === 'image/tiff' || type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/vnd.oasis.opendocument.presentation' || type === 'application/vnd.oasis.opendocument.spreadsheet' || type === 'application/vnd.oasis.opendocument.text' || type === 'application/pdf' || type === 'application/vnd.ms-powerpoint' || type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type === 'application/rtf')
      return false
  } else if (archiveModel === "image") {
    if (type === 'image/bmp' || type === 'image/gif' || type === 'image/jpeg' || type === 'image/png' || type === 'image/tiff')
      return false
  }
  
  return true
}

export const validateFyleTypeByTypeList = (type, typeList = []) => {
  let isValid = false

  if (typeList != null && Array.isArray(typeList)) {
    typeList.forEach(item => {
      if(type === item)
        isValid = true
    })
  }

  return isValid
}

export const getFileType = (extension) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif','webp'];
  const videoExtensions = ['mp4', 'avi', 'mkv'];
  const pdfExtensions = ['pdf'];
  const presentationExtensions = ['ppt', 'pptx'];
  const spreadsheetExtensions = ['xls', 'xlsx'];
  const compressedExtensions = ['zip', 'rar', '7z'];
  const textExtensions = ['txt', 'md'];
  const audioExtensions = ['mp3', 'wav', 'ogg'];
  const codeExtensions = ['js', 'html', 'css', 'py', 'java'];

  if (imageExtensions.includes(extension)) {
    return 'image';
  } else if (videoExtensions.includes(extension)) {
    return 'video';
  } else if (pdfExtensions.includes(extension)) {
    return 'pdf';
  } else if (presentationExtensions.includes(extension)) {
    return 'presentation';
  } else if (spreadsheetExtensions.includes(extension)) {
    return 'spreadsheet';
  } else if (compressedExtensions.includes(extension)) {
    return 'compressed';
  } else if (textExtensions.includes(extension)) {
    return 'text';
  } else if (audioExtensions.includes(extension)) {
    return 'audio';
  } else if (codeExtensions.includes(extension)) {
    return 'code';
  } else {
    return 'unknown';
  }
}