import { PapiroConsole } from '../utils/papiroConsole';
import Api from './api';

export const paginate = async (page, org, search, field) => { 
  let data = []

  let url = `/calledreportconfigs/paginate?page=${page}&org=${org}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginateByOrganization = async (page, orgId, search, field) => { 
  let data = []

  let url = `/calledreportconfigs/organization/paginate?page=${page}&id=${orgId}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const get = async () => { 
  let data = []
  await Api.get(`/calledreportconfigs`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledreportconfigs/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledreportconfigs/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerId = async (id) => { 
  let data = []
  await Api.get(`/calledreportconfigs/hirer/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledreportconfigs/hirer/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationId = async (orgId) => { 
  let data = []
  await Api.get(`/calledreportconfigs/organization/${orgId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationIdWithoutIncludes = async (orgId) => { 
  let data = []
  await Api.get(`/calledreportconfigs/organization/${orgId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData, config) => { 
  let data = []
  await Api.post(`/calledreportconfigs`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData, config) => { 
  let data = []
  await Api.put(`/calledreportconfigs`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteAttachment = async (id) => { 
  let data = []
  await Api.delete(`/calledreportconfigs/attach/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledreportconfigs/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}