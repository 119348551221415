import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import AddIcon from '@mui/icons-material/Add';
import { Link, withRouter } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";

import * as componentActions from '../../redux/actions/calledstatuses';
import { PapiroConsole } from '../../utils/papiroConsole';
class CalledTeams extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds } = this.props;
        const { id } = this.props.match.params

        this.urlId = id

        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
            if (item.role.roletype == 1) {
                this.AdmOrganization = true;
            }
            if (item.role.roletype == 4) {
                this.isCoordenator = true;
            }
            if (item.role.roletype == 2) {
                this.isTechinical = true;
            }
        });

        this.state = {
            calledId: id ? id: calleds.called.id,
            calledOrganizationId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.id,
            loading: false,
            displayAssignedInfoMode: 0 , 
            techinicalTeamName : calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].techinicalteam ? calleds[`called-${this.urlId}`].techinicalteam.name : '', 
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            columns : [],
        };
        

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        await this.getOrganizationSettings();
        await this.getItens();
    }

  
    async getOrganizationSettings() {

        
        const intl = this.props.intl;

        this.setState({ loading: true });

        await Api.get(`/organizationsettings/organization/${this.state.calledOrganizationId}/withoutincludes`)

            .then(result => {
                if (result.data.success) {
                    
                    if(result.data.data.displayassignedinfomode){
                        var  columns= [
                            { title: intl.formatMessage({id:"previous.responsible"}), field: 'PreviousTeamName' },
                            { title: intl.formatMessage({id:"updated.responsible"}), field: 'NextTeamName' },
                            { title: intl.formatMessage({id:"menu.statuses"}), field: 'StatusName' },
                            { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                            
                        ]
                        if((result.data.data.displayassignedinfomode ==1 && this.isClient == true) || (this.isClient == false && result.data.data.displayassignedinfomode != 4)){
                            columns.push({ title: intl.formatMessage({ id: "user" }), field: 'UserName' })  
                        } 
                    }
                   

                    this.setState({ loading: false, columns : columns});
                }
                else {
                    PapiroConsole.log("entrei aqui")
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                PapiroConsole.log("entrei aqui 2 ") 
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });

    }

    async getItens() {
        this.setState({ loading: true });

        
        await Api.get(`/calledteams/list?calledId=${this.state.calledId}&organizationid=${this.state.calledOrganizationId}`)

            .then(result => {
                if (result.data.success) {
                    // console.log(result.data.data);
                    PapiroConsole.log(result.data.data);
                    PapiroConsole.log("result.data.data");
                    const formattedList = result.data.data.map(item => {
                        return {
                            NextTeamName: item.nextteamname  ? item.nextteamname : '',
                            PreviousTeamName: item.previousteamname ? item.previousteamname : '',
                            StatusName: item.statusname,
                            DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm:ss"),
                            UserName: item.username ? item.username : ''
                        }
                    });

                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    PapiroConsole.log("entrei aqui")
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                PapiroConsole.log("entrei aqui 2 ") 
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <MyMaterialTable
                    title={intl.formatMessage({ id: "calledStatus.header" })}
                    columns={this.state.columns?.length > 0 ? this.state.columns : 
                        [
                            { title: intl.formatMessage({id:"previous.team"}), field: 'PreviousTeamName' },
                            { title: intl.formatMessage({id:"updated.team"}), field: 'NextTeamName' },
                            { title: intl.formatMessage({id:"menu.statuses"}), field: 'StatusName' },
                            { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                            { title: intl.formatMessage({ id: "user" }), field: 'UserName' }
                        ]
                    }
                    data={this.state.resultList}
                />
                
                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />
                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
CalledTeams.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    calleds: state.calleds,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledTeams))));

CalledTeams.contextType = AbilityContext;