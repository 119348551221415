export default function calledflowstatuses(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                calledflowstatus: action.calledflowstatus,
            }
        default:
            return state;
    }
}
