import Api from './api';

export const getData = async () => { 
  let data = []
  await Api.get(`/integrationservice`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const getList = async () => { 
  let data = []
  await Api.get(`/integrationservice/get-list`)
    .then(result => {
      data = result.data.data
    })
    .catch(err => {
      data = []
    })
  return data
};


export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const updateItem = async (formData) => { 
  let data = []
  await Api.put(`/integrationservice`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



export const testAuthentication = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice/test-authentication`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}


export const testService = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice/test-service`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}


export const getDataRequestById = async (id) => { 
  let data = {}

  await Api.get(`/integrationservice/${id}`)
    .then(result => {
      data = result.data.data
    })
    .catch(err => {
      console.log("Error: ", err);
    });

  return data
};

export const testRequestIntegrationService = async (payload) => { 
  let data = []
  await Api.post(`/integrationservice/test-request-integration-service`, payload)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const getMethodAuthIntegration = async () => { 
  let data = []
  await Api.get(`/integrationservice/get-method-auth-integration`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};