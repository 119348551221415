import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import moment from 'moment'
import PropTypes from 'prop-types'
import ChatMenuUserInfo from './user-info'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import './styles.css'
import { PapiroConsole } from '../../../../utils/papiroConsole'

function ChatMenuUserList(props) {
	PapiroConsole.log("=== ChatMenuUserInfo ===")
	PapiroConsole.log(props)

  return (<div className="omnichannel-chat-menu-user-list">
    { props.chats != null && props.chats.chats != null && (typeof props.chats.chats.length !== 'undefined') &&  props.chats.chats.length > 0  &&  props.chats.chats.map(item => {
      PapiroConsole.log(item)

      item.lastchatmessage = { content: '', createdat: moment().format('HH:mm') }
      if (item.chatmessages && item.chatmessages.length > 0) {
        PapiroConsole.log(item.chatmessages)
        let lastChatMessage = item.chatmessages[item.chatmessages.length - 1] /*item.chatmessages.reduce((better, current) => {
          return current.id > better.id ? current : better;
        }, { id: -1 })*/
        if (lastChatMessage != null) {
          PapiroConsole.log("=== ChatMenuUserInfo -> ===")
          PapiroConsole.log(lastChatMessage)
          item.lastchatmessage = {
            content: `${lastChatMessage.sendername ? `${lastChatMessage.sendername}: ` : ''} ${lastChatMessage.content}`,
            createdat: moment(lastChatMessage.createdat).format('HH:mm')
          }
        }
      }

      return <ChatMenuUserInfo
        key={item.id}
        id={item.id}
        senderid={item.senderid}
        messageproviderid={item.messageproviderid}
        category={item.organizationcategory}
        priority={item.priority}
        title = {item.title}
        userName={item.customer && item.customer.name ?  item.customer.name :  item.customer && item.customer.phone ? item.customer.phone : ''}
        departamentName={item.organizationdepartament ? item.organizationdepartament?.name ?? '' : ''}
        originRequestId={item.originrequest ? item.originrequest.name : ''}
        unreadMessages={item.unreadmessages ? item.unreadmessages : ''}
        lastMessage={item.lastchatmessage}
        messages={item.chatmessages}
        chatstatuscolorhex = {item.chatstatuscolorhex}
        priorityColorHex={item.priority ? item.priority?.colorhex ?? '' : ''}
        data={item} />
    })}
  </div>)
}

ChatMenuUserList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatMenuUserList)))