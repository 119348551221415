import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { withRouter } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../../styles/material-styles'
import { AbilityContext } from '../../../../../src/config/ability-context' ;
import Avatar from '@mui/material/Avatar';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as componentActions from '../../../../../src/redux/actions/organizationcategoriesportal'
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActionArea } from '@mui/material';
import moment from 'moment';

import { FormattedMessage,injectIntl } from 'react-intl';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import Cardinformations from '../../../cardinformations';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardInformations = props => {
  
  const { element, properties } = props;

  PapiroConsole.log(element)

  PapiroConsole.log(props)
  PapiroConsole.log("element aqui")

  const intl = props.intl;
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };




  const moveToAnotherPage = (element) => {

    console.log(element)
    console.log("entrei aqui")
      properties.addCurrent(element); 
      properties.history.push({
        pathname: `/${properties.match.params.lang}/portal-category/${element.id}`,
        state: {
          element: element
        }
      })
}
 


  

  return (


        
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            
              
                <Card  sx={{ height: "100%" }}   >
                  <CardActionArea onClick={() => moveToAnotherPage(element)} >
                    
                    <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {element.name.charAt(0)}
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={element.name}
                        subheader={moment(element.createdat).format("DD/MM/YYYY HH:mm")}
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        image="https://mui.com/static/images/cards/paella.jpg"
                        alt="Paella dish"
                      />
                      <CardContent>
                       <Typography variant="body2" color="text.secondary">

                        <div dangerouslySetInnerHTML={{__html:element.description.substring(0, 150) + '...'}} />
                      
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  
                </Card>
              
             
              
            
          </Grid>
        
   
    
  );

  
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
  organizationcategoryportal : state.organizationcategoryportal
});




//export default injectIntl(CardInformations);

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CardInformations))));
CardInformations.contextType = AbilityContext;
