import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleCheckBox from '../../../components/checkbox/check';
import * as Service from '../../../services/organizationsla.service';
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple-create';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleHelp from '../../../components/help';

class OrganizationSlaRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      id: 0,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      OrganizationId:  organizationid,
      OrganizationText: '',
      DistributionStrategyId:  0,
      DistributionStrategyText: '',
      WorkHourId:  0,
      WorkHourText: '',
      name : '', 
      comments : '', 
      servicetime : '', 
      solutiontime : '', 
      SLA : ''
     };

    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      DistributionStrategyList: [],
      WorkHourList: [],
      SLAList: [],
      template: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getOrganizations();
        //this.getDistributionStrategies();
        await this.getWorkHours(this.props.organizationid);
        await this.getSLA();
        await this.getDados();
        this.setState({ loading: false });
     };
     async getSLA(){
      this.setState({ loading: true });
      var result = await Service.getSLA();
        if (result.success) {
          this.setState({ loading: false, SLAList: result.data });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);  
        }
};
    async getDados(){
      this.setState({ loading: true });
      var result = await Service.getSLA(this.props.organizationid);
        if (result.success) {
          if(result.data.length > 0){
            let resultData = result.data[0];
            // console.log(resultData);
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                ['id']: resultData.id,
                ['DistributionStrategyId']: resultData.distributionstrategy ? resultData.distributionstrategy.id : 0,
                ['DistributionStrategyText']: resultData.distributionstrategy ? resultData.distributionstrategy.name : '',
                ['WorkHourId']:  resultData.workhour ? resultData.workhour.id : 0,
                ['WorkHourText']: resultData.workhour ? resultData.workhour.name : '',
                ['name'] : resultData.name, 
                ['comments'] : resultData.comment ? resultData.comments : '', 
                ['servicetime'] : resultData.servicetime, 
                ['solutiontime'] : resultData.solutiontime, 
                ['SLA'] : resultData.sla, 
              }
              
            }));
            // console.log(this.state.item.WorkHourText);
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);  
        }
    };

     async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getDistributionStrategies(){
          this.setState({ loading: true });
          var result = await Service.getDistributionStrategies();
            if (result.success) {
              this.setState({ loading: false, DistributionStrategyList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getWorkHours(orgId = 0){
          this.setState({ loading: true });
          var result = await Service.getWorkHours(orgId);
            if (result.success) {
              // console.log(result.data);
              this.setState({ loading: false, WorkHourList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if(name === 'servicetime' || name === 'solutiontime'){
      const re = /^[0-9\b]+$/;
      if(re.test(value)){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              [name]: value
            }
          }));
      }

    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));

    }

  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {

        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if(key.toString().toLowerCase().indexOf('sla') > -1 && this.state.template){
            var slaids = "";
            this.state.item.SLA.forEach(element => {
              slaids = slaids+element.id+",";
            });
            slaids = slaids.slice(0, -1);
            data.append(key, slaids);
        }else{
            data.append(key, this.state.item[key]);
          }
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if(this.state.item.id != 0){
        Api.put('/organizationslas', data, config)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });

            this.props.next();

          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
      }else{
        Api.post('/organizationslas', data, config)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });

            this.props.next();

          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
      }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));        
    
    if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
          this.getWorkHours(value);
        }
        if(stateName === 'DistributionStrategyId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['DistributionStrategyText']: text,
            }
          }));
        }
        if(stateName === 'WorkHourId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['WorkHourText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

     return (
      <div className={classes.root}>

          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={4} style={{display: 'none'}}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required />
                    </Grid>
                </Grid>
                
                  {this.state.template && (
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                      <SimpleAutoCompleteMultiple label='SLA(s):' options={this.state.SLAList} stateName='SLA' changeSelect={this.changeValues} />
                    </Grid>
                  </Grid> 
                  ) }
                    <Grid container spacing={4} style={{display: 'none'}}>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                      <SimpleSelect label={intl.formatMessage({id:"menu.distributionstrategies"})} options={this.state.DistributionStrategyList} stateName='DistributionStrategyId' changeSelect={this.changeValues} selected={item.DistributionStrategyId} />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                      <SimpleSelect label={intl.formatMessage({id:"workhour.Sla"})} options={this.state.WorkHourList} stateName='WorkHourId' changeSelect={this.changeValues} selected={item.WorkHourId} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.sla.edit.sla.calculation.base.standard.values"})}/>
                    </Grid>
                </Grid>
                {!this.state.template && (
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11} >
                      <TextField fullWidth inputProps={{ maxLength: 255 }} label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                </Grid>
                )}
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11}>
                      <TextField multiline rows="5" inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({id:"comment"})} autoComplete='fname' variant='outlined' name='comments' onChange={this.handleInputChange} value={item.comments} />
                    </Grid>
                </Grid>
                {!this.state.template && (
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11}>
                      <TextField fullWidth inputProps={{ maxLength: 15 }} label={intl.formatMessage({id:"organization.category.service.sla"}) + " " + intl.formatMessage({id:"in.minutes"})} autoComplete='fname' variant='outlined' name='servicetime' onChange={this.handleInputChange} value={item.servicetime} required />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.sla.edit.minutes.for.called.start"})}/>
                    </Grid>
                </Grid>)}
                {!this.state.template && (
                    <Grid container spacing={4}>
                    <Grid item xs={11} sm={11}>
                      <TextField fullWidth inputProps={{ maxLength: 15 }} label={intl.formatMessage({id:"sla.solution"}) + " " + intl.formatMessage({id:"in.minutes"})} autoComplete='fname' variant='outlined' name='solutiontime' onChange={this.handleInputChange} value={item.solutiontime} required />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.sla.edit.minutes.for.called.solution"})}/>
                    </Grid>
                </Grid> )}

                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4} /> 
                  <Grid  xs={4} sm={4} style={{textAlign: 'center', marginTop: '5px'}}>
                    <Button
                      type='submit'
                      color='primary'
                      onClick={back}
                    >
                      {this.props.intl.formatMessage({id:"back"})}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                    >
                      {intl.formatMessage({id:"next"})}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} /> 
                </Grid>

              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
          
      </div>
    );
  }
}
OrganizationSlaRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationSlaRegistration)));
