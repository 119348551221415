import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import * as componentActions from '../../redux/actions/permissions';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete-dialog-with-z-index';
import * as UserService from '../../services/user.service'
import ApiReports from '../../services/apireport';
import { PapiroConsole } from '../../utils/papiroConsole';

class Permissions extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;

        let filters = [
            { name: intl.formatMessage({ id: "name" }), id: "name" },
            { name: intl.formatMessage({ id: "display.name" }), id: "display" },
            { name: intl.formatMessage({ id: "description" }), id: "desc" }
        ];

        const { userSession } = this.props;
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],

            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            roleFilter: { id: 0, name: '' },
            rolesList: []
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.changeValues = this.changeValues.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.getExcel = this.getExcel.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.filterSelected != 0) {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
        this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {

        this.setState({ filterSelected: value });
    }

    deleteItem(data, resolve) {
        let abl = this.context.can('Delete', 'Permission');
        const intl = this.props.intl;
        if (abl == false || data.system == true) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }

        Api.delete(`/permissions/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "called.list.notification.delete.success" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.getItens();
        this.getRoles();
    }

    getItens(page = 1) {
        this.setState({ loading: true });

        let url = `/permissions/paginate?page=${page}`;

        if (this.state.searchValue != '' && this.state.filterSelected != 0) {
            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
        }
        const roleFilter = this.state.roleFilter.id
        if (roleFilter != '' && roleFilter != 0) {
            url += `&role=${roleFilter}`
        }
        Api.get(url)

            .then(result => {
                if (result.data.success) {

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,

                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };

    async getRoles() {
        this.setState({ loading: true });
        var result = await UserService.getRoles();
        if (result.success) {
            let idToRemove = 6;
            const rolesList = result.data.filter(item => item.id !== idToRemove);
            this.setState({ loading: false, rolesList: rolesList });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                    result.response && result.response.data && result.response.data.errors
                        ? result.response.data.errors[0]
                        : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(result);
        }
    }

    getExcel = () => {
        this.setState({ loading: true });

        const intl = this.props.intl;
        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');
        newFilter = newFilter.split('@')
        newSearch = newSearch.split('@')
        var url = '/report/permissions/getexcel';

        let name = ""
        let display = ""
        let description = ""
        newFilter.forEach((filter, i) => {
            switch (filter) {
                case "name":
                    name += name == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "display":
                    display += display == "" ? newSearch[i] : "," + newSearch[i]
                    break;
                case "description":
                    description += description == "" ? newSearch[i] : "," + newSearch[i]
                    break;
            }
        });
        const roleFilter = this.state.roleFilter  && this.state.roleFilter.id ? this.state.roleFilter.id : 0
        url += `?name=${name}&display=${display}&description=${description}&role=${roleFilter}`;

        ApiReports.get(url, {
        })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: this.props.intl.formatMessage({id:"report.sent"}) + " (" + this.props.userSession.user.email + ")",
                  });
                } else {
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: 'error',
                      notificationMessage: response.data.data && response.data.data[0] ? response.data.data[0] : this.props.intl.formatMessage({ id: "process.error" })
                     });
                  }
            })
            .catch((error) => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: error.data && error.data.response && error.data.response.data && error.data.response.data.errors && error.data.response.data.errors[0] ? error.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                })
            });

    }

    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Grid container className={classes.mb20} spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            disabled={!this.context.can('Add', 'KnowledgeBase')}
                                            size='large'
                                            className={classes.button}
                                            onClick={this.getExcel}
                                        >
                                            {intl.formatMessage({ id: "export.plan" })}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Card className="background-title-search">
                                    <CardContent>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="menu.permissions" />}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={2}>
                                                <SimpleAutoComplete label={intl.formatMessage({ id: "profile.name" })} options={this.state.rolesList} stateName='roleFilter'
                                                    selected={this.state.roleFilter}
                                                    changeSelect={(stateName, value, text = '') => {
                                                        this.setState({
                                                            roleFilter: value
                                                        }, () => {
                                                            this.getItens()
                                                        })
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            <Grid item >
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />

                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I='List' a='Permission'>
                            <MyMaterialTable
                                title=""
                                rowClick={(event, rowData) => {
                                    let abl = this.context.can('Edit', 'Permission');
                                    if (abl == false) {
                                        this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
                                        return false;
                                    }
                                    this.props.addCurrent(rowData);
                                    this.props.history.push(`/${this.props.match.params.lang}/permission-edit`);
                                }}
                                columns={[
                                    { title: intl.formatMessage({ id: "name" }), field: 'name' },
                                    { title: intl.formatMessage({ id: "display.name" }), field: 'displayname' },
                                    { title: intl.formatMessage({ id: "description" }), field: 'description' },
                                ]}
                                data={this.state.resultList}
                            //deleteItem={this.deleteItem} 
                            />
                        </Can>

                        <Can I='List' a='Permission'>

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>

                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
Permissions.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Permissions))); Permissions.contextType = AbilityContext;
