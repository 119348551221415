import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fade from '@mui/material/Fade'
import ClearIcon from '@mui/icons-material/Clear'
import MessageAttachment from './message-attachment'
import { Grid } from '@mui/material'
import MiniMap from '../../../../../../components/minimap/external-state';
import { PapiroConsole } from '../../../../../../utils/papiroConsole'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />
})

function ChatMessageLocationDialog({ url, title, isOpen, latitude, longitude, handleCloseDialog }) {
  return (<Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="chat-message-location-dialog" >
      <DialogTitle>
        Localização
        <ClearIcon onClick={handleCloseDialog} style={{ float: 'right', cursor : 'pointer'}}/> 
      </DialogTitle>
      <DialogContent className="chat-message-location-dialog">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {title}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MiniMap
              style={{
                width: 400, minWidth: 400, maxWidth: 400,
                height: 400, minHeight: 400, maxHeight: 400
              }}
              lat={latitude}
              long={longitude}
              address={title} />
          </Grid>
        </Grid>
      </DialogContent>
  </Dialog>)
}

export default React.memo(ChatMessageLocationDialog)