/*import React from "react";
import "./ModalPatrimonio.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "react-router-dom";


function ModalPatrimonio(props) {
  return (
    <div className="modalPatrimonio">
      <div className="containerModal">
        <button className="closeButton" onClick={props.onClose}>
          <HighlightOffIcon />
        </button>
        <div className="content">{props.children}</div>
        
      </div>
    </div>
  );
}
*/

import React, { Component } from "react";
import "./ModalPatrimonio.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OrganizationPatrimonyRegistration from "../../pages/organizationpatrimony-registration";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";

import { connect } from "react-redux";
import { browserHistory } from "../../helpers/history";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import * as Service from "../../services/organizationpatrimony.service";
import { FormattedMessage, injectIntl } from "react-intl";

import Footer from "../../components/footer";
import SimpleDatePicker from "../../components/date-pickers/inline-no-today";
import NumberFormat from "react-number-format";
import SimpleHelp from "../../components/help";
import SimpleCheckBox from "../../components/checkbox/check";
import SnackbarsModal from "../snackbarsModal";

class ModalPatrimonio extends Component {
  constructor(props) {
    super(props);
    const { userSession } = this.props;

    this.globalAdm = false;
    this.myRef = React.createRef();
    this.isClient = true;
    this.valorChecked = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    const defaultvalue = { id: 0, name: "" };

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      DepartamentId: 0,
      DepartamentText: "",
      PatrimonySubgroupId: 0,
      PatrimonySubgroupText: "",
      OrganizationId: 0,
      OrganizationText: "",
      PatrimonyGroupId: 0,
      PatrimonyGroupText: "",
      PatrimonyMaterialId: 0,
      keeppatrimony: true,
      PatrimonyMaterialText: "",
      name: "",
      tipping: "",
      qrcode: "",
      garanty: 0,
      value: "",
      serialnumber: "",
      brand: "",
      model: "",
      invoicedate: moment().format("YYYY/MM/DD HH:mm:ss"),
    };
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      DepartamentList: [],
      PatrimonySubgroupList: [],
      OrganizationList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
      checked: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }
  componentDidMount() {
    //this.getDepartaments();
    this.getPatrimonyGroups();
    //this.getPatrimonySubgroups();
    this.getOrganizations();
    //this.getPatrimonyMaterials();
    this.selectedPadrão();
  }
  async getDepartaments(orgid) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getDepartaments(orgid);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonySubgroups(patrimonyGroupId) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
    if (result.success) {
      this.setState({ loading: false, PatrimonySubgroupList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getOrganizations() {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      /*
      result.data.forEach((item, index) => {
        if (this.props.id === item.id) {
          this.setState({ loading: false, OrganizationList: [item] });
        }
      });*/
      this.setState({ loading: false, OrganizationList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonyGroups() {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getPatrimonyGroups();
    if (result.success) {
      this.setState({ loading: false, PatrimonyGroupList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getPatrimonyMaterials(subgroupid) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getPatrimonyMaterials(subgroupid);
    if (result.success) {
      this.setState({ loading: false, PatrimonyMaterialList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  mtel(num) {
    num = num.toString().replace(/\D/g, "");
    //
    if (num.toString().length > 5 && num.toString().length < 7) {
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    } else if (num.toString().length >= 7) {
      num = num.replace(/(\d{1,2})$/, ",$1");
      num = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      num = num.replace(/^0+/, "");
    } else if (num.toString().length == 4) {
      num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    } else {
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "value") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    const intl = this.props.intl;
    e.preventDefault();

    /*let x = document.querySelector(".classeteste");
    console.log(x);*/

    this.setState({ loading: true });

    let data = new FormData();

    if (this.isClient) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
        },
      }));
    }

    for (let key in this.state.item) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("departamentid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonygroupid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonymaterialid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("patrimonysubgroupid") > -1 &&
        this.state.item[key] != ""
      ) {
        data.append(key, this.state.item[key].id);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("invoicedate") > -1
      ) {
        data.append(
          key,
          moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else {
        data.append(key, this.state.item[key]);
      }
    }

    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let x = this.myRef;
    Api.post("/organizationpatrimonies", data, config)

      .then((result) => {
        if (result.data.success) {
          if (this.valorChecked === false) {
            this.props.onClose();
            /*
            setTimeout(function() {
              this.props.notification();
            }, 500);*/
            this.props.notification();
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: this.props.intl.formatMessage({ id: "add.success" }),
            });
          }

          this.props.loadingPatrimony(this.props.id);
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : this.props.intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({ id: "process.error" }),
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: this.props.intl.formatMessage({ id: "process.error" }),
          });
        }
        Api.kickoff(err);
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  selectedPadrão() {
    this.OrganizationId = this.setState((prevState) => ({
      item: {
        ...prevState.item,
        OrganizationId: this.props.id,
      },
    }));
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });
    if (stateName === "keeppatrimony") {
      this.valorChecked = value;
    }

    this.OrganizationId = this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
    if (stateName === "DepartamentId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["DepartamentText"]: text,
        },
      }));
    }
    if (stateName === "PatrimonySubgroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonySubgroupText"]: text,
          ["PatrimonyMaterialId"]: "",
        },
      }));

      if (value.id != 0) {
        this.getPatrimonyMaterials(value.id);
      }
    }
    if (stateName === "OrganizationId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["OrganizationText"]: text,
          ["DepartamentId"]: "",
        },
      }));
      this.getDepartaments(value);
    }
    if (stateName === "PatrimonyGroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyGroupText"]: text,
          ["PatrimonySubgroupId"]: "",
          ["PatrimonyMaterialId"]: "",
        },
      }));
      if (value.id != 0) {
        this.getPatrimonySubgroups(value.id);
      }
    }
    if (stateName === "PatrimonyMaterialId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyMaterialText"]: text,
        },
      }));
    }

    this.setState({ loading: false });
  }

  changeCheck() {
    // console.log("cheguei");
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }
  render() {
    const { classes, headerMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    return (
      <div className="modalPatrimonio">
        <div className="containerModal">
          <button className="closeButton" onClick={this.props.onClose}>
            <HighlightOffIcon
              className="closeModal"
              style={{ marginBottom: 0 }}
            />
          </button>
          <div className="containerForm">
            {/*<div className={classes.drawerHeader} />*/}
            <Container component="main" maxWidth="md">
              <CssBaseline />
              <div className={classes.paper} style={{ marginTop: 10, marginBottom: 10 }}>
                <Avatar className={classes.avatar} style={{ marginTop: 0 }}>
                  <QueuePlayNext />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {<FormattedMessage id="add.item" />}
                </Typography>
                <form
                  name="myForm"
                  className={classes.form}
                  onSubmit={this.handleSubmit}
                  encType="multipart/form-data"
                >
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect
                        label={intl.formatMessage({
                          id: "organization.header",
                        })}
                        options={this.state.OrganizationList}
                        stateName="OrganizationId"
                        changeSelect={this.changeValues}
                        selected={this.props.id}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({ id: "departament" })}
                        options={this.state.DepartamentList}
                        stateName="DepartamentId"
                        changeSelect={this.changeValues}
                        selected={item.DepartamentId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({
                          id: "menu.patrimonygroups",
                        })}
                        options={this.state.PatrimonyGroupList}
                        stateName="PatrimonyGroupId"
                        changeSelect={this.changeValues}
                        selected={item.PatrimonyGroupId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({
                          id: "menu.patrimonysubgroups",
                        })}
                        options={this.state.PatrimonySubgroupList}
                        stateName="PatrimonySubgroupId"
                        changeSelect={this.changeValues}
                        selected={item.PatrimonySubgroupId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({
                          id: "menu.patrimonymaterials",
                        })}
                        options={this.state.PatrimonyMaterialList}
                        stateName="PatrimonyMaterialId"
                        changeSelect={this.changeValues}
                        selected={item.PatrimonyMaterialId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "name" })}
                        inputProps={{ maxLength: 250 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="name"
                        onChange={this.handleInputChange}
                        value={item.name}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "tipping" })}
                        inputProps={{ maxLength: 250 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="tipping"
                        onChange={this.handleInputChange}
                        value={item.tipping}
                        required
                      />
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <TextField
                        label={intl.formatMessage({ id: "value" })}
                        variant="outlined"
                        value={item.value}
                        onChange={this.handleInputChange}
                        name="value"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required
                      />
                    </Grid>
                    {/*<Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                        <SimpleHelp message={"Usar . como separador decimal"} />
                        </Grid>*/}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "serial.number" })}
                        inputProps={{ maxLength: 250 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="serialnumber"
                        onChange={this.handleInputChange}
                        value={item.serialnumber}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "brand" })}
                        inputProps={{ maxLength: 250 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="brand"
                        onChange={this.handleInputChange}
                        value={item.brand}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "model" })}
                        inputProps={{ maxLength: 250 }}
                        autoComplete="fname"
                        variant="outlined"
                        name="model"
                        onChange={this.handleInputChange}
                        value={item.model}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <SimpleDatePicker
                        label="Data da Nota"
                        name="invoicedate"
                        stateName="invoicedate"
                        changeDate={this.changeValues}
                        currentDate={item.invoicedate}
                        required
                      />
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="filled-number"
                        label={intl.formatMessage({ id: "garanty" })}
                        type="number"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        name="garanty"
                        onChange={this.handleInputChange}
                        value={item.garanty}
                        required
                      />
                    </Grid>
                    <Grid item xs={11} sm={11} style={{ textAlign: "left" }}>
                      <SimpleCheckBox
                        selected={true}
                        label={intl.formatMessage({ id: "keep.called" })}
                        name="keeppatrimony"
                        stateName="keeppatrimony"
                        changeSelect={this.changeValues}
                        className="classeteste"
                        ref={this.myRef}
                        onClick={() => {
                          this.changeCheck();
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({ id: "called.registration.message.functionality.called.data" })} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      align-items-xs-center={"true"}
                      justify-xs-flex-end={"true"}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {this.props.intl.formatMessage({ id: "save" })}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>

              {this.state.loading && (
                <Overlay>
                  <CircularProgress color="secondary" />
                </Overlay>
              )}

              <SnackbarsModal
                variant={this.state.notificationVariant}
                message={this.state.notificationMessage}
                isOpen={this.state.openNotification}
                toClose={this.closeNotification}
              />
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

ModalPatrimonio.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ModalPatrimonio))
);
