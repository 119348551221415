import { QueuePlayNext } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-quill/dist/quill.snow.css'; // ES6
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import DialogGeneral from '../../components/dialog-general';
import Footer from '../../components/footer';
import HeaderMenu from '../../components/header-menu';
import CustomizedSnackbars from '../../components/material-snackbars';
import Title from '../../components/title-name';
import { browserHistory } from '../../helpers/history';
import * as componentActions from '../../redux/actions/integrationauths';
import Api from '../../services/api';
import * as IntegrationAuthService from "../../services/integrationauth.service";
import * as IntegrationAuthHeadersService from "../../services/integrationauthheadersservice";
import * as IntegrationAuthParamsService from "../../services/integrationauthparams.service";
import * as IntegrationAuthRequestBodyService from "../../services/integrationauthrequestbodyservice";
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';
import { PapiroConsole } from '../../utils/papiroConsole';

import { FormControlLabel, Tab, Tabs } from '@mui/material';

import EditableTable from '../authentication-registration/components/editable-table';


import { Radio, RadioGroup } from '@mui/material';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import ApiResponseDisplay from './components/api-response-display';

const StyledTextField = styled(TextField)`
  width: 100%;
`;

class AuthenticationEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, integrationauths } = this.props;

    console.log("=== DA ===")
    console.log(integrationauths)
    console.log("integrationauths")
    console.log(this.props)

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }




    var listParams = integrationauths.integrationauth.integrationauthparams
    var renamedListParams = listParams.map(item => ({
      id: item.id ? item.id : 0,
      name: item.paramname,
      value: item.paramvalue,
      isPathParam: item?.ispathparam ?? false,
      isEditable: false,
      createdInSystem: true
    }));
    renamedListParams.push({ id: renamedListParams && renamedListParams.length > 0 ? renamedListParams.length + 1 : 1, name: '', value: '', isEditable: true });


    var listHeaders = integrationauths.integrationauth.integrationauthheaders
    var renamedListHeaders = listHeaders.map(item => ({
      id: item.id ? item.id : 0,
      name: item.name,
      value: item.value,
      isEditable: false,
      createdInSystem: true
    }));
    renamedListHeaders.push({ id: renamedListHeaders && renamedListHeaders.length > 0 ? renamedListHeaders.length + 1 : 1, name: '', value: '', isEditable: true });


    var listRequestBody = integrationauths.integrationauth.integrationauthrequestbody
    var jsonTextItem = listRequestBody.find(item => item.type == 3);
    const jsonValue = jsonTextItem ? jsonTextItem.value : '';


    var renamedListRequestBodyFormData = listRequestBody.filter(item => item.type == 2).map(item => ({

      id: item.id ? item.id : 0,
      name: item.name,
      value: item.value,
      isEditable: false,
      createdInSystem: true
    }));

    renamedListRequestBodyFormData.push({ id: renamedListRequestBodyFormData && renamedListRequestBodyFormData.length > 0 ? renamedListRequestBodyFormData.length + 1 : 1, name: '', value: '', isEditable: true });




    this.initialState = {
      updatedat: moment().format('YYYY/MM/DD HH:mm:ss'),
      updateduser: userSession.user.id,
      id: integrationauths.integrationauth.id,
      name: integrationauths.integrationauth.name,
      url: integrationauths.integrationauth.url,
      accessTokenFieldName: integrationauths.integrationauth.accesstokenfieldname,
      methodAuth: integrationauths.integrationauth.methodauth,
      //dataType: integrationauths.integrationauth.datatype,
      //contentType: integrationauths.integrationauth.contenttype,
      method: integrationauths.integrationauth.method,
      basicAuthUser: integrationauths.integrationauth.basicauthuser,
      basicAuthPassword: integrationauths.integrationauth.basicauthpassword,
      token: '',

    };

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      methodAuthList: [],
      //dataTypeList: [],
      //contentTypeList: [],
      methodList: [],
      //rows: renamedList,
      //nextId: renamedList && renamedList.length > 0  ? renamedList.length + 1 : 2,


      tabIndex: 0,
      paramRows: renamedListParams,
      headerRows: renamedListHeaders,
      formDataRows: renamedListRequestBodyFormData,
      jsonValue: jsonValue,
      jsonError: '',
      requestBodyType: `${integrationauths.integrationauth.requestbodytype}`,
      DialogRemoveParamState: false,
      DialogRemoveHeaderState: false,
      DialogRemoveFormDataState: false,
      status: null,
      content: null


    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.closeRemoveParamsModal = this.closeRemoveParamsModal.bind(this);
    this.testflow = this.testflow.bind(this);

  }


  async componentDidMount() {

    this.setState({ loading: true })
    await this.getData();
    await this.getToken()

    this.setState({ loading: false })


  };



  async getData() {

    const result = await IntegrationAuthService.getData()
    if (result.success) {
      var data = result.data
      PapiroConsole.log("data here")
      PapiroConsole.log(data)

      var elementMethod = data.methodintegration.find(c => c.id == this.state.item.method)
      var elementMethodAuth = data.authenticationmethodintegration.find(c => c.id == this.state.item.methodAuth)
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          method: elementMethod,
          methodAuth: elementMethodAuth
        },
        methodAuthList: data.authenticationmethodintegration, methodList: data.methodintegration//, contentTypeList: data.contenttypeintegration //, dataTypeList: data.datatypeintegration,
      }))


    }
    else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }

  }

  async getToken() {
    this.setState({ loading: true })
    const result = await IntegrationAuthService.getToken(this.state.item.id)
    if (result.success) {
      var data = result.data

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          "token": data && data.length != 0 ? data : ''
        }
      }))


    }
    else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }

  }


  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value



    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  validations(data) {
    PapiroConsole.log("entrei aqui")
    var returnStatus = true;
    const intl = this.props.intl;
    PapiroConsole.log("data")
    PapiroConsole.log(data)

    Object.keys(data).forEach(element => {
      PapiroConsole.log(element)
      if (element == 'name' || element == 'url' || element == 'accessTokenFieldName') {
        if (data[element] === null || data[element] == '') {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento de todos os campos",
          })

          returnStatus = false;
        }
      }
      else if (element == 'methodAuth' || element == 'method') {

        if (data[element] === null || data[element].id == 0) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento de todos os campos",
          })

          returnStatus = false;
        }

      }



    });

    return returnStatus;


  }

  testflow = async e => {

    e.preventDefault()

    this.setState({ loading: true })

    const formData = Object.assign({}, this.state.item);


    var filteredListParams = []
    var filteredListHeaders = []
    var filteredListFormData = []

    if (this.state.paramRows) {
      filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
    }
    if (this.state.headerRows) {
      filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
    }

    if (this.state.formDataRows) {
      filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
    }

    formData.ParamRows = filteredListParams;
    formData.HeaderRows = filteredListHeaders;
    formData.BodyRequestRows = filteredListFormData;
    formData.RequestBodyType = this.state.requestBodyType;
    formData.jsonValue = this.state.jsonValue;

    formData.method = formData.method.id
    formData.methodAuth = formData.methodAuth.id

    PapiroConsole.log("formData")
    PapiroConsole.log(formData)

    var result = await IntegrationAuthService.testFlow(formData)
    PapiroConsole.log("result aqui 123")
    PapiroConsole.log(result)
    if (result && result.data && result.success) {
      try {

          const intl = this.props.intl;
          if (result.data.success) {
            const jsonData = JSON.parse(result.data.content);

            var jsonDataConverted = JSON.stringify(jsonData, null, 2);

            var content = jsonData.data && jsonData.data[this.state.item.accessTokenFieldName] != null 
            ? jsonData.data[this.state.item.accessTokenFieldName] 
            : jsonData[this.state.item.accessTokenFieldName] != null ? jsonData[this.state.item.accessTokenFieldName] : jsonDataConverted

            this.setState({
              loading: false,
              status: `Status Code: ${result.data.statuscode}`,
              content: jsonDataConverted
            })
          }
          else {
            this.setState({
              loading: false,
              status: `Status Code: ${result.data.statuscode}`,
              content: result.data.content ? result.data.content : result.data.reasonphrase
            })

          }

      } catch (error) {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          status: `Status Code: ${result.data.statuscode}`,
          content: result.data.content,

        })
      }

    } else {
      PapiroConsole.log("result error")
      PapiroConsole.log(result)
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
      })
      Api.kickoff(result)
    }



  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const formData = Object.assign({}, this.state.item);

    if (this.validations(formData)) {

      var filteredListParams = []
      var filteredListHeaders = []
      var filteredListFormData = []

      if (this.state.paramRows) {
        filteredListParams = this.state.paramRows.filter(item => !item.isEditable);
      }
      if (this.state.headerRows) {
        filteredListHeaders = this.state.headerRows.filter(item => !item.isEditable);
      }

      if (this.state.formDataRows) {
        filteredListFormData = this.state.formDataRows.filter(item => !item.isEditable);
      }

      formData.ParamRows = filteredListParams;
      formData.HeaderRows = filteredListHeaders;
      formData.BodyRequestRows = filteredListFormData;
      formData.RequestBodyType = this.state.requestBodyType;
      formData.jsonValue = this.state.jsonValue != '' ? this.state.jsonValue : null;

      formData.method = formData.method.id
      formData.methodAuth = formData.methodAuth.id

      PapiroConsole.log("formData")
      PapiroConsole.log(formData)

      var result = await IntegrationAuthService.updateItem(formData)
      if (result && result.success) {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "add.success" }),

        })

        this.props.addCurrent(result.data)


      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }

    }
  };

  closeNotification() {
    this.setState({ openNotification: false })
  }



  changeValues(stateName, value, text = '') {


    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  // handleInputChangeTable = (e, id, field) => {
  //   const { value } = e.target;
  //   this.setState(prevState => ({
  //     rows: prevState.rows.map(row => (row.id === id ? { ...row, [field]: value } : row))
  //   }));
  // };

  // handleAddRow = async id => {


  //   PapiroConsole.log("id aqui")
  //   PapiroConsole.log(id)
  //   const { rows, nextId } = this.state;
  //   const row = rows.find(row => row.id === id);
  //   this.setState({loading : true})
  //   if (row.name && row.value) {

  //     var formData= {
  //       name : row.name, 
  //       value : row.value,
  //       integrationauthid : this.state.item.id
  //     }
  //     //var formData = row;
  //     var result = await IntegrationAuthParamsService.addItem(formData)
  //     if (result && result.success) {
  //       PapiroConsole.log("result.data")
  //       PapiroConsole.log(result.data)

  //       this.setState(prevState => ({
  //         rows: prevState.rows.map(row => (row.id === id ? { ...row, createdInSystem : true, id :result.data.id, isEditable: false } : row)).concat({ id: nextId, name: '', value: '', isEditable: true }),
  //         nextId: nextId + 1,
  //         loading : false
  //       }));


  //     } else {
  //       const intl = this.props.intl
  //       this.setState({
  //         loading: false,
  //         openNotification: true,
  //         notificationVariant: 'error',
  //         notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
  //       })
  //       Api.kickoff(result)
  //     }




  //   } else {
  //     //alert('Preencha todos os campos antes de adicionar.');

  //     this.setState({
  //       loading: false,
  //       openNotification: true,
  //       notificationVariant: 'error',
  //       notificationMessage: "Preencha todos os campos antes de adicionar. "
  //     })
  //   }
  // };



  // deleteParam = () => {

  //   this.setState({ loading: true });

  //   Api.delete(`/integrationauthparams/${this.state.rowIdToRemove}`)
  //     .then(result => {
  //       if (result.data.success) {
  //         const intl = this.props.intl;
  //         this.setState(prevState => ({
  //           rows: prevState.rows.filter(row => row.id !== this.state.rowIdToRemove),
  //           rowIdToRemove : 0,
  //           loading: false,
  //           openNotification: true,
  //           notificationVariant: 'success',
  //           notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
  //         }),


  //       );

  //         this.closeRemoveModal()
  //       }
  //       else {
  //         const intl = this.props.intl;
  //         this.setState({
  //           loading: false, openNotification: true, notificationVariant: 'error',
  //           notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
  //         });
  //         Api.kickoff(result);
  //       }

  //     })
  //     .catch(err => {

  //       const intl = this.props.intl;
  //       this.setState({
  //         loading: false, openNotification: true, notificationVariant: 'error',
  //         notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
  //       });
  //       Api.kickoff(err);
  //     });
  // }



  // handleDeleteRow = rowItem => {

  //   // caso o parâmetro ja exista na base
  //   if(rowItem.createdInSystem == true){
  //     this.setState({ DialogRemoveState: true, rowIdToRemove: rowItem.id })
  //   }

  // };






  handleAddRow = async (type, id) => {

    PapiroConsole.log("id aqui")
    PapiroConsole.log(id)
    const row = this.state[type].find(row => row.id === id);
    this.setState({ loading: true })
    if (row.name && row.value) {

      var formData = {
        name: row.name,
        value: row.value,
        integrationauthid: this.state.item.id,
        ...(type === "paramRows" ? {ispathparam: row?.isPathParam ?? false} : {})
      }

      var result;

      switch (type) {
        case "paramRows":
          result = result = await IntegrationAuthParamsService.addItem(formData)
          break;
        case "headerRows":
          result = result = await IntegrationAuthHeadersService.addItem(formData)
          break;
        case "formDataRows":
          result = result = await IntegrationAuthRequestBodyService.addItem(formData)
        default:
          break;
      }
      if (result && result.success) {
        PapiroConsole.log("result.data")
        PapiroConsole.log(result.data)
        this.setState(prevState => ({
          // rows: prevState.rows.map(row => (row.id === id ? { ...row, createdInSystem : true, id :result.data.id, isEditable: false } : row)).concat({ id: nextId, name: '', value: '', isEditable: true }),
          // nextId: nextId + 1,
          [type]: prevState[type].map(row => (row.id === id ? { ...row, createdInSystem: true, id: result.data.id, isEditable: false } : row)).concat({ id: Math.max(...prevState[type].map(r => r.id)) + 1, name: '', value: '', isEditable: true }),
          loading: false
        }));




      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }

    }

  }



  handleTabChange = (e, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  handleRequestBodyTypeChange = (e) => {
    this.setState({ requestBodyType: e.target.value, jsonError: '' });
  };

  handleInputChangeTable = (type, field, value, id) => {

    PapiroConsole.log("type aqui")
    PapiroConsole.log(type)
    PapiroConsole.log(id)
    PapiroConsole.log(field)
    PapiroConsole.log(value)


    this.setState(prevState => ({
      [type]: prevState[type].map(row => (row.id === id ? { ...row, [field]: value } : row))
    }));

  };

  validateJson = (value) => {
    try {
      JSON.parse(value);
      this.setState({ jsonError: '' }); // Se o JSON for válido, limpa a mensagem de erro
    } catch (error) {
      this.setState({ jsonError: 'JSON inválido' }); // Se o JSON for inválido, define a mensagem de erro
    }
  };

  handleJsonValueChange = (e) => {
    const { value } = e.target;
    this.setState({ jsonValue: value });
    this.validateJson(value);
  };

  deleteParam = () => {

    this.setState({ loading: true });

    Api.delete(`/integrationauthparams/${this.state.rowIdParamToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            paramRows: prevState["paramRows"].filter(row => row.id !== this.state.rowIdParamToRemove),
            rowIdParamToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),

          this.closeRemoveParamsModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }


  handleDeleteParam = (type, rowItem) => {

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveParamState: true, rowIdParamToRemove: rowItem.id })
    }
  };

  closeRemoveParamsModal = () => {

    this.setState({ DialogRemoveParamState: false, rowIdParamToRemove: 0 })

  };


  deleteHeader = () => {

    this.setState({ loading: true });

    Api.delete(`/Integrationauthheaders/${this.state.rowIdHeaderToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            headerRows: prevState["headerRows"].filter(row => row.id !== this.state.rowIdHeaderToRemove),
            rowIdHeaderToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),

          this.closeRemoveHeadersModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  handleDeleteHeader = (type, rowItem) => {

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveHeaderState: true, rowIdHeaderToRemove: rowItem.id })
    }
  };

  closeRemoveHeadersModal = () => {

    this.setState({ DialogRemoveHeaderState: false, rowIdHeaderToRemove: 0 })

  };





  deleteFormData = () => {

    this.setState({ loading: true });

    PapiroConsole.log("this.state.rowIdFormDataToRemove 2")
    PapiroConsole.log(this.state.rowIdFormDataToRemove)

    Api.delete(`/integrationauthrequestbody/${this.state.rowIdFormDataToRemove}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState(prevState => ({
            formDataRows: prevState["formDataRows"].filter(row => row.id !== this.state.rowIdFormDataToRemove),
            rowIdFormDataToRemove: 0,
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          }));
          // this.setState(prevState => ({
          //   rows: prevState.rows.filter(row => row.id !== this.state.rowIdParamToRemove),

          // }),

          this.closeRemoveFormDatasModal()
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }

      })
      .catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  handleDeleteFormData = (type, rowItem) => {

    PapiroConsole.log("rowIdFormDataToRemove 1 ")
    PapiroConsole.log(rowItem)

    // caso o parâmetro ja exista na base
    if (rowItem.createdInSystem == true) {
      this.setState({ DialogRemoveFormDataState: true, rowIdFormDataToRemove: rowItem.id })
    }
  };

  closeRemoveFormDatasModal = () => {

    this.setState({ DialogRemoveFormDataState: false, rowIdFormDataToRemove: 0 })

  };





  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    const { rows } = this.state;
    PapiroConsole.log("this.state.requestBodyType")
    PapiroConsole.log(this.state.requestBodyType)

    const { tabIndex, paramRows, headerRows, requestBodyType, formDataRows, jsonValue, jsonError } = this.state;



    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#3f51b4' }}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5' style={{ marginTop: '10px' }}>
                {<FormattedMessage id="edit.authentication" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "authentication.method" })} options={this.state.methodAuthList} stateName='methodAuth' changeSelect={this.changeValues} selected={this.state.item.methodAuth} required />
                  </Grid>
                  {this.state.item.methodAuth && this.state.item.methodAuth.id == 2 &&

                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField inputProps={{ maxLength: 255 }} fullWidth label="Usuário da autenticação básica" variant='outlined' name='basicAuthUser' onChange={this.handleInputChange} value={item.basicAuthUser} required={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false} />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField inputProps={{ maxLength: 255 }} fullWidth label="Senha da autenticação básica" variant='outlined' name='basicAuthPassword' onChange={this.handleInputChange} value={item.basicAuthPassword} required={this.state.item.methodAuth && this.state.item.methodAuth.id == 2 ? true : false} />
                      </Grid>

                    </>


                  }

                  {this.state.item.methodAuth && this.state.item.methodAuth.id == 1 &&

                    <>
                      <Grid item xs={12} sm={12}>
                        <TextField fullWidth label="Token" variant='outlined' name='token' onChange={this.handleInputChange} value={item.token} required={this.state.item.methodAuth && this.state.item.methodAuth.id == 1 ? true : false} />
                      </Grid>
                    </>

                  }

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "url" })} autoComplete='fname' variant='outlined' name='url' onChange={this.handleInputChange} value={item.url} required selected={this.state.item.url} />
                  </Grid>

                  {/*<Grid item xs={12} sm={12}>
                    <SimpleSelect label={intl.formatMessage({ id: "data.types" })} options={this.state.dataTypeList} stateName='dataType' changeSelect={this.changeValues} selected={this.state.item.dataType} required />
                  </Grid>*/}

                  {/* <Grid item xs={12} sm={12}>
                    <SimpleSelect label={intl.formatMessage({ id: "content.type" })} options={this.state.contentTypeList} stateName='contentType' changeSelect={this.changeValues} selected={this.state.item.contentType} required />
                  </Grid>*/}

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "call.method" })} options={this.state.methodList} stateName='method' changeSelect={this.changeValues} selected={this.state.item.method} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Tabs style={{ marginTop: '10px' }} value={tabIndex} onChange={this.handleTabChange}>
                      <Tab label="Parâmetros de consulta" />
                      <Tab label="Cabeçalho" />
                      <Tab label="Corpo da Requisição" />
                    </Tabs>

                    {tabIndex === 0 && (
                      <EditableTable rows={paramRows} handleDeleteRow={this.handleDeleteParam} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="paramRows" />
                    )}

                    {tabIndex === 1 && (
                      <EditableTable rows={headerRows} handleDeleteRow={this.handleDeleteHeader} handleAddRow={this.handleAddRow} handleInputChangeTable={this.handleInputChangeTable} type="headerRows" />
                    )}

                    {tabIndex === 2 && (

                      <Grid item xs={12}>
                        <RadioGroup
                          value={requestBodyType}
                          onChange={this.handleRequestBodyTypeChange}
                          sx={{ display: 'flex', flexDirection: 'row' }} // Exibir horizontalmente
                        >
                          <FormControlLabel value="1" control={<Radio />} label="Nenhum" />
                          <FormControlLabel value="2" control={<Radio />} label="Form-data" />
                          <FormControlLabel value="3" control={<Radio />} label="JSON" />
                        </RadioGroup>




                        {requestBodyType === '2' && (
                          <EditableTable handleDeleteRow={this.handleDeleteFormData} handleAddRow={this.handleAddRow} rows={formDataRows} handleInputChangeTable={this.handleInputChangeTable} type="formDataRows" />
                        )}

                        {requestBodyType === '3' && (
                          <div>
                            <StyledTextField
                              multiline
                              rows={10}
                              value={jsonValue}
                              onChange={this.handleJsonValueChange}
                              placeholder="Digite seu JSON aqui"
                              error={!!jsonError} // Define o estado de erro
                              helperText={jsonError} // Exibe a mensagem de erro
                            />
                          </div>
                        )}

                      </Grid>

                    )}
                  </Grid>


                  {/*<StyledTableContainer component={Paper}>
                    <StyledTable>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nome</StyledTableCell>
                          <StyledTableCell>Valor</StyledTableCell>
                          <StyledTableCell>Ação</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(row => (
                          <TableRow key={row.id}>
                            <StyledTableBodyCell>
                              <StyledTextField
                                value={row.name}
                                onChange={(e) => this.handleInputChangeTable(e, row.id, 'name')}
                                disabled={!row.isEditable}
                              />
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                              <StyledTextField
                                value={row.value}
                                onChange={(e) => this.handleInputChangeTable(e, row.id, 'value')}
                                disabled={!row.isEditable}
                              />
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                              {row.isEditable ? (
                                <StyledIconButton onClick={() => this.handleAddRow(row.id)}>
                                  <SaveIcon />
                                </StyledIconButton>
                              ) : (
                                <StyledIconButton delete onClick={() => this.handleDeleteRow(row)}>
                                  <Delete />
                                </StyledIconButton>
                              )}
                            </StyledTableBodyCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </StyledTableContainer>*/}


                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "access.token.field.name" })} autoComplete='fname' variant='outlined' name='accessTokenFieldName' onChange={this.handleInputChange} value={item.accessTokenFieldName} required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {this.state.content && this.state.status &&
                      <ApiResponseDisplay status={this.state.status} content={this.state.content} />
                    }
                  </Grid>



                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          onClick={this.testflow}
                          fullWidth
                          variant='contained'
                          style={{ backgroundColor: '#687EFC' }}
                          className={classes.submit} >
                          {<FormattedMessage id="Testar fluxo" />}
                        </Button>

                      </Grid>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>

                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            <DialogGeneral
              dialogTitle="Remoção de parâmetro"
              dialogSubTitle="Você realmente deseja remover este parâmetro ?"
              open={this.state.DialogRemoveParamState}
              openOrCloseModalFunction={this.closeRemoveParamsModal}
              handleConfirmation={this.deleteParam}
            />
            <DialogGeneral
              dialogTitle="Remoção de cabeçalho"
              dialogSubTitle="Você realmente deseja remover este cabeçalho ?"
              open={this.state.DialogRemoveHeaderState}
              openOrCloseModalFunction={this.closeRemoveHeadersModal}
              handleConfirmation={this.deleteHeader}
            />
            <DialogGeneral
              dialogTitle="Remoção de dados"
              dialogSubTitle="Você realmente deseja remover este dado selecionado ?"
              open={this.state.DialogRemoveFormDataState}
              openOrCloseModalFunction={this.closeRemoveFormDatasModal}
              handleConfirmation={this.deleteFormData}
            />



            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />



          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  integrationauths: state.integrationauths
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AuthenticationEdit)));
