import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/defaultaction/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getOrganizationsByDefaultActionId = async (id) => { 
  let data = [];
  await Api.get('/organizationdefaultaccompaniments/getOrganizationsByDefaultActionId/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

