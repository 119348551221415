export default function calledclassifications(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                calledclassification: action.calledclassification,
            }
        default:
            return state;
    }
}
