export default function additionalcalledcostitems(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
        return {
            ...state,
            additionalcalledcostitem: action.additionalcalledcostitem,
        }
    case 'ADD_CURRENT_ORGANIZATION':
        return {
            ...state,
            additionalcalledcostitem: action.additionalcalledcostitem,
            organization: action.organization,
        }
      default:
          return state;
  }
}