import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleCheckBox from '../../components/checkbox/check'
import Title from '../../components/title-name';
import * as Service from '../../services/defaultaction.service'
import * as ServiceOrganizationDefaultAccompaniment from '../../services/organizationdefaultaccompaniment.service'
import * as componentActions from '../../redux/actions/defaultactions';
import Footer from '../../components/footer'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import { PapiroConsole } from '../../utils/papiroConsole';
import { getOrg } from "../../services/organization.service";
import DialogAddMultiple from '../../components/dialog-add-multiple';

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}
class DefaultActionsEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, defaultactions } = this.props;

    console.log("=== DA ===")
    console.log(defaultactions)
    console.log(this.props)

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    /*if(!defaultactions || (defaultactions && !defaultactions.defaultaction)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }*/

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    var parentResult = [];

    this.initialState = {
      updatedat: moment().format('YYYY/MM/DD HH:mm:ss'),
      updateduser: userSession.user.id,
      id: defaultactions.defaultaction.id,
      action: defaultactions.defaultaction.text,
      active: defaultactions.defaultaction.active,
      title: defaultactions.defaultaction.title,
      OrganizationId : [],
      Organizations: "",
    };

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      openOrgInsertModal: false,
      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],
      OrgListIds: [],
      selectOrganizations: false,
     

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.closeOrgModal = this.closeOrgModal.bind(this)
    this.getOrganizations =  this.getOrganizations.bind(this)
    this.saveOrgInsertModal = this.saveOrgInsertModal.bind(this)

    this.getOrganizationsByDefaultActionId = this.getOrganizationsByDefaultActionId.bind(this)
  }


  componentDidMount() {

    this.getOrganizations()
    this.getOrganizationsByDefaultActionId(this.state.item.id)


  };

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }


  async getOrganizationsByDefaultActionId(id) {

    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await ServiceOrganizationDefaultAccompaniment.getOrganizationsByDefaultActionId(id);

    if (result.success) {
      PapiroConsole.log(result)
      if (result.data && result.data.length > 0) {
        this.setState(prevState => ({
          loading: false, OrgList: result.data,

        }));
      }
      else{
        this.setState(prevState => ({
          loading: false,

        }));
      }





    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {

    
    PapiroConsole.log("entrei aqui")

    PapiroConsole.log(this.state.item.OrganizationId)
    if(this.state.item.OrganizationId != null && this.state.item.OrganizationId.length > 0){
          
      this.setState({
        loading: true
      })

      let data = new FormData();
  
      var OrganizationIds = this.state.item.OrganizationId.map(item => {
        return item.id + ","
      })
  
      data.append("organizationsids", OrganizationIds);
      data.append("defaultaccompanimentid", this.state.item.id);
  
      const intl = this.props.intl;
      await Api.post('/organizationdefaultaccompaniments/addinorganizationdefaultaccompaniment', data)
        .then(result => {
          if (result.data.success) {
            var organizations = [];
            
            result.data.data.map(item => {
                if(item.organization)
                {
                    
                    organizations.push(item.organization)
                }
            
              
            })

           

            this.setState(prevState => ({
              loading: false,
              OrgList: organizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              item: {
                ...prevState.item,
                Organizations: [],

              },
            }));

            this.openOrgInsertModal()
            
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
  
            });
            Api.kickoff(result);
          }
  
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
  
      this.setState({
        openCategoryInsertModal: !this.state.openCategoryInsertModal,
        loading: false
      })

     /* var categories = [];
      PapiroConsole.log("this.state.organizationfield.OrganizationId")
      PapiroConsole.log(this.state.organizationfield.OrganizationId)
      this.state.organizationfield.OrganizationId.map(item => {
        item.name = item.parentname && item.parentname.length > 0 ? item.parentname : item.name;
        categories.push(item)
      })
      PapiroConsole.log("categories")
      PapiroConsole.log(categories)
     // PapiroConsole.log("categories") 
      //PapiroConsole.log(categories)
      this.setState({
        loading: false,
        CategoryList: categories,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "add.success" }),
        openCategoryInsertModal: !this.state.openCategoryInsertModal,
      })*/
    }

  }

  setOrgDelete = async (org) => {
    
    this.setState({
      OrgListForDelete: org + "," 
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })

  handleChangeEditorAction = (value) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        action: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    this.setState(prevState => ({
      item: {
        ...prevState.item,
      }
    }))

    for (let key in this.state.item) {
      if (key.toString().toLowerCase().indexOf('id') > -1) {
        data.append(key, this.state.item[key]);
      } else if (key.toString().toLowerCase().indexOf('updateduser') > -1) {
        data.append(key, this.state.item[key]);
      } else if (key.toString().toLowerCase().indexOf('updatedat') > -1) {
        data.append(key, this.state.item[key]);
      } else if (key.toString().toLowerCase().indexOf('action') > -1) {
        data.append("text", this.state.item[key]);
      } else if (key.toString().toLowerCase().indexOf('active') > -1) {
        data.append(key, this.state.item[key]);
      }
      else if (key.toString().toLowerCase().indexOf('title') > -1) {
        data.append(key, this.state.item[key]);
      }
      
    }

    var result = await Service.editItem(data)
    if (result && result.success) {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "edit.success" })
      })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            OrganizationId: this.state.OrgListForSelect,
            Organizations: this.state.OrgListForSelect,
          
          },
     
        }));
       
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            OrganizationId: [],
            Organizations: [],
          },
         
        }));
        
      }
    }
    else{
      
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value
        }
      }));
    }


    this.setState({ loading: false });
  };

  orgDeleteModal = async () => {

    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {
      this.setState({ loading: true });

      var orgDeleteList = this.state.OrgListForDelete.split(",")

      let data = new FormData();
      data.append("organizationsids", orgDeleteList);
      data.append("defaultaccompanimentid", this.state.item.id);

      const intl = this.props.intl;
      await Api.post('/organizationdefaultaccompaniments/deleteinorganizationdefaultaccompaniment', data)
        .then(result => {

          if (result.data.success) {
            var organizations = [];
            
            result.data.data.map(item => {
                if(item.organization)
                {
                    
                    organizations.push(item.organization)
                }
            
              
            })
            this.setState({
              loading: false,
              OrgList: organizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } 
  }

  openCategoryInsertModal = async () => {
    this.setState({
      openCategoryInsertModal: !this.state.openCategoryInsertModal
    })
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    PapiroConsole.log("this.state.OrgList")

    PapiroConsole.log(this.state.OrgList)
    PapiroConsole.log("state OrgListForDelete")

    PapiroConsole.log(this.state.OrgListForDelete)

    

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.default.action" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "title" })} autoComplete='fname' variant='outlined' name='title' onChange={this.handleInputChange} value={item.title} required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{intl.formatMessage({ id: "organization.header" })}*</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} xs={12} sm={12} >

                          <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                            <Grid xs={12} sm={4} >

                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <DialogConfirmation
                                msgButton={intl.formatMessage({ id: "organization.material.registration.remove.organization" })}
                                msg={intl.formatMessage({ id: "user.client.registration.confirm.remove.organization.from.user" })}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={e => this.orgDeleteModal()}
                                classes={classes}
                                icon={<DeleteIcon />}
                              />
                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={e => this.openOrgInsertModal()} >
                                {intl.formatMessage({ id: "organization.material.registration.add.organization" })}
                              </Button>
                            </Grid>
                          </Grid>

                          <Grid xs={12} sm={12}
                            style={{
                              marginLeft: 5,
                              marginBottom: 5,
                              border: '3px solid #eee'
                            }} >
                            <div style={{ height: 350 }}>
                              <DataGrid
                                style={{ fontSize: 14, color: '#000' }}
                                className="DataGridStyle"
                                components={{
                                  NoRowsOverlay: NoDataRows,
                                  Toolbar: GridToolbarFilterButton,
                                }}
                                rows={this.state.OrgList}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                onSelectionModelChange={itm => {
                                  if (itm.length > 0) {
                                    this.setOrgDelete(itm);
                                  } else {
                                    this.setState({
                                      OrgListForDelete: [],
                                    })
                                  }
                                }}
                                columns={[
                                  { headerName: intl.formatMessage({ id: "menu.organization" }), field: 'name', width: 500 },
                                ]}
                                HeadersVisibility="None"
                              />
                            </div>
                          </Grid>
                        </Grid>

                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="action" />
                    </Typography>
                    <ReactQuill
                      id="action"
                      name="action"
                      value={item.action}
                      style={{ height: 200 }}
                      fullWidth
                      required
                      InputLabelProps={{ shrink: item.action && item.action.length > 0 ? true : false }}
                      variant="outlined"
                      id="outlined-multiline-static"
                      onChange={this.handleChangeEditorAction} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />

            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />

          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

DefaultActionsEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  defaultactions: state.defaultactions
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(DefaultActionsEdit)));
