import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingsvalidations'
import Api from '../../../../services/api'
import { addItem } from '../../../../services/calledservicesettingsvalidation.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType, validateFyleTypeByTypeList } from '../../../../utils/fileUtils'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import AttachFileIcon from '@mui/icons-material/AttachFile';

function AddCalledServiceSettingsValidationItemDialog(props) {

  const { intl } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [title, setTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [active, setActive] = useState(true)
  const [files, setFiles] = useState([])


  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)
    if (props.open)
      setFiles([])
  }, [props.open])

  const setInitialState = () => {
    setTitle('')
    setErrorMessage('')
    setFiles([])
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const validateForm = _ => {
    if (title == null || (title != null && title.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Título' é obrigatório")
      return false
    } else if (errorMessage == null || (errorMessage != null && errorMessage.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Mensagem de erro' é obrigatório")
      return false
    } else if (files == null || (files != null && files.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Arquivo de validação' é obrigatório")
      return false
    }

    return true
  }

  const createItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Title", title)
      data.append("ErrorMessage", errorMessage)
      data.append("Active", active)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await addItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "add.success" }))
        if (response.data != null) {
          let oldItemsList = props.calledservicesettingsvalidations && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist && props.calledservicesettingsvalidations.calledservicesettingsvalidationslist.length > 0 ? [...props.calledservicesettingsvalidations.calledservicesettingsvalidationslist] : []

          let itemList = [response.data, ...oldItemsList]
          itemList = itemList.sort((a, b) => a.title - b.title)
          props.addCalledServiceSettingsValidationsList(itemList)
        }
        setInitialState()
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }

  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-add-called-service-setting-validation-dialog-title" >
        <DialogTitle id="form-add-called-service-setting-validation-dialog-title">
          Adicionar validação
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="title"
                variant="outlined"
                fullWidth
                id="title"
                label={`${intl.formatMessage({ id: "title" })}`}
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={12} sm={12} md={12} lg={8} style={{ marginBottom: 50 }}>
              <Typography>
                Mensagem
              </Typography>
              <ReactQuill
                id="errorMessage"
                name="errorMessage"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                value={errorMessage}
                style={{ height: 200 }}
                fullWidth
                required
                InputLabelProps={{ shrink: errorMessage && errorMessage.length > 0 ? true : false }}
                variant="outlined"
                onChange={(value) => setErrorMessage(value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch', display: 'flex', alignItems: 'center' }} >
            <Tooltip title={<h5>Mensagem, que será mostrada caso o e-mail/documento do usuário, seja encontrado no arquivo de validação</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      name="active" />
                  }
                  label={intl.formatMessage({ id:"active" })} />
              </FormGroup>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Arquivo de validação</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                  <Dropzone multiple={false}
                  onDrop={acceptedFiles => {
                    let attach = null
                    if (acceptedFiles != null && acceptedFiles.type != null)
                      attach = acceptedFiles
                    else if (acceptedFiles != null && Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
                      attach = acceptedFiles[0]
                    }
                    if (attach != null) {
                      PapiroConsole.log("=== attach ===")
                      PapiroConsole.log(attach)
                      let validateAttachment = validateFyleTypeByTypeList(attach.type, ["csv", ".csv", "text/csv", "application/vnd.ms-excel", "application/csv", "text/x-csv", "application/x-csv", "text/comma-separated-values", "text/x-comma-separated-values"])
                      if (validateAttachment)
                        setFiles([attach])
                      else
                        setMessage(true, 'error', 'Formato do arquivo inválido')
                    }
                  }}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <AttachFileIcon  />
                      <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage  id="drag.and.drop" />
                      </Typography>
                    </div>
                    )}
                  </Dropzone>
                  {files.map(file => {
                  return (
                    <div className={props.classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                    </div>
                    )
                  })}
                </div>
              </section>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch', display: 'flex', alignItems: 'center' }}>
              <Tooltip title={<h5>Os dados utilizados para validação, são E-mail/Documento do usuário, na solicitação do formulário externo. Insira um arquivo .csv, com um E-mail ou documento (CPF) por linha, na primeira coluna do arquivo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={createItem} >
            {intl.formatMessage({id:"save"})}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

AddCalledServiceSettingsValidationItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsvalidations: state.calledservicesettingsvalidations,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AddCalledServiceSettingsValidationItemDialog)))