import React, { Component } from "react";
import "./ModalPatrimonio.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import * as ServiceCallCenter from '../../services/apiCallCenter';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SnackbarsModal from "../snackbarsModal";
import CustomizedSnackbars from "../../components/material-snackbars";

import ScrollTabs from '../scrolltabs/ScrollableTabsButtonForce'
import * as ApiCallCenter from '../../services/apiCallCenter';
import { v4 as uuidv4 } from 'uuid';
class ModalPatrimonio extends Component {
  constructor(props) {
    super(props);
    const { userSession } = this.props;

    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      
    };
    
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let possiblePass = "0123456789";
    let text = "";
    let password = "";

    for (var i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      if (i < 4) {
          password += possiblePass.charAt(Math.floor(Math.random() * possiblePass.length));
      }
    }
    var calledSend = {
      "id": props.called.id
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      passwordRoom: password,
      linkRoom: uuidv4(),
      requestUser: props.requestUser,
      orgSettings: props.orgSettings,
      called: JSON.stringify(calledSend),
      hirer: [],
      organization: props.organization,
      organizationkey: props.organizationkey,
      calledid: props.called.id
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.onLoading = this.onLoading.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }
  componentDidMount() {
    Api.get(`/hirer/organization/`+this.state.orgSettings.organizationid)
      .then(result => {
        if (result.data.success) {
          this.setState({
            hirer: result.data.data
          });
        }
      })
      .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
          }
          else {
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: intl.formatMessage({ id: "process.error" })
              });
          }
      });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "value") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value,
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    /*let x = document.querySelector(".classeteste");
    ;*/

    this.setState({ loading: true });

    
  };

  onLoading(){
    this.setState({ loading: true });
  }

  async onFormSubmit(data, error, type, datasetScrollTable) {
    const intl = this.props.intl;
    //event.preventDefault();
    if(error) {
      if(type == 'email') {
          this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"modal.video.call.email.error"}),
          });
      } else {
          this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"modal.video.call.sms.error"}),
          });
      }
    }else{
      this.setState({ loading: true });

      const { userSession } = this.props;
  
      /*dataForm.append('email', this.state.hirer ? this.state.hirer.email : null);
      dataForm.append('accesskey', this.state.hirer ? this.state.hirer.accesskey : null);*/
      //dataForm.append('emailtec', userSession.user.email);
      
      //var result = await ApiCallCenter.ApiLoginPost(dataForm, '/users/loginAccessKey');

	
      await Api.post("/hirer/vertexLogin", data).then(result => {
        if(result && result.data && result.data.data ){
          let dataForm2 = new FormData();
		
          dataForm2.append('cod', this.state.linkRoom);
          dataForm2.append('password', this.state.passwordRoom);
          dataForm2.append('clientId', data);
          dataForm2.append('organizationkey', this.state.organizationkey);
          dataForm2.append('hirerid', this.state.hirer ? this.state.hirer.id : 0);
          dataForm2.append('called', this.state.called ? this.state.called : null);
          dataForm2.append('useremailtec', userSession.user.email);
          
          ApiCallCenter.ApiStartPost(dataForm2, '/rooms/AddWithCalledCreated', result.data.data.accesstoken)
            .then(resultA => {
              if(resultA && resultA.data && resultA.data.success == true &&  resultA.data.data != []){
                
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: this.props.intl.formatMessage({id:"modal.video.call.created.room"})
                });
                
                let dataIntegrationKey = new FormData();
                dataIntegrationKey.append('roomid', resultA.data.data.id);
                dataIntegrationKey.append('calledid', this.state.calledid);
  
                Api.post("/calleds/saveLogIntegrationKey", dataIntegrationKey).then(result => {
  
                });

                var url = ApiCallCenter.getUrl() + "/"+this.state.linkRoom+"?token="+result.data.data.accesstoken;
                
                if(type == 'email'){
                  window.open(url+"&email=true&value="+datasetScrollTable.emailCall+"&password="+datasetScrollTable.passCall, "_blank");
                  //ServiceCallCenter.sendEmailApi(datasetScrollTable);
                }else{
                  window.open(url+"&email=false&value="+datasetScrollTable.phone+"&password="+datasetScrollTable.password, "_blank");
                  //ServiceCallCenter.sendSMSApi(datasetScrollTable);
                }
                this.props.onClose();
              }else{
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: resultA.data && resultA.data.data && resultA.data.data.errors ? resultA.data.data.errors[0] : this.props.intl.formatMessage({id:"modal.video.call.room.error"})
                });
              }
            }).catch(resultA => {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: resultA.response && resultA.response.data && resultA.response.data.errors ? resultA.response.data.errors[0] : this.props.intl.formatMessage({id:"api.2see.connect.error"})
              });
            });
          
          
        }else{
			
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"api.2see.connect.error"})
          });
        }
  
      }).catch(err => {
		
		
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"api.2see.connect.error"})
        });
		
		


      });
    }
    
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.OrganizationId = this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    this.setState({ loading: false });
  }
  
  render() {
    const { classes, headerMenu, userSession } = this.props;
    const { item } = this.state;

    const intl = this.props.intl;
//console.error(userSession);
    return (
      <div className="modalPatrimonio">
        <div className="containerModal">
          <button className="closeButton" onClick={this.props.onClose}>
            <HighlightOffIcon
              className="closeModal"
              style={{ marginBottom: 0 }}
            />
          </button>
          <div className="containerForm">
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: false,
              })}
              style={{
                marginTop: '5%',
                maxWidth: '100%',
                display: 'flex'
              }}
            >
              {/*<div className={classes.drawerHeader} />*/}
              <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 10 }}>
                  <ScrollTabs
                    cod={this.state.linkRoom}
                    password={this.state.passwordRoom}
                    eventInit={this.onFormSubmit}
                    onLoading={this.onLoading}
                    hirerid={userSession.user.hirerid}
                    requestUser={this.state.requestUser}
                  />
                </div>

                {this.state.loading && (
                  <Overlay>
                    <CircularProgress color="secondary" />
                  </Overlay>
                )}

                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
				  top={"bottom"}
                />
              </Container>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

ModalPatrimonio.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ModalPatrimonio))
);
