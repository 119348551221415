import Api from './api';


export const getCalleds = async () => {
  let data = [];
  await Api.get("/calleds")
      .then(result => {
          data = result.data
      })
      .catch(err => {
          data = err;
      });
  return data;
};

export const getCalledsForRelation = async () => {
    let data = [];
    await Api.get("/calleds/relation")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  };
