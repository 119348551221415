import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch } from '@mui/material'
import Api from '../../../services/api'
import * as Service from '../../../services/configpasswordpolicy.service'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux';
import SimpleSelect from "../../../components/select/simple";

class MessageProviderConfig extends Component {

  constructor(props) {
    super(props)

    this.state = {
      messageproviderconfig: {},
      providerList: [{id: 1, name: 'Twilio'}]
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    let { configpasswordpolicy } = this.state
    
    let data = new FormData();

    for (let key in configpasswordpolicy) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1)
        data.append(key, moment(configpasswordpolicy[key]).format('YYYY/MM/DD HH:mm:ss'))
      else
        data.append(key, configpasswordpolicy[key])
    }
    
    let isEdit = configpasswordpolicy.id === 0 ? false : true

    let response = await Service.mergeItem(data);
    if (response && response.success) {
      setLoading(false)
      setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "save.success" }))
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      configpasswordpolicy: {
        ...prevState.configpasswordpolicy,
        [name]: value
      }
    }));
  };

  changeValues(stateName, value, text = "") {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
  }

  render() {
    const { classes, intl } = this.props
    const { configpasswordpolicy } = this.state

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography component='h1' variant='h3'>
              Configuração do provedor de mensageria
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <SimpleSelect
              label="Provedor"
              options={this.state.providerList}
              stateName="messageProvider"
              changeSelect={this.changeValues}
              selected={this.state.messageproviderconfig.id}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
              label=""
              type='number' 
              variant='filled' 
              InputLabelProps={{shrink: true}} 
              name='Token' 
              onChange={this.handleInputChange} 
              value={configpasswordpolicy.passwordexpirationtime}  
              style={{padding: 5}}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField 
              label={intl.formatMessage({ id:"passwordpolicy.minimumamountcharacterstitle" })}
              type='number' 
              variant='filled' 
              InputLabelProps={{shrink: true}} 
              name='minimumamountcharacters' 
              onChange={this.handleInputChange} 
              value={configpasswordpolicy.minimumamountcharacters}  
              style={{padding: 5}}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={12} sm={4}    >
                <Button 
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit} >
                  {<FormattedMessage id="save" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

MessageProviderConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired
}

export default connect(null, null)(MessageProviderConfig)