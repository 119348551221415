import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, AttachFile } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleSelect from '../../components/select/simple';
import ImageList from '@mui/material/ImageList'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { deleteAttachmentItem } from '../../services/additionalcalledcost.service'
import * as componentActions from '../../redux/actions/additionalcalledcosts';
import * as Service from '../../services/user.service';
import * as ItemService from '../../services/additionalcalledcostitems.service'
import * as TypeService from '../../services/additionalcalledcostitemtypes.service'
import * as ReasonService from '../../services/additionalcalledcostitemreasons.service'
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import calledTimeEntries from '../called-time-entries';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import NumberFormat from 'react-number-format';
import { PapiroConsole } from '../../utils/papiroConsole';
import { getFileDownloadLink } from '../../services/attachment.service';
import AttachFileIcon from '@mui/icons-material/AttachFile'; 
class AdditionalCalledCostEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,additionalcalledcosts, calleds } = this.props;

           

        
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }
  /*  if(!activitys || (activitys && !activitys.activity)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }*/

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );


    var defaultvalue = {id:0, name:''};
    this.initialState = {
       updatedUser: userSession.user.id,
       id: additionalcalledcosts.additionalcalledcost? additionalcalledcosts.additionalcalledcost.id : 0 ,
       name : additionalcalledcosts.additionalcalledcost? additionalcalledcosts.additionalcalledcost.name : '',
       observation: additionalcalledcosts.additionalcalledcost.observation ? additionalcalledcosts.additionalcalledcost.observation : '',
       calledId : additionalcalledcosts.additionalcalledcost? additionalcalledcosts.additionalcalledcost.calledid : 0,
       calledName: calleds.called? calleds.called.title : '',
       value : additionalcalledcosts.additionalcalledcost? additionalcalledcosts.additionalcalledcost.value : 0,
       additionalcalledcostattachments: additionalcalledcosts.additionalcalledcost.additionalcalledcostattachments ? additionalcalledcosts.additionalcalledcost.additionalcalledcostattachments : [],
       AdditionalCalledCostItemId: additionalcalledcosts.additionalcalledcost.additionalcalledcostitem != null ? additionalcalledcosts.additionalcalledcost.additionalcalledcostitem : 0,
       AdditionalCalledCostItemTypeId: additionalcalledcosts.additionalcalledcost.additionalcalledcostitemtype != null ? additionalcalledcosts.additionalcalledcost.additionalcalledcostitemtype : 0,
       AdditionalCalledCostItemReasonId: additionalcalledcosts.additionalcalledcost.additionalcalledcostitemreason != null ? additionalcalledcosts.additionalcalledcost.additionalcalledcostitemreason : 0,
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      AdditionalCalledCostItemList: [],
      AdditionalCalledCostTypeList: [],
      AdditionalCalledCostReasonList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getFile = this.getFile.bind(this)
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    async componentDidMount() {
      await this.getAllItemsActive()
      await this.getAllActiveTypesByItemId()
      await this.getAllActiveReasonsByItemId()
     };

     async getActivities(){
      this.setState({ loading: true });
     
      var result = await Service.GetAllActivities();
  
      
    
        if (result.success) {
  
         
          
          
  
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ["activities"]: result.data
            }, 
            loading : false
          }));
         // this.setState({ loading: false, users: result.data });
        
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
  };    
  
  getFile = async (attachment) => {
		const intl = this.props.intl;
		this.setState({ loading: true });
		
		let url = await getFileDownloadLink(attachment.id);
		if(url &&  url.data && url.data.data && url.data.success){
      this.setState({ loading: false });
			window.open(`${url.data.data}`, '_blank');
		} else{
			this.setState({
				loading: false,
        openNotification: true,
        notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});
    }
  }

    async getUsers(){
      this.setState({ loading: true });
      
      var result = await Service.GetUsersTechs(this.state.item.organizationCategoryId,this.state.item.organizationId);
  
      
    
        if (result.success) {
  
         
          
          
  
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ["users"]: result.data
            }, 
            loading : false
          }));
         // this.setState({ loading: false, users: result.data });
        
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
  };    


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == "hours"){
        if(e.target.value <= 0 ){
          e.target.value = 1
        }
        else{
    
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              [name]: value
            }
          }));
    
        }
    }
    else{
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));

    }

   
  };

  checkValidations() {
    const intl = this.props.intl;
    if(this.state.item.name == ''){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"missing.name.field"})

      });

      return false

    }
    if(this.state.item.value == ''){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.value.field"})

      });

      return false

    }

    if(this.state.item.value <= 0){
        this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.value.zero"})

      });

      return false

    }

    return true

    
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    if (this.state.item.AdditionalCalledCostItemId == null || this.state.item.AdditionalCalledCostItemId == 0 || (this.state.item.AdditionalCalledCostItemId && this.state.item.AdditionalCalledCostItemId.id && this.state.item.AdditionalCalledCostItemId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.item.field"})
      });
      return false
    }
    if (this.state.item.AdditionalCalledCostItemTypeId == null || this.state.item.AdditionalCalledCostItemTypeId == 0 || (this.state.item.AdditionalCalledCostItemTypeId && this.state.item.AdditionalCalledCostItemTypeId.id && this.state.item.AdditionalCalledCostItemTypeId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.type.field"})
      });
      return false
    }
    if (this.state.item.AdditionalCalledCostItemReasonId == null || this.state.item.AdditionalCalledCostItemReasonId == 0 || (this.state.item.AdditionalCalledCostItemReasonId && this.state.item.AdditionalCalledCostItemReasonId.id && this.state.item.AdditionalCalledCostItemReasonId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.reason.field"})
      });
      return false
    }

    this.setState({ loading: true });
    //var returnReponse = this.checkValidations();
    var returnReponse = true

    if(returnReponse == true){

      let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      PapiroConsole.log("=== this.state.item ===")
      PapiroConsole.log(this.state.item)

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if(key.toString().toLowerCase().indexOf('additionalcalledcostitemid') > -1 || key.toString().toLowerCase().indexOf('additionalcalledcostitemtypeid') > -1 || key.toString().toLowerCase().indexOf('additionalcalledcostitemreasonid') > -1) {
          if (typeof this.state.item[key].id === 'undefined')
            data.append(key, this.state.item[key]);
          else
            data.append(key, this.state.item[key].id);
        } else if(key.toString().toLowerCase().indexOf('calledid')) {
          PapiroConsole.log(`2 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        } else if(key.toString().toLowerCase().indexOf('id')) {
          PapiroConsole.log(`3 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        } else if(key.toString().toLowerCase().indexOf('name')) {
          PapiroConsole.log(`4 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        } else if(key.toString().toLowerCase().indexOf('value')) {
          PapiroConsole.log(`5 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        } else if(key.toString().toLowerCase().indexOf('updateduser')) {
          PapiroConsole.log(`6 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        } else if(key.toString().toLowerCase().indexOf('observation')) {
          PapiroConsole.log(`7 ${key} => ${this.state.item[key]}`)
          data.append(key, this.state.item[key]);
        }
      }
      
      PapiroConsole.log("=== FormData ===")
      for (var pair of data.entries()) {
        PapiroConsole.log(pair[0]+ ', ' + pair[1]); 
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/additionalcalledcosts', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
        });

    }

    
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true });

    if(stateName == "dateStart") {
      
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]:  moment(value).format('YYYY/MM/DD ')
        }
      }));
    }
    else if (stateName == "AdditionalCalledCostItemId") {
      //await this.getAllActiveTypesByItemId(value.id)
      //await this.getAllActiveReasonsByItemId(value.id)

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
        }
      })); 
    }
    else if (stateName == "AdditionalCalledCostItemTypeId") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
        }
      })); 
    }
    else if (stateName == "AdditionalCalledCostItemReasonId") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
          name: value && value.name ? value.name : prevState.item.name
        }
      })); 
    }
    else {
      if(stateName == "UserId" ) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            ["User"]: value
          }
        })); 
      } else if(stateName == "ActivityId" ) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            ["Activity"]: value
            
          }
        }));
      }
    }
 
    this.setState({ loading: false });

  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  getAllItemsActive = async () => {
    const { calleds } = this.props

    PapiroConsole.log("=== calleds ===")
    PapiroConsole.log(calleds)

    var result = await ItemService.getActiveByOrganizationId(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostItemList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getAllActiveTypesByItemId = async () => {
    const { calleds } = this.props

    PapiroConsole.log("=== getAllActiveTypesByItemId ===")
    PapiroConsole.log(calleds)

    var result = await TypeService.getActiveByOrganizationIdWithoutIncludes(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostTypeList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getAllActiveReasonsByItemId = async () => {
    const { calleds } = this.props

    var result = await ReasonService.getActiveByOrganizationIdWithoutIncludes(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostReasonList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  deleteDocument = async (id) => {
    const intl = this.props.intl;
    var result = await deleteAttachmentItem(id)
    if (result.success) {
      let newArr = this.state.item.additionalcalledcostattachments.filter(function(item) {
        return item.attachment.id !== id
      });
      this.setState(prevState => ({
        loading: false,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.deleted.attachment"}),
        item: {
          ...prevState.item,
          additionalcalledcostattachments: newArr,
        }
      }));
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getCalledFile = (fileName) => {
    let fileNameNew = fileName.replace("\/wwwroot\/", "")
    window.open(`${Api.apiurlbase()}${fileNameNew}`);
  }

  useStyles = () => {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, activitys } = this.props;
    const { item } = this.state;
    const classes2 = this.useStyles();
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <AccountBalanceWalletIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />} {<FormattedMessage id="additional.called.costs" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "called" })}
                      disabled
                      variant="outlined"
                      name="calledName"
                      value={item.calledName}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.item" })}
                      options={this.state.AdditionalCalledCostItemList}
                      stateName='AdditionalCalledCostItemId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.type" })}
                      options={this.state.AdditionalCalledCostTypeList}
                      stateName='AdditionalCalledCostItemTypeId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemTypeId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.reason" })}
                      options={this.state.AdditionalCalledCostReasonList}
                      stateName='AdditionalCalledCostItemReasonId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemReasonId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name }
                      required />
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <TextField
                      label={intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={item.value}
                      onChange={this.handleInputChange}
                      name="value"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      InputLabelProps={{ shrink: true }}
                      autoComplete='fname'
                      required />  
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="observation"
                      autoComplete="fname"
                      label={intl.formatMessage({ id: "observation" })}
                      multiline
                      rows="4"
                      fullWidth
                      value={item.observation}
                      variant="outlined"
                      onChange={(e) => this.handleInputChange(e)}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                      <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}} >
                        <Dropzone onDrop={acceptedFiles => { this.setState({ files: [...acceptedFiles, ...this.state.files] })}}>
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <AttachFileIcon  />
                              <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                  <FormattedMessage  id="drag.and.drop.files" />
                              </Typography>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.files.map(file => {
                          return (
                            <div className={classes.dropzoneItem} key={file.name}>
                              {file.name}
                              <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  </Grid>
                {this.state.item.additionalcalledcostattachments && this.state.item.additionalcalledcostattachments.length > 0 && (
                    <Grid item xs={12} style={{marginTop: "50px"}}>
                      <div className={classes.attachmentsBox}>
                        <label>{intl.formatMessage({id:"attachments"})}</label>
                        <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                          <ul>
                            {this.state.item.additionalcalledcostattachments.map((item) => {
                              return <li key={item.attachment.path}>
                              <AttachFile /> <Button onClick={() => this.getFile(item.attachment)}>{item.attachment.description}</Button>
                              <DeleteForeverIcon onClick={ () => this.deleteDocument(item.attachment.id) } style={{ cursor: "pointer" }}/>
                            </li>
                            })}
                          </ul>
                        </ImageList>
                      </div>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
AdditionalCalledCostEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  additionalcalledcosts: state.additionalcalledcosts,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(AdditionalCalledCostEdit)));