import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../styles/material-styles'
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'
import SimpleAutoCompleteByName from '../../../components/auto-complete/autocomplete-by-name'
import { getAllSelectionModelByOrganizationId, getAllSelectionModelByParentOrganizationCategoryId, getAllSelectionModelRootCategoriesByOrganizationId, getDepartamentsByOrganizationCategoryIdNew, getCategoryParentByChildrenOrganizationCategoryId as getAllCategoryParentByChildrenOrganizationCategoryId } from '../../../services/organizationcategory.service'
import Api from '../../../services/api'
import moment from 'moment'
import { getTechinicalTeamsByOrganizationIdAndOrganizationCategoryId, getTechnicalUsers, getTechnicalUsersByTechnicalTeamIdAndRoleType, getOrganizations as getAllOrganizations, getUserAddresses, getOrganizationAddresses, changeCalledOrganization } from '../../../services/called.service'
import { getParentOrganizationDepartaments } from '../../../services/organizationdepartament.service'
import { PapiroConsole } from '../../../utils/papiroConsole'
import { getAllOrganizationsToEditCalledOrganization } from '../../../services/organization.service'
import { ExpandMore } from '@mui/icons-material'
import SimpleAutoCompleteSearch from '../../../components/auto-complete/autocomplete-search-api'

function CalledEdit(props) {
  PapiroConsole.log("=== CalledEdit ===")
  const { intl, classes } = props
  const levelCategoriesInitialState = { rootCategoriesList: [] }
  const defaultvalue = { id: 0, name: '' }

  const [isAdmOrganization, setIsAdmOrganization] = useState(false)

  const [organizationsList, setOrganizationsList] = useState(props.calledData != null  && props.calledData.organization != null ? [props.calledData.organization] : [])
  const [organizationCategoriesList, setOrganizationCategoriesList] = useState(props.calledData != null  && props.calledData.organizationcategory != null ? [props.calledData.organizationcategory] : [])
  const [techinicalTeamsList, setTechinicalTeamsList] = useState(props.calledData != null  && props.calledData.techinicalteam != null ? [props.calledData.techinicalteam] : [])
  const [assignedUsersList, setAssignedUsersList] = useState(props.calledData != null  && props.calledData.assigneduser != null ? [props.calledData.assigneduser] : [])
  const [departamentList, setDepartamentList] = useState(props.calledData != null  && props.calledData.organizationdepartament != null ? [props.calledData.organizationdepartament] : [])
  const [addressesList, setAddressesList] = useState(props.calledData.address != null && props.calledData.addresstype === 1 ? [ props.calledData.address ] : [])
  const [userAddressesList, setUserAddressesList] = useState(props.calledData.address != null && props.calledData.addresstype === 2 ? [ props.calledData.address ] : [])

  const [canSelectOrganizationCategoriesByLevels, setCanSelectOrganizationCategoriesByLevels] = useState(false)
  const [levelCategories, setLevelCategories] = useState(levelCategoriesInitialState)
  const [categoryChildrens, setCategoryChildrens] = useState([ defaultvalue ])
  const [categoryLists, setCategoryLists] = useState([])

  const [organization, setOrganization] = useState(props.calledData != null  && props.calledData.organization != null ? props.calledData.organization : null)
  const [organizationCategory, setOrganizationCategory] = useState(props.calledData != null  && props.calledData.organizationcategory != null ? props.calledData.organizationcategory : null)
  const [organizationCategoryId, setOrganizationCategoryId] = useState(null)
  const [changeCalledStatusToNew, setChangeCalledStatusToNew] = useState(false)
  const [techinicalTeam, setTechinicalTeam] = useState(props.calledData != null  && props.calledData.techinicalteam != null ? props.calledData.techinicalteam : null)
  const [assignedUser, setAssignedUser] = useState(props.calledData != null  && props.calledData.assigneduser != null ? props.calledData.assigneduser : null)
  const [loadingAssignedUsers, setLoadingAssignedUsers] = useState(false)
  const [departament, setDepartament] = useState(props.calledData != null  && props.calledData.organizationdepartament != null ? props.calledData.organizationdepartament : null)
  const [addressType, setAddressType] = useState(props.calledData != null && props.calledData.addresstype != null ? `${props.calledData.addresstype}` : "1")
  const [address, setAddress] = useState(props.calledData != null && props.calledData.address != null ? props.calledData.address : null)
  const [reason, setReason] = useState("")

  const [addressId, setAddressNameId] = useState(props.calledData.address != null && props.calledData.address.id != null && props.calledData.addresstype === 3 ? props.calledData.address.id : 0)
  const [addressName, setAddressName] = useState(props.calledData.address != null && props.calledData.address.name != null && props.calledData.addresstype === 3 ? props.calledData.address.name : "")
  const [addressZipCode, setAddressZipCode] = useState(props.calledData.address != null && props.calledData.address.zipcode != null && props.calledData.addresstype === 3 ? props.calledData.address.zipcode : "")
  const [addressStreet, setAddressStreet] = useState(props.calledData.address != null && props.calledData.address.street != null && props.calledData.addresstype === 3 ? props.calledData.address.street : "")
  const [addressNumber, setAddressNumber] = useState(props.calledData.address != null && props.calledData.address.number != null && props.calledData.addresstype === 3 ? props.calledData.address.number : "")
  const [addressComplement, setAddressComplement] = useState(props.calledData.address != null && props.calledData.address.complement != null && props.calledData.addresstype === 3 ? props.calledData.address.complement : "")
  const [addressNeighborhood, setAddressNeighborhood] = useState(props.calledData.address != null && props.calledData.address.neighborhood != null && props.calledData.addresstype === 3 ? props.calledData.address.neighborhood : "")
  const [addressCity, setAddressCity] = useState(props.calledData.address != null && props.calledData.address.city != null && props.calledData.addresstype === 3 ? props.calledData.address.city : "")
  const [addressState, setAddressState] = useState(props.calledData.address != null && props.calledData.address.state != null && props.calledData.addresstype === 3 ? props.calledData.address.state : "")
  const [addressCountry, setAddressCountry] = useState(props.calledData.address != null && props.calledData.address.country != null && props.calledData.addresstype === 3 ? props.calledData.address.country : "")
  const [addressLongitude, setAddressLongitude] = useState(props.calledData.address != null && props.calledData.address.longitude != null && props.calledData.addresstype === 3 ? props.calledData.address.longitude : "")
  const [addressLatitude, setAddressLatitude] = useState(props.calledData.address != null && props.calledData.address.latitude != null && props.calledData.addresstype === 3 ? props.calledData.address.latitude : "")

  const handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    setChangeCalledStatusToNew(checked)
  }

  const changeValues = async (stateName, value, text = '') => {
    if (stateName === 'organization') {
      setOrganization(value)
      setOrganizationCategory(null)
      if(value && value.id && value.id > 0) {
        await getOrganizationData(value)
      } else {
        setCanSelectOrganizationCategoriesByLevels(false)
        setLevelCategories(levelCategoriesInitialState)
        setCategoryChildrens([ defaultvalue ])
        setCategoryLists([])
        setOrganizationCategory(null)
        setDepartament(null)
        setAddress(null)
        setAddressType("1")
        setTechinicalTeam(null)
        setAssignedUser(null)

        setAssignedUsersList([])
        setAddressesList([])
        setUserAddressesList([])
        setTechinicalTeamsList([])
        setDepartamentList([])
        setOrganizationCategoriesList([])

        resetCustomAddressData()
      }
    } else if (stateName === 'organizationCategory') {
      setOrganizationCategory(value)
      setOrganizationCategoryId(value)
      if (value != null && value.id != null && value.id > 0 && organization != null && organization.id != null && organization.id > 0)
        await getTechnicalTeams(organization.id, value.id)

      if (value == null || (value != null && value.id != null && value.id == 0)) {
        setTechinicalTeam(null)
        setTechinicalTeamsList([])
        setAssignedUser(null)
        setAssignedUsersList(null)
      }
    } else if (stateName === 'departament') {
      setDepartament(value)
    } else if (stateName === 'address') {
      setAddress(value)
    } else if (stateName === 'techinicalTeam') {
      setTechinicalTeam(value)

      if (value == null || (value != null && value.id != null && value.id == 0)) {
        setAssignedUser(null)
        setAssignedUsersList([])
      }
    } else if (stateName === 'assignedUser') {
      setAssignedUser(value)
    }
  }

  const resetCustomAddressData = _ => {
    setAddressNameId(0)
    setAddressName("")
    setAddressZipCode("")
    setAddressStreet("")
    setAddressNumber("")
    setAddressComplement("")
    setAddressNeighborhood("")
    setAddressCity("")
    setAddressState("")
    setAddressCountry("")
    setAddressLongitude("")
    setAddressLatitude("")
  }

  const getCep = async (cepNumber) => {
    return new Promise(function(resolve, reject) {
      fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
        return response.json()
      }).then(function (data) {
        resolve(data)
      })["catch"](function (err) {
        reject(err)
      })["finally"](function (data) {
        return data
      })
    })
  }

  const handleInputChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == 'reason')
      setReason(value)
  }

  const handleInputChangeAddress = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == 'addressName') {
      setAddressName(value)
    } else if (name == 'addressZipCode') {
      if(e.target.value === '' || /^\d+$/.test(e.target.value)) { 
        setAddressZipCode(value)
        if(value.length == 8) {
          props.setLoading(true)
          let data = await getCep(value);
          setAddressStreet(data.logradouro)
          setAddressComplement(data.complemento)
          setAddressNeighborhood(data.bairro)
          setAddressCity(data.localidade)
          setAddressState(data.uf)
          setAddressCountry('BRASIL')
          props.setLoading(false)
        }
      } else {
        return false
      }
    } else if (name == 'addressStreet') {
      setAddressStreet(value)
    } else if (name == 'addressNumber') {
      setAddressNumber(value)
    } else if (name == 'addressComplement') {
      setAddressComplement(value)
    } else if (name == 'addressNeighborhood') {
      setAddressNeighborhood(value)
    } else if (name == 'addressCity') {
      setAddressCity(value)
    } else if (name == 'addressState') {
      setAddressState(value)
    } else if (name == 'addressCountry') {
      setAddressCountry(value)
    } else if (name == 'addressLongitude') {
      setAddressLongitude(value)
    } else if (name == 'addressLatitude') {
      setAddressLatitude(value)
    }
  }
  
  const handleChange = async (e) => {
    let value = e.target.value
    setAddressType(value)

    if (value && value === "2" && userAddressesList.length == 0)
      props.setMessage(true, 'error', intl.formatMessage({id:"user.no.registered.addresses"}))
  }

  const getOrganizationData = async (value, initial = false) => {
    props.setLoading(true)
    if (!initial) {
      setLevelCategories(levelCategoriesInitialState)
      setCategoryChildrens([ defaultvalue ])
      setCategoryLists([])
      setOrganizationCategory(null)
      setOrganizationCategoryId(null)
      setDepartament(null)
      setAddress(null)
      setAddressType("1")
      setTechinicalTeam(null)
      setAssignedUser(null)

      setAssignedUsersList([])
      setAddressesList([])
      setUserAddressesList([])
      setTechinicalTeamsList([])
      setDepartamentList([])
      setOrganizationCategoriesList([])

      resetCustomAddressData()
    }

    // Recuperar categorias da organização
    if (value.organizationsettings != null && value.organizationsettings.canselectorganizationcategoriesbylevels != null && value.organizationsettings.canselectorganizationcategoriesbylevels) {
      setCanSelectOrganizationCategoriesByLevels(true)
      await getRootCategoriesByOrganization(value.id)
    } else {
      setCanSelectOrganizationCategoriesByLevels(false)
      await getCategoriesByOrganization(value.id)
    }
    // Recuperar departamentos da organização
    await getOrganizationDepartaments(value.id)
    // Recuperar endereços da organização
    await getOrganizationAddressesData(value.id)
    // Recuperar times da organização

    // Recuperar técnicos da organização

    props.setLoading(false)
  }

  const handleChangeCategoryChildrens = async (i, stateName, value) => {
    props.setLoading(true)
    PapiroConsole.log("=== handleChangeCategoryChildrens ===")
    setOrganizationCategory(value)
    
    PapiroConsole.log(categoryChildrens)
    PapiroConsole.log(i)

    let categoryChildrensData = [...categoryChildrens]
    let categoryListsData = [...categoryLists]

    categoryChildrensData[i] = value

    PapiroConsole.log("=== handleChangeCategoryChildrens -> 2 ===")
    PapiroConsole.log(categoryChildrensData)

    setCategoryChildrens(categoryChildrensData)

    if (i < categoryChildrensData.length -1 ) {
      let removeData = await handleRemoveChildrenCategory(i, categoryChildrensData, categoryListsData)
      categoryChildrensData = removeData.categoryChildrensData
      categoryListsData = removeData.categoryListsData
    }
    
    if(value && value.id && value.id > 0) {
      await getCategoryChildrenByParentOrganizationCategoryId(value.id, categoryChildrensData, categoryListsData)
      if(value.cancreatecalled === true) {
        await categoryCanCreateCalled(value)
      }
    } else {
      if (categoryChildrensData[i - 1] != null && categoryChildrensData[i - 1].id != null && categoryChildrensData[i - 1].id > 0 && categoryChildrensData[i - 1].cancreatecalled != null && categoryChildrensData[i - 1].cancreatecalled) {
        setOrganizationCategory(categoryChildrensData[i - 1])
        setOrganizationCategoryId(categoryChildrensData[i - 1])
      } else {
        setTechinicalTeam(null)
        setTechinicalTeamsList([])
        setAssignedUser(null)
        setAssignedUsersList(null)
      }
    }
    props.setLoading(false)
  }

  const getOrganizationAddressesData = async (organizationId) => {
    let result = await getOrganizationAddresses(organizationId)
    if (result.success) {
      if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1)
        props.setMessage(true, 'error', intl.formatMessage({ id:"called.accompaniment.notification.organization.no.registered.address" }))

      setAddressesList(result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data)
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const handleRemoveChildrenCategory = async (i, categoryChildrensData, categoryListsData) => {
    props.setLoading(true)

    PapiroConsole.log("=== handleRemoveChildrenCategory ===")
    PapiroConsole.log(categoryChildrens)
    PapiroConsole.log(categoryLists)

    categoryChildrensData.splice(i+1)
    categoryListsData.splice(i+1)

    PapiroConsole.log("=== -> ===")
    PapiroConsole.log(categoryChildrensData)
    PapiroConsole.log(categoryListsData)

    await getPreviousCategory(i)

    setCategoryChildrens(categoryChildrensData)
    setCategoryLists(categoryListsData)
    props.setLoading(false)

    return {
      categoryChildrensData: categoryChildrensData,
      categoryListsData: categoryListsData
    }
  }

  const getPreviousCategory = async (i) => {
    PapiroConsole.log("=== getPreviousCategory ===")
    if(i > 0) {
      PapiroConsole.log("=== 1.0 ===")
      if (categoryChildrens[i-1] !== undefined) {
        PapiroConsole.log("=== 1.1.0 ===")
        if(categoryChildrens[i-1].cancreatecalled === true) {
          PapiroConsole.log("=== 1.1.1 ===")
          setOrganizationCategoryId(categoryChildrens[i-1])
          setOrganizationCategory(categoryChildrens[i-1])
          await categoryCanCreateCalled(categoryChildrens[i-1])
        } else {
          PapiroConsole.log("=== 1.1.2 ===")
          await getPreviousCategory(i-1)
        }
      }
    } else {
      PapiroConsole.log("=== 2.0 ===")
      if (categoryChildrens[i] !== undefined && categoryChildrens[i].cancreatecalled === true) {
        PapiroConsole.log("=== 2.1 ===")
        setOrganizationCategoryId(categoryChildrens[i])
        setOrganizationCategory(categoryChildrens[i])
      } else {
        PapiroConsole.log("=== 2.2 ===")
        setOrganizationCategoryId(null)
        setOrganizationCategory(null)
      }
    }
  }

  const getCategoryChildrenByParentOrganizationCategoryId = async (parentId, categoryChildrensData = [], categoryListData = []) => {
    PapiroConsole.log("=== getCategoryChildrenByParentOrganizationCategoryId ===")
    PapiroConsole.log(categoryChildrensData)
    var result = await getAllSelectionModelByParentOrganizationCategoryId(parentId)
    if (result && result.success) {
      if(result.data && result.data.length > 0) {
        handleAddChildrenCategory(result.data, categoryChildrensData, categoryListData)
      }
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const categoryCanCreateCalled = async (value) => {
    setOrganizationCategoryId(value)
    if (organization && organization.id && organization.id > 0) {
      setTechinicalTeam(null)
      setTechinicalTeamsList([])
      setAssignedUser(null)
      setAssignedUsersList([])
      await getTechnicalTeams(organization.id, value.id)
      await getDepartamentsByOrganizationCategoryId(value.id)
      if (techinicalTeam && techinicalTeam.id && techinicalTeam.id > 0)
        await getTechnicalUsersByTechnicalTeam(techinicalTeam.id)
    }
  }

  const getTechnicalUsersByTechnicalTeam = async (technicalTeamId) => {
    if (technicalTeamId && technicalTeamId > 0) {
      props.setLoading(true)
      let result = await getTechnicalUsersByTechnicalTeamIdAndRoleType(technicalTeamId, 2);
      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = new Array()
          result.data.map(item => {
              if (item && item.user != null) {
                let user = item.user
                user.name = `${user.name} (${user.email})`
                reordernewarrayuserteclist.push(user)
              }
          })
          setAssignedUsersList(reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist)
          props.setLoading(false)
        } else {
          props.setMessage(true, "info", intl.formatMessage({id:"called.registration.category.missing.team"}))
          props.setLoading(false)
        }
      } else {
        props.setMessage(true, "error", result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        props.setLoading(false)
        Api.kickoff(result)

      }
    }
  }

  const getRootCategoriesByOrganization = async (organizationId) => {
    let result = await getAllSelectionModelRootCategoriesByOrganizationId(organizationId);
    if (result.success) {
      if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1)
        props.setMessage(true, 'error', intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}))
      setLevelCategories({
        rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
      })
      setCategoryLists([
        result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
      ])
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const getCategoriesByOrganization = async (organizationId) => {
    let result = await getAllSelectionModelByOrganizationId(organizationId);
    if (result.success) {
      if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1)
        props.setMessage(true, 'error', intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}))
      setOrganizationCategoriesList(result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data)
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const getTechnicalTeams = async (organizationId, organizationCategoryId) => {
    if (organizationCategoryId && organizationCategoryId > 0) {
      let result = await getTechinicalTeamsByOrganizationIdAndOrganizationCategoryId(organizationId, organizationCategoryId);
      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = result.data && result.data.length > 0 ? result.data : new Array()
          setTechinicalTeamsList(reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist)
        } else {
          getTechnicalUsers(organizationCategoryId, organizationId)
        }
      } else {
        props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        props.setLoading(false)
        Api.kickoff(result)
      }
    }
  }

  const getTechnicalUsers = async (categoryId, organizationId) => {
    if (categoryId && categoryId > 0) {
      props.setLoading(true)
      let result = await getTechnicalUsers(categoryId, organizationId)
      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = new Array()
          result.data.map(item => {
              item.name =  item.name + " ("+item.email+")";
              reordernewarrayuserteclist.push(item);
          })
          props.setLoading(false)
          setAssignedUsersList(reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist)
        } else {
          props.setMessage(true, 'info', intl.formatMessage({id:"called.registration.category.missing.team"}))
          props.setLoading(false)
        }
      } else {
        props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        props.setLoading(false)
        Api.kickoff(result)

      }
    }
  }

  const getDepartamentsByOrganizationCategoryId = async (id) => {
    props.setLoading(true)
    var result = await getDepartamentsByOrganizationCategoryIdNew(id);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        setDepartamentList(result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data)
        props.setLoading(false)
      } else {
        getOrganizationDepartaments(organization.id)
      }
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const getOrganizationDepartaments = async (id) => {
    let result = await getParentOrganizationDepartaments(id)
    if (result.success) {
      setDepartamentList(result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)): result.data)
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const getCategoryParentByChildrenOrganizationCategoryId = async (childrenId, previousChildren = [], previousList = [], first = false) => {
    var result = await getAllCategoryParentByChildrenOrganizationCategoryId(childrenId);
    if (result && result.success) {
      PapiroConsole.log("=== getCategoryParentByChildrenOrganizationCategoryId success ===")
      PapiroConsole.log(result)
      if(result.data && result.data.listitems && result.data.listitems.length > 0) {
        if(result.data && result.data.organizationcategory && result.data.organizationcategory.id) {
          if(childrenId+'' === result.data.organizationcategory.parentorganizationcategoryid+''){
            props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "error.same.category.and.parent" }))
            return false;
          }
          let handleReverseChildren = await handleAddReverseChildrenCategory(result.data.listitems, first, previousChildren, previousList, result.data.organizationcategory)
          await getCategoryParentByChildrenOrganizationCategoryId(result.data.organizationcategory.parentorganizationcategoryid, handleReverseChildren.childrensList, handleReverseChildren.categoryList)
        } else {
          let parentIdFilter = result.data.listitems.filter(item => item.id == childrenId)
          if(parentIdFilter && parentIdFilter.length > 0) {
            if(childrenId+'' === parentIdFilter[0].parentorganizationcategoryid+''){
              props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "error.same.category.and.parent" }))
              return false
            }
            let handleReverseChildren = await handleAddReverseChildrenCategory(result.data.listitems, first, previousChildren, previousList, parentIdFilter[0])
            await getCategoryParentByChildrenOrganizationCategoryId(parentIdFilter[0].parentorganizationcategoryid, handleReverseChildren.childrensList, handleReverseChildren.categoryList)
          } else {
            let handleReverseChildren = await handleAddReverseChildrenCategory(result.data.listitems, first, previousChildren, previousList)
          }
        }
      }
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const handleAddChildrenCategory = (listCategory, categoryChildrensList = [], categoryListLists = []) => {
    let categoryChildrensData = categoryChildrensList && categoryChildrensList.length > 0 ? categoryChildrensList : categoryChildrens
    let categoryListsData = categoryListLists && categoryListLists.length > 0 ? categoryListLists : categoryLists
    PapiroConsole.log("=== handleAddChildrenCategory ===")
    PapiroConsole.log(categoryChildrensData)
    PapiroConsole.log(categoryListsData)
    setCategoryChildrens([ ...categoryChildrensData, { id: 0, name: '' } ])
    setCategoryLists([ ...categoryListsData, listCategory ])
  }

  const handleAddReverseChildrenCategory = async (listCategory, first, previousChildrens = [], previousCategories = [], category = null) => {
    if(first) {
      setCategoryChildrens([ category != null ? category :{ id: 0, name: '' } ])
      setCategoryLists([ listCategory ])
      if(category && category.cancreatecalled != null && category.cancreatecalled === true) {
        setOrganizationCategoryId(category)
        setOrganizationCategory(category)
      }
      return {
        childrensList: [ category != null ? category :{ id: 0, name: '' } ],
        categoryList: [ listCategory ]
      }
    } else {
      let categoryChildrenData = previousChildrens && previousChildrens.length > 0 ? previousChildrens : categoryChildrens
      let categoryListData = previousCategories && previousCategories.length > 0 ? previousCategories : categoryLists
      setCategoryChildrens([ category != null ? category : { id: 0, name: '' }, ...categoryChildrenData ])
      setCategoryLists([ listCategory, ...categoryListData ])
      return {
        childrensList: [ category != null ? category : { id: 0, name: '' }, ...categoryChildrenData ],
        categoryList: [ listCategory, ...categoryListData ]
      }
    }
  }

  const getOrganizations = async (isLoading = false) => {
    const { userSession } = props
    if (isLoading)
      props.setLoading(true)

    let orgData = []
    let result = await getAllOrganizationsToEditCalledOrganization()
    if (result.success) {
      orgData = result.data
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }

    if(orgData != null) {
      orgData= orgData.filter(c => c.active == true)
      setOrganizationsList(orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData)
    } else {
      orgData = []
      setOrganizationsList(orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData)
    }

    if (props.calledData && props.calledData.organization) {
      getOrganizationData(props.calledData.organization, true)
      PapiroConsole.log("=== getCategoryParentByChildrenOrganizationCategoryId ===")
      if (props.calledData && props.calledData.organizationcategory)
        getCategoryParentByChildrenOrganizationCategoryId(props.calledData.organizationcategory.id, [], [], true)
      if (props.calledData && props.calledData.requestinguser)
        getUserAddressesData(props.calledData.requestinguser.id)
    }
  }

  const getUserAddressesData = async (userId) => {
    let result = await getUserAddresses(userId);
    if (result.success) {
      if(result.data && result.data.length == 1)
        setAddress(result.data[0])
      setUserAddressesList(result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data)
    } else {
      props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      props.setLoading(false)
      Api.kickoff(result)
    }
  }

  const validateForm = _ => {
    let success = true

    if (organization == null || (organization != null && organization.id != null && organization.id == 0)) {
      success = false
      props.setMessage(true, 'error', 'O campo "Organização" é obrigatório')
    } else if (organizationCategory == null || (organizationCategory != null && organizationCategory.id != null && organizationCategory.id == 0)) {
      success = false
      props.setMessage(true, 'error', 'O campo "Categoria" é obrigatório')
    } else if ((addressType === "1" || addressType === "2") && (address == null || (address != null && address.id != null && address.id == 0))) {
      success = false
      props.setMessage(true, 'error', 'O campo "Endereço" é obrigatório')
    } else if (addressType === "3" && ((addressId == null || (addressId != null && addressId == 0)) && (addressName == null || (addressName != null && addressName.length == 0)))) {
      success = false
      props.setMessage(true, 'error', 'O campo "Endereço" é obrigatório')
    } else if ((props.calledData != null && props.calledData.techinicalTeam != null) && (techinicalTeam == null || (techinicalTeam != null && techinicalTeam.id != null && techinicalTeam.id == 0))) {
      success = false
      props.setMessage(true, 'error', 'O campo "Atuibuído ao Time" é obrigatório')
    } else if ((props.calledData != null && props.calledData.assigneduser != null && !changeCalledStatusToNew) && (assignedUser == null || (assignedUser != null && assignedUser.id != null && assignedUser.id == 0))) {
      success = false
      props.setMessage(true, 'error', 'O campo "Atribupido para" é obrigatório')
    } else if (reason == null || (reason != null && reason.length == 0)) {
      success = false
      props.setMessage(true, 'error', 'O campo "Motivo" é obrigatório')
    }

    return success
  }

  const handleSubmit = async e => {
    e.preventDefault()

    props.setLoading(true)
    if (validateForm()) {
      let data = new FormData()
      data.append("CalledId", props.calledData.id)
      data.append("CreatedUser", props.userSession.user.id)
      data.append("CreatedAt", moment().format("YYYY/MM/DD HH:mm:ss"))
      data.append("PreviousOrganizationId", props.calledData.organization.id)
      data.append("PreviousOrganizationCategoryId", props.calledData.organizationcategory.id)
      data.append("PreviousAddressId", props.calledData.address.id)
      if (props.calledData.techinicalteam != null)
        data.append("PreviousTechinicalTeamId", props.calledData.techinicalteam.id)
      if (props.calledData.assigneduser != null)
        data.append("PreviousAssignedUserId", props.calledData.assigneduser.id)
      if (props.calledData.organizationdepartament != null)
        data.append("PreviousOrganizationDepartamentId", props.calledData.organizationdepartament.id)
      data.append("NextOrganizationId", organization.id)
      data.append("NextOrganizationCategoryId", organizationCategory.id)
      data.append("AddressType", addressType)
      if (addressType === "1" || addressType === "2")
        data.append("NextAddressId", address.id)
      else {
        data.append("NextAddressId", addressId)
        data.append("AddressName", addressName)
        data.append("AddressZipCode", addressZipCode)
        data.append("AddressStreet", addressStreet)
        data.append("AddressNumber", addressNumber)
        data.append("AddressComplement", addressComplement)
        data.append("AddressNeighborhood", addressNeighborhood)
        data.append("AddressCity", addressCity)
        data.append("AddressState", addressState)
        data.append("AddressCountry", addressCountry)
        data.append("AddressLongitude", addressLongitude)
        data.append("AddressLatitude", addressLatitude)
      }
      if (techinicalTeam != null && techinicalTeam.id != null && techinicalTeam.id > 0)
        data.append("NextTechinicalTeamId", techinicalTeam.id)
      if (assignedUser != null && assignedUser.id != null && assignedUser.id > 0)
        data.append("NextAssignedUserId", assignedUser.id)
      if (departament != null && departament.id != null && departament.id > 0)
        data.append("NextOrganizationDepartamentId", departament.id)
      data.append("Reason", reason)
      data.append("ChangeCalledStatusToNew", changeCalledStatusToNew)
      
      let result = await changeCalledOrganization(data)
      if (result && result.success) {
        props.setMessage(true, 'success', 'Chamado atualizado com sucesso! Você será redirecionado em segundos')
        props.setLoading(false)

        setTimeout(function(){
          props.history.push({
            pathname: `/${props.match.params.lang}/called-accompaniment/${props.calledData.id}`,
          })
        }, 5000);
      } else {
        props.setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        props.setLoading(false)
        Api.kickoff(result)
      }
    } else
      props.setLoading(false)
  }

  useEffect(() => {
    getOrganizations(true)
  }, [])

    return (
      <Paper elevation={3} sx={{ width: '100%', minHeight: 500, paddingBottom: '25px' }}>
        <Grid container spacing={2} sx={{ marginTop: '16px!important', paddingLeft: '16px!important', paddingRight: '16px!important' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}>Informações de alteração</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "organization" })}
              options={organizationsList}
              stateName="organization"
              changeSelect={changeValues}
              selected={organization}
              isRequired
              required />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "departament" })}
              options={departamentList}
              stateName='departament'
              changeSelect={changeValues}
              selected={departament}
              disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) } />
          </Grid>
          {canSelectOrganizationCategoriesByLevels === false ? (<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: "category" })}
                options={organizationCategoriesList}
                stateName='organizationCategory'
                changeSelect={changeValues}
                selected={organizationCategory}
                disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }
                required />
          </Grid>)
          :
          (
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8} >
            <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
              <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
              <Grid container spacing={2} id="categoriesByLevels" className="categoriesByLevels">
                {categoryChildrens != null && categoryChildrens.map((el, i) =>
                  <Grid key={i} item xs={12} sm={12} md={6} lg={4} xl={4}>
                    { i > 0 ?
                    (<Grid container spacing={4}>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
                      <Grid item xs={10} sm={10} md={12} lg={10} xl={10}>
                        <SimpleAutoCompleteByName
                          intl={intl}
                          label=""
                          options={categoryLists[i] || []}
                          stateName={`lcat-${i}`}
                          name={`lcat-${i}`}
                          changeSelect={(stateName,value) => handleChangeCategoryChildrens(i, stateName, value)}
                          selected={el}
                          disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }
                          />
                      </Grid>
                    </Grid>)
                    :
                    (
                      <SimpleAutoCompleteByName
                        intl={intl}
                        label=""
                        options={categoryLists[i] || []}
                        stateName={`lcat-${i}`}
                        name={`lcat-${i}`}
                        changeSelect={(stateName,value) => handleChangeCategoryChildrens(i, stateName, value)}
                        selected={el}
                        disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }
                        />
                    )}
                  </Grid>
                )}
              </Grid>
            </fieldset>
          </Grid>)}
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <FormControl component="fieldset" disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }>
              <FormLabel component="legend">{intl.formatMessage({ id: "location" })}</FormLabel>
              <RadioGroup row aria-label="addresstype" name="addresstype" defaultValue="1" value={addressType} onChange={handleChange} disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }>
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={intl.formatMessage({id:"organization.location"})} />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={intl.formatMessage({id:"requester.location"})} />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label={intl.formatMessage({id:"location.new"})} />
              </RadioGroup>
            </FormControl>
          </Grid>
          {(addressType != null && addressType === "1") && (<Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "location" })}
              options={addressesList}
              stateName="address"
              changeSelect={changeValues}
              selected={address}
              disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) }
              required />
          </Grid>)}
          {(addressType != null && addressType === "2") && (<Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "location" })}
              options={userAddressesList}
              stateName="address"
              changeSelect={changeValues}
              selected={address}
              required
              disabled={ (organization == null || (organization != null && organization.id != null && organization.id == 0)) } />
          </Grid>)}
          {(addressType != null && (addressType === "1" || addressType === "2")) && (<Grid item xs={12} sm={12} md={12} lg={4} xl={6} />)}
          {(addressType != null && addressType === "3") && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header" >
                <Typography className={classes.heading}>{intl.formatMessage({id:"location.new"})}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={`${intl.formatMessage({id:"name"})}*`}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressName'
                      onChange={handleInputChangeAddress}
                      value={addressName} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                    label={intl.formatMessage({id:"cep"})}
                    autoComplete='fname'
                    variant='outlined'
                    name='addressZipCode'
                    onChange={handleInputChangeAddress}
                    value={addressZipCode} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"street"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressStreet'
                      onChange={handleInputChangeAddress}
                      value={addressStreet} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"number"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressNumber'
                      onChange={handleInputChangeAddress}
                      value={addressNumber} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"complement"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressComplement'
                      onChange={handleInputChangeAddress}
                      value={addressComplement} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"neighborhood"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressNeighborhood'
                      onChange={handleInputChangeAddress}
                      value={addressNeighborhood} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"city"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressCity'
                      onChange={handleInputChangeAddress}
                      value={addressCity} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"state"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressState'
                      onChange={handleInputChangeAddress}
                      value={addressState} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"country"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressCountry'
                      onChange={handleInputChangeAddress}
                      value={addressCountry}  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"longitude"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressLongitude'
                      onChange={handleInputChangeAddress}
                      value={addressLongitude}  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id:"latitude"})}
                      inputProps={{ maxLength: 255 }}
                      autoComplete='fname'
                      variant='outlined'
                      name='addressLatitude'
                      onChange={handleInputChangeAddress}
                      value={addressLatitude}  />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>)}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={changeCalledStatusToNew}
                      onChange={handleChangeSwitch}
                      name="changeCalledStatusToNew" />
                  }
                  label={intl.formatMessage({ id: "change.the.status.of.called.to.new" })} />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "attr.to.team" })}
              options={techinicalTeamsList}
              stateName="techinicalTeam"
              changeSelect={changeValues}
              selected={techinicalTeam}
              disabled={ ((organization == null || (organization != null && organization.id != null && organization.id == 0)) || (organizationCategory == null || (organizationCategory != null && organizationCategory.id != null && organizationCategory.id == 0))) }
              isRequired={ (props.calledData != null && props.calledData.techinicalteam != null) }
              required={ (props.calledData != null && props.calledData.techinicalteam != null) } />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <SimpleAutoCompleteSearch
              label={ intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "attr.to" })}
              options={assignedUsersList}
              stateName="assignedUser"
              changeSelect={changeValues}
              selected={assignedUser}
              loading={loadingAssignedUsers}
              urlapi={Api.apiurlbase()+'api/users/search/techinical/'+(techinicalTeam != null ? techinicalTeam.id : 0)}
              disabled={ (techinicalTeam == null || (techinicalTeam != null && techinicalTeam.id != null && techinicalTeam.id == 0)) }
              required={ (props.calledData != null && props.calledData.assigneduser != null && !changeCalledStatusToNew) } />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              name="reason"
              autoComplete="fname"
              label={intl.formatMessage({ id: "reason" })}
              multiline
              rows={6}
              fullWidth
              value={reason}
              variant="outlined"
              onChange={handleInputChange}
              disabled={ (organization != null && organization.id != null && organization.id == 0) } />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', marginBottom: '10px' }}  >
              <Grid item xs={12} sm={4} style={{ justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                  disabled={
                    (
                      (organization == null || (organization != null && organization.id != null && organization.id == 0))
                      || (organizationCategory == null || (organizationCategory != null && organizationCategory.id != null && organizationCategory.id == 0))
                      || (
                        ((addressType === "1" || addressType === "2") && (address == null || (address != null && address.id != null && address.id == 0)))
                        || (addressType === "3" && (addressName == null || (addressName != null && addressName.length == 0)))
                      )
                      || ( props.calledData != null && props.calledData.techinicalteam != null && (techinicalTeam == null || (techinicalTeam != null && techinicalTeam.id != null && techinicalTeam.id == 0)) )
                      || ( props.calledData != null && props.calledData.assigneduser != null && !changeCalledStatusToNew && (assignedUser == null || (assignedUser != null && assignedUser.id != null && assignedUser.id == 0)) )
                      || (props.calledData != null && props.calledData.organization != null && props.calledData.organization.id != null && organization != null && organization.id != null && organization.id == props.calledData.organization.id)
                      || (reason == null || (reason != null && reason.length == 0))
                    )} >
                  <FormattedMessage id="save" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    )
}

CalledEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledEdit)))