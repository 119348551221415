import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import * as componentActions from '../../../../redux/actions/calledservicesettingsorganizationfieldvalidations'
import Api from '../../../../services/api'
import { addItem, editItem } from '../../../../services/calledservicesettingsorganizationfieldvalidation.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import { getAllByOrganizationFieldIdWithoutIncludes } from '../../../../services/organizationfieldoptionsvalues.service'

function CalledServiceSettingsOrganizationFieldValidationItemDialog(props) {

  const { intl, calledServiceSettingsOrganizationFieldId, organizationField, handleSetItemList } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [organizationFieldOptionsValuesId, setOrganizationFieldOptionsValuesId] = useState(null)
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState(null)

  const [organizationFieldOptionValueList, setOrganizationFieldOptionValueList] = useState([])
  const organizationFieldList = [organizationField]
  const messageTypeList = [
    { id: 1, name: "Sucesso" },
    { id: 2, name: "Informação" },
    { id: 3, name: "Aviso" },
    { id: 4, name: "Erro" }
  ]

  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)
    if (props.open === true)
      getAllOrganizationFieldsOptionValuesByOrganizationFieldId()
  }, [props.open])

  useEffect(() => {
    if(props.calledservicesettingsorganizationfieldvalidations && props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation && props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.id != null) {
      PapiroConsole.log("=== CalledServiceSettingsOrganizationFieldValidationItemDialog ===")
      PapiroConsole.log(props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation)
      setId(props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.id)
      setOrganizationFieldOptionsValuesId(props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.organizationfieldoptionsvalues != null ? props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.organizationfieldoptionsvalues : props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.organizationfieldoptionsvaluesid)
      setMessage(props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.message)
      setMessageType(messageTypeList.find(item => item.id == props.calledservicesettingsorganizationfieldvalidations.calledservicesettingsorganizationfieldvalidation.messagetype))
    }
  }, [props.calledservicesettingsorganizationfieldvalidations])

  const setItemInitialState = _ => {
    setId(0)
    setOrganizationFieldOptionsValuesId(null)
    setMessage('')
    setMessageType(null)
    setOrganizationFieldOptionValueList([])
  }

  const close = () => {
    setItemInitialState()
    props.addCalledServiceSettingsOrganizationFieldValidation({
      id: 0,
      organizationfieldoptionsvaluesid: null,
      message: '',
      messagetype: null
    })
    setOpen(false)
    props.handleOpen(false)
  }

  const setSnackbarNotificationMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'organizationFieldOptionValueList')
      setOrganizationFieldOptionValueList(value)
  }

  const validateForm = _ => {
    if (organizationFieldOptionsValuesId == null || (organizationFieldOptionsValuesId != null && organizationFieldOptionsValuesId.id === 0)) {
      setLoading(false)
      setSnackbarNotificationMessage(true, 'error', "O campo 'Opção do campo personalizado' é obrigatório")
      return false
    } else if (message == null || (message != null && message.length === 0)) {
      setLoading(false)
      setSnackbarNotificationMessage(true, 'error', "O campo 'Mensagem' é obrigatório")
      return false
    } else if (messageType == null || (messageType != null && messageType.id === 0)) {
      setLoading(false)
      setSnackbarNotificationMessage(true, 'error', "O campo 'Tipo da mensagem' é obrigatório")
      return false
    } else if (calledServiceSettingsOrganizationFieldId == null || (calledServiceSettingsOrganizationFieldId != null && calledServiceSettingsOrganizationFieldId === 0)) {
      setLoading(false)
      setSnackbarNotificationMessage(true, 'error', "O campo 'Campo personalizado' é obrigatório")
      return false
    }

    return true
  }

  const saveItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("CalledServiceSettingsOrganizationFieldId", calledServiceSettingsOrganizationFieldId)
      data.append("OrganizationFieldOptionsValuesId", organizationFieldOptionsValuesId.id)
      data.append("Message", message)
      data.append("MessageType", messageType.id)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))
      data.append("UpdatedUser", props.userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      let response = id > 0 ? await editItem(data) : await addItem(data)
      if (response && response.success) {
        setLoading(false)
        setSnackbarNotificationMessage(true, 'success', id > 0 ? intl.formatMessage({ id: "edit.success" }) : intl.formatMessage({ id: "add.success" }))
        handleSetItemList([response.data])
        close()
      } else {
        setLoading(false)
        setSnackbarNotificationMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }

  const getAllOrganizationFieldsOptionValuesByOrganizationFieldId = async _ => {
    PapiroConsole.log("=== getAllOrganizationFieldsOptionValuesByOrganizationFieldId ===")
    if (organizationField && organizationField.id && organizationField.id > 0) {
      setLoading(true)

      let response = await getAllByOrganizationFieldIdWithoutIncludes(organizationField.id)
      if (response && response.success) {
        setLoading(false)
        if (response.data != null) {
          setOrganizationFieldOptionValueList(response.data)
          let itemFind = response.data.find(item => item.id == organizationField.id)
          if (itemFind != null && typeof itemFind !== 'undefined')
            setOrganizationFieldOptionsValuesId(itemFind)
        }
      } else {
        setLoading(false)
        setSnackbarNotificationMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }
  
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-called-service-setting-organization-field-validation-dialog-title" >
        <DialogTitle id="form-called-service-setting-organization-field-validation-dialog-title">
          { id > 0 ? "Editar " : "Adicionar " } Validação de campo personalizado
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <SimpleAutoComplete
                label="Campo personalizado*"
                options={organizationFieldList}
                stateName='organizationField'
                changeSelect={changeValues}
                disabled={true}
                selected={organizationField} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }} />
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <SimpleAutoComplete
                label="Opção do campo personalizado*"
                options={organizationFieldOptionValueList}
                stateName='organizationFieldOptionsValuesId'
                changeSelect={(stateName, value) => setOrganizationFieldOptionsValuesId(value)}
                selected={organizationFieldOptionsValuesId} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }} />
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <TextField
                name="message"
                variant="outlined"
                multiline
                rows="4"
                fullWidth
                id="message"
                label="Mensagem*"
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Mensagem que será exibida após o usuário selecionar a opção do campo personalizado</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <SimpleAutoComplete
                label="Tipo da mensagem*"
                options={messageTypeList}
                stateName='messageType'
                changeSelect={(stateName, value) => setMessageType(value)}
                selected={messageType} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={saveItem} >
            {intl.formatMessage({id:"save"})}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

CalledServiceSettingsOrganizationFieldValidationItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleSetItemList: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsOrganizationFieldId: PropTypes.number.isRequired,
  organizationField: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  calledservicesettingsorganizationfieldvalidations: state.calledservicesettingsorganizationfieldvalidations
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledServiceSettingsOrganizationFieldValidationItemDialog)))