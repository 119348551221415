export function addCurrent(organizationaccountemail) {
    return {
        type: 'ADD_CURRENT',
        organizationaccountemail,
    }
}
export function addCurrentOrganization(organizationaccountemail,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationaccountemail,
        organization,
        }
}