import { dia } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';

import Input from '../Input/Input';
import { useBaseInspector } from './useBaseInspector';

interface Props {
    cell: dia.Cell;
}

const cellProps = {
    label: ['attrs', 'label', 'text']
};

const LabelInspector = (props: Props): ReactElement => {

    const { cell } = props;

    const [label, setLabel] = useState<string>('');

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    return (
        <>
            <h1>Componente Selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.label, e.target.value)}
                       disabled={true}
                />
            </label>
        </>
    );
};

export default LabelInspector;
