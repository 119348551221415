import Api2Talk from "../api2talk";

const create = async (input) => { 
  try {
    const response = await Api2Talk.post('/messageproviderconfig', input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (input, config = {}) => { 
  try {
    const response = await Api2Talk.put('/messageproviderconfig', input, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await Api2Talk.get(`/messageproviderconfig/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  create,
  update,
  getById
}