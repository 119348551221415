import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import Steps from '@mui/icons-material/Money';
import { FormattedMessage, injectIntl } from 'react-intl';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PostAddIcon from '@mui/icons-material/PostAdd';

import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { QueuePlayNext } from '@mui/icons-material';

import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
   
    height: 40,
    width: 40
  },
  icon: {
    height: 20,
    width: 20
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceIconSucess: {
    color: 'green'
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const Budget = props => {
  const { className, total, comparation, positiveMonth, titleBudget, color } = props;

  console.log(color)
  console.log(titleBudget)

  const classes = useStyles();

  const intl = props.intl

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent >
        <Grid
          container
          justify="space-between"
        >
          <Grid item xs={11}>
            <Typography 
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {titleBudget}
            </Typography>
            <Typography variant="h3">{total} </Typography>
            
          </Grid>
          <Grid item  xs={1} >
            <Avatar className={classes.avatar} style={{backgroundColor: `${color}`,}}>

              
              
              <QueuePlayNext className={classes.icon} />


             
            </Avatar>
          </Grid>
          
        </Grid>
        
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  comparation: PropTypes.number,
  positiveMonth: PropTypes.bool
};

export default injectIntl(Budget);
