

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { AbilityContext } from '../../../config/ability-context'
import * as Service from "../../../services/user.service";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { getDepartaments } from "../../../services/called.service";
import { Overlay } from "../../../styles/global"
import CustomizedSnackbars from "../../../components/material-snackbars"
import { CircularProgress } from "@mui/material"
import Api from "../../../services/api"

import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  PieChart, Pie, Sector, Cell,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text
} 

from 'recharts';
import { injectIntl } from 'react-intl';


class CategoryTab extends Component {
    constructor(props) {
    super(props);

    this.state = {
        GroupList: [],
        DepartamentList:[],
        loading: false,
        total: 0
      }

      this.pieChart = null


    console.log(this.props)
    console.log("props aqui")
    }

componentWillReceiveProps(nextProps){

}    

async componentDidMount() {


   
    
   // await this.getOrganizationsCategories()

    await this.getCalledsByOrganizationsCategories();
}

renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

gerar_cor_hexadecimal()
{
  return '#' + parseInt((Math.random() * 0xFFFFFF))
    .toString(16)
    .padStart(6, '0');
}

getPercent(value, arraytotal)
{

  var total = 0
  arraytotal.forEach(element => {
          total += element.value
    });

    console.log(total)
    console.log("aqui")

    if(total == 0){
      return 0
    }


    return ((value * 100) / total).toFixed(2);

}

async getAllGrous() {
    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });
    console.log("grupos aqui ")
    console.log(groups)
  }

  customizedAxisTick = ({
    x, y, payload,
  }) => {
    
    return <Text x={x} y={y} width={70} style={{fill:'#808080', fontSize:'11px', }} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
  };



async getOrganizationsCategories() {
    this.setState({ loading: true });
    //var groups = await Service.getOrganizations();
    var result = await Service.getOrganizationsCategories(this.props.slacalleds);

    if (result.success) {
      this.setState({ loading: false, OrganizationCategories: result.data});
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
    
   
  }

  async getCalledsByOrganizationsCategories() {
    const intl = this.props.intl;
    const { classes } = this.props
    this.setState({ loading: true });
    
    var categories = this.props.slacalleds.categoriesresponse

    if(categories!= null){

        if(categories.length > 0 ){
          
          

          console.log(categories)
          console.log("categories aqui")
          /*var array = this.state.OrganizationCategories;

          var calleds = this.props.slacalleds

          console.log(calleds)

          var newArray = []



          

          array.forEach(element => {

              element.value = 0
              element.color = this.gerar_cor_hexadecimal()


              calleds.forEach(element2 => {
                  console.log("entrei no laço")

                  if(element.id == element2.organizationcategoryid){
                          element.value++
                  }

                  
                  
              });

              if(element.value != 0){

                //console.log(element)
                //console.log(index)
                newArray.push(element)
                //array.splice(index, 1)
              }
              
          });

          newArray.sort(function(a,b) {
            return a.value > b.value ? -1 : a.value < b.value ? 1 : 0;
        });

        */

        var arrayTable = null
        if(categories.length > 10)
        {
          arrayTable = categories.slice(0, 9)
        }
        else{
          arrayTable=[...categories]
        }

          


          this.pieChart = 
          <div>
              <ResponsiveContainer width="95%" height={400}>
                  
                  <BarChart
                      
                      margin={{ bottom: 80}}
                      data={arrayTable}

                      barSize={20}
                    >
                      <XAxis dataKey="name"  tick={<this.customizedAxisTick />}    padding={{ left: 10, right: 10 }} interval={0} />
                      <YAxis allowDecimals =  {false}  />
                      <Tooltip />
                    
                      <CartesianGrid strokeDasharray="3 3" />
                      <Bar dataKey="value" fill="#8884d8" background={{ fill: '#eee' }} />
                  </BarChart>
                  
                  
              </ResponsiveContainer>
              

        
              <Accordion style={{pointerEvents:"none"} }>
                <AccordionSummary style={{pointerEvents:"none"} } >
                  <Typography className={classes.heading}>{intl.formatMessage({id:"calleds.by.categories"})}</Typography>
                </AccordionSummary>
                                
              </Accordion>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table" >
                    <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                      <TableRow>
                            
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white'  }}>Nome da categoria</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white'  }}>{intl.formatMessage({id:"organization"})}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white'  }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white'  }}>{intl.formatMessage({id:"percentage"})}</TableCell>
                      </TableRow>
                    {
                        categories.map((entry, index) => (
                          <TableRow>
                            
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' ,backgroundColor:'#f2f2f2',  borderStyle: 'solid' }}>{entry.name}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' ,backgroundColor:'#f2f2f2',  borderStyle: 'solid' }}>{entry.organizationname}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' ,backgroundColor:'#f2f2f2',  borderStyle: 'solid' }}>{entry.value}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' ,backgroundColor:'#f2f2f2',  borderStyle: 'solid' }}>{this.getPercent(entry.value, categories)}%</TableCell>
                          
                        </TableRow>
                        ))
                    }
                      


                    </TableBody>
                  </Table>
                </TableContainer>
          </div>





          this.setState({ loading: false });
          

      }

    }

    this.setState({ loading: false });
    

  }

render() {

   
        return (
        

            <div>
           
                {this.pieChart}

                {this.state.loading && (
                <Overlay>
                    <CircularProgress color="secondary" />
                </Overlay>
                 )}

                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification} />
            </div>
            
        )
    }
}

CategoryTab.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
headerMenu: state.headerMenu,
userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CategoryTab)));
CategoryTab.contextType = AbilityContext;