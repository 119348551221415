import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';import SimpleSelect from '../../components/select/simple';

import * as Service from '../../services/patrimonymaterial.service';
import * as componentActions from '../../redux/actions/patrimonymaterials';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
class PatrimonyMaterialEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,patrimonymaterials } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!patrimonymaterials || (patrimonymaterials && !patrimonymaterials.patrimonymaterial)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      UpdatedUser: userSession.user.id,
       id: patrimonymaterials.patrimonymaterial.id,
     PatrimonyGroupId:  patrimonymaterials.patrimonymaterial.PatrimonyGroupId,
     PatrimonyGroupText: patrimonymaterials.patrimonymaterial.PatrimonyGroupText,
     PatrimonySubgroupId:  patrimonymaterials.patrimonymaterial.PatrimonySubgroupId,
     PatrimonySubgroupText: patrimonymaterials.patrimonymaterial.PatrimonySubgroupText,
     name : patrimonymaterials.patrimonymaterial.name == null ? '' : patrimonymaterials.patrimonymaterial.name , 

     };         
    this.state = {
      patrimonymaterial: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      PatrimonyGroupList: [],
      PatrimonySubgroupList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentDidMount() {
        await this.getPatrimonyGroups();
        await this.getPatrimonySubgroups(this.state.patrimonymaterial.PatrimonyGroupId.id);
     };
     async getPatrimonyGroups(){
          this.setState({ loading: true });
          var result = await Service.getPatrimonyGroups();
          result.data.forEach((item) => {
            item.parentname = item.name;
            if(item.id === this.state.patrimonymaterial.PatrimonyGroupId){
              let patrimony_Material = this.state.patrimonymaterial
              patrimony_Material.PatrimonyGroupId = item
              this.setState({ patrimonymaterial: patrimony_Material});

            }
          });
            if (result.success) {
              this.setState({ loading: false, PatrimonyGroupList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getPatrimonySubgroups(id){
          this.setState({ loading: true });
          var result = await Service.getPatrimonySubgroups(id);
          result.data.forEach((item) => {
            item.parentname = item.name
            if(this.state.patrimonymaterial.PatrimonySubgroupId === item.id){
              let patrimonyMaterial = this.state.patrimonymaterial
              patrimonyMaterial.PatrimonySubgroupId = item
              this.setState({ patrimonymaterial: patrimonyMaterial});
            }
          });
            if (result.success) {
              this.setState({ loading: false, PatrimonySubgroupList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name === 'name')
    {
      if(value.length <= 255){
        this.setState(prevState => ({
          patrimonymaterial: {
            ...prevState.patrimonymaterial,
            [name]: value
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        patrimonymaterial: {
          ...prevState.patrimonymaterial,
          [name]: value
        }
      }));
    }

    
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    if(this.state.patrimonymaterial.name.length> 255){
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"name"}) + " " + intl.formatMessage({id:"limit255"})
      });
    } else {
      let data = new FormData();

    

      if (this.isClient) {
        this.setState(prevState => ({
          patrimonymaterial: {
            ...prevState.patrimonymaterial,
          }
        }));
      } else {
        this.setState(prevState => ({
          patrimonymaterial: {
            ...prevState.patrimonymaterial,
          }
        }));
      }


      for (let key in this.state.patrimonymaterial) {
        
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.patrimonymaterial[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          if(key === 'PatrimonyGroupId'){
            if(this.state.patrimonymaterial.PatrimonyGroupId.parentname !== ''){
              data.append(key, this.state.patrimonymaterial.PatrimonyGroupId.id);
            } else{
              data.append(key,0);
            }
          }

          if(key === 'PatrimonySubgroupId'){
            if(this.state.patrimonymaterial.PatrimonySubgroupId.parentname !== ''){
              data.append(key, this.state.patrimonymaterial.PatrimonySubgroupId.id);
            } else{
              data.append(key,0);
            }
          }
          data.append(key, this.state.patrimonymaterial[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/patrimonymaterials', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        formattedList.PatrimonyGroupId=  formattedList.patrimonygroup ? formattedList.patrimonygroup.id : 0;
        formattedList.PatrimonyGroupText=  formattedList.patrimonygroup ? formattedList.patrimonygroup.name : '';
        formattedList.PatrimonySubgroupId=  formattedList.patrimonysubgroup ? formattedList.patrimonysubgroup.id : 0;
        formattedList.PatrimonySubgroupText=  formattedList.patrimonysubgroup ? formattedList.patrimonysubgroup.name : '';
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
    }

    
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      patrimonymaterial: {
        ...prevState.patrimonymaterial,
        [stateName]: value
      }
    }));
        if(stateName === 'PatrimonyGroupId'){
          this.getPatrimonySubgroups(value.id);
          this.setState(prevState => ({
            patrimonymaterial: {
              ...prevState.patrimonymaterial,
              ['PatrimonyGroupText']: text,
            }
          }));
          this.setState(prevState => ({
            patrimonymaterial: {
              ...prevState.patrimonymaterial,
              PatrimonySubgroupId: {parentname:''}
            }
          }));
        }
        if(stateName === 'PatrimonySubgroupId'){
          this.setState(prevState => ({
            patrimonymaterial: {
              ...prevState.patrimonymaterial,
              ['PatrimonySubgroupText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };
 
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, patrimonymaterials } = this.props;
    const { patrimonymaterial } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
              <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={patrimonymaterial.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({id:"patrimony.group"}) + '*'} name='patrimonygroupid'  options={this.state.PatrimonyGroupList} stateName='PatrimonyGroupId' changeSelect={this.changeValues} selected={patrimonymaterial.PatrimonyGroupId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({id:"patrimony.sub.group"}) + '*'} name='patrimonysubgroupid'  options={this.state.PatrimonySubgroupList} stateName='PatrimonySubgroupId' changeSelect={this.changeValues} selected={patrimonymaterial.PatrimonySubgroupId} />
                     
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
PatrimonyMaterialEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    patrimonymaterials: state.patrimonymaterials
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(PatrimonyMaterialEdit)));
