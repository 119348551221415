import Api from './api';

export const getAllByOrganizationFieldIdWithoutIncludes = async (organizationFieldId) => { 
  let data = [];
  await Api.get(`/organizationfieldoptionsvalues/organizationfield/${organizationFieldId}/withoutincludes`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}