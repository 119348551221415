import Api from './api';

export const paginate = async (page, orgId, search, field) => { 
  let data = []

  let url = `/calledlistdatas/paginate?page=${page}&org=${orgId && orgId > 0 ? orgId : 0}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginateByOrganization = async (page, orgId, search, field) => { 
  let data = []

  let url = `/calledlistdatas/organization/paginate?page=${page}&id=${orgId}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerId = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/hirer/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByHirerIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/hirer/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByOrganizationId = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/organization/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationIdAndRoleId = async (id, roleId) => { 
  let data = []
  await Api.get(`/calledlistdatas/organization/${id}/role/${roleId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByOrganizationIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledlistdatas/organization/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledlistdatas`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledlistdatas`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledlistdatas/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}