import { PapiroConsole } from "../../utils/papiroConsole";

export default function channels(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      return {
        ...state,
        channel: action.channel,
      }
    case 'UPDATE_CHANNEL_TECHINICALTEAMS':
      PapiroConsole.log("entrei aqui")
      PapiroConsole.log(state)
      PapiroConsole.log(action)
      return {
        ...state,
        channel: {
          ...state.channel,
          channeltechinicalteams: action.channeltechinicalteams
        }
      }
    default:
      return state;
  }
}