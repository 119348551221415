import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import styled from "styled-components";
import { bindActionCreators } from 'redux'
import { Box, Collapse, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import './styles.css'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import SimpleAutoComplete from './autocomplete';
import { useEffect } from 'react';
import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(82, 82, 82, .3);
    top: -2px;
  }
  & .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border: 1px solid rgba(82, 82, 82);
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    padding: 7.5px 10px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    background-color: #FFF;
    border: none;
    border-radius: 20px
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: none;
  }
  & .MuiInputBase-colorPrimary {
    border: none;
  }
`;

function ChatMenuHeader(props) {

  const [totalAttendances, setTotalAttendances] = useState(0)
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    if (props.chats != null && props.chats.chatscount != null)
      setTotalAttendances(props.chats.chatscount)
  }, [props.chats])

  const handleToggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  }

  const funcaoaqui = () => {
    console.log("entrei aqui")
  }

  return (<div className="omnichannel-chat-menu-header">
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid className='omnichannel-chat-menu-header-title' container spacing={0}>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Box component="div" className="omnichannel-chat-menu-header-total-messages" >
              {totalAttendances}
            </Box>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#525252',
            fontWeight: 700
          }}>
            Conversas
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px', marginBottom: '5px' }} >
        <StyledTextField
          fullWidth
          label=""
          inputProps={{ maxLength: 255 }}
          autoComplete='fname'
          variant='outlined'
          name='searchChat'
          onChange={props.handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'  sx={{ cursor: 'pointer', '&:hover': { color: 'primary.main' } }} >
                <IconButton onClick={props.getChatsByTechWithFilters} color="inherit">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '25px', marginBottom: '5px' }}>
        <IconButton sx={isFilterOpen ? { backgroundColor: 'rgba(0, 0, 0, 0.2)', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } : { backgroundColor: 'transparent', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }} onClick={handleToggleFilter} color="inherit">
          <FilterListIcon sx={isFilterOpen ? { color: '#5667c4' } : { color: '#545454' }} />
        </IconButton>
      </Grid>
    </Grid>
    <Collapse in={isFilterOpen} sx={{ paddingTop: '10px' }}>
      <Grid container spacing={1}>
        {/*<Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ paddingRight: '5px' }}>
            <SimpleAutoComplete
              label=""
              options={props.providerList}
              stateName="providerid"
              selected={props.providerid}
              changeSelect={props.changeValues}
              isRequired
              required />
        </Grid>*/}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#525252',
          fontWeight: 400,
          marginTop: '10px',
        }}>
          Selecione o status
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SimpleAutoComplete
            label=""
            options={props.statusList}
            stateName="chatstatusid"
            selected={props.chatstatusid}
            changeSelect={props.changeValues}
            isRequired
            required />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#525252',
          fontWeight: 400,
          marginTop: '10px',
        }}>
          Selecione o time de atendimento
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={null/*{ marginTop: '5px', marginBottom: '5px' }*/} >
          <SimpleAutoComplete
            label=""
            options={props.techinicalTeamList}
            stateName="techinicalteamid"
            //changeSelect={props.changeTechinicalTeam}
            selected={props.techinicalteamid}
            changeSelect={props.changeValues}
            isRequired
            required />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#525252',
          fontWeight: 400,
          marginTop: '5px',
        }}>
          Selecione o canal
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={null/*{ marginTop: '5px', marginBottom: '5px' }*/} >
          <SimpleAutoComplete
            label=""
            options={props.channelList}
            //changeSelect={props.changeChannel}
            stateName="channelid"
            selected={props.channelid}
            changeSelect={props.changeValues}
            isRequired
            required />
        </Grid>

      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#525252',
          fontWeight: 400,
          marginTop: '5px',
        }}>
          Selecione a origem das conversas
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <SimpleAutoComplete
            label=""
            options={props.phoneNumberList}
            stateName="phonenumberid"
            changeSelect={props.changeValues}
            selected={props.phonenumberid}
            isRequired
            required />
        </Grid>
      </Grid>
    </Collapse>
  </div>)
}

ChatMenuHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatMenuHeader)))