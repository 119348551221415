import * as CalledService from '../../../../../../services/called.service';

export const getOrganizations = async () => {
  const result = await CalledService.getOrganizations();

  if (result.success) {
    return result.data
  } 

  return [];
};

export const getCategoriesByOrganization = async (organizationId) => {
  var result = await CalledService.getCategoriesByOrganization(organizationId);

  if (result.success) {

    let categories = result.data && result.data.length > 1 
    ? result.data.sort((a, b) => a.name.localeCompare(b.name)) 
    : result.data;

    categories = categories.map(item => ({id:item?.id, name:item?.name}));

    return categories;
  }

  return [];
};