import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';

import { AttachFile } from '@mui/icons-material';
import 'react-chat-widget/lib/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import ChatListOptions from '../../components/chat-list-options';
import CustomizedSnackbars from "../../components/material-snackbars";
import { ChatOptions } from "../../components/chat-options";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  IconButton,

} from "@mui/material";
import * as FileService from '../../services/attachment.service';
import { useChatHubContext } from '../../contexts/chatHubContext';
import moment from "moment";
import "./styles.scss";
import * as signalR from '@microsoft/signalr';
import { PapiroConsole } from '../../utils/papiroConsole';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import MyMaterialTable from '../../components/table'
import { Dialog, Button, CircularProgress, Grid } from '@mui/material';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-users-dialog-with-z-index';
import { makeStyles } from '@mui/styles';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete-dialog-with-z-index'
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Api from "../../services/api"
import Typography from '@mui/material/Typography';
import { Overlay } from '../../styles/global';

function TechnicianChat({ userId, closeChat, intl }) {
  const {
    chatHub: chatHubConnection,
    currentTechnicianChat,
    currentMessages,
    newCalledCreated,
  } = useChatHubContext();
  const [inputMessage, setInputMessage] = useState(null);
  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const [dialogState, setdialogState] = useState(false);
  const [dialogStateCloseChat, setdialogStateCloseChat] = useState(false);

  const [calledInfoDialogOpen, setcalledInfoDialogOpen] = useState(false);

  const [openDialogNewAttendence, setOpenDialogNewAttendence] = useState(false);



  const [calledInfoDialogId, setcalledInfoDialogId] = useState(false);
  const [calledInfoDialogTitle, setcalledInfoDialogTitle] = useState(false);
  const [calledInfoDialogDueDate, setcalledInfoDialogDueDate] = useState(false);
  const [chatAlrealdyHasCalled, setChatAlrealdyHasCalled] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [currentCalledId, setCurrentCalledId] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [newConversationDialogOpen, setNewConversationDialogOpen] = useState(false);
  const [organizationFilter, setOrganizationFilter] = useState(0);
  const [requestingUser, setRequestingUser] = useState({ id: 0, name: '' });
  const [loadingRequestingUsers, setLoadingRequestingUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const closeAlertMessage = () => setAlertNotification({ ...alertNotification, isOpen: false });
  const [attachments, setAttachments] = useState([]);
  const openAlertMessage = (variant, message) => setAlertNotification({ variant, message, isOpen: true });

  //const [newAttendenceAccept, setNewAttendenceAccept] = useState(false);

  const [valueNewAttendence, setValueNewAttendence] = useState(0.00);







  useEffect(() => {
    scrollChatToBottom()


  }, [currentMessages])

  useEffect(() => {
    if (newConversationDialogOpen) {
      PapiroConsole.log("entrei aqui no newConversationDialogOpen ")
      PapiroConsole.log(newConversationDialogOpen)
      getOrganizations()
    }


  }, [newConversationDialogOpen])


  //iniciar uma nova conversa entre técnico e cliente
  const newChatConversation = async (e, assigneduserid, requestingUser, organization) => {

    openOrCloseNewConversationDialog()
    setLoading(true)

    if (validationsNewConversation(requestingUser, organization, assigneduserid)) {
      await chatHubConnection.invoke("InitChatByTech", requestingUser.id, assigneduserid, organization.id).then(result => {
        PapiroConsole.log(result)
        PapiroConsole.log("result")
        if (result == true) {

          openAlertMessage("success", "Conversa iniciada com sucesso");
        }

      })

    }

    setLoading(false);
  };


  const handleSubmitAccept = async (e) => {

    //setNewAttendenceAccept(true)
    createNewCalledByChat(null, true);
  }

  //função que faz validações pra ver se é possivel iniciar a conversa
  const validationsNewConversation = (requestingUser, organization, assigneduserid) => {

    if (requestingUser.id == 0) {
      openAlertMessage("error", "É necessário informar o usuário para criação do chat");
      return false
    }
    if (organization.id == 0) {

      openAlertMessage("error", "É necessário informar a organização");
      return false
    }
    if (requestingUser.id == assigneduserid) {
      openAlertMessage("error", "O solicitante não pode ser igual ao usuário logado");
      return false
    }

    return true

  };


  //abrir ou fechar dialogo que cria um novo chamado pelo chat ou atualiza um chamado pelo chat
  const openOrCloseDialog = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }
    setdialogState(!dialogState)

  };

  const openOrCloseDialogNewAttendence = (e) => {


    if (e != null) {
      e.preventDefault()
    }
    setOpenDialogNewAttendence(!openDialogNewAttendence)
  }

  const openOrCloseDialogFinishChat = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }
    setdialogStateCloseChat(!dialogStateCloseChat)

  };



  //abrir ou fechar dialogo que informa que o chamado foi criado com sucesso
  const openOrCloseInfoDialog = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }
    setcalledInfoDialogOpen(!calledInfoDialogOpen)

  };

  //abrir ou fechar dialogo que pergunta se o usuário quer iniciar uma nova conversa
  const openOrCloseNewConversationDialog = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }
    setNewConversationDialogOpen(!newConversationDialogOpen)
  };


  //mandar uma nova mensagem
  const addMessage = (e, message, sender) => {
    e.preventDefault();

    PapiroConsole.log(currentMessages)
    PapiroConsole.log("currentMessages")
    PapiroConsole.log(currentTechnicianChat)

    if (!currentTechnicianChat) {
      setAlertNotification({ variant: "error", message: intl.formatMessage({ id: "technician.chat.select.user" }), isOpen: true });

      return;
    }
    if (!message) {

      setAlertNotification({ variant: "error", message: "Digite uma mensagem para envio", isOpen: true });

      return;

    }
    if (chatHubConnection.state === signalR.HubConnectionState.Connected) {

      chatHubConnection.invoke("SendPrivateMessage", message, currentTechnicianChat.id, userId, false);
      setInputMessage("");
    }
    else {
      setAlertNotification({ variant: "error", message: "Não foi possível o envio da mensagem, problemas na conexão", isOpen: true });
    }
  }


  //finalizar atendimento do chat
  const closeChatAttendance = async () => {
    PapiroConsole.log("entrei aqui 3")
    PapiroConsole.log(currentTechnicianChat)
    PapiroConsole.log("currentTechnicianChat")
    setLoading(true)
    openOrCloseDialogFinishChat()
    if (!currentTechnicianChat) {
      setLoading(false)
      setAlertNotification({ variant: "error", message: "Selecione um chat para finalizar", isOpen: true });

      return;

    }

    await chatHubConnection.invoke("CloseChat", currentTechnicianChat.id, userId).then(result => {

      if (result == true) {
        openAlertMessage("success", intl.formatMessage({ id: "footer.chat.attendance.finished" }));

        chatHubConnection.invoke("SendPrivateMessage", '', currentTechnicianChat.id, userId, true).catch(function (err) {
          setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
          console.error(err.toString());
        });;



        //setChatAlrealdyHasCalled(false)

      }
    });

    setLoading(false)
  };


  //checar se um atendimento ja tém chamado criado 
  const checkIfChatHasCalled = async (chatId) => {

    setLoading(true);
    PapiroConsole.log("entrei aqui 12")
    PapiroConsole.log(chatId)


    PapiroConsole.log("entrei aqui 13")
    await Api.get(`/calledchat/getbychatid/${chatId}`)
      .then(result => {
        if (result && result.data && result.data.success) {

          if (result.data.data.chatid && result.data.data.calledid) {
            ///setChatAlrealdyHasCalled(true)
            setCurrentChatId(result.data.data.chatid)
            setCurrentCalledId(result.data.data.calledid)
          }
          else {
            //setChatAlrealdyHasCalled(false)
            setCurrentCalledId(null)
            setCurrentChatId(null)
          }

        } else {

          openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));
          setLoading(false);
          // setChatAlrealdyHasCalled(false)
          setCurrentCalledId(null)
          setCurrentChatId(null)

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false);
          openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))


        }
        else {
          setLoading(false);
          openAlertMessage("error", intl.formatMessage({ id: "process.error" }))

        }
        //setChatAlrealdyHasCalled(false)
        setCurrentCalledId(null)
        setCurrentChatId(null)
      });


    setLoading(false);

  }

  const changeValues = (stateName, value, text = '') => {
    setLoading(true)
    //this.setState({ [stateName]: value});

    if (stateName == 'organizationFilter') {
      setOrganizationFilter(value)
    }
    if (stateName == 'requestingUser') {
      setRequestingUser(value)
    }

    setLoading(false)
  };

  const sendAttachments = async (event) => {

    PapiroConsole.log("entrei aqui attachment")

    setLoading(true)
    const files = event.target.files;
    var arrayAttachments = [...files];
    let data = new FormData();
    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    if (files.length > 0 && currentTechnicianChat) {

      if (currentTechnicianChat.closedChat == false) {

        arrayAttachments.forEach(file => data.append('filesUpload', file));
        data.append('chatid', currentTechnicianChat.id)
        data.append('senderid', userId)

        await Api.post("/chatmessageattachment", data, config)
          .then(result => {
            if (result && result.data && result.data.success) {

              PapiroConsole.log("result attachments aqui ")
              PapiroConsole.log(result)
              if (result.data.data && result.data.data.length > 0) {

                let intMessagesArray = result.data.data.map(element => element.id);
                PapiroConsole.log("lista de inteiros aqui")
                if (chatHubConnection.state === signalR.HubConnectionState.Connected) {


                  chatHubConnection.invoke("SendAttachment", intMessagesArray, currentTechnicianChat.id, userId).catch(function (err) {
                    setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
                    console.error(err.toString());
                  });

                }
              }



              //aqui mandar invoke pra enviar mensagem pro técnico 

            } else {

              openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));
            }
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {

              openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            }
            else {
              openAlertMessage("error", intl.formatMessage({ id: "process.error" }))
            }
          });

      }
      else {
        setAlertNotification({ variant: "error", message: "Este chat foi encerrado", isOpen: true });
      }



    }
    setLoading(false)


  };


  //update no chamado pelo chat
  const updateCalledByChat = async () => {

    PapiroConsole.log(currentMessages)
    PapiroConsole.log("currentMessages")

    openOrCloseDialog()
    setLoading(true);

    if (!currentTechnicianChat) {
      openAlertMessage("error", intl.formatMessage({ id: "cannot.update.called" }));

    }
    else {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      let description = ""
      var messagesArray = []
      const idsArray = currentMessages.map(element => element.id);
      const chatmessagesids = idsArray.join(",");
      PapiroConsole.log("chatmessagesids")
      PapiroConsole.log(chatmessagesids)
      let data = new FormData();


      data.append("chatmessagesids", chatmessagesids);
      data.append("chatid", currentTechnicianChat.id);
      data.append("calledid", currentTechnicianChat.calledChat ? currentTechnicianChat.calledChat.calledId ? currentTechnicianChat.calledChat.calledId : currentTechnicianChat.calledChat.calledid : 0);
      console.log("chatmessagesids")
      console.log(chatmessagesids)
      await Api.post("/accompaniments/chat", data, config)
        .then(result => {
          if (result && result.data && result.data.success) {
            openAlertMessage("success", "Chamado Atualizado com sucesso!");


          } else {

            openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {

            openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
          }
          else {

            openAlertMessage("error", intl.formatMessage({ id: "process.error" }))
          }
        });
    }

    setLoading(false);




  }

  const getFileName = (fileName) => {
    PapiroConsole.log(fileName)
    PapiroConsole.log("fileName")
    let file = fileName.substring(fileName.lastIndexOf('/') + 1);
    PapiroConsole.log(file)
    PapiroConsole.log("file")
    return file;
  }

  const getFile = async (hirerid, fileName) => {

    let url = await FileService.getFileByPathWithFileRepositoryMethodByConfig(hirerid, fileName);
    //alert(JSON.stringify(url.data));

    if (url && url.data && url.data.data && url.data.success) {
      window.open(`${url.data.data}`, '_blank');
    }

  }

  const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }


  //criar novo chamado pelo chat
  const createNewCalledByChat = async (e, newAttendenceAccept = false) => {

    setLoading(true);
    if (!currentTechnicianChat) {
      openAlertMessage("error", intl.formatMessage({ id: "cannot.create.called.chat" }));

    }
    else {

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };


      var SecurityPassword = randomIntFromInterval(1000, 99999)

      const idsArray = currentMessages.map(element => element.id);
      const chatmessagesids = idsArray.join(",");
      PapiroConsole.log("chatmessagesids")
      PapiroConsole.log(chatmessagesids)

      let data = new FormData();

      PapiroConsole.log(newAttendenceAccept)
      PapiroConsole.log("newAttendenceAccept")
      if (newAttendenceAccept) {
        data.append("acceptNewAttendence", true);
        openOrCloseDialogNewAttendence()

      }
      else {
        openOrCloseDialog()
      }
      data.append("chatid", currentTechnicianChat.id);
      data.append("organizationid", currentTechnicianChat.organizationId);
      data.append("assigneduserid", currentTechnicianChat.assignedUserId);
      data.append("requestinguserid", currentTechnicianChat.requestingUserId);
      data.append("chatmessagesids", chatmessagesids);
      data.append("SecurityPassword", SecurityPassword.toString());
      console.log("chatmessagesids")
      console.log(chatmessagesids)

      await Api.post("/calleds/create/chat", data, config)
        .then(result => {
          if (result && result.data && result.data.success) {
            PapiroConsole.log(result)
            PapiroConsole.log("result aqui 1")

            if (result.data.data.calleds && result.data.data.chat) {

              setcalledInfoDialogDueDate(result.data.data.calleds.duedate ? moment(result.data.data.duedate).format("DD/MM/YYYY") : '')
              setcalledInfoDialogId(result.data.data.calleds.id)
              setcalledInfoDialogTitle(result.data.data.calleds.title)
              var chat = {
                id: result.data.data.chat.id,
                assignedUser: result.data.data.chat.assigneduser ? result.data.data.chat.assigneduser : null,
                assignedUserId: result.data.data.chat.assigneduserid ? result.data.data.chat.assigneduserid : 0,
                messages: null,
                requestingUser: result.data.data.chat.requestinguser ? result.data.data.chat.requestinguser : null,
                requestingUserId: result.data.data.chat.requestinguserid ? result.data.data.chat.requestinguserid : 0,
                calledChat: result.data.data.chat.calledchat ? result.data.data.chat.calledchat : null,
                hirerId: result.data.data.chat.hirerId ? result.data.data.chat.hirerId : 0,
                notSeenByAssignedUser: result.data.data.chat.notseenbyassigneduser ? result.data.data.chat.notseenbyassigneduser : 0,
                notSeenByRequestingUser: result.data.data.chat.notseenbyrequestinguser ? result.data.data.chat.notseenbyrequestinguser : 0,
                organizationId: result.data.data.chat.organizationid ? result.data.data.chat.organizationid : 0,

              }
              PapiroConsole.log("chats")
              PapiroConsole.log(chat)
              newCalledCreated(chat)

              PapiroConsole.log(currentTechnicianChat)
              PapiroConsole.log("currentTechnicianChat")
              //setChatAlrealdyHasCalled(true)
              //setCurrentCalledId(result.data.data.id)
              openAlertMessage("success", "Chamado criado com sucesso!");
              openOrCloseInfoDialog()

              PapiroConsole.log(currentTechnicianChat)
              PapiroConsole.log("currentTechnicianChat 1 ")



            }





          } else {

            PapiroConsole.log(result)
            PapiroConsole.log("result erro aqui")
            openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));

          }
        })
        .catch(err => {
          PapiroConsole.log(err)
          PapiroConsole.log("err erro aqui")
          if (err.response && err.response.data && err.response.data.errors) {

            if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {
              if (!isNaN(err.response.data.errors[0])) {
                openOrCloseDialog()
                setOpenDialogNewAttendence(true)
                setValueNewAttendence(err.response.data.errors[0])

              } else {
                openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
              }
            }


          }
          else {

            openAlertMessage("error", intl.formatMessage({ id: "process.error" }))
          }
        });



    }

    setLoading(false);

  };



  const getOrganizations = async () => {
    let data = [];
    //const { userSession } = this.props

    await Api.get('/organizations/tech')
      .then(result => {
        if (result && result.data && result.data.success) {

          const organizations = result.data.data.filter(org => {
            if (org.organizationsettings) {
              return org.organizationsettings.allowattendanceviachat
            }
          });

          PapiroConsole.log(organizations)
          PapiroConsole.log("organizations tech")

          setOrgList(organizations)

        } else {
          openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {

          openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
        else {
          openAlertMessage("error", intl.formatMessage({ id: "process.error" }))

        }
      });


  }

  const checkDisabledChat = () => {


    if (currentTechnicianChat) {
      return ""
    }

    else {
      return "disabled-main-content"
    }
  }


  const scrollChatToBottom = () => {
    const chatContent = document.querySelector(".chat-main-content");
    if (!chatContent) return;
    chatContent.scrollTo({ top: chatContent.scrollHeight, behavior: "smooth" })
  }
  return (

    <div className="technician-chat-container">


      <div className="header-container">
        <div className="header-content">
          <div style={{ width: '90%' }}>
            <div className="title">{intl.formatMessage({ id: "chat.attendance" })}</div>
            <div className="username">{currentTechnicianChat ? currentTechnicianChat.requestingUserId == userId ? currentTechnicianChat.assignedUser.name : currentTechnicianChat.requestingUser.name : ''}  {currentTechnicianChat && currentTechnicianChat.calledChat ? `- Chamado n° ${currentTechnicianChat.calledChat.calledId ? currentTechnicianChat.calledChat.calledId : currentTechnicianChat.calledChat.calledid}` : ''}</div>
          </div>
          <div className="chat-button">
            <CloseIcon onClick={closeChat} />
          </div>
        </div>
      </div>
      <div className="chat-list-container">
        <ChatListOptions />
        {/* <ChatListOptions /> */}
      </div>
      <div className="chat-container">
        <div className={`chat-main-content ${checkDisabledChat(currentTechnicianChat)}`} >
          {currentMessages && currentMessages.length > 0 && currentMessages.map(({ id, message, senderUser, isAttachment }) => {
            if (senderUser && (senderUser.id === userId) && isAttachment == false) {
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-end', borderBottomRightRadius: '0' }}>
                  {/* <span key={id} style={{ backgroundColor: "#5f5fff", color: "white", justifyContent: "right" }} className="message sender">{ message }</span> */}
                  <span key={id} style={{ backgroundColor: "#5f5fff", color: "white" }} className="message ">{message}</span>
                </div>
              )
            }
            else if (senderUser && (senderUser.id === userId) && isAttachment == true) {
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-end', borderBottomRightRadius: '0' }}>
                  {/* <span key={id} style={{ backgroundColor: "#5f5fff", color: "white", justifyContent: "right" }} className="message sender">{ message }</span> */}
                  <span key={id} style={{ backgroundColor: "#5f5fff", color: "white" }} className="message "><Button startIcon={<AttachFile />} style={{ color: 'white', fontSize: '10px' }} onClick={() => getFile(currentTechnicianChat ? currentTechnicianChat.hirerId : 0, message)}>{getFileName(message)}</Button></span>
                </div>
              )
            }
            else if (isAttachment == true) {
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                  <span  style={{color:'gray'}} >{currentTechnicianChat ? currentTechnicianChat.requestingUserId == userId ? currentTechnicianChat.assignedUser.name : currentTechnicianChat.requestingUser.name : ''}<br /></span>
                  <span key={id} className="message"><Button startIcon={<AttachFile />} style={{ color: 'black', fontSize: '10px' }} onClick={() => getFile(currentTechnicianChat ? currentTechnicianChat.hirerId : 0, message, 2)}>{getFileName(message)}</Button></span>
                </div>
              )
            }

            else {
              return (
                <div style={{ color:'gray', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                  <span>{currentTechnicianChat ? currentTechnicianChat.requestingUserId == userId ? currentTechnicianChat.assignedUser.name : currentTechnicianChat.requestingUser.name : ''}<br /></span>
                  <span key={id} className="message ">{message}</span>
                </div>
              )
            }
          })}
        </div>
        <div className="chat-input-container">
          <form onSubmit={(e) => addMessage(e, inputMessage, userId)} className="input-wrapper">
            <input disabled={currentTechnicianChat ? currentTechnicianChat.closedChat : true} onChange={(e) => setInputMessage(e.target.value)} placeholder={currentTechnicianChat ? intl.formatMessage({ id: "technician.chat.type.something" }) : intl.formatMessage({ id: "select.conversation" })} className="chat-input" type="text" value={inputMessage}></input>
            <SendIcon style={{color:'black'}} onClick={(e) => addMessage(e, inputMessage, userId)} className="send-icon" />

            <label htmlFor="attachment-input">
              <IconButton component="span">
                <AttachFileIcon />
              </IconButton>
              <input
                id="attachment-input"
                type="file"
                style={{ display: 'none' }}
                onChange={sendAttachments}
                multiple
              />
            </label>
            {/*<AttachFileIcon onClick={(e) => addMessage(e, inputMessage, userId)} className="send-icon" />*/}
            <ChatOptions  closeChat={openOrCloseDialogFinishChat} isClient={false} intl={intl} openOrCloseDialog={openOrCloseDialog} chatAlrealdyHasCalled={currentTechnicianChat ? currentTechnicianChat.calledChat ? true : false : false} openOrCloseNewConversationDialog={openOrCloseNewConversationDialog} />
          </form>
        </div>
      </div>
      {loading &&
        <Overlay >
          <CircularProgress color='secondary' />
        </Overlay>}
      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />
      <Dialog

        open={calledInfoDialogOpen}
        onClose={(e) => openOrCloseInfoDialog(e)}
        aria-labelledby="form-delete-filter"
        maxWidth="md"
        fullWidth={false}
        style={{ overflow: 'hidden', zIndex: 100000 }}
        scroll="body" >
        <DialogTitle id="form-delete-filter">Chamado criado com sucesso!</DialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="span" style={{ fontWeight: 'bold' }}>
                Número do chamado:&nbsp;
              </Typography>
              <Typography variant="body1" component="span" >
                {calledInfoDialogId}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="span" style={{ fontWeight: 'bold' }}>
                Título do chamado:&nbsp;
              </Typography>
              <Typography variant="body1" component="span" >
                {calledInfoDialogTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="span" style={{ fontWeight: 'bold' }}>
                Data de previsão de solução:&nbsp;
              </Typography>
              <Typography variant="body1" component="span" >
                {calledInfoDialogDueDate}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseInfoDialog} color="primary">
            {intl.formatMessage({ id: "close" })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        style={{ zIndex: 100000 }}

        fullWidth={true}
        maxWidth={"md"}
        open={dialogState}
        onClose={(e) => openOrCloseDialog(e)}
        aria-labelledby="create.called.new"
      >


        <DialogTitle id="create.new.called">

          <FormattedMessage id={currentTechnicianChat ? currentTechnicianChat.calledChat ? "update.called" : "create.called.chat" : "create.called.chat"} />
        </DialogTitle>
        <DialogContent  >
          <DialogContentText  >
            <div>
              {currentTechnicianChat ? currentTechnicianChat.calledChat ? intl.formatMessage({ id: "update.called.by.chat" }) : intl.formatMessage({ id: "create.called.new.by.chat" }) : intl.formatMessage({ id: "create.called.new.by.chat" })}
            </div>

          </DialogContentText>


        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseDialog} color="secondary">
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button variant='contained' color='primary' onClick={currentTechnicianChat ? currentTechnicianChat.calledChat ? (e) => updateCalledByChat(e) : (e) => createNewCalledByChat(e) : (e) => createNewCalledByChat(e)} >
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog


        style={{ zIndex: 10000 }}
        fullWidth={true}
        maxWidth={"md"}
        open={newConversationDialogOpen}
        onClose={(e) => openOrCloseNewConversationDialog(e)}
        aria-labelledby="start.new.conversation"
      >


        <DialogTitle id="start.new.conversation">

          <FormattedMessage id={intl.formatMessage({ id: "start.new.conversation" })} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText  >
            <div>
              {intl.formatMessage({ id: "start.new.conversation.ask" })}
            </div>


          </DialogContentText>
          <Grid container spacing={2} style={{ marginTop: 2 }}>

            <Grid item xs={12}>
              <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={orgList} stateName='organizationFilter' changeSelect={changeValues} />
            </Grid>

            <Grid item xs={12}>
              <SimpleAutoCompleteSearch
                label={intl.formatMessage({ id: "keypress.search.instruction" }) + " " + intl.formatMessage({ id: "requestuser" })}
                options={[]}
                stateName="requestingUser"
                changeSelect={changeValues}
                // selected={called.requestinguser} 
                loading={loadingRequestingUsers}
                urlapi={Api.apiurlbase() + 'api/users/search/' + (organizationFilter && organizationFilter.id ? organizationFilter.id : 0)}
                required />

            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseNewConversationDialog} color="secondary">
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button variant='contained' color='primary' onClick={(e) => newChatConversation(e, userId, requestingUser, organizationFilter)} >
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ zIndex: 100000 }}

        fullWidth={true}
        maxWidth={"sm"}
        open={dialogStateCloseChat}
        onClose={(e) => openOrCloseDialogFinishChat(e)}
        aria-labelledby="finish.chat"
      >


        <DialogTitle id="finish.chat">

          <FormattedMessage id={intl.formatMessage({ id: "finish.chat" })} />
        </DialogTitle>
        <DialogContent  >
          <DialogContentText  >
            <div>
              {intl.formatMessage({ id: "finish.chat.info" })}
            </div>

          </DialogContentText>


        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseDialogFinishChat} color="secondary">
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button variant='contained' color='primary' onClick={(e) => closeChatAttendance(e)} >
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ zIndex: 100000 }}
        open={openDialogNewAttendence}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: "dialog.title.warning.max.attendances.reached" })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: "warning.max.attendances.reached.first.half" })} R$ {valueNewAttendence} {intl.formatMessage({ id: "warning.max.attendances.reached.second.half" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseDialogNewAttendence} variant="contained" color="secondary" autoFocus >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button variant="contained" onClick={handleSubmitAccept} color="primary" >
            {intl.formatMessage({ id: "accept" })}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default injectIntl(TechnicianChat)