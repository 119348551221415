import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import { PapiroConsole } from '../../utils/papiroConsole';

const EmojiPicker = ({ onEmojiClick, button }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleEmojiClick = (emojiObject, event) => {
    PapiroConsole.log("=== EmojiPicker -> handleEmojiClick ===")
    setShowPicker(false);
    onEmojiClick(emojiObject);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.emoji-picker-container')) {
      setShowPicker(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="emoji-picker-container" style={{ position: 'relative' }}>
      {showPicker && (
        <div className="emoji-picker" style={{ position: 'absolute', bottom: '100%', left: 0 }}>
          <Picker onEmojiClick={handleEmojiClick} />
        </div>
      )}
      <button onClick={togglePicker} style={{ backgroundColor: 'rgba(0, 0, 0, 0)', border: 'none', display: 'flex', alignItems: 'center' }}>
        {button}
      </button>
    </div>
  );
};

export default EmojiPicker;