import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calledorganizationchecklists';
import { bindActionCreators } from 'redux';
import moment from "moment";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoSmall2 from "../../components/logo/logo-small-2";
import { PapiroConsole } from '../../utils/papiroConsole'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6

class CalledReportInformation extends Component {
  constructor(props) {
    super(props);
    
    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
    
    this.reactQuillFormats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    
    this.state = {

      calledReport: {
        token : "",
        calledId : null,
        email : ""
      },
      base64image : "",
      calledId: null, 
      latitude : "",
      logintude:"",
      address: "",
      title : "",
      status : "",
      openingDate : "", 
      solutionDate:"",
      calledFinish: "",
      dueDate : "",
      user : "",
      technical : "",
      description : "",
      organization : "",
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
      signatureName: "",
      observations: '',
      category : "",
      hirerid: 0,
      token: this.props.location && this.props.location.state && this.props.location.state.accesstoken ? this.props.location.state.accesstoken : ""
    };

    const { history } = this.props;
    this.history = history
    this.listItems = null
    this.addresses = null
    this.teste = null

    this.getData = this.getData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pdfSubmit = this.pdfSubmit.bind(this);
    
  }

  async componentDidMount() {
    await this.getData(this.props)
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      calledReport: {
        ...prevState.calledReport,
        [name]: value,
      },
    }));
  }

  pdfSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    let config = {
      headers: {
          "Authorization": "Bearer "+this.state.token
        }
    };
    Api.post("calleds/pdfReport", { id: this.state.calledId, hirerid: this.state.hirerid },config)
      .then((result) => {
       
        if(result && result.data && result.data.data){
          if(result.data.data[0] && result.data.data[0].url != null){
            window.open(`${result.data.data[0].url}`, '_blank');
          }
          
          this.setState({
            loading: false,
          });
        }
        else
        {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
            result && result.data && result.data.errors
                ? result.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        }

      })
      .catch((err) => {
        PapiroConsole.log(err)
        
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
      });

    
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const intl = this.props.intl;
    this.setState({ loading: true });

    const { calledId, token, email } = this.state.calledReport;
    var Id = parseInt(calledId)
    
    var id = '{"id" :  5}'
    Api.post("calleds/reportCalled", { Id, token, email })
        .then((result) => {
          if (result.data.success) {
            
            this.props.history.push({
              pathname: `/pt/calleds`,
              state: {
                  result: intl.formatMessage({id:"tab.called.report.anything"}),

              }
          })
            /*this.history.push({
              pathname: '/pt/calleds',
              state: { result: result }
            })*/
           /* this.props.history.push({
              pathname: 'pt/dashboard',
              state: result
            })*/

           PapiroConsole.log(result)
           this.setState({
              loading: false,
            });
           
          }
        })
        .catch((err) => {
          PapiroConsole.log(err)
          
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });
  };

  async getData(props){

    var latitude = null
    var longitude = null
    const mystyle = {
      subtitleFont : {
        marginTop:'4px',
        fontSize :"14px",
        
      },
    }

    PapiroConsole.log("=== PROPS ===")
    PapiroConsole.log(props)
    
    if(props.location && props.location.state && props.location.state.called){
        
        var called = props.location.state.called;
        if(called.calledhistorychecks && called.calledhistorychecks ){

          if(called.calledhistorychecks.length > 0)
          {
            var array = called.calledhistorychecks
             
            array.forEach(element => {
              if(element.checkout == true){
                longitude = element.longitude
                latitude = element.latitude
                }
            });
          }
        }

        var calledId = called.id
        PapiroConsole.log("=== CALLED ===")
        PapiroConsole.log(called)
        var email = called && called.requestinguser && called.requestinguser.email
        var tabReport = false
        let config = {
          headers: {
              "Authorization": "Bearer "+this.state.token
            }
        };

        await Api.post(`/calleds/attachments`,  { calledId, email, tabReport }, config)
        .then((result) => {
          if (result.data) {
            PapiroConsole.log(result)

            if(result.data.data && result.data.data.length!= undefined && result.data.data.length !=0 ){
              var base = result.data.data;
              if(base.substring(0,4).toLowerCase() != "data"){
                base = "data:image/png;base64,"+base;
              }
              this.setState({  base64image: base });
            }
          } else {
            PapiroConsole.log(result);
          }
        })
        .catch((err) => {
          // PapiroConsole.log(err);
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        
          // PapiroConsole.log("entrei aqui");
        });

      var state = props.location.state.called
      PapiroConsole.log("=== STATE ===")
      PapiroConsole.log(state)
      
      if(state.requestinguser && state.requestinguser.useraddresses){
        this.setState({  hirerid: state.requestinguser.hirerid });
      }
      var calledFinish = null;
      if(state.calledstatus != null && state.calledstatus.length > 0  ){
        array = state.calledstatus

        array.forEach(element => {
          if(element.nextstatusid == 4){
            if(element.createdat != null){
             
              calledFinish = moment(element.createdat).format('DD/MM/YYYY HH:mm:ss')
            }
           
          }
        });
       
      }
 
      if(calledId){
        var emailUser = props.location.state.email;
        
        await Api.post(`/addresses/reportCalled`,  { calledId, email }, config)
          .then((result) => {
            if (result.data && result.data.data && result.data.data.name && result.data.data.name.length > 0) {
              this.addresses = (
                <div>
                  <h4 style={{ fontSize :"16px", color:"black" }}>{result.data.data.name} - {result.data.data.street}, {result.data.data.number > 0 ? "Nº"+result.data.data.number : "S/N"}, {result.data.data.complement}. {result.data.data.neighborhood}, {result.data.data.city} - {result.data.data.country}</h4>
                </div>
              );
            } else {
              PapiroConsole.log(result);
            }
          })
          .catch((err) => {
            PapiroConsole.log(err);
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : this.props.intl.formatMessage({id:"process.error"}),
            });
          });
        }

      await Api.get(`/calledhistorycheck/signature/${calledId}`)
            .then((result) => {
              if(result && result.data && result.data.data) {
                this.setState({
                  latitude: result.data.data.latitude ? result.data.data.latitude.replaceAll("\"", "") : "",
                  longitude: result.data.data.longitude ? result.data.data.longitude.replaceAll("\"", "") : "",
                  address: result.data.data.addresscomplete ? result.data.data.addresscomplete.replaceAll("\"", "") : ""
                });
              }else {
                PapiroConsole.log(result);
              }
            })
            .catch((err) => {
              PapiroConsole.log(err);
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : this.props.intl.formatMessage({id:"process.error"}),
              });
            });

          await Api.get(`/calleds/signatureinfo/${calledId}`)
            .then((result) => {
              if(result && result.data && result.data.data) {
                this.setState({
                  signatureName: result.data.data.namesignature,
                  observations: result.data.data.observations,
                });
              }else {
                PapiroConsole.log(result);
              }
            })
            .catch((err) => {
              PapiroConsole.log(err);
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : this.props.intl.formatMessage({id:"process.error"}),
              });
            });

      this.setState({
        calledId: state.id,
        title : state.title,
        calledFinish : calledFinish,
        openingDate : moment(state.openingdate).endOf('day').format("DD/MM/YYYY"),
        dueDate : moment(state.duedate).endOf('day').format("DD/MM/YYYY h:mm  "),
        user : state.requestinguser?.name,
        technical : state.assigneduser?.name,
        description : state.description || "",
        organization : state.organization?.name,
        status: state.status.name,
        category :state.organizationcategory.name
      });

    }else{
      this.props.history.push("/pt/external-called-report");
    }

  }

  validatePassword() {
    
  }

  validatePhone() {
   
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const intl = this.props.intl;
    const { classes } = this.props;
    const { calledReport } = this.state;
    PapiroConsole.log(this.state)
    PapiroConsole.log("state aqui")

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    
    const mystyle = {

      title: {
        flexGrow: 1,
      },

        containerBorder : {
         fontSize :"20px",
         minHeight: '100px', 
         borderRight:'1px solid #e6e6e6'
         
       },

       containernoBorder : {
        fontSize :"20px",
        minHeight: '100px', 
       
        
      },
   
       subtitleFont : {
         marginTop:'4px',
        fontSize :"14px",
       
        
       },

       dataFont : {
        fontSize :"16px",
        color:"black",
        
       },
       menuRoot: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: '8px',
      },
      titleMenu: {
        flexGrow: 1,
      },
     }

    return (
    
          <Container component="main" maxWidth="md"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            border: "1px solid rgba(0, 0, 0, .3)"
          }}>
            <div className={mystyle.menuRoot}>
              
              <AppBar position="static" style={{
                backgroundColor:"rgba(197, 198, 208, .3)",
                backgroundImage: "linear-gradient(to right, #fff, rgba(197, 198, 208, .5))"
                }}>
                <Toolbar style={{marginTop:'5px', marginBottom:'5px'}}>
                    <LogoSmall2/>
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                    <div>
                        <div> <p style={{color:'#594D5B' }}> {intl.formatMessage({id:"tab.called.report.called.number"})} </p> </div>
                        <div> <p style={{color:'black' }}>  {this.state.calledId} </p> </div>
                        <div><p style={{color:'#594D5B' }}> {intl.formatMessage({id:"tab.called.report.technician.name"})}</p> </div>
                        <div ><p style={{color:'black'  }}> {this.state.technical}</p> </div>
                 
                    </div>
                </Toolbar>
              </AppBar>
            </div>
          <CssBaseline />
          <div className={mystyle.menuRoot}
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                marginBottom: "15px"
              }}>
          <Grid container xs justify="flex-end" spacing={3} style={{marginTop:'25px'}}>
            <Grid item>
              <Button 
                variant="contained"
                color='primary' 
                onClick={this.pdfSubmit}
              >
                {intl.formatMessage({id:"download"})}
              </Button>
            </Grid>

          </Grid>
          <div className={classes.paper} style={{marginTop:'10px'}} >
            <Avatar className={classes.avatar}>
              {" "}
              <AssignmentIcon />{" "}
            </Avatar>
            <Typography component="h1" variant="h5">
              {" "}
              {intl.formatMessage({id:"call report"})}{" "}
            </Typography>
          </div>
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                  <h4  >{intl.formatMessage({id:"general.data"})}</h4>
                  <Divider />
                  <Grid container spacing={3}  style={{marginTop: '20px', border : '1px solid #e6e6e6', borderRadius:'10px'}}>
                      <Grid item xs={3} style={mystyle.containerBorder} >
                        <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"tab.called.report.username"})}</h4>
                        <h4 style={mystyle.dataFont}>{this.state.user}</h4>
                      </Grid>
                    
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"address"})}</h4>
                          {this.addresses}
                      </Grid>
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"menu.categories"})}</h4>
                          <h4 style={mystyle.dataFont}>{this.state.category}</h4>
                      </Grid>
                      <Grid item xs={3} style={mystyle.containerBorder}>
                          <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"status.of.called"})}</h4>
                          <h4 style={mystyle.dataFont}>{this.state.status}</h4>
                      </Grid>
                  </Grid>
              </div>

              <div style={{marginBottom: '50px'}}>
                <h4 >{intl.formatMessage({id:"called.list.data.edit.called.title"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border : '1px solid #e6e6e6', borderRadius:'10px'}}>
                    <Grid item xs={12}  >
                      <h4 style={mystyle.dataFont}>{this.state.title}</h4>
                    </Grid>
                </Grid>
              </div>

              <div style={{marginBottom: '50px'}}>
                <h4 >{intl.formatMessage({id:"description"})}</h4>
                <Divider />
                
                <Grid container spacing={3}  style={{marginTop: '20px', marginBottom: '20px' }}>
                  <Grid item xs={12}  >
                    <Typography>
                      <FormattedMessage id="description" />
                    </Typography>
                    <ReactQuill name="description"
                      value={this.state.description || ""}
                      style={{ height: 200 }}
                      modules={this.reactQuillModules}
                      formats={this.reactQuillFormats}
                      fullWidth
                      readOnly={true}
                      required
                      InputLabelProps={{ shrink: this.state.description && this.state.description.length > 0 ? true : false }}
                      variant="outlined"
                      id="outlined-multiline-static" />
                  </Grid>
                </Grid>
              </div>
              { this.state.base64image.length > 0 && (
              <div style={{marginBottom:'40px'}}>
                  <h4 >{intl.formatMessage({id:"user.signature"})}</h4>
                  <Grid container spacing={3}  style={{marginTop: '20px'}}>
                     <Grid item xs={12} style={mystyle.containerBorder} >
                        <img id ="attachment" width="170px" src={this.state.base64image}/>
                     </Grid>
                     { this.state.latitude.length > 0 && (
                      <Grid item xs={3}  >
                        <h4 style={mystyle.subtitleFont}>Localização da assinatura</h4>
                        {
                          this.state.latitude.length > 0 && this.state.longitude.length > 0 && (
                            <h4 style={mystyle.dataFont}>({this.state.latitude}, {this.state.longitude}) {this.state.address}</h4>
                          )
                        }
                     </Grid>
                     )}
                     <Grid item xs={12} style={mystyle.containernoBorder} >
                        <h4 style={mystyle.subtitleFont}>Assinatura responsável</h4>
                        <h4 style={mystyle.dataFont}>{ this.state.signatureName && this.state.signatureName.length > 0 ? this.state.signatureName : this.state.user }</h4>
                     </Grid>
                     { (this.state.observations && this.state.observations.length > 0) && (<Grid item xs={12} style={mystyle.containernoBorder} >
                        <h4 style={mystyle.subtitleFont}>{intl.formatMessage({id:"observations"})}</h4>
                        <h4 style={mystyle.dataFont}>{ this.state.observations}</h4>
                     </Grid>) }
                  </Grid>
              </div>
              )}
              <div style={{marginBottom:'40px'}}>
                  <h4 >{intl.formatMessage({id:"called.report.config.declaration"})}</h4>
                  <Grid container spacing={3}  style={{marginTop: '20px', marginLeft:'10px'}}>
                    {intl.formatMessage({id:"tab.called.report.term.agree"})} <a href="https://dpo.privacytools.com.br/policy-view/Pk6G3wvxj/3/policy/pt_BR?s=1640698153824" target="_blank">{intl.formatMessage({id:"useterm"})}</a>
                  </Grid>
              </div>
              
              <div style={{marginBottom:'40px'}}>
                  <h4 >{intl.formatMessage({id:"tab.called.report.due.date"})} </h4>
                  <Grid container spacing={3}  style={{marginTop: '20px', marginLeft:'10px'}}>
                    {this.state.calledFinish}
                  </Grid>
              </div>
          </div>

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      

      
      
    );
  }
}

CalledReportInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);;

//export default connect(mapStateToProps)(withStyles(Styles)(CalledReportInformation));
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledReportInformation))));