import React from 'react'
import { SvgIcon } from '@mui/material';

export default function AttachFileCheckIcon(props) {
  return (
    <SvgIcon {...props}>
      <path id="AttachFile" d="M16.5,6V17.5a4,4,0,0,1-8,0V5a2.5,2.5,0,0,1,5,0V15.5a1,1,0,0,1-2,0V6H10v9.5a2.5,2.5,0,0,0,5,0V5A4,4,0,0,0,7,5V17.5a5.5,5.5,0,0,0,11,0V6Z" transform="translate(-7 -1)"/>
      <path id="Check" d="M7.034,12.448l-2.7-2.7-.921.914,3.624,3.624L14.813,6.5,13.9,5.59Z" transform="translate(8.59 1.064)"/>
    </SvgIcon>
  );
}