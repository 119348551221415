
import Api2Talk from "../api2talk";

export const DeleteOption = async (id) => { 
  let data = [];
  await Api2Talk.delete(`channeltechinicalteam/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
