import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { QueuePlayNext, DeleteForever, Girl } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import Footer from '../../components/footer';
import 'react-phone-input-2/lib/material.css'
import { Link } from 'react-router-dom'
import KeyIcon from '@mui/icons-material/Key';
import * as componentActions from '../../redux/actions/securitys';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as userSessionActions from '../../redux/actions/user-session';
import { PapiroConsole } from '../../utils/papiroConsole';
import AddIcon from '@mui/icons-material/Add'
import MyMaterialTable from '../../components/table'
import Pagination from '@mui/material/Pagination'
import * as Service from '../../services/userapipermission.service'
import { Can } from '../../config/ability-context'
import { withRouter } from 'react-router-dom';
import { AbilityContext } from '../../config/ability-context'


class Security extends Component {


  constructor(props) {
    super(props);
    const { userSession, users } = this.props;


    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.state = {
      

      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      resultList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      

    };

    this.deleteItem = this.deleteItem.bind(this);
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }*/

  async componentDidMount() {
    await this.getItens()
  };

  getItens = async (page = 1) => {

    const intl = this.props.intl

    this.setState({ loading: true });

    let response = await Service.paginate(page)

    if (response && response.success) {
      let count = response.count;
      PapiroConsole.log("=== getItens ===")
      PapiroConsole.log(response)
      PapiroConsole.log(count)
      this.setState({ count: count });
      this.setPagination(count, page);

      const formattedList = response.data.map(item => {
        return {
            ...item,
            activeTranslation:  item.active === true ? intl.formatMessage({id:"yes"}) : intl.formatMessage({id:"no"}) ,
            days: item.permanent ? 'Nunca' : item.days 
        }
    });
      

      this.setState({ loading: false, resultList: formattedList });
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(response)
    }
  };

  onChangePaginate = (e, page) => {
    this.setState({ page: page });
    this.getItens(page);
  }
  setPagination(count, page) {

    let totalPage = Math.ceil(count / 10);
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10) {
          this.setState({ pageStart: 1, pageEnd: count });
        } else {
          this.setState({ pageStart: 1, pageEnd: 10 });
        }
      } else {
        let pageStart = ((page - 1) * 10) + 1;
        if (count >= page * 10) {
          this.setState({ pageStart: pageStart, pageEnd: page * 10 });
        } else {
          this.setState({ pageStart: pageStart, pageEnd: count });
        }
      }
    } else {
      this.setState({ totalPages: 0, page: 1, count: 0 });
    }

  }

  deleteItem = async (data, resolve) => {
    /*let response = await Service.deleteItem(data.id)
    if (response && response.success) {
        const intl = this.props.intl;
        this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            resultList: []
        });
        resolve();
        await this.getItens();
    } else {
        resolve();
        const intl = this.props.intl
        this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(response)
    }*/
};


  render() {
    const { classes, headerMenu, actionsHeaderMenu, users, intl } = this.props;
    const { user } = this.state;
    PapiroConsole.log("props aqui 1 ")
    PapiroConsole.log(this.props)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Avatar className={classes.avatar}>
                <KeyIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({ id: "access.keys" })}
              </Typography>
            </div>
            <Grid container className={classes.mb20} spacing={0}>
              <Button
                variant='contained'
                color='secondary'
                size='large'

                className={classes.button}
                startIcon={<AddIcon />}
                component={Link} {...{ to: `/${this.props.match.params.lang}/security-registration` }} >{<FormattedMessage id="add.access.key" />}
              </Button>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={8} />
            </Grid>

            <Can I='List' a='UserApiPermission'>
              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {

                  this.props.addCurrent(rowData)
                  this.props.history.push(`/${this.props.match.params.lang}/security-edit`)
                }}
                columns={[
                  { title: intl.formatMessage({ id: "id" }), field: 'id' },
                  { title: intl.formatMessage({ id: "access.keys" }), field: 'accesskeyid' },
                  { title: intl.formatMessage({ id: "user" }), field: 'user.name' },
                  { title: intl.formatMessage({ id: "active" }), field: 'activeTranslation' },
                  { title: intl.formatMessage({ id: "access.token.expiration.days" }), field: 'days' },

                  
                  
                ]}
                data={this.state.resultList}
                 />
            </Can>
            <Can I='List' a='UserApiPermission'>
              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination
                    count={this.state.totalPages}
                    page={this.state.page}
                    onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

Security.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,

});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
    

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Security))));
Security.contextType = AbilityContext;
