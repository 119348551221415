export default function userOrganizations(state = [], action) {
  switch (action.type) {
    case 'ADD_USER_ORGANIZATIONS':
      return {
        userorganizations: action.userorganizations,
      }
    default:
      return state;
  }
}
