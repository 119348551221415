import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton } from '@mui/material';
import { QueuePlayNext, DeleteForever, AttachFile } from '@mui/icons-material';
import ImageList from '@mui/material/ImageList'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import * as Service from '../../services/config.service';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import SimpleCheckBox from '../../components/checkbox/check';
import { evaluateSession } from '../../utils/sessionUtils'
import { editItem, deleteAttachment } from '../../services/calledreportconfig.service'
import * as OrganizationChecklistService from '../../services/organizationchecklist.service';
import * as OrganizationFieldBLockService from '../../services/organizationfieldreportblocks.service'
import * as componentActions from '../../redux/actions/calledreportconfigs'
import { bindActionCreators } from 'redux'
import { PapiroConsole } from '../../utils/papiroConsole';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd'
import './styles.css'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SimpleSelect from '../../components/select/simple'
import { DndContainer } from './components/DnDContainer'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import OrganizationFieldBlockItemDialog from './components/organizationFieldBlockItem'
import * as FileService from '../../services/attachment.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';

class CalledReportConfigEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, calledreportconfigs } = this.props;

    evaluateSession(userSession)

    if(!calledreportconfigs || (calledreportconfigs && !calledreportconfigs.calledreportconfig)) {
      var l = this.props.match.params.lang
      document.location.href=`/${l}/called-report-configs`
    }

    this.globalAdm = false;
    this.isClient = true;

    userSession.user.userrole.map((item, key) => {
      if(item.role.roletype !=3)
        this.isClient = false
      if(item.role.roletype ==0)
        this.globalAdm =true
    });

    this.finalSpaceCharacters = [
      {
        id: 'gary',
        name: 'Gary Goodspeed',
        thumb: '/images/gary.png'
      },
      {
        id: 'cato',
        name: 'Little Cato',
        thumb: '/images/cato.png'
      },
      {
        id: 'kvn',
        name: 'KVN',
        thumb: '/images/kvn.png'
      },
      {
        id: 'mooncake',
        name: 'Mooncake',
        thumb: '/images/mooncake.png'
      },
      {
        id: 'quinn',
        name: 'Quinn Ergon',
        thumb: '/images/quinn.png'
      }
    ]

    this.initialState = {
      id: calledreportconfigs.calledreportconfig.id,
      updatedUser: userSession.user.id,
      updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      title: calledreportconfigs.calledreportconfig.title,
      declaration: calledreportconfigs.calledreportconfig.declaration,
      displaymaterialsused: calledreportconfigs.calledreportconfig.displaymaterialsused,
      displaychecklist: calledreportconfigs.calledreportconfig.displaychecklist,
      displayinitialchecklist: calledreportconfigs.calledreportconfig.displayinitialchecklist,
      displayorganizationfields: calledreportconfigs.calledreportconfig.displayorganizationfields,
      displayadditionalcosts: calledreportconfigs.calledreportconfig.displayadditionalcosts,
      displaydisplacement: calledreportconfigs.calledreportconfig.displaydisplacement,
      OrganizationId: calledreportconfigs.calledreportconfig.organization,
      OrganizationText: '',
      image: calledreportconfigs.calledreportconfig.image,
      imageid: calledreportconfigs.calledreportconfig.imageid
    }

    this.organizationFieldBlockInitialState = {
      title: '',
      active: false,
      sequence: 0,
      organizationId: calledreportconfigs.calledreportconfig.organization,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    }

    this.organizationFieldBlockTitleInitialState = {
      active: false,
      sequence: 0,
      organizationFieldId: 0,
      organizationFieldBlockId: 0,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    }

    this.organizationFieldBlockEditInitialState = {
      id: 0,
      active: false,
      sequence: 0,
      organizationFieldId: 0,
      organizationFieldBlockId: 0,
      updatedUser: userSession.user.id,
      updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    }

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      TimeZoneList: [],
      FormatDateList: [],
      FileList: [],
      file: [],
      OrganizationList: [],
      finalSpaceCharacters: this.finalSpaceCharacters,
      allOrganizationFieldBlockList: [],
      organizationFieldBlockList: [],
      organizationFieldBlockItemList: [],
      addOrganizationFieldBlockDialogOpen: false,
      addOrganizationFieldBlock: this.organizationFieldBlockInitialState,
      configureOrganizationFieldBlockDialogOpen: false,
      configureOrganizationFieldBlock: null,
      editOrganizationFieldBlockDialogOpen: false,
      editOrganizationFieldBlock: this.organizationFieldBlockEditInitialState,
      deleteOrganizationFieldBlockDialogOpen: false,
      allOrganizationFieldBlockOpen: false,
      allOrganizationFieldBlockDialogType: 0,
      searchValueListOrganizationFieldBlock: '',
      deleteOrganizationFieldBlockId: 0,
      deleteOrganizationFieldBlockTitle: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.newchangeValues = this.newchangeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.openAddOrganizationFieldBlockDialog = this.openAddOrganizationFieldBlockDialog.bind(this)
    this.closeAddOrganizationFieldBlockDialog = this.closeAddOrganizationFieldBlockDialog.bind(this)
    this.openConfigureOrganizationFieldBlockDialog = this.openConfigureOrganizationFieldBlockDialog.bind(this)
    this.closeConfigureOrganizationFieldBlockDialog = this.closeConfigureOrganizationFieldBlockDialog.bind(this)
    this.openEditOrganizationFieldBlockDialog = this.openEditOrganizationFieldBlockDialog.bind(this)
    this.closeEditOrganizationFieldBlockDialog = this.closeEditOrganizationFieldBlockDialog.bind(this)
    this.openDeleteOrganizationFieldBlockDialog = this.openDeleteOrganizationFieldBlockDialog.bind(this)
    this.closeDeleteOrganizationFieldBlockDialog = this.closeDeleteOrganizationFieldBlockDialog.bind(this)
    this.addOrganizationFieldBlock = this.addOrganizationFieldBlock.bind(this)
    this.getOrganizationFieldBLocksByOrganizationId = this.getOrganizationFieldBLocksByOrganizationId.bind(this)
    this.getAllOrganizationFieldBLocksByOrganizationId = this.getAllOrganizationFieldBLocksByOrganizationId.bind(this)
    this.handleInputChangeAddOrganizationFieldBlock = this.handleInputChangeAddOrganizationFieldBlock.bind(this)
    this.changeValuesAddOrganizationFieldBlock = this.changeValuesAddOrganizationFieldBlock.bind(this)
    this.openAllOrganizationFieldBlock = this.openAllOrganizationFieldBlock.bind(this)
    this.closeAllOrganizationFieldBlockOpen = this.closeAllOrganizationFieldBlockOpen.bind(this)
    this.onFieldChangetOrganizationFieldBlock = this.onFieldChangetOrganizationFieldBlock.bind(this)
    this.searchFiltertOrganizationFieldBlock = this.searchFiltertOrganizationFieldBlock.bind(this)
    this.setEditStepOrganizationFieldBlock = this.setEditStepOrganizationFieldBlock.bind(this)
    this.setListStepOrganizationFieldBlock = this.setListStepOrganizationFieldBlock.bind(this)
    this.handleInputChangeEditOrganizationFieldBlock = this.handleInputChangeEditOrganizationFieldBlock.bind(this)
    this.changeValuesEditOrganizationFieldBlock = this.changeValuesEditOrganizationFieldBlock.bind(this)
    this.editOrganizationFieldBlock = this.editOrganizationFieldBlock.bind(this)
    this.deleteOrganizationFieldBlock = this.deleteOrganizationFieldBlock.bind(this)
    this.setDeleteStepOrganizationFieldBlock = this.setDeleteStepOrganizationFieldBlock.bind(this)
    this.updateOrderOrganizationFieldBlock = this.updateOrderOrganizationFieldBlock.bind(this)
    this.setOrganizationFieldBlockList = this.setOrganizationFieldBlockList.bind(this)
    this.setErrorMessage = this.setErrorMessage.bind(this)
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload")
      this.componentDidMount()
  }    

  componentDidMount() {
      this.getOrganizationFieldBLocksByOrganizationId()
  }

  setErrorMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    })
  }

  setOrganizationFieldBlockList = (list) => {
    this.setState({ organizationFieldBlockList: list })
  }

  openAddOrganizationFieldBlockDialog = _ => {
    PapiroConsole.log("=== openAddOrganizationFieldBlockDialog ===")
    PapiroConsole.log(this.state.addOrganizationFieldBlock)
    this.setState({ addOrganizationFieldBlockDialogOpen: true })
  }
  
  closeAddOrganizationFieldBlockDialog = _ => this.setState({ addOrganizationFieldBlockDialogOpen: false })

  openConfigureOrganizationFieldBlockDialog = async (item) => {
    PapiroConsole.log("=== openConfigureOrganizationFieldBlockDialog ===")
    PapiroConsole.log(item)
    
    let blockItem = {
      ...item,
      name: item.title ? item.title : ''
    }

    PapiroConsole.log(blockItem)

    this.setState({
      configureOrganizationFieldBlockDialogOpen: true,
      configureOrganizationFieldBlock: blockItem
    }, () => {
      PapiroConsole.log("configureOrganizationFieldBlock")
      PapiroConsole.log(this.state.configureOrganizationFieldBlock)
    })
  }

  closeConfigureOrganizationFieldBlockDialog = _ => {
    this.setState({
      configureOrganizationFieldBlockDialogOpen: false,
      configureOrganizationFieldBlock: null
    })
  }

  openEditOrganizationFieldBlockDialog = (item) => {
    this.setState({
      editOrganizationFieldBlockDialogOpen: true,
      editOrganizationFieldBlock: item
    })
  }
  
  closeEditOrganizationFieldBlockDialog = _ => {
    this.setState({
      editOrganizationFieldBlockDialogOpen: false,
      editOrganizationFieldBlock: null
    })
  }

  openDeleteOrganizationFieldBlockDialog = (id) => this.setState({ deleteOrganizationFieldBlockDialogOpen: true })
  
  closeDeleteOrganizationFieldBlockDialog = _ => this.setState({ deleteOrganizationFieldBlockDialogOpen: false })

  addOrganizationFieldBlock = async _ => {
    const intl = this.props.intl
    
    const { addOrganizationFieldBlock } = this.state

    this.setState({ loading: true })

    if (addOrganizationFieldBlock == null || (addOrganizationFieldBlock && (addOrganizationFieldBlock.organizationId == null || addOrganizationFieldBlock.organizationId == 0 || (addOrganizationFieldBlock.organizationId && addOrganizationFieldBlock.organizationId.id == 0)) )) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
      });
    }
    if (addOrganizationFieldBlock == null || (addOrganizationFieldBlock && (addOrganizationFieldBlock.title == null || addOrganizationFieldBlock.title.length == 0))) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.title"})
      });
    } else if (addOrganizationFieldBlock.title == null || addOrganizationFieldBlock.title.length > 255) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.report.config.registration.title.character.limit"})
      });
    }
    if (addOrganizationFieldBlock == null || (addOrganizationFieldBlock != null && addOrganizationFieldBlock.active == null)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.active.field"})
      });
    }

    var data = new FormData();

    data.append('organizationid', addOrganizationFieldBlock.organizationId.id);
    data.append('title', addOrganizationFieldBlock.title)
    data.append('active', addOrganizationFieldBlock.active)
    data.append('sequence', addOrganizationFieldBlock.sequence)
    data.append('createduser', addOrganizationFieldBlock.createdUser)

    let config = { headers: { "Content-Type": "multipart/form-data" } }

    let result = await OrganizationFieldBLockService.addItem(data, config)
    if (result && result.success) {
      this.setState({
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({id:"add.success"}),
        addOrganizationFieldBlock: this.organizationFieldBlockInitialState,
        addOrganizationFieldBlockDialogOpen: false
      });
      await this.getOrganizationFieldBLocksByOrganizationId()
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  editOrganizationFieldBlock = async () => {
    
    const { userSession, intl } = this.props;
    const { editOrganizationFieldBlock } = this.state

    this.setState({ loading: true })

    if (editOrganizationFieldBlock == null || (editOrganizationFieldBlock && (editOrganizationFieldBlock.organizationId == null || editOrganizationFieldBlock.organizationId == 0 || (editOrganizationFieldBlock.organizationId && editOrganizationFieldBlock.organizationId.id == 0)) )) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
      });
    }
    if (editOrganizationFieldBlock == null || (editOrganizationFieldBlock && (editOrganizationFieldBlock.title == null || editOrganizationFieldBlock.title.length == 0))) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.title"})
      });
    } else if (editOrganizationFieldBlock.title == null || editOrganizationFieldBlock.title.length > 255) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.report.config.registration.title.character.limit"})
      });
    }
    if (editOrganizationFieldBlock == null || (editOrganizationFieldBlock != null && editOrganizationFieldBlock.active == null)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.active.field"})
      });
    }

    var data = new FormData();

    data.append('id', editOrganizationFieldBlock.id);
    data.append('organizationid', editOrganizationFieldBlock.organizationId.id);
    data.append('title', editOrganizationFieldBlock.title)
    data.append('active', editOrganizationFieldBlock.active)
    data.append('sequence', editOrganizationFieldBlock.sequence)
    data.append('updateduser', userSession.user.id)

    let config = { headers: { "Content-Type": "multipart/form-data" } }

    let result = await OrganizationFieldBLockService.editItem(data, config)
    if (result && result.success) {
      this.setState({
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({id:"edit.success"}),
        editOrganizationFieldBlock: this.organizationFieldBlockEditInitialState,
        allOrganizationFieldBlockOpen: false
      });
      await this.getOrganizationFieldBLocksByOrganizationId()
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  updateOrderOrganizationFieldBlock = async () => {
    let list = this.state.organizationFieldBlockList

    if (list && list.length > 0) {
      const intl = this.props.intl

      this.setState({ loading: true })

      let ids = ''
      let orders = ''

      list.forEach((item, i) => {
        ids = ids.length === 0 ? `${item.id}` : `${ids},${item.id}`
        orders = orders.length === 0 ? `${i+1}` : `${orders},${i+1}`
      })

      var data = new FormData();

      data.append('ids', ids);
      data.append('order', orders);

      let config = { headers: { "Content-Type": "multipart/form-data" } }

      PapiroConsole.log("=== updateOrderOrganizationFieldBlock ===")
      PapiroConsole.log(ids)
      PapiroConsole.log(orders)

      let result = await OrganizationFieldBLockService.updateOrder(data, config)
      if (result && result.success) {
        this.setState({
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({id:"save.success"}),
          deleteOrganizationFieldBlockId: 0,
          deleteOrganizationFieldBlockTitle: '',
          allOrganizationFieldBlockOpen: false
        });
        await this.getOrganizationFieldBLocksByOrganizationId()
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }

      this.setState({ loading: false })
    }
  }

  deleteOrganizationFieldBlock = async () => {
    this.setState({ loading: true })

    const intl = this.props.intl
    const { deleteOrganizationFieldBlockId } = this.state

    if (deleteOrganizationFieldBlockId && deleteOrganizationFieldBlockId > 0) {
      let result = await OrganizationFieldBLockService.deleteItem(deleteOrganizationFieldBlockId)
      if (result && result.success) {
        this.setState({
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({id:"delete.sucess"}),
          deleteOrganizationFieldBlockId: 0,
          deleteOrganizationFieldBlockTitle: '',
          allOrganizationFieldBlockOpen: false
        });
        await this.getOrganizationFieldBLocksByOrganizationId()
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    } else {

    }
  }

  openAllOrganizationFieldBlock = async () => {
    this.setState({
      allOrganizationFieldBlockOpen: true,
      allOrganizationFieldBlockDialogType: 1
    })

    await this.getAllOrganizationFieldBLocksByOrganizationId();
  }

  closeAllOrganizationFieldBlockOpen = () => {
    this.setState({
      allOrganizationFieldBlockOpen: false,
      allOrganizationFieldBlockDialogType: 0,
      allOrganizationFieldBlockList: [],
      editOrganizationFieldBlock: this.organizationFieldBlockEditInitialState,
      deleteOrganizationFieldBlockId: 0,
      deleteOrganizationFieldBlockTitle: '',
    })
  }

  setEditStepOrganizationFieldBlock = (item) => {
    this.setState({
      allOrganizationFieldBlockOpen: true,
      allOrganizationFieldBlockDialogType: 2,
      editOrganizationFieldBlock: {
        ...item,
        organizationId: item.organization
      }
    })
  }

  setDeleteStepOrganizationFieldBlock = (id, title = '') => {
    this.setState({
      allOrganizationFieldBlockOpen: true,
      allOrganizationFieldBlockDialogType: 3,
      deleteOrganizationFieldBlockId: id,
      deleteOrganizationFieldBlockTitle: title,
    })
  }

  setListStepOrganizationFieldBlock = () => {
    this.setState({
      allOrganizationFieldBlockDialogType: 1,
      editOrganizationFieldBlock: this.organizationFieldBlockEditInitialState
    })
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  getOrganizations = async () => {
    this.setState({ loading: true });
    var result = await OrganizationChecklistService.getOrganizationsSimple();

    if (result.success) {
        this.setState({ loading: false, OrganizationList: result.data });

    } else {
      PapiroConsole.log("=== getOrganizations ===")
      PapiroConsole.log(result)
        const intl = this.props.intl;
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
    }
  };

  getOrganizationsById = async (id) => {

      this.setState({ loading: true });
      var result = await OrganizationChecklistService.getOrganizationsByIdLight(id);
      if (result.success) {
          this.setState(prevState => ({
            loading: false,
            OrganizationList: [result.data],
            item: {
              ...prevState.item,
              OrganizationId: result.data,
              OrganizationText: result.data.name,
            }
          }));
          //await this.updateOrganization(id);
      } else {
        PapiroConsole.log("=== getOrganizationsById ===")
        PapiroConsole.log(result)
          const intl = this.props.intl;

          this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });

          Api.kickoff(result);
      }
  };

  getOrganizationFieldBLocksByOrganizationId = async () => {
    this.setState({ loading: true })

    PapiroConsole.log("=== getOrganizationFieldBLocksByOrganizationId DATA ===")
    PapiroConsole.log(this.state.item)

    if (this.state.item && this.state.item.OrganizationId && this.state.item.OrganizationId.id && this.state.item.OrganizationId.id > 0) {
      var result = await OrganizationFieldBLockService.getActiveByOrganizationId(this.state.item.OrganizationId.id)
      if (result.success) {
        this.setState({
          loading: false,
          organizationFieldBlockList: result.data
        });
      } else {
        PapiroConsole.log("=== getOrganizationFieldBLocksByOrganizationId ===")
        PapiroConsole.log(result)
        const intl = this.props.intl;

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });

        Api.kickoff(result);
      }
    } else {
      this.setState({ loading: false })
    }
  }

  getAllOrganizationFieldBLocksByOrganizationId = async () => {
    this.setState({ loading: true })

    PapiroConsole.log("=== getAllOrganizationFieldBLocksByOrganizationId DATA ===")
    PapiroConsole.log(this.state.item)

    if (this.state.item && this.state.item.OrganizationId && this.state.item.OrganizationId.id && this.state.item.OrganizationId.id > 0) {
      const intl = this.props.intl

      var result = await OrganizationFieldBLockService.getByOrganizationId(this.state.item.OrganizationId.id)
      if (result.success) {
        let formattedList = result.data.map(item => {
          return {
            ...item,
            organizationname: item.organization.name,
            activetext: item.active ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" }),
            data: item,
            editdata: item,
          }
        })
        
        this.setState({
          loading: false,
          allOrganizationFieldBlockList: formattedList,
          searchValueListOrganizationFieldBlock: ''
        });
      } else {
        PapiroConsole.log("=== getAllOrganizationFieldBLocksByOrganizationId ===")
        PapiroConsole.log(result)

        this.setState({
          searchValueListOrganizationFieldBlock: '',
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });

        Api.kickoff(result);
      }
    } else {
      this.setState({ loading: false, searchValueListOrganizationFieldBlock: '' })
    }
  }

  async newchangeValues(stateName, value) {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleInputChangeAddOrganizationFieldBlock = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      addOrganizationFieldBlock: {
        ...prevState.addOrganizationFieldBlock,
        [name]: value,
      }
    }))
  }

  handleInputChangeEditOrganizationFieldBlock = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState(prevState => ({
      editOrganizationFieldBlock: {
        ...prevState.editOrganizationFieldBlock,
        [name]: value,
      }
    }))
  }

  handleSubmit = async e => {
    e.preventDefault();
    const intl = this.props.intl;
    if(this.state.item.OrganizationId == null || this.state.item.OrganizationId == 0 || (this.state.item.OrganizationId && this.state.item.OrganizationId.id && this.state.item.OrganizationId.id == 0)) {
      this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
      });
      return false
    }
    if (this.state.item.title == null || this.state.item.title.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.title"})
      });
      return false
    }
    if (this.state.item.declaration == null || this.state.item.declaration.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.report.config.registration.missing.declaration.field"})
      });
      return false
    }

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('organizationid') > -1) {
          data.append(key, this.state.item[key].id);
        } else if (key.toString().toLowerCase().indexOf('title') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('declaration') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('imageid') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : 0);
        } else if (key.toString().toLowerCase().indexOf('displaymaterialsused') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('displaychecklist') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('displayinitialchecklist') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('displayorganizationfields') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('displayadditionalcosts') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('displaydisplacement') > -1) {
          data.append(key, typeof this.state.item[key] !== 'undefined' ? this.state.item[key] : false);
        } else if (key.toString().toLowerCase().indexOf('createdUser') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('createdAt') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('savetoallorganizationsingroup') > -1) {
          data.append(key, this.state.item[key]);
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      data.append('filesUpload', this.state.file)

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

     
      let result = await editItem(data, config)
      if (result && result.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({id:"edit.success"}),
          item: result.data,
          file: result.data.image,
          files: []
        });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });
    if (stateName === "displaychecklist") {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value,
            displayinitialchecklist: value === false ? false : prevState.item.displayinitialchecklist
          }
        }))
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value
          }
        }))
      }
    this.setState({ loading: false });
  };

  changeValuesAddOrganizationFieldBlock = (stateName, value, text = '') => {
    this.setState({ loading: true });
    
    this.setState(prevState => ({
      addOrganizationFieldBlock: {
        ...prevState.addOrganizationFieldBlock,
        [stateName]: value,
      }
    }))

    this.setState({ loading: false });

  };

  changeValuesEditOrganizationFieldBlock = (stateName, value, text = '') => {
    this.setState(prevState => ({
      editOrganizationFieldBlock: {
        ...prevState.editOrganizationFieldBlock,
        [stateName]: value,
      }
    }))
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  validateImagesAtachments = (files) => {
    const intl = this.props.intl;
    if(files.length > 1) {
      this.setState({
        file: [],
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"checklist.modal.select.image.error"}),
      })
      return
    }
    for (const file of files) {
      if (!file.type.includes("image")) {
        this.setState({
          file: [],
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"checklist.modal.image.field.error"}),
        })
        return
      }
    }
    
    const image = new Image();
    image.addEventListener('load', () => {
      this.setState({ file: files[0] })
    });
    image.src = URL.createObjectURL(files[0])
  }

  removeFileFromDropzone = () => {
    this.setState({ file: [] })
  }

  deleteDocument = async (id) => {
    const intl = this.props.intl
    this.setState({ loading: true });
    
    var result = await deleteAttachment(id)
    if (result && result.success) {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          image: result.data.image,
          imageid: 0
        },
        loading: false,
        openNotification: true,
        notificationVariant: "success",
        notificationMessage: intl.formatMessage({id:"called.report.config.registration.image.deleted"})
      }));
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getCalledFile = async (attachment, fileName) => {
    //let fileNameNew = fileName.replace("\/wwwroot\/", "")
    //window.open(`${Api.apiurlbase()}${fileNameNew}`);


	const intl = this.props.intl;
	this.setState({ loading: true });
	
	
	let url = await FileService.getFile(attachment.hirerid,attachment.id,fileName,attachment.filerepositorymethod); 
	//alert(JSON.stringify(url.data));
	this.setState({ loading: false });
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}	


  }

  useStyles = () => {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

  handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(this.state.finalSpaceCharacters)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    this.setState({ finalSpaceCharacters: items })
  }

  onFieldChangetOrganizationFieldBlock = (e) => {
    PapiroConsole.log("=== onFieldChangetOrganizationFieldBlock ===")
    PapiroConsole.log(e.target)
    this.setState({ [e.target.name]: e.target.value })
  }

  searchFiltertOrganizationFieldBlock = async _ => {
    const intl = this.props.intl;
    this.setState({ loading: true })
      if(this.state.searchValueListOrganizationFieldBlock && this.state.searchValueListOrganizationFieldBlock.length > 0) {

        let formData = new FormData();
            formData.append("organizationid", this.state.item.OrganizationId.id)
            formData.append("title", this.state.searchValueListOrganizationFieldBlock)

        var result = await OrganizationFieldBLockService.getAllWithFilter(formData)
        if(result && result.success) {
          if(result.data != null) {
            let formattedList = result.data.map(item => {
              return {
                ...item,
                organizationname: item.organization.name,
                activetext: item.active ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" }),
                data: item,
                editdata: item,
              }
            })

            PapiroConsole.log("=== searchFiltertOrganizationFieldBlock ===")
            PapiroConsole.log(formattedList)

            this.setState({
              loading: false,
              allOrganizationFieldBlockList: formattedList,
              searchValueListOrganizationFieldBlock: ''
            })
          }
        } else {
          this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"called.list.notification.insert.search.value"})
      })
      }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, userSession } = this.props;
    const { item, addOrganizationFieldBlock, editOrganizationFieldBlock } = this.state;
    const intl = this.props.intl;
    const classes2 = this.useStyles();

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={this.state.addOrganizationFieldBlockDialogOpen}
            onClose={this.closeAddOrganizationFieldBlockDialog}
            aria-labelledby="add-organizationfield-block-dialog-title">
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} id="add-organizationfield-block-dialog-title">
              <FormattedMessage id="called.report.config.organization.field.add.title" />
              <ClearIcon onClick={this.closeAddOrganizationFieldBlockDialog} style={{ float: 'right', cursor: 'pointer' }} />
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={0} style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={12}>
                  <SimpleAutoComplete
                    label={intl.formatMessage({ id: 'organization' })}
                    name='organizationid'
                    options={this.state.OrganizationList}
                    stateName='OrganizationId'
                    changeSelect={this.changeValuesAddOrganizationFieldBlock}
                    selected={addOrganizationFieldBlock.organizationId}
                    disabled
                    required />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <TextField
                    name="title"
                    variant="outlined"
                    type="title"
                    fullWidth
                    id="title"
                    label={intl.formatMessage({ id: 'title' })}
                    value={addOrganizationFieldBlock.title}
                    onChange={this.handleInputChangeAddOrganizationFieldBlock}
                    required />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <SimpleCheckBox
                    label={intl.formatMessage({ id: 'active' })}
                    name='active'
                    stateName='active'
                    changeSelect={this.changeValuesAddOrganizationFieldBlock}
                    selected={addOrganizationFieldBlock.active}
                    required />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeAddOrganizationFieldBlockDialog}
                variant="contained"
                color="secondary"
                autoFocus >
                <FormattedMessage id="cancel" />
              </Button>
              <Button
                variant="contained"
                onClick={this.addOrganizationFieldBlock}
                color="primary" >
                <FormattedMessage id="insert" />
              </Button>
            </DialogActions>
          </Dialog>

          <OrganizationFieldBlockItemDialog
            userId={userSession.user.id}
            open={this.state.configureOrganizationFieldBlockDialogOpen}
            organizationList={this.state.OrganizationList}
            onClose={this.closeConfigureOrganizationFieldBlockDialog}
            organization={item.OrganizationId}
            intl={intl}
            classes={classes}
            setErrorMessage={this.setErrorMessage}
            organizationFieldBlock={this.state.configureOrganizationFieldBlock} />

          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={this.state.allOrganizationFieldBlockOpen}
            onClose={this.closeAllOrganizationFieldBlockOpen}
            aria-labelledby="all-organizationfield-block-dialog">
            <DialogTitle id="all-organizationfield-block-dialog">
              {this.state.allOrganizationFieldBlockDialogType == 1 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.add.title" /></Typography>)}
              {this.state.allOrganizationFieldBlockDialogType == 2 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.edit.title" /></Typography>)}
              {this.state.allOrganizationFieldBlockDialogType == 3 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.delete.title" /> : {this.state.deleteOrganizationFieldBlockTitle}</Typography>)}
              <ClearIcon onClick={this.closeAllOrganizationFieldBlockOpen} style={{ float: 'right', cursor: 'pointer' }} />
            </DialogTitle>
            <DialogContent>
              {this.state.allOrganizationFieldBlockDialogType == 1 && (<Grid container spacing={0} style={{ marginTop: 15 }}>
                <Grid xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <Grid xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                    <TextField type="text"
                      name="searchValueListOrganizationFieldBlock"
                      value={this.state.searchValueListOrganizationFieldBlock}
                      placeholder={intl.formatMessage({ id: "search" })}
                      onChange={this.onFieldChangetOrganizationFieldBlock} />
                  </Grid>
                  <Grid xs={12} sm={1}></Grid>
                  <Grid xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.button}
                      startIcon={<SearchIcon />}
                      onClick={this.searchFiltertOrganizationFieldBlock} >
                      {intl.formatMessage({id:"search"})}
                    </Button>
                  </Grid>
                  <Grid xs={12} sm={1}></Grid>
                  <Grid xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.button}
                      startIcon={<ClearIcon />}
                      onClick={this.getAllOrganizationFieldBLocksByOrganizationId} >
                      {intl.formatMessage({id:"clear"})}
                    </Button>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={12}
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    border: '3px solid #eee'
                  }} >
                  <div style={{ height: 400 }}>
                    <DataGrid
                      style={{ fontSize: 12, color: '#000' }}
                      className="DataGridStyleCallReportEdit"
                      rows={this.state.allOrganizationFieldBlockList}
                      rowsPerPageOptions={[5]}
                      columns={[
                        {
                          headerName: intl.formatMessage({ id: "delete" }),
                          field: 'data',
                          width: 120,
                          renderCell: (params) => (
                            <IconButton aria-label="delete" color="primary" onClick={() => this.setDeleteStepOrganizationFieldBlock(params.value.id, params.value.title)}>
                              <DeleteIcon />
                            </IconButton>
                          )
                        },
                        {
                          headerName: intl.formatMessage({ id: "edit" }),
                          field: 'editdata',
                          width: 120,
                          renderCell: (params) => (
                            <IconButton aria-label="edit" color="primary" onClick={() => this.setEditStepOrganizationFieldBlock(params.value)}>
                              <EditIcon />
                            </IconButton>
                          )
                        },
                        { headerName: intl.formatMessage({ id: "name" }), field: 'title', width: 400 },
                        { headerName: intl.formatMessage({ id: "organization" }), field: 'organizationname', width: 300 },
                        { headerName: intl.formatMessage({ id: "active" }), field: 'activetext', width: 120 },
                      ]} />
                  </div>
                </Grid>
              </Grid>)}
              {this.state.allOrganizationFieldBlockDialogType == 2 && (<Grid container spacing={0} style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={12}>
                  <SimpleAutoComplete
                    label={intl.formatMessage({ id: 'organization' })}
                    name='organizationid'
                    options={this.state.OrganizationList}
                    stateName='OrganizationId'
                    changeSelect={this.changeValuesEditOrganizationFieldBlock}
                    selected={editOrganizationFieldBlock.organizationId}
                    disabled
                    required />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <TextField
                    name="title"
                    variant="outlined"
                    type="title"
                    fullWidth
                    id="title"
                    label={intl.formatMessage({ id: 'title' })}
                    value={editOrganizationFieldBlock.title}
                    onChange={this.handleInputChangeEditOrganizationFieldBlock}
                    required />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <SimpleCheckBox
                    label={intl.formatMessage({ id: 'active' })}
                    name='active'
                    stateName='active'
                    changeSelect={this.changeValuesEditOrganizationFieldBlock}
                    selected={editOrganizationFieldBlock.active}
                    required />
                </Grid>
              </Grid>)}
              {this.state.allOrganizationFieldBlockDialogType == 3 && (<Grid container spacing={0} style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={12}>
                  <FormattedMessage style={{ fontSize: '1.3em' }} id="called.report.config.organization.field.block.confirm.delete" />
                </Grid>
              </Grid>)}
            </DialogContent>
            <DialogActions>
              {((this.state.allOrganizationFieldBlockDialogType == 2 || this.state.allOrganizationFieldBlockDialogType == 3) && this.state.allOrganizationFieldBlockList.length > 0) && (<Button
                onClick={this.setListStepOrganizationFieldBlock}
                variant="contained"
                color="secondary"
                autoFocus >
                  <FormattedMessage id="back" />
              </Button>)}
              {((this.state.allOrganizationFieldBlockDialogType == 2 || this.state.allOrganizationFieldBlockDialogType == 3) && this.state.allOrganizationFieldBlockList.length == 0) && (
                <Button
                onClick={this.closeAllOrganizationFieldBlockOpen}
                variant="contained"
                color="secondary"
                autoFocus >
                  <FormattedMessage id="cancel" />
                </Button>
              )}
              {this.state.allOrganizationFieldBlockDialogType == 1 && (<Button
                onClick={this.closeAllOrganizationFieldBlockOpen}
                variant="contained"
                color="secondary"
                autoFocus >
                <FormattedMessage id="cancel" />
              </Button>)}
              {(this.state.allOrganizationFieldBlockDialogType == 2) && (<Button
                variant="contained"
                color="primary"
                onClick={this.editOrganizationFieldBlock} >
                <FormattedMessage id="save" />
              </Button>)}
              {(this.state.allOrganizationFieldBlockDialogType == 3) && (<Button
                variant="contained"
                color="primary"
                onClick={this.deleteOrganizationFieldBlock} >
                <FormattedMessage id="confirm" />
              </Button>)}
            </DialogActions>
          </Dialog>

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="called.report.config.add.item" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({ id: "organization" })}
                        options={this.state.OrganizationList}
                        stateName='OrganizationId'
                        changeSelect={this.changeValues}
                        selected={item.OrganizationId}
                        required
                        disabled  />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="title"
                        variant="outlined"
                        type="title"
                        fullWidth
                        id="title"
                        label={intl.formatMessage({ id: 'title' })}
                        value={item.title}
                        onChange={this.handleInputChange}
                        required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="declaration"
                        variant="outlined"
                        type="declaration"
                        fullWidth
                        id="declaration"
                        label={intl.formatMessage({ id: 'called.report.config.declaration' })}
                        value={item.declaration}
                        onChange={this.handleInputChange}
                        required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.materials.used' })}
                        name="displaymaterialsused"
                        stateName="displaymaterialsused"
                        changeSelect={this.changeValues}
                        selected={item.displaymaterialsused}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.checklist' })}
                        name="displaychecklist"
                        stateName="displaychecklist"
                        changeSelect={this.changeValues}
                        selected={item.displaychecklist}
                      />
                    </Grid>
                    {this.state.item.displaychecklist === true && (<Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.initial.checklist' })}
                        name="displayinitialchecklist"
                        stateName="displayinitialchecklist"
                        changeSelect={this.changeValues}
                        selected={item.displayinitialchecklist}
                      />
                    </Grid>)}
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.organization.fields' })}
                        name="displayorganizationfields"
                        stateName="displayorganizationfields"
                        changeSelect={this.changeValues}
                        selected={item.displayorganizationfields}
                      />
                    </Grid>
                    {item.displayorganizationfields && (<Grid item xs={12} sm={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="organization-field-configuration"
                          id="organization-field-configuration" >
                          <Typography><FormattedMessage id="called.report.config.organization.field.configuration" /></Typography>
                          <Grid item xs={2} sm={2} style={{ textAlign: "right", alignSelf: "stretch" }} >
                            <Tooltip title={<h5><FormattedMessage id='called.report.config.organization.field.configuration.info' /></h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4} style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
                            <Grid item xs={8} md={8} sm={12}>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={this.openAddOrganizationFieldBlockDialog} >
                                <FormattedMessage id="called.report.config.add.organization.field.block" />
                              </Button>
                            </Grid>
                            <Grid item xs={2} md={2} sm={12} >
                              <Tooltip title={<h5><FormattedMessage id="called.report.config.organization.field.block.list.all" /></h5>}>
                                <IconButton aria-label='config-all-org-field-blocks'
                                  onClick={this.openAllOrganizationFieldBlock} style={{ backgroundColor: '#277AFE', color: '#FFF' }} >
                                  <FormatListBulletedIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={2} md={2} sm={12} >
                              <Tooltip title={<h5><FormattedMessage id="called.report.config.organization.field.block.list.save.order" /></h5>}>
                                <IconButton aria-label='config-save-org-field-block'
                                  onClick={this.updateOrderOrganizationFieldBlock} style={{ backgroundColor: '#3f51b4', color: '#FFF' }} >
                                  <SaveIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <DndContainer
                              organizationFieldBlockList={this.state.organizationFieldBlockList}
                              openConfigureOrganizationFieldBlockDialog={this.openConfigureOrganizationFieldBlockDialog}
                              openEditOrganizationFieldBlockDialog={this.setEditStepOrganizationFieldBlock}
                              openDeleteOrganizationFieldBlockDialog={this.setDeleteStepOrganizationFieldBlock}
                              setOrganizationFieldBlockList={this.setOrganizationFieldBlockList} />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>)}
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.additional.costs' })}
                        name="displayadditionalcosts"
                        stateName="displayadditionalcosts"
                        changeSelect={this.changeValues}
                        selected={item.displayadditionalcosts}
                      />
                    </Grid>
                    {/*<Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: 'called.report.config.display.displacement' })}
                        name="displaydisplacement"
                        stateName="displaydisplacement"
                        changeSelect={this.changeValues}
                        selected={item.displaydisplacement}
                      />
                    </Grid>*/}
                  <Grid item xs={12} sm={12}>
                    <FormattedMessage id="called.report.config.image" />
                  </Grid>
                <Grid item xs={12} md={12} style={{ marginBottom: '12px' }}>
                  <section style={{ width: '100%', marginTop: '0', marginBottom: '10px' }}>
                    <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}} >
                      <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments([ ...acceptedFiles, ...this.state.file ])}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <AttachFileIcon  />
                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                <FormattedMessage  id="drag.image.or.click.to.select" />
                            </Typography>
                            
                          </div>
                        )}
                      </Dropzone>
                      {this.state.file && this.state.file.name && (
                        <div className={classes.dropzoneItem} key={this.state.file.name}>
                          {this.state.file.name}
                          <DeleteForever className={classes.deleteItem} onClick={() => { this.removeFileFromDropzone() }} />
                        </div>
                      )}
                    </div>
                  </section>
                </Grid>
                {this.state.item.image && this.state.item.image.path && (
                  <Grid item xs={12} md={12} spacing={0} style={{marginTop: "5px"}}>
                      <div className={classes.attachmentsBox}>
                        <label>{intl.formatMessage({id:"attachments"})}</label>
                        <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                          <ul>
                            <li key={this.state.item.image.path}>
                              <AttachFile /> <Button onClick={() => this.getCalledFile(this.state.item.image, this.state.item.image.path)}>{this.state.item.image.description}</Button>
                              <DeleteForeverIcon onClick={ () => this.deleteDocument(this.state.item.id) } style={{ cursor: "pointer" }}/>
                            </li>
                          </ul>
                        </ImageList>
                      </div>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                  <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        {<FormattedMessage id="save" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
CalledReportConfigEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calledreportconfigs: state.calledreportconfigs,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledReportConfigEdit)));
