import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelectDefault(props) {

  const { label, options, stateName, changeSelect, selected, required, disabled } = props;

  const classes = useStyles();
  const [option, setOption] = React.useState(selected ? selected : 0);

  const inputLabel = React.useRef(null);
  const [labelwidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    //setLabelWidth(inputLabel.current.offsetWidth);
    setOption(selected ? selected : 0);
  }, [selected]);

  const handleChange = event => {
    setOption(event.target.value);
    changeSelect(stateName, event.target.value);
  };

  return (
    <div>
      <FormControl required={required} variant="outlined" className={classes.formControl}           
        disabled={disabled} fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={option}
          onChange={handleChange}
          required
          input={<OutlinedInput />}
        >
          <MenuItem value={0}>
            <em> &nbsp; </em>
          </MenuItem>

          {options.map(item => {
            if(item.enable == false){
              return <MenuItem key={item.id} value={item.id} disabled={!item.enable} >{item.title ? item.title :  item.name}</MenuItem>
            }
            else{
                return <MenuItem key={item.id} value={item.id}>{item.title ? item.title :  item.name}</MenuItem>
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
}

SimpleSelectDefault.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.number,
  disabled : PropTypes.bool
};
