import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import AdditionalCalledCostItemsContent from './components/additional-called-cost-item/list';
import AdditionalCalledCostTypesContent from './components/additional-called-cost-type/list';
import AdditionalCalledCostReasonsContent from './components/additional-called-cost-reason/list';
import AdditionalCalledCostItemsTabContent from './index.content'
import * as componentActions from '../../redux/actions/calledlistdatas';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormattedMessage, injectIntl } from 'react-intl';

class AdditionalCalledCostItems extends Component {

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() { }

  render() {
    return (
      <Base props={this.props}>
        <AdditionalCalledCostItemsTabContent props={this.props} />
      </Base>
    );
  }
}

AdditionalCalledCostItems.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AdditionalCalledCostItems)));

AdditionalCalledCostItems.contextType = AbilityContext