import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';

export default function RadioButtonsGroup({seletected, options, changeValues, label, name}) {
  
  const handleChange = (event) => {

    changeValues(name, {id: event.target.value, name: event.target.name})

  };

  return (
    <FormControl component="fieldset">
      <Typography>{label}</Typography>
      <RadioGroup  name="row-radio-buttons-group" row value={seletected} onChange={handleChange}>
        {options && options.map(item => {
          return(
            <FormControlLabel name={item.id} value={item.name} control={<Radio />} label={item.name} />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}