import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../../styles/material-styles";
import { Grid } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { AbilityContext } from '../../../../config/ability-context';
import { injectIntl } from 'react-intl';
import Api2Talk from "../../../../services/api2talk";
import PropTypes from 'prop-types';
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete';
import * as ItemService from '../../../../services/2talk/channel'
import * as ItemServiceChannelAssociation from '../../../../services/2talk/channelphoneregistrationrequests'
import * as ItemRegistrationPhoneNumberService from '../../../../services/2talk/registrationphonenumber'
import { PapiroConsole } from "../../../../utils/papiroConsole";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import DialogGeneral from '../../../../components/dialog-general';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import * as ItemServiceChannel from '../../../../services/2talk/channel'




class PhoneAssociation extends Component {

  constructor(props) {
    super(props);
    const { channels } = this.props;
    this.selectedPhonesArray = [];

    //this.channelAssociateds = phoneregistrationrequests.phoneregistrationrequests.channelregistrationnumberrequests
    //pegar os canais ja existentes e exibir na lista -- depois ver um jeito melhor de fazer isso

    this.state = {
      id: channels.channel.id,
      phoneList: [],
      autocompleteInputValue: '',
      item: {
        selectedOptions: [],
      },
      DialogRemoveState: false,
      IdToDelete: { channelRegistrationNumberRequestId: 0, channelId: 0 },
      loadingGrid: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
  }



  async componentDidMount() {
    this.setState({ loadingGrid: true });
    await this.getPhoneList();
    await this.getPhonesAssociations();
    this.setState({ loadingGrid: false });
  }

  async getPhoneList() {
    const { getNotification, setLoadingState } = this.props;
    const response = await ItemRegistrationPhoneNumberService.getPhoneNumberList();
  
    if (response.success) {
      setLoadingState(false)
      this.setState({ phoneList: response.data });
    } else {
      const intl = this.props.intl;
      getNotification(false, true, 'error', response.data && response.data.response && response.data.response.data && response.data.response.data.errors && response.data.response.data.errors[0] ? response.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  async getPhonesAssociations() {
    const { getNotification, setLoadingState } = this.props;
    var result = await ItemServiceChannel.getPhonesAssociations(this.state.id)
    if (result.success) {
      setLoadingState(false)
      if (result.data && result.data.length > 0) {
        this.updateList(result.data)
      }
    } else {
      const intl = this.props.intl;
      getNotification(false, true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true });
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  checkValidation() {
    const { getNotification } = this.props;
    const count = this.state.item.selectedOptions.filter(element => element.isDefault).length;

    if (count > 1) {
      getNotification(false, true, 'error', "Não pode haver mais de uma opção marcada como padrão ")
      return false;
    }

    return true;
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { getNotification, setLoadingState } = this.props;
    setLoadingState(true)

    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let validation = this.checkValidation();
    if (validation) {
      if (Api2Talk == null) {
        getNotification(false, true, 'error', "Api de mensageria não configurada.")
        return;
      }
      this.state.item.selectedOptions.forEach((item, index) => {
        data.append(`PhonesSelected[${index}].Id`, item.id);
        data.append(`PhonesSelected[${index}].IsDefault`, item.isDefault);
      });
      
      data.append(`ChannelId`, this.state.id);


      Api2Talk.post('channelregistrationnumberrequest/channel', data, config)
        .then((result) => {
          if (result.data.success) {
            PapiroConsole.log("result aqui depois do submit")
            PapiroConsole.log(result)
            if (result.data && result.data.data && result.data.data.length > 0) {

              this.updateList(result.data.data)

            }
            getNotification(false, true, 'success', "Associação de canal atualizada como sucesso")



          } else {
            PapiroConsole.log(result)
            PapiroConsole.log("err result")
            const intl = this.props.intl;
            getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          }
        })
        .catch((err) => {
          PapiroConsole.log(err)
          PapiroConsole.log("err")
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          } else {
            const intl = this.props.intl;
            getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          }
        });

    }
  }

  updateList(list) {
    let array = [];
    list.forEach(element => {
      if (element.registrationnumberrequest && element.registrationnumberrequest.id && element.registrationnumberrequest.phonenumber) {
        let object = { id: element.registrationnumberrequest.id, name: element.registrationnumberrequest.phonenumber, isDefault: element.isdefault, channelRegistrationNumberRequestId: element.id }
        array.push(object);
      }
    });
    console.log('@log::array', array)
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        selectedOptions: array,
      },
    }));
  }

  handleAutocompleteChange = (_, newValue) => {
    if (newValue) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          selectedOptions: [...prevState.item.selectedOptions, { ...newValue, isDefault: false }],
        },
        autocompleteInputValue: '',
      }));
    }
  };

  async handleDeleteOption() {
    const { getNotification, setLoadingState } = this.props;

    setLoadingState(true)

    if (this.state.IdToDelete && this.state.IdToDelete.channelId && this.state.IdToDelete.channelRegistrationNumberRequestId && this.state.IdToDelete.channelId != 0 && this.state.IdToDelete.channelRegistrationNumberRequestId != 0) {
      var result = await ItemServiceChannelAssociation.DeleteOption(this.state.IdToDelete.channelRegistrationNumberRequestId)
      if (result.success) {
        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            selectedOptions: prevState.item.selectedOptions.filter((option) => option.id !== this.state.IdToDelete.phoneId),
          },
        }));
        this.openOrCloseRemoveModal()
        setLoadingState(false)
        getNotification(false, true, 'success', "Associação de canal deletado com sucesso")
        this.setState({ DialogRemoveState: false, IdToDelete: { channelRegistrationNumberRequestId: 0, channelId: 0, phoneId: 0 } });

      } else {
        this.openOrCloseRemoveModal()
        const intl = this.props.intl;
        getNotification(false, true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      }
    }
  }

  handleRemoveOption = (optionToRemove) => {
    if (optionToRemove.channelRegistrationNumberRequestId) {
      this.setState({ DialogRemoveState: true, IdToDelete: { channelRegistrationNumberRequestId: optionToRemove.channelRegistrationNumberRequestId, channelId: this.state.id, phoneId: optionToRemove.id }, })
    }
    else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          selectedOptions: prevState.item.selectedOptions.filter((option) => option.id !== optionToRemove.id),
        },
      }));
    }
  };

  handleCheckboxChange = (index) => {
    this.setState((prevState) => {
      const updatedOptions = [...prevState.item.selectedOptions];
      updatedOptions[index].isDefault = !updatedOptions[index].isDefault;
      return { item: { ...prevState.item, selectedOptions: updatedOptions } };
    });
  };

  handleRadioChange = (index) => {
    this.setState((prevState) => {
      const updatedOptions = prevState.item.selectedOptions.map((option, i) => ({
        ...option,
        isDefault: i === index,
      }));
      return { item: { ...prevState.item, selectedOptions: updatedOptions } };
    });
  }

  filterOptions = (options, { inputValue }) => {
    const { selectedOptions } = this.state.item;
    return options.filter(
      (option) =>
        !selectedOptions.find((selected) => selected.id === option.id) &&
        !this.state.item.selectedOptions.find((selected) => selected.id === option.id)
    );
  };

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  render() {
    const { classes } = this.props;
    const { item, phoneList, autocompleteInputValue, loadingGrid } = this.state;
    PapiroConsole.log(this.state)
    PapiroConsole.log("this.state")

    return (

      <div>
        <form name='myForm' className={classes.form} encType='multipart/form-data' onSubmit={this.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Autocomplete
                value={null}
                inputValue={autocompleteInputValue}
                options={phoneList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Números" variant="outlined" fullWidth />
                )}
                renderOption={(props, option, state) => (
                  <>
                    <List>
                      <ListItem {...props}>
                        <ListItemText primary={option.name} />
                      </ListItem>
                      {state.index < phoneList.length - 1 && <Divider />}
                    </List>
                  </>
                )}
                onChange={this.handleAutocompleteChange}

                filterOptions={this.filterOptions}
                onInputChange={(_, newInputValue) => this.setState({ autocompleteInputValue: newInputValue })}
                fullWidth
              />
              <Paper elevation={3} style={{ marginTop: 16 }}>
                {loadingGrid ? ( 
                  <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                ) : (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold', color: '#666' }}>Número telefone</TableCell>
                          <TableCell style={{ fontWeight: 'bold', color: '#666' }} align="center">Número telefone padrão?</TableCell>
                          <TableCell style={{ fontWeight: 'bold', color: '#666' }} align="center">Excluir</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.selectedOptions.map((option, index) => (
                          <TableRow key={index}>
                            <TableCell>{option.name}</TableCell>
                            <TableCell align="center">
                              <Radio
                                checked={option.isDefault}
                                onChange={() => this.handleRadioChange(index)}
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="secondary"
                                onClick={() => this.handleRemoveOption(option)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Grid>

            <Grid container style={{ marginTop: '25px' }}>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Salvar
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </form>
        <DialogGeneral
          dialogTitle={this.props.intl.formatMessage({ id: "remove.channel.association" })}
          dialogSubTitle={this.props.intl.formatMessage({ id: "remove.channel.association.info" })}
          open={this.state.DialogRemoveState}
          openOrCloseModalFunction={this.openOrCloseRemoveModal}
          handleConfirmation={this.handleDeleteOption}
        />
      </div>
    );
  }
}


PhoneAssociation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  channels: state.channels
});


export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(PhoneAssociation))));
PhoneAssociation.contextType = AbilityContext;