import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import InputIcon from '@mui/icons-material/Input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FirstElement from "../../components/first-element";
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import * as Service from '../../services/unit.service'
import ViewListIcon from '@mui/icons-material/ViewList';
import * as componentActions from '../../redux/actions/units';
import { PapiroConsole } from '../../utils/papiroConsole';
class UnitList extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;
        this.emptyArray = null;

        let filters = [{ name: intl.formatMessage({ id: "name" }), id: "name" }]

        const { userSession } = this.props;
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
        };

        this.changeValues = this.changeValues.bind(this);
        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {
      this.setState({ [event.target.name]: event.target.value, page: 1 });
    }

    callSearch() {
      if (this.state.filterSelected != 0) {
        this.getItens(this.state.page);
      }
    }

    async clearFields() {
      await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
      this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {
      this.setState({filterSelected : value})
    }


    deleteItem = async (data, resolve) => {
        let response = await Service.deleteItem(data.id)
        if (response && response.success) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                resultList: []
            });
            resolve();
            await this.getItens();
        } else {
            resolve();
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
        }
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
      await this.getItens()
    }

    getItens = async (page = 1) => {
        this.setState({ loading: true });

        let response = await Service.paginate(page, this.state.searchValue, this.state.filterSelected)

        if (response && response.success) {
            let count = response.count;
            PapiroConsole.log("=== getItens ===")
            PapiroConsole.log(count)
            this.setState({ count: count });
            this.setPagination(count, page);
            if(response.data.length === 0) {
                this.emptyArray = true 
            } else {
                this.emptyArray = false
            }

            this.setState({ loading: false, resultList: response.data });
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
        }
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                {!this.emptyArray ? (
                <div>
                <CssBaseline />
                    <Grid container className={classes.mb20}>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            style={{ marginBottom: '20px' }}
                            className={classes.button}
                            startIcon={<AddIcon />}
                            //component={Link} {... { to: '/OrganizationField-registration' }}
                            component={Link} {... {
                                to: {
                                    pathname: `/${this.props.match.params.lang}/unit-registration`
                                }
                            }}
                        >
                            {<FormattedMessage id="add" />}
                        </Button>
                    </Grid>

                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={12}>


                            <Card className="background-title-search">
                                <CardContent>

                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                {<FormattedMessage id={intl.formatMessage({id:"menu.units"})} />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                        </Grid>
                                        <Grid item >
                                            <TextField type="text"
                                                name="searchValue"
                                                value={this.state.searchValue}
                                                placeholder={intl.formatMessage({ id: "search" })}
                                                onChange={this.onFieldChange} />

                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                style={{ marginRight: 4, marginLeft: 8 }}
                                                onClick={this.callSearch}
                                            >
                                                <Search />
                                            </Button>

                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='large'
                                                onClick={this.clearFields}
                                                className={classes.button}

                                            >
                                                <ClearIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>
                        <MyMaterialTable
                            title=""
                            rowClick={(event, rowData) => {
                              this.props.addCurrent(rowData);
                              this.props.history.push({
                                pathname: `/${this.props.match.params.lang}/unit-edit`
                              });
                            }}
                            columns={[
                              { title: intl.formatMessage({ id: "name" }), field: 'name' }
                            ]}
                            data={this.state.resultList}
                            deleteItem={this.deleteItem}
                        />


                        <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                            <Grid item xs={12} md={3}>
                                <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>


                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}


                    <CustomizedSnackbars
                        variant={this.state.notificationVariant}
                        message={this.state.notificationMessage}
                        isOpen={this.state.openNotification}
                        toClose={this.closeNotification}
                    />
                </div>)
                                        :(
                                            <div>
                                               <FirstElement
                                               title = {intl.formatMessage({id:"menu.units"})}
                                               subtitle = {intl.formatMessage({id:"units.list.subtitle"})}
                                               buttonText = 
                                               {
                                                <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                style={{ marginBottom: '20px' }}
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {... {
                                                    to: {
                                                        pathname: `/${this.props.match.params.lang}/unit-registration`
                                                    }
                                                }}
                                            >
                                                {<FormattedMessage id="add" />}
                                            </Button>
                                               }
                                            
                                               icon = {<ViewListIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                            )}

                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        
               
            </Container>

        );
    }
}
UnitList.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UnitList))));
UnitList.contextType = AbilityContext;