import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Grid,IconButton, } from '@mui/material';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';


const classes = {
  flexContainer: 'ReactVirtualizedDemo-flexContainer',
  tableRow: 'ReactVirtualizedDemo-tableRow',
  tableRowHover: 'ReactVirtualizedDemo-tableRowHover',
  tableCell: 'ReactVirtualizedDemo-tableCell',
  noClick: 'ReactVirtualizedDemo-noClick',
};

const styles = ({ theme }) => ({
  // temporary right-to-left patch, waiting for
  // https://github.com/bvaughn/react-virtualized/issues/454
  '& .ReactVirtualized__Table__headerRow': {
    ...(theme.direction === 'rtl' && {
      paddingLeft: '0 !important',
    }),
    ...(theme.direction !== 'rtl' && {
      paddingRight: undefined,
    }),
  },
  [`& .${classes.flexContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  [`& .${classes.tableRow}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.tableRowHover}`]: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  [`& .${classes.tableCell}`]: {
    flex: 1,
  },
  [`& .${classes.noClick}`]: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, rowHeight, onRowClick, width} = this.props;

    return (
		<TableCell
			component="div"
			className={clsx(classes.tableCell, classes.flexContainer, {
			[classes.noClick]: onRowClick == null,
			})}
			variant="body"
			style={{ height: rowHeight, width: width }}
			align={
			(columnIndex != null && columns[columnIndex].numeric) || false
				? 'right'
				: 'left'
			}
		>
			{cellData}
		</TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns, width } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight, width: width}}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { columns, rowHeight, headerHeight, rowClick, action, ...tableProps } = this.props;


	return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
				{columns.map(({ dataKey, ...other }, index) => {
				return (
					<Column
					hover
					key={dataKey}
					headerRenderer={(headerProps) =>
						this.headerRenderer({
						...headerProps,
						columnIndex: index,
						})
					}
					className={classes.flexContainer}
					cellRenderer={this.cellRenderer}
					dataKey={dataKey}
					onClick={rowClick}
					width={width * 0.4}
					{...other}

					/>
				);
				})}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const VirtualizedTable = styled(MuiVirtualizedTable)(styles);



export default function ReactVirtualizedTable(props) {
	const { title, urledit, columns, data, options, deleteItem, rowClick, action } = props;
	const formattedList = columns.map(item => {
		return {
			...item,
			//width: 120,
            label: item.title,
            dataKey: item.field,
		}
	});		

	return (
	<Grid container spacing={0} >
		<Grid item xs={12} sm={12} style={{ display: 'table', tableLayout: 'fixed', width: '100%', minWidth: '210px', paddingLeft: 0, paddingRight: 0 }}>
  
			<Paper style={{ minHeight: 500, width: '100%' }}>
				<VirtualizedTable
				   style={{  width: '100%' }}
					rowCount={data.length}
					rowGetter={({ index }) => data[index]}
					columns={formattedList}
					rowClick={rowClick}
					action={action}
				/>
			</Paper>
		</Grid>
	</Grid>
  );
}
