import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import 'react-chat-widget/lib/styles.css';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  IconButton,
  Button
} from "@mui/material";
import { getOrganizations } from "../../services/called.service";
import * as FileService from '../../services/attachment.service';
import CustomizedSnackbars from "../../components/material-snackbars";
import { useChatHubContext } from "../../contexts/chatHubContext";
import { ChatOptions } from "../../components/chat-options";
import { InitChatDialog } from "../../components/initChatDialog";
import { Overlay } from '../../styles/global';
import ChatListOptions from '../../components/chat-list-options';
import { AttachFile } from '@mui/icons-material';
import * as signalR from '@microsoft/signalr';
import { CircularProgress } from '@mui/material';
import Api from "../../services/api"
import { Dialog} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete-dialog-with-z-index'
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';

export function ClientChat({ userId, userSession, closeChat, intl }) {
  const {
    chatHub: chatHubConnection,
    currentClientChat,
    currentMessages,
    error,
    openChatList,
  } = useChatHubContext();






  const [organizations, setOrganizations] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [showInitChatDialog, setShowInitChatDialog] = useState(true);
  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const [inputMessage, setInputMessage] = useState(null);
  const [errorInt, setErrorInt] = useState(error);
  const [loading, setLoading] = useState(false);
  const closeAlertMessage = () => setAlertNotification({ ...alertNotification, isOpen: false });
  const openAlertMessage = (variant, message) => setAlertNotification({ variant, message, isOpen: true });
  const [currentCalledId, setCurrentCalledId] = useState(null);
  const [chatAlrealdyHasCalled, setChatAlrealdyHasCalled] = useState(false);
  const [dialogStateCloseChat, setdialogStateCloseChat] = useState(false);


  useEffect(() => {
    scrollChatToBottom()



  }, [currentMessages])

  useEffect(() => {

    if (openChatList != null && openChatList.length > 0) {
      PapiroConsole.log("entrei aqui")
      setShowChat(true)


    }
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {

      let allOrganizations = userSession.user.userorganizations
      PapiroConsole.log(allOrganizations)
      PapiroConsole.log("allOrganizations")
      let organizations = allOrganizations.filter(org => {
        if(org.organizationsettings ){
            return org.organizationsettings.allowattendanceviachat
        }
        
      });
      PapiroConsole.log(organizations)

      setOrganizations(organizations)
      PapiroConsole.log(userSession.user.userorganizations)
      PapiroConsole.log(userSession)
      PapiroConsole.log("organizações")
    } else {
      PapiroConsole.log("entrei na requisição de organizações")
      getOrganizations().then((result) => {
        if (result.data && result.data.length > 0) {
          const organizations = result.data.filter(org => {
            if(org.organizationsettings ){
                return org.organizationsettings.allowattendanceviachat
            }
            
          });
          setOrganizations(organizations);
        } else {
          setOrganizations([]);
        }
      }).catch((err) => {
        setOrganizations([]);
        setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
        console.error(err.toString());

        throw err;
      });
    }


  }, [])




  const openOrCloseDialogFinishChat = (e) => {
    PapiroConsole.log(e)
    if (e != null) {
      e.preventDefault()
    }
    setdialogStateCloseChat(!dialogStateCloseChat)

  };


  const checkIfChatHasCalled = async (chatId) => {

    setLoading(true);
    PapiroConsole.log("entrei aqui 12")
    PapiroConsole.log(chatId)


    PapiroConsole.log("entrei aqui 13")
    await Api.get(`/calledchat/getbychatid/${chatId}`)
      .then(result => {
        if (result && result.data && result.data.success) {

          if (result.data.data.chatid && result.data.data.calledid) {
            //setChatAlrealdyHasCalled(true)
            //setCurrentChatId(result.data.data.chatid)
            setCurrentCalledId(result.data.data.calledid)
          }
          else {
            //setChatAlrealdyHasCalled(false)
            setCurrentCalledId(null)
            //setCurrentChatId(null)
          }

        } else {

          openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));
          setLoading(false);
          //setChatAlrealdyHasCalled(false)
          setCurrentCalledId(null)
          //setCurrentChatId(null)

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false);
          openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))


        }
        else {
          setLoading(false);
          openAlertMessage("error", intl.formatMessage({ id: "process.error" }))

        }
        //setChatAlrealdyHasCalled(false)
        setCurrentCalledId(null)
        //setCurrentChatId(null)
      });


    setLoading(false);

  }

  const checkDisabledChat = () => {


    if (currentClientChat) {
      return ""
    }

    else {
      return "disabled-main-content"
    }
  }




  const closeChatAttendance = async () => {

    PapiroConsole.log(currentClientChat)
    PapiroConsole.log("currentClientChat")
    openOrCloseDialogFinishChat()
    setLoading(true)

    if (!currentClientChat){
      setLoading(false)
      setAlertNotification({ variant: "error", message: "Selecione um chat para finalizar", isOpen: true });
      return;

    } 
    PapiroConsole.log("entrei aqui 2")
    await chatHubConnection.invoke("CloseChat", currentClientChat.id, userId).then(result => {

      if (result == true) {

        openAlertMessage("success", intl.formatMessage({ id: "footer.chat.attendance.finished" }));

        chatHubConnection.invoke("SendPrivateMessage", '', currentClientChat.id, userId, true).catch(function (err) {
          setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
          console.error(err.toString());
        });;

      }
    });

    setLoading(false)
    //closeChat(true);
  };

  const initChatConversation = async (orgId) => {
    //? Chat já foi iniciado pelo cliente

    setLoading(true)

   

      console.log("initChatConversation")
      console.log(orgId)
      console.log(userId)

      await chatHubConnection.invoke("InitChat", userId, orgId).then(result => {
        if (result == true) {

          setShowChat(true);
        }
        else {
          setShowChat(false);
        }
      })
    

    setLoading(false)
    //setErrorInt(false);
  };

  const sendPrivateMessage = async (message) => {
    PapiroConsole.log(currentClientChat)
    PapiroConsole.log("currentClientChat")
    PapiroConsole.log(currentMessages)
    PapiroConsole.log("currentMessages")

    if (!message || !currentClientChat) {
      setAlertNotification({ variant: "error", message: intl.formatMessage({ id: "client.chat.select.user" }), isOpen: true });

      return;
    }

    if (chatHubConnection.state === signalR.HubConnectionState.Connected) {

      await chatHubConnection.invoke("SendPrivateMessage", message, currentClientChat.id, userId, false).catch(function (err) {
        setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
        console.error(err.toString());
      });

    }
    else {
      setAlertNotification({ variant: "error", message: "Não foi possível o envio da mensagem, problemas na conexão", isOpen: true });
    }


  };

  const getFileName = (fileName) => {
    PapiroConsole.log(fileName)
    PapiroConsole.log("fileName")
    let file = fileName.substring(fileName.lastIndexOf('/') + 1);
    PapiroConsole.log(file)
    PapiroConsole.log("file")
    return file;
  }

  const sendAttachments = async (event) => {



    setLoading(true)
    const files = event.target.files;
    var arrayAttachments = [...files];
    let data = new FormData();
    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    if (files.length > 0 && currentClientChat) {

      if (currentClientChat.closedChat == false) {

        arrayAttachments.forEach(file => data.append('filesUpload', file));
        data.append('chatid', currentClientChat.id)
        data.append('senderid', userId)

        await Api.post("/chatmessageattachment", data, config)
          .then(result => {
            if (result && result.data && result.data.success) {

              PapiroConsole.log("result attachments aqui ")
              PapiroConsole.log(result)
              if (result.data.data && result.data.data.length > 0) {

                let intMessagesArray = result.data.data.map(element => element.id);
                PapiroConsole.log("lista de inteiros aqui")
                if (chatHubConnection.state === signalR.HubConnectionState.Connected) {


                  chatHubConnection.invoke("SendAttachment", intMessagesArray, currentClientChat.id, userId).catch(function (err) {
                    setAlertNotification({ variant: "error", message: err.toString(), isOpen: true });
                    console.error(err.toString());
                  });



                }
              }



              //aqui mandar invoke pra enviar mensagem pro técnico 

            } else {

              openAlertMessage("error", result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }));
            }
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.errors) {

              openAlertMessage("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            }
            else {
              openAlertMessage("error", intl.formatMessage({ id: "process.error" }))
            }
          });

      }

    }


    setLoading(false)


  };



  const getFile = async (hirerid, fileName) => {

    let url = await FileService.getFileByPathWithFileRepositoryMethodByConfig(hirerid, fileName);
    //alert(JSON.stringify(url.data));

    if (url && url.data && url.data.data && url.data.success) {
      window.open(`${url.data.data}`, '_blank');
    }

  }

  const addMessage = (e, message, sender) => {
    e.preventDefault();

    PapiroConsole.log(message)
    PapiroConsole.log(currentClientChat)
    PapiroConsole.log("problema aqui")
    if (!message) return;



    sendPrivateMessage(message);
    setInputMessage("");
  }

  const scrollChatToBottom = () => {
    const chatContent = document.querySelector(".chat-main-content");
    if (!chatContent) return;
    chatContent.scrollTo({ top: chatContent.scrollHeight, behavior: "smooth" })
  }

  const verifyClientChat = () => {

    PapiroConsole.log("entre na renderização ")

    return returnClientChat();
  };

  const openInitChatDialogOption = () => {

    setShowChat(false)
    setShowInitChatDialog(true)

    
  };

  const returnClientChat = () => {

    return (
      <div className="technician-chat-container">


        <div className="header-container">
          <div className="header-content">
            <div style={{ width: '90%' }}>
              <div className="title">{intl.formatMessage({ id: "chat.attendance" })}</div>
              {  /*<div className="username">{currentClientChat ? currentClientChat.requestingUser.name : ""}</div>*/}
              <div className="username">{currentClientChat ? currentClientChat.requestingUserId == userId ? currentClientChat.assignedUser.name : currentClientChat.requestingUser.name : ''}  {currentClientChat && currentClientChat.calledChat ? `- Chamado n° ${currentClientChat.calledChat.calledId}` : ''}</div>
            </div>
            <div className="chat-button">
              <CloseIcon onClick={closeChat} />
            </div>
          </div>
        </div>
        <div className="chat-list-container">
          <ChatListOptions checkIfChatHasCalled={checkIfChatHasCalled} />

        </div>
        <div className="chat-container">
          <div className={`chat-main-content ${checkDisabledChat()}`}>
            {currentMessages && currentMessages.length > 0 && currentMessages.map(({ id, message, senderUser, isAttachment }) => {
              if (senderUser && (senderUser.id === userId) && isAttachment == false) {
                return (
                  <div style={{ display:'flex', justifyContent:'flex-end', borderBottomRightRadius:'0'}}>

                    <span key={id} style={{ backgroundColor: "#5f5fff", color: "white" }} className="message">{message}</span>
                  </div>
                )
              }
              else if (senderUser && (senderUser.id === userId) && isAttachment == true) {
                return (
                  <div style={{ display:'flex', justifyContent:'flex-end', borderBottomRightRadius:'0'}}>

                    <span key={id} style={{ backgroundColor: "#5f5fff", color: "white" }} className="message"><Button startIcon={<AttachFile />} style={{ color: 'white', fontSize: '10px' }} onClick={() => getFile(currentClientChat ? currentClientChat.hirerId : 0, message)}>{getFileName(message)}</Button></span>
                  </div>
                )
              }
              else if (isAttachment == true) {

                return (
                  <div  style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <span>{currentClientChat ? currentClientChat.requestingUserId == userId ? currentClientChat.assignedUser.name : currentClientChat.requestingUser.name : ''}<br /></span>
                    <span key={id} className="message "><Button startIcon={<AttachFile />} style={{ color: 'black', fontSize: '10px' }} onClick={() => getFile(currentClientChat ? currentClientChat.hirerId : 0, message, 2)}>{getFileName(message)}</Button></span>
                  </div>
                )



              }

              else {
                return (
                  <div  style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <span>{currentClientChat ? currentClientChat.requestingUserId == userId ? currentClientChat.assignedUser.name : currentClientChat.requestingUser.name : ''}<br /></span>
                    <span key={id} className="message ">{message}</span>
                  </div>
                )
              }
            })}
          </div>
          <div className="chat-input-container">
            <form onSubmit={(e) => addMessage(e, inputMessage, userId)} className="input-wrapper">
              <input disabled={currentClientChat ? currentClientChat.closedChat : true} onChange={(e) => setInputMessage(e.target.value)} placeholder={currentClientChat ? intl.formatMessage({ id: "technician.chat.type.something" }) : intl.formatMessage({ id: "select.conversation" })} className="chat-input" type="text" value={inputMessage}></input>
              <SendIcon style={{color:'black'}} onClick={(e) => addMessage(e, inputMessage, userId)} className="send-icon" />
              <input
                id="attachment-input"
                type="file"
                style={{ display: 'none' }}
                onChange={sendAttachments}
                multiple
              />
              <label htmlFor="attachment-input">
                <IconButton component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
              <ChatOptions  closeChat={openOrCloseDialogFinishChat} isClient={true} intl={intl} openDialog={openInitChatDialogOption} />
            </form>
          </div>
        </div>
        <Dialog
        style={{ zIndex: 100000 }}

        fullWidth={true}
        maxWidth={"sm"}
        open={dialogStateCloseChat}
        onClose={(e) => openOrCloseDialogFinishChat(e)}
        aria-labelledby="finish.chat"
      >


        <DialogTitle id="finish.chat">

          <FormattedMessage id={intl.formatMessage({ id: "finish.chat" })} />
        </DialogTitle>
        <DialogContent  >
          <DialogContentText  >
            <div>
             {intl.formatMessage({ id: "finish.chat.info" }) }
            </div>

          </DialogContentText>


        </DialogContent>
        <DialogActions>
          <Button onClick={openOrCloseDialogFinishChat} color="secondary">
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button variant='contained' color='primary' onClick={ (e) => closeChatAttendance(e) } >
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>

      </div>
    )

  };


  return (
    <>
      {showChat ? verifyClientChat() : <InitChatDialog onStartChat={(orgId) => initChatConversation(orgId)} organizations={organizations} open={showInitChatDialog} handleClose={() => setShowInitChatDialog(false)} intl={intl} />}

      
      {loading &&
        <Overlay >
          <CircularProgress color='secondary' />
        </Overlay>}
      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />




    </>
  );
}