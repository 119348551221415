import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import CalledReportConfigRegistration from './index.content'
import * as componentActions from '../../redux/actions/calledlistdatas';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormattedMessage, injectIntl } from 'react-intl';

class CalledReportConfigRegistrationContainer extends Component {

  constructor(props) {
    super(props);

    let fromProps = ""
    if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
      fromProps = this.props.from

    this.state = {
      from: fromProps
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() { }

  render() {
    return (
      <Base props={this.props}>
        <CalledReportConfigRegistration from={this.state.from} props={this.props} />
      </Base>
    );
  }
}

CalledReportConfigRegistrationContainer.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledReportConfigRegistrationContainer)));

CalledReportConfigRegistrationContainer.contextType = AbilityContext