import Api from './api';

export const paginate = async (page, org, search, field) => { 
  let data = []

  let url = `/additionalcalledcostitems/paginate?page=${page}&org=${org}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const paginateByOrganization = async (page, orgId, search, field) => { 
  let data = []

  let url = `/additionalcalledcostitems/organization/paginate?page=${page}&id=${orgId}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAll = async () => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/all`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllActive = async () => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/all/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getById = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveById = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/${id}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/${id}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationId = async (orgId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/org/${orgId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationId = async (orgId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/org/${orgId}/active`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByOrganizationIdWithoutIncludes = async (orgId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/org/${orgId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getActiveByOrganizationIdWithoutIncludes = async (orgId) => { 
  let data = []
  await Api.get(`/additionalcalledcostitems/org/${orgId}/active/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData, config) => { 
  let data = []
  await Api.post(`/additionalcalledcostitems`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData, config) => { 
  let data = []
  await Api.put(`/additionalcalledcostitems`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/additionalcalledcostitems/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}