import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useChatHubContext } from '../../contexts/chatHubContext';
import Badge from '@mui/material/Badge';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';
import IconButton from '@mui/material/IconButton';
import { TablePopup } from '../table/table-popup';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";


export default function ChatListOptionsCloseds(intl) {
  const userSession = useSelector((state) => state.userSession);
  const {
    closedChatList,
    chatHub,
    currentTechnicianChat,
    currentClientChat
  } = useChatHubContext();


  const [currentPopUpData, setCurrentPopUpData] = useState([]);


  const updateCurrentChat = async (chat) => {

    if (chat) {
      await chatHub.invoke("ResetNotSeenMessages", chat.id, userSession.user.id);
      await chatHub.invoke("LoadChatMessages", chat.id);

    }
  };

  const currentChatItemStyle = (chatId, list) => {


    if ((currentTechnicianChat && chatId === currentTechnicianChat.id) || (currentClientChat && chatId == currentClientChat.id)) {
      return "current-chat-style"
    }

    else {
      return ""
    }
  }

  return (
    <List>
      {closedChatList && closedChatList.length > 0 && closedChatList.map((chat, index) => (
        <ListItem className={`chat-list-item ${currentChatItemStyle(chat.id, closedChatList)}`} key={chat.id} onClick={() => updateCurrentChat(chat)} >
          <ListItemIcon >
            {/* <Avatar> */}
            <Badge badgeContent={userSession.user.id != chat.assignedUserId ? chat.notSeenByRequestingUser : chat.notSeenByAssignedUser} color="primary">
              <AccountCircleIcon />
            </Badge>
            {/* </Avatar> */}
          </ListItemIcon>
          {/* //TODO colocar a última mensagem em secondary */}
          <ListItemText>
            <Typography variant="body1" component="span"  style={{color:'gray'}}>{userSession.user.id != chat.assignedUserId ? chat.assignedUser.name + ` (#${chat.id}) - ` : chat.requestingUser.name + ` (#${chat.id}) - `} </Typography>
            <Typography  variant="body1" component="span" style={{color:'gray'}} >Encerramento: {chat.updatedAt ? moment(chat.updatedAt).format('DD/MM/YYYY HH:mm') : ''} </Typography>
          </ListItemText>

        </ListItem>
      ))}
      {closedChatList && closedChatList.length == 0 &&
        <Grid style={{ marginLeft: '5px' }}>
          <FormattedMessage id="Não há conversas a serem exibidas" />
        </Grid>

      }

    </List>






  );
}