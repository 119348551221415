import { PapiroConsole } from "../../utils/papiroConsole";

export default function headerMenu(state = [], action) {
  switch (action.type) {
    case 'TOGGLE':
      return {
        open: action.open,
      }
    case 'RELOAD':
      return {
        refresh: action.refresh,
      }
      case 'NOTIFICATION_MENU_REFRESHED':
        PapiroConsole.log("entrei no NOTIFICATION_MENU_REFRESHED ")
        PapiroConsole.log(action)
      return {
       notificationmenurefreshed : action.notificationmenurefreshed,
      }
    default:
      return state;
  }
}
