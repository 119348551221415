import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/shippingservicesettings/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/shippingservicesettings/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByHirerId = async (hirerId) => { 
  let data = []
  await Api.get(`/shippingservicesettings/hirer/${hirerId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByHirerIdWithoutIncludes = async (hirerId) => { 
  let data = []
  await Api.get(`/shippingservicesettings/hirer/${hirerId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/shippingservicesettings`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/shippingservicesettings`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/shippingservicesettings/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}