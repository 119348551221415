import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider'
import { BarChart, ComposedChart, Bar, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem } from '@mui/material';
import {MoreVert } from '@mui/icons-material';
import './style.css'

const TechnicalRank = memo(function TechnicalRank(props) {

  const [data, setData] = useState(props.data)
  const [calledCountHidden, setCalledCountHidden] = useState(true)
  const [serviceTimeAverageHidden, setServiceTimeAverageHidden] = useState(true)
  const [solutionTimeAverageHidden, setSolutionTimeAverageHidden] = useState(true)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (props.data != data)
      setData(props.data)
  }, [props.data])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const getIntroOfPage = (dataKey, payload) => {
    if (dataKey === "servicetimeaverage")
      return payload.servicetimeaveragedata != null && payload.servicetimeaveragedata.length > 0 ? payload.servicetimeaveragedata : payload.servicetimeaverage
    if (dataKey === "solutiontimeaverage")
      return payload.solutiontimeaveragedata != null && payload.solutiontimeaveragedata.length > 0 ? payload.solutiontimeaveragedata : payload.solutiontimeaverage
    if (dataKey === "calledcount")
      return payload.calledcount != null ? payload.calledcount : 0
    return '';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {payload.map((item) => {
            return <p className="intro">{`${item.name} : ${item.payload != null ? getIntroOfPage(item.dataKey, item.payload) : item.value}`}</p>
          })}
        </div>
      );
    }
  
    return null;
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank" })}
        action={
          <IconButton
            id='settings-button'
            aria-label="settings"
            aria-controls={open ? 'settings-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick} >
            <MoreVert />
          </IconButton>
        } />
      <Menu
        id="settings-menu"
        MenuListProps={{
          'aria-labelledby': 'settings-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose} >
        <MenuItem key="calledCountHidden">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={calledCountHidden} onChange={() => setCalledCountHidden(!calledCountHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.called.count" })} />
          </FormGroup>
        </MenuItem>
        <MenuItem key="serviceTimeAverageHidden">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={serviceTimeAverageHidden} onChange={() => setServiceTimeAverageHidden(!serviceTimeAverageHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.service.time.average" })} />
          </FormGroup>
        </MenuItem>
        <MenuItem key="solutionTimeAverageHidden">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={solutionTimeAverageHidden} onChange={() => setSolutionTimeAverageHidden(!solutionTimeAverageHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.solution.time.average" })} />
          </FormGroup>
        </MenuItem>
      </Menu>
      <Divider />
      <CardContent>
        <ResponsiveContainer width='100%' aspect={4.0/1.0} >
          <ComposedChart
            width={1300}
            height={600}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }} >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1000 }} />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            { calledCountHidden && <Bar dataKey="calledcount" name={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.called.count" })} fill="#8884d8" /> }
            { serviceTimeAverageHidden && <Line type="monotone" name={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.service.time.average" })} dataKey="servicetimeaverage" fill="#ff7300" /> }
            { solutionTimeAverageHidden && <Line type="monotone" name={props.intl.formatMessage({ id: "productivity.dashboard.technical.rank.solution.time.average" })} dataKey="solutiontimeaverage" fill="#82ca9d" /> }
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
})

export default injectIntl(TechnicalRank)