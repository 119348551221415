import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import * as Service from '../../services/config.service';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import SimpleCheckBox from '../../components/checkbox/check';

import SimpleSelect from "../../components/select/simple";

class ConfigRegistration extends Component {

  constructor(props) {
    super(props);
    const intl = this.props.intl;
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
    const defaultvalue = {id:0, name:''};

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
     timezone : defaultvalue, 
     formatdate : defaultvalue, 
     decimalseparator : '', 
     thousandseparator : '', 
     gasvalueperliter: '', 
     kmperliter: 0,
      frommail: '',
      hostmail: '',
      portmail: '',
      usermail: '',
      passwordmail: "",
      enablessl: false,
      mailtest: "",
      removecallswithfinishedstatus: false,
      calledaccompanimentordertype: 2
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      TimeZoneList: [],
      FormatDateList: [],

      ordeningoptions: [ { id: 1, name: intl.formatMessage({id:"order.ascending"}) }, { id: 2, name: intl.formatMessage({id:"order.descending"}) }]     

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.newchangeValues = this.newchangeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  async componentDidMount() {
    await this.getTimeZones();
    await this.getFormatDates();
  };

  async getTimeZones(){
      this.setState({ loading: true });
      var result = await Service.getTimeZones();
        if (result.success) {
          if(result.data && result.data.length > 0) {
            var formattedList = result.data.map(item => {

              let itemOldName = item.name
              item.name = item.id + ' - ' + item.name

              return {
                  ...item,
                  oldName: itemOldName
              }
            })
            this.setState({ loading: false, TimeZoneList: formattedList });
          }            
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
};     
async getFormatDates(){
      this.setState({ loading: true });
      var result = await Service.getFormatDates();
      //console.log(result.data);
        if (result.success) {
          this.setState({ loading: false, FormatDateList: result.data });
                        
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  async newchangeValues(stateName, value) {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'kmperliter' || value.length == 0) {
      const re = /^[0-9\b]+$/;
      if (re.test(value)) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
        else if (key.toString().toLowerCase().indexOf('timezone') > -1) { 
          data.append(key, this.state.item[key].id);
        }
        else if (key.toString().toLowerCase().indexOf('formatdate') > -1) { 
          data.append(key, this.state.item[key].name);
        } else if(key.toString().toLowerCase().indexOf("gasvalueperliter") > -1) {
          if(this.state.item[key].length > 0) {
            /*let gasvalueperliterNotComma = this.state.item[key].replaceAll(",", "")
            let gasvalueperliterFloat = parseFloat(gasvalueperliterNotComma) + ""
            let gasvalueperliterFinal = gasvalueperliterFloat.replace(".", ",")
            data.append(key, gasvalueperliterFinal)*/
            data.append(key, this.state.item[key])
          } else
            data.append(key, 0)
        } else if (key.toString().toLowerCase().indexOf('removecallswithfinishedstatus') > -1){
          data.append(key, this.state.item[key])
        } else if (key.toString().toLowerCase().indexOf('calledaccompanimentordertype') > -1){
          data.append(key, this.state.item[key])
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/configs', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });


          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    })); 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"timezone"})} options={this.state.TimeZoneList} stateName='timezone' changeSelect={this.changeValues} selected={item.timezone} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"date.format"})} options={this.state.FormatDateList} stateName='formatdate' changeSelect={this.changeValues} selected={item.formatdate} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"decimal.separator"})} autoComplete='fname' variant='outlined' name='decimalseparator' onChange={this.handleInputChange} value={item.decimalseparator} required 
                      onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,1)
                      }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"thousand.separator"})} autoComplete='fname' variant='outlined' name='thousandseparator' onChange={this.handleInputChange} value={item.thousandseparator} required 
                      onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,1)
                      }}
                      />
                    </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label={intl.formatMessage({id:"gas.price.per.liter"})}
                      variant="outlined"
                      value={item.gasvalueperliter}
                      onChange={this.handleInputChange}
                      name="gasvalueperliter"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      label={intl.formatMessage({id:"km.per.liter"})}
                      autoComplete='fname'
                      variant='outlined' name='kmperliter'
                      onChange={this.handleInputChange}
                      value={item.kmperliter} required />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                      <SimpleCheckBox 
                        label={intl.formatMessage({id:"removecallswithfinishedstatus"})} 
                        name='removecallswithfinishedstatus' 
                        stateName='removecallswithfinishedstatus' 
                        changeSelect={this.changeValues} 
                        selected={this.state.item.removecallswithfinishedstatus} />                       
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                      <SimpleSelect
                        label={intl.formatMessage({id:"calledaccompanimentordertype"})}
                        options={this.state.ordeningoptions}
                        stateName='calledaccompanimentordertype'
                        changeSelect={this.changeValues}
                        selected={this.state.item.calledaccompanimentordertype}
                        required={true}
                      />
                  </Grid>

                    <Grid item xs={12} sm={12}>
                      <p>&nbsp;</p>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography component="h1" variant="h5" style={{color: "#263238", fontSize: "16px" }}>
                        Envio de e-mail
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="frommail"
                        variant="outlined"
                        type="email"
                        fullWidth
                        id="frommail"
                        label={intl.formatMessage({id:"config.edit.email.from"})}
                        value={item.frommail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="hostmail"
                        variant="outlined"
                        fullWidth
                        id="hostmail"
                        label={intl.formatMessage({id:"hostmail"})}
                        value={item.hostmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="portmail"
                        variant="outlined"
                        type="number"
                        fullWidth
                        id="portmail"
                        label={intl.formatMessage({id:"port"})}
                        value={item.portmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="usermail"
                        variant="outlined"
                        fullWidth
                        id="usermail"
                        label={intl.formatMessage({id:"usermail"})}
                        value={item.usermail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="passwordmail"
                        variant="outlined"
                        fullWidth
                        id="passwordmail"
                        label={intl.formatMessage({id:"password"})}
                        type='password'
                        placeholder={intl.formatMessage({id:"leave.empty"})}
                        value={item.passwordmail}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({id:"config.edit.enable.ssl"})}
                        name='enablessl'
                        stateName='enablessl'
                        changeSelect={this.newchangeValues}
                        selected={item.enablessl} />
                    </Grid>

                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                    </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
ConfigRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(ConfigRegistration)));
