import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleCheckBox from '../../../components/checkbox/check';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleAutoCompleteMultiple from '../../../components/auto-complete/autocomplete-multiple-create';
import * as Service from '../../../services/techinicalteam.service';
import * as ServiceOrg from '../../../services/techinicalteamorganization.service';
import * as ServiceUser from '../../../services/techinicalteamuser.service';
import * as ServiceCategory from '../../../services/techinicalteamorganizationcategory.service';

class TechinicalTeamRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name : '', 
      description : '', 
      active : false, 
      OrganizationId: organizationid,
      OrganizationText: '',
      UserId:  0,
      UserText: '',
      CategoryId: 0,
      selectCategories: false
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      UserList: [],
      OrgList: [],
      CategoryList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getOrganizations();
    await this.getUsers();
    //this.getCategories(784);
    await this.getCategories(this.props.organizationid);
    this.setState({ loading: false });
  };

  async getOrganizations(){
    this.setState({ loading: true });
    var result = await ServiceOrg.getOrganizations();
      if (result.success) {
        this.setState({ loading: false, OrgList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
  };
  async getUsers(){
    this.setState({ loading: true });
    var result = await ServiceUser.getUsers();
      if (result.success) {
        this.setState({ loading: false, UserList: result.data });
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
  };
  async getCategories(OrganizationId){
    var result = await ServiceCategory.getAllCategoriesWithParentByOrganization(OrganizationId);
    if (result.success) {
      if(OrganizationId.length > 1){
        result.data = result.data.map((item) =>{
          return {
            ...item,
            parentname: `( ${item.organization.name} ) ${item.parentname}`
          }
        });
      }
      this.setState({ loading: false, CategoryList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }
      


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
          
        } else if (key.toString().toLowerCase().indexOf('userid') > -1) {
          let listOrgIds = '';
          this.state.item[key].map((item) => {
            listOrgIds = listOrgIds+ item.id+",";
          });
          data.append(key, listOrgIds);
        }else if(key.toString().toLowerCase().indexOf('categoryid') > -1){
          let listCategory = '';
          this.state.item[key].map((item) => {
            listCategory = listCategory+ item.id + '-' + item.organizationid +",";
          });
          data.append('Category', listCategory);
        } else {
          data.append(key, this.state.item[key]);
        }
      }
      /*
      for (var pair of data['Category'].entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
      }*/
      
      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.post('/techinicalteams', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });

            this.props.next();

          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"process.error"})
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if(stateName === 'OrganizationId'){
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CategoryId']: []
        }
      }));
      this.getCategories(value);
    }

    if(stateName == 'selectCategories'){
      if(value == true){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: this.state.CategoryList,
          }
        }));
      }else{
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: [],
          }
        }));
      }
    }

    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
     return (
      <div className={classes.root}>
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField inputProps={{ maxLength: 250 }} fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField inputProps={{ maxLength: 250 }} fullWidth label={intl.formatMessage({id:"description"})} autoComplete='fname' variant='outlined' multiline rows="4"
                                                      name='description' onChange={this.handleInputChange} value={item.description}  />
                    </Grid>
                </Grid>
                    <Grid container spacing={4} style={{textAlign: 'left'}}>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"technicians"})} options={this.state.UserList} stateName='UserId' changeSelect={this.changeValues} />
                  </Grid>
                </Grid>

                <Grid container spacing={4} style={{display: 'none'}}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"menu.organizations"})} options={this.state.OrgList} stateName='OrganizationId' changeSelect={this.changeValues} />
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{textAlign: 'left'}}>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({id:"select.all.categories"})} name='selectCategories' stateName='selectCategories' changeSelect={this.changeValues} selected={item.selectCategories} />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"categories"})} options={this.state.CategoryList} stateName='CategoryId' changeSelect={this.changeValues} selected={item.CategoryId} />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4} /> 
                  <Grid  xs={4} sm={4} style={{textAlign: 'center', marginTop: '5px'}}>
                    <Button
                      type='submit'
                      color='primary'
                      onClick={back}
                    >
                      {this.props.intl.formatMessage({id:"back"})}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                    >
                      {intl.formatMessage({id:"next"})}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} /> 
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>                   
      </div>
    );
  }
}
TechinicalTeamRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(TechinicalTeamRegistration)));
