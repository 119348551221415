import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithAttachmentIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/${id}/withattachmentincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsId = async (calledServiceSettingsId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/all/calledservicesettings/${calledServiceSettingsId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsIdWithoutIncludes = async (calledServiceSettingsId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/all/calledservicesettings/${calledServiceSettingsId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getAllByCalledServiceSettingsIdWithAttachmentIncludes = async (calledServiceSettingsId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileupload/all/calledservicesettings/${calledServiceSettingsId}/withattachmentincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginate = async (page, search, field, size = 10) => { 
  let data = []

  let url = `/calledservicesettings/paginate?page=${page}&size=${size}`
  if (search != '' && field != 0)
    url += `&search=${search}&field=${field}`

  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledservicesettingsfileupload`, formData, { headers: { "Content-Type": "multipart/form-data" } })
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledservicesettingsfileupload`, formData, { headers: { "Content-Type": "multipart/form-data" } })
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettingsfileupload/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItems = async (idList) => { 
  let data = []
  await Api.delete(`/calledservicesettingsfileupload/items/${idList}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}