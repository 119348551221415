import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, IconButton, Switch, TextField, Typography, DialogContentText } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';
import * as componentActions from '../../../../redux/actions/calledservicesettingsfileuploads'
import * as calledServiceSettingsFileUploadValidationComponentActions from '../../../../redux/actions/calledservicesettingsfileuploadvalidations'
import Api from '../../../../services/api'
import { editItem } from '../../../../services/calledservicesettingsfileupload.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import CalledServiceSettingsFileUploadValidationItemDialog from './validationDialog'
import * as CalledServiceSettingsFileUploadAttachmentService from '../../../../services/calledservicesettingsfileuploadattachment.service'
import * as CalledServiceSettingsFileUploadValidationService from '../../../../services/calledservicesettingsfileuploadvalidation.service'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import AttachFileIcon from '@mui/icons-material/AttachFile';

function UpdateCalledServiceSettingsFileUploadItemDialog(props) {

  const { classes, intl, userId, hirerId } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [description, setDescription] = useState('')
  const [fileLimit, setFileLimit] = useState(0)
  const [isRequired, setIsRequired] = useState(false)
  const [files, setFiles] = useState([])

  const [calledServiceSettingsFileUploadAttachments, setCalledServiceSettingsFileUploadAttachments] = useState([])
  const [calledServiceSettingsFileUploadValidations, setCalledServiceSettingsFileUploadValidations] = useState([])

  const [calledServiceSettingFileUploadValidationDialogOpen, setCalledServiceSettingFileUploadValidationDialogOpen] = useState(false)

  const [openDeleteValidation, setOpenDeleteValidation] = useState(false)
  const [deleteValidationItemId, setDeleteValidationItemId] = useState(null)

  const [orderList, setOrderList] = useState([])
  const [organizationFieldList, setFileUploadList] = useState([])

  useEffect(() => {
    PapiroConsole.log("=== props.calledservicesettingsfileuploads ===")
    PapiroConsole.log(props.calledservicesettingsfileuploads)
    if (props.calledservicesettingsfileuploads && props.calledservicesettingsfileuploads.calledservicesettingsfileupload != null) {
      setId(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.id)
      setLabel(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.label)
      setOrder({ id: props.calledservicesettingsfileuploads.calledservicesettingsfileupload.order, name: `${props.calledservicesettingsfileuploads.calledservicesettingsfileupload.order}` })
      setDescription(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.description)
      setFileLimit(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.filelimit)
      setIsRequired(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.isrequired)
    }
  }, [props.calledservicesettingsfileuploads])

  useEffect(() => {
    PapiroConsole.log("=== props.open ===")
    PapiroConsole.log(props.open)
    setOpen(props.open)
    props.handleOpen(props.open)

    if (props.open) {
      setFiles([])
      getAllCalledServiceSettingsFileUploadAttachments(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.id)
      getAllCalledServiceSettingsFileUploadValidations(props.calledservicesettingsfileuploads.calledservicesettingsfileupload.id)
    }
  }, [props.open])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount

      if (order && !isNaN(order))
        setOrder({ id: order, name: `${order}` })
      else
        setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })
      
      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([ { id: 1, name: '1' } ])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const showMessageTypeName = (flag) => {
    let result = flag != null && flag == true ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' })
    return result
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const closeDeleteValidation = () => {
    setOpenDeleteValidation(false)
    setDeleteValidationItemId(null)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const classes2 = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      }
    });
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
  }

  const validateImagesAtachments = (filesList) => {
    if (typeof filesList === 'object') {
      let count = 0
      let fileList = []
      Object.values(filesList).forEach(val => {
        ++count
        if (isInvalidFileType(val.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
        } else
          fileList.push(val)
      })
      setFiles(fileList)
      PapiroConsole.log(files.length)
      PapiroConsole.log(calledServiceSettingsFileUploadAttachments.length)
      if(fileList.length > 2 || ((fileList.length + calledServiceSettingsFileUploadAttachments.length) > 2)) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    } else {
      let fileList = []
      filesList.foreach((item, idx) => {
        if (!isInvalidFileType(item.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
          return
        } else
          fileList.push(item)
      })
      setFiles(fileList)
      if(fileList.length > 2 || ((fileList.length + calledServiceSettingsFileUploadAttachments.length) > 2)) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    }
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const openLinkFile = url => {
    window.open(`${url}`, '_blank');
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (order == null || (order != null && order.id && order.id === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    } else if (fileLimit == null || (fileLimit != null && fileLimit === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Limite de arquivos' é obrigatório")
      return false
    }

    return true
  }

  const handleOpenCalledServiceSettingFileUploadValidationDialog = isOpen => {
    setCalledServiceSettingFileUploadValidationDialogOpen(isOpen)
  }

  const handleSetCalledServiceSettingFileUploadValidationList = itemList => setCalledServiceSettingsFileUploadValidations(itemList)

  const updateItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("Label", label)
      data.append("Order", order.id)
      data.append("Description", description)
      data.append("FileLimit", fileLimit)
      data.append("IsRequired", isRequired)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("UpdatedUser", userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await editItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
        setFiles([])
        if (response.data != null) {
          PapiroConsole.log("=== updateItem ===")
          PapiroConsole.log(props.calledservicesettingsfileuploads)
          let oldItemsList = props.calledservicesettingsfileuploads && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.length > 0 ? [...props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist] : []
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)
          PapiroConsole.log(oldItemsList)
          if (props.calledservicesettingsfileuploads.calledservicesettingsfileupload && props.calledservicesettingsfileuploads.calledservicesettingsfileupload.order != null && props.calledservicesettingsfileuploads.calledservicesettingsfileupload.order != order.id) {
            let orderFilter = [...oldItemsList]
            PapiroConsole.log(orderFilter)
            if (orderFilter && orderFilter.length > 0) {
              let oldOrder = props.calledservicesettingsfileuploads.calledservicesettingsfileupload && props.calledservicesettingsfileuploads.calledservicesettingsfileupload.order
              let newOrder = order.id
              let lastOrder = props.calledservicesettingsfileuploads && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.length > 0 ? props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.length : 1
              let newOrderFilterList = orderFilter.map(item => {
                let orderData = item.order

                if (oldOrder == lastOrder && item.order >= newOrder)
                  orderData = item.order + 1
                else if (oldOrder == 1 && item.order <= newOrder)
                  orderData = item.order - 1
                else
                {
                  if (newOrder - oldOrder > 0)
                  {
                    if (item.order > oldOrder && item.order <= newOrder)
                      orderData = item.order - 1
                  }
                  else
                  {
                    if (item.order < newOrder)
                      orderData = item.order + 1
                  }
                }
                
                return {
                  ...item,
                  order: orderData
                }
              })
              let newOrderFilterListIds = orderFilter.map(item => item.id)
              PapiroConsole.log(newOrderFilterList)

              let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
              PapiroConsole.log(notOrderFilterList)
              oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
              PapiroConsole.log(oldItemsList)

            }
          }
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)
          let itemList = [...oldItemsList, response.data]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsFileUploadsList(itemList)
        }
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }

  const deleteFileUploadAttachmentItem = async itemId => {
    setLoading(true)

    let response = await CalledServiceSettingsFileUploadAttachmentService.deleteItem(itemId)
    if (response && response.success) {
      setLoading(false)
      let notItemList = calledServiceSettingsFileUploadAttachments.filter(item => item.id != itemId)
      setCalledServiceSettingsFileUploadAttachments(notItemList)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const getAllCalledServiceSettingsFileUploadAttachments = async (id) => {
    setLoading(true)

    let response = await CalledServiceSettingsFileUploadAttachmentService.getAllByIdWithAttachmentIncludes(id)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsFileUploadAttachments(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const getAllCalledServiceSettingsFileUploadValidations = async (id) => {
    setLoading(true)

    let response = await CalledServiceSettingsFileUploadValidationService.getAllByCalledServiceSettingsFileUploadIdWithoutIncludes(id)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsFileUploadValidations(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const deleteValidationItem = async _ => {
    setLoading(true)

    let response = await CalledServiceSettingsFileUploadValidationService.deleteItem(deleteValidationItemId)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsFileUploadValidations([])

      setDeleteValidationItemId(null)
      setOpenDeleteValidation(false)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <>
      <Dialog
        open={openDeleteValidation}
        fullWidth={true}
        maxWidth="sm"
        onClose={closeDeleteValidation}
        scroll="body"
        aria-labelledby="form-delete-called-service-setting-file-upload-validation-dialog-title" >
        <DialogTitle id="form-delete-called-service-setting-file-upload-validation-dialog-title">
          Excluir validação
          <ClearIcon onClick={closeDeleteValidation}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContentText style={{ paddingLeft: '5%' }}>
          Você tem certeza que desejar excluir esta validação?
        </DialogContentText>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={closeDeleteValidation} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          {id > 0 && <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={deleteValidationItem} >
            {intl.formatMessage({id:"delete"})}
          </Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-edit-called-service-setting-file-upload-dialog-title" >
        <DialogTitle id="form-edit-called-service-setting-file-upload-dialog-title">
          Editar campo de upload de arquivos
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          { id > 0 ? (<Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="label"
                variant="outlined"
                fullWidth
                id="label"
                label={`${intl.formatMessage({ id: "name" })}`}
                value={label}
                required
                onChange={(e) => setLabel(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label={`${intl.formatMessage({ id: "item.order" })}*`}
                options={orderList}
                stateName='order'
                changeSelect={changeValues}
                selected={order} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Ordem de exibição do campo personalizado</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={9} md={10} lg={11} style={{ marginBottom: 50 }}>
              <Typography>
                Descrição
              </Typography>
              <ReactQuill
                id="description"
                name="description"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                value={description}
                style={{ height: 200 }}
                fullWidth
                required
                InputLabelProps={{ shrink: description && description.length > 0 ? true : false }}
                variant="outlined"
                onChange={(value) => setDescription(value)} />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={1} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
            <Tooltip title={<h5>Descrição do campo, que será exibida no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} >
              <TextField
                type="number"
                name="fileLimit"
                variant="outlined"
                fullWidth
                id="fileLimit"
                label={`Limite de arquivos`}
                value={fileLimit}
                required
                onChange={(e) => setFileLimit(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Limite total de arquivos que podem ser enviados no campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
            {(props.calledservicesettings && props.calledservicesettings.calledservicesettings && props.calledservicesettings.calledservicesettings.turnonfacelivenessvalidation) && <Grid item xs={11} sm={11} md={11} lg={8} >
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Validação</Typography>
            </Grid>}
            {(props.calledservicesettings && props.calledservicesettings.calledservicesettings && props.calledservicesettings.calledservicesettings.turnonfacelivenessvalidation) && <Grid item xs={1} sm={1} md={1} lg={4}/>}
            <Grid item xs={11} sm={11} md={11} lg={8} >
              { (props.calledservicesettings && props.calledservicesettings.calledservicesettings && props.calledservicesettings.calledservicesettings.turnonfacelivenessvalidation && calledServiceSettingsFileUploadValidations.length > 0) && <Grid container spacing={2}>
                {calledServiceSettingsFileUploadValidations.map(item => {
                  return (
                    <Grid key={item.id} item xs={12} sm={12} md={12} lg={12}>
                      <div style={{
                        border: '1px dotted rgba(0, 0, 0, .2)',
                        borderRadius: 15,
                        padding: 15,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'rgba(63, 81, 180, .2)'
                      }}>
                        <div>
                          <div>
                            <strong>Validações do anexo: </strong>
                          </div>
                          <div>
                            <strong>Comparar rosto do anexo com selfie capturada na verificação de vida?</strong> {showMessageTypeName(item.comparewithfaceliveness)}
                          </div>
                          <div>
                            <strong>Verificar se anexo é um documento?</strong> {showMessageTypeName(item.validatedocument)}
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <IconButton
                            color="primary"
                            aria-label="edit-validation"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              props.addCalledServiceSettingsFileUploadValidation(item)
                              handleOpenCalledServiceSettingFileUploadValidationDialog(true)
                            }} >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            aria-label="delete-validation"
                            onClick={() => {
                              setDeleteValidationItemId(item.id)
                              setOpenDeleteValidation(true)
                            }} >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </Grid>
                )})}
              </Grid>}
              { calledServiceSettingsFileUploadValidations.length == 0 && <Button
                variant='contained'
                type="button"
                startIcon={<AddIcon />}
                onClick={() => handleOpenCalledServiceSettingFileUploadValidationDialog(true)} >
                Adicionar validação
              </Button> }
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagen informativas (Máximo de 2 arquivos)</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            { calledServiceSettingsFileUploadAttachments.length < 2 && (<Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                  <Dropzone maxFiles={2} onDrop={acceptedFiles =>  validateImagesAtachments([ ...acceptedFiles, ...files ])}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <AttachFileIcon  />
                      <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage  id="drag.and.drop.files" />
                      </Typography>
                    </div>
                    )}
                  </Dropzone>
                  {files.map(file => {
                  return (
                    <div className={props.classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                    </div>
                    )
                  })}
                </div>
              </section>
            </Grid>)}
            { calledServiceSettingsFileUploadAttachments.length < 2 && <Grid item xs={1} sm={1} md={1} lg={4} />}
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={props.classes.attachmentsBox}>
                  <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                    <ul>
                {calledServiceSettingsFileUploadAttachments.map(item => {
                  return (
                      <li key={item.attachment.path}>
                        <AttachFile /> <Button onClick={() => openLinkFile(item.attachment.url)}>{item.attachment.description}</Button>
                        <DeleteForever onClick={() => deleteFileUploadAttachmentItem(item.id)} style={{ cursor: "pointer" }} />
                      </li>  
                    )
                })}
                    </ul>
                  </ImageList>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
          </Grid>) :
          (
            <Grid container spacing={2} style={{ padding: 10 }} >
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }} >
                <CircularProgress color='secondary' />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          {id > 0 && <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={updateItem} >
            {intl.formatMessage({id:"save"})}
          </Button>}
        </DialogActions>
      </Dialog>

      <CalledServiceSettingsFileUploadValidationItemDialog
        open={calledServiceSettingFileUploadValidationDialogOpen}
        handleOpen={handleOpenCalledServiceSettingFileUploadValidationDialog}
        handleSetItemList={handleSetCalledServiceSettingFileUploadValidationList}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsFileUploadId={id} />

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

UpdateCalledServiceSettingsFileUploadItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  itemData: PropTypes.object
}

const mapStateToProps = state => ({
  calledservicesettingsfileuploads: state.calledservicesettingsfileuploads,
  calledservicesettings: state.calledservicesettings
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, componentActions, calledServiceSettingsFileUploadValidationComponentActions), dispatch)

//props.addCalledServiceSettingsFileUploadValidation(itemToEdit)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UpdateCalledServiceSettingsFileUploadItemDialog)))