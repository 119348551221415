import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import Steps from '@mui/icons-material/Money';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import If from '../../../../components/conditionals/if'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'max-content',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.popper}`]: {
      position: 'absolute',
      inset: '0 auto auto 0',
      margin: 0,
      left: '-37%',
      transform: 'translate(1147px, 286px)'
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceIconSucess: {
    color: 'green'
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const useStylesColorPrimary = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  }
}))

const Budget = props => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false)

  const { className, total, comparation, positiveMonth, icon, hideComparation, customTitle, marginTop, colorPrimary, maxWidthTitle, detailsLink, detailsLinkFunction } = props;

  const classes = useStyles();
  const classes2 = useStylesColorPrimary();

  const intl = props.intl

  return (
    <Card
      className={clsx(classes.root, className)}
      style={{ marginTop: marginTop ? marginTop : 0 }}
    >
      <CardContent>
        <If test={!detailsLink}>
        <Grid
          container
          justify="space-between"
        >
          <Grid style={ maxWidthTitle ? { maxWidth: '80%'  } : {}} item xs={10} sm={10} md={11} lg={11}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {customTitle ? (customTitle) : (<FormattedMessage id="called.mounth" />)}
            </Typography>
            <Typography variant="h3">{total}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <Avatar className={colorPrimary ? classes2.avatar : classes.avatar}>
              {icon ? (icon) :
              (<MoneyIcon className={classes.icon} />)}
            </Avatar>
          </Grid>
        </Grid>
        {!hideComparation &&(
        <div className={classes.difference}>
          {positiveMonth && (
            <ArrowUpwardIcon className={classes.differenceIconSucess} />
          )}
          {!positiveMonth && (
            <ArrowDownwardIcon className={classes.differenceIcon} />
          )}
          <Typography
            className={positiveMonth ? classes.differenceIconSucess : classes.differenceIcon}
            variant="body2"
          >
            {comparation}%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            &nbsp; {<FormattedMessage id="relation.last.mounth" />}
          </Typography>
        </div>
        )}
      </If>
      <If test={detailsLink}>
        <Grid
          container
          justify="space-between">
          <Grid style={ maxWidthTitle ? { maxWidth: '80%'  } : {}} item xs={9} sm={10} md={10} lg={10}>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {customTitle ? (customTitle) : (<FormattedMessage id="called.mounth" />)}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center">
                  <Grid item>
                    <Typography variant="h3">{total}</Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                    { detailsLink && (<Typography onClick={() => setTooltipIsOpen(!tooltipIsOpen)} className={classes.secondaryColor} style={{ textDecoration: 'underline', cursor: 'pointer' }} variant="caption">Detalhes</Typography>) }
                    { detailsLinkFunction && (
                      <HtmlTooltip
                        interactive
                        placement="bottom-start"
                        open={tooltipIsOpen}
                        title={
                          <React.Fragment>
                            <Grid container spacing={2}>
                              {detailsLinkFunction}
                              <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                                  <Grid item xs={2} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                      type='button'
                                      variant='contained'
                                      color='primary'
                                      onClick={() => setTooltipIsOpen(false)}
                                      fullWidth >
                                      Fechar
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        }>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </HtmlTooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={2}>
            <Avatar className={colorPrimary ? classes2.avatar : classes.avatar}>
              {icon ? (icon) :
              (<MoneyIcon className={classes.icon} />)}
            </Avatar>
          </Grid>
        </Grid>
        {!hideComparation &&(
        <div className={classes.difference}>
          {positiveMonth && (
            <ArrowUpwardIcon className={classes.differenceIconSucess} />
          )}
          {!positiveMonth && (
            <ArrowDownwardIcon className={classes.differenceIcon} />
          )}
          <Typography
            className={positiveMonth ? classes.differenceIconSucess : classes.differenceIcon}
            variant="body2"
          >
            {comparation}%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            &nbsp; {<FormattedMessage id="relation.last.mounth" />}
          </Typography>
        </div>
        )}
      </If>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  comparation: PropTypes.number,
  positiveMonth: PropTypes.bool
};

export default injectIntl(Budget);
