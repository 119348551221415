import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Api from '../../../services/api'
import * as Service from '../../../services/shippingservicesettings.service'
import * as TwoTalkRegistrationNumberRequestService from '../../../services/twotalk.registration.number.request.service'
import { FormattedMessage } from 'react-intl'
import SimpleHelp from "../../../components/help"
import SimpleCheckBox from '../../../components/checkbox/check'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { PapiroConsole } from '../../../utils/papiroConsole'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as shippingservicesettingsActions from '../../../redux/actions/shippingservicesettings';
import { DataGrid } from '@mui/x-data-grid'
import DialogConfirmation from '../../../components/dialog-confirmation'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogCrud from './satisfactionsurveysettingsdetailsdialog.js'
import * as SatisfactionSurveySettingsDetailService from '../../../services/satisfactionsurveysettingsdetail.service';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import { Autocomplete } from '@mui/material';

class ShippingServiceSettings extends Component {

  constructor(props) {
    super(props)

    const { shippingservicesettings, userId, hirerId, satisfactionSurveySettingsDetailList } = this.props
    
    let itemData = {}
    if (shippingservicesettings && shippingservicesettings.shippingservicesettings && shippingservicesettings.shippingservicesettings.id)
      itemData = shippingservicesettings.shippingservicesettings

    PapiroConsole.log("shippingservicesettings")
    PapiroConsole.log(shippingservicesettings)

    this.state = {
      shippingservicesettings: {
        id: itemData && itemData.id ? itemData.id : 0,
        createduser: userId,
        updateduser: userId,
        hirerid: itemData && itemData.hirerid ? itemData.hirerid : hirerId,
        mailtorequesteraftercalledcreation: itemData && itemData.mailtorequesteraftercalledcreation ? itemData.mailtorequesteraftercalledcreation : false,
        mailtoassigneraftercalledcreation: itemData && itemData.mailtoassigneraftercalledcreation ? itemData.mailtoassigneraftercalledcreation : false,
        mailtorequesterwhenatechnicalisassigned: itemData && itemData.mailtorequesterwhenatechnicalisassigned ? itemData.mailtorequesterwhenatechnicalisassigned : false,
        mailtoassignerwhenatechnicalisassigned: itemData && itemData.mailtoassignerwhenatechnicalisassigned ? itemData.mailtoassignerwhenatechnicalisassigned : false,
        mailtorequesterwhencalledisfinish: itemData && itemData.mailtorequesterwhencalledisfinish ? itemData.mailtorequesterwhencalledisfinish : false,
        mailtorequesterwhencalledisfinishshowfeedback: itemData && itemData.mailtorequesterwhencalledisfinishshowfeedback ? itemData.mailtorequesterwhencalledisfinishshowfeedback : false,
        mailtorequesterwhencalledisfinishshowcalledreport: itemData && itemData.mailtorequesterwhencalledisfinishshowcalledreport ? itemData.mailtorequesterwhencalledisfinishshowcalledreport : false,
        resendemailtorequesterafterwaittime: itemData && itemData.resendemailtorequesterafterwaittime ? itemData.resendemailtorequesterafterwaittime : false,
        mailtoassignerwhenrequesterevaluatescalled: itemData && itemData.mailtoassignerwhenrequesterevaluatescalled ? itemData.mailtoassignerwhenrequesterevaluatescalled : false,
        mailtoassignerwhenrequesterevaluatescalledshowfeedback: itemData && itemData.mailtoassignerwhenrequesterevaluatescalledshowfeedback ? itemData.mailtoassignerwhenrequesterevaluatescalledshowfeedback : false,
        mailtorequesterwhencallediscancelled: itemData && itemData.mailtorequesterwhencallediscancelled ? itemData.mailtorequesterwhencallediscancelled : false,
        mailtoassignerwhencallediscancelled: itemData && itemData.mailtoassignerwhencallediscancelled ? itemData.mailtoassignerwhencallediscancelled : false,
        mailtoadminwhencalledrejectionisaccepted: itemData && itemData.mailtoadminwhencalledrejectionisaccepted ? itemData.mailtoadminwhencalledrejectionisaccepted : false,
        mailtoassignerwhencalledrejectionisstarted: itemData && itemData.mailtoassignerwhencalledrejectionisstarted ? itemData.mailtoassignerwhencalledrejectionisstarted : false,
        smstorequesterwhencalledisfinish: itemData && itemData.smstorequesterwhencalledisfinish ? itemData.smstorequesterwhencalledisfinish : false,
        mailtorequesterwhenaccompanimentsend: itemData && itemData.mailtorequesterwhenaccompanimentsend ? itemData.mailtorequesterwhenaccompanimentsend : false,
        mailtoassignerwhenaccompanimentsend: itemData && itemData.mailtoassignerwhenaccompanimentsend ? itemData.mailtoassignerwhenaccompanimentsend : false,
        mailtoadminnpsevaluation: itemData && itemData.mailtoadminnpsevaluation ? itemData.mailtoadminnpsevaluation : false,
        mailtoassignerwhencalledisreopened: itemData && itemData.mailtoassignerwhencalledisreopened ? itemData.mailtoassignerwhencalledisreopened : false,
        enablesendingsatisfactionsurveythroughmessagingproviders: itemData && itemData.enablesendingsatisfactionsurveythroughmessagingproviders ? itemData.enablesendingsatisfactionsurveythroughmessagingproviders : false,
        registrationnumberrequestid : {id : 0, phonenumber : ''}
      },
      satisfactionsurveysettingsdetailslist: satisfactionSurveySettingsDetailList ?? [],
      satisfactionsurveysettingsdetailslisttodelete: [],
      satisfactionsurveysettingsdetailsdialog: false,
      registrationNumberRequestList : []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleSubmitDetails = this.handleSubmitDetails.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  async componentDidMount() {

    
    await this.getRegistrationsNumbersActives()

  }

  async getRegistrationsNumbersActives(){
    const { shippingservicesettings} = this.props;
    PapiroConsole.log("shippingservicesettings getRegistrationsNumbersActives")
    PapiroConsole.log(shippingservicesettings)
    var registrationnumberrequestid = shippingservicesettings.shippingservicesettings.registrationnumberrequestid;
    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    var result = await TwoTalkRegistrationNumberRequestService.getAll();
    //console.log(result.data);
      if (result.success) {
       
        const initialOption = result.data.find(
          option => option.id === registrationnumberrequestid,
        );

        this.setState(prevState => ({
          shippingservicesettings: {
            ...prevState.shippingservicesettings,
            registrationnumberrequestid :initialOption
          },
          registrationNumberRequestList : result.data,
          
        }));
        setLoading(false)
      
                      
      } else {
        setLoading(false)
        setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"}))
        Api.kickoff(result);
      }
};
async changeValues(stateName, value) {
  
  this.setState(prevState => ({
    shippingservicesettings: {
      ...prevState.shippingservicesettings,
      [stateName]: value
    }
  }));

}

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    if (name === 'mailtorequesterwhencalledisfinish' && !checked) {
      this.setState(prevState => ({
        shippingservicesettings: {
          ...prevState.shippingservicesettings,
          mailtorequesterwhencalledisfinishshowcalledreport: false,
          mailtorequesterwhencalledisfinishshowfeedback: false
        }
      }))
    } else if (name === 'mailtoassignerwhenrequesterevaluatescalled' && !checked) {
      this.setState(prevState => ({
        shippingservicesettings: {
          ...prevState.shippingservicesettings,
          mailtoassignerwhenrequesterevaluatescalledshowfeedback: false,
        }
      }))
    }

    this.setState(prevState => ({
      shippingservicesettings: {
        ...prevState.shippingservicesettings,
        [name]: checked
      }
    }))
  }

  setDetailsDelete = async (detail) => {
    this.setState({
      satisfactionsurveysettingsdetailslisttodelete: detail + "," + this.state.satisfactionsurveysettingsdetailslisttodelete
    })
  }

  handleCloseDialog = () => {
    this.setState({ satisfactionsurveysettingsdetailsdialog: false })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    let { shippingservicesettings } = this.state

    let data = new FormData();

    for (let key in shippingservicesettings) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1)
        data.append(key, moment(shippingservicesettings[key]).format('YYYY/MM/DD HH:mm:ss'))
      else if(key.toString().toLowerCase().indexOf("registrationnumberrequestid") > -1) {
        data.append(key, shippingservicesettings[key] ? shippingservicesettings[key].id : 0)
      }
      else
        data.append(key, shippingservicesettings[key])
    }
    data.append("configid", this.props.configId)
    if (shippingservicesettings.hirerid === 0)
      shippingservicesettings.hirerid = this.props.hirerId

    let isEdit = shippingservicesettings.id === 0 ? false : true

    let response = shippingservicesettings.id !== 0 ? await Service.editItem(data) : await Service.addItem(data)
    if (response && response.success) {
      setLoading(false)
      setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "add.success" }))
      if (response.data && response.data.id != null && typeof response.data.id !== 'undefined')
        this.props.addShippingServiceSettings(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  handleSubmitDetails = async (item, isEdit) => {
    item.configid = this.props.configId
    item.satisfactionsurveysettingsid = this.props.satisfactionsurveysettingsid
    let { intl, setLoading, setMessage } = this.props

    setLoading(true)
    if (isEdit) {
      item.updateduser = this.props.userId
    } else {
      item.createduser = this.props.userId
    }

    var result = isEdit ? await SatisfactionSurveySettingsDetailService.update(item) : await SatisfactionSurveySettingsDetailService.create(item)
    if (result.success) {
      let resultList = this.state.satisfactionsurveysettingsdetailslist
      if (isEdit) {
        resultList = [...resultList.filter(s => s.id != result.data.id), result.data]
      } else {
        resultList.push(result.data)
      }
      this.setState({ satisfactionsurveysettingsdetailslist: resultList })
      setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "add.success" }))
    } else {
      const intl = this.props.intl;
      var message = result.errors ? result.errors[0] : result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      setMessage(true, 'error', message)
      Api.kickoff(result)
    }
    setLoading(false)
  }

  handleDelete = async (id) => {
    let { intl, setLoading, setMessage } = this.props

    setLoading(true)

    var result = await SatisfactionSurveySettingsDetailService.deleteById(id)
    if (result.success) {
      this.setState({ satisfactionsurveysettingsdetailslist: this.state.satisfactionsurveysettingsdetailslist.filter(s => s.id != id)})
      setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
    } else {
      var message = result.errors ? result.errors[0] : result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      setMessage(true, 'error', message)
      Api.kickoff(result)
    }
    setLoading(false)
  }

  render() {
    const { classes, intl, setMessage } = this.props
    const { shippingservicesettings } = this.state

    PapiroConsole.log("this.state.registrationNumberRequestList")
    PapiroConsole.log(this.state.registrationNumberRequestList)
    PapiroConsole.log(this.state.shippingservicesettings.registrationnumberrequestid)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div>
        <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography component='h1' variant='h3'>
                {<FormattedMessage id="shippingservicesettings.mail.settings" />}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailaftercalledcreation" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtorequesteraftercalledcreation}
                        onChange={this.handleChangeSwitch}
                        name="mailtorequesteraftercalledcreation" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassigneraftercalledcreation}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassigneraftercalledcreation" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailaftercalledcreation.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailwhenatechnicalisassigned" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtorequesterwhenatechnicalisassigned}
                        onChange={this.handleChangeSwitch}
                        name="mailtorequesterwhenatechnicalisassigned" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassignerwhenatechnicalisassigned}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhenatechnicalisassigned" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailwhenatechnicalisassigned.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailwhenaccompanimentsend" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtorequesterwhenaccompanimentsend}
                        onChange={this.handleChangeSwitch}
                        name="mailtorequesterwhenaccompanimentsend" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassignerwhenaccompanimentsend}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhenaccompanimentsend" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailwhenaccompanimentsend.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailwhencalledisfinish" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtorequesterwhencalledisfinish}
                        onChange={this.handleChangeSwitch}
                        name="mailtorequesterwhencalledisfinish" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailwhencalledisfinish.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shippingservicesettings.mailtorequesterwhencalledisfinishshowfeedback}
                      onChange={this.handleChangeSwitch}
                      name="mailtorequesterwhencalledisfinishshowfeedback" />
                  }
                  label={intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledisfinishshowfeedback" })} />
              </FormGroup>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledisfinishshowfeedback.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shippingservicesettings.mailtorequesterwhencalledisfinishshowcalledreport}
                      onChange={this.handleChangeSwitch}
                      name="mailtorequesterwhencalledisfinishshowcalledreport" />
                  }
                  label={intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledisfinishshowcalledreport" })} />
              </FormGroup>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledisfinishshowcalledreport.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shippingservicesettings.resendemailtorequesterafterwaittime}
                      onChange={this.handleChangeSwitch}
                      name="resendemailtorequesterafterwaittime" />
                  }
                  label={intl.formatMessage({ id: "shippingservicesettings.resendemailtorequesterafterwaittime" })} />
              </FormGroup>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.resendemailtorequesterafterwaittime.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}

            {shippingservicesettings.resendemailtorequesterafterwaittime && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            {shippingservicesettings.resendemailtorequesterafterwaittime &&
              <Grid item xs={12} sm={11} style={{ paddingTop: 0 }} >
                <Button
                 style={{marginBottom : '15px'}}
                  variant='contained'
                  onClick={_ => this.setState({ satisfactionsurveysettingsdetailsdialog: true })}
                >
                  <FormattedMessage id="shippingservicesettings.resendemailtorequesterafterwaittime.config" />
                  </Button>
              </Grid>
            }

            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0}} />}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shippingservicesettings.enablesendingsatisfactionsurveythroughmessagingproviders}
                      onChange={this.handleChangeSwitch}
                      name="enablesendingsatisfactionsurveythroughmessagingproviders" />
                  }
                  label={intl.formatMessage({ id: "enable.sending.satisfaction.survey.through.messaging.providers" })} />
              </FormGroup>
            </Grid>}
            {shippingservicesettings.mailtorequesterwhencalledisfinish == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "enable.sending.satisfaction.survey.through.messaging.providers.config.info" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}

            {shippingservicesettings.enablesendingsatisfactionsurveythroughmessagingproviders == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            
            {shippingservicesettings.enablesendingsatisfactionsurveythroughmessagingproviders == true &&
              <Grid item xs={11}>
                <Autocomplete
                  options={this.state.registrationNumberRequestList}
                  
                  getOptionLabel={(option) => option.phonenumber}
                  onChange={(event, value) => this.changeValues('registrationnumberrequestid', value)}
                  renderInput={(params) => <TextField {...params} label="Selecione o telefone" variant="outlined" />}
                  style={{ width: 300 }}
                  value={shippingservicesettings.registrationnumberrequestid}
              />
              {/* <SimpleAutoComplete
                label="Selecione o telefone"
                options={this.state.registrationNumberRequestList}
                stateName='registrationnumberrequestid'
                changeSelect={this.changeValues}
                selected={shippingservicesettings.registrationnumberrequestid}
                /> */}
              </Grid>
            }
            

            
           
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailwhenrequesterevaluatescalled" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassignerwhenrequesterevaluatescalled}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhenrequesterevaluatescalled" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailwhenrequesterevaluatescalled.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            {shippingservicesettings.mailtoassignerwhenrequesterevaluatescalled == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
            {shippingservicesettings.mailtoassignerwhenrequesterevaluatescalled == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shippingservicesettings.mailtoassignerwhenrequesterevaluatescalledshowfeedback}
                      onChange={this.handleChangeSwitch}
                      name="mailtoassignerwhenrequesterevaluatescalledshowfeedback" />
                  }
                  label={intl.formatMessage({ id: "shippingservicesettings.mailwhenrequesterevaluatescalledshowfeedback" })} />
              </FormGroup>
            </Grid>}
            {shippingservicesettings.mailtoassignerwhenrequesterevaluatescalled == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailwhenrequesterevaluatescalledshowfeedback.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailtorequesterwhencalledrejectionisaccepted" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassignerwhencalledrejectionisstarted}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhencalledrejectionisstarted" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.admin" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledrejectionisaccepted.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailtorequesterwhencalledrejectionisstarted" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoadminwhencalledrejectionisaccepted}
                        onChange={this.handleChangeSwitch}
                        name="mailtoadminwhencalledrejectionisaccepted" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencalledrejectionisstarted.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailtorequesterwhencallediscancelled" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtorequesterwhencallediscancelled}
                        onChange={this.handleChangeSwitch}
                        name="mailtorequesterwhencallediscancelled" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoassignerwhencallediscancelled}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhencallediscancelled" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtorequesterwhencallediscancelled.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            {/*<Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailwhencalledisreopened" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={shippingservicesettings.mailtoassignerwhencalledisreopened}
                        onChange={this.handleChangeSwitch}
                        name="mailtoassignerwhencalledisreopened" />
                    }
                    label={intl.formatMessage({ id:"shippingservicesettings.mail.to.assigner" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({id:"shippingservicesettings.mailwhencalledisreopened.helper"})}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>*/}
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.mailtoadminnpsevaluation" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.mailtoadminnpsevaluation}
                        onChange={this.handleChangeSwitch}
                        name="mailtoadminnpsevaluation" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.admin" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.mailtoadminnpsevaluation.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 60 }}>
              <Typography component='h1' variant='h3'>
                {<FormattedMessage id="shippingservicesettings.sms.settings" />}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  <FormattedMessage id="shippingservicesettings.sms.sendwhencalledisfinish" />
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={shippingservicesettings.smstorequesterwhencalledisfinish}
                        onChange={this.handleChangeSwitch}
                        name="smstorequesterwhencalledisfinish" />
                    }
                    label={intl.formatMessage({ id: "shippingservicesettings.mail.to.requester" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>{intl.formatMessage({ id: "shippingservicesettings.sms.sendwhencalledisfinish.helper" })}</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={4}    >
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    olor='primary'
                    className={classes.submit} >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <DialogCrud
          intl={this.props.intl}
          msgAgree="Aceitar"
          msgDisagree="Cancelar"
          list={this.state.satisfactionsurveysettingsdetailslist}
          open={this.state.satisfactionsurveysettingsdetailsdialog}
          handleClose={this.handleCloseDialog}
          title={intl.formatMessage({ id: "satisfactionsurveysettingsdetails" })}
          columns={[
            { title: intl.formatMessage({ id: "message" }), field: 'text', width: "90%" },
            { title: intl.formatMessage({ id: "item.order" }), field: 'order', width: "5%" },
            { title: intl.formatMessage({ id: "wait.time" }), field: 'waittime', width: "5%" },
          ]}
          classes={classes}
          handleSubmit={this.handleSubmitDetails}
          setMessage={setMessage}
          handleDelete={this.handleDelete}
        />
      </div>
    )
  }
}

ShippingServiceSettings.propTypes = {
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  shippingservicesettings: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  configId: PropTypes.number.isRequired,
  updateList: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  shippingservicesettings: state.shippingservicesettings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(shippingservicesettingsActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShippingServiceSettings)