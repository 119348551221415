
import React, { Component } from 'react'
import { Overlay } from "../../styles/global"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Container, Button } from '@mui/material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as componentActions from "../../redux/actions/users"
import Logo from '../../components/logo/logo';
//import Api from '../../services/api'
import '../unavailable/style.css'

class ContractInvalid extends Component {

    constructor(props) {
      super(props);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement|null}
     */
    render() {
      const intl = this.props.intl;
        return (
            <div
                style={{
                    background: "white",
                    height: '100%'
                }}>
              <Container component="main" style={{ paddingTop: 150 }}>
                <div
                    style={{
                        float: 'left',
                        width: '42%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    <Logo key="groups-fieldservice-awrar6aw15" />
                </div>
                <div style={{ float: 'right', width: '58%', paddingLeft: 30 }}>
                    <h3 style={{ fontSize: '2em', color: 'black', fontWeight: 500 }}>{intl.formatMessage({id:"contract.invalid.expired.contract"})}</h3>
                    <h4 style={{ fontSize: '1.5em', color: '#37363D', fontWeight: 500 }}>{intl.formatMessage({id:"contract.invalid.questions.renew"})} <br/> {intl.formatMessage({id:"contract.invalid.contact.commercial"})}</h4>
                    <h5 style={{ color: '#37363D', fontWeight: 100 }}>{intl.formatMessage({id:"contract.invalid.contact.info"})}</h5>
                    <Button variant='outlined' color='primary'  onClick={e => {
                      var l = this.props.match.params.lang;
                      document.location.href=`/${l}/`
                    }}>Tentar novamente</Button>
                </div>
              </Container>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default connect(null, mapDispatchToProps)(withStyles(Styles)(ContractInvalid));