import * as moment from "moment";
import { PapiroConsole } from "../../utils/papiroConsole";

function getMove(status){
    var result = null;
    if(status.previousstatus.statustypeid == 1 && status.nextstatus.statustypeid == 2) { // NOVO && MOVIMENTO
        result=status;
    }
    return result;
}
function getLastMove(status){
    var result = null;
    if(status.previousstatus.statustypeid == 2 && status.nextstatus.statustypeid == 5 ) { // MOVIMENTO && ENCERRADO
        result=status;
        return false;
    }
    return result;
}
function isMinuteBeetween(minute, hours){
    var result = minute;
    var isBeetween=false;
    var nextstart = 0;
    for(var i=0; i<hours.length;i++){ 
        var item = hours[i];
        var start = item.starthour; 
        var end = item.endhour; 

        if(minute >=start && minute <=end){ //  verificando se o minuto está entre o intervalo
            isBeetween = true;
        }
        if(nextstart == 0){
            if(start > minute){
                nextstart=start;
            }
        }
    }
    if(! isBeetween){
        if(nextstart > result){
            result = nextstart;
        }

    }
    return result;

}

function calculateWork(openingdate,movedate, day, hours, organizationCalendarEvents, calledId){
    var now = new Date(movedate);
    var minutos_mudanca_status = movedate.diff(openingdate, 'minutes'); //  recuperando a diferenca de tempo entre criaçao e abertura em minutos
    var currDate = openingdate.clone().startOf('day'); // clonando a data de abertura do chamado e zerando a hora; Essa data será a utilizada para percorrer os dias no for mais abaixo
    var lastDate = movedate.clone().startOf('day'); // clonando a primeira data de movimentacao do chamado e zerando a hora
    var minutes_worked=0;

    while(currDate.diff(lastDate) <= 0) { // andando os dias existentes entre abertura e movimentacao

        var timestart= (openingdate.hour()*60) + openingdate.minute(); // transformando a hora de abertura em minutos

        var timeend= (movedate.hour()*60) + movedate.minute(); // transformando a hora da primeira movimentacao em minutos
        day = currDate.weekday();  // recuperando o dia atual da semana
        var f = hours.filter(h => h.weekday == day).sort((a, b) => a.starthour - b.starthour); //  filtrando o array de expediente para considerar somente o dia atual da semana pego acima
        //console.log(timestart);
        timestart = isMinuteBeetween(timestart,f);
        //console.log(timestart);

        //console.log(timeend);
        //console.log(f);
        for(var i=0; i<f.length;i++){ // varrendo o array de expedientes
            var item = f[i];
            var end = item.endhour; // recuperando a hora final de expediente do dia
            if(currDate.clone().startOf('day').isSame(movedate.clone().startOf('day')) ){  // verificando se a data corrente chegou no dia que o chamado foi movimentado
                 if(timeend < end){ // se a hora de movimentação for menor que o fim do expediente, deve-se considerar ela
                    end=timeend;
                 }
            }
			var holiday = null;
			if(organizationCalendarEvents){
				holiday = organizationCalendarEvents.filter(calendar => {
					if(moment(calendar.eventdate).startOf('day').isSame(currDate.clone().startOf('day')) === true || (calendar.recurrenteveryear == true && currDate.date() == calendar.day && currDate.month() == (calendar.month - 1)))
						return calendar
				})
			}

            for(var start=item.starthour; start<=end;start++){ //  varrendo o intervalo do expediente
                    if(start >=timestart && timestart <=end){ //  verificando se o tempo em minutos da abertura está no intervalo do expediente; somente minutos dentro do intervalo serão considerados como minutos trabalhados
                        if (holiday == null || (holiday != null && holiday.length == 0) || (holiday != null && holiday.length > 0 && start > holiday.starttime && start > holiday.endtime && end > holiday.endtime))
                        minutes_worked++; //  contando os minutos trabalhados
                    }
            }
        }
        currDate.add(1, 'days'); // percorrendo 1 dia na data corrente(que iniciou com a data de abertura) para o "for"
        openingdate.add(1, 'days').startOf('day'); // // percorrendo 1 dia na data de abertura zerando a hora, para poder contar do início do expediente no próximo dia

    }
    //console.log('minutes_worked: '+minutes_worked);
    return minutes_worked;
}

function generateFullWork(){
    //0: {id: 6, starthour: 480, endhour: 720, weekday: 1}
    const min = 1;
    const max = 1000000;

    var result = [];

    for(var i=0; i<=6; i++){
        var obj = {};
        var rand = i+(min + Math.random() * (max - min));
        obj.id=rand;

        obj.starthour=0;
        obj.endhour=1440;
        obj.weekday=i;
        result.push(obj);
    }

    return result;

}

export function getSlaAtendimento(item,userSession){

    PapiroConsole.log(item)
    PapiroConsole.log("item sla aqui")
    if (item != null && typeof item !== 'undefined') {
    // console.log("chegou ate aqui no slaatendimento");
    // percentual de tempo corrido baseado na escala de horário até a primeira 
    // girada de status para em andamento
    var percentual = 0.0;
    // console.log("item");
    // console.log(item);
    var status = item.calledstatus.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
	var servicetime = 0;
	var solutiontime = 0;

	if(item.organizationcategory && item.organizationcategory.organizationsla !=null){
		 servicetime = item.organizationcategory.organizationsla.servicetime;
		 solutiontime = item.organizationcategory.organizationsla.solutiontime;
	}
	else{
		servicetime = item.organizationcategory.servicetime;
		solutiontime = item.organizationcategory.solutiontime;
	}
    
    var useslatime = item.organizationcategory.useslatime;
    if(useslatime == false){
        if(item.organizationcategory.servicetime > 0 && item.organizationcategory.solutiontime > 0){
            servicetime = item.organizationcategory.servicetime;
            solutiontime = item.organizationcategory.solutiontime;
        }
    }
    var hours = item.organizationcategory.organizationsla && item.organizationcategory.organizationsla.workhour ? item.organizationcategory.organizationsla.workhour.scheduleworkhours : generateFullWork();
    var countType = 0;
    if(item.organizationcategory.slacounttype != null){
        countType = item.organizationcategory.slacounttype.counttype;
    }

    PapiroConsole.log(countType)
    PapiroConsole.log("countType")

    if(countType == 1){ // escala do usuário
        if( userSession.user.workhour != null){
            hours = userSession.user.workhour.scheduleworkhours;
        }
        else{
            hours = generateFullWork();
        }
    }
    else if(countType == 3){ // tempo corrido
        hours = generateFullWork();
    }

    const dayofweek = item.dayofweek;
    const currentdate = moment(item.currentdate);
   
    var minutes_worked = 0;
    var count = 0;

    status.forEach(element => {
		//console.log('STATUS DE CADA CHAMADO ->'+JSON.stringify(element.nextstatus));
        
		if(''+element.nextstatus.counttimesla === 'true'){ // precisou comparar como string para funcionar
            if(element.nextstatus.statustypeid == 1){
                //console.log("trueA");
                var first = "";
                
                if(status[count+1] != null)
                {
                    first = status[count+1];
                }

                if(first != null)
                {
                    var movedate = first.createdat;
                }
                else
                {
                    var movedate = currentdate;
                }

                var movedate = moment(movedate);
                var openingdate = moment(element.createdat);

                minutes_worked += calculateWork(openingdate,movedate,dayofweek,hours, item.organizationcalendarevents, item.id);
            }
        }
        count = count + 1;
    });
    
      //servicetime     -----------  100%
      //minutes_worked  ------------ x
      /*
          720x = 103*100
          x = 10.300/720
          x = 14.30%
      */
     
    if (minutes_worked > 0 && servicetime > 0) {
        percentual =  ((minutes_worked*100)/servicetime).toFixed(2);
    }
      
     if(percentual == 0.00){
        percentual=0;
       }
    
       PapiroConsole.log(percentual)
  return percentual;
    } else {
        return 0
    }
}

export function getSlaSolucao(item,userSession){
    if (item != null && typeof item !== 'undefined') {
    // percentual de tempo corrido baseado na escala de horário até a 
    // conclusao do chamado, onde o status vai para concluido;
    var percentual = 0.0;
    //return percentual;
	var servicetime = 0;
	var solutiontime = 0;


	var status = item.calledstatus.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));


	if(item.organizationcategory.organizationsla !=null){
		servicetime = item.organizationcategory.organizationsla.servicetime;
		solutiontime = item.organizationcategory.organizationsla.solutiontime;
    }
    else{
	   servicetime = item.organizationcategory.servicetime;
	   solutiontime = item.organizationcategory.solutiontime;
    }

    var openingdate = item.openingdate;
    var useslatime = item.organizationcategory.useslatime;


    if(useslatime == false){

        if(item.organizationcategory.servicetime > 0 && item.organizationcategory.solutiontime > 0){
        servicetime = item.organizationcategory.servicetime;
        solutiontime = item.organizationcategory.solutiontime;
        }

    }
    var hours = item.organizationcategory.organizationsla && item.organizationcategory.organizationsla.workhour ? item.organizationcategory.organizationsla.workhour.scheduleworkhours : generateFullWork();

    var countType = 0;
    if(item.organizationcategory.slacounttype != null){
        countType = item.organizationcategory.slacounttype.counttype;
    }

    if(countType == 1){ // escala do usuário
        if( userSession.user.workhour != null){
            hours = userSession.user.workhour.scheduleworkhours;
        }
        else{
            hours = generateFullWork();
        }
    }
    else if(countType == 3){ // tempo corrido
        hours = generateFullWork();
    }

    const dayofweek = item.dayofweek;
    const currentdate = moment(item.currentdate);
    var minutes_worked = 0;
    var countStatus = status.length;
    var count = 0;

    status.forEach(element => {
        if(''+element.nextstatus.counttimesla === 'true'){
            if(element.nextstatus.statustypeid != 5 && element.nextstatus.id != 11 && element.nextstatus.id != 12){
                var last = "";
            
                if(status[count+1] != null){
                    last = status[count+1];
                }
    
                if(last != null){
                    var movedate = last.createdat;
                }
                else
                {
                    var movedate = currentdate;
                }
    
                var openingdate = moment(element.createdat);
                var movedate = moment(movedate);
    
                minutes_worked += calculateWork(openingdate,movedate,dayofweek, hours, item.organizationcalendarevents, item.id);
            }
        }
        count = count + 1;
    });

      //solutiontime     -----------  100%
      //minutes_worked  ------------ x
      /*
          720x = 103*100
          x = 10.300/720
          x = 14.30%
      */
    if (minutes_worked > 0 && solutiontime > 0) {
        percentual =  ((minutes_worked*100)/solutiontime).toFixed(2)
    }

   if(percentual == 0.00){
    percentual=0;
   }

  return percentual;
    } else {
        return 0
    }
}

