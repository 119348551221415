import { Avatar, Button, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MyMaterialTable from '../../../components/table';
import { PapiroConsole } from "../../../utils/papiroConsole";
import { QueuePlayNext } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import TitleName from "../../../components/title-name";
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from "react-quill";

const options = {
    read: "read",
    create: "create",
    update: "update"
}

const reactQuillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
}

const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const defaultstatevalues = {
    order: 2,
    waittime: 0.0,
    text: "",
};

export default function DialogCrud(props) {
    const { intl, classes, list, columns, title, open, handleClose, handleSubmit, setMessage, handleDelete } = props

    const [crudOption, setCrudOption] = useState(options.read)
    const [item, setItem] = useState(defaultstatevalues)

    useEffect(() => {
        setCrudOption(options.read)
    }, [open])

    const handleDeleteItem = (data, resolve) => {
        handleDelete(data.id)
        resolve()
    }

    const handleSubmitItem = () => {

        let message = document.getElementById('message')
        if (message != null) {
            let inputValue = ''
            let qlEditor = message.querySelector('.ql-container .ql-editor')
            if (qlEditor != null) {
                inputValue = `${qlEditor.innerHTML}`
            }
            item.text = inputValue
        }

        if (!item.order || item.order == null || item.order == "") {
            setMessage(true, 'error', "O campo " + intl.formatMessage({ id: "item.order" }) + " é obrigatório")
            return
        }
        if (!item.waittime || item.waittime == null || item.waittime <= 0.0) {
            setMessage(true, 'error', "O campo " + intl.formatMessage({ id: "wait.time" }) + " é obrigatório")
            return
        }
        if (crudOption == options.create) {
            handleSubmit(item, false)
        }
        if (crudOption == options.update) {
            handleSubmit(item, true)
        }
    }

    const handleInputChange = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value })
    }

    const handleChangeText = (value) => {
        setItem({ ...item, text: value })
    }

    function formFields() {
        PapiroConsole.log(item.waittime)
        return (
            <Grid container spacing={2} paddingTop={2} minHeight='fi'>
                <Grid item container direction='column' xs={12} sm={12}>
                    <FormattedMessage id="message" />
                    <Grid item paddingBottom={5}>
                        <ReactQuill
                            value={item.text || ""}
                            name="message" style={{ height: 200 }} modules={reactQuillModules} formats={reactQuillFormats}
                            fullWidth
                            required
                            variant="outlined"
                            id="message"
                            onChange={handleChangeText}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        inputProps={{ maxLength: 250 }}
                        fullWidth
                        type="number"
                        label={intl.formatMessage({ id: "item.order" })}
                        autoComplete='fname'
                        variant='outlined'
                        name='order'
                        onChange={handleInputChange}
                        value={item.order ?? ""}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        inputProps={{ maxLength: 250 }}
                        fullWidth
                        type="number"
                        label={intl.formatMessage({ id: "wait.time" })}
                        autoComplete='fname'
                        variant='outlined'
                        name='waittime'
                        onChange={handleInputChange}
                        value={item.waittime ?? 0}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                                onClick={handleSubmitItem}
                            >
                                {<FormattedMessage id="save" />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    switch (crudOption) {
        case options.read:
            return (
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle>
                        <Grid container justifyContent='space-between'>
                            <Grid item >
                                <Typography variant="h4">{title}</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleClose} color="primary">
                                    <CloseIcon className={classes.closeIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Button
                        style={{ marginLeft: 25, width: '120px' }}
                        variant='contained'
                        onClick={() => {
                            setItem({})
                            setCrudOption(options.create)
                        }}
                        color="primary"
                    >
                        {intl.formatMessage({ id: "new" })}
                    </Button>
                    <DialogContent >
                        <MyMaterialTable
                            title={title}
                            style={{ width: "100%" }}
                            rowClick={(event, rowData) => {
                                setItem(list.find(i => i.id == rowData.id))
                                setCrudOption(options.update)
                            }}
                            columns={columns}
                            data={list.map(i => ({ ...i, text: i.text.replace(/<[^>]*>?/gm, '')}))}
                            deleteItem={handleDeleteItem}
                        />
                    </DialogContent>
                </Dialog>
            )

        case options.create:
            return (
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogActions style={{ paddingInline: '24px' }}>
                        <Grid container spacing={6} justifyContent='space-between' alignItems='center'>
                            <Grid item style={{ paddingTop: 60 }}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={_ => setCrudOption(options.read)}
                                >
                                    {<FormattedMessage id="back" />}
                                </Button>
                            </Grid>

                            <Grid item >
                                <Button onClick={handleClose} color="primary">
                                    <CloseIcon className={classes.closeIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                    <DialogTitle component='main' maxWidth='md' alignSelf='center' style={{ paddingTop: 0 }} >
                        <Grid container spacing={1} alignItems='center' direction='column'>
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <QueuePlayNext />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography component='h1' variant='h5'>
                                    {<FormattedMessage id="add.item" />} <FormattedMessage id="satisfactionsurveysettingsdetails" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        {formFields()}
                    </DialogContent>
                </Dialog>
            )

        case options.update:
            return (
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogActions style={{ paddingInline: '24px' }} >
                        <Grid container spacing={6} justifyContent='space-between' alignItems='center'>
                            <Grid item style={{ paddingTop: 60 }} >
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={_ => setCrudOption(options.read)}
                                >
                                    {<FormattedMessage id="back" />}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={handleClose} color="primary">
                                    <CloseIcon className={classes.closeIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                    <DialogTitle component='main' maxWidth='md' alignSelf='center' style={{ paddingTop: 0 }} >
                        <Grid container spacing={1} alignItems='center' direction='column'>
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <QueuePlayNext />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography component='h1' variant='h5'>
                                    {<FormattedMessage id="update" />} <FormattedMessage id="satisfactionsurveysettingsdetails" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        {formFields()}
                    </DialogContent>
                </Dialog>
            )

        default:
            break;
    }

}

