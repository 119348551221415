export default function richtextarea(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              richtextarea: action.richtextarea,
          }
      default:
          return state;
  }
}
