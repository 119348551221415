import { Button, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/integration-user-variables.js';
import * as IntegrationUserVariableService from "../../../../services/integrationuservariable.service.js";
import Api from "../../../../services/api";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";

class IntegrationUserVariableGeneralData extends Component {

  constructor(props) {
    super(props);

    const { userSession, integrationUserVariable } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      createduser: userSession.user.id,
      id: integrationUserVariable?.id,
      name: integrationUserVariable?.name,
      integrationuservariabletypeid: null
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      optionsWebchatTypes: [],
      optionsIntegrationUserVariableTypes: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchOptionsIntegrationUserVariableTypes = async () => {
    this.setState({ loading: true });

    const { integrationUserVariable } = this.props;

    try {
      const response = await Api.get(`/integration-user-variable-types`);

      const { data } = response;

      if (data.success) {
        let selectedData = data.data?.find(item => item.id == integrationUserVariable?.integrationuservariabletypeid)

        this.setState(prevState => ({
          optionsIntegrationUserVariableTypes: data.data,
          item: {
            ...prevState.item,
            integrationuservariabletypeid: selectedData
          }
        }))

      } else {
        this.fetchDataNotSuccess(response);
        Api.kickoff(response);
      }

    } catch (err) {
      this.fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let input = {
      ...this.state.item,
      integrationuservariabletypeid: this.state.item.integrationuservariabletypeid?.id
    }

    try {
      const output = await IntegrationUserVariableService.update(input);

      if (output.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Registro atualizado com sucesso'
        });
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChangeAutoComplete(stateName, value) {  
    let data = value.id > 0 ? value : null ;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));
  }

  async componentDidMount() {
    await this.fetchOptionsIntegrationUserVariableTypes();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
          <Container component='main' maxWidth='lg'>
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data' style={{minWidth: '650px'}}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="IntegrationUserVariableTypeId"
                        label={intl.formatMessage({ id: "integration.user.variable.type" })}
                        options={this.state.optionsIntegrationUserVariableTypes}
                        stateName='integrationuservariabletypeid'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={item.integrationuservariabletypeid}
                        htmlOptions={true}
                        required 
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 225 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='name' 
                        onChange={this.handleInputChange} 
                        value={item.name} 
                        required 
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  integrationUserVariables: state.integrationUserVariables
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(IntegrationUserVariableGeneralData)));

IntegrationUserVariableGeneralData.contextType = AbilityContext;