import React, { Component, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DraftsIcon from '@mui/icons-material/Drafts';
import { ExitToApp, Assignment } from '@mui/icons-material';
import { bindActionCreators } from 'redux';
import * as userSessionActions from '../../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import If from './../conditionals/if'

const UserRoles = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const intl = props.intl;
    let history = useHistory();

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const logOut = () => {
      handleClose();
      userSessionActions.removeUserSession();

      let language = window.location.pathname.split('/')[1]
      if (language != "pt" && language != "es" && language != "en")
        language = "pt"

      history.push(`/${language}/`);
    };
    
    return (
        <div>
          <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} inline="true">
            <Typography variant="h6" className={props.class}>
              <div className={props.class} style={{ color:'#ffffff' }}>
                {props.user.name}
              </div>
            </Typography>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logOut}><ExitToApp /> <span style={{ marginLeft: '10px' }}>Logout</span></MenuItem>
          </Menu>
        </div>
      );

}


class UserInfo extends Component {
    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
     
    }
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, userSession, history, location, match } = this.props;
        return (
            <div >
                <UserRoles
                  intl={this.props.intl}
                  user={userSession.user}
                  class={classes.title}
                  history={ history }
                  location={ location }
                  match={ match } />
            </div>
        );
    }
  }

  const mapStateToProps = state => ({
    userSession: state.userSession
  });

  const mapDispatchToProps = dispatch =>
  bindActionCreators(userSessionActions, dispatch);
  
  export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserInfo))));
  