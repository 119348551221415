import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, Switch, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete.js";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import * as componentActions from '../../redux/actions/calledflows';
import Api from "../../services/api";
import Api2Talk from "../../services/api2talk";
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';

import Autocomplete from '@mui/material/Autocomplete';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DeleteIcon from '@mui/icons-material/Delete';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SimpleCheckBox from '../../components/checkbox/check';
import * as ServiceUser from '../../services/techinicalteamuser.service';
import { PapiroConsole } from "../../utils/papiroConsole.js";

import * as CalledService from '../../services/called.service';



class ChannelRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.formDataInitialState = {
      CreatedUser: userSession.user.id,
      ServiceRulesId : null, 
      TechinicalTeamId: null,
      DistributionStrategyId: null,
      MessageProviderId: null,
      Name: '',
      DisplayName: '',
      Published: false,
      Active: false,
      DefaultOrganizationId: null,
      DefaultOrganizationCategoryId: null
    }; 

    this.state = {
      formData: this.formDataInitialState,
      files: [],
      organizationsList: [],
      categoryList: [],
      optionsServiceRules: [],
      optionsTechinicalTeams: [],
      optionsMessageProviders: [],
      optionsDistributionsStrategies: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      selectedTechnicians: [],
      autocompleteInputValue: '',
      techinicalList: [],
      techinicalListTotal: [],
      ViewAllTechs : false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.getCategoriesByOrganization = this.getCategoriesByOrganization.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
      const intl = this.props.intl;
      this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    const formData = this.state.formData;

    Object.assign(formData, {
      DistributionStrategyId: formData.DistributionStrategyId?.id ?? null,
      MessageProviderId: formData.MessageProviderId?.id ?? null,
      ServiceRulesId: formData.ServiceRulesId?.id ?? null, 
      TechinicalTeamId: formData.TechinicalTeamId?.id ?? null,
      DefaultOrganizationId: formData.DefaultOrganizationId?.id ?? null,
      DefaultOrganizationCategoryId: formData.DefaultOrganizationCategoryId?.id ?? null
    })
    formData.selectedTechnicians = this.state.selectedTechnicians

    this.setState({ loading: true });

    Api2Talk.post(`/channel`, formData)
      .then(result => {
          if (result.data.success) {
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  formData: this.formDataInitialState,
                  techinicalList : [],
                  selectedTechnicians: [],
                  notificationMessage: 'Registro incluído com sucesso'
              });
          } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  };

  fetchOptionsServiceRules = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/servicerules`)
      .then(result => {
          this.setState({ loading: false });
          if (result.data.success) {
              const serviceRulesCollection = result.data.data;
              const serviceRulesOptions =  serviceRulesCollection.map((item) => ({ id: item.id, name: item.name }));
              this.setState(prevState => ({
                optionsServiceRules: serviceRulesOptions
              }))
          } else {
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  }

  fetchOptionsTechinicalTeam = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/techinicalteam`)
      .then(result => {
          this.setState({ loading: false });
          if (result.data.success) {
              const techinicalTeamOptions = result.data.data;
              this.setState(prevState => ({
                optionsTechinicalTeams: techinicalTeamOptions
              }))
          } else {
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  }

  fetchOptionsMessageProvider = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/messageprovider`)
      .then(result => {
          this.setState({ loading: false });
          if (result.data.success) {
              const messageProviderOptions = result.data.data;
              this.setState(prevState => ({
                optionsMessageProviders: messageProviderOptions
              }))
          } else {
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  }

  fetchOptionsDistributionsStrategies = async () => {
    this.setState({ loading: true });
    Api.get(`/distributionstrategies`)
      .then(result => {
          this.setState({ loading: false });
          if (result.data.success) {
              const distriburionsStrategyCollection = result.data.data;
              const distributionsStrategiesOptions =  distriburionsStrategyCollection.map((item) => ({ id: item.id, name: item.name }));
              this.setState(prevState => ({
                optionsDistributionsStrategies: distributionsStrategiesOptions
              }))
          } else {
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  }

  handleChangeAutoComplete = async (stateName, value) => {  
    let data = value.id > 0 ? value : null ;

    if(stateName == "TechinicalTeamId"){
      if(value && value.id != 0){
          this.getAllTechByTeam(value.id);
      } 
      else{
        this.setState({techinicalList : [],techinicalListTotal : [], selectedTechnicians: []})
      }
    }

    if(stateName == "DefaultOrganizationId" && data) {
      await this.getCategoriesByOrganization(data.id);
    }
    
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [stateName]: data
      }
    }));
  }

  handleChangeCheckbox(stateName, value) {  
   
      if(stateName == "ViewAllTechs"){
        
        var techinicalList = []
        PapiroConsole.log(value)
        PapiroConsole.log("value")
        if(value == true){
            
          techinicalList = this.state.techinicalListTotal;
          
        }
        else{
          let result = this.state.techinicalListTotal
          techinicalList =  result.filter(p => p.enableuser != null);
        }

        this.setState({ViewAllTechs : value, techinicalList : techinicalList})

      }
  }

  async getAllTechByTeam(techinicalTeamId) {

    PapiroConsole.log(techinicalTeamId)
    PapiroConsole.log("techinicalTeamId")
    var result = await ServiceUser.getAllTechByTeamAndEnableUser2talk(techinicalTeamId);
    if (result.success) {
      PapiroConsole.log(result)
      var techinicalList = []
      
      if(this.state.ViewAllTechs){
        techinicalList = result.data;
      }
      else{

        techinicalList =  result.data.filter(p => p.enableuser != null);

      }
      this.setState({ loading: false, techinicalList : techinicalList, techinicalListTotal: result.data, selectedTechnicians : [] });
      
      return result.data;

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [stateName]: value
      }
    }));
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleTechnicianSelect = (technician) => {
    const order = this.state.selectedTechnicians.length + 1;
    this.setState((prevState) => ({
      selectedTechnicians: [...prevState.selectedTechnicians, { ...technician, order }],
    }));
  };

  filterOptions = (options, { inputValue }) => {
    const { selectedTechnicians } = this.state;

    return options.filter(
      (option) =>
        !selectedTechnicians.find((selected) => selected.id === option.id) &&
        !this.state.selectedTechnicians.find((selected) => selected.id === option.id)
    );
  };

  handleOrderChange = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedTechnicians = [...this.state.selectedTechnicians];
    const [movedTechnician] = updatedTechnicians.splice(result.source.index, 1);
    updatedTechnicians.splice(result.destination.index, 0, movedTechnician);

    // Atualiza a ordem após a reordenação
    updatedTechnicians.forEach((technician, index) => {
      technician.order = index + 1;
    });

    this.setState({ selectedTechnicians: updatedTechnicians });
  };

  handleTechnicianDelete = (index) => {
    const updatedTechnicians = [...this.state.selectedTechnicians];
    updatedTechnicians.splice(index, 1);

    // Atualiza a ordem após a exclusão
    updatedTechnicians.forEach((technician, i) => {
      technician.order = i + 1;
    });
    

    this.setState({ selectedTechnicians: updatedTechnicians });
  };

  async getOrganizations(isLoading = false) {
    if(isLoading) {
      this.setState({ loading: true });
    }
      
    const { userSession } = this.props
    let orgData = []

    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations();

      if (result.success) {
        orgData = result.data
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }

    if(orgData != null) {

      orgData= orgData.filter(c => c.active == true);
      if (this.globalAdm) {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        if(isLoading) {
          this.setState({ loading: false });
        }  
      }
      else {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        
        //await this.updateOrganization(this.organizationId);
        //this.setState({ organization: this.organizationId });

        if(isLoading)
          this.setState({ loading: false });
      }
      if(this.globalAdm || this.AdmOrganization) {
       
        if(orgData && orgData.length == 1) {
          this.setState((prevState) => ({
            called: {
              ...prevState.called,
              organization: orgData[0]
            }
          }), () => {
            this.organizationId = orgData[0].id
            this.updateOrganization(this.organizationId);
            
          })
          
        }
        else{
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              organization: null
            }
          }))
        }
      }
    } else {
      orgData = []
      this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      if(isLoading)
        this.setState({ loading: false });
    }
  };

  getCategoriesByOrganization = async (organizationId) => {
    const intl = this.props.intl;
    var result = await CalledService.getCategoriesByOrganization(organizationId);

    if (result.success) {

      let categories = result.data && result.data.length > 1 
      ? result.data.sort((a, b) => a.name.localeCompare(b.name)) 
      : result.data;

      categories = categories.map(item => ({id:item?.id, name:item?.name}));

      this.setState({ 
        categoryList: categories
      });
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async componentDidMount() {
    this.fetchOptionsServiceRules();
    this.fetchOptionsTechinicalTeam();
    this.fetchOptionsMessageProvider();
    this.fetchOptionsDistributionsStrategies();
    await this.getOrganizations();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { formData, selectedTechnicians, autocompleteInputValue } = this.state;
    const intl = this.props.intl;
    PapiroConsole.log(this.state.techinicalList)
    PapiroConsole.log("this.state.techinicalList")
    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <OnlinePredictionIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "channel.registration" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='Name' 
                        onChange={this.handleInputChange} 
                        value={formData.Name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"display_name"})} 
                        autoComplete='fdisplay_name' 
                        variant='outlined' 
                        name='DisplayName' 
                        onChange={this.handleInputChange} 
                        value={formData.DisplayName} 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="MessageProviderId"
                        label={intl.formatMessage({ id: "select.message_provider" })}
                        options={this.state.optionsMessageProviders}
                        stateName='MessageProviderId'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={this.state.formData.MessageProviderId}
                        htmlOptions={true}
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="TechinicalTeamId"
                        label={intl.formatMessage({ id: "select.techinical_team" })}
                        options={this.state.optionsTechinicalTeams}
                        stateName='TechinicalTeamId'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={this.state.formData.TechinicalTeamId}
                        htmlOptions={true} 
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({ id: "view.all.techs" })} name='ViewAllTechs' stateName='ViewAllTechs' changeSelect={this.handleChangeCheckbox} selected={this.state.ViewAllTechs}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{ maxWidth: '600px', margin: 'auto', padding: '16px' }}>
                        <Autocomplete
                          value={null}
                          inputValue={autocompleteInputValue}
                          options={
                            this.state.techinicalList
                          }
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => value && this.handleTechnicianSelect(value)}
                          renderInput={(params) => (
                            <TextField {...params} label="Técnicos" variant="outlined" fullWidth />
                          )}
                          filterOptions={this.filterOptions}
                          
                        />

                        <Paper elevation={3} style={{ marginTop: 16 }}>
                          <DragDropContext onDragEnd={this.handleOrderChange}>
                            <Droppable droppableId="technicians">
                              {(provided) => (
                                <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Nome do Técnico</TableCell>
                                        <TableCell>Ordem</TableCell>
                                        <TableCell>Excluir</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedTechnicians.map((technician, index) => (
                                        <Draggable key={technician.id} draggableId={technician.id.toString()} index={index}>
                                          {(provided) => (
                                            <TableRow
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <TableCell>{technician.name}</TableCell>
                                              <TableCell>{technician.order}</TableCell>
                                              <TableCell>
                                                <IconButton
                                            
                                                  onClick={() => this.handleTechnicianDelete(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Paper>
                      </div>
                    </Grid>
                   

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="DistributionStrategyId"
                        label={intl.formatMessage({ id: "select.distribution_strategy" })}
                        options={this.state.optionsDistributionsStrategies}
                        stateName='DistributionStrategyId'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={this.state.formData.DistributionStrategyId}
                        htmlOptions={true} 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="ServiceRules"
                        label={intl.formatMessage({ id: "select.service_rules" })}
                        options={this.state.optionsServiceRules}
                        stateName='ServiceRulesId'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={this.state.formData.ServiceRulesId}
                        htmlOptions={true} 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        label={intl.formatMessage({ id: "organization" })} 
                        options={this.state.organizationsList} 
                        stateName="DefaultOrganizationId" 
                        selected={this.state.formData.DefaultOrganizationId} 
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        label={intl.formatMessage({ id: "category" })} 
                        options={this.state.categoryList} 
                        stateName='DefaultOrganizationCategoryId' 
                        selected={this.state.formData.DefaultOrganizationCategoryId} 
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Switch
                        value={this.state.formData.Published}
                        checked={this.state.formData.Published}
                        name="Published"
                        onChange={(e) => this.handleChangeSwitch(e)} 
                      />
                      <FormattedMessage id="published" />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Switch
                        value={this.state.formData.Active}
                        checked={this.state.formData.Active}
                        name="Active"
                        onChange={(e) => this.handleChangeSwitch(e)}
                      />
                      <FormattedMessage id="active" />
                    </Grid>                    
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

ChannelRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChannelRegistration)));

ChannelRegistration.contextType = AbilityContext;