import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Typography, Container, Grid, CssBaseline, Button } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSmall2 from '../logo/logo-small-2';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Can } from '../../config/ability-context';
import { AbilityContext } from '../../config/ability-context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage,injectIntl } from 'react-intl';
import appNames from './names.json'
  

class Title extends Component {

    constructor(props) {
      super(props);
    }
  
    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    }    

    componentDidMount() {
    }

    caseNames(path)
    {
      const intl = this.props.intl;
      
      path = path.replace(/\//g,'');
      path = path.replace(this.props.match.params.lang, '');

      var nameUrl = appNames[path];
      if(nameUrl != null){
        return intl.formatMessage({id:nameUrl})
      }else{
        return "";
      }
    }
  
  
    render() {

        return (
          
          <span>{this.caseNames(this.props.history.location.pathname)}</span>


        );
      }
    }
    
    
    const mapStateToProps = state => ({
      userSession: state.userSession,
    });
    
    
    export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(Title))));
    Title.contextType = AbilityContext;