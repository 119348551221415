import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import * as componentActions from '../../../../redux/actions/calledservicesettingsfileuploadvalidations'
import Api from '../../../../services/api'
import { addItem, editItem } from '../../../../services/calledservicesettingsfileuploadvalidation.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import { getAllByOrganizationFieldIdWithoutIncludes } from '../../../../services/organizationfieldoptionsvalues.service'

function CalledServiceSettingsFileUploadValidationItemDialog(props) {

  const { intl, calledServiceSettingsFileUploadId, handleSetItemList } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [compareWithFaceLiveness, setCompareWithFaceLiveness] = useState(false)
  const [validateDocument, setValidateDocument] = useState(false)

  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)
  }, [props.open])

  useEffect(() => {
    if(props.calledservicesettingsfileuploadvalidations && props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation && props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation.id != null) {
      PapiroConsole.log("=== CalledServiceSettingsFileUploadValidationItemDialog ===")
      PapiroConsole.log(props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation)
      setId(props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation.id)
      setCompareWithFaceLiveness(props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation.comparewithfaceliveness)
      setValidateDocument(props.calledservicesettingsfileuploadvalidations.calledservicesettingsfileuploadvalidation.validatedocument)
    }
  }, [props.calledservicesettingsfileuploadvalidations])

  const setItemInitialState = _ => {
    setId(0)
    setCompareWithFaceLiveness(false)
    setValidateDocument(false)
  }

  const close = () => {
    setItemInitialState()
    props.addCalledServiceSettingsFileUploadValidation({
      id: 0,
      calledservicesettingsfileuploadid: calledServiceSettingsFileUploadId,
      comparewithfaceliveness: false,
      validatedocument: false
    })
    setOpen(false)
    props.handleOpen(false)
  }

  const setSnackbarNotificationMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const validateForm = _ => {
    if (calledServiceSettingsFileUploadId == null || (calledServiceSettingsFileUploadId != null && calledServiceSettingsFileUploadId === 0)) {
      setLoading(false)
      setSnackbarNotificationMessage(true, 'error', "O campo 'Campo de upload de arquivos' é obrigatório")
      return false
    }

    return true
  }

  const saveItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("CalledServiceSettingsFileUploadId", calledServiceSettingsFileUploadId)
      data.append("CompareWithFaceLiveness", compareWithFaceLiveness)
      data.append("ValidateDocument", validateDocument)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))
      data.append("UpdatedUser", props.userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      let response = id > 0 ? await editItem(data) : await addItem(data)
      if (response && response.success) {
        setLoading(false)
        setSnackbarNotificationMessage(true, 'success', id > 0 ? intl.formatMessage({ id: "edit.success" }) : intl.formatMessage({ id: "add.success" }))
        handleSetItemList([response.data])
        close()
      } else {
        setLoading(false)
        setSnackbarNotificationMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }
  
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-called-service-setting-organization-field-validation-dialog-title" >
        <DialogTitle id="form-called-service-setting-organization-field-validation-dialog-title">
          { id > 0 ? "Editar " : "Adicionar " } Validação de campo personalizado
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={compareWithFaceLiveness}
                      onChange={(e) => setCompareWithFaceLiveness(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Comparar rosto do anexo com selfie capturada na verificação de vida?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={validateDocument}
                      onChange={(e) => setValidateDocument(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Verificar se anexo é um documento?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={saveItem} >
            {intl.formatMessage({id:"save"})}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

CalledServiceSettingsFileUploadValidationItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleSetItemList: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsFileUploadId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsfileuploadvalidations: state.calledservicesettingsfileuploadvalidations
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledServiceSettingsFileUploadValidationItemDialog)))