export function addCurrent(organizationcategory) {
    return {
        type: 'ADD_CURRENT',
        organizationcategory,
    }
}

export function addCurrentOrganization(organizationcategory,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
            organizationcategory,
            organization,
    }
}