import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../styles/material-styles'
import { Button, Grid } from '@mui/material'
import moment from 'moment'
import { AttachFile } from '@mui/icons-material'
import { getFileDownloadLink } from '../../../services/attachment.service'

function Attachment(props) {
  const { intl } = props

  const getFile = async attachment => {
		props.setLoading(true)
		
		let url = await getFileDownloadLink(attachment.id)
		if(url &&  url.data && url.data.data && url.data.success){
      props.setLoading(false)
			window.open(`${url.data.data}`, '_blank');
		} else{
        props.setMessage(true, 'error', intl.formatMessage({ id: "file.not.found" }))
        props.setLoading(false)
    }
  }

  return(<div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex', alignItems: 'center', alignContent: 'center', width: '100%' }}>
    <AttachFile sx={{ marginRight: '4px', color: 'rgb(63, 81, 180)', fill: 'rgb(63, 81, 180)' }} />
    <Button onClick={() => getFile(props.attachment)}>{props.attachment && props.attachment.description}</Button>
  </div>)
}

Attachment.propTypes = {
  classes: PropTypes.object.isRequired,
  attachment: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(withStyles(Styles)(Attachment))