import React, { useState, useEffect } from 'react';
import { Divider, Grid, IconButton, List, ListItem, ListItemText, CircularProgress, Typography, Avatar, ListItemAvatar, Button, useMediaQuery, useTheme } from '@mui/material';

import { Clear } from '@mui/icons-material';
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import './styles.css'
import { injectIntl } from 'react-intl';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import ChatIcon from '@mui/icons-material/Chat';
import moment from 'moment';
import HistoryChatMessage from './history-message'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { PapiroConsole } from '../../../../utils/papiroConsole';
const drawerWidth = 230;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 0,
    borderLeft: 0,
    height: '101%',
    backgroundColor: '#F3F3F3',
    color: 'white',
    position: 'relative',
    top: 0,
    right: 0,
    overflowX: 'hidden',
    transition: 'border-left,width 0.5s cubic-bezier(0.4, 0, 0.6, 1)',
    padding: 0
  },
  open: {
    borderLeft: '10px solid #DADADA',
    width: '20%',
    '@media (max-width:599.9px)': {
      width: '30%',
      top: '20px',
      height: '116.5%'
    },
    '@media (min-width:600px) and (max-width:1199.9px)': {
      width: '30%',
    },
    '@media (min-width:1200px)': {
      width: '20%',
    }
  },
  gridClose: {
    display: 'none!important'
  },
  gridOpen: {
    display: 'flex!important'
  },
  gridItemCenter: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridItemSubtitleColor: {
    color: '#B4B4B4',
    fill: '#B4B4B4'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: 2 * drawerWidth, // Ajuste conforme necessário
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
}));

const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#3F51B4',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#2c387e',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
  },
};

const hideScrollbarStyles = {
  '&::-webkit-scrollbar': {
    display: 'none', // Para navegadores baseados em WebKit
  },
  '-ms-overflow-style': 'none', // Para Internet Explorer e Edge Legacy
  'scrollbar-width': 'none', // Para Firefox
};

const chamados = [
  { id: 1, titulo: 'Chamado 1', mensagens: ['Mensagem 1 do chamado 1', 'Mensagem 2 do chamado 1'] },
  { id: 2, titulo: 'Chamado 2', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 3, titulo: 'Chamado 3', mensagens: ['Mensagem 1 do chamado 1', 'Mensagem 2 do chamado 1'] },
  { id: 4, titulo: 'Chamado 4', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 5, titulo: 'Chamado 5', mensagens: ['Mensagem 1 do chamado 1', 'Mensagem 2 do chamado 1'] },
  { id: 6, titulo: 'Chamado 6', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 7, titulo: 'Chamado 7', mensagens: ['Mensagem 1 do chamado 1', 'Mensagem 2 do chamado 1'] },
  { id: 8, titulo: 'Chamado 8', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 9, titulo: 'Chamado 9', mensagens: ['Mensagem 1 do chamado 1', 'Mensagem 2 do chamado 1'] },
  { id: 10, titulo: 'Chamado 10', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 11, titulo: 'Chamado 11', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },
  { id: 12, titulo: 'Chamado 12', mensagens: ['Mensagem 1 do chamado 2', 'Mensagem 2 do chamado 2'] },

  // Adicione mais chamados conforme necessário
];

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function ChatHistorySidebar(props) {
  const { isOpen, onClose, chats, userSession, intl } = props

  const [chatSelected, setChatSelected] = useState(null);
  const [exibirQuantidade, setExibirQuantidade] = useState(10); // Quantidade inicial de chats a serem exibidos

  // const [exibirQuantidade, setExibirQuantidade] = useState(10); 
  const theme = useTheme();
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl')); // 
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // 

  PapiroConsole.log(props)
  PapiroConsole.log("props informada aqui")


  useEffect(() => {

    if (!props.chats.ishistorysidebaryopen) {
      setChatSelected(null)
    }
  }, [props.chats.ishistorysidebaryopen])


  const handleChatClick = (chat) => {
    setChatSelected(chat);
  };

  const handleMostrarMais = () => {

    setExibirQuantidade((prev) => prev + 10); // Incrementar a quantidade exibida
  };
  const classes = useStyles();




  if (chats && chats.chat && chats.chat.customer && chats.chat.customer.name) {
    return (
      <div className={`${classes.root} ${isOpen ? classes.open : ''} omnichannel-chat-user-details-sidebar`}>
        <Grid className={`${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%', paddingTop: '10%' }} container spacing={0}>
          <Grid className={`${classes.gridItemCenter} omnichannel-chat-user-details-sidebar-header`} item xs={10} >
            Histórico
          </Grid>
          <Grid className={`${classes.gridItemCenter}`} item xs={2} >
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
          </Grid>
        </Grid>
        <Divider className={`${isOpen ? classes.gridOpen : classes.gridClose}`} />



        <Paper elevation={3} sx={{ padding: '10px', height: '100vh', overflowY: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.0)', ...hideScrollbarStyles }}>

          {!props.loading ?

            <>
              <List sx={{ maxHeight: '50%', overflowY: 'auto', ...scrollbarStyles }}>
                {props.historyChatList && props.historyChatList.length > 0 && props.historyChatList.slice(0, exibirQuantidade).map((chat) => (
                  <StyledListItem button key={chat.id} onClick={() => handleChatClick(chat)} selected={chatSelected && chatSelected.id && chat.id === chatSelected.id}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#f2f2f2', width: 32, height: 32 }}>
                        {(props.chats.chat.messageproviderid == 2) && <WhatsAppIcon sx={{ color: '#59BC6B' }} />}
                        {(props.chats.chat.messageproviderid == 3) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                          <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                        </svg>}
                        {(props.chats.chat.messageproviderid == 4) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                          <path fill="#448AFF" d="M24,4C13.5,4,5,12.1,5,22c0,5.2,2.3,9.8,6,13.1V44l7.8-4.7c1.6,0.4,3.4,0.7,5.2,0.7c10.5,0,19-8.1,19-18C43,12.1,34.5,4,24,4z"></path><path fill="#FFF" d="M12 28L22 17 27 22 36 17 26 28 21 23z"></path>
                        </svg>}
                        {(props.chats.chat.messageproviderid == 5) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                          <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
                        </svg>}
                        {(props.chats.chat.messageproviderid == 6) && <ChatIcon sx={{ color: '#277afe' }} />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={'#' + chat.id} secondary={props.chats.chat.assignedusername+' - '+moment(chat.createdat).format("DD/MM/YYYY HH:mm")} />
                    {isExtraLargeScreen && (
                      <IconButton edge="end" aria-label="chat">
                        <ChatIcon sx={{ color: '#3F51B4' }} />
                      </IconButton>
                    )}
                  </StyledListItem>
                ))}
              </List>
              {exibirQuantidade < props.historyChatList.length &&

                <Box textAlign="center" mt={2}>
                  <Button variant="contained" color="primary" onClick={handleMostrarMais}>
                    Mostrar mais
                  </Button>
                </Box>

              }



              <Box sx={{ maxHeight: '50%', overflowY: 'auto', marginTop: '15px', ...scrollbarStyles }}>
                {chatSelected ? (
                  <>

                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ marginRight: '3px' }}>


                      <Chip label={"Id: #" + chatSelected.id} sx={{
                        borderRadius: '4px', // Define a borda retangular
                        backgroundColor: "#C5CAE9"
                      }} />

                    </Stack>

                    <List>



                      {chatSelected && chatSelected.chatmessages.map((message) => {
                        return <HistoryChatMessage
                          userSession={userSession}
                          message={message}
                        />
                      })}


                    </List>
                  </>
                ) : (props.historyChatList && props.historyChatList.length > 0 ?
                  <Typography variant="h6" gutterBottom>
                    Selecione um chat para ver sua conversa
                  </Typography>

                  :
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#5C6BC0', textAlign:'center'}}>
                    Não há registros no histórico.
                  </Typography>

                )}
              </Box>


            </>


            :

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>

          }


        </Paper>





      </div>
    )
  } else {
    return (<></>)
  }
}

const mapStateToProps = state => ({
  chats: state.chats
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChatHistorySidebar))
