import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Typography, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calledorganizationmaterials';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import moment from "moment";
import ClearIcon from '@mui/icons-material/Clear'

class CalledOrganizationMaterials extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds } = this.props;


        this.globalAdm = false;
        this.AdmOrganization = false;
        this.isCoordenator = false;
        this.isTechinical = false;
        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
            if (item.role.roletype == 1) {
                this.AdmOrganization = true;
            }
            if (item.role.roletype == 4) {
                this.isCoordenator = true;
            }
            if (item.role.roletype == 2) {
                this.isTechinical = true;
            }
        });

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            archived : calleds.called && calleds.called.archived ? calleds.called.archived : false,
            createdUser: userSession.user.id,
            calledorganizationmaterial: [],
            user: userSession.user,
            template3: false,
            template4: true,
            disableValueInformation: false,
            MaterialList: [],
            material: [],
            OrganizationChecklistList: [],
            CalledOrganizationChecklistList: [],
            materialSelected: [],
            checklistTextEvidenceModalOpen: false,
            checklistImageEvidenceModalOpen: false,
            evAttachTitle: '',
            evTextTitle: '',
            evTextInput: '',
            evTextId: null,
            evAttachFile: {},
            evAttachFileData: null,
            evAttachId: null,
            slaOverflowContent: false,
            reasonsList: [],
            calledReasonId: null,
            reasonOther: '',
            materialFinishCalledDeleteButton: 'none',
            materialFinishCalledDeleteItems: null,
            calledOrganizationMaterialDeleteModalOpen: false,
            calledOrganizationMaterialInsertModalOpen: false,
            CalledOrganizationMaterialList: [],
            OrganizationMaterialList: [],
            formCallOrgMatOrganiaztionMaterial: null,
            formCallOrgMatQuantity: 0,
            organizationMaterialInsertModalOpen: false,
            OrganizationMaterialId: 0,
            OrganizatioMaterialText: '',
            InsertMaterialList: [],
            InsertOrganizationMaterialName: "",
            InsertOrganizationMaterialGaranty: 0,
            InsertOrganizationMaterialValue: '',
            invoicedate: moment().format('YYYY/MM/DD HH:mm:ss'),
            SLAStepIsPrevious: false,
            ActionStepIsPrevious: true,
            TwoStepsPrevious: false,
            StepCalledOrganizationChecklist: false,
            finishStepSLA: false,
            finishStepAction: false,
            finishStepChecklist: false,
            finishStepMaterial: false,
            getChecklists: false,
            navValue: "sla",
            navPrevValue: ""
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'CalledOrganizationMaterial');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }

        Api.delete(`/calledorganizationmaterials/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });
    };

    getOrganizationMaterials = async () => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/organizationmaterials/organization/${this.props.calleds.called.organization.id}`)
            .then(result => {
                this.setState({ loading: false, OrganizationMaterialList: result.data.data });
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }


    componentDidMount() {
        this.getItens();
        this.getOrganizationMaterials();
    }

    getItens() {

        this.setState({ loading: true });
        Api.get(`/calledorganizationmaterials/called/${this.props.calleds.called.id}`)
            .then(result => {
                let formatedList = []
                if (result.data.data.length > 0) {
                    formatedList = result.data.data.map(item => {
                        return {
                            ...item,
                            MaterialText: item.organizationmaterial ? item.organizationmaterial.name : '',
                            MaterialValue: item.organizationmaterial ? item.organizationmaterial.value : '',
                            MaterialTotalValue: item.organizationmaterial ? item.organizationmaterial.value ? item.organizationmaterial.value * item.quantity : 0 : 0
                        }
                    })
                }
                this.setState({ loading: false, CalledOrganizationMaterialList: formatedList });
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    updateCalledOrganizationMaterialQuantity = async (e) => {
        const intl = this.props.intl;

        this.setState({ loading: true });

        console.log("=== E ===")
        console.log(e)

        var regex = /^[0-9]+$/;
        if(e && e.field == "quantity") {
            if(!regex.test(e.value)) {
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.organization.material.list.invalid.material.amount"})
                });
                return false
            }
        } else {
            regex = /^[0-9.]+$/;
            if(!regex.test(e.value)) {
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.organization.material.list.invalid.material.value"})
                });
                return false
            }
        }

        let link = `/calledorganizationmaterials/quantity`

        let dataSet = new FormData();
        dataSet.append('Id', e.id)
        dataSet.append('UpdatedUser', this.state.user.id)

        if (e && e.field == "quantity") {
            link = `/calledorganizationmaterials/quantity`
            dataSet.append('Quantity', e.value)
        } else {
            link = `/calledorganizationmaterials/value`
            dataSet.append('Quantity', 1)
            dataSet.append('Value', e.value)
        }


        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        await Api.put(link, dataSet, config)
            .then(result => {
                if (result.data.success) {
                    let data = result.data.data;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"called.organization.material.list.updated.material.count"})
                    });
                    this.getItens();
                }
            })
            .catch(err => {
                this.setState({
                    formCallOrgMatOrganiaztionMaterial: null,
                    formCallOrgMatQuantity: 0,
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                this.getItens();
            });
    }

    openCalledOrganizationMaterialInsertModal = async () => {
        await this.getOrganizationMaterials()

        this.setState({
            calledOrganizationMaterialInsertModalOpen: true
        })
    }

    deleteCalledOrganizationMaterialsFunc = itm => {
        if (itm.length > 0) {
            this.setState({
                materialFinishCalledDeleteButton: 'flex',
                materialFinishCalledDeleteItems: itm
            })
        } else {
            this.setState({
                materialFinishCalledDeleteButton: 'none',
                materialFinishCalledDeleteItems: null,
            })
        }
    }

    openCalledOrganizationMaterialDeleteModal = async () => {
        const intl = this.props.intl;
        if (this.state.materialFinishCalledDeleteItems && this.state.materialFinishCalledDeleteItems.length > 0) {
            this.setState({
                calledOrganizationMaterialDeleteModalOpen: true
            })
        } else {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.organization.material.list.select.material"})
            })
        }
    }

    closeCalledOrganizationMaterialInsertModal = () => this.setState({ calledOrganizationMaterialInsertModalOpen: !this.state.calledOrganizationMaterialInsertModalOpen, formCallOrgMatOrganiaztionMaterial: null, formCallOrgMatQuantity: 0 })

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name == "formCallOrgMatQuantity") {
            if (value.match('.') || value.match(',')) {
                this.setState({
                    [name]: parseInt(value.replaceAll(",", ""))
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    };

    changeValues = (stateName, value) => {

        if (stateName == 'template') {
            if (value == true) {
                this.setState({
                    ['template']: value,
                    ['template2']: false,
                });
            } else {
                this.setState({
                    ['template']: value,
                });
            }

        } else if (stateName == 'template2') {
            if (value == true) {
                this.setState({
                    ['template2']: value,
                    ['template']: false,
                });
            } else {
                this.setState({
                    ['template2']: value,
                })
            }
        }


        else if (stateName == 'template3') {

            if (value == true) {
                this.setState({
                    ['template3']: value,
                    ['template4']: false,
                });
            } else {
                this.setState({
                    ['template3']: value,
                })
            }

        }

        else if (stateName == 'template4') {
            if (value == true) {
                this.setState({
                    ['template4']: value,
                    ['template3']: false,
                });
            } else {
                this.setState({
                    ['template4']: value,
                })
            }

        }
        else if (stateName.includes("chk-")) {
            this.setState({
                [stateName]: value
            })
            let splitChk = stateName.split('-')
            let checkId = splitChk[1]


        }
        else {
            this.setState({
                [stateName]: value
            });
        }

        //this.submitAction();
    }

    createCalledOrganizationMaterial = async () => {
        const intl = this.props.intl;
        if (this.state.formCallOrgMatOrganiaztionMaterial && this.state.formCallOrgMatOrganiaztionMaterial.length > 0) {
            const intl = this.props.intl;
            const { user } = this.props;
            this.setState({ loading: true });
            let dataSet = new FormData();
            dataSet.append('CalledId', this.props.calleds.called.id);

            if (this.state.formCallOrgMatQuantity > 0)
                dataSet.append('Quantity', this.state.formCallOrgMatQuantity);
            else
                dataSet.append('Quantity', 0);

            dataSet.append('CreatedUser', this.state.user.id);

            let OrganizationMaterialIds = ""
            this.state.formCallOrgMatOrganiaztionMaterial.forEach(item => {
                OrganizationMaterialIds += `${item.id},`
            })
            dataSet.append('OrganizationMaterialIds', OrganizationMaterialIds);

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            };
            await Api.post(`/calledorganizationmaterials/multiple`, dataSet, config)
                .then(result => {
                    if (result.data.success) {
                        let data = result.data.data;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: this.state.formCallOrgMatOrganiaztionMaterial.length > 1 ? intl.formatMessage({id:"called.organization.material.list.added.material.plural"}) : intl.formatMessage({id:"called.organization.material.list.added.material"}),
                            calledOrganizationMaterialInsertModalOpen: false,
                            formCallOrgMatQuantity: 0,
                            formCallOrgMatOrganiaztionMaterial: null,
                        });
                        this.getItens();
                    }
                })
                .catch(err => {
                    this.setState({
                        formCallOrgMatOrganiaztionMaterial: null,
                        formCallOrgMatQuantity: 0,
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                });
        } else {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.organization.material.list.select.material"})
            });
        }
    }

    closeCalledOrganizationMaterialDeleteModal = () => this.setState({ calledOrganizationMaterialDeleteModalOpen: !this.state.calledOrganizationMaterialDeleteModalOpen })

    deleteCalledOrganizationMaterials = async () => {

        const intl = this.props.intl
        this.setState({ loading: true });

        let ids = this.state.materialFinishCalledDeleteItems
        if (ids != null) {
            let idsString = ""
            ids.forEach(function (item, i) {
                idsString += `${item.id},`
            })


            Api.delete(`/calledorganizationmaterials/multiple/${ids}`)
                .then(result => {
                    if (result && result.data && result.data.success && result.data.success == true) {
                        this.setState({
                            evAttachFileData: result.data.data.evidenceattachment,
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({id:"called.organization.material.deleted"}),
                            calledOrganizationMaterialDeleteModalOpen: false,
                            materialFinishCalledDeleteItems: null
                        });
                    }
                    this.getItens();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.getItens();
                });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const intl = this.props.intl;
        const { classes } = this.props;
        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                <CssBaseline />
                <Dialog open={this.state.calledOrganizationMaterialDeleteModalOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {intl.formatMessage({id:"sure.delete.multiple"})}
                        <ClearIcon onClick={this.closeCalledOrganizationMaterialDeleteModal} style={{ float: 'right', cursor: 'pointer' }} />
                    </DialogTitle>
                    <Grid container justify="flex-end" style={{ padding: 15 }}>
                        <Grid item xs={12} sm={4}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                className={classes.button}
                                onClick={this.closeCalledOrganizationMaterialDeleteModal}
                            >
                                {<FormattedMessage id="cancel" />}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={this.deleteCalledOrganizationMaterials}
                            >
                                {<FormattedMessage id="delete" />}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={this.state.calledOrganizationMaterialInsertModalOpen}
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            overflow: 'hidden'
                        }
                    }} >
                    <DialogTitle id="form-dialog-title">
                        {intl.formatMessage({id:"called.organization.material.insert"})}
                        <ClearIcon onClick={this.closeCalledOrganizationMaterialInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container style={{ padding: 15 }}>
                            <Grid item xs={12} sm={12}>
                                <SimpleAutoCompleteMultiple
                                    label={intl.formatMessage({id:"components"})}
                                    options={this.state.OrganizationMaterialList}
                                    stateName='formCallOrgMatOrganiaztionMaterial'
                                    changeSelect={this.changeValues}
                                    selected={this.state.formCallOrgMatOrganiaztionMaterial} />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                                <TextField
                                    name="formCallOrgMatQuantity"
                                    autoComplete="fname"
                                    label={intl.formatMessage({id:"amount"})}
                                    fullWidth
                                    value={this.state.formCallOrgMatQuantity}
                                    type="number"
                                    variant="outlined"
                                    onChange={(e) => this.handleInputChange(e)} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="button"
                                onClick={this.closeCalledOrganizationMaterialInsertModal}
                                fullWidth >
                                {this.props.intl.formatMessage({id:"cancel"})}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={this.createCalledOrganizationMaterial}
                            >
                                {<FormattedMessage id="save" />}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <Grid container>
                    <Grid xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                        <Grid xs={12} sm={3} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                            <Typography component='h1' variant='h5'>{intl.formatMessage({id:"materials.used"})}</Typography>
                        </Grid>
                        <Grid xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                            { (this.globalAdm || this.AdmOrganization || this.isCoordenator || this.isTechinical ) && !this.state.archived && (
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                                onClick={this.openCalledOrganizationMaterialDeleteModal}
                            >
                                {intl.formatMessage({id:"called.organization.material.delete"})}
                            </Button>)}
                        </Grid>
                        <Grid xs={12} sm={1} ></Grid>
                        <Grid xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                            { (this.globalAdm || this.AdmOrganization || this.isCoordenator || this.isTechinical ) && !this.state.archived && (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={this.openCalledOrganizationMaterialInsertModal} >
                                {intl.formatMessage({id:"called.organization.material.new"})}
                            </Button>)}
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={12}
                        style={{
                            marginLeft: 5,
                            marginBottom: 5,
                            border: '3px solid #eee'
                        }} >
                        <div style={{ height: 300 }}>

                            <DataGrid
                                style={{ fontSize: 14, color: '#000' }}
                                className="DataGridStyle"
                                rows={this.state.CalledOrganizationMaterialList}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                onCellEditCommit={(e) => this.updateCalledOrganizationMaterialQuantity(e)}
                                onSelectionModelChange={itm => {
                                    this.deleteCalledOrganizationMaterialsFunc(itm)
                                }}
                                columns={[
                                    { headerName: intl.formatMessage({id:"components"}), field: 'MaterialText', width: 500 },
                                    { headerName: intl.formatMessage({id:"amount"}), field: 'quantity', width: 200, editable: (this.globalAdm || this.AdmOrganization || this.isCoordenator || this.isTechinical ) ? true : false },
                                    { headerName: intl.formatMessage({id:"value"}), field: 'value', width: 150, editable: (this.globalAdm || this.AdmOrganization || this.isCoordenator || this.isTechinical ) ? true : false },
                                    { headerName: intl.formatMessage({id:"total.value"}), field: 'valueamount', width: 150 }
                                ]}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} style={{ marginLeft: 5, marginBottom: 5 }}>
                    <Typography component='h1' variant='h5' style={{ fontSize: "0.7rem" }} >
                        {intl.formatMessage({id:"called.organization.material.double.click.edit"})}
                    </Typography>
                </Grid>

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}

                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />
            </Container>

        );
    }
}
CalledOrganizationMaterials.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledOrganizationMaterials)));

CalledOrganizationMaterials.contextType = AbilityContext;
