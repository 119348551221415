import React from 'react';
import Path from '../../images/delivered.png';

const Icon = props => (
    <div>
        <img src={props.src && props.src.length > 0 ? props.src : Path}  
        style={props.style != null ? props.style : {  }}
        title={props.title != null ? props.title : "Delivered"}/>
    </div>
);

export default Icon;
