import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CategoryIcon from '@mui/icons-material/Category';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { InputLabel, FormControl, TextField, Grid} from '@mui/material';
import ImageList from '@mui/material/ImageList';

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 6,
    paddingLeft: 6,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        '--tree-view-bg-color:active': bgColor,
        '--tree-view-bg-color:target': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 200,
    flexGrow: 1,
    maxWidth: 400,
  },
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: 'flex',
  },
  ImageList: {
    width: "100%",
    height: 200,
  },  
});
export default function SimpleTreeView(props) {
    const classes = useStyles();
    const { rootId,allowSelect ,parentLabel, label, options, stateName, changeSelect, selected, selectedText } = props;

    const [option, setOption] = React.useState(selected ? selected : 0);
    const [optionText, setOptionText] = React.useState(selectedText ? selectedText : '');
    const inputLabel = React.useRef(null);
    const [labelwidth, setLabelWidth] = React.useState(0);
  
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
      setOption(selected ? selected : 0);
      setOptionText(selectedText ? selectedText : '');
    }, [selectedText]);
  
  
    function getParentLi(el){
      var parent = el.parentNode;
      while(parent != null ){
        if(parent.tagName == 'LI'){
            break;
        }
        parent = parent.parentNode;
      }
      return parent;
    }
    const handleChange = event => {
      //console.log(event.target.innerText);
      var li = getParentLi(event.target);
      //console.log(li);
      var id = parseInt(li.getAttribute('value'));
      //console.log(id);
      //console.log(selected);
      setOption(id);
      setOptionText(event.target.innerText);
      changeSelect(stateName, id, event.target.innerText);
      //console.log(selectedText);
      //console.log(options)

    };
    
    const handleToogle = event => {
      //console.log(event.target);
    };

    const getTreeItemsFromData = treeItems => {
      //console.log(treeItems);
      return treeItems.map(treeItemData => {
        let children = undefined;
        if (treeItemData.categories && treeItemData.categories.length > 0) {
          children = getTreeItemsFromData(treeItemData.categories);
        }
        
            return (
              <StyledTreeItem onClick={allowSelect ? handleChange : (treeItemData.cancreatecalled == 1 ? handleChange : null) }
                key={treeItemData.id}
                nodeId={""+treeItemData.id+""}
                labelText={treeItemData.name}
                value={treeItemData.id}
                labelIcon={treeItemData.cancreatecalled == 1 ? PlaylistAddCheckIcon : Label}
                labelInfo="" 
                color="#1a73e8"
                bgColor="#e8f0fe"
                children={children}
              />
            );
      });
    };
    //console.log(rootId);
    return (
      <div>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                      <InputLabel ref={inputLabel} >
                          {parentLabel}
                      </InputLabel>
              </Grid>
          </Grid>

          <ImageList cellHeight={60} className={classes.ImageList} cols={1} >
            <FormControl variant="outlined" className={classes.formControl}>
                  <TreeView
                    className={classes.root}
                    defaultExpanded={["0"]}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    value={option}
                    onNodeToggle={handleToogle}
                  >
                    <StyledTreeItem  nodeId="0" labelText={label} labelIcon={CategoryIcon} value="0" onClick={allowSelect ? handleChange : null}>
                      {getTreeItemsFromData(options)}
                    </StyledTreeItem>
                  </TreeView>
              </FormControl>  
            </ImageList>
      </div>
    );
  }


  SimpleTreeView.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.number,
    selectedText: PropTypes.string
  };
  