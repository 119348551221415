import React from 'react';
import LogoMarca from '../../images/logo-cmtech-login.png';
import './styles.css'

const Logo = props => (
    <div>
        <img src={props.src && props.src.length > 0 ? props.src : LogoMarca} style={{ minHeight: 60, maxHeight: "600px", maxWidth: "600px" }} title="Logomarca" className='App-logo' />
    </div>
);

export default Logo;
