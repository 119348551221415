import React from 'react';
import DescriptionIcon from '../../images/me-feedback/description_icon.png';

const MEDescriptionIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : DescriptionIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackDescriptionIcon"
            className='App-Description-icon' />
    </div>
);

export default MEDescriptionIcon;
