import Api from './api';
export const getOrganizationDepartaments = async () => { 
  let data = [];
  await Api.get('/organizationdepartaments')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getDepartaments = async (orgid = 0) => { 
  let data = [];
  // console.log(orgid);
  if(orgid == 0){
    await Api.get('/organizationdepartaments/')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
    return data;
  }else{
    await Api.get(`/organizationdepartaments/organization/${orgid}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
    return data;
  }
  
};
export const getPatrimonySubgroups = async (patrimonyGroupId = 0) => { 
  let data = [];
  if(patrimonyGroupId == 0){
    await Api.get('/patrimonysubgroups')
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
    return data;
  }else{
    await Api.get(`/patrimonysubgroups/group/${patrimonyGroupId}`)
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
    return data;
  }
};

export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getPatrimonyGroups = async () => { 
  let data = [];
  await Api.get('/patrimonygroups')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getPatrimonyMaterials = async (subgroupby = 0) => { 
  let data = [];
  if(subgroupby == 0){
    await Api.get('/patrimonymaterials')
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
    return data;
  }else{
    await Api.get(`/patrimonymaterials/subgroup/${subgroupby}`)
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
    return data;
  }
};


export const getPatrimonyOrganizations = async (id = 0) => { 
  let data = [];
  await Api.get(`/organizationpatrimonies/organization/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
