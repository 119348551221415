import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import HeaderMenu from '../../components/header-menu';
import Api from '../../services/api';
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/companies';
import Footer from '../../components/footer';
import { FormattedMessage, injectIntl } from 'react-intl';
import clsx from 'clsx';
import { Button, Card, CardContent, CircularProgress, Container, CssBaseline, Grid, Pagination, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Search from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FirstElement from '../../components/first-element';
import PeopleIcon from '@mui/icons-material/People';
import MyMaterialTable from '../../components/table';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import Api2Talk from "../../services/api2talk";

class CompanyList extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;
        this.emptyArray = null;

        const { userSession } = this.props;

        this.state = {
            dataFilter: false,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            totalPages:0,
            page:1,
            pageStart:0,
            pageEnd:0,
            count:0,
            searchValue: ''
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e,page) =>{
        this.setState({ page:page });
        this.getItens(page);
    }

    setPagination(count,page){              
        let totalPage = Math.ceil (count/10);
        this.setState({ totalPages:totalPage })
        if(count > 0){
            if(page == 1){
                this.setState({pageStart:1,pageEnd:10});
            } else{
                let pageStart = ((page - 1) * 10) + 1;
                if(count >= page * 10){
                    this.setState({pageStart:pageStart,pageEnd:page * 10});
                } else{
                    this.setState({pageStart:pageStart,pageEnd:count});
                }
            }
        } else {
            this.setState({totalPages:0,page:1,count:0});
        }                   
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    }    

    componentDidMount() {
        this.getItens();
    }

    fetchDataNotSuccess(result) {
        const intl = this.props.intl;
        this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
    }

    fetchDataThrowError(err) {
        const intl = this.props.intl;
        this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
    }

    getItens(page = 1) {
        this.setState({ loading: true });

        let url = `/company/paginate?page=${page}`;
        
        Api2Talk.get(url)
            .then(result => {
                this.setState({ loading: false });
                if (result.data.success) {
                    this.emptyArray = (result.data.data.length === 0) ? true : false ;
                    const count = result.data.count;
                    this.setState({count});
                    this.setPagination(count,page);
                    const formattedList = result.data.data.map(item => {
                        return {...item}
                    });
                    this.setState({ loading: false, resultList: formattedList });
                } else {
                    this.fetchDataNotSuccess(result);
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.fetchDataThrowError(err)
                Api.kickoff(err);
            });
    };

    callSearch(){
        if(this.state.searchValue != '') this.getItens(this.state.page);                        
    }

    onFieldChange(event) {
        this.setState({ [event.target.name]: event.target.value, page:1 });
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;

        Api2Talk.delete(`/company/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                        resultList: []
                    });
                    this.getItens();
                } else {
                    this.fetchDataNotSuccess(result);
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.fetchDataThrowError(err);
                Api.kickoff(err);
            });
    };

    clearFields(){
        this.setState({searchValue: '', page:1, loading: true})
        this.getItens(1);
    }

    render() {

        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var lang = this.props.match.params.lang;
            document.location.href = `/${lang}/unavailable`
        }

        return (
          <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
            <HeaderMenu />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: headerMenu.open,
                }, classes.actionsContent, {
                    [classes.actionscontentShift]: actionsHeaderMenu.open,
                })}
            >
                <div className={classes.drawerHeader} />
                <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                    
                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}
                    
                    {(!this.emptyArray || this.state.dataFilter) ? (
                        <div>
                            <CssBaseline />
                            <Grid container className={classes.mb20}  spacing={0}>             
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    disabled={!this.context.can('Add', 'Group')}
                                    component={Link} {...{ to: `/${this.props.match.params.lang}/company-registration` }}
                                >
                                    {<FormattedMessage id="add" />}
                                </Button>
                            </Grid>

                            <Grid container spacing={6} alignItems="center"> 
                                <Grid item xs={12} sm={12}>
                                    <Card className="background-title-search">
                                        <CardContent> 
                                            <Grid container spacing={1} alignItems="center">
                                                
                                                <Grid item xs={12} sm={5} md={3} lg={6}>
                                                    <Typography variant="h5">
                                                        {<FormattedMessage id="company.header" />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>                                
                            </Grid>  

                            <MyMaterialTable
                                title=""
                                rowClick={(event, rowData) => {  
                                    this.props.addCurrent(rowData);
                                    this.props.history.push(`/${this.props.match.params.lang}/company-edit`);
                                }}
                                columns={[
                                    { 
                                        title: intl.formatMessage({id:"name"}), 
                                        field: 'name' 
                                    },
                                ]}
                                data={this.state.resultList}
                                deleteItem={this.deleteItem}                 
                            />

                            <Grid container style={{marginBottom:12,marginTop:8}}>
                                <Grid item xs={12} md={3}>
                                    <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                    {this.state.pageStart} &nbsp;
                                    {<FormattedMessage id="to" />} &nbsp; 
                                    {this.state.pageEnd} &nbsp;
                                    {<FormattedMessage id="of" />} &nbsp; 
                                    {this.state.count} &nbsp;
                                    {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
        
                        </div>)
                    :(
                        <div>
                            <FirstElement
                                title = {intl.formatMessage({id:"company.list.create.first.company"})}
                                subtitle = {intl.formatMessage({id:"company.list.create.company"})}
                                buttonText = {
                                    <Button
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    component={Link} {...{ to: `/${this.props.match.params.lang}/company-registration` 
                                }}>
                                {<FormattedMessage id="add" />}
                                </Button>
                                }
                                icon = {<PeopleIcon style={{fontSize: "45px"}} />}
                            />
                        </div>
                    )}

                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}

                    <CustomizedSnackbars
                        variant={this.state.notificationVariant}
                        message={this.state.notificationMessage}
                        isOpen={this.state.openNotification}
                        toClose={this.closeNotification}
                    />
                </Container>
            </main>
            <Footer />   
        </div>
        );
    }
}

CompanyList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CompanyList)));

CompanyList.contextType = AbilityContext;