import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CheckCircle, Error, Info, Close, Warning } from "@mui/icons-material";
import { amber, green } from "@mui/material/colors";
import {
  IconButton,
  //Snackbar,
  SnackbarContent,
  Button,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';


import { makeStyles } from "@mui/styles";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const useClasses = {
	success: {
	  backgroundColor: green[600],
	},
	error: {
	  backgroundColor: "#ed1628",
	},
	info: {
	  backgroundColor: "#0078d7",
	},
	warning: {
	  backgroundColor: amber[700],
	},
	icon: {
	  fontSize: 20,
	},
	iconVariant: {
	  opacity: 0.9,
	  marginRight: 5,
	},
	message: {
	  display: "flex",
	  alignItems: "center",
	},
	margin: {
	  margin: 1,
	},
  };

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const {
    handleRedirection,
    toMove,
    message,
    onClose,
    variant,
    ...other
  } = props;
  const Icon = variantIcon[variant];

  let newMessage = message;
  if(message == "Error: ContractInvalid"){
    newMessage = this.props.intl.formatMessage({id:"material.snackbars.system.unavailable"})
  }

  if (toMove != "" && toMove != undefined) {
    const handleRedirectionConst = () => {
      handleRedirection();
    };

    return (
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {newMessage}
          </span>
        }
        action={[
          <div>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => handleRedirectionConst()}
            >
              Verificar
            </Button>
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <Close className={classes.icon} />
            </IconButton>
          </div>,
        ]}
        {...other}
      />
    );
  } else {
    return (
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {newMessage}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <Close className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
		
      >
		<Alert onClose={onClose} severity={props.variant} sx={{ width: '100%' }}>
			{props.message}
		</Alert>		  
	  </SnackbarContent>
    );
  }
}

function GetAction(props) {
	const {
	  handleRedirection,
	  toMove,
	  message,
	  onClose,
	  variant,
	  classes,
	  ...other
	} = props;


	if (toMove != "" && toMove != undefined) {
		const handleRedirectionConst = () => {
			handleRedirection();
		  };	
  
	  return (
		  
			<div>
			  <Button
				variant="contained"
				size="small"
				color="primary"
				onClick={() => handleRedirectionConst()}
			  >
				Verificar
			  </Button>
			  <IconButton
				key="close"
				aria-label="close"
				color="inherit"
				onClick={onClose}
			  >
				<Close className={classes.icon} />
			  </IconButton>
			</div>
		  
		  
		
	  );
	} else {
	  return (
		 
			<IconButton
			  key="close"
			  aria-label="close"
			  color="inherit"
			  onClick={onClose}
			>
			  <Close className={classes.icon} />
			</IconButton>
		  
	  );
	}
  }

MySnackbarContentWrapper.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
  toMove: PropTypes.string,
  handleRedirection: PropTypes.func,
};

//const CustomizedSnackbarsInt = React.forwardRef(function CustomizedSnackbarsInt(props, ref) {
export default function	CustomizedSnackbars(props){
	const intl = props.intl;
	//const ref = React.createRef();
	const {
		variant,
		message,
		isOpen,
		toClose,
		toMove,
		handleRedirection,
		top
	  } = props;
	  const [open, setOpen] = React.useState(false);
	  const classes = useClasses;
  	  const Icon = variantIcon[variant];
	  let topsnack = top;
      if(topsnack == null || topsnack == ""){
		topsnack="top";

	  }
	  useEffect(() => {
		setOpen(isOpen);
	  }, [isOpen]);
	
	  const handleClose = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
		toClose();
	  };
  
	  let newMessage = message;
	  if(message == "Error: ContractInvalid"){
		newMessage = this.props.intl.formatMessage({id:"material.snackbars.system.unavailable"})
	  }
	  
	  return (
		//<div /*ref={ref}*/ {...props}>
		  <Snackbar
			anchorOrigin={{
			  vertical: topsnack,
			  horizontal: "right",
			}}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			message={<span id="client-snackbar" className={classes.message}>
			  {/*<Icon className={clsx(classes.icon, classes.iconVariant)} />*/}
			  {newMessage}
		    </span>
		    }
			key={{
				vertical: topsnack+" right",
			  }}

			  action={
				<React.Fragment>
					<GetAction 	onClose={handleClose}
						variant={variant}
						message={message}
						toMove={toMove}
						handleRedirection={handleRedirection}
						classes={classes}
					/>
				</React.Fragment>
			  }			  
		    >
				{/*
			<MySnackbarContentWrapper
			  onClose={handleClose}
			  variant={variant}
			  message={message}
			  toMove={toMove}
			  handleRedirection={handleRedirection}
			/>
			*/}
			<Alert onClose={handleClose} severity={props.variant} sx={{ width: '100%' }}>
				{<span id="client-snackbar" className={classes.message}>
			  {/*<Icon className={clsx(classes.icon, classes.iconVariant)} />*/}
			  {newMessage}
				</span>}
			</Alert>		  

		  </Snackbar>
		//</div>
	  );
	
};

//export default function CustomizedSnackbarsInt(props) {
//	return (
//        <CustomizedSnackbarsInt />
//  );
//}

CustomizedSnackbars.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  toClose: PropTypes.func,
  toMove: PropTypes.string,
  handleRedirection: PropTypes.func,
};
